import {
  ConnectorNotification,
  NotificationBufferSize,
} from "@app/kells/models/connector.model";
import { createReducer, on } from "@ngrx/store";
import * as NotificationActions from "./notification.actions";

export const featureKey = "notifications";

export interface NotificationState {
  notifications: ConnectorNotification[];
}

export const initialState: NotificationState = {
  notifications: [],
};

export const reducer = createReducer(
  initialState,
  on(NotificationActions.addFromConnector, (state, { notification }) => ({
    ...state,
    notifications: [notification, ...state.notifications].slice(
      0,
      NotificationBufferSize
    ),
  })),
  on(NotificationActions.markAllAsRead, (state) => ({
    ...state,
    notifications: state.notifications.map((n) => ({ ...n, read: true })),
  }))
);

export const getNotifications = (state: NotificationState) =>
  state.notifications;

export const unreadNotificationCount = (state: NotificationState) =>
  state.notifications.filter((notification) => !notification.read).length;
