import { ElementRef, Injectable } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  merge,
  ReplaySubject,
  Subject,
} from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  switchMapTo,
} from "rxjs/operators";
import { SizingPriority } from "../../models/canvas.model";
import { CanvasResizeInternalService } from "./canvas-resize-internal.service";
import { fromElementResize } from "@kells/utils/observable/observable-creators";
import { takeLatest } from "@kells/utils/observable/observable-operators";

export interface ImageResolution {
  height: number;
  width: number;
}

@Injectable()
export class CanvasResizeService {
  private MIN_RESIZE_INTERVAL = 100;

  private sizingPriority$ = new BehaviorSubject<SizingPriority>(
    SizingPriority.Width
  );

  private maxWidthSubject$ = new BehaviorSubject<number>(Infinity);

  private maxHeightSubject$ = new BehaviorSubject<number>(Infinity);

  private maxWidth$ = this.maxWidthSubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  private maxHeight$ = this.maxHeightSubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  private sourceImageResolution$ = new BehaviorSubject<ImageResolution>({
    height: 0,
    width: 0,
  });

  /**
   * The aspect ratio of the source image (and thus, the canvas).
   * It is calculated as `width / height`.
   */
  private widthOverHeightAspectRatio$ = this.sourceImageResolution$.pipe(
    map(({ width, height }) => {
      if (width === 0 || height === 0) return 0;
      this.sourceImageDimensions.next({ height, width });
      return width / height;
    })
  );

  private canvas$ = new ReplaySubject<fabric.Canvas>();

  private canvasContainerRef$ = new ReplaySubject<ElementRef>();

  private initialZoomLevelSubject = new BehaviorSubject<number>(0);

  private shouldUpdateCanvasDimensions$ = new Subject<unknown>();

  private sourceImageDimensions = new BehaviorSubject<ImageResolution>({
    height: 0,
    width: 0,
  });
  public sourceImageDimensions$ = this.sourceImageDimensions.asObservable();

  initialZoomLevel$ = this.initialZoomLevelSubject.asObservable();

  constructor() {
    this.initCanvasResizeEffects();
  }

  /**
   * Invoke this method to dynamically resize the canvas dimensions.
   *
   * The most important goal for canvas resizing is to ensure 100% of the canvas
   * is visible to the user. Next, the goal would be to maximize the canvas,
   * given the various canvas sizing constraints.
   *
   * Canvas resizing considers a number of factors, including but is not limited to:
   *   - browser window width and height
   *   - parent container(s) of the canvas instance
   *   - user-imposed max height and width constraints
   *
   * @param args
   *   - `canvasRef`:
   *       The canvas instance on which resizing will be performed.
   *   - `sizingPriority`:
   *       When several constraints are binding, whether to optimize for
   *       resizing based on window width or height.
   *   - `imageResolution`:
   *       The height and width of an image, in pixels.
   *   - `boundingContainerRef`:
   *       An Angular Element Ref. The ref should point to the canvas's
   *       immediate parent container.
   */
  init(args: {
    canvasRef: fabric.Canvas;
    sizingPriority: SizingPriority;
    imageResolution: ImageResolution;
    boundingContainerRef: ElementRef;
  }) {
    const {
      canvasRef,
      boundingContainerRef,
      sizingPriority,
      imageResolution,
    } = args;

    this.canvas$.next(canvasRef);
    this.canvasContainerRef$.next(boundingContainerRef);

    this.sizingPriority$.next(sizingPriority);
    this.sourceImageResolution$.next(imageResolution);

    this.shouldUpdateCanvasDimensions$.next();
  }

  /**
   * Manually trigger a calculation on the canvas's dimensions.
   *
   * @remark
   * Most of the canvas's dimension re-calculation happens automatically.
   * For instance, when the canvas's bounding container (its immediate parent
   * component) is resized, or if `setMaxWidth` or `setMaxHeight` is invoked,
   * canvas is automatically resized.
   *
   * However, there may be instances when you may want manual control over when
   * resize happens. This method exists for that purpose. When invoked, this
   * method automatically schedules a resize request to guarantee an upcoming
   * resize calculation.
   */
  triggerResize() {
    this.shouldUpdateCanvasDimensions$.next();
    // let w = 1; // desired width in pixels
  }

  /**
   * Update the max width constraint for the canvas.
   *
   * @param newMaxWidth
   *    The new maximum width, in pixels, for the canvas.
   */
  setMaxWidth(newMaxWidth: number) {
    this.maxWidthSubject$.next(newMaxWidth);
  }

  /**
   * Update the max height constraint for the canvas.
   *
   * @param newMaxHeight
   *    The new maximum height, in pixels, for the canvas.
   */
  setMaxHeight(newMaxHeight: number) {
    this.maxHeightSubject$.next(newMaxHeight);
  }

  private initCanvasResizeEffects() {
    const constraintChanges$ = merge(this.maxWidth$, this.maxHeight$).pipe(
      debounceTime(this.MIN_RESIZE_INTERVAL)
    );

    const containerResized$ = this.canvasContainerRef$.pipe(
      switchMap((containerRef) =>
        fromElementResize(containerRef.nativeElement).pipe(
          distinctUntilChanged(
            (x, y) => x.width === y.width && x.height === y.height
          )
        )
      )
    );

    merge(containerResized$, constraintChanges$).subscribe(() => {
      this.shouldUpdateCanvasDimensions$.next();
    });

    this.shouldUpdateCanvasDimensions$
      .pipe(
        switchMapTo(
          combineLatest([
            this.canvas$,
            this.canvasContainerRef$,
            this.widthOverHeightAspectRatio$,
          ]).pipe(takeLatest())
        )
      )
      .subscribe(
        ([canvas, boundingContainerRef, widthOverHeightAspectRatio]) => {
          this.updateCanvasDimensions(canvas, boundingContainerRef, {
            widthOverHeightAspectRatio,
          });
        }
      );
  }

  /**
   * When called, updates the canvas's width and height such that it fills
   * 100% of its container.
   */
  private updateCanvasDimensions(
    canvas: fabric.Canvas,
    boundingContainerRef: ElementRef,
    args: {
      widthOverHeightAspectRatio: number;
    }
  ) {
    if (!canvas) return;

    const { widthOverHeightAspectRatio } = args;

    const {
      widthConstraint,
      heightConstraint,
    } = CanvasResizeInternalService._resolveEffectiveSizeConstraints({
      containerRef: boundingContainerRef,
      userConfigured: {
        maxWidth: this.maxWidthSubject$.value,
        maxHeight: this.maxHeightSubject$.value,
      },
    });

    // The height and width, in px, that the canvas will have by the end of
    // this method, unless either or both of them are 0.
    const effectiveCanvasDimensions = CanvasResizeInternalService._computeEffectiveCanvasDimensions(
      {
        priority: this.sizingPriority$.value,
        widthOverHeightAspectRatio,
        widthConstraint,
        heightConstraint,
      }
    );

    // do not modify the canvas if the current canvas dimension is already optimal
    if (
      effectiveCanvasDimensions.width === canvas.width &&
      effectiveCanvasDimensions.height === canvas.height
    ) {
      return;
    }

    // Not setting the canvas's dimensions to 0 ensures that the canvas is
    // still visible on-screen after the window is resized to a larger size.
    if (
      effectiveCanvasDimensions.height === 0 ||
      effectiveCanvasDimensions.width === 0
    ) {
      return;
    }

    const zoomFactor = Math.min(
      effectiveCanvasDimensions.width / this.sourceImageResolution$.value.width,
      effectiveCanvasDimensions.height /
        this.sourceImageResolution$.value.height
    );

    canvas.setZoom(zoomFactor);

    this.initialZoomLevelSubject.next(zoomFactor);

    canvas.setDimensions(effectiveCanvasDimensions);

    // Ensure that the canvas remains at the center of the container by
    // snapping the canvas back to the container's boundaries.
    // A (somewhat undesirable) side effect is: if the image's zoom percentage
    // is less than 100%, this would essentially set the percentage to 100%.
    this.limitImageToCanvasBoundaries(canvas, zoomFactor);
  }

  /**
   * Ensure that edges of the image do not exceed canvas boundaries at all times.
   *
   * Adjusts viewport transformations to ensure that the canvas surface is
   * 100% covered by the image. For this purpose, the left edge of the image
   * cannot be on the right side of the left boundary of the canvas. This can
   * be extended for the other three borders of the image.
   *
   * This method should be called during any panning procedure to ensure
   * canvas is always fully covered by the image.
   *
   * `renderAll` should only be called after this function, not before.
   */
  limitImageToCanvasBoundaries(canvas: fabric.Canvas, zoom: number) {
    if (!canvas) {
      console.error(
        "Attempted to limit image boundaries when canvas is not instantiated."
      );
      return;
    }

    const vpt = canvas.viewportTransform;

    if (!vpt) return;
    // const [ zoomLevel, , , zoomLevel, left, top] = vpt;
    const canvasHeight = canvas.getHeight();
    const canvasWidth = canvas.getWidth();

    // Because zoom levels are floating point numbers, comparison results
    // between two zoom levels can be flaky. `zoom` can occasionally go
    // slightly below `initialZoomLevel` even though it is not supposed to.
    // We therefore relax the minimum zoom level that is used to check if
    // the image is currently zoomed in.

    const EPSILON = -0.000001;
    const hasZoomedIn = zoom - this.initialZoomLevelSubject.value > EPSILON;

    const imageOriginalWidth = this.sourceImageResolution$.value.width;
    const imageOriginalHeight = this.sourceImageResolution$.value.height;
    const xmin = canvasWidth / 2 - (imageOriginalWidth / 2) * zoom;
    const xmax = imageOriginalWidth * zoom - canvasWidth + 50;

    if (hasZoomedIn) {
      // if canvas has free space on x axis - do not change x position
      if (imageOriginalWidth * zoom < canvasWidth) {
        vpt[4] = xmin;
      } else {
        if (vpt[4] >= 0) {
          // moving left
          vpt[4] = 0;
        } else if (Math.abs(vpt[4]) >= Math.abs(xmax)) {
          // moving right
          vpt[4] = -xmax;
        }
      }

      if (vpt[5] >= 0) {
        // moving down
        vpt[5] = 0;
      } else if (vpt[5] < canvasHeight - imageOriginalHeight * zoom) {
        // moving up
        vpt[5] = canvasHeight - imageOriginalHeight * zoom;
      }
    } else if (zoom === this.initialZoomLevelSubject.value) {
      // prevent panning if not zoomed in
      vpt[4] = 0;
      vpt[5] = 0;
    }

    // canvas.setViewportTransform(vpt);
  }
}
