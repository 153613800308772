/**
 * A range of oral health conditions that a patient could be in.
 */
export enum PatientOralHealthCondition {
  Good = "Good",
  Fair = "Fair",
  Poor = "Poor",
}

export const PATIENT_ORAL_HEALTH_CONDITIONS_ALL: PatientOralHealthCondition[] = [
  PatientOralHealthCondition.Good,
  PatientOralHealthCondition.Fair,
  PatientOralHealthCondition.Poor,
];
