/**
 * Custom, frequently-used pipes to be used in observable pipes.
 */

import { pipe, iif, of, timer, UnaryFunction, Observable } from "rxjs";
import { filter, tap, map, switchMap, mapTo, take } from "rxjs/operators";
import { isNullable } from "@kells/utils/js";

/**
 * Argument properties for function [[`keepDefined`]].
 */
interface KeepDefinedProps {
  /**
   * Whether to throw when encountering null or undefined values in the pipe.
   *
   * This can be useful for assertion purposes in pipes where values are not
   * expected to be nullable, even though their type signature may indicate so.
   *
   * As with any throwable functions, the caller should be responsible for
   * handling potentially thrown errors.
   *
   * Default to `false`.
   */
  throwOnNullable?: boolean;
  /**
   * Optionally provide a custom error message, which will be used when
   * `throwOnNullable` is set to `true` and a nullable value is encountered
   * in a pipe.
   */
  nullableErrorMessage?: string;
}

/**
 * Only take defined values.
 * `null` and `undefined` values will be filtered out.
 *
 * @usage
 *
 * ```typescript
 * from(['js', undefined, 'ts', null]).pipe(
 *   keepDefined(), // filter out nullable values
 *   pipeLog()      // 'js', 'ts'
 * )
 *
 * from(['js', undefined, 'ts', null]).pipe(
 *   keepDefined({
 *     throwOnNullable: true,
 *     nullableErrorMessage: 'Custom Error Message: unexpected nullable value encountered'
 *   }), // throw on the first nullable value encountered
 *   pipeLog()      // 'js'
 * )
 * ```
 *
 * @param props
 *    For more documentation, see:
 *    [[`KeepDefinedProps`]]
 */
export function keepDefined<T>(
  props: KeepDefinedProps = { throwOnNullable: false }
) {
  return pipe(
    map((v: T | null | undefined) => {
      if (props.throwOnNullable && isNullable(v)) {
        const errorMsg =
          props.nullableErrorMessage ||
          "Unexpected nullable value encountered in `keepDefined` guard";
        throw new Error(errorMsg);
      }
      return v;
    }),
    filter(
      (v: T | null | undefined): v is NonNullable<T> =>
        v !== undefined && v !== null
    )
  );
}

/**
 * In a stream, throw if `undefined` or `null` is encountered.
 *
 * This is useful in streams where one would expect nothing but defined values,
 * but the type annotation indicates value is nullable.
 *
 * For streams where `undefined` and `null` is sometimes expected, but only the
 * defined values are relevant, consider using [[`keepDefined`]]. It does not
 * throw by default.
 *
 * @example
 * ```typescript
 * from('kells', undefined, 'assessment').pipe(
 *   throwIfUndefined(), // thrown on the second value, will not reach the 3rd value
 *   pipelog(),          // 'kells'
 * )
 * ```
 *
 * @param thrownMessage  Optionally provide an error message.
 *    This message may help provide context to why the error was thrown.
 *
 * @typeParam T  The type of the values in the stream.
 *     It is guaranteed that the values emitted after this pipe operator is of
 *     the type `NonNullable<T>`. In other words, it is guaranteed that this
 *     operator filters out all the nullable values.
 */
export function throwIfUndefined<T>(thrownMessage?: string) {
  return pipe(
    keepDefined<T>({
      throwOnNullable: true,
      nullableErrorMessage: thrownMessage,
    })
  );
}

/**
 * In a stream of booleans, only keep emissions of `true`.
 */
export const keepTrue = () => pipe(filter((v: boolean) => v));

/**
 * In a stream of booleans, only keep emissions of `false`.
 */
export const keepFalse = () => pipe(filter((v: boolean) => !v));

/**
 * Maps a value to true if it is not `undefined` or `null`.
 */
export const mapDefined = <T>() =>
  pipe(map((v: T) => v !== undefined && v !== null));

/**
 * Log the output from the value returned from the previous operator.
 *
 * @param msg  optionally provide a debug context message that will be printed
 *             before the value in the pipe.
 */
export const pipeLog = <T>(msg = "") => {
  // eslint-disable-next-line no-console
  return pipe(tap((v: T) => console.log(msg, v)));
};

/**
 * Given a boolean observable, throttles only 1 kind of its emission and not
 * the other (e.g. throttle `true` but not `false`). Returns an observable
 * with potential throttling.
 *
 * This operator is useful for preventing flickering UI loading elements when
 * the load time is short. You may want to use this operator to delaying
 * showing loading indicator until the load time has reached a sufficient
 * threshold.
 *
 * @param whichBoolean
 *     defines whether to throttle `true` or `false`.
 * @param throttleTime
 *     defines the throttle duration, in miliseconds, for the aforementioned value.
 */
export const throttleBoolean = (
  whichBoolean: boolean,
  throttleTime: number
): UnaryFunction<Observable<boolean>, Observable<boolean>> =>
  switchMap((incomingBoolVal) =>
    iif(
      () => (whichBoolean ? incomingBoolVal : !incomingBoolVal),
      timer(throttleTime).pipe(mapTo(incomingBoolVal)),
      of(incomingBoolVal)
    )
  );

/**
 * In an observable stream, take the latest emission and completes it.
 *
 * It is a short-hand version of `take(1)`.
 */
export const takeLatest = <T>() => pipe(take<T>(1));
