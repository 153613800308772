import { Observable, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { isEqual, flatten } from "lodash-es";

/**
 * Iterates on an object's properties, performing the same action on
 * its properties.
 *
 * @param o object to iterate on
 * @param predicate evaluation condition for each of the object's property
 */
export const iterateObjectProperties = <T, R>(
  o: Record<string, T>,
  predicate: (o: [string, T]) => R
) => Object.entries(o).map(predicate);

/** @return true if all of an object's fields are defined, false otherwise */
export const areAllFieldsDefinedForObject = <T>(
  o: Record<string, T>
): boolean => {
  const isPropertyDefined = ([, v]: [string, any]) =>
    v !== null && v !== "" && v !== undefined;
  return iterateObjectProperties(o, isPropertyDefined).every((b) => b);
};

/**
 * Flatten any highly nested object into a one-level object, discarding all
 * intermediate levels. The remaining keys are the deepest nested keys.
 *
 * Example:
 * ```
 * {
 *    k11: {
 *      k12: {
 *        k13: []
 *      }                 {
 *    },                      k13: [],
 *    k21: {},    =>          k21: {},
 *    k31: {                  k32: []
 *      k32: []           }
 *    }
 * }
 * ```
 * @param object the object that needs to be flattened
 */
export const flattenObj = <
  R,
  T extends string | number | symbol,
  V extends Record<T, any>
>(
  object: Record<T, V>
): Record<T, R> => {
  const _flatten = (obj: Record<T, V>): Record<T, R>[] => {
    const toBeConcatted = Object.entries<any>(obj).map(([k, v]) => {
      if (typeof v === "object" && !Array.isArray(v)) {
        return _flatten(v);
      }
      return { [k]: v };
    });

    return flatten(toBeConcatted);
  };
  return Object.assign({}, ..._flatten(object));
};

/**
 * Drop a property in the given object.
 *
 * @param obj Original object
 * @param propToDrop The name of the property to be dropped.
 * @returns A new object the same as the object provided, except without
 *  the property specified. If the property to drop is not found, a new object
 *  that is equal to the original object is returned.
 */
export const dropProperty = <T>(
  obj: Record<string, T>,
  propToDrop: string
): Record<string, T> =>
  Object.entries(obj)
    .filter(([k]) => k !== propToDrop)
    .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});

/**
 * Bind an observable's emission's to a behavioral subject.
 *
 * This utility is useful for facilitating the adoption of observables in a
 * traditional, properties-driven component. Use this utility to bind an
 * observable to a behavior subject, then create a getter property that returns
 * the behavior subject's latest value. Then you have created a readonly
 * property whose value depends solely on the observable.
 *
 * @param obs The observable to bind from
 * @param onComplete a subject that signifies subscription termination.
 * @param initialVal for the subject to be initialized. `Null` if not provided.
 * @returns a new behavior subject.
 */
export const bindToSubject = <T>(
  obs: Observable<T>,
  onComplete: Subject<boolean>,
  initialVal?: T
): BehaviorSubject<T | null> => {
  const sbj = new BehaviorSubject<T | null>(initialVal || null);
  obs.pipe(takeUntil(onComplete)).subscribe((v) => sbj.next(v));
  return sbj;
};

/**
 * Only keep unique values in an array.
 * @example
 * array.filter(keepUnique)
 */
export const keepUnique = <T>(v: T, index: number, A: T[]) =>
  A.findIndex((x) => isEqual(x, v)) === index;

/**
 * Assert in the codebase that a condition that should never be met is
 * satisfied during runtime.
 *
 * Typical use cases include:
 *     - a variable is of an Enum type and it has a value that is not part of
 *       the enum during runtime.
 *     - an object property which should be defined is found to be nullable
 *       during runtime.
 *
 * @param errorMessage
 *     a string which explains what the condition which should never happen
 *     is, and the context (e.g. local variables) under which this condition
 *     happened during runtime.
 *
 * @throws
 *     an error with the provided `errorMessage` which records the nature of
 *     the condition which should never be true.
 */
export const assertNever = (errorMessage: string) => {
  throw new Error(errorMessage);
};
