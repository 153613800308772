import { createAction, props } from "@ngrx/store";
import { AddTreatmentData, Treatment } from "@kells/clinic-one/apis";

enum ActionTypes {
  AddTreatmentFromPatientSession = "[Patient Page] Add Treatment",
  AddTreatmentFromImageDetail = "[Image Detail Page] Add Treatment",
  AddTreatment = "[Image Report] Add Treatment",
  AddTreatmentSuccess = "[Image Report] Add Treatment Success",
  AddTreatmentFailure = "[Image Report] Add Treatment Failure",

  DeleteTreatment = "[Image Report] Delete Treatment",
  DeleteTreatmentSuccess = "[Image Report] Delete Treatment Success",
  DeleteTreatmentFailure = "[Image Report] Delete Treatment Failure",

  GetPatientTreatments = "[Report] Get Patient Treatments",
  GetPatientTreatmentsSuccess = "[Report] Get Patient Treatments Success",
  GetPatientTreatmentsFailure = "[Report] Get Patient Treatments Failure",
}

export const addTreatmentFromImageDetail = createAction(
  ActionTypes.AddTreatmentFromImageDetail,
  props<{ treatment: AddTreatmentData }>()
);

export const addTreatmentFromPatientSession = createAction(
  ActionTypes.AddTreatmentFromPatientSession,
  props<{
    treatment: AddTreatmentData;
    patientId: string;
    sessionDate: string;
    sessionId: string;
  }>()
);

export const addTreatment = createAction(
  ActionTypes.AddTreatment,
  props<{
    treatment: AddTreatmentData;
    patientId: string;
    sessionDate: string;
    sessionId: string;
  }>()
);

export const addTreatmentSuccess = createAction(
  ActionTypes.AddTreatmentSuccess,
  props<{ treatment: Treatment }>()
);

export const addTreatmentFailure = createAction(
  ActionTypes.AddTreatmentFailure,
  props<{ error: any }>()
);

export const deleteTreatment = createAction(
  ActionTypes.DeleteTreatment,
  props<{ treatmentId: string; patientId: string }>()
);

export const deleteTreatmentSuccess = createAction(
  ActionTypes.DeleteTreatmentSuccess,
  props<{ treatmentId: string }>()
);

export const deleteTreatmentFailure = createAction(
  ActionTypes.DeleteTreatmentFailure,
  props<{ error: any }>()
);

export const getPatientTreatments = createAction(
  ActionTypes.GetPatientTreatments,
  props<{ patientId?: string }>()
);

export const getPatientTreatmentsSuccess = createAction(
  ActionTypes.GetPatientTreatmentsSuccess,
  props<{ treatments: Treatment[] }>()
);

export const getPatientTreatmentsFailure = createAction(
  ActionTypes.GetPatientTreatmentsFailure,
  props<{ error: any }>()
);

export const errorActions = [addTreatmentFailure, deleteTreatmentFailure];
