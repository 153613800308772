<div
  class="finding-edit-form-container dark  material-edit-form  py-4">
  <div class="form-wrapper">
    <form [formGroup]="editForm">
      <mat-form-field appearance="fill" style="width: 5rem">
        <mat-label>Tooth</mat-label>
        <input
          #tooth_field
          matInput
          formControlName="tooth"
          autocomplete="off"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 9rem">
        <mat-label>Material</mat-label>
        <mat-select formControlName="material">
          <mat-option
            *ngFor="let material of materialOptions"
            [value]="material"
          >
            {{ material }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="error-container" *ngIf="editFormTooth?.errors">
        <span *ngIf="editFormTooth?.dirty && editFormTooth?.errors?.required">
          Must specify a tooth.
        </span>

        <span *ngIf="editFormTooth?.errors?.requireSingleLetter">
          <i class="fa fas fa-exclamation-triangle"></i> Tooth must be between 1 and 32.
        </span>


        <span *ngIf="editFormTooth?.errors?.invalidNumber">
          <i class="fa fas fa-exclamation-triangle"></i> Tooth number must be within 1 - 32.
        </span>

        <span *ngIf="editFormTooth?.errors?.noLeadingZeroes">
          <i class="fa fas fa-exclamation-triangle"></i> Tooth number cannot start with 0.
        </span>

        <span *ngIf="editFormTooth?.errors?.noNegativeValues">
          <i class="fa fas fa-exclamation-triangle"></i> Tooth value cannot be negative.
        </span>
      </div>
    </form>
  </div>
  <div class="edit-label-btns py-2">
    <button
      mat-flat-button
      class="save"
      color="primary"
      [ngClass]="{ disabled: !editForm.valid }"
      [disabled]="!editForm.valid || (isImageConfirmed$ | async)"
      (click)="onSaveClicked()"
    >
      Save
    </button>
    <button mat-stroked-button class="cancel"  (click)="onCancelClicked()">
      Cancel
    </button>

    <button
      *ngIf="showDeletButton"
      mat-stroked-button
      class="delete"
      [disabled]="isImageConfirmed$ | async"
      (click)="onDeleteClicked()"
    >
      Delete
    </button>
  </div>
</div>
