export enum SlideItemType {
  ViewReports,
  DentalAdvisor,
  AIPhotoScan,
  TreatmentVerification,
  TalkToDentist,
}

export interface OnboardingSlide {
  image: string;
  header: string;
  title: string;
  key: SlideItemType;
}
