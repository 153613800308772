import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { keepDefined } from "@kells/utils/observable/observable-operators";
import { FindingsByImage } from "@kells/interfaces/finding";
import { SubSink } from "subsink";
import { observeProperty } from "@kells/utils/angular";
import { KellsImageBase } from "@kells/interfaces/image";
import { DataAccessService } from "@kells/apis/data-access";
import { isDefined } from "@kells/utils/js";
import { ImageType } from "@kells/clinic-one/apis";
import { distinctUntilChanged, map } from "rxjs/operators";

// export const INITIAL_TO_MODERATE = ['#7b8ccc', '#918db7', '#a38ea1', '#b18f89', '#bb9175', '#c6925a', '#cf9440', '#d59620'];
// export const INITIAL_TO_ADVANCED = ['#7b8ccc', '#8a8ac2', '#9787b8', '#a285ae', '#ac82a5', '#b57f9b', '#bd7d91', '#c47a87', '#cb777e', '#d27474'];
// export const INITIAL_ONLY = ['#35557D', '#3A5B85', '#3F628E', '#456896', '#4B6F9E', '#5075A6', '#567CAF', '#5C83B7', '#6389BF', '#6990C7'];

// export const MODERATE_FROM_INITAL = ['#ddd1c8', '#f2d7a6'];
// export const MODERATE_ONLY = ['#90561E', '#995F1F', '#A36820', '#AC7121', '#B57A23', '#BE8324', '#C78D25', '#CF9726', '#D7A127', '#DFAB28'];

// export const ADVANCED = ['#741413', '#831F1A', '#922A22', '#A0362B', '#AD4334', '#B9503F', '#C45E4A', '#CE6D56', '#D77C63', '#DF8B71'];

@Component({
  selector: "koa-xray-tab",
  templateUrl: "./xray-tab.component.html",
  styleUrls: ["./xray-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XRayTabComponent implements OnInit {
  private _subs = new SubSink();

  images$ = new BehaviorSubject<Map<string, FindingsByImage>>(
    new Map<string, FindingsByImage>()
  );

  @Input() findingsByImage: FindingsByImage[];

  private findingsByImage$: Observable<FindingsByImage[]> = observeProperty(
    this,
    "findingsByImage"
  ).pipe(keepDefined(), distinctUntilChanged());

  hostWidth: string;
  hostHeight: string;

  cavityXrayStartIndex = 0;
  cavityXrayEndIndex = 4;

  constructor(
    public data: DataAccessService,
    private hostElement: ElementRef
  ) {}

  @Input("filterTooth") filterTooth: string;

  @Input("xrays") xrays: string[];
  xrays$: Observable<string[]> = observeProperty(this, "xrays").pipe(
    keepDefined()
  );
  @Input("imageType") imageType: ImageType = ImageType.Xray;

  @Output("onXrayOpen") xrayOpenEvent = new EventEmitter<void>();

  public readonly imagesLength$ = this.images$.pipe(
    map((images) => images.size)
  );

  ngOnInit() {
    const styles = getComputedStyle(this.hostElement.nativeElement);
    this.hostWidth = styles.width;
    this.hostHeight = styles.height;
    this._subs.sink = this.findingsByImage$.subscribe((findingsByImage) => {
      this._subs.sink = this.xrays$.subscribe((xrays: string[]) => {
        const images = new Map<string, FindingsByImage>();
        xrays.map((url: string) => {
          const image = findingsByImage.find(
            (image) => image.image.url === url
          );
          if (image?.imageType !== this.imageType) return;
          if (isDefined(image)) {
            images.set(url, image);
          }
        });
        this.images$.next(images);
      });
    });
  }

  prevXRays = () => {
    const len = this.images$.value.size;
    if (0 < this.cavityXrayStartIndex) {
      this.cavityXrayEndIndex = Math.min(this.cavityXrayEndIndex - 4, len);
      this.cavityXrayStartIndex = Math.max(this.cavityXrayStartIndex - 4, 0);
    }
  };

  nextXRays = () => {
    const len = this.images$.value.size;

    if (len > this.cavityXrayEndIndex) {
      this.cavityXrayEndIndex = Math.min(this.cavityXrayEndIndex + 4, len);
      this.cavityXrayStartIndex = Math.max(this.cavityXrayStartIndex + 4, 0);
    }
  };
  trackImageIdentityByUrl(index: number, item: KellsImageBase) {
    return item.url;
  }
}
