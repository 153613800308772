<button
  [disabled]="isLoading"
  [ngClass]="{
    'report-button primary-btn': true,
    'report-button-sm': sm,
    'report-button-xs': xs,
    'loading': isLoading
  }"
>
  <ng-content *ngIf="!isLoading" select="[icon]"></ng-content>
  <i *ngIf="isLoading" class="fas fa-circle-notch fa-spin"></i>

  <span class="btn-text">
    <ng-content></ng-content>
  </span>
</button>
