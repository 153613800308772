import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as UserApiActions from "./user-api.actions";
import { KellsUser } from "../models/user.model";

export const userFeatureKey = "user";

export type UserState = EntityState<KellsUser>;

export const adapter = createEntityAdapter<KellsUser>();

export const initialState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(UserApiActions.getUserSuccess, (state, { user }) => {
    return adapter.upsertOne(user, state);
  }),
  on(UserApiActions.turnOffAutoEnhanceSuccess, (state, { loggedInUserId }) => {
    const loggedInUser = state.entities[loggedInUserId];
    if (!loggedInUser) return state;

    return adapter.updateOne(
      {
        id: loggedInUserId,
        changes: {
          settings: { ...loggedInUser.settings, autoEnhance: false },
        },
      },
      state
    );
  }),
  on(UserApiActions.turnOnAutoEnhanceSuccess, (state, { loggedInUserId }) => {
    const loggedInUser = state.entities[loggedInUserId];
    if (!loggedInUser) return state;

    return adapter.updateOne(
      {
        id: loggedInUserId,
        changes: {
          settings: { ...loggedInUser.settings, autoEnhance: true },
        },
      },
      state
    );
  }),
  on(
    UserApiActions.updateCariesDetectionThresholdPreferenceSuccess,
    (state, { loggedInUserId, thresholdKey }) => {
      const loggedInUser = state.entities[loggedInUserId];
      if (!loggedInUser) return state;

      return adapter.updateOne(
        {
          id: loggedInUserId,
          changes: {
            settings: {
              ...loggedInUser.settings,
              cariesDetection: {
                ...loggedInUser.settings?.cariesDetection,
                selected: thresholdKey,
              },
            },
          },
        },
        state
      );
    }
  ),
  on(
    UserApiActions.updateCariesDetectionThresholdValuesSuccess,
    (state, { loggedInUserId, thresholds }) => {
      const loggedInUser = state.entities[loggedInUserId];
      if (!loggedInUser) return state;

      return adapter.updateOne(
        {
          id: loggedInUserId,
          changes: {
            settings: {
              ...loggedInUser.settings,
              cariesDetection: {
                ...loggedInUser.settings?.cariesDetection,
                thresholds,
              },
            },
          },
        },
        state
      );
    }
  )
);
