import { Component, Input } from "@angular/core";
import { observeProperty } from "@kells/utils/angular";
import {
  distinctUntilDeepValueChanged,
  keepDefined,
} from "@kells/utils/observable/observable-operators";

import { interval, Observable } from "rxjs";
import { startWith, timeInterval, delay } from "rxjs/operators";
import { Portals } from "../../enums/query.enums";

@Component({
  selector: "kpr-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  Portals = Portals;

  @Input() portal: Portals;
  @Input("loading") loading: boolean;
  loading$: Observable<boolean> = observeProperty(this, "loading")
    .pipe(keepDefined(), distinctUntilDeepValueChanged())
    .pipe(delay(1000), startWith(true));

  public readonly currentYear = new Date().getFullYear();
}
