import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PatientService } from "@kells/clinic-one/apis";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  ReviewJob,
  ReviewJobResponse,
  ReviewJobSurvey,
  ReviewJobSurveyResponse,
  ReviewJobWithPatient,
  ReviewJobWithPatientResponse,
  SubmitReviewJobSurveyRarams,
  UpdateSessionParams,
} from "../models/review-job.model";
import { formatReviewJob } from "./formatters";

interface GetAllReviewJobsResponse {
  message: string;
  data: ReviewJobWithPatientResponse[];
}

interface GetAllPatientReviewJobsResponse {
  message: string;
  data: ReviewJobResponse[];
}

@Injectable({
  providedIn: "root",
})
export class ReviewJobService {
  private readonly apiEndpoint = (resource = "") => {
    const baseUrl = `${this.baseUrl}/${this.apiVersion}/sessions`;
    if (!resource) return baseUrl;
    return `${baseUrl}/${resource}`;
  };

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient,
    private date: DatePipe
  ) {}

  getPatientReviewJobs(patientId: string): Observable<ReviewJob[]> {
    return this.http
      .get<GetAllPatientReviewJobsResponse>(
        `${this.baseUrl}/${this.apiVersion}/patients/${patientId}/sessions`
      )
      .pipe(
        map((resp) => resp.data),
        map((reviewJobs) => reviewJobs.map((job) => this.toReviewJob(job)))
      );
  }

  getAll(): Observable<ReviewJobWithPatient[]> {
    return this.http.get<GetAllReviewJobsResponse>(this.apiEndpoint()).pipe(
      map((resp) => resp.data),
      map((reviewJobs) => reviewJobs.map((r) => this.toReviewJobWithPatient(r)))
    );
  }

  markOneAsComplete(reviewJobId: string) {
    return this.http.put(this.apiEndpoint(`${reviewJobId}/complete`), null);
  }

  public updateReviewJob(reviewJobId: string, params: UpdateSessionParams) {
    const data: UpdateSessionParams = {
      provider: params.provider || undefined,
      screener: params.screener || undefined,
      organization_code: params.organization_code || undefined,
      event_code: params.event_code || undefined,
    };

    return this.http.patch(this.apiEndpoint(`${reviewJobId}`), data);
  }

  public getReviewJobSurvey(
    templateName = "photo_scan"
  ): Observable<ReviewJobSurvey> {
    return this.http
      .get<ReviewJobSurveyResponse>(this.apiEndpoint(`survey/${templateName}`))
      .pipe(map((resp) => resp.data));
  }

  public submitReviewJobSurveyAnswers(
    sessionId: string,
    body: SubmitReviewJobSurveyRarams
  ): Observable<void> {
    return this.http.post<void>(this.apiEndpoint(`${sessionId}/answers`), body);
  }

  private toReviewJob(reviewJob: ReviewJobResponse): ReviewJob {
    //Was made static for use as a utility function in tests without
    //setting up all the mock http service dependencies for this class
    return formatReviewJob(reviewJob);
  }

  private toReviewJobWithPatient(
    reviewJob: ReviewJobWithPatientResponse
  ): ReviewJobWithPatient {
    const r = reviewJob;

    return {
      id: r._id,
      completed: r.completed,
      sessionDate: this.date.transform(r.session_date, "yyyy-M-d") ?? "",
      patientId: r.patient._id,
      patient: PatientService.parsePatientResponse(r.patient),
      eventCode: r.event_code,
      organizationCode: r.organization_code,
      organization: r.organization,
      provider_details: r.provider_details,
      risk_levels: r.risk_levels,
    };
  }
}
