import { Component, Inject, OnInit } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService } from "@kells/apis/analytics";
import { PatientService } from "@kells/clinic-one/apis";
import { KellsPatient } from "@kells/interfaces/patient";
import { InfoCollectionOpenedEvent } from "apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { format } from "date-fns";

@Component({
  selector: "kpr-essential-patient-info-modal",
  templateUrl: "./essential-patient-info-modal.component.html",
  styleUrls: ["./essential-patient-info-modal.component.scss"],
})
export class EssentialPatientInfoModalComponent implements OnInit {
  form: FormGroup = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    birthday: ["", Validators.required],
    gender: ["", Validators.required],
    phone: ["", Validators.required],
    state: ["", Validators.required],
    zipcode: ["", Validators.required],
    has_insurance: [false, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private patientService: PatientService,
    @Inject(MAT_DIALOG_DATA) public data: KellsPatient,
    public dialogRef: MatDialogRef<EssentialPatientInfoModalComponent>,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.analyticsService.record(InfoCollectionOpenedEvent({}));

    this.form.patchValue({
      ...this.data,
      has_insurance: this.data.has_insurance ?? false,
      gender: this.data.gender === "unknown" ? "" : this.data.gender,
    });
    if (!this.data.phone) this.form.patchValue({ phone: "" });
  }

  private _updateValueAndValidity() {
    for (const controlName in this.form.controls) {
      this.form.controls[controlName].updateValueAndValidity();
    }
  }

  onSubmit() {
    this._updateValueAndValidity();
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.patientService
      .updatePatient({
        ...this.form.value,
        id: this.data.id,
        dob: format(new Date(this.form.value.birthday), "yyyy-MM-dd"),
        first_name: this.form.value.firstName,
        last_name: this.form.value.lastName,
        phone: this.form.value.phone?.toString() ?? "",
        has_insurance: this.form.value.has_insurance ?? false,
      })
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
