import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  HostListener,
  HostBinding,
  Input,
  EventEmitter,
  Output,
  Attribute,
} from "@angular/core";
import { observeProperty } from "@kells/utils/angular";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { SubSink } from "subsink";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import { isDefined } from "@kells/utils/js";
import { FindingType, FindingsByTooth } from "@kells/interfaces/finding";
import {
  TREATMENT_PRIORITY_BY_TYPE,
  TreatmentTypes,
} from "@kells/clinic-one/apis";

export const FINDINGS = {
  CARIES_INITIAL: "caries-initial-finding",
  CARIES_MODIFIED: "caries-moderate-finding",
  CARIES_ADVANCED: "caries-advanced-finding",
  NONE: "tooth finding-none",
};

@Directive({
  selector: "[tooth]",
  inputs: ["byTooth", "id"],
})
export class ToothDirective {
  @Output("click")
  click: EventEmitter<FindingsByTooth> = new EventEmitter<FindingsByTooth>();

  constructor(
    @Attribute("data-tooth") public name: string,
    public hostElement: ElementRef,
    private renderer: Renderer2
  ) {
    this.activateTooth = this.activateTooth.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  isFilteredOut = false;

  // @HostBinding('class')

  private _subs = new SubSink();
  toothId: string;

  private _findings: FindingsByTooth;
  private _hasCaries = false;

  public get findings(): FindingsByTooth {
    return this._findings!;
  }

  public set findings(value: FindingsByTooth) {
    this._findings = value;
    this.initStateFromFindings();
  }

  public makeInactive(): void {
    requestAnimationFrame(() => {
      this.removeSelectionClasses();
    });
  }

  public removeSelectionClasses(): void {
    const { hostElement } = this;
    hostElement.nativeElement.classList.remove("caries-advanced-finding");
    hostElement.nativeElement.classList.remove("caries-moderate-finding");
    hostElement.nativeElement.classList.remove("caries-initial-finding");
    hostElement.nativeElement.classList.remove("selected");
    hostElement.nativeElement.classList.add("default-no-finding");
  }

  private _activeTooth$: BehaviorSubject<FindingsByTooth>;
  public get activeTooth(): BehaviorSubject<FindingsByTooth> {
    return this._activeTooth$;
  }

  public set activeTooth$(value$: BehaviorSubject<FindingsByTooth>) {
    this._activeTooth$ = value$;
    this._subs.sink = this._activeTooth$.subscribe((val: FindingsByTooth) => {
      if (val.tooth_id === this.findings.tooth_id) {
        this.hostElement.nativeElement.classList.add("selected");
      } else {
        this.hostElement.nativeElement.blur();
        this.hostElement.nativeElement.classList.remove("selected");
      }
    });
  }

  private _clickHandler: (() => void) | null = null;
  public set onClick(handler: () => void) {
    this._clickHandler = handler;
  }

  activateTooth() {
    if (
      isDefined(this._activeTooth$) &&
      isDefined(this.findings) &&
      !this.isFilteredOut
    ) {
      this._activeTooth$.next(this.findings);
    }
  }

  handleClick() {
    if (this.isFilteredOut) return;
    this.activateTooth();

    if (this._clickHandler) {
      this._clickHandler();
    }
  }

  get isAdvancedFindingType(): boolean {
    const advancedFindingTypes = [
      FindingType.Plaque,
      FindingType.GumRecession,
      FindingType.GumInflammation,
      FindingType.MissingTooth,
      FindingType.Fracture,
      FindingType.Calculus,
      FindingType.Infection,
      FindingType.DefectiveRestoration,
    ];

    return this.findings.allFindings.some((finding) =>
      advancedFindingTypes.includes(finding.type)
    );
  }

  get mostAdvancedTreatmentPriority(): number {
    let highestPriority = 0;
    this.findings.treatments.forEach((treatment) => {
      if (treatment.session !== this.findings.sessionId) {
        return;
      }
      const treatmentPriority: number =
        TREATMENT_PRIORITY_BY_TYPE[treatment.description as TreatmentTypes] ??
        0;

      highestPriority =
        highestPriority < treatmentPriority
          ? treatmentPriority
          : highestPriority;
    });
    return highestPriority;
  }

  initStateFromFindings(): void {
    const { findings, hostElement } = this;

    if (findings.totalCaries === 0 && !this.isAdvancedFindingType) {
      hostElement.nativeElement.classList.add("default-no-finding");
      this._hasCaries = false;
    } else if (
      findings.qtyAdvanced > 0 ||
      this.mostAdvancedTreatmentPriority === 2
    ) {
      hostElement.nativeElement.classList.add("caries-advanced-finding");
      this._hasCaries = true;
    } else if (
      findings.qtyModerate > 0 ||
      this.mostAdvancedTreatmentPriority === 1
    ) {
      hostElement.nativeElement.classList.add("caries-moderate-finding");
      this._hasCaries = true;
    } else if (
      findings.qtyInitial > 0 ||
      this.mostAdvancedTreatmentPriority === 0
    ) {
      hostElement.nativeElement.classList.add("caries-initial-finding");
      this._hasCaries = true;
    }

    if (this._hasCaries) {
      this.renderer.setAttribute(
        this.hostElement.nativeElement,
        "tabindex",
        "0"
      );
      hostElement.nativeElement.classList.remove("default-no-finding");
    }
  }

  ngOnInit() {
    const idAttr = this.hostElement.nativeElement.getAttribute("id");
    if (idAttr) {
      this.toothId = idAttr.split("-")[1];
    }

    this.hostElement.nativeElement.addEventListener("click", this.handleClick);
  }
}
