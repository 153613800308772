import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  HostListener,
  HostBinding,
  Input,
  EventEmitter,
  Output,
  Attribute
} from '@angular/core';
import { observeProperty } from '@kells/utils/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { keepDefined } from '@kells/utils/observable/observable-operators';
import { isDefined } from '@kells/utils/js';

@Directive({
  selector: '[interactive]'

})

export class SvgInteractiveDirective {
  private _subs = new SubSink();

  // @Output('click') click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  // @Output('mouseover') mouseover: EventEmitter<any> = new EventEmitter<any>();
  // @Output('mouseout') mouseout: EventEmitter<any> = new EventEmitter<any>();
  // @Output('mouseleave') mouseleave: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    @Attribute('data-tooth') public name: string,

    public hostElement: ElementRef,
    private renderer: Renderer2) {
  }

  ngOnInit() {
    this.renderer.setAttribute(this.hostElement.nativeElement, 'tabindex', '0');

    // this.hostElement.nativeElement.addEventListener('click', this.activateTooth);

  }

  @HostListener('click', ['event'])
  hostClicked = (event:MouseEvent) => {
    console.info('svg clicked', event);
  }
}
