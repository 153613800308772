import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  CanLoad,
  Route,
  UrlSegment,
  CanActivateChild,
} from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import {
  Portals,
  QueryParameters,
} from "apps/patient-reporting/src/app/shared/enums/query.enums";
import { getQueryParamsFromLink } from "apps/patient-reporting/src/app/shared/functions/get-query.functions";
import { SharedService } from "apps/patient-reporting/src/app/shared/shared.service";
import { Observable } from "rxjs";
import { tap, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private auth: AuthService,
    private sharedService: SharedService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(take(1));
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  private redirectIfUnauthenticated(
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          const queryPortal = getQueryParamsFromLink(window.location.href)?.[
            QueryParameters.Portal
          ] as Portals;
          this.sharedService.localStoragePortal =
            queryPortal || this.sharedService.localStoragePortal;
          this.auth.loginWithRedirect({
            screen_hint: "signup",
            appState: { target: state.url },
          });
        }
      })
    );
  }
}
