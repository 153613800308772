import { ClassificationSuccessResult } from "@kells/clinic-one/apis";
import { createAction, props } from "@ngrx/store";

enum ActionTypes {
  ClassificationStart = "[Classification] Start",

  ClassifyImage = "[Classification/API] Classify Image",
  ClassifyImageSuccess = "[Classification/API] Classify Image Success",
  ClassifyImageFailure = "[Classification/API] Classify Image Failure",

  ClassificationFinalized = "[Classification] Finalized",

  GetVersionNumber = "[Classification/API] Get Version Number",
  GetVersionNumberSuccess = "[Classification/API] Get Version Number Success",
  GetVersionNumberFailure = "[Classification/API] Get Version Number Failure",
}

export const classificationStart = createAction(
  ActionTypes.ClassificationStart,
  props<{ imageIds: string[] }>()
);

export const classifyImage = createAction(
  ActionTypes.ClassifyImage,
  props<{ imageId: string }>()
);

export const classifyImageSuccess = createAction(
  ActionTypes.ClassifyImageSuccess,
  props<ClassificationSuccessResult>()
);

export const classifyImageFailure = createAction(
  ActionTypes.ClassifyImageFailure,
  props<{ imageId: string; error: any }>()
);

export const classificationFinalized = createAction(
  ActionTypes.ClassificationFinalized
);

export const getVersionNumber = createAction(ActionTypes.GetVersionNumber);

export const getVersionNumberSuccess = createAction(
  ActionTypes.GetVersionNumberSuccess,
  props<{ versionNumber: string }>()
);

export const getVersionNumberFailure = createAction(
  ActionTypes.GetVersionNumberFailure,
  props<{ error?: any }>()
);

export const errorActions = [classifyImageFailure, getVersionNumberFailure];
