
export const INITIAL_TO_MODERATE = ['#7b8ccc', '#918db7', '#a38ea1', '#b18f89', '#bb9175', '#c6925a', '#cf9440', '#d59620'];
export const INITIAL_TO_ADVANCED = ['#7b8ccc', '#8a8ac2', '#9787b8', '#a285ae', '#ac82a5', '#b57f9b', '#bd7d91', '#c47a87', '#cb777e', '#d27474'];
export const INITIAL_ONLY = ['#35557D', '#3A5B85', '#3F628E', '#456896', '#4B6F9E', '#5075A6', '#567CAF', '#5C83B7', '#6389BF', '#6990C7'];

export const MODERATE_FROM_INITAL = ['#ddd1c8', '#f2d7a6'];
export const MODERATE_ONLY = ['#90561E',
'#995F1F',
'#A36820',
'#AC7121',
 '#B57A23',
 '#BE8324',
  '#C78D25',
  '#CF9726',
  '#D7A127',
   '#DFAB28',
   '#E1A532'];

export const ADVANCED = [
  '#741413',
'#831F1A',
'#922A22',
'#A0362B',
'#AD4334',
'#B9503F',
 '#C45E4A',
 '#CE6D56',
 '#D77C63',
 '#DF8B71'
];

export const cariesInitialFocus = [
    '#464846',
    '#565956',
    '#686B68',
    '#7A7E7A',
    '#8C918C',
    '#9FA59F',
    '#B2B9B3',
    '#C6CDC7',
    '#DAE2DB',
    '#EEF7EF'];




export const  cariesInitialFocus_altBright= [ '#3A363B', '#4F494F', '#645C64', '#7B717A', '#938691', '#AB9BA8', '#C5B1C0', '#DFC8D9', '#FADFF2'];


  //altDark= [ '#C6C0C8', '#B5AEB5', '#A49DA4', '#938D92', '#827C81', '#716C71', '#615D61', '#524D51', '#433F42'];

export const  cariesInitialFocus_altDark= [ '#FCF5FF', '#ECE4ED', '#DBD4DC', '#CAC3CB', '#BAB3BA', '#AAA3AA', '#9A949A', '#8A848A', '#7B757A'];


export const  cariesInitial = [
  '#1F1C17',
'#322D26',
  '#464037',
'#5B5348',
  '#71685A',
'#887D6C',
  '#A09380',
'#B8A993',
  '#D1C0A8',
'#EAD8BC'];


export const  cariesInitial_altDark= [ '#EBF1DE', '#ffffffee', '#B8BBAD', '#9FA295', '#87897D', '#6F7167', '#585951', '#43433D', '#2E2E29'];

export const  cariesInitial_altBright= [ '#3A363B', '#4F494F', '#645C64', '#7B717A', '#938691', '#AB9BA8', '#C5B1C0', '#DFC8D9', '#FADFF2'];



export const  cariesModerateActive = [
    '#5B481F',
    '#6B5523',
    '#7B6226',
    '#8C6F29',
    '#9E7C2A',
    '#AF8A2B',
    '#C2982A',
    '#D4A628',
    '#E8B424',
    '#FBC31C'];


export const  cariesModerateActive_altDark= [ '#F4A501', '#DB9406', '#C28308', '#AA7209', '#926208', '#7B5207', '#654306', '#503404'];

export const  cariesModerateActive_altBright= [ '#382B12', '#4B3C1B', '#5F4F25', '#72622F', '#857739', '#988C45', '#AAA351', '#BCBA5E', '#CDD26C', '#DDEA7B'];


export const  cariesModerateFocus = [
    '#3A3120',
    '#473D26',
    '#56482C',
    '#655430',
    '#746134',
    '#846D37',
    '#947A39',
    '#A5873A',
    '#B6953A',
    '#C7A239',
];


export const  cariesModerateFocus_altDark= [ '#EACA2E', '#CFB326', '#B59D1F', '#9C8718', '#847112', '#6C5D0C', '#554907', '#3F3604'];

export const  cariesModerateFocus_altBright= [ '#60501C', '#72601F', '#847022', '#988024', '#AB9125', '#BFA224', '#D4B322', '#E9C51C', '#FED70E'];


export const  cariesModerate = [
    '#281904',
    '#3F2C10',
    '#574019',
    '#6F5623',
    '#866D2D',
    '#9E8637',
    '#B4A043',
    '#CABB4F',
    '#DFD75C',
    '#F3F46B',
  /** http://tristen.ca/hcl-picker/#/clh/8/68/574D10/E8D020 */

];


export const  cariesModerate_altBright= [ '#574D10', '#6A5E14', '#7D7018', '#92821B', '#A6951E', '#BCA81F', '#D2BC20', '#E8D020'];

export const  cariesModerate_altDark= [ '#FFE817', '#EAD426', '#D5C12D', '#C0AD31', '#AC9B31', '#988830', '#84772E', '#71652A', '#5F5425'];


export const  cariesAdvanced = [
    '#57231A',
    '#692D23',
    '#7A392C',
    '#8C4536',
    '#9D5341',
    '#AF604D',
    '#BF6F59',
    '#CF7D66',
    '#DF8D73',
    '#EE9D81'
];


export const  cariesAdvanced_altDark= [ '#F84447', '#F5436F', '#E55191', '#C964AC', '#A576BC', '#7E83C0', '#598CB9', '#4192AA'];

export const  cariesAdvanced_altBright= [ '#3C0B05', '#50100D', '#661412', '#7C1817', '#941B1C', '#AD1D20', '#C71D25', '#E21A29', '#FE132D'];


export const  cariesAdvancedFocus = [
    '#520203',
    '#690E0E',
    '#7F1E18',
    '#952E24',
    '#A84031',
    '#BB5340',
    '#CB6751',
    '#D97C63',
    '#E59277',
    '#EDAA8D'
];

export const cariesAdvancedFocus_altDark= [ '#4B0001', '#560106', '#62020A', '#6D030D', '#790510', '#850713', '#920A16', '#9E0D19'];

export const  cariesAdvancedFocus_altBright= [ '#F5A387', '#F18F74', '#EB7B63', '#E26853', '#D85645', '#CB4337', '#BD312B', '#AE1E21', '#9E0617'];


export const tealGray = [
'#464651',
'#515b62',
'#5c6f73',
'#688584',
'#769a96',
'#85b0a7',
'#97c6b9',
'#addbc9',
'#caf0d8',
'#ffffe0'
];


export const  tealGray_altDark= [ '#34AEC2', '#3AACD0', '#50A8DD', '#6CA3E6', '#8C9BEB', '#AC91EA', '#CC84E4', '#E975D8'];


export const    tealGray_altBright= [ '#3B2F6B', '#40387B', '#44418B', '#474A9C', '#4953AD', '#495DBE', '#4768CF', '#4272E1', '#397DF3'];


export const  pinkGray = [
  '#1b1b24',
  '#332f38',
  '#4d444c',
  '#675b61',
  '#837277',
  '#9e8b8d',
  '#baa5a4',
  '#d5c1ba',
  '#efdecf',
  '#ffffe0'
];


export const  pinkGray_altDark= [ '#EC8A9E', '#D8788F', '#C56880', '#B05871', '#9C4962', '#873B53', '#722E45', '#5D2237'];


export const    pinkGray_altVivid= [ '#F6128C', '#DD227F', '#C52872', '#AE2A65', '#982A59', '#84294D', '#702642', '#5D2237'];


export const  pinkGray_altBright= [ '#631D39', '#792847', '#8F3456', '#A54265', '#BB5074', '#D16084', '#E67093', '#FB81A3'];
export const  slateRose = [
  '#65656e',
  '#7d707b',
  '#947b88',
  '#a98796',
  '#bd95a3',
  '#cfa3b1',
  '#deb3c0',
  '#eac4ce',
  '#f0d7de',
  '#ededed',
];


export const  slateRose_altDark = [ '#90505E', '#804B55', '#71454B', '#624043', '#553A3A', '#483332', '#3C2D2A', '#312723'];


export const  slateRose_altVivid = [ '#EB1E87', '#D12C79', '#B8316C', '#A0335F', '#8A3353', '#753047', '#622C3C', '#502732'];


export const   slateRose_altBright = [ '#E17191', '#C86681', '#AF5A71', '#974E61', '#814352', '#6B3844', '#562C36', '#422229'];



export const  neon_blue1 = [ '#310872', '#3B0A84', '#460B96', '#510CA8', '#5D0BBB', '#6A0ACE', '#7707E2', '#8502F5'];

export const neon_blue2 = [ '#0B2262', '#132B76', '#1C358B', '#253FA0', '#2F49B5', '#3A54CA', '#455FDF', '#516AF3'];

export const neon_blue3 = [ '#2381F7', '#2374DE', '#2267C5', '#1F5AAD', '#1C4E96', '#194280', '#15366A', '#102B56'];

export const neon_indigo = [ '#F75555', '#E44765', '#CA4171', '#AC4078', '#8B417A', '#694177', '#473E6E', '#253960'];

export const neon_pink = [ '#FE88B8', '#F175AC', '#E364A1', '#D45394', '#C44288', '#B3327B', '#A1236D', '#8F1360', '#7C0352'];

export const neon_violet = [ '#EAB3E4', '#DA99E6', '#C880E3', '#B468DC', '#9E51D0', '#883CC0', '#7129AC', '#5A1795', '#44077A'];

export const neon_greenDark = [ '#14F969', '#2AE15A', '#33C94B', '#36B23E', '#359B31', '#328526', '#2E701C', '#285C13'];

export const neon_green = [ '#175217', '#24691D', '#327F23', '#439727', '#55AF2B', '#68C82D', '#7DE02E', '#94FA2D'];

export const neon_yellow = [ '#6F6611', '#807514', '#918416', '#A29417', '#B3A518', '#C5B519', '#D7C618', '#EAD717', '#FCE913'];

export const neon_red = [ '#341D46', '#4F2052', '#6B205B', '#8A1D60', '#A81861', '#C5135E', '#E01657', '#F8264C'];

export const neon_crimson = [ '#560005', '#69000B', '#7C010F', '#900314', '#A40419', '#B9071D', '#CE0C22', '#E41027', '#FA152C'];

export const neon_orange = [ '#7D3702', '#8D4006', '#9D490B', '#AD5311', '#BD5D18', '#CD681F', '#DD7227', '#ED7D2F', '#FD8937'];


export const defaultBackground = [
  "#fafaf9",
  "#f5f5f4",
  "#e7e5e4",
  "#d6d3d1",
  "#a8a29e",
  "#78716c",
  "#57534e",
  "#44403c",
  "#292524",
  "#1c1917"
];


export const  black = "#000";
export const white =  "#fff";

export const rose = [
  "#fff1f2",
  "#ffe4e6",
  "#fecdd3",
  "#fda4af",
  "#fb7185",
  "#f43f5e",
  "#e11d48",
  "#be123c",
  "#9f1239",
  "#881337"
];


export const  pink = [
  "#fdf2f8",
"#fce7f3",
  "#fbcfe8",
"#f9a8d4",
  "#f472b6",
"#ec4899",
  "#db2777",
"#be185d",
  "#9d174d",
"#831843"
];


export const  fuchsia = [
  "#fdf4ff",
"#fae8ff",
  "#f5d0fe",
"#f0abfc",
  "#e879f9",
"#d946ef",
  "#c026d3",
"#a21caf",
  "#86198f",
"#701a75"
];


export const  purple = [
      "#faf5ff",
      "#f3e8ff",
      "#e9d5ff",
      "#d8b4fe",
      "#c084fc",
      "#a855f7",
      "#9333ea",
      "#7e22ce",
      "#6b21a8",
      "#581c87"
  ];


export const  violet = [
  "#f5f3ff",
"#ede9fe",
  "#ddd6fe",
"#c4b5fd",
  "#a78bfa",
"#8b5cf6",
  "#7c3aed",
"#6d28d9",
  "#5b21b6",
"#4c1d95"
];


export const  indigo = [
  "#eef2ff",
"#e0e7ff",
  "#c7d2fe",
"#a5b4fc",
  "#818cf8",
"#6366f1",
  "#4f46e5",
"#4338ca",
  "#3730a3",
"#312e81"
];


export const  blue = [
  "#eff6ff",
"#dbeafe",
  "#bfdbfe",
"#93c5fd",
  "#60a5fa",
"#3b82f6",
  "#2563eb",
"#1d4ed8",
  "#1e40af",
"#1e3a8a"
];


export const  lightBlue = [
      "#f0f9ff",
      "#e0f2fe",
      "#bae6fd",
      "#7dd3fc",
      "#38bdf8",
      "#0ea5e9",
      "#0284c7",
      "#0369a1",
      "#075985",
      "#0c4a6e"
];
export const adaptiveWhite =  [
    '#fefefe',
        '#959595',
    '#767676',
    '#686868',
    '#5d5d5d',
    '#545454'

];
export const adaptiveWhiteToSamon = [
  '#fff',
  '#b18d82',
  '#8b7168','#7a645d','#6d5953','#62514c','#594945'
];
export const adaptiveTealSecondaryText = [
  "#D6FFF5",
'#aab0bf',
'#858fa6',
'#6e7a97',
'#5c6a8c',
'#4f5d84',
'#43537e',
'#3a4a79',
'#fff',
'#8594be',
'#6375ab',
'#596794',
'#505d82',
'#495473',
'#434c67'
];

export const  sky = [
  "#f0f9ff",
  "#e0f2fe",
  "#bae6fd",
  "#7dd3fc",
  "#38bdf8",
  "#0ea5e9",
  "#0284c7",
  "#0369a1",
  "#075985",
  "#0c4a6e"
];


export const  cyan = [
  "#ecfeff",
  "#cffafe",
  "#a5f3fc",
  "#67e8f9",
  "#22d3ee",
  "#06b6d4",
  "#0891b2",
  "#0e7490",
  "#155e75",
  "#164e63"
];


export const  teal = [
  "#f0fdfa",
  "#ccfbf1",
  "#99f6e4",
  "#5eead4",
  "#2dd4bf",
  "#14b8a6",
  "#0d9488",
  "#0f766e",
  "#115e59",
  "#134e4a"
];


export const  emerald = [
    "#ecfdf5",
    "#d1fae5",
    "#a7f3d0",
    "#6ee7b7",
    "#34d399",
    "#10b981",
    "#059669",
    "#047857",
    "#065f46",
    "#064e3b"
];


export const  green = [
  "#f0fdf4",
  "#dcfce7",
  "#bbf7d0",
  "#86efac",
  "#4ade80",
  "#22c55e",
  "#16a34a",
  "#15803d",
  "#166534",
  "#14532d"
];


export const  lime = [
  "#f7fee7",
  "#ecfccb",
  "#d9f99d",
  "#bef264",
  "#a3e635",
  "#84cc16",
  "#65a30d",
  "#4d7c0f",
  "#3f6212",
  "#365314"
];


export const  yellow = [
  "#fefce8",
  "#fef9c3",
  "#fef08a",
  "#fde047",
  "#facc15",
  "#eab308",
  "#ca8a04",
  "#a16207",
  "#854d0e",
  "#713f12"
];


export const  amber = [
  "#fffbeb",
  "#fef3c7",
  "#fde68a",
  "#fcd34d",
  "#fbbf24",
  "#f59e0b",
  "#d97706",
  "#b45309",
  "#92400e",
  "#78350f"
];


export const  orange = [
  "#fff7ed",
  "#ffedd5",
  "#fed7aa",
  "#fdba74",
  "#fb923c",
  "#f97316",
  "#ea580c",
  "#c2410c",
  "#9a3412",
  "#7c2d12"
];


export const  red = [
  "#fef2f2",
  "#fee2e2",
  "#fecaca",
  "#fca5a5",
  "#f87171",
  "#ef4444",
  "#dc2626",
  "#b91c1c",
  "#991b1b",
  "#7f1d1d"
];


export const  warmGray = [
  "#fafaf9",
  "#f5f5f4",
  "#e7e5e4",
  "#d6d3d1",
  "#a8a29e",
  "#78716c",
  "#57534e",
  "#44403c",
  "#292524",
  "#1c1917"
];


export const  trueGray = [
  "#fafafa",
  "#f5f5f5",
  "#e5e5e5",
  "#d4d4d4",
  "#a3a3a3",
  "#737373",
  "#525252",
  "#404040",
  "#262626",
  "#171717"
];


export const  gray = [
  "#fafafa",
  "#f4f4f5",
  "#e4e4e7",
  "#d4d4d8",
  "#a1a1aa",
  "#71717a",
  "#52525b",
  "#3f3f46",
  "#27272a",
  "#18181b"
];


export const  coolGray = [
  "#f9fafb",
  "#f3f4f6",
  "#e5e7eb",
  "#d1d5db",
  "#9ca3af",
  "#6b7280",
  "#4b5563",
  "#374151",
  "#1f2937",
  "#111827"
];


export const  blueGray = [
  "#f8fafc",
  "#f1f5f9",
  "#e2e8f0",
  "#cbd5e1",
  "#94a3b8",
  "#64748b",
  "#475569",
  "#334155",
  "#1e293b",
  "#0f172a"
];
