/* eslint-disable class-methods-use-this */
import { Injectable } from "@angular/core";
import { AnalyticsTrackEvent } from "../models/analytics/analytics.model";

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  _userID: string;
  _userInfo: Record<string, any>;

  /**
   * Once called, subsequent events tracked will be associated with the user
   * identified here.
   */
  identifyUser(userID: string, userInfo: Record<string, any> = {}) {
    this._userID = userID;
    this._userInfo = userInfo;
    if (!(window as any).analytics) return;
    (window as any).analytics.identify(userID, userInfo);
  }

  /**
   * Records an event.
   *
   * Calls Segment's track API to record a user-triggered event.
   */
  record<T>(event: AnalyticsTrackEvent<T>) {
    if (!(window as any).analytics) return;
    (window as any).analytics.track(
      event.type,
      this._appendUserIDToEvent(event.detail)
    );
  }

  /**
   * Append user-identifiable information to the event, if possible.
   *
   * Google Analytics does not retain user-identifiable information posted
   * in the `identifyUser` function call. To make user IDs visible on GA,
   * it should be stored as a property of the recorded event.
   *
   * @param eventDetail of type `T` in `AnalyticsTrackEvent<T>`
   */
  _appendUserIDToEvent<T>(eventDetail: T): (T & { category: string }) | T {
    if (this._userID || this._userInfo) {
      // On Google Analytics, events can be grouped by categories.
      // Assigning user ID to key 'category' allows us to analyze an
      // individual user's events.
      return { ...eventDetail, category: this._userID, ...this._userInfo };
    }
    return eventDetail;
  }
}
