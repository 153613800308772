import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, ReplaySubject } from "rxjs";
import { fabric } from "fabric";
import { distinctUntilChanged, throttleTime } from "rxjs/operators";
import { takeLatest } from "@kells/utils/observable/observable-operators";

/**
 * `CanvasAdjustService` is responsible for enabling the adjust mode in canvas.
 *
 * In adjust mode, user will be able to change brightness and contrast by
 * dragging vertically and horizontally across canvas.
 *
 * @example
 * ```
 * class CanvasComponent {
 *   // inject the service
 *   constructor(private canvasAdjustService: CanvasAdjustService) {
 *     // ...
 *   }
 *
 *   private onAdjustmentModeEnabled() {
 *     // invoke the 'enable' method to enter in adjustment mode.
 *     // this would also exit any other canvas mode the canvas was previously in.
 *     this.canvasAdjustService.enable({ ... })
 *   }
 *
 *   private onAdjustmentModeDisabled() {
 *     // invoke the 'disable' method to exit adjust mode.
 *     this.canvasAdjustService.disable({ ... })
 *   }
 * }
 * ```
 */
@Injectable()
export class CanvasAdjustService {
  private readonly DEFAULT_BRIGHTNESS = 1;
  private readonly DEFAULT_CONTRAST = 1;

  /**
   * Stores the user's mouse location on mousedown events.
   *
   * Location is invalidated when a user releases their clicks
   * (i.e. on mouseup events). It is set to `undefined` then.
   */
  private mouseDownLocation$ = new BehaviorSubject<
    { x: number; y: number } | undefined
  >(undefined);

  private currentContrast$ = new BehaviorSubject<number>(this.DEFAULT_CONTRAST);

  private currentBrightness$ = new BehaviorSubject<number>(
    this.DEFAULT_BRIGHTNESS
  );

  /**
   * Stores the initial contrast value everytime a user starts dragging to
   * adjust contrast. This is used to calculate the contrast delta as user
   * continues moving the mouse.
   */
  private contrastBaseline$ = new BehaviorSubject<number>(
    this.DEFAULT_CONTRAST
  );

  /**
   * Stores the brightness value when the user starts dragging to adjust
   * image brightness. This is used to calculate the brightness delta as user
   * continues moving the mouse.
   */
  private brightnessBaseline$ = new BehaviorSubject<number>(
    this.DEFAULT_BRIGHTNESS
  );

  private isMouseDown$ = new BehaviorSubject<boolean>(false);

  /** Persists a reference to the latest canvas. */
  private canvas$ = new ReplaySubject<fabric.Canvas>();

  /** A decimal value indicating the current image contrast. */
  contrast$ = this.currentContrast$.asObservable().pipe(distinctUntilChanged());

  /** A decimal value indicating the current image brightness level. */
  brightness$ = this.currentBrightness$
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor() {
    combineLatest([this.contrast$, this.brightness$])
      .pipe(throttleTime(100))
      .subscribe(([contrast, brightness]) => {
        this.performImageAdjustments({ contrast, brightness });
      });

    this.adjustmentMouseDownHandler = this.adjustmentMouseDownHandler.bind(this);
    this.adjustmentMouseUpHandler = this.adjustmentMouseUpHandler.bind(this);
    this.adjustmentMouseMoveHandler = this.adjustmentMouseMoveHandler.bind(this);

  }

  /**
   * Enable canvas adjustment mode.
   *
   * @param canvas
   *    A fabric.Canvas instance, on which adjustment mode effects will be
   *    performed.
   *
   * @param args
   *    - contrastBaseline:
   *       the current contrast value on canvas, when entering adjustment mode.
   *    - brightnessBaseline:
   *       the current brightness value on canvas, when entering adjustment mode.
   */
  enable(
    canvas: fabric.Canvas,
    args: { contrastBaseline: number; brightnessBaseline: number }
  ) {
    this.canvas$.next(canvas);

    this.contrastBaseline$.next(args.contrastBaseline);
    this.brightnessBaseline$.next(args.brightnessBaseline);

    this.currentContrast$.next(args.contrastBaseline);
    this.currentBrightness$.next(args.brightnessBaseline);

    canvas.on("mouse:down", this.adjustmentMouseDownHandler);
    canvas.on("mouse:up", this.adjustmentMouseUpHandler);
    canvas.on("mouse:move", this.adjustmentMouseMoveHandler);
  }

  /**
   * Disable canvas adjustment mode.
   */
  disable() {
    this.canvas$.pipe(takeLatest()).subscribe((canvas) => {
      canvas.off("mouse:down", this.adjustmentMouseDownHandler);
      canvas.off("mouse:up", this.adjustmentMouseUpHandler);
      canvas.off("mouse:move", this.adjustmentMouseMoveHandler);
    });
  }

  private adjustmentMouseDownHandler = (option: any) => {
    const event = option.e;

    this.isMouseDown$.next(true);

    this.mouseDownLocation$.next({
      x: event.clientX,
      y: event.clientY,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private adjustmentMouseUpHandler = (option: any) => {
    this.isMouseDown$.next(false);
    this.mouseDownLocation$.next(undefined);

    this.contrastBaseline$.next(this.currentContrast$.value);
    this.brightnessBaseline$.next(this.currentBrightness$.value);
  };

  private adjustmentMouseMoveHandler = (option: any) => {
    if (!this.isMouseDown$.value) return;

    this.updateContrast(option);
    this.updateBrightness(option);
  };

  private updateContrast(option: any) {
    this.canvas$.pipe(takeLatest()).subscribe((canvas) => {
      const CONTRAST_ADJUSTMENT_SCALER = 0.5;
      const baseline = this.contrastBaseline$.value;

      const currY = option.e.clientY;
      const deltaY = (this.mouseDownLocation$.value?.y || 0) - currY;
      const deltaYPercentage = deltaY / canvas.getHeight();

      const newContrast =
        baseline + deltaYPercentage * CONTRAST_ADJUSTMENT_SCALER;

      this.currentContrast$.next(newContrast);
    });
  }

  private updateBrightness(option: any) {
    this.canvas$.pipe(takeLatest()).subscribe((canvas) => {
      const BRIGHTNESS_ADJUSTMENT_SCALER = 0.5;
      const baseline = this.brightnessBaseline$.value;

      const currX = option.e.clientX;
      const deltaX = currX - (this.mouseDownLocation$.value?.x || 0);
      const deltaXPercentage = deltaX / canvas.getWidth();

      const newBrightness =
        baseline + deltaXPercentage * BRIGHTNESS_ADJUSTMENT_SCALER;

      this.currentBrightness$.next(newBrightness);
    });
  }

  private performImageAdjustments(adjustments: {
    contrast: number;
    brightness: number;
  }) {
    this.canvas$.pipe(takeLatest()).subscribe((canvas) => {
      let { contrast, brightness } = adjustments;

      contrast -= 1;
      brightness -= 1;

      const bg = canvas.backgroundImage as fabric.Image;

      if (!bg) return;

      try {
        bg.filters = [];
        // this can potentially throw when bg has not been rendered yet.
        bg.applyFilters([
          new fabric.Image.filters.Contrast({ contrast }),
          new fabric.Image.filters.Brightness({ brightness }),
        ]);

        canvas.requestRenderAll();
      } catch (error) {
        console.error(error);
        return;
      }
    });
  }
}
