import { RecommendedProductFilter } from "../enums/recommended-product-filter.enum";
import { RecommendedProductTabs } from "../enums/recommended-product-tabs.enum";

export const recommendedProductsByCategory = {
  [RecommendedProductTabs.GeneralHygiene]: {
    [RecommendedProductFilter.OTC]: [
      {
        name: "Oral B smart brush",
        price: 1,
        link: "https://amzn.to/3AokZHQ",
        image: "assets/recommended-products/electric-toothbrush-oral-b.jpg",
      },
      {
        name: "Biotène Fluoride Toothpaste",
        price: 1,
        link: "https://amzn.to/4dtA9dw",
        image: "assets/recommended-products/fluoridated-toothpaste.jpg",
      },
      {
        name: "Cocofloss",
        price: 1,
        link: "https://amzn.to/3X6umof",
        image: "assets/recommended-products/cocofloss.jpg",
      },
    ],
    [RecommendedProductFilter.Prescriptions]: [],
  },
  [RecommendedProductTabs.Caries]: {
    [RecommendedProductFilter.OTC]: [
      {
        name: "Listerine Total Care",
        price: 1,
        link: "https://amzn.to/3AoUphD",
        image: "assets/recommended-products/listerine-total-care.jpg",
      },
    ],
    [RecommendedProductFilter.Prescriptions]: [],
  },
  [RecommendedProductTabs.GumInflammation]: {
    [RecommendedProductFilter.OTC]: [
      {
        name: "Crest gum detoxify",
        price: 1,
        link: "https://amzn.to/3yHQQT8",
        image: "assets/recommended-products/crest-gum-detoxity.jpg",
      },
      {
        name: "Listerine gum care",
        price: 1,
        link: "https://amzn.to/4crdmxr",
        image: "assets/recommended-products/listerine-gum-care.jpg",
      },
    ],
    [RecommendedProductFilter.Prescriptions]: [],
  },
  [RecommendedProductTabs.BoneLoss]: {
    [RecommendedProductFilter.OTC]: [
      {
        name: "Crest gum detoxify",
        price: 1,
        link: "https://amzn.to/3yHQQT8",
        image: "assets/recommended-products/crest-gum-detoxity.jpg",
      },
      {
        name: "Listerine Total Care",
        price: 1,
        link: "https://amzn.to/3AoUphD",
        image: "assets/recommended-products/listerine-total-care.jpg",
      },
    ],
    [RecommendedProductFilter.Prescriptions]: [],
  },
};
