import { Treatment } from "@kells/clinic-one/apis";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as TreatmentActions from "./treatment.actions";

export const featureKey = "treatments";

export type TreatmentState = EntityState<Treatment>;

export const adapter: EntityAdapter<Treatment> = createEntityAdapter<Treatment>();

export const initialState: TreatmentState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(TreatmentActions.getPatientTreatmentsSuccess, (state, { treatments }) => 
     adapter.setAll(treatments, state)
  ),
  on(TreatmentActions.addTreatmentSuccess, (state, { treatment }) =>
    adapter.addOne(treatment, state)
  ),
  on(TreatmentActions.deleteTreatmentSuccess, (state, { treatmentId }) =>
    adapter.removeOne(treatmentId, state)
  )
);

export const { selectAll, selectIds, selectEntities } = adapter.getSelectors();
