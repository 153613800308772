/**
 * Given a file, returns a promise of its content in base64 format.
 *
 * @param file the file to convert.
 * @returns a promise of its base64 content.
 */
export function toImageBase64(file: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = (reader.result as string)
        .replace("data:", "")
        .replace(/^.+,/, "");
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
}
