import { Injectable } from "@angular/core";
import {
  ToothLocation,
  ToothNumber,
  TOOTH_NUMBER_RANGE,
} from "../models/tooth.model";
import { assertNever, isNullable } from "@kells/utils/js";

@Injectable({
  providedIn: "root",
})
export class ToothService {
  /**
   * Genereates an array containing teeth numbers.
   *
   * @param args:
   *   - `usePhysiologicalOrder`: Optional, default to `false`. When set to
   *     `true`, the returned array is no longer sorted ascendingly. Rather,
   *     it would be `1, 2, ..., 16, 32, 31, ..., 17`, using the order of teeth
   *     in a human's mouth when read from the top left to the bottom right.
   *
   * @returns an array containing numbers [1 ~ 32]: the valid tooth numbers.
   */
  static generateToothNumbersArray(
    args: Partial<{
      usePhysiologicalOrder: boolean;
    }> = {}
  ): ToothNumber[] {
    const { usePhysiologicalOrder = false } = args;

    if (usePhysiologicalOrder) {
      const upperRowTeeth = Array.from({ length: 16 }).map((_, i) => i + 1);

      const lowerRowTeeth = Array.from({ length: 16 })
        .map((_, i) => i + 17)
        .reverse();

      return [...upperRowTeeth, ...lowerRowTeeth] as ToothNumber[];
    }

    return Array.from(
      { length: TOOTH_NUMBER_RANGE.MAX },
      (_, k) => k + 1
    ) as ToothNumber[];
  }

  /**
   * Obtains a textual description of a tooth's location, given the tooth number.
   */
  static getToothLocation(tooth: string | number): ToothLocation | undefined {
    let _tooth: number;

    if (typeof tooth !== "string" && typeof tooth !== "number") return;

    if (typeof tooth === "string") {
      try {
        _tooth = Number.parseInt(tooth, 10);
        if (Number.isNaN(_tooth)) return;
      } catch (err) {
        return;
      }
    } else {
      _tooth = tooth;
    }

    if (_tooth >= 1 && _tooth <= 8) {
      return ToothLocation.UpperRight;
    } else if (_tooth >= 9 && _tooth <= 16) {
      return ToothLocation.UpperLeft;
    } else if (_tooth >= 17 && _tooth <= 24) {
      return ToothLocation.LowerLeft;
    } else if (_tooth >= 25 && _tooth <= 32) {
      return ToothLocation.LowerRight;
    }

    return assertNever(`Tooth number must be within 1-32; got ${_tooth}`);
  }

  /**
   * Test whether the input is a valid tooth value.
   *
   * A valid tooth value is one of the followings:
   *  - number 1-32
   *  - stringified number 1-32
   *  - characters a-t
   *  - characters A-T
   *
   * @returns a boolean indicating whether the input falls into the above categories.
   */
  static isTooth(tooth: unknown): boolean {
    if (typeof tooth === "number") {
      return tooth >= 1 && tooth <= 32;
    }

    if (typeof tooth === "string") {
      // see if it is a stringified number
      try {
        const num = Number.parseInt(tooth, 10);
        if (Number.isNaN(num)) throw new Error();
        return num >= 1 && num <= 32;
      } catch (e) {
        // see if it is part of a-t or A-T
        if (tooth.length !== 1) return false;

        const asciiCode = {
          a: 97,
          t: 116,
          A: 65,
          T: 84,
        };

        const toothAsciiVal = tooth.charCodeAt(0);

        // if within a-t
        if (toothAsciiVal >= asciiCode.a && toothAsciiVal <= asciiCode.t)
          return true;

        // or, if within A-T
        if (toothAsciiVal >= asciiCode.A && toothAsciiVal <= asciiCode.T)
          return true;
      }
    }

    return false;
  }


  static formatToothDescription(tooth: string | number) {
    if (isNullable(tooth)) return "-";
    return `${tooth}`;
  }

  static  formatToothLocation(tooth: string | number) {
    if (isNullable(tooth)) return "-";
    return `${ToothService.getToothLocation(tooth)}`;
  }
}
