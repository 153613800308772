import { Injectable } from "@angular/core";
import { ImageFilters, getImageTemplate } from "../models/image-template.model";
import { Image } from "../models/image.model";
import {
  ImageClassification,
  BitewingClassifications,
  ClassificationBaseKind,
} from "../models/image-classifications.model";
import { isNullable } from "@kells/utils/js";

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class ClassificationService {
  /**
   * Given 2 version numbers, return `true` if `version2` is greater than
   * `version1`. It returns `false` otherwise.
   *
   * Version number arguments are expected to follow semantic versioning,
   * i.e. `MAJOR.MINOR.PATCH`. While this function does not throw, the return
   * value for comparing non-properly formatted version numbers is undefined.
   */
  static compareVersionNumber(version1: string, version2: string): boolean {
    try {
      const [major1, minor1, patch1] = version1
        .split(".")
        .map((n) => parseInt(n, 10));
      const [major2, minor2, patch2] = version2
        .split(".")
        .map((n) => parseInt(n, 10));

      if (major2 > major1) {
        return true;
      } else if (major1 > major2) {
        return false;
      }
      // major numbers are equal
      if (minor2 > minor1) {
        return true;
      } else if (minor1 > minor2) {
        return false;
      }
      // major and minor numbers are equal
      if (patch2 > patch1) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error parsing classifier version numbers", error);
      return false;
    }
  }

  /**
   * Given an image array and an image filter, return an array of images
   * that fall only under the specified filter, in the order that is ready
   * to be rendered to the user.
   */
  static sortByImageFilter({
    images,
    filter,
  }: {
    images: Image[];
    filter: ImageFilters;
  }): Image[] {
    type ImageWithOrderId = Omit<Image, "orderId"> & { orderId: number };

    const filteredImages = ClassificationService.filterImages(images, filter);

    const sortedImages = filteredImages
      .map(
        (g): ImageWithOrderId => ({
          ...g,
          orderId: isNullable(g.orderId)
            ? filteredImages.length + 1
            : g.orderId,
        })
      )
      // sort by orderId in ascending order.
      .sort((a, b) => a.orderId - b.orderId);

    return sortedImages;
  }

  /**
   * Given an image array and an image filter, returns an array of images
   * containing only images that can be classified under that filter.
   */
  private static filterImages(images: Image[], filter: ImageFilters): Image[] {
    const template = getImageTemplate(filter);

    if (template) {
      return template
        .map((type) => images.filter((g) => g.xrayType === type))
        .reduce((acc, val) => acc.concat(val), []);
    }

    return images;
  }

  static keepBitewingImages(images: Image[]) {
    return images
      .filter((image) => !isNullable(image))
      .filter((image) => !isNullable(image.xrayType))
      .filter((image) => image.xrayType.startsWith("Bitewing"));
  }

  /**
   * Given an image, determines whether it has gone through classification.
   *
   * Having gone through classification does not imply that an image has a
   * valid classifcation. See {@link hasClassification} for that functionality.
   */
  static hasGoneThroughClassification(image: Image): boolean {
    return (
      !isNullable(image.xrayType) &&
      image.xrayType !== ClassificationBaseKind.NotInitialized
    );
  }

  /**
   * Given an image, determines if it has a valid classification.
   * Images with a valid classification type should be attributable to
   * a {@link ValidClassificationKinds} type.
   *
   * @remark
   * Every image that has classification must have gone through the
   * classification process (see {@link hasGoneThroughClassification}).
   * But images which have gone throgh classification may not have a
   * classification.
   * In other words, *images with classification is a **subset** of images that
   * has gone through classification*.
   */
  static hasClassification(image: Image): boolean {
    return (
      ClassificationService.hasGoneThroughClassification(image) &&
      image.xrayType !== ClassificationBaseKind.Err
    );
  }

  /**
   * Matches a string to one of all possible values under `ImageClassification`.
   *
   * The conversion from string to predefined `ImageClassifcation` types is
   * done after receiving a classification string from the API. The conversion
   * is beneficial for several purposes:
   *   - Disregard invalid / un-implemented classification strings
   *   - Facilitate type inference on classification type in the codebase
   *
   * @param rawClassification a string that may denote an image classification.
   *
   * @return
   *   For string inputs that could not be matched to any meaningful image
   *   classifications, return `NotInitialized` under `ImageClassification`.
   *   For all other strings with a corresponding classification, return the
   *   corresponding `ImageClassification` value.
   */
  static classificationResolver(
    rawClassification: string
  ): ImageClassification {
    switch (rawClassification) {
      case BitewingClassifications.LeftMolar:
        return BitewingClassifications.LeftMolar;
      case BitewingClassifications.LeftPremolar:
        return BitewingClassifications.LeftPremolar;
      case BitewingClassifications.RightPremolar:
        return BitewingClassifications.RightPremolar;
      case BitewingClassifications.RightMolar:
        return BitewingClassifications.RightMolar;
      case ClassificationBaseKind.Generic:
        return ClassificationBaseKind.Generic;
      default:
        return ClassificationBaseKind.NotInitialized;
    }
  }
}
