import { AuthState } from "../auth.reducer";

export const AUTH_STATE_FIXTURES: Record<string, AuthState> = {
  authenticated: {
    loggedInProfile: {
      userId: "test-usr",
      userOfficeId: "office123",
    },
    authToken: {
      accessToken: "test-token",
      refreshToken: "refresh-token",
      accessTokenExpiresIn: 6400,
      recordedAt: new Date(2020, 4, 8),
    },
    isAuthenticating: false,
    authError: undefined,
  },
  unauthenticated: {
    isAuthenticating: false,
    authError: undefined,
  },
  authenticationPending: {
    isAuthenticating: true,
    authError: undefined,
  },
  authenticationErred: {
    authError: new Error("not authenticated"),
    isAuthenticating: false,
  },
};
