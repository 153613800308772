export enum ConnectorNotificationKind {
  Info,
  Error,
  Update,
}

export interface ConnectorNotification {
  /** Notification classification. See `ConnectorNotificationKind` for context. */
  type: ConnectorNotificationKind;
  /** Notification content. */
  message: string;
  /** The time at which this notification is created. */
  time: Date;
  /** Whether the notification has been seen by the user. */
  read: boolean;
}

export interface DisplayedNotification
  extends Omit<ConnectorNotification, "time"> {
  /** Transform time to a string to be displayed. */
  time: string;
}

/**
 * The maximum number of notifications to persist locally.
 * Old notification entries should be discarded after the total number of
 * entries exceed this number.
 */
export const NotificationBufferSize = 100;
