import { RenderState } from "@kells/shared-ui/canvas";
import { theme } from "../../../exported-theme";
import { IFabricFindingStyles } from "../fabricstyle.interface";
const MAIN_PALETTE = theme.colors.warmGray;
const HOVER_PALETTE = theme.colors.warmGray;
const FOCUS_PALETTE = theme.colors.coolGray;
const ACTIVE_PALETTE = theme.colors.cyan;

class InfectionStateStyles implements IFabricFindingStyles {
  strokeWidth_active_hover = 3;
  strokeWidth_active = 2;
  strokeWidth_focus_hover = 3;
  strokeWidth_focus = 2;

  strokeWidth_hover = 3;
  strokeWidth = 2;

  strokeColor = MAIN_PALETTE[300] + "cc";
  strokeColor_hover = HOVER_PALETTE[300];

  fillColor = "transparent";
  fillColor_hover = MAIN_PALETTE[300] + "30";

  strokeColor_focus = FOCUS_PALETTE[200] + "cc";
  strokeColor_focus_hover = FOCUS_PALETTE[200];

  fillColor_focus = FOCUS_PALETTE[300] + "27";
  fillColor_focus_hover = FOCUS_PALETTE[300] + "15";

  strokeColor_active = ACTIVE_PALETTE[300] + "27";
  strokeColor_active_hover = ACTIVE_PALETTE[300];

  fillColor_active = ACTIVE_PALETTE[200] + "27";
  fillColor_active_hover = ACTIVE_PALETTE[200];

  unconfirmedDashArray = [1, 2, 3, 5, 3, 2];

  confirmed = false;

  rectState: RenderState;

  setUp() {
    this.rectState = {
      strokeWidth: this.strokeWidth,
      strokeColor: this.strokeColor,
      fillColor: this.fillColor,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray,
    };
  }

  setActiveHover() {
    this.rectState = {
      strokeWidth: this.strokeWidth_active_hover,
      strokeColor: this.strokeColor_active_hover,
      fillColor: this.fillColor_active_hover,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray,
    };
  }

  setActive() {
    this.rectState = {
      strokeWidth: this.strokeWidth_active,
      strokeColor: this.strokeColor_active,
      fillColor: this.fillColor_active,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray,
    };
  }

  setFocusHover() {
    this.rectState = {
      strokeWidth: this.strokeWidth_focus_hover,
      strokeColor: this.strokeColor_focus_hover,
      fillColor: this.fillColor_focus_hover,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray,
    };
  }

  setFocus() {
    this.rectState = {
      strokeWidth: this.strokeWidth_focus,
      strokeColor: this.strokeColor_focus,
      fillColor: this.fillColor_focus,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray,
    };
  }
  setHover() {
    this.rectState = {
      strokeWidth: this.strokeWidth_hover,
      strokeColor: this.strokeColor_hover,
      fillColor: this.fillColor_hover,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray,
    };
  }

  public constructor(confirmed: boolean) {
    this.confirmed = confirmed;
    this.setUp();
  }
}
export default InfectionStateStyles;
