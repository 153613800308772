import { ValidatorFn, ValidationErrors } from "@angular/forms";
import { isNullable } from "@kells/utils/js";
import { CariesLocationTypes } from "../models";

const asciiCodes = {
  A: 65,
  T: 84,
  a: 97,
  t: 116,
};

export enum ToothInputErrors {
  /**
   * Error when no input is provided.
   */
  noToothValue = "noToothValue",
  /**
   * Error when the input is not a number, and it is not a single letter.
   */
  requireSingleLetter = "requireSingleLetter",
  /**
   * Error when the input is a single letter, but it is not from A-T or a-t.
   */
  invalidSingleLetter = "invalidSingleLetter",
  /**
   * Error when the input is a number, but it is not within 1-32.
   */
  invalidNumber = "invalidNumber",
  /**
   * Error when the numeric input starts with 0
   */
  noLeadingZeroes = "noLeadingZeroes",
  /**
   * Error when the input starts with '-'.
   */
  noNegativeValues = "noNegativeValues",


  noToothLocation = 'noToothLocation',

  invalidToothLocation = 'invalidToothLocation'
}

const isCharWithinAtoT = (toothNumString: string) => {
  const toothNumAsciiCode = toothNumString.charCodeAt(0);

  // Validating that the tooth letter input is within A-T or a-t:
  //
  // On the ASCII table, letters A-T and a-t, if drawn on a scale, looks
  // roughly like this:
  //
  //    0------A-----T--a-----t-------127
  //           ^^^^^^^  ^^^^^^^
  //
  // To see if user input violates this constraint, the following expression
  // matches the inverse of the constraint, i.e.
  //
  //    0------A-----T--a-----t-------127
  //    ^^^^^^^       ^^       ^^^^^^^^^
  return (
    (toothNumAsciiCode >= asciiCodes.A && toothNumAsciiCode <= asciiCodes.T) ||
    (toothNumAsciiCode >= asciiCodes.a && toothNumAsciiCode <= asciiCodes.t)
  );
};

const validateToothLetter = (toothValue: string): ValidationErrors | null => {
  const toothNumString = String(toothValue);
  if (toothNumString.length >= 2) {
    return { [ToothInputErrors.requireSingleLetter]: true };
  }

  if (!isCharWithinAtoT(toothNumString)) {
    return { [ToothInputErrors.invalidSingleLetter]: true };
  }

  return null;
};

const validateToothNumber = (
  toothValue: string | number
): ValidationErrors | null => {
  let toothNumber: number;

  if (typeof toothValue === "string") {
    if (toothValue.startsWith("0")) {
      return { [ToothInputErrors.noLeadingZeroes]: true };
    }

    toothNumber = Number.parseInt(toothValue, 10);
  } else {
    toothNumber = toothValue;
  }

  if (toothNumber < 1 || toothNumber > 32) {
    return { [ToothInputErrors.invalidNumber]: true };
  }

  return null;
};

export const toothInputValidator: ValidatorFn = (
  control
): ValidationErrors | null => {
  const toothValue = control.value;

  if (isNullable(toothValue) || toothValue === "") {
    return { [ToothInputErrors.noToothValue]: true };
  }

  if (
    (typeof toothValue === "string" && toothValue.startsWith("-")) ||
    (typeof toothValue === "number" && toothValue < 0)
  ) {
    return { [ToothInputErrors.noNegativeValues]: true };
  }

  const containsOnlyDigits = /^\d+$/.test(toothValue);

  if (!containsOnlyDigits) {
    return validateToothLetter(toothValue);
  }
  return validateToothNumber(toothValue);
};



export const toothLocationValidator: ValidatorFn = (
  control
): ValidationErrors | null => {
  const toothLocation = control.value;

  if (isNullable(toothLocation) || toothLocation === "") {
    return { [ToothInputErrors.noToothLocation]: true };
  }

  if (
    (toothLocation !==  CariesLocationTypes.Buccal &&
      toothLocation !== CariesLocationTypes.Distal &&
      toothLocation !== CariesLocationTypes.Lingual &&
      toothLocation !== CariesLocationTypes.Mesial &&
      toothLocation !== CariesLocationTypes.Occlusal)
  ) {
    return { [ToothInputErrors.invalidToothLocation]: true };
  }
  return null;
};
