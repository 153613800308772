import {
  FindingFilterSelectionTypes,
  FindingStatus,
  PlaqueFilterSelectionType,
} from "@kells/interfaces/finding";
import { CanvasLibFinding } from "@kells/shared-ui/canvas";
import BaseFindingObject from "../base-finding-object";
import StateStyles from "./styles";
import { fabric } from "fabric";
import { isNullable } from "@kells/utils/js";
import { BASE_FABRIC_PROPS } from "../fabricstyle.interface";
import { CanvasContext } from "../../canvas/canvas-context.interface";
import { tap } from "rxjs/operators";

class PlaqueFindingFabric extends BaseFindingObject {
  createLabel() {
    if (!this.labelElement && !isNullable(this.object)) {
      const { id } = this;

      this.labelElement = fabric.util.makeElement("div", {
        id: `${id}_label`,
      }) as HTMLDivElement;

      this.labelElement.classList.add(
        "kco-finding-label",
        "finding-label-carries"
      );
      this.labelElement.classList.add("invisible");
      this.labelElement.innerHTML = `
      <div class="label-inner">
        <div>
          <span class="type">Plaque: ${this.finding.tooth}</span>
        </div>
      </div>
      `;
      this.canvasElementRef = document.getElementById(
        "overlay-positioning-container"
      ) as HTMLDivElement;
      document.body.appendChild(this.labelElement);
    }
  }
  constructor(finding: CanvasLibFinding, protected _ctx: CanvasContext) {
    super(finding, _ctx);
    this.styles = new StateStyles(finding.status === FindingStatus.Confirmed);
    this.styles.setUp();
    this.typeKey = PlaqueFilterSelectionType;
    (async () => {
      await this.delayedInit();
      this.createLabel();
      if (!isNullable(this.labelElement)) {
        this.setPosition(this.labelElement);
      }
      this.initStyles();
      this._subs.sink = _ctx.findingFilterKeys$
        .pipe(
          tap((f: FindingFilterSelectionTypes[]) => {
            if (f.includes(PlaqueFilterSelectionType)) {
              this.makeVisible();
            } else {
              this.makeHidden();
            }
          })
        )
        .subscribe();
    })();
  }

  async delayedInit() {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.initFabricObject();
        resolve(null);
      }, 100);
    });
  }

  initFabricObject() {
    super.initFabricObject({
      ...BASE_FABRIC_PROPS,
      originX: "left",
      originY: "top",
    });
  }
}

export default PlaqueFindingFabric;
