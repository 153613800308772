import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ModalData } from "@kells/utils/angular";
import { isNullable } from "@kells/utils/js";

@Component({
  selector: "koa-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {}

  ngOnInit() {
    if (isNullable(this.data.centered)) {
      this.data.centered = true;
    }
    if (isNullable(this.data.contentCentered)) {
      this.data.contentCentered = true;
    }
    if (isNullable(this.data.titleCentered)) {
      this.data.titleCentered = true;
    }
  }
}
