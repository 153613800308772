/* eslint-disable eqeqeq */
/**
 * Given an argument, return `true` if it is not `undefined` or `null`.
 */
export const isDefined = <T>(x: T): x is NonNullable<T> => {
  return x != null;
};

/**
 * Given an argument, determine if it is `null` or `undefined`.
 */
export const isNullable = (x: any): x is undefined | null => {
  return x == null;
};
