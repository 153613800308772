import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { PatientRecommendation } from "@kells/clinic-one/apis";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, distinctUntilKeyChanged } from "rxjs/operators";
import { keepDefined } from "@kells/utils/observable/observable-operators";

import { isDefined } from "@kells/utils/js";
import {
  animate,
  animateChild,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { FindingsByTooth } from "@kells/interfaces/finding";

import { SubSink } from "subsink";
import { ToothDirective } from "./tooth.directive";
import { observeProperty } from "@kells/utils/angular";

const ANI_ENTER_TIMING = "225ms cubic-bezier(0, 0, .2, 1)";

const fadeAppear = [
  trigger("fadeAppear", [
    transition(":enter", [
      style({ opacity: 0, transform: "scale(1.2)" }),
      animate(ANI_ENTER_TIMING, style({ opacity: 1, transform: "scale(1)" })),
      animateChild(),
    ]),
    transition(":leave", [
      style({ opacity: 1, transform: "scale(1)" }),
      animate(ANI_ENTER_TIMING, style({ opacity: 0, transform: "scale(1.1)" })),
      animateChild(),
    ]),
  ]),
];

@Component({
  selector: "koa-mouth",
  templateUrl: "./mouth.component.html",
  styleUrls: ["./mouth.component.scss"],
  animations: [fadeAppear],
})
export class MouthComponent implements AfterViewInit, OnDestroy {
  @Input() report: PatientRecommendation;

  private report$: Observable<PatientRecommendation> = observeProperty(
    this,
    "report"
  ).pipe(distinctUntilChanged());

  @Input() findingsByTooth: Map<string, FindingsByTooth>;

  private findingsByTooth$: Observable<
    Map<string, FindingsByTooth>
  > = observeProperty(this, "findingsByTooth").pipe(
    keepDefined(),
    distinctUntilChanged()
  );

  @Input() set selectedFindingsFilterType(type: string) {
    this._selectedFindingsFilterType = type;
    if (!this.lastByToothParams) {
      return;
    }

    this.onByToothSubscription(this.lastByToothParams);
  }
  _selectedFindingsFilterType: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output("click") clickEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() activeToothChange = new EventEmitter<FindingsByTooth>();
  @Output() toothClick = new EventEmitter<FindingsByTooth>();

  @ViewChildren(ToothDirective) teeth: QueryList<ToothDirective>;

  private lastByToothParams: Partial<{
    report?: PatientRecommendation;
    findingsByTooth: Map<string, FindingsByTooth>;
  }>;
  private _activeTooth$: BehaviorSubject<FindingsByTooth>;
  public get activeTooth$(): BehaviorSubject<FindingsByTooth> {
    return this._activeTooth$;
  }
  public toothDeselected = true;

  _subs = new SubSink();
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  byTooth: Map<string, FindingsByTooth>;

  ngAfterViewInit() {
    this._subs.sink = combineLatest([
      this.report$,
      this.findingsByTooth$,
    ]).subscribe(([report, findingsByTooth]) => {
      this.onByToothSubscription({ report, findingsByTooth });
    });
  }

  onByToothSubscription = ({
    report,
    findingsByTooth,
  }: Partial<{
    report?: PatientRecommendation;
    findingsByTooth: Map<string, FindingsByTooth>;
  }>) => {
    this.lastByToothParams = { report, findingsByTooth };
    const isSelectedToothFilteredOut = !this._activeTooth$
      ?.getValue()
      ?.allFindings.some(
        (finding) => finding.type === this._selectedFindingsFilterType
      );
    if (this._selectedFindingsFilterType && isSelectedToothFilteredOut) {
      this.toothDeselected = true;
    }
    if (isDefined(findingsByTooth)) {
      this.byTooth = findingsByTooth;
      this.teeth.map((tooth: ToothDirective) => {
        if (findingsByTooth.has(tooth.toothId)) {
          tooth.findings = findingsByTooth.get(
            tooth.toothId
          ) as FindingsByTooth;
          const hasFindingsForFilterType = this._selectedFindingsFilterType
            ? tooth.findings.allFindings.some((finding) => {
                return finding.type === this._selectedFindingsFilterType;
              })
            : true;
          tooth.isFilteredOut = false;
          // add click only on clickable element where tooth has totalCaries > 0
          if (
            (tooth.findings.totalCaries > 0 || tooth.isAdvancedFindingType) &&
            hasFindingsForFilterType
          ) {
            tooth.onClick = () => this.toothClick.emit(tooth.findings);
          }

          if (!hasFindingsForFilterType) {
            tooth.isFilteredOut = true;
            tooth.removeSelectionClasses();
            tooth.makeInactive();
          } else if (
            (this.toothDeselected || !isDefined(this._activeTooth$)) &&
            (tooth.findings.totalCaries > 0 || tooth.isAdvancedFindingType)
          ) {
            this._activeTooth$ = new BehaviorSubject<FindingsByTooth>(
              tooth.findings
            );
            tooth.activeTooth$ = this._activeTooth$;
            this.initActiveToothWatcher();
            this.toothDeselected = false;
            this.changeDetectorRef.detectChanges();
          } else if (
            tooth.findings.totalCaries > 0 ||
            tooth.isAdvancedFindingType
          ) {
            tooth.activeTooth$ = this._activeTooth$;
          } else {
            tooth.makeInactive();
          }
        } else {
          tooth.makeInactive();
        }
      });
    }
  };

  initActiveToothWatcher() {
    const { activeTooth$, _subs } = this;
    _subs.sink = activeTooth$
      .pipe(keepDefined(), distinctUntilKeyChanged("tooth_id"))
      .subscribe((val: FindingsByTooth) => {
        this.activeToothChange.emit(val);
      });
    requestAnimationFrame(() => {
      this._activeTooth$.next(this._activeTooth$.value);
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
