import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState, userFeatureKey } from "./user.reducer";
import { selectAuthState } from "@kells/clinic-one/auth";
import { UserService } from "../services/user.service";
import { UserRole } from "../models/user.model";

export const selectUsersState = createFeatureSelector<UserState>(
  userFeatureKey
);

/**
 * Gets a user instance describing the currently logged in user.
 * @returns `undefined` no user has logged in,
 *    or the currently logged in user if a user is authenticated.
 */
export const selectLoggedInUser = createSelector(
  selectAuthState,
  selectUsersState,
  (authState, userState) => {
    if (!authState.loggedInProfile?.userId) return;
    return userState.entities[authState.loggedInProfile.userId];
  }
);

export const getLoggedInUserEmail = createSelector(
  selectLoggedInUser,
  (user) => user?.email
);

export const selectLoggedInUserRole = createSelector(
  selectLoggedInUser,
  (user) => user?.role
);

export const selectIsLoggedInUserAdmin = createSelector(
  selectLoggedInUserRole,
  (role) => role === UserRole.Admin
);

/**
 * Returns a user's initials if available, or `undefined`.
 */
export const getLoggedInUserInitials = createSelector(
  selectLoggedInUser,
  (user) => UserService.getInitials(user)
);

/**
 * @returns the logged in user's fullname, or `undefined` if no logged in user
 *     can be found.
 */
export const getLoggedInUserFullname = createSelector(
  selectLoggedInUser,
  (user) => UserService.getUserFullname(user)
);

export const selectDefaultAutoEnhancementSetting = createSelector(
  selectLoggedInUser,
  (user) => {
    if (!user) return false;
    return user.settings.autoEnhance;
  }
);

export const getLoggedInUserDefaultPredictionThreshold = createSelector(
  selectLoggedInUser,
  (user) => {
    if (!user) return;

    const { selected } = user.settings.cariesDetection;
    return {
      key: selected,
      value: user.settings.cariesDetection.thresholds[selected],
    };
  }
);
