<ng-container *ngIf="dataReady">
  <div id="bonehealth" #bonehealth class="bone-health">
    <section class="bone-xray">
      <h1 class="report-header mb-5 fw-700 bottom-hb">YOUR BONE HEALTH</h1>
      <div class="section-content">
        <div class="grid grid-cols-12 gap-4 md:gap-3 xs:gap-1 sm:gap-1 my-5 py-3 xs:my-0">
          <div class="2xl:col-span-8 lg:col-span-8 md:col-span-8 sm:col-span-12 xs:col-span-12">
            <p class="ft-medium fw-500">
              The chart below shows how much bone you have lost as captured in x-rays. Bone loss occurs naturally
              as we age but it is important to monitor it over time. The different colors indicate your level of bone loss
              around the corresponding tooth.</p>
          </div>
          <div class="2xl:col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-12 xs:col-span-12">
            <div class="caries-chart-legend w-full flex flex-row justify-end">
              <div class="flex items-center content-center mr-5 sm:mr-3">
                <div class="legend-color bg-white border-solid-1 border-2 mr-2 shadow"></div>
                <div class="md:kpr-blockquote sm:kpr-small-label chart-legend-label ft-medium">Normal</div>
              </div>
              <div class="flex items-center content-center mr-5 sm:mr-3">
                <div class="legend-color moderate mr-2 shadow"></div>
                <div class="md:kpr-blockquote sm:kpr-small-label chart-legend-label ft-medium">Abnormal</div>
              </div>
              <div class="flex items-center content-center">
                <div class="legend-color advanced mr-2 shadow"></div>
                <div class="md:kpr-blockquote sm:kpr-small-label chart-legend-label ft-medium">Advanced</div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="grid grid-cols-16 grid-rows-2 gap-0 grid-flow-row my-5 py-3 xs:my-0">
            <ng-container *ngLet="{
                              all:(teethNumbers$ | async),
                              top: (topTeeth$ | async),
                              bottom: (bottomTeeth$ | async)
                            } as chartData">
              <ng-container *ngFor="let item of chartData.top">

                <div class="chart__teeth-single relative w-18 flex flex-col h-28 pb-3" [matTooltipPosition]="'after'"
                  [matTooltip]="'bone loss value: ' + item.bonelossScore + ' mm'">
                  <div class="relative">
                    <p class="xs:text-sm xs:font-bold tooth-number">{{ item.toothNumber }} </p>
                  </div>
                  <koa-boneloss-icon [tooth]="getTooth(item.toothNumber)" [toothNumber]="item"></koa-boneloss-icon>
                </div>

              </ng-container>

              <ng-container *ngFor="let item of chartData.bottom">
                <ng-container *ngLet="getTooth(item.toothNumber) as tooth">
                  <div class="chart__teeth-single relative w-18 flex flex-col h-28"
                    [matTooltip]="'bone loss value: ' + item.bonelossScore + ' mm'">

                    <koa-boneloss-icon [tooth]="tooth" [toothNumber]="item"></koa-boneloss-icon>

                    <div class="relative">
                      <p class="tooth-number">{{ item.toothNumber }}</p>

                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </section>

    <section class="bone-deep-drive section-content">
      <div class=" pb-1 pt-5 x-auto rounded-lg xs:pt-0 xs:pb-0">
        <h3 class="report-header my-5 fw-700">
          DEEP DIVE IN YOUR BONE HEALTH
        </h3>
        <p class="ft-medium fw-500" [innerHTML]="(deepDiveText$ | async)"></p>
      </div>
    </section>
  </div>
</ng-container>