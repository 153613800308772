import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  PatientRecommendation,
  Treatment
} from "@kells/clinic-one/apis";
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject } from "rxjs";
import { distinctUntilChanged, distinctUntilKeyChanged, map, filter } from "rxjs/operators";
import {
  keepDefined,
  pipeLog,
  takeLatest,
} from "@kells/utils/observable/observable-operators";
import { DatePipe } from "@angular/common";
import { capitalize, isDefined, isNullable } from "@kells/utils/js";
import {
  animate,
  animateChild,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DataAccessService } from "@kells/apis/data-access";
import { CariesStageTypes, FindingsByTooth, FindingType, IndexedFindingsMap, KellsFinding, PerioData, RenderableFindingBase, ToothIconSlot, TOOTH_NAMES } from "@kells/interfaces/finding";
import { KellsImageBase } from "@kells/interfaces/image";

import { SubSink } from "subsink";
// import { ToothDirective } from './tooth.directive';
import { observeProperty } from "@kells/utils/angular";
import { ToothNumber } from "@kells/interfaces/tooth";
import { INITIAL_TO_MODERATE, INITIAL_TO_ADVANCED, INITIAL_ONLY, MODERATE_FROM_INITAL, MODERATE_ONLY, ADVANCED, cariesInitialFocus } from "../../theme/colors";

export function calcSeverity(finding: RenderableFindingBase): number {
  return Number(finding?.bone_loss_attributes?.measurement_mm ?? finding?.bone_loss_attributes?.measurement_pixel ?? 1);
}

export function calcMeasurement(bone_loss_attributes: PerioData): PerioData {

  // let px: number = Math.sqrt(Math.hypot((bone_loss_attributes.ac_x - bone_loss_attributes.cej_x), (bone_loss_attributes.ac_y - bone_loss_attributes.cej_y)))
  // bone_loss_attributes.measurement_pixel = Number(px).toFixed(2);
  // const mm: string = Number(px * 0.26458333).toFixed(2);

  // bone_loss_attributes.measurement_mm = mm;

  return bone_loss_attributes;

}
interface ToothIcon {
  /** The tooth number that is associated with this icon. */
  toothNumber: ToothNumber;
  /** The identifier assigned to the SVG representing this tooth. */
  iconIdentifier: string;
  /**
   * Color to be applied on this tooth icon. If not provided, use the default color.
   */

  /**
   * Color to be applied to this tooth icon's border. Use the default color if `undefined`.
   */
  toothStroke: string;
  toothFill: string;
  bonelossScore: number;
  dataFill: string;
  dataStroke: string;
  dataHeight: number | string;
  toothClass: string,
  dataClass: string,
  y?: number | string;
  xlink: string
}

const BONE_LOSS_MODERATE_THRESHOLD = 3;
const BONE_LOSS_ADVANCED_THRESHOLD = 5


@Component({
  selector: "koa-boneloss-icon",
  templateUrl: "./boneloss-icon.component.html",
  styleUrls: ["./boneloss-icon.component.scss"],
})
export class BoneLossIconComponent implements OnInit, OnDestroy {
  @Input() tooth:FindingsByTooth;
  tooth$: Observable<FindingsByTooth | null> = observeProperty(this, 'tooth');

  @Input() toothNumber: ToothIconSlot;
  toothNumber$: Observable<ToothIconSlot> = observeProperty(this, 'toothNumber');

  dataReady$ = new BehaviorSubject(false);
  _subs = new SubSink();


  constructor(private el: ElementRef) {

  }


  ngOnInit() {
    this._subs.sink = this.toothIcon$.subscribe(()=> {
      this.dataReady$.next(true);
      setTimeout(() => {

        this._subs.sink = this.dataY$.subscribe();
      }, 333);

    })

  }


  ngAfterViewInit() {

  }




  @ViewChild('iconSvg') iconSvg:ElementRef<SVGUseElement>;
  iconSVG$: Observable<ElementRef<SVGUseElement>> = observeProperty(this, 'iconSvg').pipe(keepDefined());


  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  _getDataClass(severity:number):string {
    if (severity > 1) {
      if (severity < 2) {
        return 'kpr-data-initial';
      } else if (severity < 3) {
        return 'kpr-data-moderate';
      } else {
        return 'kpr-data-advanced';
      }
    }
    return 'kpr-data-initial';
  }



  _getToothClass(bonelossScore: number): string {
    if (bonelossScore > 1) {
      // if (bonelossScore < 2) {
      //   return 'kpr-tooth-initial';
      // }
        if (bonelossScore >= BONE_LOSS_MODERATE_THRESHOLD && bonelossScore < BONE_LOSS_ADVANCED_THRESHOLD) {
        return 'kpr-tooth-moderate';
      } else if (bonelossScore >=  BONE_LOSS_ADVANCED_THRESHOLD) {
        return 'kpr-tooth-advanced';
      }
    }

    return 'kpr-tooth-initial';

  }
  _getToothColor(bonelossScore: number): string[] {

     if (bonelossScore >= BONE_LOSS_MODERATE_THRESHOLD && bonelossScore < BONE_LOSS_ADVANCED_THRESHOLD) {
      return ['#e1a532', '#8393C3'];
    } else if (bonelossScore >= 5) {
      return ['#d53c34', '#8393C3'];
    }
    return ['#fff', '#8393C3'];
  }
  _getDataColor(bonelossScore: number): string[] {


   if (bonelossScore >= BONE_LOSS_MODERATE_THRESHOLD && bonelossScore < BONE_LOSS_ADVANCED_THRESHOLD) {
      return ['#e1a532', 'none'];
   } else if (bonelossScore >= 5) {
       return ['#d53c34', 'none'];
    }
    return ['#fff', 'none'];
  }

  maxSeverity = 0;

  _getBonelossScore(tooth: FindingsByTooth): number {
    let score = 0;
    let severity = 0.1;
    let maxSeverityAll = 0;
    let severityTxt = '';
    if (tooth.qtyBoneLoss > 0) {
      tooth.boneLoss.forEach((boneData: RenderableFindingBase) => {

        boneData.bone_loss_attributes = calcMeasurement(boneData.bone_loss_attributes as PerioData);
        severity = calcSeverity(boneData);

        if (!severity) {
          calcSeverity(boneData);
        } else {
          if (severity > maxSeverityAll) {
            maxSeverityAll = severity;

          }

        }

      });
    }
    if (maxSeverityAll > this.maxSeverity) {
      this.maxSeverity = maxSeverityAll;
    }
    return Math.trunc(maxSeverityAll);
  }

  processToothNumber = (tooth:FindingsByTooth | null, item:ToothIconSlot): ToothIcon=> {
    let toothFill = '#fff';
    let toothStroke = '#8393C3';
    let dataFill = '#fff';
    let dataStroke = '#8393C3';
    let bonelossScore = 1;
    let dataHeight = 0;
    if (!isNullable(tooth)) {

      bonelossScore = this._getBonelossScore(tooth) as number;
        [dataFill, dataStroke] = this._getDataColor(bonelossScore);
        [toothFill, toothStroke] = this._getToothColor(bonelossScore);
        dataHeight = bonelossScore > 0 ? 65 * (bonelossScore / 4) : 0;

    } else {
      bonelossScore = 0;
      dataFill = '#fff';
    }

    const scorePercent = (4 / 100) * bonelossScore;

    const xlinkClip = this._getClipFromScore(bonelossScore, item.toothNumber);
    const y = this._getDataYFromScoreAndToothNumber(bonelossScore, item.toothNumber);
    const dataClass = this._getDataClass(bonelossScore);
    const toothClass = this._getToothClass(bonelossScore);
    const toothIcon = {
      ...item,
      bonelossScore,
      xlinkClip,
      toothFill: toothFill,
      toothStroke: toothStroke,
      dataFill,
      dataStroke,
      dataHeight,
      toothClass,
      dataClass,
      y,

    };
    return toothIcon;
  }

  private _getDataYFromScoreAndToothNumber(score:number, toothNumber:number):number {
    if (toothNumber < 17) {
      return -4;
    } else {
      const maxY = 132
      switch (score as number) {
        case 0:
          return maxY;
        case 1:
          return maxY - 33;
        case 2:
          return maxY - 66;
        case 3:
          return maxY - 99;
        case 4:
          return 0;
      }
    }
    return 132;

  }

  private _getClipFromScore(score: number, toothNumber:number):string {
    switch (score as number) {
      case 0:
        return `url(#clip-0-${toothNumber}-boneloss)`;
      case 1:
        return `url(#clip-1-${toothNumber}-boneloss)`;
      case 2:
        return `url(#clip-2-${toothNumber}-boneloss)`;
      case 3:
        return `url(#clip-3-${toothNumber}-boneloss)`;
      case 4:
        return `url(#clip-4-${toothNumber}-boneloss)`;
    }
    return `url(#clip-0-${toothNumber}-boneloss)`;
  }

  iconWidthSubject:BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  iconWidthSubject$: Observable<number> = this.iconWidthSubject.asObservable().pipe(keepDefined(), distinctUntilChanged(), filter(w => !isNaN(w) && w !== -1));
  iconHeightSubject: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  iconHeightSubject$: Observable<number> = this.iconHeightSubject.asObservable().pipe(keepDefined(), distinctUntilChanged(), filter(w => !isNaN(w) && w !== -1 ));



  toothIcon$: Observable<ToothIcon> = combineLatest([
    this.tooth$,
    this.toothNumber$
  ]).pipe(map(([tooth, toothNumber]) => {
      const icon: ToothIcon = this.processToothNumber(tooth as (FindingsByTooth | null), toothNumber as ToothIconSlot);
      return icon;
    }));


  dataHeight$: Observable<number> = combineLatest([this.iconHeightSubject$, this.toothIcon$]).pipe(
    map(
      ([height, toothIcon]) => {
        switch((toothIcon.bonelossScore as number)) {
          case 0:
            return 0;
          case 1:
            return ((height as number) * 33);
          case 2:
            return ((height as number) * 66);
          case 3:
          case 4:
            return height as number;
        }
        return 0;
      }));


  dataY$: Observable<number> = combineLatest([this.toothNumber$, this.iconHeightSubject$, this.dataHeight$]).pipe(map(([toothSlot, iconHeight, dataHeight]) => {
    if (toothSlot.toothNumber < 17) {
      return -4;
    } else {
      return 136 - dataHeight;
    }
  }));

  _measureContainer(): void {
    requestAnimationFrame(() => {
      const styles = this.iconSvg.nativeElement.getBBox();

      console.log("SVG symbol Height", styles.height);
      console.log("SVG symbol Width", styles.width);
      this.iconWidthSubject.next(Number(styles.width));
      this.iconHeightSubject.next(Number(styles.height));
    });
  }



}

