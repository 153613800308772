import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";
import {
  CariesStageTypes,
  FindingStatus,
  FindingType,
  KellsFinding,
  RenderableFindingBase,
} from "@kells/interfaces/finding";
import { isNullable } from "@kells/utils/js";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TreatmentDB } from "../db/treatment.db";
import {
  AddTreatmentData,
  Treatment,
  TreatmentCostEstimate,
  TreatmentPriority,
  TreatmentResponse,
  TreatmentTypes,
  WHOLE_MOUTH_TREATMENT_LABEL,
} from "../models";
import { FindingService } from "./finding.service";
import parse from "date-fns/parse";

interface TreatmentSaveSuccessResponse {
  message: string;
  data: {
    treatment_id: string;
  };
}

interface GetTreatmentsResponse {
  message: string;
  data: TreatmentResponse[];
}

@Injectable({
  providedIn: "root",
})
export class TreatmentService {
  private treatmentTypeLookupMap: Map<string, TreatmentTypes>;

  treatmentServiceBaseUrl = (patientId: string) =>
    `${this.baseUrl}/${this.apiVersion}/patients/${patientId}/treatments`;

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient
  ) {}

  getPatientTreatments(patientId: string): Observable<Treatment[]> {
    return this.http
      .get<GetTreatmentsResponse>(this.treatmentServiceBaseUrl(patientId))
      .pipe(
        map(({ data }) => data.map(TreatmentService.parseTreatmentResponse))
      );
  }

  getTreatment(patientId: string, treatmentId: string) {
    return this.http.get(
      `${this.treatmentServiceBaseUrl(patientId)}/${treatmentId}`
    );
  }

  saveTreatment(args: {
    patientId: string;
    sessionDate: string;
    session: string;
    treatment: AddTreatmentData;
  }): Observable<Treatment> {
    const { patientId, sessionDate, treatment, session } = args;
    return this.http
      .post<TreatmentSaveSuccessResponse>(
        this.treatmentServiceBaseUrl(patientId),
        TreatmentService.toAddTreatmentPayload(
          treatment,
          patientId,
          sessionDate,
          session
        )
      )
      .pipe(
        map((resp) => ({
          id: resp.data.treatment_id,
          patientId,
          sessionDate,
          session,
          ...treatment,
        }))
      );
  }

  deleteTreatment(patientId: string, treatmentId: string) {
    return this.http.delete(
      `${this.treatmentServiceBaseUrl(patientId)}/${treatmentId}`
    );
  }

  static parseTreatmentResponse(
    treatmentResponse: TreatmentResponse
  ): Treatment {
    const sessionDate = treatmentResponse.session_date
      ? parse(
          treatmentResponse.session_date.split("T")[0],
          "yyyy-MM-dd",
          new Date()
        )
      : undefined;
    const sessionDateString = sessionDate
      ? [
          sessionDate.getFullYear(),
          sessionDate.getMonth() + 1,
          sessionDate.getDate(),
        ].join("-")
      : undefined;

    return {
      id: treatmentResponse._id,
      patientId: treatmentResponse.patient,
      sessionDate: sessionDateString,
      session: treatmentResponse.session,
      __originalDate: sessionDate,
      description: treatmentResponse.description,
      notes: treatmentResponse.notes,
      tooth: treatmentResponse.tooth,
    };
  }

  static toAddTreatmentPayload(
    treatment: AddTreatmentData,
    patientId: string,
    sessionDate: string,
    sessionId: string
  ): Omit<TreatmentResponse, "_id"> {
    return {
      patient: patientId,
      session_date: sessionDate,
      description: treatment.description,
      notes: treatment.notes,
      tooth: treatment.tooth,
      session: sessionId,
    };
  }

  static toTreatmentDTO(treatment: Treatment): TreatmentResponse {
    return {
      _id: treatment.id,
      patient: treatment.patientId,
      session_date: treatment.sessionDate,
      description: treatment.description,
      notes: treatment.notes,
      tooth: treatment.tooth,
      session: treatment.session,
    };
  }

  /**
   * Given the textual description of a treatment, map to one of the
   * defined [[`TreatmentTypes`]].
   */
  lookupTreatmentType(
    treatmentDescription: string
  ): TreatmentTypes | undefined {
    if (!this.treatmentTypeLookupMap) {
      this.initTreatmentLookupMap();
    }

    return this.treatmentTypeLookupMap.get(treatmentDescription);
  }

  /**
   * Given a treatment, return a string describing how much this treatment
   * will cost approximately.
   */
  lookupCostEstimates(
    treatmentDescription: string
  ): TreatmentCostEstimate | undefined {
    const treatmentType = this.lookupTreatmentType(treatmentDescription);
    if (isNullable(treatmentType)) return;
    return TreatmentDB.getCostEstimate(treatmentType);
  }

  /**
   * Determine the priority of a treatment, based on the findings associated with
   * the treatment.
   *
   * Treatment priority depends on the scope of findings in consideration.
   * It maps one-to-one to the _maximum_ stage of confirmed findings diagnosed
   * on the same tooth of a treatment. For example, if one session has a
   * confirmed advanced caries on tooth 3, and there's a treatment on tooth 3,
   * then this treatment has priority "High" b/c the caries is in advanced stage.
   *
   * The "scope" of findings can vary, depending on if treatments are considered
   * in the context of one image, one session, or bigger.
   *
   * @param treatment the treatment to calculate priority for.
   *
   * @param findings findings from the treatment's "scope"; this could be an
   *   image's finding, a session's finding, or a bigger scope (all of a
   *   patient's findings).
   *
   * @returns a [[`TreatmentPriority`]], or `undefined` if the function could
   * not find any treatment-attributable findings in the provided findings array.
   */
  computeTreatmentPriority(
    treatment: Treatment,
    findings: KellsFinding[]
  ): TreatmentPriority | undefined {
    const findingsForTreatment = this.getFindingsForTreatment(
      treatment,
      findings
    );

    if (findingsForTreatment.length === 0) return;

    const findingsSortedByStage = findingsForTreatment.sort((f1, f2) => {
      if (f1.stage === f2.stage) return 0;

      const _stageMap = {
        [CariesStageTypes.Advanced]: 1,
        [CariesStageTypes.Moderate]: 0,
        [CariesStageTypes.Initial]: -1,
        [CariesStageTypes.Undefined]: -2,
      };

      return _stageMap[f1.stage] - _stageMap[f2.stage];
    });

    const maxStage = findingsSortedByStage[0].stage;

    switch (maxStage) {
      case CariesStageTypes.Advanced:
        return TreatmentPriority.High;
      case CariesStageTypes.Moderate:
        return TreatmentPriority.Medium;
      case CariesStageTypes.Initial:
        return TreatmentPriority.Low;

      default:
        return;
    }
  }

  /**
   * Given a [[`TreatmentPriority`]], map to its textual representation.
   */
  getTreatmentPriorityDescription(
    priority?: TreatmentPriority
  ): "Low" | "Medium" | "High" | "" {
    switch (priority) {
      case TreatmentPriority.High:
        return "High";
      case TreatmentPriority.Medium:
        return "Medium";
      case TreatmentPriority.Low:
        return "Low";
      default:
        return "";
    }
  }

  private initTreatmentLookupMap() {
    this.treatmentTypeLookupMap = new Map<string, TreatmentTypes>();

    const treatments = [
      ...TreatmentDB.getInitialStageTreatments(),
      ...TreatmentDB.getModerageStageTreatments(),
      ...TreatmentDB.getAdvancedStageTreatments(),
      ...TreatmentDB.getWholeMouthTreatments(),
    ];

    treatments.forEach(({ type, description }) => {
      this.treatmentTypeLookupMap.set(description, type);
    });
  }

  formatTreatmentDescription(treatment: string): string {
    const treatmentType = this.lookupTreatmentType(treatment);

    if (isNullable(treatmentType) || treatmentType === TreatmentTypes.Other) {
      return treatment;
    }

    return `${treatmentType}`;
  }

  public isWholeMouthTreatment(treatment: string): boolean {
    return treatment === WHOLE_MOUTH_TREATMENT_LABEL;
  }

  public getFindingsForTreatment(
    treatment: Treatment,
    findings: KellsFinding[]
  ): RenderableFindingBase[] {
    const allowedFindingTypes = [
      FindingType.Caries,
      FindingType.Fracture,
      FindingType.Calculus,
      FindingType.Infection,
      FindingType.DefectiveRestoration,
      FindingType.Plaque,
      FindingType.GumRecession,
      FindingType.GumInflammation,
      FindingType.MissingTooth,
    ];

    return findings.filter(
      (finding) =>
        finding.tooth === treatment.tooth &&
        allowedFindingTypes.includes(finding.type) &&
        finding.status === FindingStatus.Confirmed &&
        FindingService.isFindingRenderable(finding)
    ) as RenderableFindingBase[];
  }
}
