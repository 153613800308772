import { InjectionToken, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedUiAngularModule } from "@kells/shared-ui/angular";
import { OralAnalyticsSharedUiModule } from "@kells/oral-analytics/shared-ui";
import { PatientReportPageComponent } from "./containers/patient-report-page/patient-report-page.component";
import { ReportGridContentComponent } from "./components/report-grid-content/report-grid-content.component";
import { ReportImageFindingsComponent } from "./components/report-image-findings/report-image-findings.component";
import { ReportSectionComponent } from "./components/report-section/report-section.component";
import { ReportTextualRecommendationComponent } from "./components/report-textual-recommendation/report-textual-recommendation.component";
import { RiskFactorButtonComponent } from "./components/risk-factor-button/risk-factor-button.component";
import { RiskAssessmentGaugeComponent } from "./components/risk-assessment-gauge/risk-assessment-gauge.component";
import { CanvasModule } from "@kells/shared-ui/canvas";
import { ReportPersonalInfoComponent } from "./components/report-personal-info/report-personal-info.component";
import { RiskAssessmentComponent } from "./components/risk-assessment/risk-assessment.component";
import { RiskLevelComponent } from "./components/risk-level/risk-level.component";
import { CariesChartsComponent } from "./components/caries-charts/caries-charts.component";
import { ReportTableComponent } from "./components/report-table/report-table.component";
import { ReportHeaderComponent } from "./components/report-header/report-header.component";
import { TreatmentPlanTableComponent } from "./components/treatment-plan-table/treatment-plan-table.component";
import { PreviousTreatmentTableComponent } from "./components/previous-treatment-table/previous-treatment-table.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { CollapsibleContentComponent } from "./components/collapsible-content/collapsible-content.component";
import { TreatmentPriorityCellComponent } from "./components/treatment-priority-cell/treatment-priority-cell.component";
import { TreatmentPriorityFlagComponent } from "./components/treatment-priority-flag/treatment-priority-flag.component";
import { CariesDistReportComponent } from "./components/caries-dist-report/caries-dist-report.component";
import { OralHealthSummaryComponent } from "./components/oral-health-summary/oral-health-summary.component";
import { OralConditionIndicatorComponent } from "./components/oral-condition-indicator/oral-condition-indicator.component";
import { OralSuggestionIndicatorComponent } from "./components/oral-suggestion-indicator/oral-suggestion-indicator.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MouthComponent } from "./components/mouth/mouth.component";
import { ToothDirective } from "./components/mouth/tooth.directive";
import { OralConditionDonutChartComponent } from "./components/oral-condition-donut-chart/oral-condition-donut-chart.component";
import { XRayCarouselComponent } from "./components/xray-carousel/xray-carousel.component";
import { XRayImageComponent } from "./components/xray-carousel/xray-image/xray-image.component";
import { XRayDialogComponent } from "./components/xray-dialog/xray-dialog.component";
import { DataAccessApisModule } from "@kells/apis/data-access";
import { SvgInteractiveDirective } from "./components/xray-carousel/xray-image/svg-interativity.directive";
import { NgLetModule } from "@ngrx-utils/store";
import { BoneLossChartComponent } from "./components/boneloss-chart/boneloss-chart.component";
import { BoneLossIconComponent } from "./components/boneloss-chart/boneloss-icon/boneloss-icon.component";

import {
  BlockScrollStrategy,
  Overlay,
  ScrollStrategy,
} from "@angular/cdk/overlay";
import { SnapshotBonelossChartComponent } from "./components/snapshot-boneloss-chart/snapshot-boneloss-chart.component";
import { BoneLossSnapshotComponent } from "./components/snapshot/boneloss/boneloss-snapshot.component";
import { XRayTabComponent } from "./components/xray-tab/xray-tab.component";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { FooterComponent } from "./components/footer/footer.component";
import { ReportButtonComponent } from "./components/report-button/report-button.component";
import { DentistInfoComponent } from "./containers/patient-report-page/dentist-info/dentist-info.component";
import { RiskChartComponent } from "./components/risk-chart/risk-chart.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { FindingsFilterComponent } from "./components/findings-filter/findings-filter.component";
import { ProductRecommendationGlobalModule } from "./components/product-recommendation/product-recommendation.module";
import { DemoPatientReportPageComponent } from "./containers/patient-report-page/demo/demo-patient-report-page.component";
export const DIALOG_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>(
  "DialogScrollStrategy"
);

export function DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay
): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
// noinspection JSUnusedGlobalSymbols
export const DIALOG_SCROLL_STRATEGY_PROVIDER = {
  provide: DIALOG_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY,
};

@NgModule({
  imports: [
    CommonModule,
    SharedUiAngularModule,
    OralAnalyticsSharedUiModule,
    CanvasModule,
    MatTooltipModule,
    MatExpansionModule,
    MatTableModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    DataAccessApisModule,
    NgLetModule,
    NgxTippyModule,
    ProductRecommendationGlobalModule,
  ],
  declarations: [
    ConfirmationModalComponent,
    BoneLossChartComponent,
    RiskAssessmentGaugeComponent,
    ReportGridContentComponent,
    ReportImageFindingsComponent,
    ReportSectionComponent,
    ReportTextualRecommendationComponent,
    RiskFactorButtonComponent,
    PatientReportPageComponent,
    DemoPatientReportPageComponent,
    DentistInfoComponent,
    ReportPersonalInfoComponent,
    RiskAssessmentComponent,
    RiskLevelComponent,
    CariesChartsComponent,
    ReportTableComponent,
    ReportHeaderComponent,
    TreatmentPlanTableComponent,
    PreviousTreatmentTableComponent,
    CollapsibleContentComponent,
    TreatmentPriorityCellComponent,
    TreatmentPriorityFlagComponent,

    CariesDistReportComponent,
    OralHealthSummaryComponent,
    OralConditionIndicatorComponent,
    OralSuggestionIndicatorComponent,
    MouthComponent,
    FindingsFilterComponent,
    ToothDirective,
    SvgInteractiveDirective,
    OralConditionDonutChartComponent,
    SnapshotBonelossChartComponent,
    RiskChartComponent,
    XRayCarouselComponent,
    XRayImageComponent,
    XRayDialogComponent,
    XRayTabComponent,
    BoneLossIconComponent,
    BoneLossSnapshotComponent,
    FooterComponent,
    ReportButtonComponent,
  ],
  exports: [
    PatientReportPageComponent,
    DemoPatientReportPageComponent,
    DentistInfoComponent,
    RiskFactorButtonComponent,
    RiskAssessmentGaugeComponent,
    MouthComponent,
    ToothDirective,
    OralConditionDonutChartComponent,
    SnapshotBonelossChartComponent,
    RiskChartComponent,
    BoneLossSnapshotComponent,
    XRayCarouselComponent,
    XRayImageComponent,
    BoneLossChartComponent,
    BoneLossIconComponent,
    XRayTabComponent,
    ProductRecommendationGlobalModule,
    // SvgInteractiveDirective
  ],
  providers: [
    DIALOG_SCROLL_STRATEGY_PROVIDER,
    // { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { scrollStrategy: DIALOG_SCROLL_STRATEGY_PROVIDER } }
  ],
})
export class OralAnalyticsReportModule {}
