import { ReviewJob } from "../models";
import { ReviewJobResponse } from "../models/review-job.model";
import parse from "date-fns/parse";

export function formatReviewJob(reviewJob: ReviewJobResponse): ReviewJob {
  const jobDate = parse(
    reviewJob.session_date.split("T")[0],
    "yyyy-MM-dd",
    new Date()
  );
  const jobDateString = [
    jobDate.getFullYear(),
    jobDate.getMonth() + 1,
    jobDate.getDate(),
  ].join("-");
  return {
    id: reviewJob._id,
    patientId: reviewJob.patient,
    completed: reviewJob.completed,
    sessionDate: jobDateString,
    eventCode: reviewJob.event_code,
    organizationCode: reviewJob.organization_code,
    organization: reviewJob.organization,
    provider_details: reviewJob.provider_details,
  };
}
