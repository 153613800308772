import { ElementRef, Injectable } from "@angular/core";
import { isNullable } from "@kells/utils/js";
import { fabric } from "fabric";

export interface ImageRenderSuccess {
  /** Resolution of the rendered image, in pixels. */
  sourceImageResolution: {
    /** Native width of the image rendered, in pixels. */
    width: number;
    /** Native height of the image rendered, in pixels. */
    height: number;
  };
  /** Reference to the fabric.Image instance that has been successfully displayed. */
  backgroundImageElement: fabric.Image;
}

@Injectable()
export class CanvasRenderService {
  constructor() {}

  /**
   * Creates and returns a fabric.Canvas instance for `CanvasComponent`.
   *
   * @param args
   *    - `canvasRef`:
   *        An Angular ElementRef that points to the HTML element that the
   *        fabric.Canvas instance will attach on.
   * @returns
   *    A new `fabric.Canvas` instance.
   */
  static instantiateCanvas(args: { canvasRef: ElementRef }) {
    CanvasRenderService.useCustomFabricTextureSize();

    const { canvasRef: canvasElementRef } = args;

    return new fabric.Canvas(canvasElementRef.nativeElement, {
      // allow objects (e.g. label boxes) to be resized without
      // maintaining their aspect ratio.
      uniformScaling: false,
      //enableRetinaScaling: true,
      // disable group element selection (this enforces only one label box
      // can be selected at a time)
      selection: false,
      preserveObjectStacking: true,
    });
  }



  /**
   * Render an image as a fabric canvas's background.
   *
   * @param canvas
   *    The fabric.Canvas instance where the background image will be rendered.
   * @param imageUrl
   *    URL of the image that will be rendered.
   * @returns
   *    A promise of `ImageRenderSuccess` if resolved, or a promise rejection.
   */
  static renderImageAsBackground(canvas: fabric.Canvas, imageUrl: string) {
    return new Promise<ImageRenderSuccess>((resolve, reject) => {
      const imageBackgroundConfig = {
        left: 0,
        top: 0,
        evented:true,
        selectable:true,
      };

      const imageRenderedCallback = (imageElement: fabric.Image) => () => {
        canvas.renderAll();

        resolve({
          sourceImageResolution: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            width: imageElement.width!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            height: imageElement.height!,
          },
          backgroundImageElement: imageElement,
        });
      };

      fabric.Image.fromURL(
        imageUrl,
        (fabricImageElement) => {
          if (!canvas) {
            return reject("Cannot render image when canvas is not initiated");
          }

          if (
            !fabricImageElement ||
            isNullable(fabricImageElement.height) ||
            isNullable(fabricImageElement.width)
          ) {
            return reject("An error has occurred while loading the image.");
          }

          canvas.setBackgroundImage(
            fabricImageElement,
            imageRenderedCallback(fabricImageElement),
            imageBackgroundConfig
          );
        },
        /**
         * NOTE: this is required for the adjustment mode to work (adjusting
         * image brightness and contrast via cursor movement).
         */
        { crossOrigin: "anonymous" }
      );
    });
  }

  private static useCustomFabricTextureSize() {
    // Increase the default texture size to handle large images.
    //
    // The default `textureSize` is 2048, meaning the maximum image
    // resolution accepted would be 2048x2048. For images with high
    // resolution, the default texture size may result in arbitrary cuts to
    // the original image, especially when image filters are applied.
    // Thus we increase the limit to 4096, per Fabric.JS's recomeendation.
    //
    // See section 'Common errors and problems' in fabricjs.com/fabric-filters
    fabric.textureSize = 4096;
  }
}
