import {
  CariesLocationTypes,
  CariesStageTypes,
  BoxCoordinates,
  RenderableFindingBase,
  MaterialTypes,
  PerioData,
} from "@kells/interfaces/finding";

/** A subset of finding properties required for finding creation / update. */
export interface FindingUpdatePayload {
  tooth: string;
  location: CariesLocationTypes;
  stage: CariesStageTypes;
  box?: BoxCoordinates;
  material?: MaterialTypes;
  severity?:string,
  bone_loss_attributes?: PerioData,
  [k: string]: any;
}

 /*
 properties required for tooth update.
 */
export interface ToothUpdatePayload {
  tooth: string;
  box?: BoxCoordinates;
  //id:string;
  [k: string]: any;
}


export type RenderableFindingCreationPayload = RenderableFindingBase;

export interface RenderState {
  strokeColor: string;
  strokeWidth: number;
  fillColor: string;
  strokeDashArray?: Array<number>;
  strokeLineCap?: string;
}


export enum FindingInteractionStates {
  Deemphasized = 'Deemphasized',
  Up = 'Up',
  Hover = 'Hover',
  Active = 'Active',
  ActiveHover = 'ActiveHover',
  Focus = 'Focus',
  FocusHover = 'FocusHover',
  Hidden = 'Hidden'
}

export interface CanvasLibFinding extends RenderableFindingBase {
  id: string;
  // renderConfig?: {
  //   useDashedLine?: boolean;
  //   /**
  //    * If true, directly applies a semi-transparent tint over this label box.
  //    * The tint color will be computed based on the label box's border color.
  //    *
  //    * Default to `false`.
  //    */
  //   useTint?: boolean;
  //   /**
  //    * If true, applies a semi-transparent tint over any label box that is
  //    * being hovered over.
  //    *
  //    * Default to `true`.
  //    */
  //   useTintOnHover?: boolean;
  //   /**
  //    * Configures whether a user can interact with a label box.
  //    *
  //    * If disabled, user will not be able to perform actions such as edit,
  //    * delete, resize, and drag on the box. Further, any hover-based interaction
  //    * with this box is disabled.
  //    *
  //    * Default to `true`.
  //    */
  //   enableInteraction?: boolean;
  //   /**
  //    * If `true`, displays a label text for this label box irrespective of
  //    * whether the CanvasComponent's global label text display settings.
  //    *
  //    * Default to `false`.
  //    */
  //   showLabelText?: boolean;
  //   /**
  //    * If a text is provided, display the provided string as this item's label.
  //    *
  //    * The canvas supports automatically inferring label texts based on the
  //    * finding object shape. Use this property as an override if the default
  //    * label text does not work.
  //    */
  //   labelText?: string;

  //   strokeWidth?:number;
  // };
}
