import { createAction, props } from "@ngrx/store";
import { ConnectorNotification } from "@app/kells/models/connector.model";

enum ActionTypes {
  ADD_FROM_CONNECTOR = "[Notifications / ConnectorWS] Add",
  MARK_ALL_AS_READ = "[Notifications] Mark All As Read",
}

export const addFromConnector = createAction(
  ActionTypes.ADD_FROM_CONNECTOR,
  props<{ notification: ConnectorNotification }>()
);

export const markAllAsRead = createAction(ActionTypes.MARK_ALL_AS_READ);
