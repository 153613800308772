import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { PatientRecommendation, Treatment } from "@kells/clinic-one/apis";
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  Subject,
} from "rxjs";
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  map,
  filter,
} from "rxjs/operators";
import {
  distinctUntilDeepValueChanged,
  keepDefined,
  pipeLog,
  takeLatest,
} from "@kells/utils/observable/observable-operators";
import { DatePipe } from "@angular/common";
import { capitalize, isDefined, isNullable } from "@kells/utils/js";
import {
  animate,
  animateChild,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DataAccessService } from "@kells/apis/data-access";
import {
  CariesStageTypes,
  FindingsByTooth,
  FindingType,
  IndexedFindingsMap,
  KellsFinding,
  PerioData,
  RenderableFindingBase,
  ToothIconSlot,
  TOOTH_NAMES,
} from "@kells/interfaces/finding";
import { KellsImageBase } from "@kells/interfaces/image";

import { SubSink } from "subsink";
// import { ToothDirective } from './tooth.directive';
import { observeProperty } from "@kells/utils/angular";
import { ToothNumber } from "@kells/interfaces/tooth";
import {
  INITIAL_TO_MODERATE,
  INITIAL_TO_ADVANCED,
  INITIAL_ONLY,
  MODERATE_FROM_INITAL,
  MODERATE_ONLY,
  ADVANCED,
  cariesInitialFocus,
} from "../../theme/colors";

export function calcSeverity(finding: RenderableFindingBase): number {
  return Number(
    finding?.bone_loss_attributes?.measurement_mm ??
      finding?.bone_loss_attributes?.measurement_pixel ??
      1
  );
}

export function calcMeasurement(bone_loss_attributes: PerioData): PerioData {
  let px: number = Math.sqrt(
    Math.hypot(
      bone_loss_attributes.ac_x - bone_loss_attributes.cej_x,
      bone_loss_attributes.ac_y - bone_loss_attributes.cej_y
    )
  );
  bone_loss_attributes.measurement_pixel = +Number(px).toFixed(2);
  // const mm: string = Number(px * 0.26458333).toFixed(2);

  // bone_loss_attributes.measurement_mm = mm;

  return bone_loss_attributes;
}

@Component({
  selector: "kpr-boneloss-snapshot",
  templateUrl: "./boneloss-snapshot.component.html",
  styleUrls: ["./boneloss-snapshot.component.scss"],
})
export class BoneLossSnapshotComponent implements OnInit, OnDestroy {
  @Input() tooth: FindingsByTooth;
  tooth$: Observable<FindingsByTooth | null> = observeProperty(this, "tooth");

  @Input() toothNumber: ToothIconSlot;
  toothNumber$: Observable<ToothIconSlot> = observeProperty(
    this,
    "toothNumber"
  );

  dataReady$ = new BehaviorSubject(false);
  _subs = new SubSink();

  snapshotBonelossBody: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  snapshotBonelossBody$ = this.snapshotBonelossBody
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  BONELOSS_SNAPSHOT_GOOD_BODY =
    "The condition on your bone looks like it is in great shape. There is nothing that requires immediate attention.";
  BONELOSS_SNAPSHOT_FAIR_BODY =
    "It seems like there are areas of your bone that are not at the levels we expect them to be. There are different risk factors that could cause this to happen including tarter build up, smoking, or previous dental restorations.";
  BONELOSS_SNAPSHOT_ADVANCED_BODY =
    "The overall condition of the bone is not looking too great. There seems to be areas of your bone that have a history of accelerated bone loss. There are different risk factors that could cause this to happen including having tartar build up, smoking, and previous dental restorations.";

  constructor(
    private el: ElementRef,
    private datePipe: DatePipe,
    public data: DataAccessService
  ) {}

  ngOnInit() {
    this._subs.sink = this.data.boneLossScore$.subscribe((bonelossScore) => {
      let statusText = "FAIR  (" + bonelossScore + ")";
      if (bonelossScore < 3) {
        this.snapshotBonelossBody.next(this.BONELOSS_SNAPSHOT_GOOD_BODY);
        statusText = "GOOD (" + bonelossScore + ")";
      } else if (bonelossScore >= 5) {
        this.snapshotBonelossBody.next(this.BONELOSS_SNAPSHOT_ADVANCED_BODY);
        statusText = "POOR  (" + bonelossScore + ")";
      } else {
        this.snapshotBonelossBody.next(this.BONELOSS_SNAPSHOT_FAIR_BODY);
      }

      this.dataReady$.next(true);
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  // _measureContainer(): void {
  //   requestAnimationFrame(() => {
  //     const styles = this.el.nativeElement.getBBox();

  //     this.iconWidthSubject.next(Number(styles.width));
  //     this.iconHeightSubject.next(Number(styles.height));
  //   });
  // }
}
