import { combineReducers, MetaReducer } from "@ngrx/store";
import * as fromImages from "./image/image.reducer";
import * as fromPatients from "./patient/patient.reducer";
import * as fromSelectedPatient from "./selected-patient";
import * as fromLayout from "./layout/layout.reducer";
import * as fromNotifications from "./notification/notifications.reducer";
import * as fromPrediction from "./prediction/prediction.reducer";
import * as fromTreatment from "./treatment/treatment.reducer";
import * as fromUserManagement from "./user-management/user-management.reducer";
import * as fromOrganization from "./organization/organizations.reducer";
import { localStorageSyncReducer } from "./meta-reducers";

export const coreModuleFeatureKey = "coreModule";

export interface AppState {
  [fromImages.imageFeatureKey]: fromImages.ImageState;
  [fromPatients.patientFeatureKey]: fromPatients.PatientState;
  [fromSelectedPatient.featureKey]: fromSelectedPatient.FeatureState;
  [fromLayout.layoutFeatureKey]: fromLayout.LayoutState;
  [fromNotifications.featureKey]: fromNotifications.NotificationState;
  [fromPrediction.featureKey]: fromPrediction.PredictionState;
  [fromTreatment.featureKey]: fromTreatment.TreatmentState;
  [fromUserManagement.featureKey]: fromUserManagement.UserManagementState;
  [fromOrganization.featureKey]: fromOrganization.OrganizationState;
}

export const reducers = combineReducers({
  [fromImages.imageFeatureKey]: fromImages.reducer,
  [fromPatients.patientFeatureKey]: fromPatients.reducer,
  [fromSelectedPatient.featureKey]: fromSelectedPatient.reducer,
  [fromLayout.layoutFeatureKey]: fromLayout.reducer,
  [fromNotifications.featureKey]: fromNotifications.reducer,
  [fromPrediction.featureKey]: fromPrediction.reducer,
  [fromTreatment.featureKey]: fromTreatment.reducer,
  [fromUserManagement.featureKey]: fromUserManagement.reducer,
  [fromOrganization.featureKey]: fromOrganization.reducer,
});

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];
