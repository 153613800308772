import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedUiAngularModule } from "@kells/shared-ui/angular";
import { CariesStatusComponent } from "./components/caries-status/caries-status.component";
import { AnalyticsChartsComponent } from "./components/analytics-charts/analytics-charts.component";
import { DonutChartAnalyticComponent } from "./components/donut-chart-analytic/donut-chart-analytic.component";
import { PieChartAnalyticComponent } from "./components/pie-chart-analytic/pie-chart-analytic.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { CariesSeverityDistPieChartComponent } from "./components/caries-severity-dist-pie-chart/caries-severity-dist-pie-chart.component";
import { TippyDirective } from "./directives/tippy/kells-tippy-directive";

@NgModule({
  imports: [
    CommonModule,
    SharedUiAngularModule,
    MatTooltipModule,
    MatIconModule,
  ],
  declarations: [
    CariesStatusComponent,
    AnalyticsChartsComponent,
    DonutChartAnalyticComponent,
    PieChartAnalyticComponent,
    CariesSeverityDistPieChartComponent,
    TippyDirective
  ],
  exports: [
    CariesStatusComponent,
    AnalyticsChartsComponent,
    CariesSeverityDistPieChartComponent,
    TippyDirective
  ],
})
export class OralAnalyticsSharedUiModule {}
