/**
 * Valid options for a caries's location stored under a finding.
 */
export enum CariesLocationTypes {
  Mesial = "Mesial",
  Distal = "Distal",
  Occlusal = "Occlusal",
  Buccal = "Buccal",
  Lingual = "Lingual",
  /**
   * Unrecognized caries location.
   *
   * This location value is used when the model is unable to predict the
   * caries's location. It also acts as a default fallback value.
   */
  Other = "Other",
}

/**
 * Caries location options that a user is able to choose from.
 */
export const cariesLocations: CariesLocationTypes[] = [
  CariesLocationTypes.Mesial,
  CariesLocationTypes.Distal,
  CariesLocationTypes.Occlusal,
  CariesLocationTypes.Buccal,
  CariesLocationTypes.Lingual,
];

export enum CariesStageTypes {
  Initial = "Initial",
  Moderate = "Moderate",
  Advanced = "Advanced",

  /**
   * Fallback value for Findings without an explicitly defined stage.
   */
  Undefined = "",
}

export const cariesStages = [
  CariesStageTypes.Initial,
  CariesStageTypes.Moderate,
  CariesStageTypes.Advanced,
];



export enum BoneLossSeverityTypes {
  Initial = "1",
  Moderate = "2",
  Advanced = "3",
  /**
   * Fallback value for Findings without an explicitly defined stage.
   */
  Undefined = "",
}

export const boneLossSeverities = [
  BoneLossSeverityTypes.Initial,
  BoneLossSeverityTypes.Moderate,
  BoneLossSeverityTypes.Advanced,
];
