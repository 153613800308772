import {
  animate,
  animateChild,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { ANI_ENTER_TIMING, ANI_LONG_TIMING } from "./animation.const";


export const fadeEnter = [
  trigger("fadeEnter", [
    transition(":enter", [
      style({ opacity: 0, transform: "scale(1.2)" }),
      animate(ANI_ENTER_TIMING, style({ opacity: 1, transform: "scale(1)" })),
      animateChild(),
    ]),
  ]),
];

export const fadeLeave = [
  trigger("fadeLeave", [
    transition(":leave", [
      style({ opacity: 1, transform: "scale(1)" }),
      animate(ANI_ENTER_TIMING, style({ opacity: 0, transform: "scale(1.1)" })),
      animateChild(),
    ]),
  ]),
];

export const fadeEnterLeave = [
  trigger("fadeEnterLeave", [
    transition(":enter", [
      style({ opacity: 0, transform: "scale(1.2)" }),
      animate(ANI_LONG_TIMING, style({ opacity: 1, transform: "scale(1)" })),
      animateChild(),
    ]),
    transition(":leave", [
      style({ opacity: 1, transform: "scale(1)" }),
      animate(ANI_LONG_TIMING, style({ opacity: 0, transform: "scale(1.1)" })),
      animateChild(),
    ]),
  ]),
];
