
import { FindingFilterSelectionTypes, FindingStatus, FindingType ,MaterialsFilterSelectionType } from "@kells/interfaces/finding";
import { CanvasLibFinding, KellsFabric } from "@kells/shared-ui/canvas";
import { theme } from "../../../exported-theme";
import BaseFindingObject from "../base-finding-object";
import MaterialStateStyles from "./material-state-styles";
import { fabric } from "fabric";
import { isNullable } from '@kells/utils/js';
import { CanvasContext } from "../../canvas/canvas-context.interface";
import { tap } from "rxjs/operators";
import { BASE_FABRIC_PROPS } from "../fabricstyle.interface";

class MaterialFindingFabric extends BaseFindingObject {

  // static BASE_FABRIC_PROPS = {
  //   strokeUniform: true,
  //   hasRotatingPoint: false, // hide the rotating control above a fabric.Rect
  //   hasBorders: false, // disable the blue border shown when a box is selected
  //   selectable: true,
  //   hasControls: true,
  //   lockScalingX: false,
  //   lockScalingY: false,
  //   lockRotation: true,
  //   noScaleCache: false,
  //   // originX: 'center',
  //   // originY: 'center',
  //   strokeLineCap: 'square',
  //   hoverCursor: 'pointer',
  //   cornerColor: theme.controlFillColor,
  //   cornerStrokeColor: theme.controlStrokeColor,
  //   transparentCorners: false,
  //   cornerSize: theme.controlCornerSize
  // };

  createLabel() {
    if (!this.labelElement && !isNullable(this.object)) {
      const { id, object, styles: style, canvas } = this;


      this.labelElement = fabric.util.makeElement('div', {
        id: `${id}_label`,

      }) as HTMLDivElement;

      this.labelElement.classList.add('kco-finding-label', 'finding-label-material', 'finding-label-' + this.finding.material!.split(' ').join('-'));
      this.labelElement.classList.add('invisible');
      this.labelElement.innerHTML = `
        <div class="label-inner">
        <span class="title-value tooth-value"><span>${this.finding.tooth}</span></span>
        <span class="title-value">${this.finding.material}</span>

        </div>
      `;
      this.canvasElementRef = document.getElementById('overlay-positioning-container') as HTMLDivElement;
      document.body.appendChild(this.labelElement);
      super.createLabel();
    }
  }
  constructor( finding: CanvasLibFinding,
      protected _ctx: CanvasContext,
       ordinality: number) {
    super(finding, _ctx);
    this.ordinality = ordinality;
    this.styles = new MaterialStateStyles(finding.status === FindingStatus.Confirmed);
    this.styles.setUp();
    this.typeKey = MaterialsFilterSelectionType;
    (async ()=>{   
      await this.delayedInit();
      // this.createLabel();
      // if(!isNullable(this.labelElement)) {
      //   this.setPosition(this.labelElement);
      // }
      this.initStyles();
      this._subs.sink = _ctx.findingFilterKeys$.pipe(
        tap((f: FindingFilterSelectionTypes[]) => {
          if (f.includes(MaterialsFilterSelectionType) || finding.id === 'NEW') {
            this.makeVisible();
          } else {
            this.makeHidden();
          }
        })).subscribe();
    })()
  }

  async delayedInit(){
    return new Promise((resolve,reject)=>{
      setTimeout(() => {
        this.initFabricObject();
        resolve(null);  
      },100);
    })
  }


  initFabricObject() {
    super.initFabricObject({
      ...BASE_FABRIC_PROPS,
      originX:'left',
      originY: 'top'
    });
  }


}


export default MaterialFindingFabric;
