<div class="product">
  <div class="product__image-container">
    <img
      [src]="recommendedProduct?.image"
      alt="Product"
      class="product__image"
    />
  </div>
  <div class="product__description">
    <div class="product__description__text">
      <div class="product__description__text-header">
        {{ recommendedProduct?.name }}
      </div>
    </div>
    <a
      (click)="onShopNowClick()"
      [href]="recommendedProduct?.link"
      target="_blank"
      class="product__description__link"
      >Shop Now</a
    >
  </div>
</div>
