import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { KellsOffice } from "../models/office.model";
import * as OfficeApiActions from "./office-api.actions";

export const officeFeatureKey = "office";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OfficeState extends EntityState<KellsOffice> {
  offices: KellsOffice[];
}

export const adapter = createEntityAdapter<KellsOffice>();

const initialState: OfficeState = adapter.getInitialState({
  offices: [],
});

export const reducer = createReducer(
  initialState,
  on(OfficeApiActions.getOfficeInfoSuccess, (state, { office }) =>
    adapter.upsertOne(office, state)
  ),
  on(OfficeApiActions.getAllOfficesSuccess, (state, { offices }) => ({
    ...state,
    offices,
  }))
);
