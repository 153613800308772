import { User } from "@kells/clinic-one/data-access/users";
import { createAction, props } from "@ngrx/store";
import {
  AddUserRequestParams,
  UpdateUserRequestParams,
} from "libs/clinic-one/data-access/users/src/lib/models/user.model";

enum ActionTypes {
  AddUser = "[User Management] Add User",
  AddUserSuccess = "[User Management] Add User Success",
  AddUserFailure = "[User Management] Add User Failure",
  UpdateUser = "[User Management] Update User",
  UpdateUserSuccess = "[User Management] Update User Success",
  UpdateUserFailure = "[User Management] Update User Failure",
  GetUsers = "[User Management] Get Users",
  GetUsersSuccess = "[User Management] Get Users Success",
  GetUsersFailure = "[User Management] Get Users Failure",
}

export const getUsers = createAction(ActionTypes.GetUsers);

export const getUsersSuccess = createAction(
  ActionTypes.GetUsersSuccess,
  props<{ users: User[] }>()
);

export const getUsersFailure = createAction(
  ActionTypes.GetUsersFailure,
  props<{ error: any }>()
);

export const addUser = createAction(
  ActionTypes.AddUser,
  props<{ data: AddUserRequestParams; dialogId: string }>()
);

export const addUserSuccess = createAction(
  ActionTypes.AddUserSuccess,
  props<{ dialogId: string }>()
);

export const addUserFailure = createAction(
  ActionTypes.AddUserFailure,
  props<{ error: any }>()
);

export const updateUser = createAction(
  ActionTypes.UpdateUser,
  props<{ data: UpdateUserRequestParams; dialogId: string }>()
);

export const updateUserSuccess = createAction(
  ActionTypes.UpdateUserSuccess,
  props<{ dialogId: string }>()
);

export const updateUserFailure = createAction(
  ActionTypes.UpdateUserFailure,
  props<{ error: any }>()
);
