import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  CariesPrediction,
  BoneLossPrediction,
  isCariesPrediction,
  isBonelossPrediction,
  isMaterialPrediction,
  isToothPrediction,
  MaterialPrediction,
  MinMaxBoxCoordinates,
  PredictionApiResponse,
  PredictionKinds,
  ToothPrediction,
} from "../models/finding-prediction.model";
import { map } from "rxjs/operators";
import { assertNever, capitalize } from "@kells/utils/js";
import {
  BoxCoordinates,
  CariesLocationTypes,
  CariesStageTypes,
  FindingProcessingService,
  FindingStatus,
  FindingType,
  PerioData,
  RenderableFindingBase,
} from "@kells/interfaces/finding";
import { CanvasResizeService } from "libs/shared-ui/canvas/src/lib/services/canvas-resize/canvas-resize.service";
import { Store } from "@ngrx/store";
import * as fromRoot from "@app/store";
import { AuthActions } from "@kells/clinic-one/auth";
/**
 * Service responsible for generating predicted findings.
 *
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class FindingPredictionService {
  private readonly PREDICTION_BASE_URL =
    "https://gwlx3zino3.execute-api.us-east-1.amazonaws.com/v1/api";

  constructor(private http: HttpClient, private store: Store<fromRoot.RootState>) {}

  /**
   * Produces prediction results, given an image url to predict on.
   *
   * @param imageUrl
   *    the url of the image that prediction service will run on.
   * @param threshold
   *    the caries detection threshold that will be used to generate prediction.
   *    Optional. Provide this value to override the default threshold of 0.1.
   */
   checkTokenExpiry(){
    var predictionAuth = window.localStorage.getItem("prediction_auth");
    const authBody = predictionAuth ? JSON.parse(predictionAuth) : null;
    const token_expiry = authBody.expiry_time
    let current_time = new Date();
    current_time.setSeconds(current_time.getSeconds());
     return current_time > token_expiry ? true : false;
}
  getPredictions(
    imageUrl: string,
    threshold = 0.1
  ): Observable<PredictionKinds[]> {
    if(this.checkTokenExpiry()){
      this.store.dispatch(AuthActions.logout());
    }
    return this.http
      .post<PredictionApiResponse>(
        `${this.PREDICTION_BASE_URL}/finding/predict`,
        {
          image_url:imageUrl
        },
    
      )
      .pipe(
        map((ps) => ps.data),
        map((data) => [
          ...data.boneloss,
          ...data.caries,
          ...data.materials,
          ...data.tooth.data,
        ])
      );
  }

  static parsePredictionAsFinding(
    prediction: PredictionKinds
  ): RenderableFindingBase & { score: number } {
    if (isBonelossPrediction(prediction)) {
      return FindingPredictionService.parseBonelossPredictionAsFinding(
        prediction as BoneLossPrediction
      );
    }

    if (isCariesPrediction(prediction)) {
      return FindingPredictionService.parseCariesPredictionAsFinding(
        prediction
      );
    } else if (isToothPrediction(prediction)) {
      return FindingPredictionService.parseToothPredictionAsFinding(prediction);
    } else if (isMaterialPrediction(prediction)) {
      return FindingPredictionService.parseMaterialPredictionAsFinding(
        prediction
      );
    }

    return assertNever("unrecognized prediction type.");
  }

  private static parseCariesPredictionAsFinding(
    prediction: CariesPrediction
  ): RenderableFindingBase & { score: number } {
    return {
      box: prediction.box,
      type: FindingPredictionService.resolveFindingTypeFromPrediction(
        prediction
      ),
      location: FindingPredictionService.resolvePredictedCariesLocation(
        prediction.location
      ),
      tooth: prediction.tooth ?? "",
      stage: FindingProcessingService.resolveCariesStage(prediction.stage),
      score: prediction.score,
    };
  }

  static parseBoneLossAttributes(prediction: BoneLossPrediction): PerioData {
    return {
      cej_x: prediction.cej_x,
      cej_y: prediction.cej_y,
      ac_x: prediction.ac_x,
      ac_y: prediction.ac_y,
      measurement_mm: prediction.length_in_mm,
      severity: prediction.stage,
      tooth: prediction.tooth ?? "",
    };
  }

  static parseBonelossPredictionAsFinding(
    prediction: BoneLossPrediction
  ): RenderableFindingBase & { score: number } {
    const finding = {
      bone_loss_attributes: FindingPredictionService.parseBoneLossAttributes(
        prediction
      ),
      location: CariesLocationTypes.Other,
      stage: CariesStageTypes.Undefined,
      type: FindingType.BoneLoss,
      tooth: prediction.tooth ?? "",
      status: FindingStatus.Predicted,
      score: prediction.score,
      box: [prediction.cej_x, prediction.cej_y, prediction.ac_x, prediction.ac_y],
    } as RenderableFindingBase & { score: number };

    console.info(
      "  ######################        parseBonelossPredictionAsFinding",
      finding
    );
    return finding;
  }

  private static parseToothPredictionAsFinding(
    prediction: ToothPrediction
  ): RenderableFindingBase & { score: number } {
    return {
      box: FindingPredictionService.parseMinMaxBoxCoordinates(prediction),
      type: FindingPredictionService.resolveFindingTypeFromPrediction(
        prediction
      ),
      location: CariesLocationTypes.Other,
      stage: CariesStageTypes.Undefined,
      tooth: prediction.label,
      score: prediction.score,
    };
  }

  private static parseMaterialPredictionAsFinding(
    prediction: MaterialPrediction
  ): RenderableFindingBase & { score: number } {
    return {
      box: FindingPredictionService.parseMinMaxBoxCoordinates(prediction),
      type: FindingPredictionService.resolveFindingTypeFromPrediction(
        prediction
      ),
      location: CariesLocationTypes.Other,
      stage: CariesStageTypes.Undefined,
      tooth: prediction.tooth ?? "",
      material: prediction.label,
      score: prediction.score,
    };
  }

  /**
   * Given a predicted location string from the prediction service, map it to
   * one of the [[`CariesLocationTypes`]].
   *
   * @param predictedLocation a string of the predicted location as returned
   *    by the prediction API.
   *
   * @returns one of the [[`CariesLocationTypes`]].
   */
  static resolvePredictedCariesLocation(
    predictedLocation: string
  ): CariesLocationTypes {
    let _loc = capitalize(predictedLocation);

    // handle a special case where caries location parsing is more complicated
    // than simply capitalizing the first letter.
    if (_loc === "Others") _loc = CariesLocationTypes.Other;

    const validLocationOptions = new Set(Object.keys(CariesLocationTypes));
    if (validLocationOptions.has(_loc)) {
      return _loc as CariesLocationTypes;
    }

    return assertNever(
      `Unrecognized caries location type in prediction: '${predictedLocation}'`
    );
  }

  /**
   * Given an object that extends [[`MinMaxBoxCoordinates`]], transform the
   * coordinates to a form that follows [[`MinMaxBoxCoordinates`]].
   *
   * @param o the object to parse `MinMaxBoxCoordinates` from.
   * @returns a `BoxCoordinates`
   */
  static parseMinMaxBoxCoordinates<T extends MinMaxBoxCoordinates>(
    o: T
  ): BoxCoordinates {
    const left = o.xmin;
    const top = o.ymin;
    const width = o.xmax - o.xmin;
    const height = o.ymax - o.ymin;

    return [left, top, width, height];
  }

  /**
   * Given a prediction, determines the finding type that is should map to.
   *
   * @returns A [[`FindingType`]] that the provided prediction corresponds to.
   */
  private static resolveFindingTypeFromPrediction(
    p: PredictionKinds
  ): FindingType {
    if (isToothPrediction(p)) return FindingType.Tooth;
    if (isMaterialPrediction(p)) return FindingType.Material;
    if (isBonelossPrediction(p)) return FindingType.BoneLoss;
    return FindingType.Caries;
  }
}
