import { ReportService } from "./../../../../../../../libs/oral-analytics/report/src/lib/services/report.service";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { apiEnvironment } from "@kells/clinic-one/environments";

type OptioVideo = {
  id: string;
  title: string;
  subtitle: string;
  category: {
    id: string;
    title: string;
  };
  thumbnail_url: string;
  captions_url: string;
  has_slides: boolean;
};

interface OptioVideoList {
  id: string;
  title: string;
  description: string;
  videos: OptioVideo[];
}

@Component({
  selector: "video-dialog",
  templateUrl: "video-dialog.component.html",
})
export class VideoDialogComponent implements OnInit {
  constructor(
    public reportService: ReportService,
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {}

  optioVideoList: OptioVideoList[] = [];
  optioToken = "";
  isVideoLoaded = false;

  ngOnInit() {}

  onCancel(): void {
    this.dialogRef.close();
  }
  isLoading = 0;
  isVideoAvailable = true;
  onLoad() {
    this.isLoading += 1;
  }
  viewVideo() {
    if (this.isVideoLoaded) return;
    this.optioVideoList = this.reportService.getVideoList();
    this.optioToken = this.reportService.getToken();

    const dentistryVideos = this.optioVideoList[0].videos;
    let matchedVideo = dentistryVideos.find((video) =>
      video.id.includes(this.data.name.toLowerCase().split(" ").join("_"))
    );
    if (!matchedVideo)
      matchedVideo = dentistryVideos.find((video) =>
        video.id.includes(this.data.name.split(" ")[0].toLowerCase())
      );
    if (!matchedVideo) this.isVideoAvailable = false;
    if (matchedVideo) {
      const optioVideoUrl = `${apiEnvironment.optioBaseUrl}/embed/player/?video=`;
      const videoUrl = `${optioVideoUrl}${matchedVideo?.id}&token=${this.optioToken}`;
      this.isVideoLoaded = true;
      return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    }
    this.isVideoLoaded = true;
    this.isLoading += 1;
  }
}
