import { OrganizationNames } from "../enums/organization-names.enum";
import { OrganizationContentSettings } from "../models/organization-content-settings.interface";

export const ORGANIZATION_CONTENT_SETTINGS: Partial<
  Record<OrganizationNames, OrganizationContentSettings>
> = {
  [OrganizationNames.Aetna]: {
    reportLogo: "/assets/punch/images/aetna-logo.svg",
    scheduleAppointmentLink: "https://www.theteledentists.com/aetna",
  },
  [OrganizationNames.Teledentists]: {
    reportLogo: "/assets/punch/images/kells-teledentists.svg",
    scheduleAppointmentLink: "https://ai.theteledentistsvideo.com",
    scheduleAppointmentDescription: `
      Schedule a virtual consultation with a remote dentist in your state to go over the report and discuss any questions or needs you may have. Use Service Key “KELLS” when creating an account with The TeleDentists for your discounted consultation.
    `,
  },
  [OrganizationNames.KareMobile]: {
    reportLogo: "/assets/punch/images/kells-kare.svg",
  },
};
