import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Params,
} from "@angular/router";
import * as fromRouter from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export const routerFeatureKey = "routerReducer";

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>(routerFeatureKey);

@Injectable()
export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  // eslint-disable-next-line class-methods-use-this
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    const { params } = CustomSerializer._extractLeafState(routerState.root);
    return { url, queryParams, params };
  }

  private static _extractLeafState(state: ActivatedRouteSnapshot): any {
    return state.firstChild
      ? CustomSerializer._extractLeafState(state.firstChild)
      : state;
  }
}

export const getUrl = createSelector(
  getRouterState,
  (router) => router.state.url
);

export const getParams = createSelector(
  getRouterState,
  (router) => router.state.params
);

export const getQueryParams = createSelector(
  getRouterState,
  (router) => router.state.queryParams
);
