/**
 * Capitalize a certain string.
 *
 * Given a string, returns another string that is the same as the input string,
 * but with the first character capitalized.
 *
 * For instance, given input `test cases`, the function will return `Test cases`.
 *
 * @param s the string to perform capitalization on.
 */
export const capitalize = (s: string) => {
  if (s.length === 0) return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
