import { isDefined } from "@kells/utils/js";
import { createSelector } from "@ngrx/store";
import { selectCoreModule } from "../module.selector";
import {
  PredictionState,
  PredictionInitializationSources,
} from "./prediction.reducer";

const getPatientPrediction = (
  state: PredictionState,
  patientId: string,
  source: PredictionInitializationSources
) => {
  const patientPredictionState = state.predictions[patientId];

  if (!patientPredictionState) return;

  return patientPredictionState[source];
};

const getProgressNumber = (
  state: PredictionState,
  patientId: string,
  source: PredictionInitializationSources
): number | undefined => {
  if (!patientId) return;

  if (!state.predictions[patientId]) return;
  if (!state.predictions[patientId][source]) return;

  const progressObj = state.predictions[patientId][source]?.progress;

  if (!progressObj) return;

  return progressObj.completed / progressObj.total;
};

export const selectPredictionState = createSelector(
  selectCoreModule,
  (module) => module.prediction
);

export const isRunningStoryboardPrediction = createSelector(
  selectPredictionState,
  (state: PredictionState, props: { patientId: string }): boolean => {
    return isDefined(
      getPatientPrediction(state, props.patientId, "storyboard")
    );
  }
);

export const isRunningSessionPrediction = createSelector(
  selectPredictionState,
  (state: PredictionState, props: { patientId: string }): boolean => {
    return isDefined(getPatientPrediction(state, props.patientId, "session"));
  }
);

export const getStoryboardPredictionProgress = createSelector(
  selectPredictionState,
  (
    state: PredictionState,
    props: { patientId: string }
  ): number | undefined => {
    return getProgressNumber(state, props.patientId, "storyboard");
  }
);

export const getSessionPredictionProgress = createSelector(
  selectPredictionState,
  (
    state: PredictionState,
    props: { patientId: string }
  ): number | undefined => {
    return getProgressNumber(state, props.patientId, "session");
  }
);

export const isRunningImageDetailPrediction = createSelector(
  selectPredictionState,
  (state: PredictionState, props: { patientId: string }): boolean => {
    if (!isDefined(state.predictions[props.patientId])) return false;

    return isDefined(state.predictions[props.patientId]["image-detail"]);
  }
);
