import { Component, ChangeDetectionStrategy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { URLService } from "../../../services";

@Component({
  selector: "kpr-payment-success-modal",
  templateUrl: "./payment-success-modal.component.html",
  styleUrls: ["./payment-success-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentSuccessModalComponent {
  constructor(
    private readonly dialog: MatDialog,
    private readonly urlService: URLService
  ) {}

  public goToDashboard(): void {
    this.urlService.removeQueryParam("payment");
    this.dialog.closeAll();
  }
}
