import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { DataAccessService } from "@kells/apis/data-access";
import { format, parse } from "date-fns";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { RecommendedProductTabs } from "../../shared/enums/recommended-product-tabs.enum";
import { FindingsByImage } from "@kells/interfaces/finding";
import { LinkParams } from "../../shared/interfaces/link-params.interface";

@Component({
  selector: "kpr-product-recommendation-page",
  templateUrl: "./product-recommendation-page.component.html",
  styleUrls: ["./product-recommendation-page.component.scss"],
})
export class ProductRecommendationPageComponent implements OnInit, OnDestroy {
  @Input() public isInreportRecommendation: boolean;
  @Input() public latestReviewDate$: Observable<string>;
  @Input() public latestReviewId$: Observable<string>;
  @Input() public patientId$: Observable<string>;
  @Output() public linkClicked = new EventEmitter<LinkParams>();
  tabs: RecommendedProductTabs[];
  private _subs = new SubSink();
  public reportData$: Observable<FindingsByImage[]>;
  public hasCaries$: Observable<boolean>;
  public hasGumInflammation$: Observable<boolean>;
  public hasBoneLoss$: Observable<boolean>;
  public tabs$: Observable<RecommendedProductTabs[]>;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  constructor(public data: DataAccessService) {}

  ngOnInit(): void {
    if (!this.data.reportForm) {
      this.data.initReport();
    }
    this.initObservables();

    this._subs.sink = this.latestReviewId$
      .pipe(take(1))
      .subscribe((selectedSession) => {
        if (selectedSession) this.data.updateSelectedSession(selectedSession);
      });
    this._subs.sink = this.tabs$.subscribe((tabs) => {
      this.tabs = tabs;
    });
  }

  initObservables() {
    this.reportData$ = this.latestReviewDate$.pipe(
      tap((latestReviewDate) => {
        if (!latestReviewDate) {
          this.isLoading$.next(false);
        }
      }),
      switchMap((latestReviewDate) =>
        this.data.reportDataLoadComplete$.pipe(
          map(({ findingsByImage }) => {
            return latestReviewDate
              ? Array.from(findingsByImage?.values() || []).filter((image) => {
                  const sessionDate = parse(
                    image.session.split("T")?.[0]
                      ? image.session.split("T")[0]
                      : image.session,
                    "yyyy-MM-dd",
                    new Date()
                  );
                  const reviewDate = parse(
                    latestReviewDate.split("T")?.[0]
                      ? latestReviewDate.split("T")[0]
                      : latestReviewDate,
                    "yyyy-MM-dd",
                    new Date()
                  );

                  return (
                    format(sessionDate, "MM/dd/yyyy") ===
                    format(reviewDate, "MM/dd/yyyy")
                  );
                })
              : [];
          })
        )
      ),
      shareReplay(1)
    );
    this.hasCaries$ = this.reportData$.pipe(
      map((findingsByImage: FindingsByImage[]) => {
        return findingsByImage.some((findings) => !!findings.allCaries.length);
      })
    );

    this.hasGumInflammation$ = this.reportData$.pipe(
      map((findingsByImage: FindingsByImage[]) => {
        return findingsByImage.some(
          (findings) => !!findings.gumInflammation.length
        );
      })
    );

    this.hasBoneLoss$ = this.reportData$.pipe(
      map((findingsByImage: FindingsByImage[]) => {
        return findingsByImage.some((findings) => !!findings.boneLoss.length);
      })
    );

    this.tabs$ = combineLatest([
      this.hasBoneLoss$,
      this.hasGumInflammation$,
      this.hasCaries$,
      this.latestReviewId$,
    ]).pipe(
      map(([hasBoneLoss, hasGumInflammation, hasCaries, latestReviewId]) => {
        const tabs = [];

        if (latestReviewId) tabs.push(RecommendedProductTabs.GeneralHygiene);
        if (hasBoneLoss) tabs.push(RecommendedProductTabs.BoneLoss);
        if (hasGumInflammation)
          tabs.push(RecommendedProductTabs.GumInflammation);
        if (hasCaries) tabs.push(RecommendedProductTabs.Caries);

        return tabs;
      }),
      tap(() => {
        this.isLoading$.next(false);
      })
    );
  }

  linkClickedHandler(params: LinkParams) {
    this.linkClicked.emit(params);
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
