import { createReducer, on, Action } from "@ngrx/store";

import * as SelectedPatientActions from "./selected-patient.actions";
import * as PatientActions from "../patient/patient.actions";
import * as LayoutActions from "@app/core/store/layout/layout.actions";
import { ImageActions, ImageClassificationActions } from "../image/actions";
import { ReviewJobActions } from "@kells/clinic-one/data-access/review-jobs";

export const featureKey = "selectedPatient";

export interface FeatureState {
  readonly id: string | null;
  readonly loading: boolean;
  readonly failed: boolean;
  readonly tab: string;
  readonly startPredictingFrom: string;
  readonly isOrdering: boolean;
  readonly sessionSelection: {
    readonly selectLatest: boolean;
    readonly selectAll: boolean;
    readonly selected: string[];
  };
  readonly classification: {
    isClassifying: boolean;
    classifiedCount: number;
    totalClassificationCount: number;
  };
  readonly isCreatingSession: boolean;
}

export const initialState: FeatureState = {
  id: null,
  loading: false,
  failed: false,
  tab: "",
  startPredictingFrom: "",
  isOrdering: false,
  sessionSelection: {
    selectLatest: true,
    selectAll: false,
    selected: [],
  },
  classification: {
    isClassifying: false,
    classifiedCount: 0,
    totalClassificationCount: 0,
  },
  isCreatingSession: false,
};

const selectedPatientReducer = createReducer(
  initialState,
  on(
    SelectedPatientActions.selectPatientFromPatientHome,
    SelectedPatientActions.selectPatientFromProductTour,
    (state, { patientId: id }) => ({
      ...state,
      id,
      sessionSelection: {
        selectAll: false,
        selectLatest: true,
        selected: [],
      },
    })
  ),
  on(SelectedPatientActions.selectSessionDates, (state, { sessionDates }) => ({
    ...state,
    sessionSelection: {
      selectAll: false,
      selectLatest: false,
      selected: sessionDates,
    },
  })),
  on(SelectedPatientActions.selectAllSessionDates, (state) => ({
    ...state,
    sessionSelection: { ...state.sessionSelection, selectAll: true },
  })),
  on(SelectedPatientActions.undoSelectAllSessonDates, (state) => ({
    ...state,
    sessionSelection: { ...state.sessionSelection, selectAll: false },
  })),
  on(ReviewJobActions.openReviewJob, (state, { reviewJob }) => ({
    ...state,
    id: reviewJob.patientId,
    sessionSelection: {
      selectAll: false,
      selectLatest: false,
      selected: [reviewJob.sessionDate],
    },
  })),
  on(SelectedPatientActions.selectActiveTab, (state, { tab }) => ({
    ...state,
    tab,
  })),
  on(
    SelectedPatientActions.setStartPredictingFrom,
    (state, { startPredictingFrom }) => ({
      ...state,
      startPredictingFrom,
    })
  ),
  on(
    PatientActions.fetchPatient,
    PatientActions.fetchXVWebPatientFromPatientHome,
    (state) => ({ ...state, loading: true })
  ),
  on(
    PatientActions.fetchPatientFailure,
    PatientActions.fetchXVWebPatientFromPatientHomeFailure,
    (state) => ({ ...state, loading: false, failed: true })
  ),
  on(
    PatientActions.fetchPatientSuccess,
    PatientActions.fetchXVWebPatientFromPatientHomeSuccess,
    (state) => ({ ...state, loading: false, failed: false })
  ),
  on(ImageActions.autoArrangeImageOrder, (state) => ({
    ...state,
    isOrdering: true,
  })),
  on(
    ImageActions.autoArrangeImageOrderSuccess,
    ImageActions.autoArrangeImageOrderFailure,
    (state) => ({ ...state, isOrdering: false })
  ),
  on(LayoutActions.homeLogoClicked, (state) => ({
    ...state,
    id: null,
  })),
  on(ImageClassificationActions.classificationStart, (state, { imageIds }) => ({
    ...state,
    classification: {
      isClassifying: true,
      classifiedCount: 0,
      totalClassificationCount: imageIds.length,
    },
  })),
  on(
    ImageClassificationActions.classifyImageSuccess,
    ImageClassificationActions.classifyImageFailure,
    (state) => {
      const nextClassifiedCount = Math.min(
        state.classification.classifiedCount + 1,
        state.classification.totalClassificationCount
      );

      if (
        nextClassifiedCount === state.classification.totalClassificationCount
      ) {
        return {
          ...state,
          classification: {
            isClassifying: false,
            classifiedCount: 0,
            totalClassificationCount: 0,
          },
        };
      }

      return {
        ...state,
        classification: {
          ...state.classification,
          classifiedCount: nextClassifiedCount,
        },
      };
    }
  ),
  on(PatientActions.createSession, (state) => ({
    ...state,
    isCreatingSession: true,
  })),
  on(
    PatientActions.createSessionFailure,
    PatientActions.createSessionSuccess,
    (state) => ({
      ...state,
      isCreatingSession: false,
    })
  )
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return selectedPatientReducer(state, action);
}

export const getId = (state: FeatureState) => state.id;

export const getTab = (state: FeatureState) => state.tab;

export const getStartPredictingFrom = (state: FeatureState) =>
  state.startPredictingFrom;

export const getLoadingStatus = (state: FeatureState) => state.loading;

export const getFailedStatus = (state: FeatureState) => state.failed;

export const isOrderingImages = (state: FeatureState) => state.isOrdering;

export const isClassifying = (state: FeatureState) =>
  state.classification.isClassifying;

export const classificationProgress = (state: FeatureState) => {
  if (!state.classification.isClassifying) return 0;
  if (state.classification.totalClassificationCount === 0) return 0;

  return (
    state.classification.classifiedCount /
    state.classification.totalClassificationCount
  );
};

export const isCreatingPatientSession = (state: FeatureState) => {
  return state.isCreatingSession;
};
