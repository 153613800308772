import { ElementRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CanvasService {
  constructor() {}

  /**
   * The distance between the left edge of the screen to the left edge of the
   * canvas, in pixels.
   *
   * @returns `undefined` if this property is accessed before the canvas has
   *     been initialized, otherwise the canvas left offset in pixels.
   */
  static getCanvasLeftOffset(
    canvas: fabric.Canvas | null | undefined
  ): number | undefined {
    if (!canvas) return undefined;

    // explicitly casting to `any` to access a property internal to fabric.Canvas.
    return (canvas as any)._offset.left;
  }

  /**
   * The distance between the top edge of the screen and the top edge of the
   * canvas, in pixels.
   *
   * @returns `undefined` if this property is accessed before the canvas has
   *     been initialized, otherwise the canvas left offset in pixels.
   */
  static getCanvasTopOffset(
    canvas: fabric.Canvas | null | undefined
  ): number | undefined {
    if (!canvas) return undefined;

    // explicitly casting to `any` to access a property internal to fabric.Canvas.
    return (canvas as any)._offset.top;
  }

  /**
   * Applies a dictionary of new styles to a given
   * `ElementRef<T extends HTMLElement>`.
   */
  static updateNativeElementStyle<T extends HTMLElement>(
    element: ElementRef<T>,
    newStyle: { [k: string]: string }
  ): ElementRef<T> {
    const nextElem = element;
    Object.entries(newStyle).forEach(([k, v]) => {
      nextElem.nativeElement.style[k as any] = v;
    });
    return nextElem;
  }
}
