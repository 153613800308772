import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  Organization,
  OrganizationResponse,
} from "@app/core/models/organization.model";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

interface GetOrganizationsResponse {
  data: OrganizationResponse[];
}

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class OrganizationService {
  private organizationServiceBaseUrl = `${this.baseUrl}/${this.apiVersion}/organizations`;
  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient
  ) {}
  public getAllOrganizations(): Observable<Organization[]> {
    return this.http
      .get<GetOrganizationsResponse>(this.organizationServiceBaseUrl)
      .pipe(
        map(({ data }) =>
          data.map((organization) =>
            this.parseOrganizationResponse(organization)
          )
        )
      );
  }

  public parseOrganizationResponse(
    organization: OrganizationResponse
  ): Organization {
    return {
      id: organization._id,
      name: organization.name,
      archived: organization.archived,
    };
  }
}
