import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";
import { AppContainerComponent } from "apps/patient-reporting/src/app/shared/components/app-container/app-container.component";
import { DemoReportComponent } from "./home/containers/demo-report/demo-report.component";
import { DemoPracticeReportComponent } from "./home/containers/demo-practice-report/demo-practice-report.component";

export const rootRoutes: Routes = [
  {
    path: "app/report/demo",
    component: DemoReportComponent,
  },
  {
    path: "app/report/demo/practice",
    component: DemoPracticeReportComponent,
  },
  {
    path: "",
    component: AppContainerComponent,
    children: [
      {
        path: "app",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },
      { path: "**", redirectTo: "app" },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(rootRoutes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
