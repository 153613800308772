<div class="container">
  <div class="form-wrapper py-2">
    <form [formGroup]="editForm">
      <div>
      <mat-form-field appearance="fill" style="width: 5rem">
        <mat-label>Tooth</mat-label>
        <input #tooth_field matInput formControlName="tooth" autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="fill" style="width: 9rem">
        <mat-label>Location</mat-label>
        <mat-select formControlName="location">
          <mat-option *ngFor="let cariesLocation of cariesLocations" [value]="cariesLocation">
            {{ cariesLocation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>

<div>
      <mat-form-field appearance="fill" style="width: 15rem">
        <mat-label>Stage</mat-label>
        <mat-select formControlName="stage">
          <mat-option
            *ngFor="let cariesStage of cariesStages"
            [value]="cariesStage"
          >
            {{ cariesStage }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div class="error-container" *ngIf="editFormTooth?.errors">
        <span *ngIf="editFormTooth?.dirty && editFormTooth?.errors?.required">
           <i class="fa fas fa-exclamation-triangle"></i> Must specify a tooth.
        </span>

        <span *ngIf="editFormTooth?.errors?.requireSingleLetter">
           <i class="fa fas fa-exclamation-triangle"></i> This field must be within 1 - 32. Zero indicates unknown.
        </span>

        <span *ngIf="editFormTooth?.errors?.invalidSingleLetter">
          <i class="fa fas fa-exclamation-triangle"></i>  Please type in a letter from A - T.
        </span>

        <span *ngIf="editFormTooth?.errors?.invalidNumber">
          <i class="fa fas fa-exclamation-triangle"></i>  Tooth number must be within 1 - 32.
        </span>

        <span *ngIf="editFormTooth?.errors?.noLeadingZeroes">
          <i class="fa fas fa-exclamation-triangle"></i>  Tooth number cannot be 0 (indicates unknown).
        </span>

        <span *ngIf="editFormTooth?.errors?.noNegativeValues">
          <i class="fa fas fa-exclamation-triangle"></i>  Tooth value cannot be negative.
        </span>

        <span *ngIf="editFormTooth?.errors?.invalidToothLocation">
         <i class="fa fas fa-exclamation-triangle"></i> Invalid tooth location.
        </span>

        <span *ngIf="editFormTooth?.dirty && editFormTooth?.errors?.noToothLocation">
         <i class="fa fas fa-exclamation-triangle"></i> Invalid tooth location.
        </span>

        <span *ngIf="(isImageConfirmed$ | async)" style="color:cornflowerblue">
          <i class="fa fas fa-info-circle"></i> Image findings are already confirmed.
        </span>
      </div>
    </form>
  </div>
  <div class="edit-label-btns py-2">
    <button
      mat-flat-button
      class="save"
      color="primary"
      [ngClass]="{ disabled: !editForm.valid}"
      [disabled]="!editForm.valid || (isImageConfirmed$ | async)"
      (click)="onSaveClicked()"
    >
      Save
    </button>
    <button mat-stroked-button class="cancel" (click)="onCancelClicked()">
      Cancel
    </button>

    <button
      *ngIf="showDeletButton"
      mat-stroked-button
      class="delete"
      [disabled]="isImageConfirmed$ | async"
      (click)="onDeleteClicked()"
    >
      Delete
    </button>
  </div>
</div>
