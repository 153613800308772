import { Component, Input } from '@angular/core';

@Component({
  selector: 'koa-report-button',
  templateUrl: './report-button.component.html',
  styleUrls: ['./report-button.component.scss'],
})
export class ReportButtonComponent {
  @Input() isLoading = false;
  @Input() xs = false;
  @Input() sm = false;
}
