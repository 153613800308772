import { Inject, Injectable } from "@angular/core";
import { Observable, forkJoin, from, of } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { pluck, filter, map, concatMap, toArray } from "rxjs/operators";
import { keepUnique, isDefined, isNullable } from "@kells/utils/js";
import {
  Finding,
  isFinding,
  RenderableFinding,
  FindingCreationPayload,
  DummyFinding,
} from "../models/finding.model";
import {
  FindingDTO,
  FindingApiResponse,
  FindingDTOPatch,
} from "../models/finding-api.model";
import {
  BoxCoordinates,
  CariesStageTypes,
  FindingProcessingService,
  FindingStatus,
  FindingType,
  NonDummyFindingType,
  PerioData,
} from "@kells/interfaces/finding";
import {
  BoneLossPrediction,
  FindingPredictionService,
  isBonelossPrediction,
  isCariesPrediction,
  isMaterialPrediction,
  isToothPrediction,
  PredictionKinds,
} from "@kells/apis/ml";
import { CanvasLibFinding } from "@kells/shared-ui/canvas";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";
import { FindingAdapter } from "../adapters/finding.adapter";
import { TreatmentDB } from "../db/treatment.db";

enum CariesStagesNumericalIdentifier {
  Undefined,
  Initial,
  Moderate,
  Advanced,
}

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class FindingService {
  /**
   * Creates a finding service url, based on a specific image ID.
   *
   * @param imageId the ID of the image you're requesting findings for
   */
  findingServiceBaseUrl = (imageId: string) =>
    `${this.baseUrl}/${this.apiVersion}/images/${imageId}/findings`;

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient
  ) {}

  getFindings(imageId: string): Observable<Finding[]> {
    return this.http
      .get<FindingApiResponse>(this.findingServiceBaseUrl(imageId))
      .pipe(
        pluck("data"),
        filter((data): data is FindingDTO[] => data !== undefined),
        map((fs) => fs.map(FindingService.numerifyToothNumber)),
        map((fs) => fs.filter(isFinding)),
        map((fs) => fs.filter(keepUnique)),
        map((fs) => fs.map(FindingAdapter.toFinding))
      );
  }

  createFinding(imageId: string, newFinding: FindingCreationPayload) {
    if (
      newFinding.type === FindingType.BoneLoss &&
      !isDefined(newFinding.bone_loss_attributes)
    ) {
      throw new Error("Boneloss finding missing bone_loss_attributes object");
    }
    return this.http.post(
      this.findingServiceBaseUrl(imageId),
      FindingAdapter.toDTO(newFinding)
    );
  }

  editFinding(
    imageId: string,
    findingBeforeModification: Finding,
    editedForm: Partial<Finding>
  ): Observable<unknown> {
    let updatedFinding: Partial<Finding>;
    if (findingBeforeModification.type === FindingType.BoneLoss) {
      let { bone_loss_attributes } = editedForm;
      const {
        id,
        predictionScore,
        status,
        type,
        location,
        tooth,
        box,
      } = findingBeforeModification;
      if (!bone_loss_attributes) {
        bone_loss_attributes = findingBeforeModification.bone_loss_attributes;
      }
      updatedFinding = {
        id: id as string,
        predictionScore, //:`${predictionScore}`,
        status: editedForm.status, //: `${status}`,
        type: FindingType.BoneLoss, //: `${type}`,
        location, //: `${location}`,
        tooth: editedForm.tooth ? editedForm.tooth : tooth,
        bone_loss_attributes: {
          ac_x: bone_loss_attributes?.ac_x as number,
          ac_y: bone_loss_attributes?.ac_y as number,
          cej_x: bone_loss_attributes?.cej_x as number,
          cej_y: bone_loss_attributes?.cej_y as number,
          measurement_mm: bone_loss_attributes?.measurement_mm
            ? +bone_loss_attributes?.measurement_mm
            : 0,
          severity: bone_loss_attributes?.severity,
          tooth: tooth,
        },
      };
    } else {
      updatedFinding = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(findingBeforeModification as any),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(editedForm as any),
      };
    }

    const sendableDTO: FindingDTOPatch = FindingAdapter.toDTO(updatedFinding);
    console.info("sending DTO", sendableDTO);
    return this.http.patch(
      `${this.findingServiceBaseUrl(imageId)}/${findingBeforeModification.id}`,
      sendableDTO
    );
  }

  //updating the boneloss measurement with the new calculation by using image dimensions
  updateBoneLossFinding(
    imageId: string,
    findingBeforeModification: Finding,
    editedForm: Partial<Finding>
  ): Observable<unknown> {
    let updatedFinding: Partial<Finding>;
    if (findingBeforeModification.type === FindingType.BoneLoss) {
      console.info("findingBeforeModification", findingBeforeModification);
      let { bone_loss_attributes } = editedForm;
      const {
        id,
        predictionScore,
        status,
        type,
        location,
        tooth,
        box,
      } = findingBeforeModification;
      if (!bone_loss_attributes) {
        bone_loss_attributes = findingBeforeModification.bone_loss_attributes;
      }
      updatedFinding = {
        id: id as string,
        predictionScore, //:`${predictionScore}`,
        status: findingBeforeModification.status, //: `${status}`,
        type: FindingType.BoneLoss, //: `${type}`,
        location, //: `${location}`,
        tooth: editedForm.tooth ? editedForm.tooth : tooth,
        bone_loss_attributes: {
          ac_x: bone_loss_attributes?.ac_x as number,
          ac_y: bone_loss_attributes?.ac_y as number,
          cej_x: bone_loss_attributes?.cej_x as number,
          cej_y: bone_loss_attributes?.cej_y as number,
          measurement_mm: bone_loss_attributes?.measurement_mm
            ? +bone_loss_attributes?.measurement_mm
            : 0,
          severity: bone_loss_attributes?.severity,

          tooth: tooth,
        }, //: `${bone_loss_attributes}`,
        //box_based_attributes
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // ...(findingBeforeModification as any),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // bone_loss_attributes: {

        //   ...(findingBeforeModification.bone_loss_attributes as any),

        // }
      };
      // updatedFinding.bone_loss_attributes.severity = editedForm.severity;
    } else {
      updatedFinding = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(findingBeforeModification as any),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(editedForm as any),
      };
    }

    const sendableDTO: FindingDTOPatch = FindingAdapter.toDTO(updatedFinding);
    console.info("sending DTO ", sendableDTO);
    return this.http.patch(
      `${this.findingServiceBaseUrl(imageId)}/${findingBeforeModification.id}`,
      sendableDTO
    );
  }

  /**
   * Delete multiple findings on an image.
   *
   * @param imageId ID of the image where findings are stored.
   * @param findingsToDelete Findings to be deleted.
   */
  deleteMultipleFindings(imageId: string, findingsToDelete: Finding[]) {
    // ensure that deletion order is ordered descendingly.
    // findings are identified by indices in an array. If elements listed
    // earlier in the array are deleted, it would invalidate indices (ids)
    // of findings that are about to be deleted.
    const sortByDescendingIdOrder = (a: Finding, b: Finding) => {
      return a.id > b.id ? -1 : 1;
    };

    if (findingsToDelete.length === 0) return of([]);

    const _findingsInDeletionOrder = findingsToDelete
      .slice()
      .sort(sortByDescendingIdOrder);

    return from(_findingsInDeletionOrder).pipe(
      concatMap((f) => this.deleteFinding(imageId, f.id)),
      toArray()
    );
  }

  deleteFinding(imageId: string, findingId: string) {
    return this.http.delete(
      `${this.findingServiceBaseUrl(imageId)}/${findingId}`
    );
  }

  /**
   * Persist predictions as predicted findings.
   *
   * If no prediction is provided, this method creates a dummy finding that
   * indicates the image has no predicted findings.
   *
   * @param args
   *    - `imageId`: ID of the image under which new finding(s) will be created
   *    - `predictions`: predictions to be persisted as 'predicted' findings
   *
   * @returns
   *     An observable of the finding creation network requests.
   */
  createFindingsFromPredictions(args: {
    predictions: PredictionKinds[];
    imageId: string;
  }) {
    const { predictions, imageId } = args;

    if (predictions.length === 0) {
      return this.createDummyFinding({
        imageId,
        status: FindingStatus.Predicted,
      });
    }

    const newFindings = predictions.map((p) =>
      this.convertPredictionToFinding(p)
    );
    return forkJoin(newFindings.map((f) => this.createFinding(imageId, f)));
  }

  /**
   * Given a prediction, determines the finding type that is should map to.
   *
   * @returns A [[`FindingType`]] that the provided prediction corresponds to.
   */
  private static resolveFindingTypeFromPrediction(
    p: PredictionKinds
  ): NonDummyFindingType {
    //FindingType.Caries | FindingType.Tooth | FindingType.Material | FindingType.BoneLoss {
    if (isToothPrediction(p)) return FindingType.Tooth;
    if (isMaterialPrediction(p)) return FindingType.Material;
    if (isBonelossPrediction(p)) return FindingType.BoneLoss;
    return FindingType.Caries;
  }

  /**
   * Creates a dummy finding for an image.
   */
  private createDummyFinding({
    imageId,
    status,
  }: {
    imageId: string;
    status?: FindingStatus;
  }) {
    const dummyFinding: Omit<DummyFinding, "id"> = {
      type: FindingType.Dummy,
      status: isDefined(status) ? status : FindingStatus.Default,
    };

    return this.createFinding(imageId, dummyFinding);
  }

  /**
   * Given a finding array, return an array with only the findings that can
   * be displayed on-screen.
   *
   * This removes non-visible findings. For instance, a finding that indicates
   * an image has been confirmed will be filtered out.
   *
   * ## Note
   *
   * Further finding filtering may be required before display. For instance, a
   * user may have a particular prediction sensitivity setting that requires
   * predicted findings to reach a level of accuracy.
   */
  static keepRenderableFindings = (fs: Finding[]): RenderableFinding[] =>
    fs.filter((f): f is RenderableFinding => {
      return FindingService.isFindingRenderable(f);
    });

  /**
   * Given a finding, determines if it has properties such that it can be
   * rendered as a box on screen.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isFindingRenderable = (f: any): f is RenderableFinding => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const finding: any = f;
    return !isNullable(finding.box);
  };

  /**
   * Given an array of findings, return an array only with findings that are
   * considered as diagnoses.
   *
   * For instance, tooth findings are not diagnoses and are thus excluded from
   * the returned array.
   *
   * @returns an array of [[`RenderableFinding`]]. Note that the type has been
   *   narrowed down from the input `Finding` type to the returned
   *   `RenderableFinding`. This is to ensure that all returned values from this
   *   function (diagnoses) are renderable on a canvas.
   */
  static keepDiagnosisFindings = (fs: Finding[]): RenderableFinding[] => {
    const diagnosisFindingTypes = new Set([
      FindingType.Caries,
      FindingType.Fracture,
      FindingType.Calculus,
      FindingType.Infection,
      FindingType.DefectiveRestoration,
      FindingType.Plaque,
      FindingType.GumRecession,
      FindingType.GumInflammation,
      FindingType.MissingTooth,
    ]);

    return fs
      .filter(FindingService.isFindingRenderable)
      .filter((f) => diagnosisFindingTypes.has(f.type));
  };

  /**
   * Given a finding, determines if it is already confirmed by user.
   */
  static isFindingConfirmed = (f: Finding | null | undefined): boolean => {
    // not confirmed if the finding is nullable
    if (isNullable(f)) {
      return false;
    }

    // confirmed only if the checks above pass and its status is `confirmed`
    return f.status === FindingStatus.Confirmed;
  };

  /**
   * Given an array of findings, bisect findings into the ones that should be
   * currently displayed on-screen, and the findings that are not displayed.
   *
   * Findings can only be visible or non-visible. This means that a finding can
   * either belong to `visibleFindings` or `nonVisibleFindings`, but not both.
   *
   * @param fs the findings to partition from.
   * @param thresholdValue the prediction threshold value used for this partition.
   * @returns
   *   an object with two properties:
   *     - visibleFindings:
   *         findings that should be displayed on the image.
   *     - nonVisibleFindings:
   *         all other findings from the findings array parameter.
   */
  static partitionFindingsByVisibility(
    fs: Finding[],
    thresholdValue: number
  ): { visibleFindings: RenderableFinding[]; nonVisibleFindings: Finding[] } {
    const visibleFindings = FindingService.filterFindingsByPredictionThreshold(
      fs,
      thresholdValue
    ).filter(FindingService.isFindingRenderable);

    const nonVisibleFindings = fs.filter(
      (f) => !FindingService.isFindingAboveThresholdValue(thresholdValue)(f)
    );

    return {
      visibleFindings,
      nonVisibleFindings,
    };
  }

  /**
   * Given an array of findings to be rendered, adds display settings depending
   * on the nature of each finding.
   *
   * The modification rules for each finding are as follows:
   * - prediction is rendered with dashed lines
   *
   * @param findings the array of findings to be rendered.
   *
   * @returns an array that represents the same findings as the input, but with
   *   display properties attached to them.
   */
  static addRenderPropertiesToFindings<F extends RenderableFinding>(
    findings: F[]
  ): (F & CanvasLibFinding)[] {
    return findings.map((f): F & CanvasLibFinding => {
      if (f.status === FindingStatus.Predicted) {
        return {
          ...f,
          renderConfig: {
            useDashedLine: true,
          },
        };
      }

      return f;
    });
  }

  static filterFindingsByPredictionThreshold<F extends Finding>(
    fs: F[],
    thresholdValue: number
  ): F[] {
    return fs.filter(
      FindingService.isFindingAboveThresholdValue(thresholdValue)
    );
  }

  private static isFindingAboveThresholdValue = (thresholdValue: number) => (
    f: Finding
  ) => {
    if (!FindingService.isFindingRenderable(f)) return false;
    if (f.status !== FindingStatus.Predicted) return true;
    if (isNullable(f.predictionScore)) return true;
    return f.predictionScore >= thresholdValue;
  };

  static sortByDescendingSeverity<F extends RenderableFinding>(
    findings: F[]
  ): F[] {
    if (isNullable(findings as any) || !Array.isArray(findings)) {
      return [];
    }

    const byDescendingSeverity = (x: F, y: F) => {
      if (isNullable(x.stage) || isNullable(y.stage)) {
        return 0;
      }

      return FindingService.compareStageSeverity(x.stage, y.stage);
    };

    try {
      return findings.sort(byDescendingSeverity);
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  /**
   * Given two `CariesStageTypes`, return a number of:
   *   - `-1` if the first argument is more severe than the second
   *   - `1` if the second argument is more severe than the first
   *   - `0` if the two arguments have the same severity
   *
   * The level of severity, in descending order, is defined as follows:
   *    Advanced > Moderate > Initial > Undefined.
   */
  static compareStageSeverity(
    x: CariesStageTypes,
    y: CariesStageTypes
  ): -1 | 0 | 1 {
    if (isNullable(x) || isNullable(y)) {
      return 0;
    }

    const xSeverity = FindingService.numerifyCariesStage(x);
    const ySeverity = FindingService.numerifyCariesStage(y);

    if (xSeverity > ySeverity) return -1;
    else if (xSeverity < ySeverity) return 1;
    return 0;
  }

  /**
   * Resolve for the treatment options to be displayed when creating a treatment
   * for the given tooth number.
   *
   * @returns `undefined` if:
   *    - any of the arguments provided is nullable,
   *    - the provided findings array is empty, or
   *    - no finding in the provided findings array has a defined `stage`.
   *
   *    Otherwise the function returns the treatments for the most severe
   *    finding that is associated with the provided tooth number.
   */
  static resolveDefaultTreatmentOptions(props: {
    findings: Finding[];
    toothNumber: string | undefined | null;
    isWholeMouth?: boolean;
  }): string[] | undefined {
    const { findings, toothNumber, isWholeMouth } = props;

    if (isWholeMouth) {
      return TreatmentDB.getWholeMouthTreatments().map((t) => t.description);
    }

    if (isNullable(findings) || isNullable(toothNumber)) {
      return undefined;
    }

    const renderableFindings = FindingService.keepRenderableFindings(findings);

    const findingsForTooth = renderableFindings.filter(
      (f) => f.tooth === toothNumber
    );

    if (findingsForTooth.length === 0) {
      return undefined;
    }

    return TreatmentDB.getAllFindingsTreatments().map((t) => t.description);
  }

  /**
   * Give a numerical representation of Caries stages.
   *
   * A more advanced stage has a higher numerical value.
   */
  static numerifyCariesStage(stage: CariesStageTypes): number {
    switch (stage) {
      case CariesStageTypes.Advanced:
        return CariesStagesNumericalIdentifier.Advanced;
      case CariesStageTypes.Moderate:
        return CariesStagesNumericalIdentifier.Moderate;
      case CariesStageTypes.Initial:
        return CariesStagesNumericalIdentifier.Initial;
      case CariesStageTypes.Undefined:
      default:
        return CariesStagesNumericalIdentifier.Undefined;
    }
  }

  private static numerifyToothNumber(f: FindingDTO): FindingDTO {
    if (isNullable(f.number)) {
      return f;
    }

    return {
      ...f,
      number: Number(f.number),
    };
  }

  private convertPredictionToFinding(
    p: PredictionKinds
  ): Omit<RenderableFinding, "id"> {
    // for tooth predictions, the tooth number is stored under property 'label'
    // for other predictions, tooth number is stored under 'tooth_id'
    const tooth = isToothPrediction(p) ? p.label : p.tooth ?? "0";

    const type = FindingService.resolveFindingTypeFromPrediction(p);

    const bone_loss_attributes: PerioData | undefined = isBonelossPrediction(p)
      ? FindingPredictionService.parseBoneLossAttributes(p)
      : undefined;
    //   const bonelossFinding = FindingPredictionService.parseBonelossPredictionAsFinding(p as BoneLossPrediction);
    //   console.info('convertPredictionToFinding', bonelossFinding);
    //   return bonelossFinding;
    // }

    let box;
    if (isToothPrediction(p) || isMaterialPrediction(p)) {
      box = FindingPredictionService.parseMinMaxBoxCoordinates(p);
    } else if (isBonelossPrediction(p)) {
      box = [p.ac_x, p.ac_y, p.cej_x, p.cej_y] as BoxCoordinates;
    } else {
      box = p.box;
    }

    const material = isMaterialPrediction(p) ? p.label : undefined;
    const location = isCariesPrediction(p) ? p.location : "";
    const stage = isCariesPrediction(p)
      ? FindingProcessingService.resolveCariesStage(p.stage)
      : CariesStageTypes.Undefined;

    return {
      box,
      material,
      stage,
      location,
      tooth,
      type,
      bone_loss_attributes,
      status: FindingStatus.Predicted,
      predictionScore: p.score,
    };
  }
}
