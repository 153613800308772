<footer
  id="footer"
  class="w-fullmx-auto sm:px-6 lg:px-8 footer xs:px-0"
  [ngClass]="{ loaded: !(loading$ | async) }"
>
  <div
    class="page-padding max-w-6xl mx-auto py-0 kpr-border grid grid-cols-12 md:pl-5 xs:pl-0"
  >
    <div
      class="img-container col-span-4 kpr-border py-5 col-span-3 xs:pl-0 xs:py-0 xs:col-span-12"
    >
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.getkells.com/"
        aria-label="Go to kells.care"
        class="w-3/6 mb-3 xs:mb-2"
        [ngStyle]="{
          pointerEvents: portal !== Portals.Aetna ? 'initial' : 'none'
        }"
      >
        <img
          src="/assets/punch/images/logo-white.svg"
          role="img"
          alt="Kells"
          class="image-container kpr-border pt-5 md:pl-10 lg:pl-0 sm: pl-10 xs:w-36"
      /></a>
    </div>
  </div>
  <hr class="max-width-container" />
  <div class="page-padding max-w-6xl mx-auto py-8 pt-10">
    <!-- <div class="xs:col-span-12 col-span-5 col-end-13 pl-4">

    </div> -->
    <div class="footer-tags sm:pl-0">
      <div class="flex pt-8 content" style="justify-content: space-between">
        <div
          class="pb-3 pt-1 md:pl-6 flex"
          style="display: inline-flex; justify-content: center"
        >
          <p
            class="copyright opacity-80 pb-2 mb-0 md:pl-10 w-max-content xs:pl-5"
          >
            © {{ currentYear }} KELLS Inc. All Rights Reserved.
          </p>
        </div>
        <!-- <div class="email lg: pt-1 pl-16 md:pl-1 sm:pl-0" >
      <a href="kells:team@getkells.com?subject=Mail from our Website">  <p class="copyright opacity-80 pb-2 mb-0 md:pl-10 xs:pl-5">
          Email: team@getkells.com
      </p></a>
      </div> -->
        <div
          class="content pb-3 pt-1 md:pr-10 sm:pl-24 flex"
          style="display: inline-flex; justify-content: center"
        >
          <a href="kells:team@getkells.com?subject=Mail from our Website">
            <p class="copyright opacity-80 pb-2 mb-0 pr-8 md:pl-10 xs:pl-5">
              Email: team@getkells.com
            </p></a
          >
          <p
            class="copyright opacity-80 pb-2 mb-0 xs:pl-5 pr-8"
            style="justify-content: center"
          >
            Connect With KELLS
          </p>
          <div class="flex pb-2" style="justify-content: center">
            <a
              mat-icon-button
              color="secondary"
              aria-label="KELLS on LinkedIn"
              rel="noreferrer"
              target="_blank"
              class="pr-5 pb-1 lg:pr-0"
              href="https://www.linkedin.com/company/kells-inc/"
              aria-label="KELLS on LinkedIn"
            >
              <img
                src="/assets/punch/images/INN.svg"
                alt=""
                class="image-11 pb-3"
              />
            </a>

            <!--&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                mat-icon-button
                color="secondary"
                rel="noreferrer"
                target="_blank"

                href="https://www.linkedin.com/company/kells-inc"
                aria-label="KELLS on LinkedIn"
              >
              <img src="/assets/punch/images/TWIITTER.svg" loading="lazy" alt="" class="image-11 pb-3 ">

              </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
