import { Injectable } from "@angular/core";
import { KellsFabric } from "@kells/shared-ui/canvas";
import { BoxCoordinates } from "@kells/interfaces/finding";
import { isNullable } from "@kells/utils/js";
import { CanvasService } from "./canvas.service";

export interface LabelBoxCoordinateComparisonArgs {
  /** A fabric.Rect instance used for coordinate comparison. */
  box: fabric.Rect;
  /**
   * Coordinate, expressed in the absolute distance from the top left corner of
   * the screen. The unit for `x` and `y` is pixels.
   */
  absoluteCoor: { x: number; y: number };
}

export interface LabelBoxLocationUpdateArgs {
  box: fabric.Rect;
  location: {
    left: number;
    top: number;
  };
}

@Injectable({
  providedIn: "root",
})
export class CanvasElementService {
  constructor() {}

  static updateLabelBoxLocation(
    canvas: fabric.Canvas,
    args: LabelBoxLocationUpdateArgs
  ) {
    const { box, location: newBoxLocation } = args;

    box.left = newBoxLocation.left;
    box.top = newBoxLocation.top;

    canvas.requestRenderAll();
  }

  /**
   * Checks if a coordinate is within the boundaries of a label box.
   *
   * @param canvas
   *    An instance of fabric.Canvas. The label box that will be compared
   *    against should be rendered on this canvas.
   * @param args
   *    See `LabelBoxCoordinateComparisonArgs`.
   *
   * @returns
   *    `true` if the provided coordinate is within the label box, `false`
   *    otherwise.
   *    The method also returns `false` if any of the arguments provided is
   *    not valid.
   */
  static isCoorWithinLabelBox(
    canvas: fabric.Canvas,
    args: LabelBoxCoordinateComparisonArgs
  ) {
    try {
      const {
        canvasLeftOffset,
        canvasTopOffset,
        absoluteCoor,
        oCoords,
      } = CanvasElementService._validateCoorComparisonArgs(canvas, args);

      const { x: cursorX, y: cursorY } = absoluteCoor;

      const isWithinLabelBox =
        cursorX > oCoords.tl.x + canvasLeftOffset &&
        cursorY > oCoords.tl.y + canvasTopOffset &&
        cursorX < oCoords.tr.x + canvasLeftOffset &&
        cursorY < oCoords.br.y + canvasTopOffset;

      return isWithinLabelBox;
    } catch (argValidationError) {
      console.warn(argValidationError);
      return false;
    }
  }

  /**
   * Translates a box's coordinates in the form of `BoxCoordinates`.
   *
   * @param box
   *    The fabric.Rect instance whose coordinate will be translated.
   */
  static computeBoxCoordinates(box: KellsFabric.Rect): BoxCoordinates {
    // height = on the `y` axis, the box's bottom right coor - its top right coor
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const boxHeight = box.aCoords!.br.y - box.aCoords!.tr.y;

    // width = on the `x` axis, the box's bottom right coor = its bottom left coor
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const boxWidth = box.aCoords!.br.x - box.aCoords!.bl.x;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const boxDimensions = [box.left!, box.top!, boxWidth, boxHeight].map(
      Math.round
    ) as BoxCoordinates;

    return boxDimensions;
  }

  private static _validateCoorComparisonArgs(
    canvas: fabric.Canvas,
    args: LabelBoxCoordinateComparisonArgs
  ) {
    const { box, absoluteCoor } = args;
    const { oCoords } = box;

    if (!oCoords) {
      throw new Error(
        [
          `Comparing coordinate ${JSON.stringify(absoluteCoor)}`,
          `that does not have property 'oCoords': comparison will fail automatically`,
          `because this property is required for comparison.`,
          `box: ${JSON.stringify(box)}`,
        ].join(" ")
      );
    }

    const canvasLeftOffset = CanvasService.getCanvasLeftOffset(canvas);
    const canvasTopOffset = CanvasService.getCanvasTopOffset(canvas);

    if (isNullable(canvasLeftOffset)) {
      throw new Error(
        [
          `Cannot compare coordinate ${JSON.stringify(absoluteCoor)}`,
          `with box: canvas left offset not available.`,
        ].join(" ")
      );
    }

    if (isNullable(canvasTopOffset)) {
      throw new Error(
        [
          `Cannot compare coordinate ${JSON.stringify(absoluteCoor)}`,
          `with box: canvas top offset not available.`,
        ].join(" ")
      );
    }

    const validatedArgs = {
      ...args,
      oCoords,
      canvasLeftOffset,
      canvasTopOffset,
    };

    return validatedArgs;
  }
}
