import {
  FindingFilterSelectionTypes,
  FindingStatus,
} from "@kells/interfaces/finding";
import {
  CanvasLibFinding,
  KellsFabric,
  FindingInteractionStates,
  ZoomEvent,
} from "@kells/shared-ui/canvas";
import { fabric } from "fabric";
import { isDefined, isNullable } from "@kells/utils/js";
import { IEvent, IObjectOptions } from "fabric/fabric-impl";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { SubSink } from "subsink";
import { IFabricFindingStyles } from "./fabricstyle.interface";
import { CanvasContext } from "../canvas/canvas-context.interface";
import { observeProperty } from "@kells/utils/angular";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import { distinctUntilChanged } from "rxjs/operators";

class BaseFindingObject {
  protected _box: KellsFabric.Rect;

  protected _confirmedRect: KellsFabric.Rect;
  protected _unconfirmedRect: KellsFabric.Rect;
  imageConfirmed: boolean;

  get id(): string {
    return this.finding.id as string;
  }

  protected _group: KellsFabric.Group;
  get object(): KellsFabric.Object {
    if (this._isConfirmed) {
      return this._confirmedRect as KellsFabric.Object;
    }
    return this._unconfirmedRect as KellsFabric.Object;
  }

  _interactiveState = FindingInteractionStates.Up;
  interactiveState$: Observable<FindingInteractionStates> = observeProperty(
    this,
    "_interactiveState"
  ).pipe(keepDefined(), distinctUntilChanged());

  protected _isConfirmed = false;
  set confirmed(val: boolean) {
    this._isConfirmed = val;
    this.styles.confirmed = val;
  }
  get confirmed(): boolean {
    return this._isConfirmed;
  }
  protected _hasFocus = false;
  get hasFocus() {
    return this._hasFocus;
  }

  _activeObject: KellsFabric.Object;
  get finding(): CanvasLibFinding {
    return this._finding;
  }

  finding$: BehaviorSubject<CanvasLibFinding>;

  protected _isDragging = false;

  styles: IFabricFindingStyles;
  protected _focusedPoint: fabric.Point | null;
  protected _pointTL: fabric.Point;
  protected _pointBR: fabric.Point;

  ordinality: number;

  protected _subs = new SubSink();

  private _canvas?: KellsFabric.Canvas | undefined;
  protected get canvas(): KellsFabric.Canvas {
    return this._canvas!;
  }
  protected set canvas(value: KellsFabric.Canvas) {
    this._canvas = value;
  }

  constructor(
    protected _finding: CanvasLibFinding,
    protected _ctx?: CanvasContext
  ) {
    this._canvas = _ctx!.canvas;
    this.finding$ = new BehaviorSubject<CanvasLibFinding>(_finding);
    this._isConfirmed = _finding.status === FindingStatus.Confirmed;

    this.mouseover = this.mouseover.bind(this);
    this.mouseout = this.mouseout.bind(this);
    this.mousedown = this.mousedown.bind(this);
    this.mouseup = this.mouseup.bind(this);
    this.objectMoving = this.objectMoving.bind(this);
    this.selectionCreated = this.selectionCreated.bind(this);
    this.selectionCleared = this.selectionCleared.bind(this);
    this.selectionUpdated = this.selectionUpdated.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.objectMoved = this.objectMoved.bind(this);

    this.canvas.on("selection:created", this.selectionCreated);
    this.canvas.on("selection:cleared", this.selectionCleared);
    this.canvas.on("selection:updated", this.selectionUpdated);

    this._subs.sink = _ctx!.navStart$.subscribe((event) => {
      this.blur();
      this.dispose();
    });
    this._subs.sink = combineLatest([this._ctx!.isImageConfirmed$]).subscribe(
      ([isImageConfirmed]) => {
        this.imageConfirmed = isImageConfirmed;
      }
    );
    this.registerZoom(_ctx!.zoomEvent$);
    this.registerToggleLabels(_ctx!.showFindingLabels$);
    this.registerToggleFindingVisibility(
      _ctx!.showFindings$,
      _ctx!.findingFilterKeys$
    );
  }

  initStyles(): void {
    this._subs.sink = this.interactiveState$.subscribe(
      (interactiveState: FindingInteractionStates) => {
        switch (interactiveState) {
          case FindingInteractionStates.Focus:
            this.styles.setFocus();
            if (isDefined(this.labelElement)) {
              this.labelElement!.classList.remove("hovering");
              this.labelElement!.classList.add("focused");
            }
            break;
          case FindingInteractionStates.FocusHover:
            this.styles.setFocusHover();
            if (isDefined(this.labelElement)) {
              this.labelElement!.classList.add("focused");
              this.labelElement!.classList.add("hovering");
            }
            break;
          case FindingInteractionStates.Active:
            if (isDefined(this.labelElement)) {
              this.labelElement!.classList.add("focused");
            }
            this.styles.setActive();
            break;
          case FindingInteractionStates.ActiveHover:
            if (isDefined(this.labelElement)) {
              this.labelElement!.classList.add("hovering");
              this.labelElement!.classList.add("focused");
            }
            this.styles.setActiveHover();
            break;
          case FindingInteractionStates.Up:
            if (isDefined(this.labelElement)) {
              this.labelElement!.classList.remove("hovering");
              this.labelElement!.classList.remove("focused");
            }
            this.styles.setUp();
            break;
          case FindingInteractionStates.Hover:
            if (isDefined(this.labelElement)) {
              this.labelElement!.classList.add("hovering");
              this.labelElement!.classList.remove("focused");
            }
            this.styles.setHover();
            break;
          case FindingInteractionStates.Deemphasized:
            if (isDefined(this.labelElement)) {
              this.labelElement!.classList.remove("hovering");
              this.labelElement!.classList.remove("focused");
            }
            this.makeDeemphasized();
            break;
        }
      }
    );
  }

  dispose(): void {
    this._subs.unsubscribe();
    if (!isNullable(this.canvas)) {
      this.canvas.off("selection:created", this.selectionCreated);
      this.canvas.off("selection:cleared", this.selectionCleared);
      this.canvas.off("selection:updated", this.selectionUpdated);
      this.canvas.off("mouse:up:before", this.objectMoved);
      this.canvas.off("object:moved", this.objectMoved);
      this.canvas.off("object:scaled", this.objectMoved);
      this._activeObject = this.canvas.getActiveObject() as KellsFabric.Object;
    }
    document.removeEventListener("keydown", this.onKeyDown);
    if (
      !isNullable(this.labelElement) &&
      !isNullable(this.labelElement.parentElement)
    ) {
      document.body.removeChild(this.labelElement);
      delete this.labelElement;
    }
    this._unconfirmedRect.off("mouseover", this.mouseover);
    this._unconfirmedRect.off("mouse:out", this.mouseout);
    this._confirmedRect.off("mouseover", this.mouseover);
    this._confirmedRect.off("mouse:out", this.mouseout);

    if (!isNullable(this._activeObject)) {
      this._activeObject.off("moving", this.objectMoving);
      this._activeObject.off("scaling", this.objectMoving);
    }
    this.remove();
    delete this.canvasElementRef;
    delete this.labelElement;
    delete this._canvas;
    delete this._ctx;
  }

  selectionCreated(event: any) {
    if (event.selected.length > 0) {
      const target = event.selected[0];
      if (target.ctrl.id === this.id) {
        this.hideLabel();
        console.info(
          this._finding.type.toUpperCase() + " selectionCreated",
          event
        );

        this._setActiveSubtarget(target);
      } else {
        if (this._interactiveState !== FindingInteractionStates.Up) {
          this.blur();
        }
        this.makeDeemphasized();
      }
    }
  }

  selectionCleared(event: any) {
    if (isDefined(event?.deselected)) {
      const untarget = event?.deselected[0];
      if (!isNullable(untarget) && untarget.ctrl.id === this.id) {
        this.blur();
      }
    }
    this.unmakeDeemphasized();
  }

  static initActiveControls(obj: KellsFabric.Object) {
    obj.controls = {
      ...fabric.Rect.prototype.controls,
      mb: new fabric.Control({ visible: false }),
      ml: new fabric.Control({ visible: false }),
      mr: new fabric.Control({ visible: false }),
      mt: new fabric.Control({ visible: false }),
      mtr: new fabric.Control({ visible: false }),
    };
  }

  selectionUpdated(event: any) {
    const blurTarget = event.deselected[0];
    const focusTarget = event.selected[0];
    if (focusTarget.ctrl.id === this.id) {
      console.info(
        `${this.finding.type.toUpperCase()} selectionUpdated`,
        event
      );
      this._setActiveSubtarget(focusTarget);
    } else {
      if (blurTarget.ctrl.id === this.id) {
        console.info(
          `${this.finding.type.toUpperCase()} selectionUpdated`,
          event
        );
        this.blur();
      }
      this.makeDeemphasized();
    }
  }

  makeDeemphasized() {
    this.setLabelVisibility(false);
    this.object.set({
      opacity: 0.3,
    });
  }

  unmakeDeemphasized() {
    this.setLabelVisibility(this._showLabelByDefault);
    this.object.set({
      opacity: 1,
    });
  }

  protected drawMouseMoveHandler = (option: any) => {
    if (!this.canvas) return;

    const initialZoomLevel = this.canvas.getZoom();
    if (
      isNullable(this.object) ||
      isNullable(this.object.left) ||
      isNullable(this.object.top)
    ) {
      console.warn(
        `drawing box is invalid while drawing: ${JSON.stringify(this.object)}`
      );
      return;
    }

    const pointer = this.canvas.getPointer(option.e);
    const xCoor = pointer.x;
    const yCoor = pointer.y;

    const isMouseWithinCanvas =
      xCoor > 0 &&
      yCoor > 0 &&
      xCoor * initialZoomLevel < this.canvas.getWidth() &&
      yCoor * initialZoomLevel < this.canvas.getHeight();

    const isDraggingToBottomRight =
      xCoor > this.object.left && yCoor > this.object.top;

    if (isMouseWithinCanvas && isDraggingToBottomRight) {
      this.object.set({
        width: Math.abs(xCoor - this.object.left),
        height: Math.abs(yCoor - this.object.top),
      });

      this.canvas.requestRenderAll();
    }
  };

  protected _setActiveSubtarget(target: KellsFabric.Object): void {
    this._hasFocus = true;
    this._interactiveState = FindingInteractionStates.Focus;
    this._focusedPoint = this._pointTL;
    this.object.set({ opacity: 1 });
    this.styles.setFocus();
    this.update();
    this.canvas.requestRenderAll();
    this._activeObject = this.canvas.getActiveObject() as KellsFabric.Object;
    this._activeObject.on("object:moving", this.objectMoving);
    this._activeObject.on("object:scaling", this.objectMoving);
    this.canvas.on("mouse:up:before", this.objectMoved);
    this.canvas.on("object:moved", this.objectMoved);
    this.canvas.on("object:scaled", this.objectMoved);

    if (isDefined(this.labelElement)) {
      this.labelElement!.classList.add("focusing");
      this.setLabelVisibility(true);
    }

    document.addEventListener("keydown", this.onKeyDown);
  }

  protected _clearActiveSubtarget() {
    if (!isNullable(this._canvas!.getActiveObject())) {
      const activeObj: KellsFabric.Object = this._canvas!.getActiveObject() as KellsFabric.Object;
      if (activeObj.ctrl.id === this.id) {
        activeObj.off("object:moving", this.objectMoving);
        activeObj.off("object:scaling", this.objectMoving);
        this._canvas!.off("object:moved", this.objectMoved);
        this._canvas!.discardActiveObject();
      }
      document.removeEventListener("keydown", this.onKeyDown);
      if (isDefined(this.labelElement)) {
        this.labelElement!.classList.remove("focusing");
        this._focusedPoint = null;
        this.setPosition(this.labelElement);
        this.setLabelVisibility(this._showLabelByDefault);
      }
    }
  }

  /**
   * Shape editing has been completed but this finding has not been
   * deselected
   * @param event
   */
  objectMoved(event: any) {
    this.hideLabel();
    console.info(this._finding.type.toUpperCase() + " object:moved", event);
    this._isDragging = false;
    if (event?.target?.ctrl?.id === this.id) {
      const { _confirmedRect: rect1, _unconfirmedRect: rect2 } = this;
      const matrix = event.target.calcTransformMatrix();
      const tprops = fabric.util.qrDecompose(matrix);
      event.target.set({ ...tprops }).setCoords();

      this.styles.setFocusHover();
      this.update();
      this.setPosition(this.labelElement);
      if (this._canvas) {
        this._canvas!.requestRenderAll();
      }
    }
  }

  _debounceZoom: number | null;
  registerZoom(zoomEvent$: Observable<ZoomEvent>) {
    // this._subs.sink = zoomEvent$.pipe(debounceTime(5)).subscribe((event: any) => {
    this._subs.sink = zoomEvent$.subscribe((event: any) => {
      if (this._debounceZoom) {
        cancelAnimationFrame(this._debounceZoom);
      }
      this._debounceZoom = requestAnimationFrame(() => {
        this.updateCoords();
        this.setPosition(this.labelElement);
        this._debounceZoom = null;
      });
    });
  }

  _showLabelByDefault = false;
  registerToggleLabels(showFindingLabels$: Observable<boolean>) {
    this._subs.sink = showFindingLabels$.subscribe((value: boolean) => {
      this._showLabelByDefault = value;
      if (value === false) {
        if (this._interactiveState === FindingInteractionStates.Up) {
          this.setLabelVisibility(value);
        }
      } else {
        this.setLabelVisibility(value);
        this.setPosition(this?.labelElement);
      }
    });
  }

  typeKey: FindingFilterSelectionTypes;
  _isHidden: boolean;

  makeVisible() {
    const { object, _isHidden, hideLabel } = this;

    object?.set({ visible: true });
    this._isHidden = false;
  }

  makeHidden() {
    //if (this._isHidden) return;
    const { object } = this;
    if (this.finding.id !== "NEW") {
      this.hideLabel();

      switch (this._interactiveState) {
        case FindingInteractionStates.Focus:
        case FindingInteractionStates.FocusHover:
        case FindingInteractionStates.Active:
        case FindingInteractionStates.ActiveHover:
          this.blur();
          break;
      }

      object?.set({ visible: false });
      //this._confirmedRect.set({ visible: false });
      this._isHidden = true;
    }
  }

  objectMoving(event: any) {
    const { e, pointer, transform, action } = event;
    const { x, y } = event.pointer;
    const { _finding: finding } = this;
    console.info(`${finding.type.toUpperCase()} objectMoving t:`, transform);
    const target = this._canvas!.getActiveObject() as KellsFabric.Object;

    console.info(
      `${finding.type.toUpperCase()}  objectMoving o:`,
      target.aCoords
    );
    if (
      !isNullable(this.finding.box) &&
      !isNullable(target) &&
      !isNullable(this.labelElement)
    ) {
      this.updateCoords();
      this.setPosition(this.labelElement, transform);
      this._canvas!.requestRenderAll();

      // switch (action as string) {
      //   case 'drag':
      //     this.updateCoords();
      //     this.setPosition(this.labelElement, transform);
      //     this._canvas!.requestRenderAll();
      //     break;
      //   case 'scale':
      //     this.updateCoords();
      //     this.setPosition(this.labelElement, transform);
      //     this._canvas!.requestRenderAll();
      //     break;
      // }
    }
  }

  protected _baseProps: IObjectOptions;

  initFabricObject(baseProps: IObjectOptions) {
    baseProps.selectable = this.imageConfirmed ? false : true; // when the image is confirmed should not able to do edit or move the finding
    // this._strokeColor = CanvasFindingService.resolveLabelBoxColor(this.finding);
    // this._fillColor_hover = this._strokeColor + '22';
    this._baseProps = baseProps;
    const [left, top, width, height] = this.finding.box;
    const { styles: style } = this;
    this._pointTL = new fabric.Point(left, top);
    this._pointBR = new fabric.Point(left + width, top + height);
    this._unconfirmedRect = new fabric.Rect({
      left,
      top,
      width,
      height,
      strokeWidth: style.rectState.strokeWidth,
      strokeDashArray: style.rectState.strokeDashArray,
      stroke: style.rectState.strokeColor,
      fill: style.rectState.fillColor,
      ...baseProps,
    }) as KellsFabric.Rect;
    this._unconfirmedRect.id = `${this.finding.type}_unc_${this.id}`;
    this._unconfirmedRect.ctrl = this;
    this._unconfirmedRect.finding = this.finding;

    this._unconfirmedRect.on("mouseover", this.mouseover);
    this._unconfirmedRect.on("mouse:out", this.mouseout);

    BaseFindingObject.initActiveControls(this._unconfirmedRect);

    this._confirmedRect = new fabric.Rect({
      left,
      top,
      width,
      height,
      strokeWidth: style.rectState.strokeWidth,
      stroke: style.rectState.strokeColor,
      fill: style.rectState.fillColor,
      ...baseProps,
    }) as KellsFabric.Rect;

    this._confirmedRect.id = `${this.finding.type}_con_${this.id}`;
    this._confirmedRect.ctrl = this;
    this._confirmedRect.finding = this.finding;

    this._confirmedRect.on("mouseover", this.mouseover);
    this._confirmedRect.on("mouse:out", this.mouseout);

    BaseFindingObject.initActiveControls(this._confirmedRect);

    if (this.confirmed && !isNullable(this._canvas)) {
      this._canvas!.add(this._confirmedRect);
    } else {
      this._canvas!.add(this._unconfirmedRect);
    }
    if (this.finding.id !== "NEW") {
      this.createLabel();
      if (!isNullable(this.labelElement)) {
        this.setPosition(this.labelElement);
      }
    } else {
      this.makeVisible();
      this.canvas.renderAll();
      this.object.selectable = true;
      this.object.setCoords();
      this.canvas.setActiveObject(this.object);
      this._isDragging = true;
      this.canvas.on("mouse:move", this.drawMouseMoveHandler);
      this.object.on("mouseup", this.onCreated);
    }
    //this._createGroup();
  }
  registerToggleFindingVisibility(
    isInsightEnabled$: Observable<boolean>,
    findingFilterKeys$: Observable<FindingFilterSelectionTypes[]>
  ) {
    console.log("registerToggleFindingVisibility called");
    this._subs.sink = combineLatest([
      findingFilterKeys$,
      isInsightEnabled$,
    ]).subscribe(([filterKeys, isEnabled]) => {
      if (this._baseProps != undefined) {
        if (this.imageConfirmed === true) {
          this._confirmedRect.selectable = this.imageConfirmed ? false : true;
          this._unconfirmedRect.selectable = this.imageConfirmed ? false : true;
        } else {
          this._confirmedRect.selectable = this.imageConfirmed ? false : true;
          this._unconfirmedRect.selectable = this.imageConfirmed ? false : true;
        }
      }
      console.log(this.finding.type.toUpperCase() + " " + isEnabled);
      if (isEnabled && filterKeys.indexOf(this.typeKey) !== -1) {
        this.makeVisible();
        this.setLabelVisibility(this._showLabelByDefault);
      } else {
        this.makeHidden();
      }
      this._canvas!.requestRenderAll();
    });
  }

  onCreated = () => {
    this.object.off("mouse:up", this.onCreated);
    this.canvas.off("mouse:move", this.drawMouseMoveHandler);
    this.createLabel();
    if (!isNullable(this.labelElement)) {
      this.setPosition(this.labelElement);
    }
  };

  onKeyDown(event: Event) {
    const keyEvent = event as KeyboardEvent;
    const { key, code } = keyEvent;
    if (!isNullable(this._focusedPoint)) {
      switch (code) {
        case "27":
          this.blur();
          event.stopPropagation();
          keyEvent.preventDefault();
          break;
      }
      this._canvas!.requestRenderAll();
    }
  }

  updateFinding(finding: CanvasLibFinding, render = false) {
    const wasConfirmed = this._isConfirmed;
    this._finding = finding;
    this._isConfirmed = finding.status === FindingStatus.Confirmed;

    if (wasConfirmed !== this._isConfirmed) {
      render = true;
    }
    this.recreateLabel();
    if (render) {
      this.update();
    }
  }

  /**
   *
   */
  update(): void {
    const { styles: style, canvas } = this;
    if (!this._confirmedRect) return;
    this._confirmedRect
      .set({
        strokeWidth: style.rectState.strokeWidth,
        stroke: style.rectState.strokeColor,
        fill: style.rectState.fillColor,
      })
      .setCoords();

    this._unconfirmedRect
      .set({
        strokeWidth: style.rectState.strokeWidth,
        strokeDashArray: style.rectState.strokeDashArray,
        stroke: style.rectState.strokeColor,
        fill: style.rectState.fillColor,
      })
      .setCoords();

    if (!this._canvas) return;
    this.setPosition(this.labelElement);
    this._canvas!.requestRenderAll();
    if (this.confirmed) {
      if (!this._confirmedRect.canvas) {
        this._canvas!.add(this._confirmedRect as fabric.Object);
      }
      if (this._unconfirmedRect.canvas) {
        this._canvas!.remove(this._unconfirmedRect as fabric.Object);
      }
    } else {
      if (!this._unconfirmedRect.canvas && this._canvas) {
        this._canvas!.add(this._unconfirmedRect as fabric.Object);
      }

      if (this._confirmedRect.canvas) {
        this._canvas!.remove(this._confirmedRect as fabric.Object);
      }
    }
  }

  updateCoords() {
    const matrix = this.object.calcTransformMatrix();
    const tprops = fabric.util.qrDecompose(matrix);
    this.object.set({ ...this._baseProps, ...tprops }).setCoords();
  }

  _getZoomedStrokeWidth(base: number) {
    if (base > 0) {
      return base / this._canvas!.getZoom();
    }
    return base;
  }

  mouseover(event: any): void {
    const target: KellsFabric.Object = event as KellsFabric.Object;
    if (!isNullable(this.labelElement)) {
      this._canvas!.on("mouse:out", this.mouseout);
      switch (this._interactiveState) {
        case FindingInteractionStates.Up:
          this._interactiveState = FindingInteractionStates.Hover;

          break;
        case FindingInteractionStates.Focus:
          this._interactiveState = FindingInteractionStates.FocusHover;

          break;
        case FindingInteractionStates.Active:
          this._interactiveState = FindingInteractionStates.ActiveHover;

          break;
      }
      if (this._showLabelByDefault === false) {
        this.setLabelVisibility(true);
        this.setPosition(this.labelElement);
      }
      //target.selectable = true;
      this.update();
      this._canvas!.requestRenderAll();
    }
  }

  mouseout(event: IEvent<Event>): void {
    this._canvas!.off("mouse:out", this.mouseout);
    // const target = event as KellsFabric.Object;
    console.info(
      this.finding.type.toUpperCase() + " mouseout ",
      this._interactiveState,
      event
    );
    // this.hideLabel();
    if (!isNullable(this.labelElement)) {
      switch (this._interactiveState) {
        case FindingInteractionStates.Hover:
          this._interactiveState = FindingInteractionStates.Up;

          if (!this._showLabelByDefault) {
            this.hideLabel();
          }
          break;
        case FindingInteractionStates.FocusHover:
          this._interactiveState = FindingInteractionStates.Focus;
          break;
        case FindingInteractionStates.ActiveHover:
          this._interactiveState = FindingInteractionStates.Active;

          break;
        case FindingInteractionStates.Deemphasized:
          this.setLabelVisibility(false);
          break;
      }
    }
    this.update();
    this._canvas!.requestRenderAll();
  }

  mousedown(option: any): void {
    const target = option as KellsFabric.Object;
    // this._interactiveState = FindingInteractionStates.Active;
    // this._setActiveSubtarget(target);
    // // const activeObj = this._isConfirmed ? this._confirmedRect : this._unconfirmedRect;
    // this.canvas.setActiveObject(activeObj);
    // this._group.destroy();
    // this.canvas.remove(this._group);
  }

  mouseup(target: KellsFabric.Object): void {
    console.info("MATERIAL mouse:up", target);
    this._interactiveState = FindingInteractionStates.Focus;
    // this._clearActiveSubtarget();
    // this._enableActiveControls();
  }

  mouseupBefore(target: KellsFabric.Object): void {
    console.info("MATERIAL mouse:up:before", target);
    this._interactiveState = FindingInteractionStates.Focus;
    // this._clearActiveSubtarget();
    // this._enableActiveControls();
  }

  focus(target: KellsFabric.Object) {
    // console.info('focus', this);
    this._interactiveState = FindingInteractionStates.Focus;
    this._setActiveSubtarget(target);
    if (!isNullable(this.labelElement)) {
      this.labelElement.classList.add("focusing");
    }
    // this._enableActiveControls();
  }

  blur() {
    console.info("blur", this);
    this._interactiveState = FindingInteractionStates.Up;
    this._clearActiveSubtarget();

    this.update();
    this._canvas!.requestRenderAll();
  }

  hide() {
    this._unconfirmedRect.set({ visible: false });
    this._confirmedRect.set({ visible: false });

    this._canvas!.requestRenderAll();
  }

  show() {
    this._unconfirmedRect.set({ visible: true });
    this._confirmedRect.set({ visible: true });

    this._canvas!.requestRenderAll();
  }

  remove() {
    if (this._group) {
      this._group.destroy();
      this._canvas!.remove(this._group);
      if (this._confirmedRect.canvas) {
        this._canvas!.remove(this._confirmedRect as fabric.Object);
      }
      if (this._unconfirmedRect.canvas) {
        this._canvas!.remove(this._unconfirmedRect as fabric.Object);
      }
    } else {
      if (this._confirmedRect.canvas) {
        this._canvas!.remove(this._confirmedRect as fabric.Object);
      }
      if (this._unconfirmedRect.canvas) {
        this._canvas!.remove(this._unconfirmedRect as fabric.Object);
      }
    }
  }

  labelElement?: HTMLDivElement | undefined;
  labelWidth: number;
  labelHeight: number;

  canvasElementRef?: HTMLDivElement;

  /**
   * Actual label div created and populated in extending class.
   * Responsinble for measuring native element dimensions, and toggling from
   * invisible to display none
   */
  createLabel() {
    if (!isNullable(this.labelElement) && !isNullable(this.object)) {
      this.labelWidth = this.labelElement.clientWidth;
      this.labelHeight = this.labelElement.clientHeight;
      this.labelElement.classList.add("hidden");
      this.labelElement.classList.remove("invisibile");
    }
  }

  /**
   * Finding data has updated content in label so just tear it down and repopulate
   */
  recreateLabel() {
    if (this.labelElement) {
      const { id } = this;
      const label = document.getElementById(`${id}_label`) as HTMLDivElement;
      if (label) {
        document.body.removeChild(label);
      }
      delete this.labelElement;
    }
    this.createLabel();
  }

  hideLabel() {
    this.setLabelVisibility(false);
  }

  showLabel() {
    this.setLabelVisibility(true);
  }

  setLabelVisibility(visible: boolean) {
    if (isDefined(this.labelElement)) {
      if (visible) {
        this.labelElement.classList.remove("hidden", "invisible");
        this.labelElement.classList.add("inline-block");
      } else {
        this.labelElement.classList.remove("inline-block");
        this.labelElement.classList.add("hidden");
      }
    }
  }

  getAbsolutePosition(point: fabric.Point): fabric.Point {
    const newP: fabric.Point = fabric.util.transformPoint(
      point,
      this.object.getViewportTransform()
    );
    return newP;
  }

  /**
   * Places the provided html element (labelElement) at the absolute position as calculated
   * by the extending class.
   */
  public setPosition = (
    el: HTMLDivElement | undefined,
    doTransform?: boolean
  ) => {
    if (
      !isNullable(el) &&
      !isNullable(this._confirmedRect) &&
      !isNullable(this._unconfirmedRect)
    ) {
      const { styles: style } = this;
      const canvas = this._canvas!;
      const object = this._isConfirmed
        ? this._confirmedRect
        : this._unconfirmedRect;

      if (doTransform) {
        const matrix = object.calcTransformMatrix();
        //transform = fabric.util.qrDecompose(matrix);
        this.object.set({ ...fabric.util.qrDecompose(matrix) }).setCoords();
      }

      const intViewportHeight = window.innerHeight;
      const intViewportWidth = window.innerHeight;
      const elWidth = el.clientWidth;
      const elHeight = el.clientHeight;

      // const offset = this.canvasElementRef.offsetTop;

      const zoom = canvas.getZoom();
      //let { scaleX, scaleY, width, height, left, top } = object;
      const objACoords = this.object.aCoords!;
      const labelPoint = this.getAbsolutePosition(objACoords.tl);
      const tooltipMaxWidth = 1200;
      const tooltipPadding = 10;
      let left = labelPoint.x + this._ctx!.canvasOffsetLeft + tooltipPadding;
      let top = labelPoint.y - elHeight;
      if (
        (zoom < 0.29 && labelPoint.y < 250) ||
        (zoom < 0.39 && labelPoint.y < 190) ||
        (zoom < 0.45 && labelPoint.y < 145) ||
        (zoom <= 0.53 && labelPoint.y < 75) ||
        (zoom > 0.53 && labelPoint.y < 60)
      ) {
        top = labelPoint.y + elHeight;
      }

      if (this._ctx?.isPreview) {
        this.hideLabel();
        top = labelPoint.y - elHeight + this._ctx!.canvasOffsetTop!;
      }
      el.style.left = `${left}px`;
      el.style.top = `${top}px`;

      //   const coords = object.aCoords;
      //   let point = coords!.tl;
      //   if (isNullable(transform)) {
      //     scaleX = scaleX!;
      //     scaleY = scaleY!;
      //     width = width!;
      //     height = height!;
      //     left = left!;
      //     top = top!;

      //     // if (top < 64 - elHeight) {
      //     //   point = coords!.tl;
      //     //   if (left > (intViewportWidth - elWidth - 40)) {
      //     //     point = coords!.tr;
      //     //   }
      //     // }
      //   }  else {

      //     point.x += transform.offsetX;
      //     point.y += transform.offsetY;

      //     // if (top! < 64 - elHeight) {
      //     //   point = coords!.tr;
      //     //   if (point.x! > (intViewportWidth - elWidth - 40)) {
      //     //     point = coords!.bl;
      //     //   }
      //     // }

      // }
      // el.style.marginLeft = `${point.x}px`;// + padLeft}px`;
      // el.style.top  = `${point.y + offset}px`;//` + padTop}px`;
    }
  };

  _boundsRect: KellsFabric.Rect;

  getEditFormPoint() {
    return this.object;
  }
}

export default BaseFindingObject;
