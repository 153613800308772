import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { environment } from "apps/patient-reporting/src/environments/environment";

import { ZocDocSearchWidgetConfig } from '../models/zocdoc-search-widget-config.model'; 
import { ScriptService } from './script.service';

declare global {
  interface Window { 
    _zdConfig_x: ZocDocSearchWidgetConfig; 
  }
}

@Injectable({
  providedIn: "root",
})
export class ZocDocService {
  private searchWidgetContainerId = "zd-widget-container";
  private searchWidgetScript = {
    URL: "https://d1uhlocgth3qyq.cloudfront.net/searchwidget-install-script/scripts/searchwidget-install-script.js",
    id: "zd-widget-install-script"
  };
  private searchWidgetConfig: ZocDocSearchWidgetConfig = {
    affiliateid: environment.zocDocAffiliateID,
    specialtyid: "98",
    searchbar: "on",
    searchUI: "button",
    providercount: "3",
    buttonbackgroundcolor: "6dc2b6",
    buttontextcolor: "212529",
    linkcolor: "6175b2"
  }

  constructor(private _scriptService: ScriptService) { }
 
  loadSearchWidget(renderer: Renderer2, containerRef: ElementRef<HTMLDivElement>) {
    window._zdConfig_x = this.searchWidgetConfig;
    
    const widgetContainer = renderer.createElement('div');
    widgetContainer.id = this.searchWidgetContainerId;
    containerRef.nativeElement.appendChild(widgetContainer);

    const searchWidgetScript = this._scriptService.loadJsScript(
      renderer,
      this.searchWidgetScript.URL,
      this.searchWidgetScript.id
    );

    return searchWidgetScript;
  }  
}
