import { Component, OnInit } from "@angular/core";
import { AnalyticsService } from "@kells/apis/analytics";
import { AppEvents } from "./shared/models/analytics";
@Component({
  selector: "kpr-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "member-reporting";

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.recordAppLoad();
  }

  private recordAppLoad() {
    return this.analyticsService.record(AppEvents.appLoaded({}));
  }
}
