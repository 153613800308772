import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PatientRecommendation } from "@kells/clinic-one/apis";
import axios from "axios";
import { apiEnvironment } from "@kells/clinic-one/environments";

type OptioVideo = {
  id: string;
  title: string;
  subtitle: string;
  category: {
    id: string;
    title: string;
  };
  thumbnail_url: string;
  captions_url: string;
  has_slides: boolean;
};

interface OptioVideoList {
  id: string;
  title: string;
  description: string;
  videos: OptioVideo[];
}

const parseDate = function (date: string): Date {
  const dateParts = date.split("-");
  return new Date(
    parseInt(dateParts[0]),
    parseInt(dateParts[2]) - 1,
    parseInt(dateParts[1])
  );
};

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class ReportService {
  static selectVisibleRecommendation(
    recommendations: PatientRecommendation[],
    sessionDate: string
  ): PatientRecommendation | undefined {
    if (!recommendations?.length || !sessionDate) return;

    const sessionRecommendations = recommendations.filter(
      (recommendation) => recommendation.sessionId === sessionDate
    );

    const _recommendations = sessionRecommendations.sort((a, b) => {
      return b.createTs.getTime() - a.createTs.getTime();
    });

    if (!_recommendations.length) return;

    return _recommendations[0];
  }

  constructor(private http: HttpClient) {
    this.fetchVideoList();
  }

  optioToken: string;
  videoList: OptioVideoList[];
  fetchVideoList() {
    const formData = new FormData();
    formData.append("username", apiEnvironment.optioUser);
    formData.append("password", apiEnvironment.optioCred); // Need to get from cert source
    axios
      .post(`${apiEnvironment.optioBaseUrl}/api/login`, formData, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((res) => {
        this.optioToken = res.data.token;
      })
      .then(() => {
        if (this.optioToken !== undefined) {
          axios
            .get(
              `${apiEnvironment.optioBaseUrl}/api/products?token=${this.optioToken}`
            )
            .then((response) => {
              console.info("videolist response", response);
              this.videoList = response.data;
            });
        }
      })
      .catch((err) => {
        console.info("Optio API error--------->--------->--------->", err);
      });
  }

  getVideoList() {
    return this.videoList;
  }

  getToken() {
    return this.optioToken;
  }

  initVideoToken() {
    try {
      const login = new FormData();
      login.append("username", "test@getkells.com");
      login.append("password", "test");
      fetch("https://sandbox.optiopublishing.com/api/login", {
        method: "post",
        credentials: "omit",
        mode: "cors",
        headers: {
          referrerPolicy: "no-referrer",
          // 'Referrer-Policy': 'no-referrer-when-downgrade',
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: login,
      })
        .then((response) => response.json())
        .then((data) => console.log(data));
    } catch (err) {
      console.info("Optio API error", err);
    }
    // this.http.post<{ token: string }>('https://sandbox.optiopublishing.com/api/login', {
    //   headers: {
    //     'Referrer Policy': 'no-referrer-when-downgrade'
    //   },
    //   observe: 'body',
    //   params: {
    //     'username': 'test@getkells.com',
    //     'password': 'test'
    //   },
    //   withCredentials:false
    // }).subscribe((response) => {
    //   if (response.token !== undefined) {
    //     this.optioToken = response.token;
    //     this.http.get<OptioVideoList[]>(`https://sandbox.optiopublishing.com/api/products?token=${this.optioToken}`)
    //       .subscribe((response) => {
    //         console.info('videolist response', response);
    //         //this.videoList = videos;
    //       });
    //   }
    // });
  }
}
