import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { Observable } from "rxjs";
import { FindingFilterElement } from "../../models/findings-filter.model";

@Component({
  selector: "koa-findings-filter",
  templateUrl: "./findings-filter.component.html",
  styleUrls: ["./findings-filter.component.scss"],
})
export class FindingsFilterComponent {
  @Input() findingsFilterItems$: Observable<FindingFilterElement>;

  @Output() selectedFindingsFilterTypeChanged = new EventEmitter<string>();
  @ViewChild("swiper", { static: false }) swiper: any;

  selectedFindingsFilterType = "";

  public get currentSlideIndex(): number {
    return this.swiper?.nativeElement?.swiper.activeIndex;
  }

  public get isPrevSlideVisible(): boolean {
    return !!this.currentSlideIndex;
  }

  public get isNextFilterSlideVisible(): boolean {
    return !this.swiper?.nativeElement?.swiper?.isEnd;
  }

  prevFilterSlide() {
    if (!this.swiper) return;
    this.swiper.nativeElement.swiper.slidePrev();
  }
  nextFilterSlide() {
    if (!this.swiper) return;
    this.swiper.nativeElement.swiper.slideNext();
  }

  toggleFindingsType(selectedType: string): void {
    this.selectedFindingsFilterType =
      this.selectedFindingsFilterType === selectedType ? "" : selectedType;
    this.selectedFindingsFilterTypeChanged.emit(
      this.selectedFindingsFilterType
    );
  }
}
