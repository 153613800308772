<div class="modal-wrapper">
  <h1 mat-dialog-title>Let us know a bit about you</h1>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="info-name">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" required />
          <mat-error
            class="error"
            *ngIf="form.controls['firstName']?.hasError('required')"
            >First Name is required</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName" required />
          <mat-error
            class="error"
            *ngIf="form.controls['lastName']?.hasError('required')"
            >Last Name is required</mat-error
          >
        </mat-form-field>
      </div>

      <div class="info-large">
        <div class="">
          <mat-form-field class="birthday">
            <mat-label
              >Date of Birth
              <span class="input-format">MM/DD/YYYY</span></mat-label
            >
            <input
              matInput
              formControlName="birthday"
              [matDatepicker]="dobPicker"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dobPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #dobPicker></mat-datepicker>
            <mat-error
              class="error"
              *ngIf="form.controls['birthday']?.hasError('required')"
              >Date of Birth is required</mat-error
            >
          </mat-form-field>
          <mat-form-field class="phone">
            <mat-label
              >Phone <span class="input-format">(XXX) XXX-XXXX</span></mat-label
            >
            <input
              matInput
              placeholder="(555) 444 1234"
              formControlName="phone"
              required
            />
            <mat-error
              class="error"
              *ngIf="
                form.controls['phone']?.hasError('pattern') ||
                form.controls['phone']?.hasError('maxlength') ||
                form.controls['phone']?.hasError('minlength')
              "
              >Please enter a valid phone number</mat-error
            >
            <mat-error
              class="error"
              *ngIf="form.controls['phone']?.hasError('required')"
              >Phone is required</mat-error
            >
          </mat-form-field>
        </div>
        <div>
          <mat-label class="gender-label">Gender *</mat-label>
          <mat-radio-group
            formControlName="gender"
            aria-label="Select your gender"
            class="gender-group"
          >
            <mat-radio-button class="gender-item" value="male"
              >Male</mat-radio-button
            >
            <mat-radio-button class="gender-item" value="female"
              >Female</mat-radio-button
            >
            <mat-radio-button class="gender-item" value="non-binary"
              >Non-binary</mat-radio-button
            >
          </mat-radio-group>
          <mat-error
            style="font-size: 75%"
            class="error"
            *ngIf="
              form.get('gender')?.hasError('required') &&
              form.get('gender')?.touched
            "
            >Gender is required</mat-error
          >
        </div>
      </div>
      <div class="location-row">
        <mat-form-field>
          <mat-label>State / Province</mat-label>
          <input matInput formControlName="state" required />
          <mat-error
            class="error"
            *ngIf="form.get('state')?.hasError('required')"
            >State / Province is required</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <mat-label>Postal / Zip Code</mat-label>
          <input matInput formControlName="zipcode" required />
          <mat-error
            class="error"
            *ngIf="form.get('zipcode')?.hasError('required')"
            >Postal / Zip Code is required</mat-error
          >
        </mat-form-field>
      </div>
      <div class="insurance-row last">
        <mat-checkbox
          class="insurance-checkbox"
          formControlName="has_insurance"
        >
          <mat-label>Do you have insurance?</mat-label>
        </mat-checkbox>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="onSubmit()" class="confirm-btn">Submit</button>
  </mat-dialog-actions>
</div>
