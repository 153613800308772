import { QueryParameter } from "../models/query-parameters.models";

export const getQueryParamsFromLink = (link: string): QueryParameter => {
  const queryParameters: QueryParameter = {};
  const paramString = link.split("?")[1];
  if (!paramString) {
    return queryParameters;
  }
  const queries = paramString.split("&");

  for (const query of queries) {
    const [key, value] = query.split("=");
    queryParameters[key] = value.toLowerCase();
  }

  return queryParameters;
};
