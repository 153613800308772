export enum TreatmentPriority {
  Low,
  Medium,
  High,
}

export const WHOLE_MOUTH_TREATMENT_LABEL = "Whole Mouth";

/** Broad treatment cagetories that each treatment will fall under.  */
export enum TreatmentTypes {
  Filling = "Filling",
  Crown = "Crown",
  RootCanal = "Root Canal",
  Extraction = "Extraction",
  Monitor = "Monitor",
  Implant = "Implant",
  Dentures = "Dentures",
  PartialDenture = "Partial Denture",
  DeepCleaning = "Deep Cleaning",
  Cleaning = "Cleaning",
  GumGraft = "Gum Graft",
  Other = "Other",
}

export const TREATMENT_PRIORITY_BY_TYPE = {
  [TreatmentTypes.Filling]: TreatmentPriority.Medium,
  [TreatmentTypes.Crown]: TreatmentPriority.High,
  [TreatmentTypes.RootCanal]: TreatmentPriority.High,
  [TreatmentTypes.Extraction]: TreatmentPriority.High,
  [TreatmentTypes.Monitor]: TreatmentPriority.Low,
  [TreatmentTypes.DeepCleaning]: TreatmentPriority.Medium,
  [TreatmentTypes.Cleaning]: TreatmentPriority.Low,
  [TreatmentTypes.Implant]: TreatmentPriority.Low,
  [TreatmentTypes.Dentures]: TreatmentPriority.Low,
  [TreatmentTypes.PartialDenture]: TreatmentPriority.Low,
  [TreatmentTypes.GumGraft]: TreatmentPriority.Low,
  [TreatmentTypes.Other]: TreatmentPriority.Low,
};

export interface TreatmentResponse {
  _id: string;
  description: string;
  notes: string;
  patient: string;
  session_date?: string;
  session: string;
  tooth: string;
}

export interface Treatment {
  id: string;
  patientId: string;
  sessionDate?: string;
  session: string;
  description: string;
  notes: string;
  tooth: string;
  /**
   * Added to cache date operations for performance
   */
  __originalDate?: Date;
}

export interface TreatmentCostEstimate {
  /** The type of treatment that this estimate applies to. */
  type: TreatmentTypes;
  /** Estimated min cost. */
  costMin?: number;
  /** Estimated max cost. */
  costMax?: number;
  /** The condition under which the above cost minimum and maximum applies. */
  costContext?: string;
}

export type AddTreatmentData = Pick<
  Treatment,
  "description" | "notes" | "tooth"
>;
