/**
 * An enumeration of all the possible colors of a label box.
 */
export enum LabelBoxColors {
  CariesInitial = "#f2f2f2",
  CariesModerate = "#ffb04f",
  CariesAdvanced = "#ff4747",
  Tooth = "#a1a1a1",
  Material = "#73beff",

  BonelossAnchor = "#f2f2f2",
  BonelossInitial = "#f2f2f2",
  BonelossModerate = "#ffb04f",
  BonelossAdvanced = "#ff4747",
}
