<div class="success-modal">
  <div class="title">Thank you for upgrading to Premium!</div>
  <div class="description">
    <p>
      Your subscription has been successfully updated, unlocking exclusive
      features and enhanced benefits just for you.
    </p>
  </div>

  <div>
    <button
      (click)="goToDashboard()"
      mat-button
      class="action-button button-primary"
    >
      OK
    </button>
  </div>
</div>
