import { Action, createReducer, on } from "@ngrx/store";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import * as PatientsActions from "./patient.actions";
import { ImageId, Patient, SessionIndexed } from "@kells/clinic-one/apis";
import { get } from "lodash-es";
import { isDefined } from "@kells/utils/js";
import { ReviewJobActions } from "@kells/clinic-one/data-access/review-jobs";

export const patientFeatureKey = "patients";

export interface PatientState extends EntityState<Patient> {
  readonly isLoadingPatientsList: boolean;
  readonly loadPatientListFailed: boolean;
  /** An array of patient IDs for patients who visited the office recently. */
  readonly mostRecentPatientIds: string[];
}

const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>();

export const initialState: PatientState = adapter.getInitialState({
  isLoadingPatientsList: false,
  loadPatientListFailed: false,
  mostRecentPatientIds: [],
});

const patientsReducer = createReducer(
  initialState,
  on(PatientsActions.fetchPatientList, (state) => ({
    ...state,
    isLoadingPatientsList: true,
    loadPatientListFailed: false,
  })),
  on(PatientsActions.fetchPatientListFailure, (state) => ({
    ...state,
    isLoadingPatientsList: false,
    loadPatientListFailed: true,
  })),
  on(
    PatientsActions.fetchPatientListSuccess,
    PatientsActions.fetchMostRecentPatientsSuccess,
    (state, { patientEntities }) => {
      const patientsToInsert = patientEntities.map(
        (patient): Patient => {
          const prevState = state.entities[patient.id];
          if (prevState) {
            return {
              ...patient,
              predicting: prevState.predicting,
            };
          }
          return patient;
        }
      );

      return adapter.upsertMany(patientsToInsert, {
        ...state,
        isLoadingPatientsList: false,
        loadPatientListFailed: false,
      });
    }
  ),
  on(
    PatientsActions.fetchMostRecentPatientsSuccess,
    (state, { patientEntities }) => ({
      ...state,
      mostRecentPatientIds: patientEntities.map((patient) => patient.id),
    })
  ),
  on(
    PatientsActions.fetchPatientSuccess,
    PatientsActions.fetchXVWebPatientFromPatientHomeSuccess,
    (state, { patientData }) => adapter.upsertOne(patientData, { ...state })
  ),
  on(ReviewJobActions.getAllReviewJobsSuccess, (state, { reviewJobs }) =>
    adapter.upsertMany(
      reviewJobs.map((r) => r.patient),
      state
    )
  )
);

export function reducer(state: PatientState | undefined, action: Action) {
  return patientsReducer(state, action);
}

export const { selectEntities } = adapter.getSelectors();

export const isLoadingPatientsList = (state: PatientState) => {
  return state.isLoadingPatientsList;
};

export const didLoadPatientListFail = (state: PatientState) =>
  state.loadPatientListFailed;

export const getSessionDateIndexedImages = (
  state: PatientState,
  props: { patientId: string }
): SessionIndexed<ImageId> =>
  get(state, ["entities", props.patientId, "images"], null);

export const getPatientName = (
  state: PatientState,
  props: { patientId: string }
): string | undefined => {
  const nameConstructor: string[] = [
    get(state, ["entities", props.patientId, "firstName"], null),
    get(state, ["entities", props.patientId, "lastName"], null),
  ];
  return (
    nameConstructor
      .filter((s) => s)
      .join(" ")
      .trim() || undefined
  );
};

export const getPatientEmail = (
  state: PatientState,
  props: { patientId: string }
): string | undefined => {
  return (
    [get(state, ["entities", props.patientId, "email"], null)]
      .filter((s) => s)
      .join("")
      .trim() || undefined
  );
};

export const mostRecentPatients = (state: PatientState) => {
  return state.mostRecentPatientIds
    .map((patientId) => state.entities[patientId])
    .filter((patient): patient is Patient => isDefined(patient));
};
