import { createAction, props } from "@ngrx/store";
import { Finding } from "@kells/clinic-one/apis";

enum ActionTypes {
  SessionPredictionStart = "[Prediction / Patient Session] Prediction Start",

  StoryboardPredictionStart = "[Prediction / Patient Storyboard] Prediction Start",

  ImageDetailPredictionStart = "[Prediction / Image Detail] Prediction Start",

  PredictImage = "[Prediction API] Predict Image",
  PredictImageSuccess = "[prediction API] Predict Image Success",
  PredictImageFailure = "[Prediction API] Predict Image Failure",
}

export const sessionPredictionStart = createAction(
  ActionTypes.SessionPredictionStart,
  props<{ patientId: string; imageIds: string[] }>()
);

export const storyboardPredictionStart = createAction(
  ActionTypes.StoryboardPredictionStart,
  props<{ patientId: string; imageIds: string[] }>()
);

export const imageDetailPredictionStart = createAction(
  ActionTypes.ImageDetailPredictionStart,
  props<{ patientId: string; imageId: string }>()
);

export const predictImage = createAction(
  ActionTypes.PredictImage,
  props<{
    imageId: string;
    patientId: string;
    source: "session" | "storyboard" | "image-detail";
  }>()
);

export const predictImageSuccess = createAction(
  ActionTypes.PredictImageSuccess,
  props<{
    imageFindings: Finding[];
    imageId: string;
    patientId: string;
    source: "session" | "storyboard" | "image-detail";
  }>()
);

export const predictImageFailure = createAction(
  ActionTypes.PredictImageFailure,
  props<{
    error: any;
    imageId: string;
    patientId: string;
    source: "session" | "storyboard" | "image-detail";
  }>()
);

export const errorActions = [predictImageFailure];
