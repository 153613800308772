import { createSelector } from "@ngrx/store";
import {
  ClassificationService,
  FindingService,
  ImageService,
  Image,
  SessionIndexed,
  ToothNumberIndexed,
  StoryboardImageFilters,
  Treatment,
  WHOLE_MOUTH_TREATMENT_LABEL,
} from "@kells/clinic-one/apis";
import { selectCoreModule } from "./module.selector";
import * as fromSelectedPatient from "./selected-patient";
import * as fromImages from "./image/image.reducer";
import * as fromLayout from "./layout/layout.reducer";
import * as fromPatients from "./patient/patient.reducer";
import * as fromNotifications from "./notification/notifications.reducer";

import * as fromRouter from "../../store/router.reducer";
import { selectAllTreatments } from "./treatment/treatment.selectors";
import { PatientReportToothEntry } from "@app/report";

import { isDefined, isNullable } from "@kells/utils/js";
import {
  FindingProcessingService,
  FindingType,
} from "@kells/interfaces/finding";
import { ToothService } from "@kells/interfaces/tooth";
import { flatten } from "lodash-es";
import { By, ImageFilterService } from "../services/image-filter.service";
import { FindingForReport } from "@app/report/components/report/models/report.model";
import {
  selectAllReviewJobs,
  ReviewJobWithOptionalPatient,
} from "@kells/clinic-one/data-access/review-jobs";

export * from "./prediction/prediction.selectors";

/**
 * Reorganize images into key-value pairs, where the keys are image IDs and
 * values are the findings shown in that image's report.
 *
 * @param images an array of images to extract reportable findings from.
 *
 * @returns an [[`ImageFindingsCollection`]]. See [[`ImageFindingsCollection`]]
 *   for additional detail about the return signature.
 */
const createReport = (args: {
  patientId: string;
  sessionId: string;
  sessionImages: Image[];
  treatments: Treatment[];
  threshold?: number;
}): PatientReportToothEntry[] => {
  const { patientId, sessionId, sessionImages, treatments, threshold } = args;
  const defaultThreshold = threshold ? threshold : 0.5; //findings of threshold value below 0.5 will not be shown in treatment plan

  const treatmentsInReport = treatments
    .filter((t) => t.patientId === patientId)
    .filter((t) => t.session === sessionId);

  const allFindingsInReport: FindingForReport[] = flatten(
    sessionImages.map((g) =>
      FindingService.keepDiagnosisFindings(g.findings)
        .map((f) => ({
          ...f,
          imageId: g.id,
          findingDescription: FindingProcessingService.toFindingText(f),
        }))
        .filter((item) =>
          item.predictionScore ? item.predictionScore >= defaultThreshold : item
        )
    )
  );

  const allTeeth = new Set(
    [
      ...allFindingsInReport.map((f) => f.tooth),
      ...treatmentsInReport.map((t) => t.tooth),
    ].filter(ToothService.isTooth)
  );
  const ascendingTeethArray: string[] = [...allTeeth]
    .map((t) => +t)
    .sort((a, b) => a - b)
    .map((t) => t.toString());

  return [WHOLE_MOUTH_TREATMENT_LABEL, ...ascendingTeethArray].map((tooth) => ({
    tooth,
    isWholeMouth: tooth === WHOLE_MOUTH_TREATMENT_LABEL,
    treatments: treatmentsInReport.filter((t) => t.tooth === tooth),
    findings: allFindingsInReport.filter((f) => f.tooth === tooth),
  }));
};

function _getSessionDateOfImage(
  patientState: fromPatients.PatientState,
  patientId: string | null | undefined,
  imageId: string | null | undefined
): string | undefined {
  if (!patientId) return;

  const selectedPatient = patientState.entities[patientId];
  if (!selectedPatient || !selectedPatient.images) return;

  const locatedSession = Object.entries(
    selectedPatient.images
  ).find(([, imageIds]) => imageIds.find((id) => id === imageId));

  if (!locatedSession) return;

  const [locatedSessionDate] = locatedSession;
  return locatedSessionDate;
}

const selectImagesState = createSelector(
  selectCoreModule,
  (state) => state.images
);

const selectPatientsState = createSelector(
  selectCoreModule,
  (state) => state.patients
);

const selectSelectedPatientState = createSelector(
  selectCoreModule,
  (state) => state.selectedPatient
);

const selectLayoutState = createSelector(
  selectCoreModule,
  (state) => state.layout
);

const selectNotificationsState = createSelector(
  selectCoreModule,
  (state) => state.notifications
);

export const getImageFindings = createSelector(
  selectImagesState,
  fromImages.getFindingsForImage
);

export const getImageRenderableFindings = createSelector(
  getImageFindings,
  (imageFindings) => {
    if (!imageFindings) return [];

    return FindingService.keepRenderableFindings(imageFindings);
  }
);

export const getImageCaries = createSelector(
  getImageRenderableFindings,
  (renderableFindings) => {
    return renderableFindings.filter((f) => f.type === FindingType.Caries);
  }
);

export const getImage = createSelector(selectImagesState, fromImages.getImage);

export const getImageUrl = createSelector(
  selectImagesState,
  fromImages.getImageUrl
);

export const getFindingsLoadingStatus = createSelector(
  selectImagesState,
  fromImages.getLoadingStatus
);

export const getImageConfirmationStatus = createSelector(
  selectImagesState,
  fromImages.isImageConfirmed
);

export const getTreatmentCount = createSelector(
  selectImagesState,
  fromImages.getTotalTreatmentCount
);

export const areImageFindingsLoaded = createSelector(
  selectImagesState,
  fromImages.areImageFindingsLoaded
);

export const getImageDefinitions = createSelector(
  selectImagesState,
  (
    imageState: fromImages.ImageState,
    props: { imageIds: string[] }
  ): { id: string; url: string }[] => {
    const images: Image[] = props.imageIds
      .map((imageId) => imageState.entities[imageId])
      .filter((g): g is Image => isDefined(g));

    return images.map((g) => ({ id: g.id, url: g.url }));
  }
);

export const getClassifierVersionNumber = createSelector(
  selectImagesState,
  fromImages.getClassifierVersionNumber
);

const getSessionIndexedImageIds = createSelector(
  selectPatientsState,
  fromPatients.getSessionDateIndexedImages
);

export const getSessionIndexedImages = createSelector(
  getSessionIndexedImageIds,
  selectImagesState,
  (sessionIndexedImageIds, images) => {
    if (isNullable(sessionIndexedImageIds)) return {};

    return Object.fromEntries(
      Object.entries(sessionIndexedImageIds).map(([sessionDate, imageIds]) => [
        sessionDate,
        imageIds
          .map((id) => images.entities[id])
          .filter((g): g is Image => Boolean(g)),
      ])
    );
  }
);

export const getAllPatients = createSelector(
  selectPatientsState,
  fromPatients.selectEntities
);

export const getPatientSessionImages = createSelector(
  selectPatientsState,
  selectImagesState,
  (
    patientState: fromPatients.PatientState,
    imageState: fromImages.ImageState,
    props: { patientId: string; sessionDate: string; sessionId: string }
  ) => {
    const { entities: patientEntities } = patientState;
    const { entities: imageEntities } = imageState;

    const patient = patientEntities[props.patientId];
    if (!patient) return [];

    const { images } = patient;
    if (!images) return [];

    const selectedSessionImageIds = images[props.sessionDate];
    if (!selectedSessionImageIds) return [];

    return selectedSessionImageIds
      .map((id) => imageEntities[id])
      .filter(
        (g): g is Image => Boolean(g) && g?.sessionId === props.sessionId
      );
  }
);

export const getIsSessionConfirming = createSelector(
  getPatientSessionImages,
  (images) => {
    return images.some((image) => image.isConfirming);
  }
);

/**
 * Returns whether all the images in a session has been confirmed.
 */
export const isSessionConfirmed = createSelector(
  getPatientSessionImages,
  (images) => {
    if (isNullable(images) || images.length === 0) return false;

    return ImageService.isSessionConfirmed(images);
  }
);

export const getPatientName = createSelector(
  selectPatientsState,
  fromPatients.getPatientName
);

export const getPatientEmail = createSelector(
  selectPatientsState,
  fromPatients.getPatientEmail
);

export const getMostRecentPatients = createSelector(
  selectPatientsState,
  fromPatients.mostRecentPatients
);

export const getSelectedPatientId = createSelector(
  selectSelectedPatientState,
  fromSelectedPatient.getId
);

export const getSelectedPatientTab = createSelector(
  selectSelectedPatientState,
  fromRouter.getRouterState,
  fromSelectedPatient.getTab
);

export const isClassifyingImagesFromSelectedPatient = createSelector(
  selectSelectedPatientState,
  fromSelectedPatient.isClassifying
);

export const getClassificationProgressFromSelectedPatient = createSelector(
  selectSelectedPatientState,
  fromSelectedPatient.classificationProgress
);

export const isCreatingPatientSession = createSelector(
  selectSelectedPatientState,
  fromSelectedPatient.isCreatingPatientSession
);

export const getStartPredictingFrom = createSelector(
  selectSelectedPatientState,
  fromRouter.getRouterState,
  fromSelectedPatient.getStartPredictingFrom
);

export const getSelectedPatient = createSelector(
  selectPatientsState,
  getSelectedPatientId,
  (state, patientId) => {
    if (isNullable(patientId)) {
      return;
    }

    return state.entities[patientId];
  }
);

export const isLoadingPatientsList = createSelector(
  selectPatientsState,
  fromPatients.isLoadingPatientsList
);

export const getSessionDateOfImage = createSelector(
  selectPatientsState,
  (
    patientState: fromPatients.PatientState,
    props: { patientId: string; imageId: string }
  ) => {
    return _getSessionDateOfImage(patientState, props.patientId, props.imageId);
  }
);

export const isLoadingSelectedPatient = createSelector(
  selectSelectedPatientState,
  fromSelectedPatient.getLoadingStatus
);

export const isOrderingImages = createSelector(
  selectSelectedPatientState,
  fromSelectedPatient.isOrderingImages
);

export const didLoadSelectedPatientFail = createSelector(
  selectSelectedPatientState,
  fromSelectedPatient.getFailedStatus
);

export const getSelectedPatientName = createSelector(
  getSelectedPatient,
  (patient) => (patient ? `${patient.firstName} ${patient.lastName}` : "")
);

export const getSelectedPatientImages = createSelector(
  getSelectedPatient,
  selectImagesState,
  (patient, { entities }) => {
    if (!patient) return {};
    const { images } = patient;
    if (!images) return {};
    return Object.entries(images).reduce(
      (a, [date, targetIds]) => ({
        ...a,
        [date]: Object.values(entities).filter(
          (g) => !isNullable(g) && targetIds.includes(g.id)
        ),
      }),
      {}
    ) as SessionIndexed<Image>;
  }
);

export const getSelectedPatientImagesArray = createSelector(
  getSelectedPatient,
  selectImagesState,
  (selectedPatient, { entities }): Image[] => {
    if (!selectedPatient) return [];

    const { images: sessionIndexedImageIds } = selectedPatient;

    if (!sessionIndexedImageIds) return [];

    const imageIds = flatten(Object.values(sessionIndexedImageIds));

    return imageIds
      .map((id) => entities[id])
      .filter((g): g is Image => isDefined(g));
  }
);

export const doesSelectedPatientHaveImages = createSelector(
  isLoadingSelectedPatient,
  getSelectedPatientImagesArray,
  (isLoading, selectedPatientImages) => {
    if (isLoading) return;
    if (isNullable(selectedPatientImages)) return;
    return selectedPatientImages.length > 0;
  }
);

export const getSelectedPatientSessionDates = createSelector(
  selectSelectedPatientState,
  selectPatientsState,
  (selectedPatientState, patientsState) => {
    if (!selectedPatientState.id) return [];

    const selectedPatient = patientsState.entities[selectedPatientState.id];

    if (!selectedPatient) return [];

    const { images: selectedPatientImages } = selectedPatient;
    if (!selectedPatientImages) return [];

    const allPatientSessions = Object.keys(selectedPatientImages);
    if (allPatientSessions.length === 0) return [];

    if (selectedPatientState.sessionSelection.selectAll) {
      return allPatientSessions;
    }

    if (selectedPatientState.sessionSelection.selectLatest) {
      return allPatientSessions?.length > 0 ? [allPatientSessions[0]] : [];
    }

    return selectedPatientState.sessionSelection.selected;
  }
);

export const getSelectedPatientImageFilter = createSelector(
  selectLayoutState,
  fromLayout.getImageFilter
);

export const getStoryboardImageFilters = createSelector(
  selectLayoutState,
  fromLayout.getStoryboardFilters
);

export const getSelectedPatientSessionImages = createSelector(
  getSelectedPatientImages,
  getSelectedPatientSessionDates,
  (patientImages, selectedSessionDates) =>
    patientImages && selectedSessionDates
      ? selectedSessionDates.reduce((acc, session) => {
          if (patientImages[session]) {
            return acc.concat(patientImages[session]);
          }
          return acc;
        }, [] as Image[])
      : []
);

/**
 * If a patient is selected, returns all the images selected by the user,
 * filtered by the current image filter and indexed by session dates.
 *
 * Images in each session are sorted by user-defined order indices, if such
 * indices are available.
 */
export const getVisibleSessionIndexedImages = createSelector(
  getSelectedPatientImages,
  getSelectedPatientSessionDates,
  getSelectedPatientImageFilter,
  (patientImages, selectedSessionDates, filter) => {
    if (!patientImages || !selectedSessionDates) {
      return {};
    }
    return selectedSessionDates.reduce((acc, sessionKey) => {
      if (!patientImages[sessionKey]) {
        console.error(`
            Expected to find images for session ${sessionKey} but none could
            be found.
          `);
        return acc;
      }

      const sortedSessionImages = ClassificationService.sortByImageFilter({
        images: patientImages[sessionKey],
        filter,
      });

      return {
        ...acc,
        [sessionKey]: sortedSessionImages,
      };
    }, {} as SessionIndexed<Image>);
  }
);

export const getAllSessionImages = createSelector(
  getSelectedPatientImages,
  getSelectedPatientSessionDates,
  getSelectedPatientImageFilter,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (patientImages, selectedSessionDates) => {
    if (!patientImages || !selectedSessionDates) {
      return {};
    }
    return patientImages;
  }
);

export const getSessionImagesToPredict = createSelector(
  getSelectedPatientSessionImages,
  (imagesFromSelectedSessions) => {
    return ImageService.filterImagesToPredict(imagesFromSelectedSessions);
  }
);

export const getStoryboardImagesToPredict = createSelector(
  getSelectedPatientImages,
  (images) => {
    return ImageService.filterImagesToPredict(
      ImageService.flattenSessionIndexedEntity(images)
    );
  }
);

export const getSelectedImageId = createSelector(
  fromRouter.getRouterState,
  (router) => router.state && router.state.params.imageId
);

/**
 * Extracts the Image Url. Intended to be used in components related to image
 * rendering, such as `ImageDetailComponent`.
 */
export const getSelectedImageUrl = createSelector(
  fromRouter.getRouterState,
  (router) => router.state && router.state.queryParams.imageUrl
);

export const isPredictingImage = createSelector(
  selectImagesState,
  fromImages.isPredictingImage
);

export const getSelectedImageFindings = createSelector(
  selectImagesState,
  getSelectedImageId,
  (state, imageId: string) => fromImages.getFindingsForImage(state, { imageId })
);

export const getIsDeletingStatus = createSelector(
  selectImagesState,
  fromImages.getIsDeletingStatus
);

export const getSelectedImage = createSelector(
  selectImagesState,
  getSelectedImageId,
  (state, imageId) => state.entities[imageId]
);

export const selectImageReportSessionDate = createSelector(
  selectPatientsState,
  getSelectedPatientId,
  getSelectedImage,
  (patientState, selectedPatientId, selectedImage) => {
    return _getSessionDateOfImage(
      patientState,
      selectedPatientId,
      selectedImage?.id
    );
  }
);

export const getFindingFilterTypes = createSelector(
  selectLayoutState,
  fromLayout.getFindingFilterTypes
);

export const getLabelsEnabledStatus = createSelector(
  selectLayoutState,
  fromLayout.getLabelsEnabled
);

export const getInsightEnabledStatus = createSelector(
  selectLayoutState,
  fromLayout.getInsightEnabled
);

export const getUseImageEnhancementStatus = createSelector(
  selectLayoutState,
  fromLayout.getUseImageEnhancementStatus
);

export const isSidebarOpen = createSelector(
  selectLayoutState,
  fromLayout.getSidebarOpenStatus
);

export const isSessionListVisible = createSelector(
  selectLayoutState,
  fromLayout.isSessionListVisible
);

export const isPatientSearchVisible = createSelector(
  selectLayoutState,
  fromLayout.isPatientSearchVisible
);

export const isPatientReportOpen = createSelector(
  selectLayoutState,
  fromLayout.getPatientReportOpenStatus
);

export const getSessionDateForPatientReport = createSelector(
  selectLayoutState,
  fromLayout.getSessionDateForPatientReport
);

export const getSessionIdForPatientReport = createSelector(
  selectLayoutState,
  fromLayout.getSessionIdForPatientReport
);

export const isInProductTour = createSelector(
  selectLayoutState,
  fromLayout.isInProductTour
);

export const getSettingsExitUrl = createSelector(
  selectLayoutState,
  fromLayout.getSettingsExitUrl
);

export const getNotifications = createSelector(
  selectNotificationsState,
  fromNotifications.getNotifications
);

export const getUnreadNotificationsCount = createSelector(
  selectNotificationsState,
  fromNotifications.unreadNotificationCount
);

/**
 * Plucks out the findings that will be part of the displayed patient report.
 * Such findings must be associated with the report's session and must be
 * user-visible.
 *
 * @returns an [[`ImageFindingsCollection`]] in which all findings are user-visible.
 */
export const getPatientReport = (threshold: number = 0) =>
  createSelector(
    getSelectedPatientId,
    getSessionDateForPatientReport,
    getSessionIdForPatientReport,
    getVisibleSessionIndexedImages,
    selectAllTreatments,
    (
      selectedPatientId,
      reportSessionDate,
      reportSessionId,
      visibleSessionIndexedImages,
      treatments
    ) => {
      if (
        isNullable(selectedPatientId) ||
        isNullable(reportSessionDate) ||
        isNullable(reportSessionId) ||
        isNullable(visibleSessionIndexedImages[reportSessionDate])
      ) {
        return [];
      }

      const imagesForReport = visibleSessionIndexedImages[reportSessionDate];

      return createReport({
        patientId: selectedPatientId,
        sessionId: reportSessionId,
        sessionImages: imagesForReport.filter(
          (g) => g.sessionId === reportSessionId
        ),
        treatments: treatments.filter((t) => t.session === reportSessionId),
        threshold,
      });
    }
  );

/**
 * Extracts the findings taht will be part of the displayed report for a
 * patient session.
 *
 * @returns an [[`ImageFindingsCollection`]] in which all findings are user-visible.
 */
export const getReportByPatientSession = createSelector(
  getSelectedPatientId,
  getVisibleSessionIndexedImages,
  selectAllTreatments,
  (
    selectedPatientId: string | null,
    visibleSessionIndexedImages: SessionIndexed<Image>,
    treatments: Treatment[],
    props: { sessionId: string; sessionDate: string }
  ) => {
    const imagesForReport = visibleSessionIndexedImages[props.sessionDate];

    if (!selectedPatientId) {
      console.warn("Cannot access patient report if no patient is selected.");
      return;
    }

    if (!imagesForReport) {
      console.warn(`
        Session ${props.sessionDate} is not available under the currently
        visible session images.
      `);
      return;
    }

    return createReport({
      patientId: selectedPatientId,
      sessionId: props.sessionId,
      sessionImages: imagesForReport.filter(
        (i) => i.sessionId === props.sessionId
      ),
      treatments: treatments.filter((t) => t.session === props.sessionId),
    });
  }
);

export const getSessionIndexedTreatments = createSelector(
  selectAllTreatments,
  (
    treatments: Treatment[],
    props: { patientId: string; sessionId: string }
  ) => {
    if (!treatments || !props.sessionId) return {};

    const treatmentsForPatient = treatments.filter(
      (t) => t.patientId === props.patientId && t.session === props.sessionId
    );

    return { [props.sessionId]: treatmentsForPatient };
  }
);

export const getReportFromSelectedImage = createSelector(
  getSelectedPatientId,
  selectAllTreatments,
  getVisibleSessionIndexedImages,
  getSelectedImage,
  (selectedPatientId, treatments, sessionIndexedImages, selectedImage) => {
    if (
      isNullable(selectedPatientId) ||
      isNullable(treatments) ||
      isNullable(sessionIndexedImages) ||
      isNullable(selectedImage)
    ) {
      return;
    }

    return createReport({
      sessionId: selectedImage.sessionId,
      sessionImages: [selectedImage],
      treatments: treatments.filter(
        (t) => t.session === selectedImage.sessionId
      ),
      patientId: selectedPatientId,
    });
  }
);

export const getSelectedPatientToothIndexedImages = createSelector(
  getSelectedPatientImagesArray,
  (images): ToothNumberIndexed<Image> => {
    const reverseChronologicalImages = images.reverse();

    const toothNumbers = ToothService.generateToothNumbersArray();

    const EMPTY = toothNumbers.reduce(
      (acc, toothNumber) => ({ ...acc, [toothNumber]: [] }),
      {} as ToothNumberIndexed<Image>
    );

    if (reverseChronologicalImages.length === 0) return EMPTY;

    return toothNumbers.reduce(
      (acc, toothNumber) => ({
        ...acc,
        [toothNumber]: ImageFilterService.filter(
          reverseChronologicalImages,
          By.ContainingTooth(toothNumber)
        ),
      }),
      {} as ToothNumberIndexed<Image>
    );
  }
);

export const getStoryboardToothIndexedImages = createSelector(
  getSelectedPatientToothIndexedImages,
  getStoryboardImageFilters,
  (toothIndexedImages, storyboardFilters) => {
    // if no filters are set, returns the tooth indexed images as-is.
    if (storyboardFilters.length === 0) return toothIndexedImages;

    if (storyboardFilters.includes(StoryboardImageFilters.Caries)) {
      // for each tooth indexed images array, keep only the ones with caries
      return Object.entries(toothIndexedImages).reduce(
        (acc, [tooth, images]) => {
          const toothNumber = Number.parseInt(tooth, 10);

          const imagesWithCariesFindingsOnTooth = images.map((g) => ({
            ...g,
            findings: g.findings
              .filter((f) => +(f.tooth ?? 0) === toothNumber)
              .filter((f) => f.type === FindingType.Caries),
          }));

          return {
            ...acc,
            [toothNumber]: imagesWithCariesFindingsOnTooth,
          };
        },
        {} as ToothNumberIndexed<Image>
      );
    }

    return toothIndexedImages;
  }
);

export const getAllReviewJobsWithPatients = createSelector(
  selectAllReviewJobs,
  selectPatientsState,
  (reviewJobs, patientState) => {
    return reviewJobs.map(
      (r): ReviewJobWithOptionalPatient => ({
        ...r,
        patient: patientState.entities[r.patientId],
      })
    );
  }
);
