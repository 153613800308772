import { createEventsFromSchematics, eventDetail } from "@kells/apis/analytics";

interface OpenReportEventInterface {
  patientId: string;
  sessionDate: string;
}

enum EventTypes {
  UserLoggedIn = "User has logged in",
}

const eventCreators = createEventsFromSchematics({
  UserLoggedInEvent: {
    displayedName: EventTypes.UserLoggedIn,
  },
});

export const { UserLoggedInEvent } = eventCreators;
