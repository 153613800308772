import { Action, createReducer, on, createSelector } from "@ngrx/store";
import { EntityState, createEntityAdapter, EntityAdapter } from "@ngrx/entity";

import { isNullable } from "@kells/utils/js";
import * as PatientActions from "../patient/patient.actions";
import * as PredictionActions from "@app/core/store/prediction/prediction.actions";
import {
  ImageService,
  Image,
  ImageOrderMode,
  Finding,
  ClassificationBaseKind,
  TemplateType,
} from "@kells/clinic-one/apis";
import {
  ImageFindingActions,
  ImageActions,
  ImageConfirmationActions,
  ImageRotationActions,
  ImageClassificationActions,
} from "./actions";
import { FindingStatus } from "@kells/interfaces/finding";

export const imageFeatureKey = "images";

export interface ImageState extends EntityState<Image> {
  isLoading: boolean;
  classifierVersion: string | null;
}

export const adapter: EntityAdapter<Image> = createEntityAdapter<Image>();

export const initialState: ImageState = adapter.getInitialState({
  isLoading: false,
  classifierVersion: null,
});

const imagesReducer = createReducer(
  initialState,
  on(ImageActions.createImage, (state, { id, url }) =>
    adapter.addOne(
      {
        id,
        url,
        captureTime: null,
        xrayCaptureTime: null,
        orderMode: ImageOrderMode.NotInitialized,
        xrayType: ClassificationBaseKind.NotInitialized,
        isBeingPredicted: false,
        isPredicted: false,
        isPredictionFailed: false,
        isBeingDeleted: false,
        isDeleted: false,
        isDeletedFailed: false,
        isConfirming: false,
        findings: [],
        treatments: [],
        status: FindingStatus.Default,
        sessionId: "",
      },
      state
    )
  ),
  on(
    ImageFindingActions.fetchFindings,
    ImageFindingActions.addNewFinding,
    ImageConfirmationActions.confirmImage,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(ImageActions.imageTemplateDragged, (state, { imageId, templateId }) => ({
    ...adapter.updateOne(
      {
        id: imageId,
        changes: {
          templateId,
          dragged: true,
          template_type: TemplateType.FMX,
        },
      },
      state
    ),
  })),
  on(ImageActions.imagePanoDragged, (state, { imageId, template_type }) => ({
    ...adapter.updateOne(
      {
        id: imageId,
        changes: {
          template_type,
          dragged: true,
        },
      },
      state
    ),
  })),
  on(
    ImageFindingActions.fetchFindingsSuccess,
    (state, { imageId, findingEntities }) => ({
      ...adapter.updateOne(
        {
          id: imageId,
          changes: {
            findings: findingEntities,
            loaded: true,
          },
        },
        state
      ),
      isLoading: false,
    })
  ),
  on(
    ImageFindingActions.fetchFindingsFailure,
    ImageFindingActions.addNewFindingFailure,
    ImageConfirmationActions.confirmImageFailure,
    ImageFindingActions.addNewFindingSuccess,
    ImageActions.autoArrangeImageOrderFailure,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(ImageConfirmationActions.confirmSessionStarted, (state, { imageIds }) =>
    adapter.updateMany(
      imageIds.map((imageId) => ({
        id: imageId,
        changes: {
          isConfirming: true,
        },
      })),
      state
    )
  ),
  on(ImageConfirmationActions.confirmSessionEnded, (state, { imageIds }) =>
    adapter.updateMany(
      imageIds.map((imageId) => ({
        id: imageId,
        changes: {
          isConfirming: false,
        },
      })),
      state
    )
  ),
  on(
    PatientActions.fetchPatientSuccess,
    PatientActions.fetchXVWebPatientFromPatientHomeSuccess,
    (state, { imageEntities }) => {
      const imagesToAdd = imageEntities.map((g) => ({
        ...g,
        loaded: g.findings.length > 0,
      }));
      return adapter.upsertMany(imagesToAdd, state);
    }
  ),
  on(ImageActions.updateSessionImageOrdering, (state, { updatedOrdering }) =>
    adapter.updateMany(
      updatedOrdering.map(({ imageId: id, orderId }) => ({
        id,
        changes: { orderId },
      })),
      state
    )
  ),
  on(
    ImageRotationActions.rotateImageLeftSuccess,
    ImageRotationActions.rotateImageRightSuccess,
    (state, { image }) =>
      adapter.updateOne(
        {
          id: image.id,
          changes: image,
        },
        state
      )
  ),
  on(
    ImageRotationActions.leftRotateMultipleImagesSuccess,
    ImageRotationActions.rightRotateMultipleImagesSuccess,
    (state, { images }) =>
      adapter.updateMany(
        images.map((image) => ({ id: image.id, changes: image })),
        state
      )
  ),
  on(ImageActions.imageTemplateSavedSuccess, (state, { images }) =>
    adapter.updateMany(
      images.map((image) => ({
        id: image.id,
        changes: {
          dragged: false,
        },
      })),
      state
    )
  ),
  on(ImageActions.unsetTemplateImagesSuccess, (state, { images }) =>
    adapter.updateMany(
      images.map((image) => ({
        id: image.id,
        changes: {
          templateId: undefined,
          template_type: "unknown",
        },
      })),
      state
    )
  ),
  on(
    ImageFindingActions.deleteSessionImageFindingsStart,
    (state, { imageId }) =>
      adapter.updateOne(
        {
          id: imageId,
          changes: {
            isBeingDeleted: true,
            isDeleted: false,
            isDeletedFailed: false,
          },
        },
        state
      )
  ),
  on(
    ImageFindingActions.deleteSessionImageFindingsSuccess,
    (state, { imageId }) =>
      adapter.updateOne(
        {
          id: imageId,
          changes: {
            isBeingDeleted: false,
            isDeleted: true,
            isDeletedFailed: false,
          },
        },
        state
      )
  ),
  on(
    ImageFindingActions.deleteSessionImageFindingsFailure,
    (state, { imageId }) =>
      adapter.updateOne(
        {
          id: imageId,
          changes: {
            isBeingDeleted: false,
            isDeleted: false,
            isDeletedFailed: true,
          },
        },
        state
      )
  ),
  on(PredictionActions.predictImage, (state, { imageId }) =>
    adapter.updateOne(
      {
        id: imageId,
        changes: {
          isBeingPredicted: true,
          isPredicted: false,
          isPredictionFailed: false,
        },
      },
      state
    )
  ),
  on(PredictionActions.predictImageSuccess, (state, { imageId }) =>
    adapter.updateOne(
      {
        id: imageId,
        changes: {
          isBeingPredicted: false,
          isPredicted: true,
          isPredictionFailed: false,
        },
      },
      state
    )
  ),
  on(PredictionActions.predictImageFailure, (state, { imageId }) =>
    adapter.updateOne(
      {
        id: imageId,
        changes: {
          isBeingPredicted: false,
          isPredicted: false,
          isPredictionFailed: true,
        },
      },
      state
    )
  ),
  on(
    PredictionActions.predictImageSuccess,
    (state, { imageId, imageFindings }) => {
      return adapter.updateOne(
        {
          id: imageId,
          changes: { findings: imageFindings, isPredictionFailed: false },
        },
        state
      );
    }
  ),
  on(
    ImageClassificationActions.getVersionNumberSuccess,
    (state, { versionNumber }) => ({
      ...state,
      classifierVersion: versionNumber,
    })
  ),
  on(
    ImageClassificationActions.classifyImageSuccess,
    (state, { imageId, modelVersion, xrayType }) =>
      adapter.updateOne(
        {
          id: imageId,
          changes: { xrayType, classifierModelVersion: modelVersion },
        },
        state
      )
  ),
  on(ImageClassificationActions.classifyImageFailure, (state, { imageId }) =>
    adapter.updateOne(
      { id: imageId, changes: { xrayType: ClassificationBaseKind.Err } },
      state
    )
  ),
  // Delete Image
  on(ImageActions.deleteImageSuccess, (state, { imageId }) =>
    adapter.removeOne(imageId, state)
  ),

  on(ImageConfirmationActions.confirmImageSuccess, (state, { imageId }) =>
    adapter.updateOne(
      {
        id: imageId,
        changes: {
          status: FindingStatus.Confirmed,
        },
      },
      state
    )
  ),

  on(ImageConfirmationActions.unconfirmImageSuccess, (state, { imageId }) =>
    adapter.updateOne(
      {
        id: imageId,
        changes: {
          status: FindingStatus.Default,
        },
      },
      state
    )
  )
);

export function reducer(state: ImageState | undefined, action: Action) {
  return imagesReducer(state, action);
}

export const getFindingEntities = (state: ImageState) => state.entities;

export const getImage = (
  state: ImageState,
  props: { imageId: string }
): Image | null => state.entities[props.imageId] || null;

export const getFindingsForImage = (
  state: ImageState,
  props: { imageId: string }
): Finding[] | undefined => {
  const image = state.entities[props.imageId];

  if (isNullable(image)) {
    return undefined;
  }

  return image.findings;
};

export const getImageUrl = createSelector(getImage, (image) =>
  image ? image.url : undefined
);

export const getLoadingStatus = (state: ImageState) => state.isLoading;

export const getIsDeletingStatus = (
  state: ImageState,
  props: { imageId: string }
): boolean | undefined => {
  const image = state.entities[props.imageId];

  if (isNullable(image)) {
    return undefined;
  }

  return image.isBeingDeleted;
};

export const getIsDeletingFailed = (
  state: ImageState,
  props: { imageId: string }
): boolean | undefined => {
  const image = state.entities[props.imageId];

  if (isNullable(image)) {
    return undefined;
  }

  return image.isDeletedFailed;
};

export const getIsDeleted = (
  state: ImageState,
  props: { imageId: string }
): boolean | undefined => {
  const image = state.entities[props.imageId];

  if (isNullable(image)) {
    return undefined;
  }

  return image.isDeleted;
};

export const isImageConfirmed = createSelector(getImage, (image) => {
  if (isNullable(image)) return false;
  return ImageService.isImageConfirmed(image);
});

export const getTotalTreatmentCount = createSelector(getImage, (image) =>
  image && image.treatments ? image.treatments.length : 0
);

export const areImageFindingsLoaded = (
  state: ImageState,
  props: { imageId: string }
): boolean => {
  const entity = state.entities[props.imageId];

  if (isNullable(entity)) {
    return false;
  }

  return entity.loaded || false;
};

export const getClassifierVersionNumber = (state: ImageState) =>
  state.classifierVersion;

export const isPredictingImage = (
  state: ImageState,
  props: { imageId: string }
): boolean => {
  const image = state.entities[props.imageId];
  if (!image) return false;
  return image.isBeingPredicted ?? false;
};
