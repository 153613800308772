import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import {
  PatientRecommendation,
  PatientRecommendationDto,
  PatientRecommendationRiskLevels,
  PatientRecommendationUpdatePayload,
  RiskFactor,
  RiskFactorDto,
} from "../models/risk-evaluation.model";
import { Observable } from "rxjs";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";

interface GetRiskFactorsResponse {
  message: string;
  data: RiskFactorDto[];
}

interface GetRecommendationsResponse {
  message: string;
  data: PatientRecommendationDto[];
}

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class RiskEvaluationApiService {
  private apiUrl = (resource: string) =>
    `${this.baseUrl}/${this.apiVersion}/` + resource;

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient
  ) {}

  /**
   * Get a list of risk factors. Each risk factor contains its displayed name
   * and a url pointing to its icon.
   */
  getRiskFactors(): Observable<RiskFactor[]> {
    return this.http
      .get<GetRiskFactorsResponse>(this.apiUrl("risk_factors/"))
      .pipe(map((rs) => rs.data.map(RiskEvaluationApiService.toRiskFactor)));
  }

  /**
   * Get a list of the recommenations given to a patient.
   *
   * @param patientId id of the patient whose recommendation will be retrieved.
   */
  getPatientRecommendations(
    patientId: string
  ): Observable<PatientRecommendation[]> {
    return this.http
      .get<GetRecommendationsResponse>(
        this.apiUrl(`patients/${patientId}/recommendations/`)
      )
      .pipe(
        map((recommendations) =>
          recommendations.data.map(RiskEvaluationApiService.toRecommendation)
        )
      );
  }

  /**
   * Create a NEW recommendations.
   *
   * @param patientId id of the patient that will be updated.
   *
   * @param payload content of the patient's latest recommendation.
   *
   * @returns a string that is the ID of the updated recommendation.
   */
  saveLatestPatientRecommendation(
    patientId: string,
    payload: PatientRecommendationUpdatePayload
  ): Observable<string> {
    if (payload.id) {
      return this.updatePatientRecommendation(patientId, payload.id, payload);
    }

    return this.http.post<string>(
      this.apiUrl(`patients/${patientId}/recommendations/`),
      {
        risk_factors: payload.riskFactors,
        note: payload.note,
        risk_level: payload.riskLevel,
        session: payload.sessionId,
      }
    );
  }

  /* Update existing rec */

  /**
   * Update a apatient's recommendations.
   *
   * @param patientId id of the patient that will be updated.
   *
   * @param recId id of the patient that will be updated.
   *
   * @param payload content of the patient's latest recommendation.
   *
   * @returns a string that is the ID of the updated recommendation.
   */
  updatePatientRecommendation(
    patientId: string,
    recId: string,
    payload: PatientRecommendationUpdatePayload
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl(`patients/${patientId}/recommendations/${recId}`),
      {
        risk_factors:
          payload.riskFactors /*.map((r) => {
          return { _id: r }
        }),*/,
        note: payload.note,
        risk_level: payload.riskLevel,
        session: payload.sessionId,
      }
    );
  }

  private static toRiskFactor(riskFactorDto: RiskFactorDto): RiskFactor {
    return {
      id: riskFactorDto._id,
      iconUrl: riskFactorDto.icon_url,
      selectedIconUrl: riskFactorDto.selected_icon_url,
      displayedName: riskFactorDto.display_name,
    };
  }

  private static toRecommendation(
    recommendationDto: PatientRecommendationDto
  ): PatientRecommendation {
    return {
      id: recommendationDto._id,
      sessionId: recommendationDto.session,
      note: recommendationDto.note,
      createTs: new Date(recommendationDto.created_datetime),
      patientId: recommendationDto.patient,
      riskFactors: recommendationDto.risk_factors,
      riskLevel: RiskEvaluationApiService._toRecommendationRiskLevel(
        recommendationDto.risk_level
      ),
    };
  }

  /**
   * Given a number, maps to one of the [[`PatientRecommendationRiskLevels`]].
   *
   * @returns [[`PatientRecommendationRiskLevels.Undefined`]] if no predefined
   *   risk level matches, or one of the defined risk levels.
   */
  private static _toRecommendationRiskLevel(
    riskLevel: number
  ): PatientRecommendationRiskLevels {
    switch (riskLevel) {
      case PatientRecommendationRiskLevels.High:
        return PatientRecommendationRiskLevels.High;
      case PatientRecommendationRiskLevels.Medium:
        return PatientRecommendationRiskLevels.Medium;
      case PatientRecommendationRiskLevels.Low:
        return PatientRecommendationRiskLevels.Low;
      default:
        return PatientRecommendationRiskLevels.Undefined;
    }
  }
}
