import { Injectable } from "@angular/core";
import {
  CariesLocationTypes,
  CariesStageTypes,
  FindingType,
  RenderableFindingBase,
} from "../models";
import { assertNever, capitalize, isNullable } from "@kells/utils/js";

export interface FindingTextArgs {
  /**
   * Whether to include the tooth number in the finding's textual representation.
   */
  includeToothNumber: boolean;
  /**
   * For caries findings, whether to display the full caries location or as
   * one, capitalized letter.
   *
   * For instance, if `true`, use `'M'` to represent a mesial caries.
   */
  useLocationInitial: boolean;
}

@Injectable({
  providedIn: "root",
})
export class FindingProcessingService {
  constructor() {}

  /**
   * Given a key and a value, convert them to a serialized key-value pair.
   * The returned format is one that all entries of a finding's 'labels' should
   * conform to.
   *
   * @param args the key and value to serialize.
   */
  static serializeSingleFindingLabelEntry(args: { key: string; val: string }) {
    const { key, val } = args;
    return `${key}--${val}`;
  }

  static serializeCariesFindingLabels(
    finding: Pick<
      RenderableFindingBase,
      "location" | "stage" | "tooth" | "bone_loss_attributes"
    >
  ) {
    const constructKeyValPair = (
      object: Pick<
        RenderableFindingBase,
        "location" | "stage" | "tooth" | "bone_loss_attributes"
      >,
      key: Exclude<
        keyof RenderableFindingBase,
        "box" | "material" | "status" | "type" | "bone_loss_attributes"
      >,
      opts?: { keyOverride?: string }
    ) => {
      const _key = opts?.keyOverride ?? key;
      return FindingProcessingService.serializeSingleFindingLabelEntry({
        key: _key,
        val: object[key],
      });
    };

    return [
      constructKeyValPair(finding, "location"),
      constructKeyValPair(finding, "stage"),
      constructKeyValPair(finding, "tooth", { keyOverride: "number" }),
    ];
  }

  /**
   * Given an array of strings conforming to type `{key}--{val}`
   * @returns val for a given key, if it exists; else null.
   */
  static extractLabel = (labels: string[] | undefined | null, key: string) => {
    if (!labels) return null;

    const originalKey = labels.find((l) => l.startsWith(key));

    if (originalKey === undefined) return null;
    return originalKey.split("--")[1] || null;
  };

  static resolveCariesStage(
    rawStage: string | null | undefined
  ): CariesStageTypes {
    if (isNullable(rawStage)) {
      return CariesStageTypes.Undefined;
    }

    switch (rawStage) {
      case "Initial":
      case "initial":
        return CariesStageTypes.Initial;
      case "Moderate":
      case "moderate":
        return CariesStageTypes.Moderate;
      case "Advanced":
      case "advanced":
        return CariesStageTypes.Advanced;
      default:
        return CariesStageTypes.Undefined;
    }
  }

  static resolveFindingType(
    rawFindingType: string | null | undefined
  ): FindingType {
    if (isNullable(rawFindingType)) {
      console.warn("finding 'type' cannot be undefined.");
      return "" as any;
    }

    switch (rawFindingType) {
      case FindingType.Caries:
      case "predicted":
      case "":
        return FindingType.Caries;
      case FindingType.Material:
        return FindingType.Material;
      case FindingType.Tooth:
        return FindingType.Tooth;
      case FindingType.Dummy:
        return FindingType.Dummy;
      case FindingType.BoneLoss:
        return FindingType.BoneLoss;
      case FindingType.Calculus:
        return FindingType.Calculus;
      case FindingType.Fracture:
        return FindingType.Fracture;
      case FindingType.Infection:
        return FindingType.Infection;
      case FindingType.DefectiveRestoration:
        return FindingType.DefectiveRestoration;
      case FindingType.Plaque:
        return FindingType.Plaque;
      case FindingType.GumRecession:
        return FindingType.GumRecession;
      case FindingType.GumInflammation:
        return FindingType.GumInflammation;
      case FindingType.MissingTooth:
        return FindingType.MissingTooth;
      default: {
        // do not perform `assertNever` here, as some legacy findings may have
        // unrecognized or invalid 'type' values.
        console.warn(`Unrecognized finding 'type': '${rawFindingType}'`);
        return rawFindingType as any;
      }
    }
  }

  static resolveCariesLocation(
    rawLocation: string | undefined | null
  ): CariesLocationTypes | undefined {
    const cariesLocations = new Set<string>(Object.values(CariesLocationTypes));

    if (!rawLocation || !cariesLocations.has(rawLocation)) {
      console.warn(`Unrecognized caries location type '${rawLocation}'`);
      return;
    }

    return rawLocation as CariesLocationTypes;
  }

  /**
   * Converts a Finding entity to a readable text.
   *
   * @param finding
   *    return the text form of this finding
   * @param includeToothNumber
   *    optionally configure whether the tooth number will be included in the
   *    finding's text output. Defaults to `true`.
   */
  static toFindingText = <F extends RenderableFindingBase>(
    finding: F,
    args: Partial<FindingTextArgs> = {
      includeToothNumber: true,
      useLocationInitial: false,
    }
  ): string => {
    const { includeToothNumber, useLocationInitial } = args;

    const findingText: (string | null | undefined)[] = [];

    if (finding.type === FindingType.Material) {
      const { material } = finding;

      if (!material) {
        return assertNever(
          "Expected finding of type 'material' to have a 'material' attribute, but none could be found."
        );
      }

      return material;
    }

    if (finding.type === FindingType.BoneLoss) {
      const { bone_loss_attributes } = finding;

      if (!bone_loss_attributes) {
        return assertNever(
          "Expected finding of type 'material' to have a 'material' attribute, but none could be found."
        );
      }

      return `Bone Loss severity ${bone_loss_attributes.severity} grade ${bone_loss_attributes.grade}`;
    }

    if (finding.type === FindingType.Tooth) {
      findingText.push(capitalize(finding.type));
    }

    if (includeToothNumber) {
      findingText.push(
        !isNullable(finding.tooth) && finding.tooth !== "0"
          ? `No. ${finding.tooth}`
          : `Unknown`
      );
    }

    const location =
      finding.type === FindingType.Caries && useLocationInitial
        ? finding.location[0].toUpperCase()
        : finding.location;

    findingText.push(location);
    findingText.push(finding.stage);

    if (
      finding.type !== FindingType.Tooth &&
      finding.type !== FindingType.Dummy
    ) {
      findingText.push(capitalize(finding.type));
    }

    return findingText.filter((s) => s).join(" ");
  };
}
