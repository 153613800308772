<div mat-dialog-content style="position: relative; padding-bottom: 56.25%;height: 0; overflow: hidden;">
  <p>{{data.name}}</p>
  <br>
  <div [hidden]="!(isLoading < 2)">
    <i class="fas fa-circle-notch fa-spin" style="font-size:36px; padding: 10px"></i> Loading...
  </div>
  <p [hidden]="isVideoAvailable" style="font-size: 14px;">Video for this treatment is presently not available.</p>

  <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" [src]="viewVideo()" (load)="onLoad()"
    title="KELLS Clinic One - video player" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Close</button>
  <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
</div>