import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as UserManagementActions from "./user-management.actions";
import { User } from "@kells/clinic-one/data-access/users";

export const featureKey = "userManagement";

export type UserManagementState = EntityState<User> & {
  isLoading: boolean;
  isLoaded: boolean;
  isSaving: boolean;
  error: any;
};

const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: UserManagementState = adapter.getInitialState({
  isLoading: false,
  isSaving: false,
  isLoaded: false,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(UserManagementActions.getUsers, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(UserManagementActions.getUsersSuccess, (state, { users }) =>
    adapter.upsertMany(users, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null,
    })
  ),
  on(UserManagementActions.getUsersFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(
    UserManagementActions.addUser,
    UserManagementActions.updateUser,
    (state) => ({
      ...state,
      isSaving: true,
    })
  ),
  on(
    UserManagementActions.addUserSuccess,
    UserManagementActions.updateUserSuccess,
    (state) => ({
      ...state,
      isSaving: false,
    })
  ),
  on(
    UserManagementActions.addUserFailure,
    UserManagementActions.updateUserFailure,
    (state) => ({
      ...state,
      isSaving: false,
    })
  )
);

export const { selectAll } = adapter.getSelectors();
