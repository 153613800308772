import { createFeatureSelector, createSelector } from "@ngrx/store";
import { OfficeState, officeFeatureKey } from "./office.reducer";
import {
  getLoggedInUserOfficeId,
  selectAuthState,
} from "@kells/clinic-one/auth";

export const selectOfficeState = createFeatureSelector<OfficeState>(
  officeFeatureKey
);

export const getLoggedInOffice = createSelector(
  selectAuthState,
  selectOfficeState,
  (authState, officeState) => {
    if (!authState.loggedInProfile?.userOfficeId) return;
    return officeState.entities[authState.loggedInProfile.userOfficeId];
  }
);

export const isB2COffice = createSelector(
  getLoggedInOffice,
  (office) => office?.isB2COffice ?? false
);

/**
 * Gets the imaging software configuration associated with the currently
 * logged in user.
 * @returns `undefined` if the imaging software has not been configured,
 *    or an `ImagingIntegration` instance describing the integration.
 */
export const getImagingSoftware = createSelector(
  selectOfficeState,
  getLoggedInUserOfficeId,
  (officeState, officeId) => {
    if (!officeId) {
      return;
    }
    return officeState.entities[officeId]?.integrations?.imaging;
  }
);

export const selectAllOffices = createSelector(
  selectOfficeState,
  (officeState) => {
    return officeState.offices;
  }
);
