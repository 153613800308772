import {
  FindingFilterSelectionTypes,
  FindingStatus,
  FindingType,
  PerioData,
} from "@kells/interfaces/finding";
import {
  CanvasFindingService,
  CanvasLibFinding,
  KellsFabric,
  FindingInteractionStates,
  ZoomEvent,
  LineCoordsGeometry,
} from "@kells/shared-ui/canvas";
import { isDefined, isNullable } from "@kells/utils/js";
import { fabric } from "fabric";
import { ICircleOptions, ILineOptions, IRectOptions } from "fabric/fabric-impl";
import BoneLossStateStyles from "./bone-loss-state-styles";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { SubSink } from "subsink";
import Flatten from "@flatten-js/core";
import { CanvasContext } from "../canvas/canvas-context.interface";
import { tap } from "rxjs/operators";
import { NavigationStart } from "@angular/router";
import { CanvasResizeService } from "../../services/canvas-resize/canvas-resize.service";
const { Point, Segment } = Flatten;

export const PI = Math.PI;
export const PI_2 = Math.PI * 2;
export const TAU = 6.28318530717958647693;
export const QUARTER_PI = 0.7853982;
export const HALF_PI = 1.57079632679489661923;
export const QTHREE_PI = QUARTER_PI * 3;
export const MM_PER_PIXEL = 0.26458333; //monitor-based
export const DEG_TO_RAD = Math.PI / 180;
export const RAD_TO_DEG = 180 / Math.PI;

class BoneLossObject {
  private _line: KellsFabric.Line;

  private _acCircle: KellsFabric.Circle;

  private _cejSquare: KellsFabric.Rect;

  // private _finding: CanvasLibFinding | null = null;
  // private _canvas: KellsFabric.Canvas;

  private _isDragging = false;

  private _imageOriginalHeight = 0;
  private _imageOriginalWidth = 0;

  private _group: KellsFabric.Group;
  imageConfirmed: boolean;
  get object(): KellsFabric.Object {
    return this._group as KellsFabric.Object;
  }

  get MM_PER_PIXEL(): number {
    return this.ctx.filmScaleFactorWidth$.value;
  }
  get id(): string {
    return this._finding.id as string;
  }

  get activeObject() {
    return this._activeObject ?? null;
  }
  _activeObject: KellsFabric.Object;

  private _hasFocus = false;
  get hasFocus() {
    return this._hasFocus;
  }

  private _isConfirmed = false;
  set confirmed(val: boolean) {
    this._isConfirmed = val;
    this._styles.isConfirmed = val;
  }
  get confirmed(): boolean {
    return this._isConfirmed;
  }

  private _interactiveState_1: FindingInteractionStates =
    FindingInteractionStates.Up;

  public interactiveState$: BehaviorSubject<FindingInteractionStates> = new BehaviorSubject<FindingInteractionStates>(
    FindingInteractionStates.Up
  );
  public get interactiveState(): FindingInteractionStates {
    return this._interactiveState_1;
  }
  public set interactiveState(value: FindingInteractionStates) {
    this.interactiveState$.next(value);
    this._interactiveState_1 = value;
  }

  private _isInteractive = true;
  set interactive(val: boolean) {
    this._isInteractive = val;
  }
  get interactive(): boolean {
    return this._isInteractive;
  }

  get finding(): CanvasLibFinding {
    return this._finding;
  }

  isUpper: boolean = false;
  quadrant: number = 0;

  static BASE_FABRIC_PROPS = {
    strokeUniform: true,
    hasRotatingPoint: false, // hide the rotating control above a fabric.Rect
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,
    originX: "center",
    originY: "center",
    strokeLineCap: "square",
    hoverCursor: "crosshair",
  };

  private _canvas?: KellsFabric.Canvas | undefined;
  protected get canvas(): KellsFabric.Canvas {
    return this._canvas!;
  }
  protected set canvas(value: KellsFabric.Canvas) {
    this._canvas = value;
  }

  protected get ctx(): CanvasContext {
    return this._ctx!;
  }
  protected set ctx(value: CanvasContext) {
    this._ctx = value;
  }
  private _styles: BoneLossStateStyles;

  finding$: BehaviorSubject<CanvasLibFinding>;

  constructor(
    private _finding: CanvasLibFinding,
    private _ctx?: CanvasContext | undefined,
    private canvasResizeService?: CanvasResizeService | undefined
  ) {
    this._canvas = _ctx!.canvas;
    this.finding$ = new BehaviorSubject<CanvasLibFinding>(_finding);
    this._ordinality = -1;
    this._styles = new BoneLossStateStyles(
      _finding.status === FindingStatus.Confirmed
    );
    this._styles.baseLineColor = CanvasFindingService.resolveLabelBoxColor(
      _finding
    );
    this.mouseover = this.mouseover.bind(this);
    this.mouseout = this.mouseout.bind(this);
    this.mousedown = this.mousedown.bind(this);
    this.mouseup = this.mouseup.bind(this);
    this.objectMoving = this.objectMoving.bind(this);
    //this.disposeOnMouseup = this.disposeOnMouseup.bind(this);
    this.selectionCreated = this.selectionCreated.bind(this);
    this.selectionCleared = this.selectionCleared.bind(this);
    this.selectionUpdated = this.selectionUpdated.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.objectMoved = this.objectMoved.bind(this);
    this._styles.setAllUp();
    this.initFabricObject();

    // setTimeout(() => {
    //   this.updateCoords();
    // }, 100);
    this.updateCoords();
    if (this.finding.id !== "NEW") {
      this.createLabel();
      setTimeout(() => {
        this.setPosition(this.labelElement);
      });
    }

    this.initEvents();
  }

  initEvents() {
    const { canvas, _acCircle, _cejSquare, _line, ctx } = this;
    canvas.on("selection:created", this.selectionCreated);
    canvas.on("selection:cleared", this.selectionCleared);
    canvas.on("selection:updated", this.selectionUpdated);
    _acCircle.on("mouseover", this.mouseover);
    _cejSquare.on("mouseover", this.mouseover);

    this._isHidden = false;
    this.makeHidden();
    _line.set({ visible: false });
    this._line.set({ visible: false });

    this._subs.sink = ctx.findingFilterKeys$
      .pipe(
        tap((f: FindingFilterSelectionTypes[]) => {
          if (f.includes(FindingType.BoneLoss)) {
            this.makeVisible();
          } else {
            this.makeHidden();
          }
        })
      )
      .subscribe();

    this._subs.sink = this._ctx!.navStart$.subscribe(
      (event: NavigationStart) => {
        this.blur();
        this.dispose();
      }
    );

    this.registerZoom(this._ctx!.zoomEvent$);
    this.registerToggleLabels(this._ctx!.showFindingLabels$);
    this.registerToggleFindingVisibility(
      this._ctx!.showFindings$,
      this._ctx!.findingFilterKeys$
    );
    this.canvasResizeService?.sourceImageDimensions$.subscribe(
      ({ height, width }) => {
        this._imageOriginalHeight = height;
        this._imageOriginalWidth = width;
      }
    );
  }

  dispose(): void {
    this._subs.unsubscribe();

    if (!isNullable(this.canvas)) {
      this.canvas.off("selection:created", this.selectionCreated);
      this.canvas.off("selection:cleared", this.selectionCleared);
      this.canvas.off("selection:updated", this.selectionUpdated);
      this.canvas.off("mouse:up:before", this.objectMoved);
      this.canvas.off("object:moved", this.objectMoved);
      this.canvas.off("object:scaled", this.objectMoved);
      this._activeObject = this.canvas.getActiveObject() as KellsFabric.Object;
    }

    document.removeEventListener("keydown", this.onKeyDown);
    if (!isNullable(this.labelElement)) {
      document.body.removeChild(this.labelElement);
      delete this._labelElement;
    }
    this._acCircle.off("mouseover", this.mouseover);
    this._cejSquare.off("mouseover", this.mouseover);

    if (!isNullable(this._activeObject)) {
      this._activeObject?.off("moving", this.objectMoving);
      this._activeObject?.off("scaling", this.objectMoving);
    }

    this.remove();
    this.clearRuler();

    delete this._canvas;
    delete this._ctx;
  }

  selectionCreated(event: any) {
    if (event.selected.length > 0) {
      const target = event.selected[0];
      if (target.ctrl.id === this.id) {
        console.info("BONELOSS selectionCreated", event);
        this._setActiveSubtarget(target);
      } else {
        if (this.interactiveState !== FindingInteractionStates.Up) {
          this.blur();
        }
        this.makeDeemphasized();
      }
    }
  }

  selectionCleared(event: any) {
    if (isDefined(event?.deselected)) {
      const untarget = event?.deselected[0];
      if (!isNullable(untarget) && untarget.ctrl.id === this.id) {
        console.info("BONELOSS selectionCleared", event);
        this.blur();
      }
    }
    this.unmakeDeemphasized();
  }

  selectionUpdated(event: any) {
    const blurTarget = event.deselected[0];
    const focusTarget = event.selected[0];
    if (focusTarget.ctrl.id === this.id) {
      console.info("BONELOSS selectionUpdated", event);
      this._setActiveSubtarget(focusTarget);
    } else {
      if (blurTarget.ctrl.id === this.id) {
        console.info("BONELOSS selectionUpdated", event);
        this.blur();
      }
      this.makeDeemphasized();
    }
  }

  private _focusedPoint: fabric.Point | null;
  private _cejPoint: fabric.Point;
  private _acPoint: fabric.Point;
  private _labelAnchor: fabric.Point;

  private _components: Map<string, KellsFabric.Object> = new Map();

  get perioData(): PerioData {
    return this._finding.bone_loss_attributes as PerioData;
  }
  get linePointsFromFinding(): LineCoordsGeometry {
    const { cej_x: x1, cej_y: y1, ac_x: x2, ac_y: y2 } = this.perioData;
    return { x1, y1, x2, y2 };
  }
  get lineCoordsFromFinding(): number[] {
    const { cej_x: x1, cej_y: y1, ac_x: x2, ac_y: y2 } = this.perioData;
    const xA: number = Math.min(x1, x2);
    const xB: number = Math.max(x1, x2);
    const yA: number = Math.min(y1, y2);
    const yB: number = Math.max(y1, y2);

    return [xA, yA, xB, yB];
  }
  get lineProps(): Partial<ILineOptions> {
    const { _lineState: style } = this._styles as BoneLossStateStyles;
    return {
      stroke: style.strokeColor,
      strokeWidth: style.strokeWidth,
      strokeDashArray: this.confirmed ? undefined : style.strokeDashArray,
      perPixelTargetFind: true,
      hasControls: false,
      hasBorders: false,
      evented: false,
      ...this.linePointsFromFinding,
    };
  }

  get acProps(): Partial<ICircleOptions> {
    const { _acState: style } = this._styles as BoneLossStateStyles;
    const { ac_x, ac_y } = this.finding.bone_loss_attributes as PerioData;
    this._subs.sink = combineLatest([this.ctx!.isImageConfirmed$]).subscribe(
      ([isImageConfirmed]) => {
        this.imageConfirmed = isImageConfirmed;
      }
    );
    return {
      stroke: style.strokeColor,
      strokeWidth: style.strokeWidth,
      strokeDashArray: this.confirmed ? undefined : style.strokeDashArray,
      perPixelTargetFind: false,
      hasControls: false,
      hasBorders: false,
      radius: 5,
      fill: style.fillColor,
      left: ac_x,
      top: ac_y,
      selectable: this.imageConfirmed ? false : true, // when the image is confirmed should not able to do edit or move the finding
      objectCaching: false,
      ...BoneLossObject.BASE_FABRIC_PROPS,
    };
  }

  get cejProps(): Partial<IRectOptions> {
    const { _cejState: style } = this._styles as BoneLossStateStyles;
    const { cej_x, cej_y } = this.finding.bone_loss_attributes as PerioData;
    return {
      stroke: style.strokeColor,
      strokeWidth: style.strokeWidth,
      strokeDashArray: this.confirmed ? undefined : style.strokeDashArray,
      perPixelTargetFind: false,
      hasControls: false,
      hasBorders: false,
      width: 10,
      height: 10,
      fill: style.fillColor,
      left: cej_x,
      top: cej_y,
      selectable: this.imageConfirmed ? false : true, // when the image is confirmed should not able to do edit or move the finding
      objectCaching: false,
      ...BoneLossObject.BASE_FABRIC_PROPS,
    };
  }

  initFabricObject() {
    if (!this._finding) return;
    const { finding } = this;
    const {
      cej_x: x1,
      cej_y: y1,
      ac_x: x2,
      ac_y: y2,
    } = finding.bone_loss_attributes as PerioData;

    // const [x1, y1, x2, y2] = finding.box;
    this._acPoint = new fabric.Point(x2, y2);
    this._cejPoint = new fabric.Point(x1, y1);
    this._line = new fabric.Line([x1, y1, x2, y2], {
      ...this.lineProps,
    }) as KellsFabric.Line;

    this._acCircle = new fabric.Circle({
      ...this.acProps,
    }) as KellsFabric.Circle;
    this._acCircle.setPositionByOrigin(this._acPoint, "center", "center");
    this._acCircle.setCoords();

    this._cejSquare = new fabric.Rect({
      ...this.cejProps,
    }) as KellsFabric.Rect;
    this._cejSquare.setPositionByOrigin(this._cejPoint, "center", "center");
    this._cejSquare.setCoords();

    // this._cejSquare.id = `cej_${this.id}`;
    this._cejSquare.finding = finding;
    this._cejSquare.ctrl = this;
    this._cejSquare.line = this._line;
    this._cejSquare.ac = this._acCircle;
    this._cejSquare.role = "cej";
    this._components.set("cej", this._cejSquare);

    // this._acCircle.id = `ac_${this.id}`;
    this._acCircle.finding = finding;
    this._acCircle.ctrl = this;
    this._acCircle.line = this._line;
    this._acCircle.cej = this._cejSquare;
    this._acCircle.role = "ac";
    this._components.set("ac", this._acCircle);

    // this._line.id = 'ac_' + this.id;
    this._line.finding = finding;
    this._line.ctrl = this;
    this._line.line = this._line;
    this._line.ac = this._acCircle;
    this._line.cej = this._cejSquare;
    this._line.role = "line";
    this._components.set("line", this._line);

    this.canvas.add(this._line);
    this.canvas.add(this._acCircle);
    this.canvas.add(this._cejSquare);
    if (this.finding.id === "NEW") {
      //const pointer = this.canvas.getPointer();
      const activeObj: any = this._acCircle;
      this.canvas.renderAll();
      this.mousedown(activeObj);
      activeObj.selectable = true;
      activeObj.setCoords();
      this.canvas.setActiveObject(activeObj);
      this.canvas.on("mouse:up", this.onCreated);
      this.canvas.on("mouse:move", this.drawMouseMoveHandler);
    }
  }

  protected drawMouseMoveHandler = (option: any) => {
    if (!this.canvas) return;

    const initialZoomLevel = this.canvas.getZoom();
    if (
      !isDefined(this._focusedPoint) ||
      isNullable(this._activeObject) ||
      isNullable(this._activeObject.left) ||
      isNullable(this._activeObject.top)
    ) {
      console.warn(
        `drawing box is invalid while drawing: ${JSON.stringify(
          this._activeObject
        )}`
      );
      return;
    }

    const pointer = this.canvas.getPointer(option.e);
    const xCoor = pointer.x;
    const yCoor = pointer.y;

    const isMouseWithinCanvas =
      xCoor > 0 &&
      yCoor > 0 &&
      xCoor * initialZoomLevel < this.canvas.getWidth() &&
      yCoor * initialZoomLevel < this.canvas.getHeight();

    if (isMouseWithinCanvas) {
      this._focusedPoint!.x = xCoor;
      this._focusedPoint!.y = yCoor;

      this._activeObject.setPositionByOrigin(
        this._focusedPoint!,
        "center",
        "center"
      );
      this._activeObject.setCoords();
      this.updateCoords();
      //} && isDraggingToBottomRight) {
      //   this.object.set({
      //     width: Math.abs(xCoor - this.object.left),
      //     height: Math.abs(yCoor - this.object.top),
      //   });
    }
    this.canvas.requestRenderAll();
  };

  onCreated = () => {
    if (!this.canvas) return;
    this.canvas.off("mouse:move", this.drawMouseMoveHandler);
    this.canvas.off("mouseup", this.onCreated);
    this.createLabel();
    if (!isNullable(this.labelElement)) {
      this.setPosition(this.labelElement);
    }
  };

  redrawLine() {
    const {
      _acCircle: ac,
      _cejSquare: cej,
      _line: line,
      _acPoint,
      _cejPoint,
      _finding: finding,
      canvas,
    } = this;
    const {
      cej_x: x1,
      cej_y: y1,
      ac_x: x2,
      ac_y: y2,
    } = finding.bone_loss_attributes as PerioData;
    if (!canvas) return;

    canvas.remove(line);

    this._line = new fabric.Line().set({
      ...this.lineProps,
    }) as KellsFabric.Line;
    this._line.role = "line";
    this._components.set("line", this._line);
    canvas.add(this._line).sendToBack(this._line);
  }

  get midPoint(): fabric.Point {
    return this._acPoint.midPointFrom(this._cejPoint);
  }

  makeDeemphasized() {
    if (isDefined(this.labelElement)) {
      this.setLabelVisibility(false);
    }
    this.interactiveState = FindingInteractionStates.Deemphasized;

    this._styles.setAllDeemphasized();
    this.update();
  }

  unmakeDeemphasized() {
    this.setLabelVisibility(this._showLabelByDefault);
    this.interactiveState = FindingInteractionStates.Up;
    this._styles.setAllUp();
    this.update();
  }

  calcLinePoints(): { x1: number; x2: number; y1: number; y2: number } {
    const pCoords = this.linePointsFromFinding;
    const lWidth = Math.abs(pCoords.x1 - pCoords.x2);
    const lHeight = Math.abs(pCoords.y1 - pCoords.y2);
    const xMult = pCoords.x1 <= pCoords.x2 ? -1 : 1;
    const yMult = pCoords.y1 <= pCoords.y2 ? -1 : 1;
    const x1 = xMult * lWidth * 0.5;
    const y1 = yMult * lHeight * 0.5;
    const x2 = xMult * lWidth * -0.5;
    const y2 = yMult * lHeight * -0.5;

    return { x1, x2, y1, y2 };
  }

  get calcLineCoords(): number[] {
    const { x1, x2, y1, y2 } = this.calcLinePoints();
    return [x1, y1, x2, y2];
  }

  calcPathPoints() {
    const { x1, x2, y1, y2 } = this.calcLinePoints();
    return [new fabric.Point(x1, y1), new fabric.Point(x2, y2)];
  }

  private tanL: KellsFabric.Line;
  private mLine: KellsFabric.Line;

  rulerLineProps: Partial<ILineOptions> = {
    selectable: false,
    evented: false,
    hasControls: false,
    strokeDashArray: [1, 2, 3, 5, 3, 2],
    strokeLineCap: "square",
    strokeWidth: 2,
    strokeUniform: true,
    stroke: "#7c00ff",
  };

  rectCoordsWithPadding(padding: number): { [key: string]: number } {
    const coords = this.lineCoordsFromFinding as number[];
    const left = coords[0] - padding;
    const top = coords[1] - padding;
    const width = Math.abs(coords[2] + padding - left);
    const height = Math.abs(coords[3] + padding - top);
    return { left, top, width, height };
  }

  _boundsRect: KellsFabric.Rect;
  getEditFormPoint(padding = 40) {
    const coords = this.rectCoordsWithPadding(padding);

    const boundsRect = new fabric.Rect({
      stroke: this.showDebugLines ? "#837277" : "transparent",
      fill: "transparent",
      hasControls: false,
      selectable: false,
      evented: false,
      ...coords,
    }) as KellsFabric.Rect;
    boundsRect.id = "bR_" + this.id;
    this.canvas.add(boundsRect);
    this._boundsRect = boundsRect;
    this.ruleLines.set("boundsRect", this._boundsRect);
    return boundsRect;
  }

  showDebugLines = false;
  /**
   * Returns a set of points representing the line rotated
   * 90 degs around midpoint
   */
  _ctrlPoints: number = 0;
  renderControlPoint = (
    { x, y }: { x: number; y: number },
    color: string = "#c026d3"
  ): void => {
    const sq2: KellsFabric.Rect = new fabric.Rect({
      fill: color,
      left: x,
      top: y,
      width: 6,
      height: 6,
      evented: false,
      originX: "center",
      originY: "center",
      objectCaching: false,
      angle: this.angleDegs,
    }) as KellsFabric.Rect;
    sq2.id = "ctr" + this._ctrlPoints++;
    this.canvas.add(sq2);
    this.ruleLines.set(sq2.id, sq2);
  };

  get midPointTan(): any {
    const {
      _line: line,
      showDebugLines,
      isLeftHanded,
      renderControlPoint,
    } = this;
    // const mP = this.midPoint;
    this.clearRuler();
    const { x1, y1, x2, y2 } = this.linePointsFromFinding as LineCoordsGeometry;
    const startPoint = new Point(x1, y1);
    renderControlPoint(startPoint, "#10b981");
    const endPoint = new Point(x2, y2);
    renderControlPoint(endPoint, "#10b981");
    this.clearRuler();
    const seg = new Segment(new Point(x1, y1), new Point(x2, y2));
    const mP = seg.middle();
    //renderControlPoint(mP);
    const tanVUnit = seg.tangentInStart();
    let tanV40 = tanVUnit.multiply(15);
    switch (isLeftHanded) {
      case true:
        if (y2 >= y1) {
          tanV40 = tanV40.rotate90CCW();
        } else {
          tanV40 = tanV40.rotate90CW();
        }
        break;
      case false:
        if (y2 >= y1) {
          tanV40 = tanV40.rotate90CW();
        } else {
          tanV40 = tanV40.rotate90CCW();
        }
        break;
    }
    const anchor = new Point(mP.x, mP.y).translate(tanV40);
    // renderControlPoint(anchor);
    const seg2 = new Segment(mP, anchor);

    const mLine = new fabric.Line(
      [seg2.start.x, seg2.start.y, anchor.x, anchor.y],
      {
        ...this.rulerLineProps,
        hasRotatingPoint: true,
        centeredRotation: true,
        stroke: "#bd95a3",
        //stroke: showDebugLines ?'#bd95a3' : 'transparent'
      }
    ) as KellsFabric.Line;
    mLine.setCoords();

    if (this.showDebugLines) {
      this.canvas.add(mLine);
      this.ruleLines.set("mLine", mLine);
    }

    this.getEditFormPoint();

    return seg2.end;
  }

  clearRuler() {
    this.ruleLines.forEach((line: KellsFabric.Object, key: string) => {
      if (line.canvas) {
        this.canvas.remove(line);
      }
    });
  }

  labelPosition$: BehaviorSubject<fabric.Point> = new BehaviorSubject<fabric.Point>(
    new fabric.Point(0, 0)
  );

  ruleLines: Map<string, KellsFabric.Object> = new Map();
  rulePoints: Map<string, fabric.Point> = new Map();
  ruleMinorDash: number[] = [1, 2, 3, 5, 3, 2];

  getAbsolutePosition(point: fabric.Point): fabric.Point {
    //const m:number[] = obj.calcTransformMatrix() as number[]; , obj:KellsFabric.Object
    const newP: fabric.Point = fabric.util.transformPoint(
      point,
      this._line.getViewportTransform()
    );

    return newP;
  }

  drawRuler(
    startPoint: fabric.Point,
    endPoint: fabric.Point,
    rFactor: number,
    index: number
  ) {
    const lP = startPoint.lerp(endPoint, rFactor);

    const rL = new fabric.Line(this.lineCoordsFromFinding, {
      ...this.rulerLineProps,
      strokeWidth: 1,
      stroke: "#7c00ff",
    }) as KellsFabric.Line;
    //tanL.translateToOriginPoint(this._line.getCenterPoint(), 'center', 'center');
    rL.set({
      angle: 90,
    });
    rL.setPositionByOrigin(lP, "center", "center");
    rL.setCoords();
    // rL.setPositionByOrigin(this._line.getCenterPoint(), 'center', 'center');
    this.ruleLines.set("r" + index, rL);
    this.canvas.add(rL);
  }

  get labelAnchor(): fabric.Point {
    const p = this.getAbsolutePosition(this.midPointTan);
    this.labelPosition$.next(p);
    return p; //new fabric.Point(p1.x, p2.y);//this._acPoint.midPointFrom(this._cejPoint);
  }

  updateFinding(finding: CanvasLibFinding, render = false) {
    const wasConfirmed = this._isConfirmed;
    this._styles = new BoneLossStateStyles(
      finding.status === FindingStatus.Confirmed
    );
    this._styles.baseLineColor = CanvasFindingService.resolveLabelBoxColor(
      finding
    );
    this._styles.setAllUp();
    if (finding && finding.status === FindingStatus.Confirmed) {
      this._isConfirmed = true;
      this._styles.isConfirmed = true;
    } else {
      this._isConfirmed = false;
      this._styles.isConfirmed = false;
    }
    this._finding = finding;
    if (wasConfirmed !== this._isConfirmed) {
      render = true;
    }
    if (render) {
      this.update();
    }
  }

  update(): void {
    const { _cejState, _acState, _lineState } = this._styles;

    if (this._line) {
      this._line
        .set({
          stroke: _lineState.strokeColor,
          strokeWidth: _lineState.strokeWidth,
          strokeDashArray: _lineState.strokeDashArray,
        })
        .setCoords();
    }

    if (this._cejSquare) {
      this._cejSquare.set({
        fill: _cejState.fillColor,
        stroke: _cejState.strokeColor,
        strokeWidth: _cejState.strokeWidth,
      });
      this._cejSquare.setCoords();
    }

    if (this._acCircle) {
      this._acCircle.set({
        // left: x1,
        // top:y1,
        fill: _acState.fillColor,
        stroke: _acState.strokeColor,
        strokeWidth: _acState.strokeWidth,
      });
      this._acCircle.setCoords();
    }
  }

  measurementvalue: any;

  public toothNumber: string;

  updateCoords() {
    const {
      _acCircle: ac,
      _cejSquare: cej,
      _line: line,
      _acPoint,
      _cejPoint,
      _finding: finding,
      _canvas: canvas,
    } = this;

    const acMatrix = ac.calcTransformMatrix();
    const acTransProps = fabric.util.qrDecompose(acMatrix);
    ac.setPositionByOrigin(_acPoint, "center", "center");
    ac.set({ ...acTransProps }).setCoords();

    const cejMatrix = cej.calcTransformMatrix();
    const cejTransProps = fabric.util.qrDecompose(cejMatrix);
    cej.set({ ...cejTransProps, angle: this.angleDegs }).setCoords();

    // const lineMatrix = cej.calcTransformMatrix();
    // const lineTransProps = fabric.util.qrDecompose(lineMatrix)
    //

    this._acPoint.x = ac.get("left") as number;
    this._acPoint.y = ac.get("top") as number;

    this._cejPoint.x = cej.get("left") as number;
    this._cejPoint.y = cej.get("top") as number;

    this._finding.bone_loss_attributes = {
      ...this._finding.bone_loss_attributes,
      cej_x: this._cejPoint.x,
      cej_y: this._cejPoint.y,
      ac_x: this._acPoint.x,
      ac_y: this._acPoint.y,
    };
    this.redrawLine();
    const longerImageDimension = Math.max(
      this._imageOriginalHeight,
      this._imageOriginalWidth
    );
    const pixel_val = 32.9 / (!longerImageDimension ? 1 : longerImageDimension);
    if (isDefined(this.labelElement)) {
      requestAnimationFrame(() => {
        if (!isNullable(finding.bone_loss_attributes)) {
          finding.bone_loss_attributes.measurement_pixel = +Number(
            Math.sqrt(
              Math.hypot(
                finding?.bone_loss_attributes?.ac_x -
                  finding?.bone_loss_attributes?.cej_x,
                finding?.bone_loss_attributes.ac_y -
                  finding?.bone_loss_attributes.cej_y
              )
            )
          ).toFixed(2);
          const measurement = Number(
            Math.hypot(
              (finding?.bone_loss_attributes?.ac_x -
                finding?.bone_loss_attributes?.cej_x) *
                pixel_val,
              (finding?.bone_loss_attributes.ac_y -
                finding?.bone_loss_attributes.cej_y) *
                pixel_val
            )
          ).toFixed(2);
          this.measurementvalue = measurement;
          this.toothNumber = finding.tooth || "0";
          this.labelElement.innerHTML = this.labelInnerHTML;
          finding.bone_loss_attributes.measurement_mm = measurement;
        }
      });
    }

    // this._cejSquare.set({
    //   left: this._cejPoint.x,
    //   top: this._cejPoint.y,
    // }).setCoords();
  }

  private _setActiveSubtarget(target: KellsFabric.Object): void {
    this._hasFocus = true;
    this.interactiveState = FindingInteractionStates.Focus;
    this._components.forEach((obj: KellsFabric.Object, key: string) => {
      obj.set({
        opacity: 1,
      });
    });
    if (target.type === "rect") {
      this._focusedPoint = this._cejPoint;
    } else if (target.type === "circle") {
      this._focusedPoint = this._acPoint;
    }
    this._styles.setActive(target.type as string);
    this.update();
    this.canvas.requestRenderAll();
    this._activeObject = target; // this.canvas.getActiveObject() as KellsFabric.Object;
    this._activeObject.on("moving", this.objectMoving);
    this.canvas.on("object:moved", this.objectMoved);
    // document.addEventListener('keydown', this.onKeyDown);
  }

  private _clearActiveSubtarget() {
    this._hasFocus = false;
    if (!isNullable(this.canvas.getActiveObject())) {
      const activeObj: KellsFabric.Object = this.canvas.getActiveObject() as KellsFabric.Object;
      if (activeObj.ctrl.id === this.id) {
        this.canvas.discardActiveObject();
      }
    }
    this._focusedPoint = null;
    this._activeObject?.off("moving", this.objectMoving);
    this._styles._setAll();
    this.update();
    this.canvas.requestRenderAll();
  }

  objectMoved(event: any) {
    this._isDragging = false;
    this.updateCoords();
    if (event?.target?.ctrl?.id === this.id) {
      //this._rebuildLine();
      this._styles.setAllUp();
      if (this._activeObject) {
        this._styles.setFocusHover(this._activeObject.type as string);
      }
      this.update();
      if (this.canvas) this.canvas.requestRenderAll();
    }
  }

  onKeyDown(event: Event) {
    const keyEvent = event as KeyboardEvent;

    const { key, code } = keyEvent;
    if (!isNullable(this._focusedPoint)) {
      switch (key) {
        case "ArrowRight":
          this._focusedPoint.x += 1;
          event.stopPropagation();
          keyEvent.preventDefault();
          break;
        case "ArrowLeft":
          this._focusedPoint.x -= 1;
          event.stopPropagation();
          keyEvent.preventDefault();
          break;

        case "ArrowUp":
          this._focusedPoint.y -= 1;
          event.stopPropagation();
          keyEvent.preventDefault();
          break;

        case "ArrowDown":
          this._focusedPoint.y += 1;
          event.stopPropagation();
          keyEvent.preventDefault();
          break;
      }
      if (code === "27") {
        this.blur();
        event.stopPropagation();
        keyEvent.preventDefault();
      }
      this.clearRuler();
      this.canvas.requestRenderAll();
    }
  }

  mouseover(event: any): void {
    const { target } = event;
    if (event.e.type === "mousemove") {
      this.labelElement.innerHTML = event.target.role;
      // this.showLabel();
    }
    console.info("BONELOSS mouse:over ", event);
    this.canvas.on("mouse:out", this.mouseout);
    switch (this.interactiveState) {
      case FindingInteractionStates.Up:
        this.interactiveState = FindingInteractionStates.Hover;
        this._styles.setHover(target.type as string);
        break;
      case FindingInteractionStates.Focus:
        this.interactiveState = FindingInteractionStates.FocusHover;
        this._styles.setFocusHover(target.type as string);
        break;
      case FindingInteractionStates.Active:
        this.interactiveState = FindingInteractionStates.ActiveHover;
        this._styles.setActiveHover(target.type as string);
        break;
    }

    //target.selectable = true;
    this.update();
    this.canvas.requestRenderAll();
  }

  mouseout(event: any): void {
    if (this.labelInnerHTML != undefined) {
      this.labelElement.innerHTML = this.labelInnerHTML;
    }
    if (this._showLabelByDefault === false) {
      this.hideLabel();
    }
    console.info("BONELOSS mouseout ", event);
    const target = event.target as KellsFabric.Object;
    if (
      !target ||
      target.ctrl === null ||
      target.ctrl.id !== this.id ||
      !this.canvas
    ) {
      return;
    }

    this.canvas.off("mouse:out", this.mouseout);
    switch (this.interactiveState) {
      case FindingInteractionStates.Hover:
        this.interactiveState = FindingInteractionStates.Up;
        this._styles._setAll();
        break;
      case FindingInteractionStates.FocusHover:
        this.interactiveState = FindingInteractionStates.Focus;
        this._styles._setAllFocus();
        break;
      case FindingInteractionStates.ActiveHover:
        this.interactiveState = FindingInteractionStates.Active;
        this._styles._setAllFocus();
        switch (target.type) {
          case "rect":
            this._styles._setCEJActive();
            break;
          case "circle":
            this._styles._setACActive();
            break;
        }
        break;
    }
    this.update();
    this.canvas.requestRenderAll();
  }

  _selectedObj: KellsFabric.Object;
  mousedown(target: KellsFabric.Object): void {
    console.info("BONELOSS mousedown ", target);
    this._setActiveSubtarget(target);
    this._selectedObj = target;
    this._line.id = this.id;
    this._line.data = { ...this._finding };
    this.canvas.bringToFront(this._line);
    this.canvas.bringToFront(this._activeObject);
    this._isDragging = true;
  }

  set cej_x(x: number) {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      this._finding.bone_loss_attributes.cej_x = x;
      this._cejPoint.setX(x);
    }
  }
  get cej_x(): number {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      return this._finding.bone_loss_attributes.cej_x;
    }
    return 0;
  }

  set cej_y(y: number) {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      this._finding.bone_loss_attributes.cej_y = y;
      this._cejPoint.setY(y);
    }
  }
  get cej_y(): number {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      return this._finding.bone_loss_attributes.cej_y;
    }
    return 0;
  }

  set ac_x(x: number) {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      this._finding.bone_loss_attributes.ac_x = x;
      this._acPoint.setX(x);
    }
  }
  get ac_x(): number {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      return this._finding.bone_loss_attributes.ac_x;
    }
    return 0;
  }

  set ac_y(y: number) {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      this._finding.bone_loss_attributes.ac_y = y;
      this._acPoint.setY(y);
    }
  }
  get ac_y(): number {
    if (!isNullable(this._finding.bone_loss_attributes)) {
      return this._finding.bone_loss_attributes.ac_y;
    }
    return 0;
  }

  objectMoving(event: any) {
    const { e, pointer, transform } = event;
    const { x, y } = event.pointer;
    //const dims = this._line._calcDimensions()
    const { _canvas: canvas, finding } = this;
    const target = this.canvas.getActiveObject() as KellsFabric.Object;
    //const { cej_x: x1, cej_y: y1, ac_x: x2, ac_y: y2 } = finding.bone_loss_attributes as PerioData;
    if (!isNullable(this._finding.bone_loss_attributes)) {
      if (target.role === "cej") {
        this.cej_y = y;
        this.cej_x = x;
      } else if (target.role === "ac") {
        this.ac_y = y;
        this.ac_x = x;
      }
    }
    this.updateCoords();
    if (isDefined(this.labelElement)) {
      this.setPosition(this.labelElement);
    }

    this.canvas.requestRenderAll();
    // this.canvas.requestRenderAll();
  }

  _debounceZoom: number | null;

  registerZoom(zoomEvent$: Observable<ZoomEvent>) {
    this._subs.sink = zoomEvent$.subscribe((event: any) => {
      if (this._debounceZoom) {
        window.cancelAnimationFrame(this._debounceZoom);
      }

      this._debounceZoom = window.requestAnimationFrame(() => {
        // this.updateCoords();
        // this.setPosition(this.labelElement);
        this._debounceZoom = null;
      });
    });
  }

  private _subs = new SubSink();
  _showLabelByDefault = false;
  registerToggleLabels(showFindingLabels$: Observable<boolean>) {
    this._subs.sink = showFindingLabels$.subscribe((value: boolean) => {
      this._showLabelByDefault = value;
      if (isDefined(this.labelElement)) {
        if (value === false) {
          if (this.interactiveState === FindingInteractionStates.Up) {
            this.labelElement.classList.add("hidden");
          }
        } else {
          this.labelElement.classList.remove("hidden");
          this.setPosition(this.labelElement);
        }
      }
    });
  }

  typeKey = "bone_loss";

  _isHidden = true;
  registerToggleFindingVisibility(
    isInsightEnabled$: Observable<boolean>,
    findingFilterKeys$: Observable<string[]>
  ) {
    this._subs.sink = combineLatest([
      findingFilterKeys$,
      isInsightEnabled$,
    ]).subscribe(([filterKeys, isEnabled]) => {
      this._acCircle.selectable = this.imageConfirmed ? false : true;
      this._cejSquare.selectable = this.imageConfirmed ? false : true;
      console.log(this.finding.type.toUpperCase() + " " + isEnabled);
      if (isEnabled && filterKeys.indexOf(this.typeKey) !== -1) {
        this.makeVisible();
        this.setLabelVisibility(this._showLabelByDefault);
      } else {
        this.makeHidden();
        this.setLabelVisibility(false);
      }
      this._canvas!.requestRenderAll();
    });

    // isInsightEnabled$.subscribe((value: boolean) => {
    //   console.log(this._finding.type.toUpperCase() + ' ' + value);
    //   if (value) {
    //     this.makeVisible();
    //   } else {
    //     this.makeHidden();
    //   }
    //   this.canvas.requestRenderAll();
    // });
  }

  makeVisible() {
    const { object, _isHidden, _acCircle, _cejSquare, _line } = this;
    if (!_isHidden) return;
    if (this._showLabelByDefault) {
      this.showLabel();
    }
    _acCircle.set({ visible: true });
    _cejSquare.set({ visible: true });
    _line.set({ visible: true });
    this.ruleLines.forEach((line: KellsFabric.Object, key: string) => {
      line.set({ visible: true });
    });

    setTimeout(() => {
      this.updateCoords();
    });
    this._isHidden = false;
  }

  makeHidden() {
    if (this._isHidden) return;
    const { object, _acCircle, _cejSquare, _line } = this;
    this.hideLabel();
    _acCircle.set({ visible: false });
    _cejSquare.set({ visible: false });
    _line.set({ visible: false });
    this.ruleLines.forEach((line: KellsFabric.Object, key: string) => {
      line.set({ visible: false });
    });
    this._isHidden = true;
  }

  mouseup(target: KellsFabric.Object): void {
    this.interactiveState = FindingInteractionStates.Focus;
    this._setActiveSubtarget(target);
  }

  focus(target: KellsFabric.Object) {
    // console.info('focus', this);
    this.interactiveState = FindingInteractionStates.Focus;
    this._setActiveSubtarget(target);
    // this._enableActiveControls();
  }

  blur() {
    console.info("blur", this);
    this.interactiveState = FindingInteractionStates.Up;
    if (!this._showLabelByDefault) {
      this.hideLabel();
    }
    this._clearActiveSubtarget();
  }

  hide() {
    this._acCircle.set({ visible: false });
    this._cejSquare.set({ visible: false });
    this._line.set({ visible: false });
    this.canvas.requestRenderAll();
  }

  show() {
    this._acCircle.set({ visible: true });
    this._cejSquare.set({ visible: true });
    this._line.set({ visible: true });
    this.canvas.requestRenderAll();
  }

  remove() {
    if (this._acCircle.canvas) {
      this.canvas.remove(this._acCircle as fabric.Object);
    }
    if (this._cejSquare.canvas) {
      this.canvas.remove(this._cejSquare as fabric.Object);
    }
    if (this._line.canvas) {
      this.canvas.remove(this._line as fabric.Object);
    }
  }

  _ordinality: number;
  set ordinality(val) {
    this._ordinality = val;
    this.updateLabelInnerHTML();
  }
  get ordinality() {
    return this._ordinality;
  }
  get isLeftHanded() {
    return this._ordinality % 2 === 1;
  }

  private _labelElement?: HTMLDivElement | undefined;
  public get labelElement(): HTMLDivElement {
    return this._labelElement!;
  }
  public set labelElement(value: HTMLDivElement) {
    this._labelElement = value;
  }
  labelWidth: number;
  labelHeight: number;
  canvasElementRef: HTMLDivElement;
  labelPixelMeasurment: HTMLSpanElement;

  createLabel() {
    if (isNullable(this.labelElement)) {
      const { id } = this;
      this.labelElement = fabric.util.makeElement("div", {
        id: `${id}_label`,
      }) as HTMLDivElement;

      this.labelElement.classList.add("kco-finding-label");
      this.labelElement.classList.add("finding-label-bone_loss");
      this.labelElement.classList.add(
        "finding-label-bone_loss_" +
          this._finding.bone_loss_attributes?.severity
      );
      //this.labelElement.classList.add('hidden');
      this.labelElement.innerHTML = this.labelInnerHTML;

      document.body.appendChild(this.labelElement);
      this.labelPixelMeasurment = document.getElementById(
        this.id + "-finding-pixels"
      ) as HTMLSpanElement;
      this.labelWidth = this.labelElement.clientWidth;
      this.labelHeight = this.labelElement.clientHeight;
      this.labelElement.classList.add("hidden");
    }
  }

  updateLabelInnerHTML() {
    if (this.isLeftHanded) {
      this.labelElement.style.transform = `translateX(-${
        this.labelWidth / 2
      }px)`;
      this.labelElement.classList.add("finding-label-rtl");
    } else {
      this.labelElement.classList.remove("finding-label-rtl");
    }
    this.labelElement.innerHTML = this.labelInnerHTML;
    this.labelPixelMeasurment = document.getElementById(
      this.id + "-finding-pixels"
    ) as HTMLSpanElement;
    this.labelWidth = this.labelElement.clientWidth;
    this.labelHeight = this.labelElement.clientHeight;
  }

  get labelInnerHTML() {
    const { id, _styles: styles, ordinality } = this;

    return (
      `<div class="label-inner" style="--icon-color:${styles.MAIN_PALETTE[900]}; --label-background-color:${styles.MAIN_PALETTE[800]};" >` +
      `<span class="title-label">
        <span id="${id}-finding-pixels">T${this.toothNumber} ${this.measurementvalue}</span></span>` +
      `</div>`
    );
  }

  hideLabel() {
    this.setLabelVisibility(false);
  }

  showLabel() {
    this.setLabelVisibility(true);
  }

  setLabelVisibility(visible: boolean) {
    if (isDefined(this.labelElement)) {
      if (visible) {
        this.labelElement.classList.remove("hidden", "invisible");
        this.labelElement.classList.add("inline-block");
      } else {
        this.labelElement!.classList.remove("inline-block");
        this.labelElement!.classList.add("hidden");
      }
    }
  }

  get angleRads(): number {
    const { ac_x, ac_y, cej_x, cej_y } = this;

    return Math.atan2(cej_y - ac_y, cej_x - ac_x);
  }

  get angleDegs(): number {
    let _angleRads = this.angleRads;
    return _angleRads * HALF_PI;
  }
  get tanDegs(): number {
    let _angleRads = this.angleRads;
    const degs = _angleRads + 90;
    return degs > 360 ? degs - 360 : degs;
  }

  get tanRads(): number {
    let _angleDegs = this.angleDegs;
    const rads = _angleDegs + HALF_PI;
    return rads > PI_2 ? rads - PI_2 : rads;
  }

  public setPosition = (el: HTMLDivElement) => {
    const { labelAnchor: lPoint, labelHeight } = this;

    if (!isNullable(el) && !isNullable(lPoint)) {
      const left = lPoint.x + this._ctx!.canvasOffsetLeft;
      const top = lPoint.y - labelHeight;
      el.style.left = `${left}px`;
      el.style.top = `${top}px`;
    }
  };
}

export default BoneLossObject;
