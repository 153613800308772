import { createAction, props } from "@ngrx/store";
import { Image, TemplateType } from "@kells/clinic-one/apis";

enum ActionTypes {
  CREATE_IMAGE = "[Image] Create Image",
  SELECT_IMAGE_FROM_PATIENT_HOME = "[Patient Home] Select Image",
  SELECT_IMAGE_FROM_STORYBOARD = "[Storyboard] Select Image",
  IMAGE_DETAIL_NAVIGATE_IMAGE = "[Image Detail] Navigate to Image",
  IMAGE_DRAGGED = "[Image/Layout] Image Dragged",
  IMAGE_TEMPLATE_DRAGGED = "[Image/Layout] Image Template Dragged",
  IMAGE_PANO_DRAGGED = "[Image/Layout] Image Pano Dragged",
  IMAGE_TEMPLATE_EDIT_CANCELED = "[Image] Image Template Edit Canceled",
  IMAGE_TEMPLATE_SAVED = "[Image/Layout] Image Template Saved",
  IMAGE_TEMPLATE_SAVED_SUCCESS = "[Image/Layout] Image Template Saved Success",
  IMAGE_TEMPLATE_SAVED_FAILURE = "[Image/Layout] Image Template Saved Failure",
  UNSET_TEMPLATE_IMAGES = "[Image/Layout] Unset Template Images",
  UNSET_TEMPLATE_IMAGES_SUCCESS = "[Image/Layout] Unset Template Images Success",
  UNSET_TEMPLATE_IMAGES_FAILURE = "[Image/Layout] Unset Template Images Failure",
  UPDATE_IMAGE_ORDERING = "[Image/Layout] Update Session Image Ordering",
  AUTO_ARRANGE_ORDER = "[Image/Layout] Auto Arrange Image Order",
  AUTO_ARRANGE_ORDER_SUCCESS = "[Image/Layout] Auto Arrange Image Order Success",
  AUTO_ARRANGE_ORDER_FAILURE = "[Image/Layout] Auto Arrange Image Order Failure",
  DELETE_IMAGE = "[Image/Layout] Delete Image",
  DELETE_IMAGE_SUCCESS = "[Image/Layout] Delete Image Success",
  DELETE_IMAGE_ERROR = "[Image/Layout] Delete Image Error",
}

export const createImage = createAction(
  ActionTypes.CREATE_IMAGE,
  props<{ id: string; url: string }>()
);

export const selectImageFromPatientHome = createAction(
  ActionTypes.SELECT_IMAGE_FROM_PATIENT_HOME,
  props<{ id: string }>()
);

export const selectImageFromStoryboard = createAction(
  ActionTypes.SELECT_IMAGE_FROM_STORYBOARD,
  props<{ id: string }>()
);

export const imageDetailNavigateToImage = createAction(
  ActionTypes.IMAGE_DETAIL_NAVIGATE_IMAGE,
  props<{ id: string }>()
);

export const imageDragged = createAction(
  ActionTypes.IMAGE_DRAGGED,
  props<{ orderUpdatedImages: Image[] }>()
);

export const imageTemplateDragged = createAction(
  ActionTypes.IMAGE_TEMPLATE_DRAGGED,
  props<{ imageId: string; templateId?: number }>()
);

export const imagePanoDragged = createAction(
  ActionTypes.IMAGE_PANO_DRAGGED,
  props<{ imageId: string; template_type: TemplateType | string }>()
);

export const imageTemplateEditCanceled = createAction(
  ActionTypes.IMAGE_TEMPLATE_EDIT_CANCELED
);

export const unsetTemplateImages = createAction(
  ActionTypes.UNSET_TEMPLATE_IMAGES,
  props<{ patientId: string; sessionDate: string; sessionId: string }>()
);

export const imageTemplateSaved = createAction(
  ActionTypes.IMAGE_TEMPLATE_SAVED,
  props<{ patientId: string; sessionDate: string; sessionId: string }>()
);

export const imageTemplateSavedSuccess = createAction(
  ActionTypes.IMAGE_TEMPLATE_SAVED_SUCCESS,
  props<{ images: Image[] }>()
);

export const unsetTemplateImagesSuccess = createAction(
  ActionTypes.UNSET_TEMPLATE_IMAGES_SUCCESS,
  props<{ images: Image[] }>()
);

export const imageTemplateSavedFailure = createAction(
  ActionTypes.IMAGE_TEMPLATE_SAVED_FAILURE,
  props<{ error: any }>()
);

export const unsetTemplateImagesFailure = createAction(
  ActionTypes.UNSET_TEMPLATE_IMAGES_FAILURE,
  props<{ error: any }>()
);

export const updateSessionImageOrdering = createAction(
  ActionTypes.UPDATE_IMAGE_ORDERING,
  props<{ updatedOrdering: { imageId: string; orderId: number }[] }>()
);

export const autoArrangeImageOrder = createAction(
  ActionTypes.AUTO_ARRANGE_ORDER
);

export const autoArrangeImageOrderSuccess = createAction(
  ActionTypes.AUTO_ARRANGE_ORDER_SUCCESS
);

export const autoArrangeImageOrderFailure = createAction(
  ActionTypes.AUTO_ARRANGE_ORDER_FAILURE,
  props<{ error: any }>()
);

export const deleteImage = createAction(
  ActionTypes.DELETE_IMAGE,
  props<{ imageId: string; navigateUrl?: string[] }>()
);

export const deleteImageSuccess = createAction(
  ActionTypes.DELETE_IMAGE_SUCCESS,
  props<{ imageId: string; navigateUrl?: string[] }>()
);

export const deleteImageError = createAction(
  ActionTypes.DELETE_IMAGE_ERROR,
  props<{ error: any }>()
);

export const errorActions = [
  autoArrangeImageOrderFailure,
  imageTemplateSavedFailure,
];
