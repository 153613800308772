import {
  FindingStatus,
  FindingType,
  NonDummyFindingType,
  RenderableFindingBase,
  PerioData
} from "@kells/interfaces/finding";
import { RenderableFindingCreationPayload } from "@kells/shared-ui/canvas";
import { isDefined } from "@kells/utils/js";

/**
 * A set of properties defined on findings which are not meant to be
 * saved to the server.
 */
interface LocalFindingProperties {
  /**
   * Persist the finding's index on the server side as a property locally.
   * This index is used as the ID for this finding in communicating w/ APIs.
   */
  id: string;
}

/**
 * Interface for a finding that will be renderable on-screen.
 */
export interface RenderableFinding
  extends RenderableFindingBase,
    LocalFindingProperties {
  type: NonDummyFindingType;
  status: FindingStatus;
  /**
   * Threshold value that is used to generate a predicted finding.
   * This field is only meaningful if a finding is predicted, that is, its
   * 'status' === 'predicted'.
   */
  predictionScore?: number;
}

/**
 * Interface for a finding that is non-renderable.
 */
export interface DummyFinding extends LocalFindingProperties {
  type: FindingType.Dummy;
  status: FindingStatus;
  tooth?: string;
  bone_loss_attributes?: PerioData;
}

export type Finding = DummyFinding | RenderableFinding;

export type FindingCreationPayload =
  | RenderableFindingCreationPayload
  | Omit<DummyFinding, "id">;

/**
 * Asserts a variable is of type Finding during runtime.
 */
export const isFinding = (f: unknown): f is Finding =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isDefined((f as any).box_based_attributes) || (f as any).type === "dummy" || (f as any).type === "bone_loss";

/**
 * Describes the aggregation of findings across different images.
 *
 * Keys are expected to be image IDs. Under each image ID key, the collection
 * stores arrays of findings, each of which are expected to be associated with
 * the image ID key.
 */
export interface ImageFindingsCollection {
  [k: string]: RenderableFinding[];
}
