import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  toothInputValidator,
  RenderableFindingBase,
  materialOptions,
  CariesLocationTypes,
  CariesStageTypes
} from "@kells/interfaces/finding";
import { observeProperty } from "@kells/utils/angular";
import { SubSink } from "subsink";
import { keepDefined, pipeLog } from "@kells/utils/observable/observable-operators";
import { Observable } from "rxjs";
import { FindingUpdatePayload } from "@kells/shared-ui/canvas";

@Component({
  selector: "canvas-material-edit-form",
  templateUrl: "./material-edit-form.component.html",
  styleUrls: ["./material-edit-form.component.scss"],
})
export class MaterialEditFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() initialData:
    | (RenderableFindingBase & { id: number })
    | undefined = undefined;
  private initialData$: Observable<
    (RenderableFindingBase & { id: number }) | undefined
  > = observeProperty(this, "initialData");

  @Input('imageConfirmed') isImageConfirmed = false;
  isImageConfirmed$: Observable<boolean> = observeProperty(this, "isImageConfirmed")
    .pipe(pipeLog('isImageConfirmed$'));


  @Input() showDeletButton: boolean;

  @Output() onSave = new EventEmitter<FindingUpdatePayload>();

  @Output() onCancel = new EventEmitter<unknown>();

  @Output() onDelete = new EventEmitter<number>();

  @ViewChild("tooth_field") toothFieldRef: ElementRef<HTMLInputElement>;


  readonly materialOptions = materialOptions;

  private _subs = new SubSink();

  ngOnInit() {
    this.initialData$
      .pipe(keepDefined())
      .subscribe(this.populateInitialData.bind(this));
  }

  ngAfterViewInit() {
    this.afterViewInitFocusOnToothField();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  editForm = new FormGroup({
    tooth: new FormControl("", [Validators.required, toothInputValidator]),
    material: new FormControl("", [Validators.required]),
  });

  get editFormTooth() {
    return this.editForm.get("tooth");
  }

  onSaveClicked() {
    this.onSave.emit({
      tooth: this.editForm.value.tooth,
      material: this.editForm.value.material,
      location: CariesLocationTypes.Other,
      stage: CariesStageTypes.Undefined,
    });
  }

  onCancelClicked() {
    this.editForm.reset();
    this.onCancel.next();
  }

  onDeleteClicked() {
    if (this.initialData) {
      this.onDelete.emit(this.initialData.id);
      this.editForm.reset();
    }
  }

  private populateInitialData(data: RenderableFindingBase) {
    this.editForm.setValue({
      tooth: data.tooth,
      material: data.material
    });
  }

  private afterViewInitFocusOnToothField() {
    this.toothFieldRef.nativeElement.focus();
  }
}
