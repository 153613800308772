import { Renderer2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: "root",
})
export class ScriptService {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
  ) { }
 
  public loadJsScript(renderer: Renderer2, src: string, id: string): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.id = id;
    script.type = "text/javascript";
    script.src = src;
    script.setAttribute("defer", "");

    renderer.appendChild(this._document.body, script);
    
    return script;
  }
}
