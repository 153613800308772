import { createReducer, on } from "@ngrx/store";
import * as PredictionActions from "./prediction.actions";

export const featureKey = "prediction";

/** Locations where prediction can start. */
export type PredictionInitializationSources =
  | "session"
  | "storyboard"
  | "image-detail";

interface PredictionEntity {
  progress: {
    completed: number;
    total: number;
  };
}

export interface PredictionState {
  activeCount: number;
  predictions: {
    [patientId: string]: {
      [key in PredictionInitializationSources]?: PredictionEntity;
    };
  };
}

export const initialState: PredictionState = {
  activeCount: 0,
  predictions: {},
};

const evalPredictionStart = (
  state: PredictionState,
  args: {
    patientId: string;
    imageIds: string[];
    source: PredictionInitializationSources;
  }
): PredictionState => {
  const patientPredictions = state.predictions[args.patientId];
  // there is a prediction session initialized for the same patient at the
  // same source -- ignore request to initialize another prediction session.
  if (patientPredictions && patientPredictions[args.source]) {
    return state;
  }

  return {
    ...state,
    activeCount: state.activeCount + 1,
    predictions: {
      ...state.predictions,
      [args.patientId]: {
        ...state.predictions[args.patientId],
        [args.source]: {
          progress: {
            completed: 0,
            total: args.imageIds.length,
          },
        },
      },
    },
  };
};

const evalImageDetailPredictionStart = (
  state: PredictionState,
  args: { patientId: string }
): PredictionState => {
  const patientPrediction = state.predictions[args.patientId];
  if (patientPrediction && patientPrediction["image-detail"]) {
    return state;
  }

  return {
    ...state,
    activeCount: state.activeCount + 1,
    predictions: {
      ...state.predictions,
      [args.patientId]: {
        ...state.predictions[args.patientId],
        "image-detail": {
          progress: {
            completed: 0,
            total: 1,
          },
        },
      },
    },
  };
};

export const reducer = createReducer(
  initialState,
  on(PredictionActions.sessionPredictionStart, (state, props) =>
    evalPredictionStart(state, { ...props, source: "session" })
  ),
  on(PredictionActions.storyboardPredictionStart, (state, props) =>
    evalPredictionStart(state, { ...props, source: "storyboard" })
  ),
  on(PredictionActions.imageDetailPredictionStart, (state, props) =>
    evalImageDetailPredictionStart(state, props)
  ),

  on(
    PredictionActions.predictImageSuccess,
    PredictionActions.predictImageFailure,
    (state, { patientId, source }) => {
      if (!state.predictions[patientId]) return state;
      if (!state.predictions[patientId][source]) return state;

      const currentProgress = state.predictions[patientId][source]?.progress;
      if (!currentProgress) return state;

      if (currentProgress.completed + 1 === currentProgress.total) {
        // prediction has completed, remove prediction session
        return {
          ...state,
          activeCount: state.activeCount - 1,
          predictions: {
            ...state.predictions,
            [patientId]: {
              ...state.predictions[patientId],
              [source]: undefined,
            },
          },
        };
      }

      return {
        ...state,
        predictions: {
          ...state.predictions,
          [patientId]: {
            ...state.predictions[patientId],
            [source]: {
              ...state.predictions[patientId][source],
              progress: {
                completed: currentProgress.completed + 1,
                total: currentProgress.total,
              },
            },
          },
        },
      };
    }
  )
);

export const getActivePredictons = (state: PredictionState) => state.activeCount;
