import { createSelector } from "@ngrx/store";
import { selectCoreModule } from "../module.selector";
import { selectAll } from "./treatment.reducer";

export const selectTreatmentState = createSelector(
  selectCoreModule,
  (module) => module.treatments
);

export const selectAllTreatments = createSelector(
  selectTreatmentState,
  selectAll
);
