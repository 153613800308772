import { createAction, props } from "@ngrx/store";
import { Image } from "@kells/clinic-one/apis";

enum ActionTypes {
  CONFIRM_IMAGE = "[Image Confirmation / API] Confirm Image",
  CONFIRM_IMAGE_SUCCESS = "[Image Confirmation / API] Confirm Image Success",
  CONFIRM_IMAGE_FAILURE = "[Image Confirmation / API] Confirm Image Failure",
  UNCONFIRM_IMAGE = "[Image Confirmation / API] Unconfirm Image",
  UNCONFIRM_IMAGE_SUCCESS = "[Image Confirmation / API] Unconfirm Image Success",
  UNCONFIRM_IMAGE_FAILURE = "[Image Confirmation / API] Unconfirm Image Failure",

  CONFIRM_SESSION = "[Image Confirmation / API] Confirm Session",
  CONFIRM_SESSION_STARTED = "[Image Confirmation / API] Confirm Session Started",
  CONFIRM_SESSION_ENDED = "[Image Confirmation / API] Confirm Session Ended",
  CONFIRM_SESSION_SUCCESS = "[Image Confirmation / API] Confirm Session Success",
  CONFIRM_SESSION_FAILURE = "[Image Confirmation / API] Confirm Session Failure",

  UNCONFIRM_SESSION = "[Image Confirmation / API] Unconfirm Session",
  UNCONFIRM_SESSION_SUCCESS = "[Image Confirmation / API] Unconfirm Session Success",
  UNCONFIRM_SESSION_FAILURE = "[Image Confirmation / API] Unconfirm Session Failure",
}

export const confirmImage = createAction(
  ActionTypes.CONFIRM_IMAGE,
  props<{ imageId: string }>()
);
export const confirmImageSuccess = createAction(
  ActionTypes.CONFIRM_IMAGE_SUCCESS,
  props<{ imageId: string }>()
);
export const confirmImageFailure = createAction(
  ActionTypes.CONFIRM_IMAGE_FAILURE,
  props<{ error: any }>()
);

export const unconfirmImage = createAction(
  ActionTypes.UNCONFIRM_IMAGE,
  props<{ imageId: string }>()
);
export const unconfirmImageSuccess = createAction(
  ActionTypes.UNCONFIRM_IMAGE_SUCCESS,
  props<{ imageId: string }>()
);
export const unconfirmImageFailure = createAction(
  ActionTypes.UNCONFIRM_IMAGE_FAILURE,
  props<{ error: any }>()
);

export const confirmSession = createAction(
  ActionTypes.CONFIRM_SESSION,
  props<{ patientId: string; sessionDate: string; sessionId: string }>()
);

export const confirmSessionStarted = createAction(
  ActionTypes.CONFIRM_SESSION_STARTED,
  props<{ imageIds: string[] }>()
);

export const confirmSessionEnded = createAction(
  ActionTypes.CONFIRM_SESSION_ENDED,
  props<{ imageIds: string[] }>()
);

export const confirmSessionSuccess = createAction(
  ActionTypes.CONFIRM_SESSION_SUCCESS,
  props<{ images: Image[] }>()
);

export const confirmSessionFailure = createAction(
  ActionTypes.CONFIRM_SESSION_FAILURE,
  props<{ error: any; images: Image[] }>()
);

export const unconfirmSession = createAction(
  ActionTypes.UNCONFIRM_SESSION,
  props<{ patientId: string; sessionDate: string; sessionId: string }>()
);

export const unconfirmSessionSuccess = createAction(
  ActionTypes.UNCONFIRM_SESSION_SUCCESS
);

export const unconfirmSessionFailure = createAction(
  ActionTypes.UNCONFIRM_SESSION_FAILURE,
  props<{ error: any }>()
);

export const errorActions = [
  confirmImageFailure,
  unconfirmImageFailure,
  unconfirmSessionFailure,
  confirmSessionFailure,
];
