import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import {
  Subject,
  combineLatest,
  merge,
  fromEvent,
  Observable,
  BehaviorSubject,
} from "rxjs";
import {
  map,
  distinctUntilChanged,
  tap,
  debounceTime,
  filter,
} from "rxjs/operators";
import {
  keepDefined,
  pipeLog,
} from "@kells/utils/observable/observable-operators";
import { SubSink } from "subsink";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  XRayDialogComponent,
  XRayDialogData,
} from "../../xray-dialog/xray-dialog.component";
import { observeProperty } from "@kells/utils/angular";
import {
  FindingsAggregator,
  FindingsByImage,
  FindingsByTooth,
  PerioData,
  RenderableFindingBase,
  TOOTH_NAMES,
} from "@kells/interfaces/finding";
import { isDefined } from "@kells/utils/js";
import { KellsImageBase } from "@kells/interfaces/image";
import { DataAccessService } from "@kells/apis/data-access";
import { ADVANCED, MODERATE_ONLY } from "../../theme/colors";
import { INITIAL_ONLY } from "../../oral-condition-donut-chart/oral-condition-donut-chart.component";
import { NgxTippyProps } from "ngx-tippy-wrapper";

export function calcSeverity(finding: RenderableFindingBase): number {
  return Number(
    finding?.bone_loss_attributes?.measurement_mm ??
      finding?.bone_loss_attributes?.measurement_pixel ??
      1
  );
}

export function calcMeasurement(bone_loss_attributes: PerioData): PerioData {
  let px: number = Math.sqrt(
    Math.hypot(
      bone_loss_attributes.ac_x - bone_loss_attributes.cej_x,
      bone_loss_attributes.ac_y - bone_loss_attributes.cej_y
    )
  );
  // bone_loss_attributes.measurement_pixel = Number(px).toFixed(2);
  // const mm: string = Number(px * 0.26458333).toFixed(2);
  // bone_loss_attributes.measurement_mm = mm;
  return bone_loss_attributes;
}

@Component({
  selector: "koa-xray-image",
  templateUrl: "./xray-image.component.html",
  styleUrls: ["./xray-image.component.scss"],
})
export class XRayImageComponent implements OnInit, OnDestroy {
  COLOR_PALETTE_ADVANCED = ADVANCED;
  COLOR_PALETTE_INITIAL = INITIAL_ONLY;
  COLOR_PALETTE_MODERATE = MODERATE_ONLY;
  xrayImageUrl: Subject<string> = new Subject<string>();

  @Input() image: KellsImageBase;
  @Input() imageUrl: string;
  @Input() index: number;
  @Input() totalCount: number;

  @Input() imageId: string;
  private imageId$: Observable<string> = observeProperty(this, "imageId");

  @Input() findingsByTooth: FindingsByTooth;
  findingsByTooth$: Observable<FindingsByTooth> = observeProperty(
    this,
    "findingsByTooth"
  ).pipe(keepDefined(), distinctUntilChanged());

  @Input() findingsByImage: FindingsByImage;
  findingsByImage$: Observable<FindingsByImage> = observeProperty(
    this,
    "findingsByImage"
  ).pipe(keepDefined(), distinctUntilChanged());

  @Input("showCaries") showCaries = true;
  @Input("showBoneloss") showBoneloss = false;
  @Input("showPlaque") showPlaque = false;
  @Input("showFracture") showFracture = false;
  @Input("showGumRecession") showGumRecession = false;
  @Input("showGumInflammation") showGumInflammation = false;
  @Input("showMissingTooth") showMissingTooth = false;
  @Input("interactive") interactive = true;

  renderFindings$: Observable<FindingsAggregator> = merge(
    this.findingsByTooth$.pipe(
      filter((findings) => isDefined(findings.allFindings))
    ),
    this.findingsByImage$.pipe(
      filter((findings) => isDefined(findings.allFindings))
    )
  );

  cariesAdvanced$: Observable<RenderableFindingBase[]>;
  cariesModerate$: Observable<RenderableFindingBase[]>;
  cariesInitial$: Observable<RenderableFindingBase[]>;
  fracture$: Observable<RenderableFindingBase[]>;
  plaque$: Observable<RenderableFindingBase[]>;
  gumRecession$: Observable<RenderableFindingBase[]>;
  gumInflammation$: Observable<RenderableFindingBase[]>;
  missingTooth$: Observable<RenderableFindingBase[]>;
  boneLoss$: Observable<RenderableFindingBase[]>;

  getToothName(toothNumber: number): string {
    return TOOTH_NAMES[toothNumber];
  }

  dataReady = false;
  /**
   * When set to `true`, user clicks the image to select it (potentially from
   * a list of images). Turning this attribute on also disables the default
   * behavior of navigating to the image page.
   */
  @Input() inSelectionMode = false;

  /**
   * An input that specifies whether the image is selected.
   * Defaults to `false`.
   */
  @Input() selected = false;

  /**
   * An input that specifies whether the findings are shown.
   * Defaults to `false`.
   */
  @Input() hideFindingsCount = false;

  /**
   * An input that specifies whether the selection button is shown.
   * Defaults to `false`.
   */
  @Input() hideSelectionButton = false;

  /** Emits the image's ID when it is clicked */
  // @Output() clicked = new EventEmitter<string>();
  /** Emits if the image selection button has been clicked. */
  @Output() selectionRequested = new EventEmitter<string>();

  @ViewChild("svg") svg: ElementRef<SVGElement>;

  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;

  @ViewChild("xray") xray: ElementRef<HTMLImageElement>;

  @Input("filterTooth") filterTooth: string | null = null;

  @Output("onXrayOpen") xrayOpenEvent = new EventEmitter<void>();

  imageClicked = new Subject<void>();
  imageClicked$ = this.imageClicked.asObservable().pipe(
    map(() => {
      if (this.interactive) {
        this._openDialogRef();
      }
    })
  );

  private navigateToImage$ = this.imageClicked$.pipe(tap(() => {}));

  imageContainerWidthSubject = new BehaviorSubject<number>(80);
  imageContainerWidth$: Observable<number> = this.imageContainerWidthSubject
    .asObservable()
    .pipe(keepDefined(), distinctUntilChanged())
    .pipe(
      map((val) => {
        if (isNaN(val)) {
          return 0;
        }
        return val;
      })
    );

  imageContainerHeightSubject = new BehaviorSubject<number>(0);
  imageContainerHeight$: Observable<number> = this.imageContainerHeightSubject
    .asObservable()
    .pipe(keepDefined(), distinctUntilChanged())
    .pipe(
      pipeLog("imageContainerHeight"),
      map((val) => {
        if (isNaN(val)) {
          return 0;
        }

        return val;
      })
    );

  containterDimensions$ = combineLatest([
    this.imageContainerWidth$.pipe(keepDefined(), distinctUntilChanged()),
    this.imageContainerHeight$.pipe(keepDefined(), distinctUntilChanged()),
  ]).pipe(
    tap(([containerHeight, containerWidth]: [number, number]) => {
      if (containerHeight > 0 && containerWidth > 0) {
        this.dataReady = true;
        this.svg.nativeElement.setAttribute("width", `${containerWidth}px`);
        this.svg.nativeElement.setAttribute("height", `${containerHeight}px`);
      }
      // this.xray.nativeElement.setAttribute('height', containerHeight + 'px');
      // this.xray.nativeElement.setAttribute('width', containerWidth + 'px');
    })
  );

  hostWidth: string;
  hostHeight: string;

  xrayWidth: number;
  xrayHeight: number;

  private _subs = new SubSink();

  initFindings(): void {}

  constructor(
    private hostElement: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    public dialog: MatDialog,

    private data: DataAccessService
  ) {}

  tippyProps: NgxTippyProps;

  ngOnInit() {
    this.initFindings();
    this.initFindingsObservables();
    this._subs.sink = this.renderFindings$.subscribe();
    this.tippyProps = {
      trigger: "hover",
      allowHTML: true,
    };
  }

  maxSeverity = 0;

  ngOnDestroy() {
    this._subs.unsubscribe();
    this.svg.nativeElement.removeEventListener("click", this._openDialogRef);
  }

  interactive$: Observable<boolean> = observeProperty(this, "interactive")
    .pipe(keepDefined(), distinctUntilChanged())
    .pipe(
      map((val: boolean) => {
        if (val) {
          this.enableInteraction();
        }
        return val;
      })
    );

  enableInteraction() {
    this.hostElement.nativeElement.classList.add("interactive");
    this.hostElement.nativeElement.addEventListener(
      "click",
      this._openDialogRef
    );
  }

  private _dialogRef: MatDialogRef<XRayDialogComponent, XRayDialogData>;
  _openDialogRef = () => {
    if (this.interactive) {
      if (this.xrayHeight > this.xrayWidth) {
        var width = `400px`;
      } else {
        width = `800px`;
      }
      this._dialogRef = this.dialog.open(XRayDialogComponent, {
        width: width,
        height: "auto", //`${this.xrayHeight}px`,
        // maxHeight: `calc(100vh - 108px)`,
        panelClass: ["xrayPanelClass"],
        data: {
          image: this?.image,
          imageUrl: this?.imageUrl,
          findingsByTooth: this.filterTooth,
          findingsByImage: this.findingsByImage,
          xrayWidth: this.xrayWidth,
          xrayHeight: this.xrayHeight,
          showCaries: this.showCaries,
          showBoneloss: this.showBoneloss,
          showPlaque: this.showPlaque,
          showFracture: this.showFracture,
          showGumRecession: this.showGumRecession,
          showGumInflammation: this.showGumInflammation,
          showMissingTooth: this.showMissingTooth,
          index: this.index,
          totalCount: this.totalCount,
        },
      });
    }
  };

  _measureContainer(): void {
    requestAnimationFrame(() => {
      const styles = getComputedStyle(this.hostElement.nativeElement);
      this.imageContainerWidthSubject.next(Number(styles.width.split("px")[0]));
      this.imageContainerHeightSubject.next(
        Number(styles.height.split("px")[0])
      );
    });
  }

  _loadImage(url: string) {
    if (!this.xray) return;

    const img = this.xray.nativeElement;

    img.onload = () => {
      this.svg.nativeElement.setAttribute(
        "viewBox",
        `0 0 ${img.naturalWidth} ${img.naturalHeight}`
      );

      img.setAttribute("width", `${img.naturalWidth}px`);
      img.setAttribute("height", `${img.naturalHeight}px`);

      this.xrayWidth = img.naturalWidth;
      this.xrayHeight = img.naturalHeight;
      this.xrayImageUrl.next("#" + this.image.id);
    };

    img.setAttribute("src", url);
  }

  ngAfterViewInit() {
    this._measureContainer();
    if (isDefined(this.image)) {
      this._loadImage(this.image.url);
    } else {
      this._subs.sink = this.data.images$.subscribe(
        (images: KellsImageBase[]) => {
          let findImage = images.find((x) => {
            return x.url === this?.imageUrl;
          });
          if (findImage) {
            this.image = findImage;
            this._loadImage(this.image.url);
          }
        }
      );
    }

    this._subs.sink = fromEvent(window, "resize")
      .pipe(debounceTime(500))
      .subscribe(() => {
        this._measureContainer();
      });
    this.svg.nativeElement.onclick = (event) => {
      this._openDialogRef();

      if (this.interactive) {
        this.xrayOpenEvent.emit();
      }
    };

    this._subs.sink = this.containterDimensions$.subscribe();
  }

  private broadcastImageClickEvents() {
    // this._subs.sink = this.imageClicked$.subscribe(() => this.clicked.next(this.imageId));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.filterTooth) {
      this._loadImage(this.image.url);
      this._subs.sink = this.renderFindings$.subscribe();
      this.initFindingsObservables();
    }
  }

  initFindingsObservables() {
    this.cariesInitial$ = this.renderFindings$
      .pipe(map((findings) => findings.initial))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.cariesAdvanced$ = this.renderFindings$
      .pipe(map((findings) => findings.advanced))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.cariesModerate$ = this.renderFindings$
      .pipe(map((findings) => findings.moderate))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.fracture$ = this.renderFindings$
      .pipe(map((findings) => findings.fracture))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.plaque$ = this.renderFindings$
      .pipe(map((findings) => findings.plaque))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.gumRecession$ = this.renderFindings$
      .pipe(map((findings) => findings.gumRecession))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.gumInflammation$ = this.renderFindings$
      .pipe(map((findings) => findings.gumInflammation))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.missingTooth$ = this.renderFindings$
      .pipe(map((findings) => findings.missingTooth))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          return f;
        })
      );
    this.boneLoss$ = this.renderFindings$
      .pipe(map((findings) => findings.boneLoss))
      .pipe(filter((f) => isDefined(f) && f.length > 0))
      .pipe(
        map((f) => {
          if (this.filterTooth) {
            return f.filter((finding) => {
              return finding.tooth === this.filterTooth;
            });
          }
          f.map((find) => {
            let severity = 0.1;
            find.bone_loss_attributes = calcMeasurement(
              find.bone_loss_attributes as PerioData
            );
            severity = calcSeverity(find);
            find.bone_loss_attributes.severity = severity.toString();
          });
          return f;
        })
      );
  }
}
