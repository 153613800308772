import {
  FindingType,
  FindingProcessingService,
  BoxCoordinates,
  FindingStatus,
} from "@kells/interfaces/finding";
import { isNullable, assertNever } from "@kells/utils/js";
import { Finding, DummyFinding, RenderableFinding } from "../..";
import { FindingDTO, FindingDTOPatch } from "../models/finding-api.model";

/**
 * Class responsible for converting a finding from it's local representation
 * to its server representation, and vice versa.
 */
export class FindingAdapter {
  /**
   * Convert a finding response from the server to its local representation.
   *
   * @param dto the finding object returned from the server
   *
   * @returns a local finding object
   */
  static toFinding(dto: FindingDTO): Finding {
    if (dto.type === FindingType.Dummy) {
      return {
        type: FindingType.Dummy,
        status: FindingAdapter.resolveFindingStatus(dto.status),
        id: dto._id,
      } as DummyFinding;
    }

    if (dto.type === FindingType.BoneLoss) {
      const bone_loss_attributes = dto.bone_loss_attributes;
      if (isNullable(bone_loss_attributes)) {
        return assertNever(
          `Finding of type '${dto.type}' is expected to have bone_loss_attributes but none can be found.`
        );
      } else {
        if (isNullable(bone_loss_attributes.measurement_pixel)) {
          let px: number = Math.sqrt(
            Math.hypot(
              bone_loss_attributes.ac_x - bone_loss_attributes.cej_x,
              bone_loss_attributes.ac_y - bone_loss_attributes.cej_y
            )
          );
          // const mm: string = Number(px * 0.26458333).toFixed(2);
          // bone_loss_attributes.measurement_pixel = Number(px).toFixed(2);
          // bone_loss_attributes.measurement_mm = mm;
        }

        return {
          id: dto._id,
          type: FindingType.BoneLoss,
          status: FindingAdapter.resolveFindingStatus(dto.status),

          box: [
            bone_loss_attributes.cej_x,
            bone_loss_attributes.cej_y,
            bone_loss_attributes.ac_x,
            bone_loss_attributes.ac_y,
          ],
          bone_loss_attributes: {
            ac_x: (bone_loss_attributes.ac_x as number) ?? 0,
            ac_y: (bone_loss_attributes.ac_y as number) ?? 0,
            cej_x: (bone_loss_attributes.cej_x as number) ?? 0,
            cej_y: (bone_loss_attributes.cej_y as number) ?? 0,
            measurement_mm:
              (bone_loss_attributes.measurement_mm as number) ?? 0,
            measurement_pixel:
              (bone_loss_attributes.measurement_pixel as number) ?? 0,
            severity: (bone_loss_attributes.severity as string) ?? "",
            grade: (bone_loss_attributes.grade as string) ?? "A",
            tooth:
              FindingProcessingService.extractLabel(dto.labels, "tooth") ?? "0",
          },
          location:
            FindingProcessingService.extractLabel(dto.labels, "location") ??
            "1",
          stage:
            FindingProcessingService.resolveCariesStage(
              FindingProcessingService.extractLabel(dto.labels, "stage")
            ) ?? undefined,
          tooth:
            FindingProcessingService.extractLabel(dto.labels, "tooth") ?? "0",
          predictionScore: dto.score,
        } as RenderableFinding;
      }
    }

    const boxAttrs = dto.box_based_attributes;

    if (isNullable(boxAttrs))
      return assertNever(
        `Finding of type '${dto.type}' is expected to have a box but none can be found.`
      );

    return {
      id: dto._id,
      type: FindingProcessingService.resolveFindingType(dto.type),
      status: FindingAdapter.resolveFindingStatus(dto.status),

      box: [boxAttrs.xmin, boxAttrs.ymin, boxAttrs.width, boxAttrs.height],

      location:
        FindingProcessingService.extractLabel(dto.labels, "location") ??
        undefined,
      stage:
        FindingProcessingService.resolveCariesStage(
          FindingProcessingService.extractLabel(dto.labels, "stage")
        ) ?? undefined,
      tooth:
        FindingProcessingService.extractLabel(dto.labels, "tooth") ?? undefined,

      material:
        FindingProcessingService.extractLabel(dto.labels, "material") ??
        undefined,

      predictionScore: dto.score,
    } as RenderableFinding;
  }

  /**
   * Converts finding from its local representation to a format accepted by
   * the server.
   *
   * @param finding a finding object in its local data structure
   *
   * @returns a finding object that fits the server's finding schema
   */
  static toDTO(finding: Partial<Finding>): FindingDTOPatch {
    const dto: Omit<FindingDTO, "_id"> = {};

    if (finding.type === FindingType.Dummy) {
      return finding;
    }

    // manual type narrowing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _finding: Partial<RenderableFinding> = finding as any;
    dto.type = _finding.type;
    dto.status = _finding.status;
    dto.score = _finding.predictionScore;
    dto.bone_loss_attributes = _finding.bone_loss_attributes;
    if (_finding.box) {
      dto.box_based_attributes = FindingAdapter.convertBoxToServerFormat(
        _finding.box
      );
    }

    dto.labels = FindingAdapter.toLabelsArray({
      tooth: _finding.tooth,
      stage: _finding.stage,
      location: _finding.location,
      material: _finding.material,
    });

    return dto;
  }

  private static toLabelsArray(
    obj: Record<string, string | null | undefined>
  ): string[] {
    return Object.entries(obj)
      .filter(([key, val]) => Boolean(key) && Boolean(val))
      .map(([key, val]) => `${key}--${val}`);
  }

  private static convertBoxToServerFormat(
    box: BoxCoordinates
  ): FindingDTO["box_based_attributes"] {
    return {
      xmin: box[0],
      ymin: box[1],
      width: box[2],
      height: box[3],
    };
  }

  private static resolveFindingStatus(rawStatus?: string): FindingStatus {
    if (isNullable(rawStatus)) {
      return FindingStatus.Default;
    }

    switch (rawStatus) {
      case FindingStatus.Confirmed:
        return FindingStatus.Confirmed;
      case FindingStatus.Predicted:
        return FindingStatus.Predicted;
      default:
        return FindingStatus.Default;
    }
  }
}
