import { RenderState } from '@kells/shared-ui/canvas';
import { theme } from '../../exported-theme';

const HOVER_PALETTE = theme.colors.cyan;
const ACTIVE_PALETTE = theme.colors.pink;
const FOCUS_PALETTE = theme.colors.lime;
const MAIN_PALETTE = theme.colors.slateRose;
const MAIN_PALETTE_CEJ = theme.colors.tealGray_altDark;
const ALPHAMOD = '39';
class BoneLossStateStyles {


  HOVER_PALETTE = HOVER_PALETTE;
  ACTIVE_PALETTE = ACTIVE_PALETTE;
  FOCUS_PALETTE = FOCUS_PALETTE;
  MAIN_PALETTE = MAIN_PALETTE;

  private _lineColor: string = MAIN_PALETTE[700];
  private _lineColor_hover: string = HOVER_PALETTE[400];
  private _lineColor_focus: string = FOCUS_PALETTE[400];
  private _lineColor_active: string = HOVER_PALETTE[500];

  private _acFillColor: string = MAIN_PALETTE[700];
  private _acjFillColor_hover: string = HOVER_PALETTE[400];
  private _acFillColor_focus: string = FOCUS_PALETTE[400];
  private _acFillColor_focus_hover: string = FOCUS_PALETTE[300];
  private _acFillColor_active: string = ACTIVE_PALETTE[400];
  private _acFillColor_active_hover: string = ACTIVE_PALETTE[300];


  private _cejFillColor: string = MAIN_PALETTE_CEJ[4];
  private _cejFillColor_hover: string = MAIN_PALETTE_CEJ[5];
  private _cejFillColor_focus: string = MAIN_PALETTE_CEJ[4];
  private _cejFillColor_focus_hover: string = FOCUS_PALETTE[300];
  private _cejFillColor_active: string = ACTIVE_PALETTE[400];
  private _cejFillColor_active_hover: string = ACTIVE_PALETTE[300];


  private _cejStrokeColor: string = MAIN_PALETTE_CEJ[1];
  private _cejStrokeColor_hover: string = HOVER_PALETTE[400];
  private _cejStrokeColor_focus: string = FOCUS_PALETTE[400];
  private _cejStrokeColor_focus_hover: string = FOCUS_PALETTE[300];
  private _cejStrokeColor_active: string = ACTIVE_PALETTE[400];
  private _cejStrokeColor_active_hover: string = ACTIVE_PALETTE[300];

  private _unconfirmedDashArray = [4, 2, 4, 2, 4, 2];

  public _cejState: RenderState;
  public _acState: RenderState;
  public _lineState: RenderState;

  public isConfirmed:boolean;

  public constructor(isConfirmed:boolean) {
    this.isConfirmed = isConfirmed;
    this._setCEJ();
    this._setAC();
    this._setLine();
  }

  set baseLineColor(color:string) {
    this._lineColor = color;
  }

  _setCEJ() {
    this._cejState = {
      strokeWidth: 1,//this._cejStrokeWidth_hover,
      strokeColor: this._cejStrokeColor,// | null = null;
      fillColor: this._cejFillColor
    };
  }


  _setACAlpha() {
    this._acState = {
      strokeWidth: 1,//this._cejStrokeWidth_hover,
      strokeColor: this._cejStrokeColor + ALPHAMOD,
      fillColor: this._cejFillColor + ALPHAMOD
    };
  }

  _setCEJAlpha() {
    this._cejState = {
      strokeWidth: 1,//this._cejStrokeWidth_hover,
      strokeColor: this._cejStrokeColor + ALPHAMOD,
      fillColor: this._cejFillColor + ALPHAMOD
    };
  }
  _setLineAlpha() {
    this._lineState = {
      strokeWidth: 1,
      strokeColor: this._cejStrokeColor + ALPHAMOD,
      fillColor: this._cejFillColor + ALPHAMOD
    };
  }

  _setAC() {
    this._acState = {
      strokeColor: this._lineColor,
      strokeWidth: 1,
      fillColor: this._acFillColor
    }
  }

  _setLine() {
    this._lineState = {
      strokeColor: this._lineColor,
      strokeWidth: 2,
      fillColor: this._acFillColor
    };
    if (this.isConfirmed === false) {
      this._lineState.strokeDashArray = this._unconfirmedDashArray;
    }
  }

  _setAll() {
    this._setCEJ();
    this._setAC();
    this._setLine();
  }


  _setCEJHover() {
    this._cejState = {
      strokeWidth: 1,
      strokeColor: this._cejStrokeColor_hover,
      fillColor: this._cejFillColor_hover
    };
  }

  _setACHover() {
    this._acState = {
      strokeColor: this._lineColor_hover,
      strokeWidth: 1,
      fillColor: this._acjFillColor_hover
    };
  }

  _setLineHover() {
    this._lineState = {
      strokeColor: this._lineColor_hover,
      strokeWidth: 4,
      fillColor: this._lineColor_hover
    };
    if (this.isConfirmed === false) {
      this._lineState.strokeDashArray = this._unconfirmedDashArray;
    }
  }

  _setAllHover() {
    this._setCEJHover();
    this._setACHover();
    this._setLineHover();
  }



  _setCEJFocus() {
    this._cejState = {
      strokeWidth: 1,//this._cejStrokeWidth_hover,
      strokeColor: this._cejStrokeColor_focus,// | null = null;
      fillColor: this._cejFillColor_focus// | null = null;
    };
  }

  _setACFocus() {
    this._acState = {
      strokeColor: this._acFillColor_focus,
      strokeWidth: 1,
      fillColor: this._acFillColor_focus
    };
  }

  _setLineFocus() {
    this._lineState = {
      strokeColor: this._lineColor_focus,
      strokeWidth: 2,
      fillColor: this._lineColor_focus
    };
    if (this.isConfirmed === false) {
      this._lineState.strokeDashArray = this._unconfirmedDashArray;
    }
  }

  _setAllFocus() {
    this._setCEJFocus();
    this._setACFocus();
    this._setLineFocus();
  }



  _setCEJActive() {
    this._cejState = {
      strokeWidth: 1,
      strokeColor: this._cejStrokeColor_active,
      fillColor: this._cejFillColor_active
    };
  }

  _setACActive() {
    this._acState = {
      strokeColor: this._lineColor_active,
      strokeWidth: 1,
      fillColor: this._acFillColor_active
    };
  }

  _setLineActive() {
    this._lineState = {
      strokeColor: this._lineColor_active,
      strokeWidth: 4,
      fillColor: this._lineColor_active
    };
    if (this.isConfirmed === false) {
      this._lineState.strokeDashArray = this._unconfirmedDashArray;
    }
  }

  _setAllActive() {
    this._setCEJActive();
    this._setACActive();
    this._setLineActive();
  }

  _setCEJFocusHover() {
    this._cejState = {
      strokeWidth: 1,//this._cejStrokeWidth_hover,
      strokeColor: this._cejStrokeColor_focus_hover,// | null = null;
      fillColor: this._cejFillColor_focus_hover// | null = null;
    };
  }

  _setACFocusHover() {
    this._acState = {
      strokeColor: this._acjFillColor_hover,
      strokeWidth: 1,
      fillColor: this._acFillColor_focus_hover
    };
  }


  _setCEJActiveHover() {
    this._cejState = {
      strokeWidth: 1,//this._cejStrokeWidth_hover,
      strokeColor: this._cejStrokeColor_focus_hover,// | null = null;
      fillColor: this._cejFillColor_focus_hover// | null = null;
    };
  }

  _setACActiveHover() {
    this._acState = {
      strokeColor: this._cejStrokeColor_active_hover,
      strokeWidth: 1,
      fillColor: this._cejFillColor_active_hover
    };
  }

  setAllUp() {
    this._setCEJ();
    this._setLine();
    this._setAC();
  }

  setAllDeemphasized() {
    this._setCEJAlpha();
    this._setLineAlpha();
    this._setACAlpha();
  }


  setActive(type:string) {
    switch (type) {
      case 'rect':
        this._setCEJActive();
        this._setLineFocus();
        this._setACFocus();
        break;

      case 'circle':
        this._setACActive();
        this._setCEJFocus();
        this._setLineFocus();
        break;
      case 'line':
        this._setAllFocus();
        return;
    }
  }

  setHover(type: string) {
    switch (type) {
      case 'rect':
        this._setCEJHover();
        break;
      case 'circle':
        this._setACHover();
        break;
      case 'line':
        this._setAll();
        break;
    }
  }

  setFocusHover(type:string) {
    switch (type) {
      case 'rect':
        this._setCEJFocusHover();
        break;
      case 'circle':
        this._setACFocusHover();
        break;
      case 'line':
        this._setAllFocus();
        break;
    }
  }
  setFocus(type: string) {
    switch (type) {
      case 'rect':
        this._setCEJFocus();
        break;
      case 'circle':
        this._setACFocus();
        break;
      case 'line':
        this._setAll();
        break;
    }
  }

  setActiveHover(type: string) {
    switch (type) {
      case 'rect':
        this._setCEJActiveHover();
        break;

      case 'circle':
        this._setACActiveHover();
        break;
      case 'line':
        this._setAllActive();
        break;
    }
  }


}


export default BoneLossStateStyles;
