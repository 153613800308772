import {
  ForGoogleAnalytics,
  StandardAnalyticsEventCreator,
  GoogleAnalyticsEventAdapter,
  GoogleAnalyticsEventCreator,
  DetailFactory,
  AnalyticsEventCreator,
  AnalyticsEventSchematics,
  SchematicsOutput,
  ValidatedSchematics,
} from "./analytics.model";

export function createAnalyticsEvent<T = Record<string, unknown>>(
  eventName: string
): StandardAnalyticsEventCreator<T>;

export function createAnalyticsEvent<T = Record<string, unknown>>(
  eventName: string,
  toGoogleAnalyticsEvent: GoogleAnalyticsEventAdapter<T>
): GoogleAnalyticsEventCreator<T>;

export function createAnalyticsEvent<T = Record<string, unknown>>(
  eventName: string,
  toGoogleAnalyticsEvent?: GoogleAnalyticsEventAdapter<T>
): any {
  return (eventDetail: T) => {
    return {
      type: eventName,
      detail: toGoogleAnalyticsEvent
        ? toGoogleAnalyticsEvent(eventDetail)
        : eventDetail,
    };
  };
}

/**
 * A factory function which records the interface of an event's detail payload.
 */
export const eventDetail = <
  D = Record<string, unknown>
>(): DetailFactory<D> => (d: D) => ({
  _detailPayload: d,
});

/**
 * @internal
 * This function is used solely by `createEventsFromSchematics` for now.
 * It is designed to replace `createAnalyticsEvent` in a future update, because
 * it also works with the schematics creator while its predecessor doesn't.
 */
const createEvent = <T = Record<string, unknown>>(
  eventName: string,
  detailCreator = eventDetail<T>(),
  toGoogleAnalyticsEvent?: GoogleAnalyticsEventAdapter<T>
): AnalyticsEventCreator<T | ForGoogleAnalytics<T>> => (detailPayload: T) => {
  const gaDetailFactory = eventDetail<ForGoogleAnalytics<T>>();

  const { _detailPayload } = toGoogleAnalyticsEvent
    ? gaDetailFactory(toGoogleAnalyticsEvent(detailPayload))
    : detailCreator(detailPayload);

  return {
    type: eventName,
    detail: _detailPayload,
  };
};

/**
 * Create multiple event creators, given event specifications provided in the
 * function argument.
 *
 * @example
 * ```ts
 * const eventCreators = createEventsFromSchematics({
 *   // specify the event name variable as a property of the schematic
 *   SelectPatientEvent: {
 *     displayedName: 'Patient Selected',
 *     // specify the event properties, including optional ones
 *     detail: eventDetail<{
 *       patientId: string;
 *       patientName?: string;
 *     }>(),
 *   },
 *   ... // list more event schematics here
 * });
 *
 * // obtain event creators directly from `createEventsFromSchematics`'s output.
 * export const { SelectPatientEvent } = eventCreators;
 *
 * // create event instances with the properties specified in the schematic
 * const eventInstance = SelectPatientEvent({
 *   patientId: "demo-patient-id",
 *   patientName: "Demo Patient"
 * })
 * ```
 */
export const createEventsFromSchematics = <S extends AnalyticsEventSchematics>(
  schematics: S
): SchematicsOutput<ValidatedSchematics<S>> => {
  return Object.entries(schematics).reduce((acc, [eventCreatorName, spec]) => {
    return {
      ...acc,
      [eventCreatorName]: createEvent(
        spec.displayedName,
        spec.detail || eventDetail(),
        spec.googleAnalyticsAdapter
      ),
    };
  }, {} as SchematicsOutput<ValidatedSchematics<S>>);
};
