import { BoxCoordinates, PerioData } from "@kells/interfaces/finding";
import { isDefined } from "@kells/utils/js";

type ToothNumberResponse = string;

export type CariesPredictionIdentifier = "caries";

/**
 * An interface that describes a box's coordinates based on its minimum
 * and maximum values on the x- and y-direction.
 */
export interface MinMaxBoxCoordinates {
  xmax: number;
  xmin: number;
  ymax: number;
  ymin: number;
}

export interface Prediction {
  /**
   * A score indicating how accurate the prediction is.
   *
   * Minimum: 0.
   * Maximum: 1.
   */
  score: number;
}

/**
 * An interface that includes properties that are included for predictions
 * attributable to a tooth.
 */
interface ToothAttributable {
  tooth: ToothNumberResponse;
}

export interface CariesPrediction
  extends Prediction,
    Partial<ToothAttributable> {
  box: BoxCoordinates;
  label: CariesPredictionIdentifier;
  /**
   * A string that describes a caries location.
   * It is expected to resolve to one of `CariesLocationTypes`.
   */
  location: string;
  /**
   * A string that describes a caries's severity stage.
   * It is expected to resolve to one of `CariesStageTypes`.
   */
  stage: string;
}

export interface BoneLossPrediction
  extends Prediction,
  Partial<ToothAttributable> {
    ac_x: number;
    ac_y: number;
    cej_x: number;
    cej_y: number;
  stage: string;
  length_in_mm: string;
  tooth:string;

}

export interface ToothPrediction extends Prediction, MinMaxBoxCoordinates {
  label: ToothNumberResponse;
}



export interface MaterialPrediction
  extends Prediction,
    MinMaxBoxCoordinates,
    Partial<ToothAttributable> {
  /** A string that describes the type of material for this prediction. */
  label: string;
}

export type PredictionKinds =
  | CariesPrediction
  | ToothPrediction
  | MaterialPrediction
  | BoneLossPrediction;

const doesExtendMinMaxBoxCoordinates = <T extends MinMaxBoxCoordinates>(
  p: unknown
): p is T => {
  return (
    isDefined((p as any).ymax) &&
    isDefined((p as any).ymin) &&
    isDefined((p as any).xmax) &&
    isDefined((p as any).xmin)
  );
};

export const isToothPrediction = (p: PredictionKinds): p is ToothPrediction => {
  const isNumeric = (str: unknown) => {
    if (typeof str !== "string") return false; // we only process strings!
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  };

  return (
    isDefined((p as any).label) && // a label must exist for a tooth prediction
    isNumeric((p as any).label) && // infer prediction as tooth prediction by checking if 'label' contains tooth number
    doesExtendMinMaxBoxCoordinates(p)
  );
};

export const isMaterialPrediction = (
  p: PredictionKinds
): p is MaterialPrediction => {
  if (isToothPrediction(p)) return false;

  return doesExtendMinMaxBoxCoordinates(p);
};

export const isCariesPrediction = (
  p: PredictionKinds
): p is CariesPrediction => {
  return !isToothPrediction(p) && !isMaterialPrediction(p);
};



export const isBonelossPrediction = (
  p: PredictionKinds
): p is BoneLossPrediction => {
  return isDefined((p as any).length_in_mm);
  //return !isToothPrediction(p) && !isMaterialPrediction(p);
};
export interface PredictionApiResponse {
  data: {
    boneloss: BoneLossPrediction[];
    caries: CariesPrediction[];
    materials: MaterialPrediction[];
    tooth: {
      data: ToothPrediction[];
      /** Number of tooth predictions generated. */
      total_num: number;
    };
  };
}
