import { isEqual } from "lodash-es";
import { Observable, pipe, UnaryFunction } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

/**
 * Do not re-emit if the next value deep equals the previous emission.
 *
 * This pipe operator only emits values if they are meaningfully different
 * from the previous emission.
 *
 * It can be thought of as a stricter version of `distinctUntilChanged`.
 * `distinctUntilChanged` re-emits if the next value's memory reference differs
 * from the previous emission. This means shallow-copied objects (new memory
 * reference but no changes in content) are re-emitted using
 * `distinctUntilChanged`. In contrast, `distinctUntilDeepValueChanged` does
 * not emit based on memory references: if two adjacent values in a stream
 * have the same content (is deep equal), only the first one is emitted.
 *
 * Specifically, `distinctUntilDeepValueChanged` does not re-emit if:
 *
 * - object's memory reference changed, but content hasn't (object is shallow copied)
 * - object is reconstructed (deep copied), but its content is not modified
 *
 * Use this operator when you only want to trigger a downstream action if the
 * underlying content has changed in some meaningful way.
 */
export const distinctUntilDeepValueChanged = <T>(): UnaryFunction<
  Observable<T>,
  Observable<T>
> => {
  return pipe(distinctUntilChanged(isEqual));
};
