import { createAction, props } from "@ngrx/store";
import { Patient, Image } from "@kells/clinic-one/apis";

export enum ActionTypes {
  FETCH_PATIENT_LIST = "[Patients API] Fetch Patient List",
  FETCH_PATIENT_LIST_SUCCESS = "[Patients API] Fetch Patient List Success",
  FETCH_PATIENT_LIST_FAILURE = "[Patients API] Fetch Patient List Failure",

  FETCH_PATIENT = "[Patients API] Fetch Patient",
  REFETCH_PATIENT = "[Patients API] Refetch Patient",
  FETCH_PATIENT_SUCCESS = "[Patients API] Fetch Patient Success",
  FETCH_PATIENT_FAILURE = "[Patients API] Fetch Patient Failure",

  FETCH_XVWEB_PATIENT = "[Patient Home] Fetch XVWeb Patient",
  FETCH_XVWEB_PATIENT_SUCCESS = "[Patient Home] Fetch XVWeb Patient Success",
  FETCH_XVWEB_PATIENT_FAILURE = "[Patient Home] Fetch XVWeb Patient Failure",

  FETCH_MOST_RECENT_PATIENTS = "[Patient Search] Fetch Most Recent Patients",
  FETCH_MOST_RECENT_PATIENTS_SUCCESS = "[Patient Search] Fetch Most Recent Patients Success",
  FETCH_MOST_RECENT_PATIENTS_FAILURE = "[Patient Search] Fetch Most Recent Patients Failure",

  FETCH_PATIENT_IMAGE_FINDINGS = "[Patients API] Fetch Patient Image Findings",
  FETCH_PATIENT_IMAGE_FINDINGS_SUCCESS = "[Patients API] Fetch Patient Image Findings Success",
  FETCH_PATIENT_IMAGE_FINDINGS_FAILURE = "[Patients API] Fetch Patient Image Findings Failure",

  CREATE_SESSION = "[Patient Home] Create Session",
  CREATE_SESSION_SUCCESS = "[Patient Home] Create Session Success",
  CREATE_SESSION_FAILURE = "[Patient Home] Create Session Failure",
}

export const fetchPatientList = createAction(
  ActionTypes.FETCH_PATIENT_LIST,
  props<{ officeId: string }>()
);
export const fetchPatientListSuccess = createAction(
  ActionTypes.FETCH_PATIENT_LIST_SUCCESS,
  props<{ patientEntities: Patient[] }>()
);
export const fetchPatientListFailure = createAction(
  ActionTypes.FETCH_PATIENT_LIST_FAILURE,
  props<{ error: any; officeId: string }>()
);

export const fetchPatient = createAction(
  ActionTypes.FETCH_PATIENT,
  props<{ patientId: string }>()
);
export const fetchPatientSuccess = createAction(
  ActionTypes.FETCH_PATIENT_SUCCESS,
  props<{
    patientData: Patient;
    imageEntities: Image[];
  }>()
);
export const fetchPatientFailure = createAction(
  ActionTypes.FETCH_PATIENT_FAILURE,
  props<{ error: any; patientId: string }>()
);

export const fetchXVWebPatientFromPatientHome = createAction(
  ActionTypes.FETCH_XVWEB_PATIENT,
  props<{ patientId: string }>()
);

export const fetchXVWebPatientFromPatientHomeSuccess = createAction(
  ActionTypes.FETCH_XVWEB_PATIENT_SUCCESS,
  props<{ patientData: Patient; imageEntities: Image[] }>()
);

export const fetchXVWebPatientFromPatientHomeFailure = createAction(
  ActionTypes.FETCH_XVWEB_PATIENT_FAILURE,
  props<{ error: any; patientId: string }>()
);

export const fetchMostRecentPatients = createAction(
  ActionTypes.FETCH_MOST_RECENT_PATIENTS,
  props<{ officeId: string }>()
);

export const fetchMostRecentPatientsSuccess = createAction(
  ActionTypes.FETCH_MOST_RECENT_PATIENTS_SUCCESS,
  props<{ patientEntities: Patient[] }>()
);

export const fetchMostRecentPatientsFailure = createAction(
  ActionTypes.FETCH_MOST_RECENT_PATIENTS_FAILURE,
  props<{ error: any; officeId: string }>()
);

export const createSession = createAction(
  ActionTypes.CREATE_SESSION,
  props<{ patientId: string; images: File[] }>()
);

export const createSessionSuccess = createAction(
  ActionTypes.CREATE_SESSION_SUCCESS,
  props<{ patientId: string; imageIds: string[] }>()
);

export const createSessionFailure = createAction(
  ActionTypes.CREATE_SESSION_FAILURE,
  props<{ error: any }>()
);

export const errorActions = [
  fetchPatientListFailure,
  fetchPatientFailure,
  fetchXVWebPatientFromPatientHomeFailure,
  fetchMostRecentPatientsFailure,
];
