import { ElementRef, Injectable } from "@angular/core";
import { SizingPriority } from "../../models/public_api";

/**
 * Service responsible for performing canvas resize calculations.
 *
 * This service contains calculation-only logic that is expected to be used
 * by the [[`CanvasResizeService`]].
 *
 * @category
 * Service
 *
 * @internal
 */
@Injectable({
  providedIn: "root",
})
export class CanvasResizeInternalService {
  constructor() {}

  /**
   * Determines the best width and height of the canvas, such that
   *   1. it is 100% displayed within the window, and
   *   2. it is as large as possible provided it stays within the boundaries
   *        of this component.
   *
   * @param widthOverHeightAspectRatio
   *     provide the current image's aspect ratio, which this calculation depends upon.
   *
   * @returns the best height and width of this canvas as of the time this
   *     method is invoked, in pixels.
   */
  static _computeEffectiveCanvasDimensions(args: {
    priority: SizingPriority;
    heightConstraint: number;
    widthConstraint: number;
    widthOverHeightAspectRatio: number;
  }): { height: number; width: number } {
    const {
      priority,
      widthOverHeightAspectRatio,
      heightConstraint,
      widthConstraint,
    } = args;


    const _dimensionCandidates = {
      fill: {
        height: heightConstraint,
        width: widthConstraint,
      },

      heightMajor: {
        height: heightConstraint,
        width: heightConstraint * widthOverHeightAspectRatio,
      },
      widthMajor: {
        width: widthConstraint,
        height: widthConstraint / widthOverHeightAspectRatio,
      },
    };

    if (priority === SizingPriority.Fill) {
      return _dimensionCandidates.fill;
    }

    if (priority === SizingPriority.Row) {
      const isVertical = widthOverHeightAspectRatio < 1;

      if (isVertical) {
        // calculate the image's height if it was rotated to horizontal.
        const reconciledHeight = widthConstraint * widthOverHeightAspectRatio;
        return {
          height: reconciledHeight,
          width: reconciledHeight * widthOverHeightAspectRatio,
        };
      }

      return _dimensionCandidates.widthMajor;
    }

    if (priority === SizingPriority.SameSize) {
      const isVertical = widthOverHeightAspectRatio < 1;

      if (isVertical) {
        // use the width of the horizontal image as the height of the vertical image
        const effectiveHeight = _dimensionCandidates.widthMajor.width;

        return {
          width: effectiveHeight * widthOverHeightAspectRatio,
          height: effectiveHeight,
        };
      }

      return _dimensionCandidates.widthMajor;
    }

    if (priority === SizingPriority.Height) {
      if (_dimensionCandidates.heightMajor.width <= widthConstraint)
        return _dimensionCandidates.heightMajor;
      return _dimensionCandidates.widthMajor;
    }

    if (_dimensionCandidates.widthMajor.height <= heightConstraint)
      return _dimensionCandidates.widthMajor;
    return _dimensionCandidates.heightMajor;
  }

  /**
   * Determines the effective max width and height constraints of the canvas.
   *
   * @param args
   *   - containerRef: an `ElementRef` to the canvas's container (immediate parent)
   *   - userConfigured.maxHeight: the height constraint specified by the caller
   *   - userConfigured.maxWidth: the width constraint specified by the caller
   *
   * @returns the effective width and height constraints.
   */
  static _resolveEffectiveSizeConstraints(args: {
    containerRef: ElementRef;
    userConfigured: { maxHeight: number; maxWidth: number };
  }) {
    const { containerRef, userConfigured } = args;

    const {
      clientHeight: containerHeight,
      clientWidth: containerWidth,
    } = containerRef.nativeElement;

    const heightConstraint = Math.min(
      containerHeight,
      userConfigured.maxHeight
    );
    const widthConstraint = Math.min(containerWidth, userConfigured.maxWidth);

    return {
      widthConstraint,
      heightConstraint,
    };
  }
}
