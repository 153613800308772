<div class="container">
  <div class="form-wrapper">
    <form [formGroup]="editForm">
      <mat-form-field appearance="fill">
        <mat-label>Tooth</mat-label>
        <input
          #tooth_field
          matInput
          type="number"
          formControlName="tooth"
          autocomplete="off"
        />
      </mat-form-field>

      <div class="error-container" *ngIf="editFormTooth?.errors">
        <span *ngIf="editFormTooth?.dirty && editFormTooth?.errors?.required">
          <i class="fa fas fa-exclamation-triangle"></i> Must specify a tooth.
        </span>

        <span *ngIf="editFormTooth?.errors?.invalidNumber">
          <i class="fa fas fa-exclamation-triangle"></i> Tooth number must be
          within 1 - 32. Zero indicates unknown.
        </span>

        <span *ngIf="editFormTooth?.errors?.noLeadingZeroes">
          <i class="fa fas fa-exclamation-triangle"></i> Tooth number cannot be
          0.
        </span>

        <span *ngIf="editFormTooth?.errors?.noNegativeValues">
          <i class="fa fas fa-exclamation-triangle"></i> Tooth value cannot be
          negative.
        </span>
      </div>
    </form>
  </div>
  <div class="edit-label-btns">
    <button
      mat-flat-button
      class="save"
      color="primary"
      [ngClass]="{ disabled: !editForm.valid }"
      [disabled]="!editForm.valid || (isImageConfirmed$ | async)"
      (click)="onSaveClicked()"
    >
      Save
    </button>
    <button mat-stroked-button class="cancel" (click)="onCancelClicked()">
      Cancel
    </button>

    <button
      *ngIf="showDeletButton"
      mat-stroked-button
      class="delete"
      [disabled]="isImageConfirmed$ | async"
      (click)="onDeleteClicked()"
    >
      Delete
    </button>
  </div>
</div>
