import { Injectable } from "@angular/core";
import {
  BoneLossSeverityTypes,
  CariesStageTypes,
  FindingType,
  RenderableFindingBase,
} from "@kells/interfaces/finding";
import { LabelBoxColors } from "../models/label-box.model";

@Injectable({
  providedIn: "root",
})
export class CanvasFindingService {
  constructor() {}

  /**
   * Given a finding, return the color that should be used for rendering
   * its label box.
   *
   * @param finding the finding to resolve label box color for.
   * @returns the color for caries in their Initial stage by default, if the
   *     input is undefined or the finding does not have a `stage`. Otherwise,
   *     the box's color will be resolved depending on the finding's severity.
   */
  static resolveLabelBoxColor<F extends RenderableFindingBase>(
    finding: F
  ): LabelBoxColors {
    if (!finding) {
      return LabelBoxColors.CariesInitial;
    }
    let color: LabelBoxColors = LabelBoxColors.CariesInitial;
    switch (finding.type) {
      case FindingType.Tooth:
      case FindingType.Calculus:
      case FindingType.Fracture:
      case FindingType.Infection:
      case FindingType.DefectiveRestoration:
      case FindingType.Plaque:
      case FindingType.GumRecession:
      case FindingType.GumInflammation:
      case FindingType.MissingTooth:
        color = LabelBoxColors.Tooth;
        break;
      case FindingType.Material:
        color = LabelBoxColors.Material;
        break;
      case FindingType.Caries:
        if (!finding.stage) {
          color = LabelBoxColors.CariesInitial;
        } else {
          color = CanvasFindingService.resolveCarriesStageColor(finding.stage);
        }
        break;
      case FindingType.BoneLoss:
        if (finding.bone_loss_attributes) {
          color = CanvasFindingService.resolveBoneLossSeverityColor(
            finding.bone_loss_attributes.severity as string
          );
        }
        break;
      default:
        color = LabelBoxColors.CariesInitial;
    }
    return color;
  }

  static resolveCarriesStageColor(stage: string) {
    switch (stage) {
      case CariesStageTypes.Moderate:
        return LabelBoxColors.CariesModerate;
      case CariesStageTypes.Advanced:
        return LabelBoxColors.CariesAdvanced;
      case CariesStageTypes.Initial:
      default:
        return LabelBoxColors.CariesInitial;
    }
  }

  static resolveBoneLossSeverityColor(severity: string) {
    switch (severity) {
      case BoneLossSeverityTypes.Moderate:
        return LabelBoxColors.BonelossModerate;
      case BoneLossSeverityTypes.Advanced:
        return LabelBoxColors.BonelossAdvanced;
      case BoneLossSeverityTypes.Initial:
      default:
        return LabelBoxColors.BonelossInitial;
    }
  }

  static isCancelMode = false;
  static getIsCancelMode = () => {
    return CanvasFindingService.isCancelMode;
  };

  static setIsCancelMode = () => {
    CanvasFindingService.isCancelMode = true;
    setTimeout(() => {
      CanvasFindingService.isCancelMode = false;
    }, 2000);
  };
}
