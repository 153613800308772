import { createAction, props } from "@ngrx/store";
import { Image } from "@kells/clinic-one/apis";

enum ActionTypes {
  IMAGE_DETAIL_ROTATE_LEFT = "[Image Detail Page] Rotate Image Left",
  IMAGE_DETAIL_ROTATE_LEFT_SUCCESS = "[Image Detail Page] Rotate Image Left Success",
  IMAGE_DETAIL_ROTATE_LEFT_FAILURE = "[Image Detail Page] Rotate Image Left Failure",

  IMAGE_DETAIL_ROTATE_RIGHT = "[Image Detail Page] Rotate Image Right",
  IMAGE_DETAIL_ROTATE_RIGHT_SUCCESS = "[Image Detail Page] Rotate Image Right Success",
  IMAGE_DETAIL_ROTATE_RIGHT_FAILURE = "[Image Detail Page] Rotate Image Right Failure",

  IMAGE_SESSION_ROTATE_LEFT = "[Image Session] Rotate Images Left",
  IMAGE_SESSION_ROTATE_LEFT_SUCCESS = "[Image Session] Rotate Images Left Success",
  IMAGE_SESSION_ROTATE_LEFT_FAILURE = "[Image Session] Rotate Images Left Failure",

  IMAGE_SESSION_ROTATE_RIGHT = "[Image Session] Rotate Images Right",
  IMAGE_SESSION_ROTATE_RIGHT_SUCCESS = "[Image Session] Rotate Images Right Success",
  IMAGE_SESSION_ROTATE_RIGHT_FAILURE = "[Image Session] Rotate Images Right Failure",
}

export const rotateImageLeft = createAction(
  ActionTypes.IMAGE_DETAIL_ROTATE_LEFT,
  props<{ imageId: string; templateId?: number }>()
);

export const rotateImageLeftSuccess = createAction(
  ActionTypes.IMAGE_DETAIL_ROTATE_LEFT_SUCCESS,
  props<{ image: Image }>()
);

export const rotateImageLeftFailure = createAction(
  ActionTypes.IMAGE_DETAIL_ROTATE_LEFT_FAILURE,
  props<{ error: any }>()
);

export const rotateImageRight = createAction(
  ActionTypes.IMAGE_DETAIL_ROTATE_RIGHT,
  props<{ imageId: string; templateId?: number }>()
);

export const rotateImageRightSuccess = createAction(
  ActionTypes.IMAGE_DETAIL_ROTATE_RIGHT_SUCCESS,
  props<{ image: Image }>()
);

export const rotateImageRightFailure = createAction(
  ActionTypes.IMAGE_DETAIL_ROTATE_RIGHT_FAILURE,
  props<{ error: any }>()
);

export const leftRotateMultipleImages = createAction(
  ActionTypes.IMAGE_SESSION_ROTATE_LEFT,
  props<{ imageIds: string[] }>()
);

export const leftRotateMultipleImagesSuccess = createAction(
  ActionTypes.IMAGE_SESSION_ROTATE_LEFT_SUCCESS,
  props<{ images: Image[] }>()
);

export const leftRotateMultipleImagesFailure = createAction(
  ActionTypes.IMAGE_SESSION_ROTATE_LEFT_FAILURE,
  props<{ error: any }>()
);

export const rightRotateMultipleImages = createAction(
  ActionTypes.IMAGE_SESSION_ROTATE_RIGHT,
  props<{ imageIds: string[] }>()
);

export const rightRotateMultipleImagesSuccess = createAction(
  ActionTypes.IMAGE_SESSION_ROTATE_RIGHT_SUCCESS,
  props<{ images: Image[] }>()
);

export const rightRotateMultipleImagesFailure = createAction(
  ActionTypes.IMAGE_SESSION_ROTATE_RIGHT_FAILURE,
  props<{ error: any }>()
);

export const errorActions = [
  rotateImageLeftFailure,
  rotateImageRightFailure,
  leftRotateMultipleImagesFailure,
  rightRotateMultipleImagesFailure,
];
