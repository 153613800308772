import { ElementRef, Injectable } from "@angular/core";
import { KellsFabric } from "@kells/shared-ui/canvas";
import { isNullable } from "@kells/utils/js";
import { takeLatest } from "@kells/utils/observable/observable-operators";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { CanvasService } from "./canvas.service";
// import { ModalService } from "@kells/utils/angular";
import BoneLossObject from "../components/finding-fabrics/bone-loss-object";
/**
 * Describe possible display states for tools related to the active finding.
 *
 * Active finding is the finding the user is currently working with, either
 * via mouse hovers, or other interactions.
 *
 * Note that these states are _mutually exclusive_. For instance, tooltips
 * and edit forms should not be shown at the same time.
 */
enum ActiveFindingDisplayState {
  /** Show a tooltip that describes a finding, with edit and delete buttons. */
  ShowTooltip,
  /** Show a form where user can change, add, update finding attributes. */
  ShowEditForm,
  /** Do not show tooltip or edit form. */
  HideTools,
}


/**
 * This service is responsible for managing the contexts around the hovering
 * edit form associated with a label box.
 *
 * Its responsiblities include, but are not limited to:
 *   - displaying / hiding edit form
 *   - displaying / hiding label box tooltip
 */
@Injectable()
export class CanvasEditToolsService {
  // The following variables are represent sizes of HTML elements related
  // to the positioning of the edit toolbar. Since sizes listed below
  // could become out-of-date as the codebase evolves, consult the CSS
  // file for the latest sizes and update the variables below if
  // they become out-of-date.
  private readonly nativeElementSizes = {
    editForm: {
      height: 147,
    },
    editTooltip: {
      height: 31,
    },
  };

  private shouldShowActiveFindingTools$ = new BehaviorSubject(
    ActiveFindingDisplayState.HideTools
  );

  private activeFindingType$ = new Subject<string>();


  private canvas$ = new ReplaySubject<fabric.Canvas>();

  private editToolContainerRef$ = new ReplaySubject<ElementRef>();

  /** Whether the edit form should be displayed. */
  isEditFormShown$ = this.shouldShowActiveFindingTools$.pipe(
    map((state) => state === ActiveFindingDisplayState.ShowEditForm)
  );

  /** Whether the edit tooltip should be displayed. */
  isEditTooltipShown$ = this.shouldShowActiveFindingTools$.pipe(
    map((state) => state === ActiveFindingDisplayState.ShowTooltip)
  );

  constructor() {}

  /**
   * Initialize the `CanvasEditToolsService`.
   *
   * The service's other methods and properties should not be invoked before
   * this method is callled.
   *
   * @param args
   *    - `canvasRef`:
   *        A fabric.Canvas instance where the label boxes are drawn.
   *        The service will use this reference to calculate where label box
   *        tooltip and edit forms will be positioned.
   *
   *    - `editToolContainerRef`:
   *        An angular ElementRef. The ElementRef should point to the an HTML
   *        page element that contains the edit tooltip and the edit form.
   */
  init(args: { canvasRef: fabric.Canvas; editToolContainerRef: ElementRef<HTMLDivElement> }) {
    this.canvas$.next(args.canvasRef);
    this.editToolContainerRef$.next(args.editToolContainerRef);
  }

  /**
   * Show the tooltip that includes a finding's label (textual description).
   */
  showEditTooltipFor(targetObject: KellsFabric.Object | undefined | null) {
    if (isNullable(targetObject)) {
      return;
    }

    this.canvas$.pipe(takeLatest()).subscribe((canvas) => {
      this._placeEditTooltipNextTo(canvas, targetObject);

      this.shouldShowActiveFindingTools$.next(
        ActiveFindingDisplayState.ShowTooltip
      );
    });
  }

  /**
   * Show an edit form where a finding's attributes can be modified.
   * this.modalService.open(AppModalComponent,
   */
  showEditFormFor(targetObject: KellsFabric.Object | null | undefined) {
    if (isNullable(targetObject)) return;
    if (this.isEditFormShown) return;
    this.canvas$.pipe(takeLatest()).subscribe((canvas) => {
     this._placeEditFormNextTo(canvas, targetObject);

      this.shouldShowActiveFindingTools$.next(
        ActiveFindingDisplayState.ShowEditForm
      );

    });
  }

  showBonelossEditForm(ctrl:BoneLossObject) {
    if (isNullable(ctrl.object)) return;
    if (this.isEditFormShown) return;
    this.canvas$.pipe(takeLatest()).subscribe((canvas) => {
      this._placeEditFormNextTo(canvas, ctrl.object);

      this.shouldShowActiveFindingTools$.next(
        ActiveFindingDisplayState.ShowEditForm
      );

    });

  }

  /**
   * Hide the tools for editing a finding (the tooltip and the edit form).
   */
  hideEditTools() {
    if (this.isEditFormShown) {
      this.shouldShowActiveFindingTools$.next(
        ActiveFindingDisplayState.HideTools
      );
    }

  }

  /**
   * Whether the edit form is displayed.
   * It is the synchronous counterpart of `isEditFormShown$`.
   */
  get isEditFormShown() {
    return (
      this.shouldShowActiveFindingTools$.value ===
      ActiveFindingDisplayState.ShowEditForm
    );
  }

  /**
   * @internal
   */
  _placeEditFormNextTo(
    canvas: KellsFabric.Canvas,
    targetBox: KellsFabric.Object | null | undefined
  ) {
    try {
      const {
        targetBoxOcoords,
        canvasWidth,
        canvasHeight,
        canvasLeftOffset,
        canvasTopOffset,
      } = CanvasEditToolsService._unwrapEditToolPositionUpdateArgs(
        canvas,
        targetBox?.ctrl.getEditFormPoint()
      );

      const { editForm } = this.nativeElementSizes;

      // add additional space between edit form and the label box
      const editFormTopPadding = 40;
      const rightMargin = canvasWidth + canvasLeftOffset - 328 - 75;
      let left = targetBoxOcoords.bl.x + canvasLeftOffset;
      if (left < canvasLeftOffset) left = canvasLeftOffset;
      if (left > rightMargin ) {
        left = rightMargin
      }


      let top = targetBoxOcoords.bl.y + canvasTopOffset + editFormTopPadding;
      if (top < canvasTopOffset) top = canvasTopOffset + editFormTopPadding;

      // If the edit form's lower border will go out of the canvas's bottom
      // border, reposition the edit form to be at the top of the finding box.
      if (top + editForm.height > canvasHeight + canvasTopOffset) {
        top =
          targetBoxOcoords.tl.y +
          canvasTopOffset -
          editForm.height -
          editFormTopPadding;
      }

      //return {left, top};
      this.updateEditToolsPosition({ left, top });
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * @internal
   */
  private _placeEditTooltipNextTo(
    canvas: KellsFabric.Canvas,
    targetBox: KellsFabric.Object | null | undefined
  ) {
    try {
      const {
        targetBoxOcoords,
        canvasLeftOffset,
        canvasTopOffset,
        canvasHeight,
      } = CanvasEditToolsService._unwrapEditToolPositionUpdateArgs(
        canvas,
        targetBox?.ctrl.getEditFormPoint()
      );

      const { editTooltip } = this.nativeElementSizes;

      let left = targetBoxOcoords.bl.x + canvasLeftOffset;
      if (left < canvasLeftOffset) left = canvasLeftOffset;

      let top = targetBoxOcoords.bl.y + canvasTopOffset;
      if (top < canvasTopOffset) top = canvasTopOffset;

      // If the edit toolbars's lower border will go out of the canvas,
      // reposition it to the top of the finding box.
      if (top + editTooltip.height > canvasHeight + canvasTopOffset) {
        top = targetBoxOcoords.tl.y + canvasTopOffset - editTooltip.height;
      }
     // return { left, top };
      this.updateEditToolsPosition({ left, top });
    } catch (err) {
      console.error(err);
    }
  }

  private static _unwrapEditToolPositionUpdateArgs(
    canvas: KellsFabric.Canvas,
    targetBox: KellsFabric.Object | null | undefined
  ) {
    if (!targetBox || !targetBox.oCoords) {
      throw new Error(
        `
        Edit tools position procedure aborted:
        finding box provided is nullable or invalid: ${JSON.stringify(
          targetBox
        )}
      `
      );
    }

    const canvasLeftOffset = CanvasService.getCanvasLeftOffset(canvas);
    const canvasTopOffset = CanvasService.getCanvasTopOffset(canvas);

    if (
      isNullable(canvas) ||
      isNullable(canvasLeftOffset) ||
      isNullable(canvasTopOffset)
    ) {
      throw new Error(`
        Edit tools position procedure aborted:
        cannot position edit tools when the canvas is uninitialized.
      `);
    }


    return {
      targetBox,
      targetBoxOcoords: targetBox.oCoords,
      canvasWidth: canvas.getWidth(),
      canvasHeight: canvas.getHeight(),
      canvasLeftOffset,
      canvasTopOffset,
    };
  }

  private updateEditToolsPosition(pos: { left: number; top: number }) {
    this.editToolContainerRef$.pipe(takeLatest()).subscribe((editTools) => {
      CanvasService.updateNativeElementStyle(editTools, {
        // left: `${pos.left}px`,
        // top: `${pos.top}px`,
          left: `4px`,
          top: `400px`,
      });
    });
  }
}
