import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { pick } from "lodash-es";
import { MatDialog } from "@angular/material/dialog";
import { ZocDocService } from "../../../services/zocdoc.service";
import { DatePipe, DOCUMENT } from "@angular/common";
import { isDefined, isNullable } from "@kells/utils/js";
import {
  MetadataService,
  PageMetadata,
  ProactiveMessage,
  ReportService,
  VoiceflowService,
} from "../../../services";
import { SubSink } from "subsink";
import { BehaviorSubject, fromEvent, Observable, of } from "rxjs";
import { debounceTime, filter, map, switchMap, take } from "rxjs/operators";
import {
  ImageType,
  TREATMENT_PRIORITY_BY_TYPE,
  TreatmentService,
  TreatmentTypes,
  WHOLE_MOUTH_TREATMENT_LABEL,
} from "@kells/clinic-one/apis";
import {
  findingsByImage,
  images,
  treatmentPlanTableColumns,
  treatments,
  sessionScore,
  snapshot,
  findingsByTooth,
  productRecommendationTabs,
} from "./mocks";
import { AnalyticsService } from "@kells/apis/analytics";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import * as ReportEvents from "apps/patient-reporting/src/app/shared/models/analytics/report-page.event";
import {
  distinctUntilDeepValueChanged,
  keepDefined,
} from "@kells/utils/observable/observable-operators";
import {
  CariesStageTypes,
  FindingsByImage,
  FindingsByTooth,
  FindingType,
  FindingTypeLabels,
  KellsFinding,
  RenderableFindingBase,
} from "@kells/interfaces/finding";
import { ToothService } from "@kells/interfaces/tooth";
import { uniqBy } from "lodash";
import { VideoDialogComponent } from "@app/shared/components/video-modal/video-dialog.component";

const VOICEFLOW_PROACTIVE_MESSAGES: ProactiveMessage[] = [
  {
    type: "text",
    payload: {
      message: "Have a question about the report?",
    },
  },
  {
    type: "text",
    payload: {
      message: "Click on the chat to ask",
    },
  },
];

const META_DATA: PageMetadata = {
  title: "KELLS Oral Health Report Demo",
  description: "KELLS Oral Health Report Demo",
  image: "/assets/demo/og.png",
};

@Component({
  selector: "koa-demo-patient-report-page",
  templateUrl: "./demo-patient-report-page.component.html",
  styleUrls: [
    "../punch-patient-report-page.component.scss",
    "./demo-patient-report-page.component.scss",
  ],
})
export class DemoPatientReportPageComponent implements OnInit, AfterViewInit {
  public readonly SNAPSHOT_SECTION_ID = "snapshot";
  public readonly CAVITIES_SECTION_ID = "cavities";
  public readonly BONE_HEALTH_SECTION_ID = "bonehealth";
  public readonly SMILE_CHECKLIST_SECTION_ID = "smilechecklist";
  public readonly FIND_A_DENTIST_SECTION_ID = "find-dentist-section";
  PRODUCT_RECOMMENDATION_SECTION_ID = "product-recommendation";

  CARIES_INITIAL_DIAGNOSIS = `With the proper care you can prevent this from getting bigger. Initial cavities can often be taken care of without a filling.`;
  CARIES_INITIAL_INFO_TITLE = "Initial Cavity";
  CARIES_INITIAL_INFO =
    "An initial cavity is the start of breakdown in the tooth occuring. With proper brushing a flossing habit the breakdown can be reversed.";

  CARIES_MODERATE_DIAGNOSIS =
    "At this point you may start to have sensitivity in the tooth. This is a sign that the tooth may need a filling.";
  CARIES_MODERATE_INFO_TITLE = "Moderate Cavity";
  CARIES_MODERATE_INFO =
    "A moderate cavity is a breakdown that has reached the inner layer of your tooth. Cavities in this section of the tooth mean that you will need a dental filling or crown to replace the damaged tooth.";

  CARIES_ADVANCED_DIAGNOSIS =
    "You may notice food getting stuck in your tooth and pain starting to occur. Your tooth may need immediate attention. Your dentist may recommend a crown and depending on the extent of the cavity it may also need a root canal.";
  CARIES_ADVANCED_INFO_TITLE = "Advanced Cavity";
  CARIES_ADVANCED_INFO =
    "An advanced cavity is breakdown that has occurred in the tooth almost reaching or has reached the innermost portion of the tooth. This will require immediate attention and delaying treatment can lead to infection or tooth loss.";

  FINDING_DESCRIPTION_INFO: Partial<
    Record<
      FindingType,
      {
        stage: string;
        title: string;
        infoText: string;
      } | null
    >
  > = {
    [FindingType.Plaque]: {
      stage: "advanced",
      title: "Plaque",
      infoText:
        "Plaque is a sticky film of bacteria that forms on the teeth. It is a colorless and sticky substance that continuously forms on the teeth and gums. If not removed through proper oral hygiene practices such as brushing and flossing, plaque can harden and turn into tartar, which can lead to tooth decay and gum disease.",
    },
    [FindingType.GumRecession]: {
      stage: "advanced",
      title: "Gum Recession",
      infoText:
        "Gum recession is where the gum tissue that surrounds and supports the teeth wears away or pulls back, exposing the roots of the teeth. Gum recession can lead to tooth sensitivity, an increased risk of tooth decay, and even tooth loss if left untreated.",
    },
    [FindingType.GumInflammation]: {
      stage: "advanced",
      title: "Gum Inflammation",
      infoText:
        "Gum Inflammation, also known as gingivitis, is a condition where the gums become red, swollen, and can bleed easily. It is typically caused by bacteria accumulating on the teeth and gums. If left untreated, it can lead to a more severe form of gum disease known as periodontitis, which can damage the bones and tissues that support the teeth.",
    },
    [FindingType.MissingTooth]: {
      stage: "advanced",
      title: "Missing Tooth",
      infoText: "",
    },
    [FindingType.Fracture]: {
      stage: "advanced",
      title: "Fracture",
      infoText:
        "A dental fracture refers to a break or crack in a tooth. When a tooth is fractured, it can cause pain, sensitivity, and difficulty while eating or speaking.",
    },
    [FindingType.Calculus]: {
      stage: "advanced",
      title: "Calculus",
      infoText: "",
    },
    [FindingType.Infection]: {
      stage: "advanced",
      title: "Infection",
      infoText: "",
    },
    [FindingType.DefectiveRestoration]: {
      stage: "advanced",
      title: "Defective Restoration",
      infoText: "",
    },
  };

  @Input() isFindingDentistAvailable = true;

  @ViewChild("scrollFrame", { static: false })
  scrollFrameRef: ElementRef<HTMLDivElement>;
  @ViewChild("pageHeader", { static: false })
  pageHeaderRef: ElementRef<HTMLDivElement>;
  @ViewChild("snapshot") snapshotSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("cavities") cavitiesSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("xrayCarousel") xrayCarouselRef: ElementRef<HTMLDivElement>;
  @ViewChild("bonehealth") bonehealthSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("smilechecklist")
  smileChecklistSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("heroSection") heroSection: ElementRef<HTMLDivElement>;
  @ViewChild("sections") sectionsRef: ElementRef<HTMLDivElement>;
  @ViewChild("findDentistSection")
  findDentistSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("productRecommendationSection")
  productRecommendationSectionRef: ElementRef<HTMLDivElement>;
  @ViewChild("searchWidgetContainer")
  searchWidgetContainerRef: ElementRef<HTMLDivElement>;
  @ViewChild("reportImageGeneration")
  reportImageGenerationRef: ElementRef<HTMLDivElement>;
  @ViewChild("questionsSection")
  questionsSectionRef: ElementRef<HTMLDivElement>;

  public readonly REPORT_LOGO = "/assets/punch/images/kells-logo-2.svg";

  public readonly sessionDate = new Date();

  public currentSection = "snapshot";

  public readonly productRecommendationTabs = productRecommendationTabs;

  public readonly SessionImageType = ImageType;

  private _subs = new SubSink();

  public readonly treamentPlanDisplayedTableColumns = treatmentPlanTableColumns;

  public readonly images = images;

  public readonly treatments = treatments;

  public readonly snapshotSubhead = snapshot.subhead;

  public readonly sessionScore = sessionScore;

  public readonly findingsByTooth = of(findingsByTooth);

  public activeTooth: BehaviorSubject<FindingsByTooth | null> = new BehaviorSubject<FindingsByTooth | null>(
    null
  );

  activeTooth$ = this.activeTooth.asObservable().pipe(
    keepDefined(),
    filter((t) => t !== null),
    distinctUntilDeepValueChanged()
  );

  public readonly activeToothFindings$ = this.activeTooth$.pipe(
    map((activeTooth) => activeTooth.allFindings)
  );

  public readonly activeToothFindingsDescriptions$ = this.activeToothFindings$.pipe(
    map((findings) => {
      const findingsDescription = findings
        .map((finding) => {
          return this.getFindingDescription(finding);
        })
        .filter(Boolean);

      const uniqueFindings = uniqBy(
        findingsDescription,
        (item) => `${item?.stage}_${item?.title}`
      );
      return uniqueFindings;
    })
  );

  activeToothFindingsImages$ = this.activeTooth$.pipe(
    switchMap((activeTooth) =>
      this.findingsByImage$.pipe(
        map((findingsByImage) => {
          if (!findingsByImage) {
            return activeTooth.xrays;
          }
          const images = activeTooth.xrays
            .map((url) => {
              const image = findingsByImage.find(
                (findingsByImage) => findingsByImage.image.url === url
              );

              if (!image) return;
              const {
                calculus,
                fracture,
                gumInflammation,
                missingTooth,
                gumRecession,
                infection,
                plaque,
                initial,
                moderate,
                advanced,
              } = image;
              const findings = [
                ...calculus,
                ...fracture,
                ...gumInflammation,
                ...missingTooth,
                ...gumRecession,
                ...infection,
                ...plaque,
                ...initial,
                ...moderate,
                ...advanced,
              ];
              const hasFindingsForSelected = findings.some(
                (finding) => finding.tooth === activeTooth.tooth_id
              );
              if (!hasFindingsForSelected) return;

              return url;
            })
            .filter(isDefined);

          return images;
        })
      )
    )
  );

  public readonly findingsByImage$ = of(findingsByImage) as Observable<
    FindingsByImage[]
  >;

  _cariesToothIllustrationSvg: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  cariesToothIllustrationSvg$ = this._cariesToothIllustrationSvg
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  selectedFindingsFilterType: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  selectedFindingsFilterType$ = this.selectedFindingsFilterType
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public readonly allFindings$ = this.findingsByImage$.pipe(
    map((findingsByImage: FindingsByImage[]) => {
      if (!findingsByImage.length) {
        return [];
      }
      return findingsByImage.reduce(
        (acc: RenderableFindingBase[], imageFindings: FindingsByImage) => {
          const flatFindings: RenderableFindingBase[] =
            imageFindings.allFindings;
          return acc.concat(flatFindings);
        },
        []
      );
    })
  );

  public getFindingDescription(finding: RenderableFindingBase) {
    const advancedFindingTypes = [
      FindingType.Plaque,
      FindingType.GumRecession,
      FindingType.GumInflammation,
      FindingType.MissingTooth,
      FindingType.Fracture,
      FindingType.Calculus,
      FindingType.Infection,
      FindingType.DefectiveRestoration,
    ];

    if (finding.type === FindingType.Caries) {
      switch (finding.stage) {
        case CariesStageTypes.Advanced:
          return {
            stage: "advanced",
            title: this.CARIES_ADVANCED_INFO_TITLE,
            infoText: this.CARIES_ADVANCED_INFO,
          };

        case CariesStageTypes.Moderate:
          return {
            stage: "moderate",
            title: this.CARIES_MODERATE_INFO_TITLE,
            infoText: this.CARIES_MODERATE_INFO,
          };

        case CariesStageTypes.Initial:
          return {
            stage: "initial",
            title: this.CARIES_INITIAL_INFO_TITLE,
            infoText: this.CARIES_INITIAL_INFO,
          };
      }
    } else if (advancedFindingTypes.includes(finding.type)) {
      return this.FINDING_DESCRIPTION_INFO[finding.type];
    } else {
      return null;
    }
  }

  public readonly findingsFilterItems$ = this.allFindings$.pipe(
    map((allFindings: RenderableFindingBase[]) => {
      const advancedFindingTypes = [
        FindingType.Plaque,
        FindingType.GumRecession,
        FindingType.GumInflammation,
        FindingType.MissingTooth,
        FindingType.Fracture,
        FindingType.Calculus,
        FindingType.Infection,
        FindingType.DefectiveRestoration,
        FindingType.Caries,
      ];
      const allTypes = allFindings
        .map((findings) => findings.type)
        .filter((findingType) =>
          advancedFindingTypes.some((type) => type === findingType)
        );
      const types = [...new Set(allTypes)];
      const displayableTypes = types.map((type) => {
        return {
          type,
          name: FindingTypeLabels[type] ?? "",
        };
      });
      return displayableTypes;
    })
  );

  public readonly activeNonCariesToothFindings$ = this.activeTooth$.pipe(
    map((activeTooth) => {
      return activeTooth.allFindings.filter(
        (finding) => finding.type !== FindingType.Caries
      );
    })
  );

  public activeToothCariesStage: BehaviorSubject<string> = new BehaviorSubject<string>(
    "no"
  );
  activeToothCariesStage$ = this.activeToothCariesStage
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothDiagnosis: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  activeToothDiagnosis$ = this.activeToothDiagnosis
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothTitle: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  activeToothTitle$ = this.activeToothTitle
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  public activeToothTitleOrdn: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );

  CARIES_TAB_FINDINGS = "CARIES_TAB_FINDINGS";
  CARIES_TAB_XRAY = "CARIES_TAB_XRAY";
  CARIES_TAB_INTRAORAL = "CARIES_TAB_INTRAORAL";

  activeCariesTab: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.CARIES_TAB_FINDINGS
  );
  activeCariesTab$ = this.activeCariesTab
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  activeToothTitleOrdn$ = this.activeToothTitleOrdn
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  toothTreatments = new BehaviorSubject<any[]>([]);
  toothTreatments$ = this.toothTreatments.asObservable();

  setActiveCariesTab = (tab: string) => {
    this.activeCariesTab.next(tab);

    if (this.activeTooth.value) {
      this.setActiveCariesTooth(this.activeTooth.value);
    }
  };

  setActiveCariesTabXRay = (tab: string) => {
    this.setActiveCariesTab(tab);

    const event =
      tab === this.CARIES_TAB_INTRAORAL
        ? ReportEvents.DemoCariesIntraoralPhotoTabClick
        : ReportEvents.DemoCariesXRayTabClick;
    this.analyticsService.record(event({}));
  };

  constructor(
    private readonly datePipe: DatePipe,
    public readonly dialog: MatDialog,
    private readonly _zocdocService: ZocDocService,
    private readonly _renderer2: Renderer2,
    public readonly report: ReportService,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly voiceflowService: VoiceflowService,
    private readonly analyticsService: AnalyticsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly treatmentService: TreatmentService,
    private readonly metadataService: MetadataService
  ) {}

  ngOnInit() {
    this.initVoiceFlow();

    this.metadataService.setMetadata(META_DATA);
  }

  ngAfterViewInit() {
    this.loadZocDocSearchWidget();

    this._subs.sink = fromEvent(this.scrollFrameRef.nativeElement, "scroll")
      .pipe(debounceTime(100))
      .subscribe((event) => this.setActiveSection(event));
  }

  splitOrdinalsToParts(label: string): string[] {
    let ordn: string | null = null;
    if (label.indexOf("1st") !== -1) {
      ordn = "1st";
    }
    if (label.indexOf("2nd") !== -1) {
      ordn = "2nd";
    }
    if (label.indexOf("3rd") !== -1) {
      ordn = "3rd";
    }
    if (ordn !== null) {
      const labelParts: string[] = label.split(ordn as string);
      return [ordn, labelParts[1]];
    }
    return [label];
  }

  splitOrdinals(label: string): void {
    const labelParts = this.splitOrdinalsToParts(label);
    if (labelParts.length > 1) {
      this.activeToothTitleOrdn.next("" + labelParts[0]);
      this.activeToothTitle.next(" " + labelParts[1]);
    } else {
      this.activeToothTitleOrdn.next("");
      this.activeToothTitle.next(labelParts[0]);
    }
  }

  _updateCarieTab(toothName: string, cariesStage?: string, diagnosis?: string) {
    this.splitOrdinals(toothName);
    cariesStage &&
      this.activeToothCariesStage.next(
        cariesStage === "moderate" ? "a " + cariesStage : "an " + cariesStage
      );
    diagnosis && this.activeToothDiagnosis.next(diagnosis);
  }

  setCariesState(tooth: FindingsByTooth) {
    if (tooth.qtyAdvanced > 0) {
      this._updateCarieTab(
        tooth.name.toLowerCase(),
        "advanced",
        this.CARIES_ADVANCED_DIAGNOSIS
      );
    } else if (tooth.qtyModerate > 0) {
      this._updateCarieTab(
        tooth.name.toLowerCase(),
        "moderate",
        this.CARIES_MODERATE_DIAGNOSIS
      );
    } else if (tooth.qtyInitial > 0) {
      this._updateCarieTab(
        tooth.name.toLowerCase(),
        "initial",
        this.CARIES_INITIAL_DIAGNOSIS
      );
    }
  }

  public hasVideo(treatmentPlanName: string): boolean {
    const videoList = this.report.getVideoList();
    const dentistryVideos = videoList?.length ? videoList[0].videos : [];
    const matchedVideo = dentistryVideos.find((video) =>
      video.id.includes(treatmentPlanName.toLowerCase().split(" ").join("_"))
    );

    return !!matchedVideo;
  }

  public openDialog(treatmentPlan: string): void {
    this.dialog.open(VideoDialogComponent, {
      width: "600px",
      data: { name: treatmentPlan },
    });
  }

  private toCostEstimateCellData(treatmentDescription: string): string {
    const costEst = this.treatmentService.lookupCostEstimates(
      treatmentDescription
    );
    if (
      isNullable(costEst) ||
      isNullable(costEst.costMin) ||
      isNullable(costEst.costMax)
    ) {
      return "-";
    }
    return `$${costEst.costMin} - $${costEst.costMax}`;
  }

  private parseTreatments = (tooth: FindingsByTooth) => {
    const reportFindings: KellsFinding[] = tooth.allFindings;
    const rows = tooth.treatments
      .filter((t) => {
        return (
          t.tooth === WHOLE_MOUTH_TREATMENT_LABEL ||
          Boolean(
            this.treatmentService.getFindingsForTreatment(t, reportFindings)
              .length
          )
        );
      })
      .map((t) => {
        const priority =
          TREATMENT_PRIORITY_BY_TYPE[t.description as TreatmentTypes];

        return {
          tooth: tooth.name,
          toothLocation: ToothService.formatToothLocation(t.tooth),
          treatmentPlan: this.treatmentService.formatTreatmentDescription(
            t.description
          ),
          costEstimates: this.toCostEstimateCellData(t.description),
          priority,
          priorityDesc: this.treatmentService.getTreatmentPriorityDescription(
            priority
          ),
        };
      });
    const uniqueTreatments = uniqBy(rows, (item) => item?.treatmentPlan);

    return uniqueTreatments.sort(
      (t1, t2) => (t2.priority ?? -1) - (t1.priority ?? -1)
    );
  };

  setActiveCariesTooth = (tooth: FindingsByTooth) => {
    this._cariesToothIllustrationSvg.next(
      `/assets/svgs/buccal/SVG/Tooth-${tooth.tooth_id}.svg`
    );

    this.activeTooth.next(tooth);

    this.activeNonCariesToothFindings$
      .pipe(take(1))
      .subscribe((activeNonCariesToothFindings) => {
        if (activeNonCariesToothFindings.length) {
          this._updateCarieTab(tooth.name.toLowerCase());
        } else {
          this.setCariesState(tooth);
        }
      });
    const treatments = tooth.treatments.filter(
      (t) => t.session === tooth.sessionId
    );
    tooth.treatments = treatments;

    const trmnts = this.parseTreatments(tooth);

    this.toothTreatments.next(trmnts);
    this.changeDetectorRef.detectChanges();
  };

  public setActiveSection(event: Event) {
    const section = event.target as HTMLDivElement;
    const pageYOffset =
      section.scrollTop + this.pageHeaderRef.nativeElement.offsetHeight || 0;
    let currentSection = "";

    if (pageYOffset < this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID)) {
      currentSection = this.SNAPSHOT_SECTION_ID;
    } else if (
      pageYOffset >= this.getSectionOffsetTopById(this.CAVITIES_SECTION_ID) &&
      pageYOffset < this.getSectionOffsetTopById(this.BONE_HEALTH_SECTION_ID)
    ) {
      currentSection = this.CAVITIES_SECTION_ID;
    } else if (
      pageYOffset >=
        this.getSectionOffsetTopById(this.BONE_HEALTH_SECTION_ID) &&
      pageYOffset <
        this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID)
    ) {
      currentSection = this.BONE_HEALTH_SECTION_ID;
    } else if (
      pageYOffset >=
        this.getSectionOffsetTopById(this.SMILE_CHECKLIST_SECTION_ID) &&
      pageYOffset <
        this.getSectionOffsetTopById(this.PRODUCT_RECOMMENDATION_SECTION_ID)
    ) {
      currentSection = this.SMILE_CHECKLIST_SECTION_ID;
    } else if (
      pageYOffset >=
        this.getSectionOffsetTopById(this.PRODUCT_RECOMMENDATION_SECTION_ID) &&
      pageYOffset < this.getSectionOffsetTopById(this.FIND_A_DENTIST_SECTION_ID)
    ) {
      currentSection = this.PRODUCT_RECOMMENDATION_SECTION_ID;
    } else if (
      pageYOffset >=
      this.getSectionOffsetTopById(this.FIND_A_DENTIST_SECTION_ID)
    ) {
      currentSection = this.FIND_A_DENTIST_SECTION_ID;
    }

    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
    }
  }

  getSectionElementById(id: string) {
    switch (id) {
      case this.SNAPSHOT_SECTION_ID:
        return this.snapshotSectionRef?.nativeElement;

      case this.CAVITIES_SECTION_ID:
        return this.cavitiesSectionRef?.nativeElement;

      case this.BONE_HEALTH_SECTION_ID:
        return this.bonehealthSectionRef?.nativeElement;

      case this.SMILE_CHECKLIST_SECTION_ID:
        return this.smileChecklistSectionRef?.nativeElement;

      case this.FIND_A_DENTIST_SECTION_ID:
        return this.findDentistSectionRef?.nativeElement;

      case this.PRODUCT_RECOMMENDATION_SECTION_ID:
        return this.productRecommendationSectionRef?.nativeElement;

      default:
        return null;
    }
  }

  getSectionOffsetTopById(id: string) {
    return this.getSectionElementById(id)?.offsetTop || 0;
  }

  scrollToSection(id: string) {
    if (!this.scrollFrameRef) return;

    const elYPosition = this.getSectionOffsetTopById(id);
    const pageHeaderHeight = this.pageHeaderRef.nativeElement.offsetHeight || 0;
    const scrollPosition = elYPosition - pageHeaderHeight;

    this.scrollFrameRef.nativeElement.scroll({
      top: scrollPosition,
      left: 0,
      behavior: "smooth",
    });
  }

  public formatDateString(date: Date | string, format = "MMMM d, y") {
    if (isNullable(date)) return "-";
    return this.datePipe.transform(date, format);
  }

  public onGetReportClick() {
    this.analyticsService.record(ReportEvents.DemoGetReportClick({}));
  }

  public onXrayOpen() {
    this.analyticsService.record(ReportEvents.DemoXRayView({}));
  }

  public recordToothClick(tooth: FindingsByTooth) {
    const toothInfo = pick(tooth, [
      "tooth_id",
      "toothAppearances",
      "totalCaries",
      "qtyAdvanced",
      "qtyInitial",
      "qtyModerate",
      "qtyMaterial",
      "qtyBoneLoss",
    ]);

    this.analyticsService.record(
      ReportEvents.DemoCariesToothClick({
        ...toothInfo,
      })
    );
  }

  private initVoiceFlow() {
    this.voiceflowService.initializeVoiceFlow(
      {
        first_name: "Olivia",
        last_name: "Smith",
      },
      VOICEFLOW_PROACTIVE_MESSAGES
    );
  }

  loadZocDocSearchWidget() {
    if (!this.searchWidgetContainerRef || !this.isFindingDentistAvailable)
      return;

    const widget = this._zocdocService.loadSearchWidget(
      this._renderer2,
      this.searchWidgetContainerRef
    );

    widget.onload = () => {
      const iframe = this._document.querySelector("iframe");
      // Remove border from iframe
      iframe && iframe.classList.add("zocdoc-iframe");
    };
  }
}
