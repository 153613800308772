<div
  class="canvas-bounding-container"
  #canvas_bounding_container
  [ngClass]="{ 'adjust-mode': isInAdjustMode$ | async }"
>
  <div class="overlay-positioning-container" id="overlay-positioning-container">
    <canvas #canvas></canvas>

    <div class="canvas-top-left-container">
      <ng-content select="[topLeftBadge]"></ng-content>
    </div>
  </div>
</div>

<div
  #label_box_tooltip
  class="label-box-tooltip"
  [ngClass]="{ hidden: !(isLabelTextTooltipShown$ | async) }"
>
  <span> Click to edit. </span>
</div>
<ng-template [cdkPortalOutlet]="domPortal"></ng-template>
<div #findingLabels id="labelLayer">
  <boneloss
    *ngFor="let blCtrl of boneLossFabricControllers | keyvalue"
    ctrl="blCtrl"
  ></boneloss>
</div>
<div #editTools id="edit-hover">
  <span *ngIf="shouldShowActiveFindingTooltip$ | async">
    <ng-container [ngTemplateOutlet]="findingProperties"></ng-container>
  </span>

  <span *ngIf="shouldShowCariesEditForm$ | async">
    <ng-container [ngTemplateOutlet]="cariesEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowToothEditForm$ | async">
    <ng-container [ngTemplateOutlet]="toothEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowMaterialEditForm$ | async">
    <ng-container [ngTemplateOutlet]="materialEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowBonelossEditForm$ | async">
    <ng-container [ngTemplateOutlet]="bonelossEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowFractureEditForm$ | async">
    <ng-container [ngTemplateOutlet]="fractureEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowCalculusEditForm$ | async">
    <ng-container [ngTemplateOutlet]="calculusEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowInfectionEditForm$ | async">
    <ng-container [ngTemplateOutlet]="infectionEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowDefectiveRestorationEditForm$ | async">
    <ng-container
      [ngTemplateOutlet]="defectiveRestorationEditForm"
    ></ng-container>
  </span>

  <span *ngIf="shouldShowPlaqueEditForm$ | async">
    <ng-container [ngTemplateOutlet]="plaqueEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowGumRecessionEditForm$ | async">
    <ng-container [ngTemplateOutlet]="gumRecessionEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowGumInflammationEditForm$ | async">
    <ng-container [ngTemplateOutlet]="gumInflammationEditForm"></ng-container>
  </span>

  <span *ngIf="shouldShowMissingToothEditForm$ | async">
    <ng-container [ngTemplateOutlet]="missingToothEditForm"></ng-container>
  </span>
</div>

<!-- <ng-template #findingProperties>
  <canvas-finding-label
    [finding]="activeFinding$ | async"
  ></canvas-finding-label>
</ng-template> -->

<ng-template #cariesEditForm>
  <canvas-finding-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-finding-edit-form>
</ng-template>

<ng-template #toothEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #materialEditForm>
  <canvas-material-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-material-edit-form>
</ng-template>

<ng-template #bonelossEditForm>
  <canvas-boneloss-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-boneloss-edit-form>
</ng-template>

<ng-template #fractureEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #calculusEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #infectionEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #defectiveRestorationEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #plaqueEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #gumRecessionEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #gumInflammationEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>

<ng-template #missingToothEditForm>
  <canvas-tooth-edit-form
    [initialData]="activeFinding$ | async"
    [showDeletButton]="!isCanvasInDrawingMode()"
    [imageConfirmed]="isImageConfirmed$ | async"
    (onSave)="saveFinding($event)"
    (onCancel)="cancelEdit()"
    (onDelete)="deleteActiveFinding()"
  ></canvas-tooth-edit-form>
</ng-template>
