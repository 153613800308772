import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { PatientRecommendation } from "@kells/clinic-one/apis";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import {
  distinctUntilDeepValueChanged,
  keepDefined,
} from "@kells/utils/observable/observable-operators";
import { isDefined, isNullable } from "@kells/utils/js";
import { DataAccessService } from "@kells/apis/data-access";
import {
  FindingsByTooth,
  PerioData,
  RenderableFindingBase,
  ToothIconSlot,
} from "@kells/interfaces/finding";
import { SubSink } from "subsink";
import { observeProperty } from "@kells/utils/angular";
import { ToothNumber, ToothService } from "@kells/interfaces/tooth";

export function calcSeverity(finding: RenderableFindingBase): number {
  return Number(
    finding?.bone_loss_attributes?.measurement_mm ??
      finding?.bone_loss_attributes?.measurement_pixel ??
      1
  );
}

export function calcMeasurement(bone_loss_attributes: PerioData): PerioData {
  //  let px: number = Math.sqrt(Math.hypot((bone_loss_attributes.ac_x - bone_loss_attributes.cej_x), (bone_loss_attributes.ac_y - bone_loss_attributes.cej_y)))
  //    bone_loss_attributes.measurement_pixel = Number(px).toFixed(2);
  //   const mm: string = Number(px * 0.26458333).toFixed(2);

  //    bone_loss_attributes.measurement_mm = mm;

  return bone_loss_attributes;
}
interface ToothIcon {
  /** The tooth number that is associated with this icon. */
  toothNumber: ToothNumber;
  /** The identifier assigned to the SVG representing this tooth. */
  iconIdentifier: string;
  /**
   * Color to be applied on this tooth icon. If not provided, use the default color.
   */

  /**
   * Color to be applied to this tooth icon's border. Use the default color if `undefined`.
   */
  strokeColor: string;
  toothColor: string;
  bonelossScore: number;
  dataColor: string;
  dataHeight: number | string;
  xlink: string;
}

@Component({
  selector: "koa-boneloss-chart",
  templateUrl: "./boneloss-chart.component.html",
  styleUrls: ["./boneloss-chart.component.scss"],
})
export class BoneLossChartComponent implements OnInit, OnDestroy {
  byTooth: Map<string, FindingsByTooth>;
  byTooth$: Observable<Map<string, FindingsByTooth>> = observeProperty(
    this,
    "byTooth"
  );
  private _subs = new SubSink();

  dataReady = false;
  constructor(private el: ElementRef, public data: DataAccessService) {}
  ngOnInit() {
    this._subs.sink = this.data.reportDataLoadComplete$.subscribe(
      this.onByToothSubscription
    );
  }

  public deepDiveText: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );
  public deepDiveText$ = this.deepDiveText
    .asObservable()
    .pipe(keepDefined(), distinctUntilDeepValueChanged());

  REC_INITIAL =
    "Based on your X-Rays, your bone levels look like where they need to be for someone in your age group.";

  REC_FAIR =
    "Based on your X-Rays, your bone level falls outside the boundaries of the expected bone level of someone in your age group. Your dentist may recommend a deep cleaning which can help prevent future bone loss from occurring. Follow up with a dentist to see how they can help you treat and prevent further damage.";

  REC_ADVANCED =
    "Based on your X-Rays, your bone has had a history of bone loss which is out of the normal range of someone in your age group. These areas will need to be evaluated by a dentist or periodontist. They may recommend a deep cleaning to prevent future bone loss from occurring. They may have a discussion with you about options that are available to you to restore the lost bone structure and ways to prevent further damage.";

  onByToothSubscription = ({
    report,
    findingsByTooth,
  }: Partial<{
    report?: PatientRecommendation;
    findingsByTooth: Map<string, FindingsByTooth>;
  }>) => {
    if (isDefined(findingsByTooth)) {
      this.byTooth = findingsByTooth;
      this._subs.sink = this.teethNumbers$.subscribe(() => {
        requestAnimationFrame(() => {
          this.dataReady = true;
        });
      });

      this._subs.sink = this.data.boneLossScore$
        .pipe(keepDefined(), distinctUntilChanged())
        .subscribe((bonelossScore: number) => {
          let statusText = "FAIR";
          if (bonelossScore < 3) {
            this.deepDiveText.next(this.REC_INITIAL);
            statusText = "GOOD";
          } else if (bonelossScore >= 5) {
            this.deepDiveText.next(this.REC_ADVANCED);
            statusText = "POOR";
          } else {
            this.deepDiveText.next(this.REC_FAIR);
          }
        });
    }
  };

  counter(i: number) {
    return new Array(i);
  }

  /**
   * A map where the keys are the selection identifiers, and the values are
   * hex color codes.
   */

  initActiveToothWatcher() {
    const { _subs } = this;
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private readonly teethNumbers: ToothIconSlot[] = ToothService.generateToothNumbersArray(
    {
      usePhysiologicalOrder: true,
    }
  ).map((toothNumber) => ({
    toothNumber,
    iconIdentifier: `BoneLoss-Tooth-${toothNumber}`,
    xlink: `#BoneLoss-Tooth-${toothNumber}`,
  }));

  topTeeth$: BehaviorSubject<ToothIcon[]> = new BehaviorSubject<ToothIcon[]>(
    []
  );
  bottomTeeth$: BehaviorSubject<ToothIcon[]> = new BehaviorSubject<ToothIcon[]>(
    []
  );

  getTooth(toothNum: number): FindingsByTooth | null {
    if (this.byTooth.has("" + toothNum)) {
      return this.byTooth.get("" + toothNum) as FindingsByTooth;
    }
    return null;
  }

  getColor(score: number): string {
    if (score < 2) {
      return "#f5c465";
    }
    return "#941B1C";
  }

  maxSeverity = 0;

  getBonelossScore(tooth: FindingsByTooth): number {
    let score = 0;
    let severity = 0.1;
    let maxSeverityAll = 0;

    if (tooth.qtyBoneLoss > 0) {
      tooth.boneLoss.forEach((boneData: RenderableFindingBase) => {
        boneData.bone_loss_attributes = calcMeasurement(
          boneData.bone_loss_attributes as PerioData
        );
        severity = calcSeverity(boneData);

        if (!severity) {
          calcSeverity(boneData);
        } else {
          if (severity > maxSeverityAll) {
            maxSeverityAll = severity;
          }
          score = maxSeverityAll;
        }
      });
    }
    if (maxSeverityAll > this.maxSeverity) {
      this.maxSeverity = maxSeverityAll;
    }
    return score;
  }

  processToothNumber(item: ToothIconSlot): ToothIcon {
    let toothColor: string;
    let dataColor = "#EBF1DE";
    let bonelossScore = 1;
    let tooth: FindingsByTooth;
    if (this.byTooth.has(String(item.toothNumber))) {
      tooth = this.byTooth.get(String(item.toothNumber)) as FindingsByTooth;
      if (!isNullable(tooth)) {
        bonelossScore = this.getBonelossScore(tooth) as number;
        dataColor = this.getColor(bonelossScore);
      }
    } else {
      bonelossScore = 0;
      dataColor = "#EBF1DE";
    }

    const scorePercent = (4 / 100) * bonelossScore;

    const dataHeight = bonelossScore > 0 ? 65 * (bonelossScore / 4) : 0;

    const toothIcon = {
      ...item,
      bonelossScore,
      toothColor: "#EBF1DE",
      dataColor: dataColor,
      dataHeight: dataHeight,
      strokeColor: "#315875",
    };
    return toothIcon;
  }

  teethNumbers$: Observable<ToothIcon[]> = this.byTooth$.pipe(
    map(() => {
      const icons: ToothIcon[] = this.teethNumbers!.map((h) =>
        this.processToothNumber(h)
      );

      this.topTeeth$.next(icons.slice(0, 16));
      this.bottomTeeth$.next(icons.slice(16, 32));
      return icons;
    })
  );
}
