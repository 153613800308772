import { createReducer, on } from "@ngrx/store";
import { AuthActions } from "./actions";

export const featureKey = "auth";

export interface AuthState {
  authError: unknown;
  isAuthenticating: boolean;
  loggedInProfile?: {
    userId: string | null;
    userOfficeId: string | null;
  };
  authToken?: {
    /**
     * Token for accessing Auth0 APIs.
     *
     * The token will be valid from the time of receiving this access token
     * (see `recordedAt`), for the duration specified in `accessTokenExpiresIn`.
     */
    accessToken: string;
    /** The time to expiry (in seconds) of the access token */
    accessTokenExpiresIn: number;
    /** The time at which this AuthResult instance is recorded. */
    recordedAt: Date;
    /**
     * Token for obtaining refreshed access tokens (JWT tokens).
     */
    refreshToken: string;
  };
}

export const initialState: AuthState = {
  authError: undefined,
  isAuthenticating: false,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.login, (state) => ({
    ...state,
    authError: undefined,
    isAuthenticating: true,
  })),
  on(
    AuthActions.loginSuccess,
    (
      state,
      { userId, userOfficeId, refreshToken, accessToken, tokenExpiresInSecs }
    ) => ({
      ...state,
      isAuthenticating: false,
      loggedInProfile: { userId, userOfficeId },
      authToken: {
        accessToken,
        refreshToken,
        recordedAt: new Date(),
        accessTokenExpiresIn: tokenExpiresInSecs,
      },
    })
  ),

  on(AuthActions.loginFailure, (state, { error }) => ({
    ...state,
    isAuthenticating: false,
    authError: error,
  })),

  on(AuthActions.refreshAccessTokenSuccess, (state, updates) => ({
    ...state,
    authToken: {
      accessToken: updates.accessToken,
      refreshToken: updates.refreshToken,
      accessTokenExpiresIn: updates.expiresIn,
      recordedAt: new Date(),
    },
  })),

  on(AuthActions.logout, () => initialState)
);
