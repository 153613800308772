import { Component, Input } from "@angular/core";
import { User } from "@kells/clinic-one/data-access/users";

@Component({
  selector: "koa-patient-reporting-dentist-info",
  templateUrl: "./dentist-info.component.html",
  styleUrls: ["./dentist-info.component.scss"],
})
export class DentistInfoComponent {
  @Input() dentistInfo: User | null;

  private readonly defaultPhoto = "assets/punch/images/default-dentist.png";

  private readonly defaultFullName = "Dr. Blake Mann";

  private readonly defaultSpecialty = "DDS MPH MS FACD";

  public get fullName(): string {
    const dentistFullName = [
      this.dentistInfo?.firstName,
      this.dentistInfo?.lastName,
    ]
      .filter(Boolean)
      .join(" ");

    return dentistFullName || this.defaultFullName;
  }

  public get photoUrl(): string {
    return this.dentistInfo?.photoUrl || this.defaultPhoto;
  }

  public get specialty(): string {
    return this.dentistInfo?.specialty || this.defaultSpecialty;
  }
}
