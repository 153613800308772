import { InjectionToken } from "@angular/core";

/**
 * Defines the injection token of the clinic one API base url.
 */
export const CLINIC_ONE_API_BASE_URL = new InjectionToken<string>(
  "ClinicOneApiBaseUrl"
);

/**
 * Defines the injection token of the clinic one API version used.
 */
export const CLINIC_ONE_API_VER = new InjectionToken<string>(
  "ClinicOneApiVersion"
);
