/* eslint-disable @typescript-eslint/no-namespace */
/**
 * Declarations for custom interfaces for `fabric.js` with our internal
 * custom properties.
 *
 * @usage
 * ```typescript
 * import { KellsFabric } from './fabric.model';
 *
 * const object: KellsFabric.Object;
 * ```
 *
 * This emulates the experience of importing types from fabric directly, where
 * one would do:
 * ```typescript
 * import { fabric } from 'fabric';
 *
 * const object: fabric.Object;
 * ```
 */

import { fabric } from "fabric";
import { CanvasLibFinding } from "./finding.model";

interface SharedCustomFabricProps {
  id: string;
  findingType?: string;
  role?:string;
  lineCoords: { [key: string]: any}
}

interface CustomRectProps {
  enableInteraction: boolean;
}


export interface CompositeBoneLossProperties {
  finding: CanvasLibFinding;
  ac?: any;
  cej?: any;
  line?: any;
  name?: string;
  ctrl?: any;
}


export interface LineCoordsGeometry {
  [key: string]: number;
}
export interface LinePointsGeometry {
  [key: string]: fabric.Point;
}
export enum FabricEvents {
OBJECT_MODIFIED = 'object:modified',
OBJECT_ROTATING = 'object:rotating',
OBJECT_SCALING = 'object:scaling',
OBJECT_MOVING = 'object:moving',
OBJECT_SKEWING = 'object:skewing',

BEFORE_TRANSFORM = 'before:transform',
BEFORE_SELECTION_CLEARED = 'before:selection:cleared',
SELECTION_CLEARED = 'selection:cleared',
SELECTION_UPDATED = 'selection:updated',
SELECTION_CREATED = 'selection:created',

PATH_CREATED = 'path:created',
MOUSE_DOWN = 'mouse:down',

MOUSE_MOVE = 'mouse:move',
MOUSE_UP = 'mouse:up',
MOUSE_DOWN_BREFORE = 'mouse = down:before',
MOUSE_MOVE_BEFORE = 'mouse:move:before',
MOUSE_UP_BEFORE = 'mouse:up:before',
MOUSE_OVER = 'mouse:over',
MOUSE_OUT = 'mouse:out',
MOUSE_DBLCLICK = 'mouse:dblclick',

DRAGOVER = 'dragover',
DRAGENTER = 'dragenter',
DRAGLEAVE = 'dragleave',
DRPDOWN = 'dropdown',
AFTER_RENDER = 'after:render',
AFTER_SELECTION = 'before:render',
OBJECT_ROTATED = 'object:scaled',
OBJECT_MOVED = 'object:moved',
OBJECT_SKEWED = 'object:skewed',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace KellsFabric {
  export type Canvas = fabric.Canvas;
  export type Object = fabric.Object & SharedCustomFabricProps & CompositeBoneLossProperties;
  export type Rect = fabric.Rect & SharedCustomFabricProps & CompositeBoneLossProperties;
  export type Text = fabric.Text & SharedCustomFabricProps;
  export type Circle = fabric.Circle & SharedCustomFabricProps & CompositeBoneLossProperties;
  export type Group = fabric.Group & SharedCustomFabricProps & CompositeBoneLossProperties;
  export type IGroupOptions = fabric.IGroupOptions & SharedCustomFabricProps & CustomRectProps & CompositeBoneLossProperties;
  export type Line = fabric.Line & SharedCustomFabricProps & CompositeBoneLossProperties;
  export type Path = fabric.Path & SharedCustomFabricProps & CompositeBoneLossProperties;
  export type Polyline = fabric.Polyline & SharedCustomFabricProps & CompositeBoneLossProperties;
  export interface Dimensions {
    height: number;
    width: number;
  }

  export class PolyPoint {
    constructor(
      public x: number,
      public y: number
    ) { }
  }
}



declare module 'fabric' {
  namespace fabric {
    interface IObject {
      id?: string | undefined,
      role?: string | undefined,
      ctrl?: any | undefined,
    }
    interface Image {
      lineCoords: {
        tl: Point,
        tr: Point,
        bl: Point,
        br: Point,
      }
    }
  }
}


export function isBackgroundImage(img: fabric.Image | string):img is fabric.Image {
    return (img as fabric.Image).setSrc !== undefined;
}
