<div class="fixed-container" #scrollFrame>
  <div @pageAnimations #reportImageGeneration>
    <div
      class="punch-patient-report-wrapper"
      *ngLet="{
        loadingComplete: !(data.loading$ | async),
        status: (data.loading$ | async) ? 'loading' : 'loading-complete'
      } as loaderState"
    >
      <div class="kells-report" *ngLet="isXraysExist$ | async as isXraysExist">
        <ng-container
          *ngLet="isIntraoralPhotosExist$ | async as isIntraoralPhotosExist"
        >
          <ng-container
            *ngLet="
              organizationBasedContent$ | async as organizationBasedContent
            "
          >
            <!-- Top navigation bar -->
            <section class="hero-section report-generate-block">
              <div class="kells-report-container">
                <div class="hero-section-conatiner">
                  <div
                    class="nav-bar-non-sticky flex justify-between items-center py-5"
                  >
                    <a [href]="'/'" class="flex kells-logo-link">
                      <div class="xs:flex xs:h-auto">
                        <img
                          *ngIf="organizationBasedContent?.reportLogo"
                          [src]="organizationBasedContent?.reportLogo"
                          [ngClass]="{ 'portal-image': !!portal }"
                          alt="kells-logo"
                          [@enterBounce1]
                        />
                      </div>
                    </a>
                    <div class="flex">
                      <a [href]="'/'" class="homepage-link">
                        <i class="fa-solid fa-chevron-left mr-3"></i>
                        KELLS Portal
                      </a>
                    </div>
                  </div>

                  <ng-container
                    *ngLet="data.selectedPatient$ | async as thePatient"
                  >
                    <div
                      class="flex justify-between items-center py-5 page-title"
                    >
                      <div>
                        <h1 class="fw-700 uppercase">
                          Kells Oral Health Report
                        </h1>
                      </div>

                      <div
                        *ngIf="isReportDownloadAvailable"
                        class="download-report-btn omit-report-generation"
                      >
                        <koa-report-button
                          [isLoading]="isReportDownloading"
                          (click)="downloadReport()"
                        >
                          <ng-container ngProjectAs="[icon]">
                            <i
                              class="fa fa-arrow-down-to-line"
                              aria-hidden="true"
                            ></i>
                          </ng-container>

                          Download
                        </koa-report-button>
                      </div>
                    </div>

                    <div
                      id="user-section"
                      class="flex justify-between py-10 items-center"
                    >
                      <div
                        class="user-profile flex flex-row items-center"
                        *ngIf="thePatient"
                      >
                        <div class="user-thumbnil">
                          <span class="ft-large">{{
                            patientInitials$ | async
                          }}</span>
                        </div>
                        <div class="user-name ml-5">
                          <p class="ft-large fw-700">
                            {{ patientFullName$ | async }}
                          </p>
                          <p class="ft-small ft-500">
                            {{ thePatient.email }}
                          </p>
                        </div>
                      </div>

                      <div class="report-date">
                        <p class="ft-small fw-700 grey-label">REPORT DATE</p>
                        <p class="ft-small fw-500">
                          {{ formatDateString(selectedSessionDate$ | async) }}
                        </p>
                      </div>
                    </div>
                  </ng-container>

                  <div class="disclaimer my-3">
                    <p class="disclaimer-content p-3 ft-small">
                      This report is based solely on the evaluation of your
                      dental images. The results are generated through a
                      combination of AI technology and a licensed dentist's
                      review. Please note that this report is not intended to
                      serve as a formal diagnosis or replace an in-person visit
                      to a dental office.
                    </p>
                  </div>

                  <div
                    [ngClass]="{
                      'disclaimer my-3': true,
                      'download-report-unavailable-content': isReportDownloadAvailable,
                      'd-block': !isReportDownloadAvailable
                    }"
                  >
                    <p
                      class="disclaimer-content disclaimer-content-warning p-3 ft-small"
                    >
                      Report download is currently not available on mobile
                      device. You can download the report on desktop instead.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <!-- Top navigation bar end -->

            <!-- Sticky bar -->
            <section
              class="sticky-bar page-header report-generate-block"
              #pageHeader
            >
              <div class="navbar w-nav shadow-md sticky top-0 z-50">
                <div class="kells-report-container flex justify-center">
                  <nav
                    role="navigation"
                    class="nav-menu nav-menu grid xs:grid-cols-5 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 2xl:grid-cols-5"
                  >
                    <a
                      (click)="scrollToSection(SNAPSHOT_SECTION_ID)"
                      class="nav-link"
                      [ngClass]="{
                        active: currentSection === SNAPSHOT_SECTION_ID
                      }"
                    >
                      <span>SNAPSHOT</span>
                      <span class="underline"></span>
                    </a>

                    <a
                      (click)="scrollToSection(CAVITIES_SECTION_ID)"
                      class="nav-link-text nav-link"
                      [ngClass]="{
                        active: currentSection === CAVITIES_SECTION_ID
                      }"
                    >
                      <span>YOUR TEETH</span>
                      <span class="underline"></span>
                    </a>

                    <a
                      *ngIf="isXraysExist"
                      (click)="scrollToSection(BONE_HEALTH_SECTION_ID)"
                      class="nav-link"
                      [ngClass]="{
                        active: currentSection === BONE_HEALTH_SECTION_ID
                      }"
                    >
                      <span>BONE HEALTH</span>
                      <span class="underline"></span>
                    </a>

                    <a
                      class="nav-link"
                      (click)="scrollToSection(SMILE_CHECKLIST_SECTION_ID)"
                      [ngClass]="{
                        active: currentSection === SMILE_CHECKLIST_SECTION_ID
                      }"
                    >
                      <span>SMILE CHECKLIST</span>
                      <span class="underline"></span>
                    </a>

                    <a
                      *ngIf="!(isProductRecommendationsHidden$ | async)"
                      class="nav-link"
                      (click)="
                        scrollToSection(PRODUCT_RECOMMENDATION_SECTION_ID)
                      "
                      [ngClass]="{
                        active:
                          currentSection === PRODUCT_RECOMMENDATION_SECTION_ID
                      }"
                    >
                      <span>PRODUCT RECOMMENDATION</span>
                      <span class="underline"></span>
                    </a>

                    <a
                      class="nav-link"
                      *ngIf="!(isZocDocHidden$ | async)"
                      (click)="scrollToSection(FIND_A_DENTIST_SECTION_ID)"
                      [ngClass]="{
                        active: currentSection === FIND_A_DENTIST_SECTION_ID
                      }"
                    >
                      <span>FIND A DENTIST</span>
                      <span class="underline"></span>
                    </a>
                  </nav>
                  <div class="w-nav-button">
                    <div class="w-icon-nav-menu"></div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Snapshot section -->
            <section
              id="{{ SNAPSHOT_SECTION_ID }}"
              class="snapshot-section report-generate-block"
              #snapshot
              *ngLet="{
                subhead: (snapshotSubhead$ | async),
                snapshotBody: (snapshotBody$ | async),
                snapshotBonelossBody: (snapshotBonelossBody$ | async),
                snapshotBonelossSubhead: (snapshotBonelossSubhead$ | async)
              } as snapshot"
            >
              <div class="kells-report-container">
                <h1 class="report-header mb-10 fw-700 bottom-hb">SNAPSHOT</h1>
                <div class="cavity-snapshot section-content">
                  <div
                    class="snapshot-row-layout grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2"
                  >
                    <div class="left-side">
                      <h3 class="snapshot-title pb-5 fw-700">
                        {{ isXraysExist ? "CAVITY" : "OVERALL" }} RISK
                      </h3>
                      <h4 class="snapshot-subtitle py-2 fw-700">SUMMARY</h4>
                      <div
                        class="snapshot-description py-2 fw-500 ibmplex-semi-font ft-medium"
                      >
                        <p
                          class="snapshot-subhead"
                          *ngIf="snapshot.subhead"
                          [innerHTML]="snapshot.subhead"
                        ></p>
                        <p [innerHTML]="snapshot.snapshotBody"></p>
                      </div>
                      <p
                        class="snapshot-post py-2"
                        *ngIf="showSnapshotPost"
                        [innerHTML]="snapshot.snapshotPost"
                      ></p>
                    </div>
                    <div class="right-side relative">
                      <koa-oral-condition-donut-chart
                        [score]="sessionScore$ | async"
                      ></koa-oral-condition-donut-chart>
                    </div>
                  </div>
                </div>

                <div
                  class="bone-snapshot section-content mb-10"
                  *ngIf="isXraysExist"
                >
                  <div
                    class="snapshot-row-layout grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2"
                  >
                    <div class="left-side">
                      <h3 class="snapshot-title pb-5 fw-700">
                        BONE HEALTH RISK
                      </h3>
                      <h4 class="snapshot-subtitle py-2 fw-700">SUMMARY</h4>
                      <div
                        class="snapshot-description py-2 fw-500 ibmplex-semi-font ft-medium"
                      >
                        <p
                          class="snapshot-subhead"
                          *ngIf="snapshot.snapshotBonelossSubhead"
                          [innerHTML]="snapshot.snapshotBonelossSubhead"
                        ></p>
                        <p
                          *ngIf="snapshot.snapshotBonelossBody"
                          [innerHTML]="snapshot.snapshotBonelossBody"
                        ></p>
                      </div>
                    </div>
                    <div class="right-side relative">
                      <koa-snapshot-boneloss-chart></koa-snapshot-boneloss-chart>
                    </div>
                  </div>
                  <!-- <kpr-boneloss-snapshot></kpr-boneloss-snapshot> -->
                </div>

                <div *ngIf="isXraysExist">
                  <koa-xray-carousel
                    title="YOUR X-RAYS"
                    [imageType]="SessionImageType.Xray"
                    [showBoneloss]="false"
                    [showCaries]="false"
                    [showFindings]="false"
                    [interactive]="true"
                    [downloadEnabled]="true"
                    (onDownload)="downloadXrays($event)"
                    (onXrayOpen)="xrayOpenEvent.emit()"
                    [findingsByImage]="findingsByImage$ | async"
                  >
                  </koa-xray-carousel>
                </div>

                <div *ngIf="isIntraoralPhotosExist">
                  <koa-xray-carousel
                    title="YOUR INTRAORAL PHOTOS"
                    [imageType]="SessionImageType.Photo"
                    [showBoneloss]="false"
                    [showCaries]="false"
                    [showFindings]="false"
                    [interactive]="true"
                    [downloadEnabled]="true"
                    (onDownload)="downloadXrays($event)"
                    (onXrayOpen)="xrayOpenEvent.emit()"
                    [findingsByImage]="findingsByImage$ | async"
                  >
                  </koa-xray-carousel>
                </div>

                <div class="dentist-notes section-content" *ngIf="isXraysExist">
                  <h3 class="report-header mb-5 fw-700">DENTIST NOTES</h3>
                  <div class="grid grid-cols-12 grid-small-6">
                    <koa-patient-reporting-dentist-info
                      class="col-span-3"
                      [dentistInfo]="dentistInfoByPatientId$ | async"
                    ></koa-patient-reporting-dentist-info>

                    <div
                      class="col-span-9 notes-for-user pt-0 xs:pt-5 sm:pt-5"
                      *ngIf="!isClinicOne"
                    >
                      <div
                        class="textarea-editor ft-large fw-500"
                        #content
                        data-placeholder="No notes available"
                        [innerHTML]="recommendationNote"
                      ></div>
                    </div>
                    <div
                      class="col-span-9 notes-for-user pt-0 xs:pt-5 sm:pt-5"
                      *ngIf="isClinicOne"
                    >
                      <div
                        class="textarea-editor ft-large fw-500"
                        #content
                        contenteditable="true"
                        data-placeholder="Type your notes here.."
                        [innerHTML]="recommendationNote"
                        (input)="onRecEditorFullKeyUp($event.target.innerHTML)"
                        (click)="ChangeRecommendation()"
                      ></div>
                      <div
                        class="buttons btn-group col-md-5"
                        *ngIf="showButton"
                      >
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          (click)="saveRecommendation()"
                        >
                          Save</button
                        >&nbsp;
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          (click)="cancelRecommendation()"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Cavity section -->
            <section
              class="cavities-section report-generate-block"
              id="{{ CAVITIES_SECTION_ID }}"
              #cavities
            >
              <ng-container
                *ngLet="{
                  tooth: (activeTooth$ | async),
                  infoText: (activeToothInfoText$ | async),
                  stage: (activeToothCariesStage$ | async),
                  ordn: (activeToothTitleOrdn$ | async),
                  toothName: (activeToothTitle$ | async),
                  diagnosis: (activeToothDiagnosis$ | async),
                  activeTreatments: (toothTreatments$ | async),
                  activeToothFindingsImages:
                    (activeToothFindingsImages$ | async),
                  activeNonCariesToothFindings:
                    (activeNonCariesToothFindings$ | async),
                  activeCariesToothFindings:
                    (activeCariesToothFindings$ | async),
                  isIntraoralPhotosExist: (isIntraoralPhotosExist$ | async),
                  isIntraoralPhotosFindingsExist:
                    (isIntraoralPhotosFindingsExist$ | async),
                  hasFindings: (hasFindings$ | async),
                  hasXraysCavities: (hasXraysCavities$ | async),

                  totalCariesSession: (data.totalCariesSession$ | async),
                  totalFindingsSession: (data.totalFindingsSession$ | async),
                  findingsByTooth: data.findingsByTooth
                } as tabsState"
              >
                <div
                  class="kells-report-container"
                  *ngIf="isCariesSectionExists$ | async"
                >
                  <div
                    [class.your-teeth]="tabsState.findingsByTooth.length === 0"
                  >
                    <h1 class="report-header fw-700 mb-10 bottom-hb">
                      YOUR TEETH
                    </h1>

                    <div
                      *ngIf="tabsState.findingsByTooth.length === 0"
                      class="snapshot-description py-2 fw-500 ibmplex-semi-font ft-medium"
                    >
                      <p [innerHTML]="snapshotBody$ | async"></p>
                    </div>
                  </div>
                </div>

                <div class="multiple-cavities">
                  <div class="kells-report-container">
                    <div class="section-content">
                      <h3 class="report-header mb-5 fw-700">
                        <ng-container
                          *ngIf="
                            tabsState.hasXraysCavities &&
                            !tabsState.isIntraoralPhotosFindingsExist
                          "
                        >
                          YOU MAY HAVE MULTIPLE CAVITIES
                        </ng-container>
                        <ng-container
                          *ngIf="
                            tabsState.hasFindings &&
                            (!tabsState.hasXraysCavities ||
                              tabsState.isIntraoralPhotosFindingsExist)
                          "
                        >
                          YOU MAY HAVE MULTIPLE CONCERNS
                        </ng-container>
                        <ng-container
                          *ngIf="
                            !tabsState.hasFindings && !isIntraoralPhotosExist
                          "
                        >
                          YOU MAY HAVE NO CAVITY
                        </ng-container>
                        <ng-container
                          *ngIf="
                            !tabsState.hasFindings && isIntraoralPhotosExist
                          "
                        >
                          YOU MAY HAVE NO CONCERNS
                        </ng-container>
                      </h3>
                      <koa-findings-filter
                        (selectedFindingsFilterTypeChanged)="
                          selectedFindingsFilterType.next($event)
                        "
                        [findingsFilterItems$]="findingsFilterItems$"
                      ></koa-findings-filter>
                      <div
                        id="cavitiesBody"
                        class="grid grid-cols-12 pt-8 gap-5 md:gap-3 xs:gap-1 sm:gap-1 xs:px-0"
                      >
                        <div
                          class="left-cavity-container 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-5 xs:col-span-12"
                        >
                          <div
                            class="caries-chart-legend w-full flex justify-center"
                          >
                            <div
                              class="flex items-center content-center mr-5 sm:mr-3"
                            >
                              <div
                                class="legend-color initial mr-2 shadow"
                              ></div>
                              <div class="ft-medium">Initial</div>
                            </div>
                            <div
                              class="flex items-center content-center mr-5 sm:mr-3"
                            >
                              <div
                                class="legend-color moderate mr-2 shadow"
                              ></div>
                              <div class="ft-medium">Moderate</div>
                            </div>
                            <div
                              class="flex items-center content-center mr-5 sm:mr-3"
                            >
                              <div
                                class="legend-color advanced mr-2 shadow"
                              ></div>
                              <div class="ft-medium">Advanced</div>
                            </div>
                          </div>
                          <div
                            class="w-full xs:pb-5 mr-5 xs:mr-0 xs:pb-0 mouth-container relative"
                          >
                            <img
                              class="tooth-navigation-map"
                              src="/assets/punch/images/tooth-navigation.png"
                              alt="tooth-navigation"
                            />
                            <koa-mouth
                              [report]="report$ | async"
                              [findingsByTooth]="findingsByTooth$ | async"
                              (activeToothChange)="setActiveCariesTooth($event)"
                              (toothClick)="recordToothClick($event)"
                              [selectedFindingsFilterType]="
                                selectedFindingsFilterType$ | async
                              "
                            ></koa-mouth>
                          </div>
                          <div
                            class="caries-chart-legend w-full flex justify-center"
                          >
                            <p class="ft-medium bg-white p-3 mt-5 fw-500">
                              Click on any colored tooth to learn more
                            </p>
                          </div>
                        </div>
                        <div
                          class="right-cavity-container ml-10 2xl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-6 sm:col-span-7 xs:col-span-12"
                        >
                          <div class="cavities-tab">
                            <div
                              data-current="Tab 1"
                              data-easing="ease"
                              data-duration-in="300"
                              data-duration-out="100"
                              class="w-tabs"
                            >
                              <div class="tab-menu flex">
                                <a
                                  data-w-tab="Tab 1"
                                  class="tab-item ft-large"
                                  (click)="
                                    setActiveCariesTab(CARIES_TAB_FINDINGS)
                                  "
                                  [ngClass]="{
                                    active:
                                      (activeCariesTab$ | async) ===
                                      CARIES_TAB_FINDINGS
                                  }"
                                >
                                  Description <span class="underline"></span>
                                </a>
                                <a
                                  *ngIf="
                                    isXraysExist && activeToothXrayImagesCount
                                  "
                                  data-w-tab="Tab 2"
                                  class="tab-item ft-large"
                                  (click)="
                                    setActiveCariesTabXRay(CARIES_TAB_XRAY)
                                  "
                                  [ngClass]="{
                                    active:
                                      (activeCariesTab$ | async) ===
                                      CARIES_TAB_XRAY
                                  }"
                                >
                                  X-Ray
                                  <span class="underline"></span>
                                </a>
                                <a
                                  *ngIf="
                                    isIntraoralPhotosExist &&
                                    activeToothInraoralImagesCount
                                  "
                                  data-w-tab="Tab 3"
                                  class="tab-item ft-large"
                                  (click)="
                                    setActiveCariesTabXRay(CARIES_TAB_INTRAORAL)
                                  "
                                  [ngClass]="{
                                    active:
                                      (activeCariesTab$ | async) ===
                                      CARIES_TAB_INTRAORAL
                                  }"
                                >
                                  Intraoral Photo
                                  <span class="underline"></span>
                                </a>
                              </div>
                              <div class="tab-content">
                                <div class="tab-pane">
                                  <div class="tab-container">
                                    <div
                                      class="grid xs:grid-cols-12 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12"
                                    >
                                      <div
                                        class="flex flex-col justify-start xs:col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 2xl:col-span-6"
                                      >
                                        <div class="tooth-illustration-wrapper">
                                          <img
                                            [src]="
                                              cariesToothIllustrationSvg$
                                                | async
                                            "
                                            role="image"
                                            alt="{{ activeTooth.name }}"
                                            class="active-tooth-svg"
                                          />
                                        </div>

                                        <h1
                                          class="kpr-title dark-blue-label tooth-name lowercase pt-3"
                                        >
                                          <span class="text-ordinals">{{
                                            tabsState.ordn
                                          }}</span>
                                          {{ tabsState.toothName }}
                                        </h1>
                                      </div>

                                      <div
                                        *ngIf="
                                          !(isTreatmentsHidden$ | async) &&
                                          tabsState.activeTreatments?.length &&
                                          (activeCariesTab$ | async) ===
                                            CARIES_TAB_FINDINGS
                                        "
                                        class="flex flex-col justify-start xs:col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-6 2xl:col-span-6"
                                      >
                                        <h3
                                          class="action-header ft-large fw-700 blue-light-label"
                                        >
                                          Potential Action
                                        </h3>

                                        <ng-container
                                          *ngFor="
                                            let trmnt of tabsState.activeTreatments
                                          "
                                        >
                                          <div
                                            class="treatment-plan flex flex-col my-5"
                                          >
                                            <!-- css has written here because header-label class is being used many places in this component -->
                                            <h4
                                              class="treatment-plan-name mb-1 ft-large fw-500"
                                            >
                                              {{ trmnt.treatmentPlan }}
                                            </h4>

                                            <button
                                              class="video-button"
                                              *ngIf="
                                                hasVideo(trmnt.treatmentPlan)
                                              "
                                              (click)="
                                                openDialog(trmnt.treatmentPlan)
                                              "
                                            >
                                              <i class="fa-solid fa-play"></i>
                                              <span>Play video</span>
                                            </button>

                                            <ng-container
                                              *ngIf="
                                                !(
                                                  isEstimatedCostsHidden$
                                                  | async
                                                )
                                              "
                                            >
                                              <h4
                                                *ngIf="
                                                  trmnt.costEstimates &&
                                                  trmnt.costEstimates != '-'
                                                "
                                                class="treatment-plan-cost grey-label ft-large fw-700"
                                              >
                                                {{ trmnt.costEstimates }}
                                                <i
                                                  class="fa fas fa-question-circle"
                                                  title="Estimated cost"
                                                ></i>
                                              </h4>
                                            </ng-container>
                                          </div>
                                        </ng-container>
                                      </div>
                                    </div>

                                    <div
                                      class="mt-5"
                                      data-w-tab="CARIES_TAB_FINDINGS"
                                      id="CARIES_TAB_FINDINGS"
                                      *ngIf="
                                        (activeCariesTab$ | async) ===
                                        CARIES_TAB_FINDINGS
                                      "
                                    >
                                      <p
                                        class="tab-description-paragraph ft-medium dark-blue-label"
                                        *ngIf="
                                          tabsState.activeCariesToothFindings
                                            ?.length &&
                                          !tabsState
                                            .activeNonCariesToothFindings
                                            ?.length
                                        "
                                      >
                                        You may have
                                        {{ tabsState.stage }} cavity on your
                                        <span class="text-ordinals">{{
                                          tabsState.ordn
                                        }}</span
                                        >{{ tabsState.toothName }}.
                                        <span
                                          [innerHTML]="tabsState.diagnosis"
                                        ></span>
                                      </p>

                                      <p
                                        class="tab-description-paragraph ft-medium dark-blue-label"
                                        *ngIf="
                                          tabsState.activeNonCariesToothFindings
                                            ?.length
                                        "
                                      >
                                        You may have issues with your
                                        <span class="text-ordinals">{{
                                          tabsState.ordn
                                        }}</span
                                        >{{ tabsState.toothName }} that you may
                                        want to be looked at by a dentist to be
                                        treated.
                                      </p>

                                      <ng-container
                                        *ngLet="
                                          activeToothFindingsDescriptions$
                                            | async as activeToothFindingsDescriptions
                                        "
                                      >
                                        <ng-container
                                          *ngFor="
                                            let findingDescription of activeToothFindingsDescriptions
                                          "
                                        >
                                          <div class="cavity-info-block">
                                            <h3
                                              class="moderate-cavity-header mb-5 fw-700 dark-blue-label"
                                              [innerHTML]="
                                                findingDescription.title
                                              "
                                            ></h3>
                                            <p
                                              class="moderate-cavity-paragraph ft-medium dark-blue-label"
                                              [innerHTML]="
                                                findingDescription.infoText
                                              "
                                            ></p>
                                          </div>
                                        </ng-container>
                                      </ng-container>
                                    </div>

                                    <ng-container
                                      *ngLet="
                                        activeCariesTab$
                                          | async as activeCariesTab
                                      "
                                    >
                                      <div
                                        data-w-tab="Tab 2"
                                        class="w-tab-pane xray-tab"
                                        *ngIf="
                                          (activeCariesTab$ | async) ===
                                          CARIES_TAB_XRAY
                                        "
                                      >
                                        <koa-xray-tab
                                          [filterTooth]="
                                            (activeTooth$ | async).tooth_id
                                          "
                                          [xrays]="
                                            tabsState.activeToothFindingsImages
                                          "
                                          (onXrayOpen)="xrayOpenEvent.emit()"
                                          [imageType]="SessionImageType.Xray"
                                          [hideIfNoFindingsForImage]="true"
                                          [findingsByImage]="
                                            findingsByImage$ | async
                                          "
                                        ></koa-xray-tab>
                                      </div>

                                      <div
                                        data-w-tab="Tab 2"
                                        class="w-tab-pane xray-tab"
                                        *ngIf="
                                          (activeCariesTab$ | async) ===
                                          CARIES_TAB_INTRAORAL
                                        "
                                      >
                                        <koa-xray-tab
                                          [filterTooth]="
                                            (activeTooth$ | async).tooth_id
                                          "
                                          [xrays]="
                                            tabsState.activeToothFindingsImages
                                          "
                                          (onXrayOpen)="xrayOpenEvent.emit()"
                                          [imageType]="SessionImageType.Photo"
                                          [hideIfNoFindingsForImage]="true"
                                          [findingsByImage]="
                                            findingsByImage$ | async
                                          "
                                        ></koa-xray-tab>
                                      </div>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="kells-report-container">
                  <div
                    class="dentist-has-to-say"
                    *ngIf="tabsState.totalFindingsSession"
                  >
                    <div class="dentist-notes section-content">
                      <ng-container
                        *ngIf="
                          tabsState.hasXraysCavities &&
                          !tabsState.isIntraoralPhotosFindingsExist
                        "
                      >
                        <h3 class="report-header mb-5 fw-700">
                          NOTE ON CAVITIES
                        </h3>
                        <div class="grid grid-cols-12 grid-small-6">
                          <div class="col-span-12 notes-for-user pt-0">
                            <p class="ft-large fw-500">
                              “You may have several cavities. You are probably
                              at a higher risk of getting cavities than the
                              average person. This can be due to a number of
                              reasons including genetics, diets, and hygiene
                              practices. Your dentist may recommend a
                              prescription strength toothpaste. These
                              toothpastes have a higher level of fluoride that
                              is meant to prevent you getting more cavities.”
                            </p>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>

                  <ng-container *ngIf="!(isTreatmentsHidden$ | async)">
                    <ng-container
                      *ngLet="treatmentsInReport$ | async as treatments"
                    >
                      <div
                        class="potential-actions section-content"
                        *ngIf="treatments?.length"
                      >
                        <h3 class="report-header mb-5 fw-700">
                          POTENTIAL ACTIONS
                        </h3>
                        <koa-treatment-plan-table
                          [displayedColumns]="
                            treamentPlanDisplayedTableColumns$ | async
                          "
                          [images]="data.images$ | async"
                          [treatments]="treatments"
                          [loading]="loading"
                        >
                        </koa-treatment-plan-table>
                        <p class="ft-small fw-500 py-5">
                          Cost estimates are based on factors such as size,
                          position in mouth, and case complexity according to
                          the 2020 American Dental Association survey.
                        </p>
                      </div>
                    </ng-container>
                  </ng-container>

                  <div class="cavities-xrays">
                    <koa-xray-carousel
                      *ngIf="isXraysExist"
                      #xrayCarousel
                      [imageType]="SessionImageType.Xray"
                      [title]="'YOUR CAVITY X-RAYS'"
                      [showGumInflammation]="true"
                      [showMissingTooth]="true"
                      [showGumRecession]="true"
                      [showFracture]="true"
                      [showPlaque]="true"
                      [showBoneloss]="true"
                      [showCaries]="true"
                      [showFindings]="true"
                      [interactive]="true"
                      [hideIfNoFindingsForImage]="true"
                      (onXrayOpen)="xrayOpenEvent.emit()"
                      [findingsByImage]="findingsByImage$ | async"
                    >
                    </koa-xray-carousel>

                    <koa-xray-carousel
                      *ngIf="isIntraoralPhotosExist"
                      #xrayCarousel
                      [imageType]="SessionImageType.Photo"
                      [title]="'YOUR INTRAORAL PHOTOS'"
                      [showGumInflammation]="true"
                      [showMissingTooth]="true"
                      [showGumRecession]="true"
                      [showFracture]="true"
                      [showPlaque]="true"
                      [showBoneloss]="true"
                      [showCaries]="true"
                      [showFindings]="true"
                      [interactive]="true"
                      [hideIfNoFindingsForImage]="true"
                      (onXrayOpen)="xrayOpenEvent.emit()"
                      [findingsByImage]="findingsByImage$ | async"
                    >
                    </koa-xray-carousel>
                  </div>

                  <div
                    *ngIf="isXraysExist"
                    class="more-about-cavities-health section-content"
                  >
                    <h3 class="report-header mb-5 fw-700">
                      LEARN MORE ABOUT CAVITIES
                    </h3>

                    <mat-accordion>
                      <div class="accordion-item">
                        <mat-expansion-panel
                          (opened)="
                            setQAndACavitiesExpansionPanelOpened(1, true)
                          "
                          (closed)="
                            setQAndACavitiesExpansionPanelOpened(1, false)
                          "
                          hideToggle="true"
                        >
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p
                                class="flex items-center ibmplex-semi-font ft-large fw-500"
                              >
                                <i class="fa fa-info-circle mr-2"></i>
                                <span>What is a cavity?</span>
                              </p>
                            </mat-panel-title>
                            <div class="icons">
                              <mat-icon *ngIf="!cavitiesAccordionPanels[1]"
                                ><i
                                  class="fa fas fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </mat-icon>
                              <mat-icon *ngIf="cavitiesAccordionPanels[1]"
                                ><i
                                  class="fa fas fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </mat-icon>
                            </div>
                          </mat-expansion-panel-header>
                          <div class="faq-ans mt-8">
                            <p class="ft-medium fw-500">
                              A cavity, also called tooth decay, is a hole that
                              forms in your tooth. This is caused by a number of
                              things, including consumption of sugary food and
                              beverages. Over time, a cavity could get bigger
                              and result in sensitivity, pain, infection and
                              ultimately, tooth loss. It is optimal to treat
                              cavities early as they can be reversed with proper
                              oral hygiene.
                            </p>
                          </div>
                        </mat-expansion-panel>
                      </div>

                      <div class="accordion-item">
                        <mat-expansion-panel
                          (opened)="
                            setQAndACavitiesExpansionPanelOpened(2, true)
                          "
                          (closed)="
                            setQAndACavitiesExpansionPanelOpened(2, false)
                          "
                          hideToggle="true"
                        >
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p
                                class="flex items-center ibmplex-semi-font ft-large fw-500"
                              >
                                <i class="fa fa-info-circle mr-2"></i>
                                <span> How do you treat cavity?</span>
                              </p>
                            </mat-panel-title>
                            <div class="icons">
                              <mat-icon *ngIf="!cavitiesAccordionPanels[2]"
                                ><i
                                  class="fa fas fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </mat-icon>
                              <mat-icon *ngIf="cavitiesAccordionPanels[2]"
                                ><i
                                  class="fa fas fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </mat-icon>
                            </div>
                          </mat-expansion-panel-header>
                          <div class="faq-ans mt-8">
                            <p class="ft-medium fw-500">
                              Initial cavities can be treated with hygiene
                              practices. The tooth can be restrengthen with the
                              use of fluoride products like toothpaste and
                              rinse. If the cavity gets larger, the infected
                              tooth structure needs to be removed and is
                              replaced with dental filling or crown.
                            </p>
                          </div>
                        </mat-expansion-panel>
                      </div>

                      <div class="accordion-item">
                        <mat-expansion-panel
                          (opened)="
                            setQAndACavitiesExpansionPanelOpened(3, true)
                          "
                          (closed)="
                            setQAndACavitiesExpansionPanelOpened(3, false)
                          "
                          hideToggle="true"
                        >
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              <p
                                class="flex items-center ibmplex-semi-font ft-large fw-500"
                              >
                                <i class="fa fa-info-circle mr-2"></i>
                                <span>How can you prevent cavities?</span>
                              </p>
                            </mat-panel-title>
                            <div class="icons">
                              <mat-icon *ngIf="!cavitiesAccordionPanels[3]"
                                ><i
                                  class="fa fas fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </mat-icon>
                              <mat-icon *ngIf="cavitiesAccordionPanels[3]"
                                ><i
                                  class="fa fas fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </mat-icon>
                            </div>
                          </mat-expansion-panel-header>
                          <div class="faq-ans mt-8">
                            <p class="ft-medium fw-500">
                              The best way to prevent cavities is the use of
                              fluoride based products. Brushing twice a day and
                              flossing once a day will be used to break up
                              plaque and tartar build up.
                            </p>
                          </div>
                        </mat-expansion-panel>
                      </div>
                    </mat-accordion>
                  </div>
                </div>
              </ng-container>
            </section>

            <!--Bone health section -->
            <section
              *ngIf="isXraysExist"
              id="{{ BONE_HEALTH_SECTION_ID }}"
              class="your-bone-health report-generate-block"
              #bonehealth
            >
              <div class="kells-report-container">
                <div class="boneloss-chart">
                  <koa-boneloss-chart></koa-boneloss-chart>
                </div>
                <div class="boneloss-xrays">
                  <koa-xray-carousel
                    [title]="'YOUR BONE LOSS X-RAYS'"
                    [imageType]="SessionImageType.Xray"
                    [showCaries]="false"
                    [showBoneloss]="true"
                    [interactive]="true"
                    (onXrayOpen)="xrayOpenEvent.emit()"
                    [findingsByImage]="findingsByImage$ | async"
                  >
                  </koa-xray-carousel>
                </div>
                <div class="more-about-bone-health section-content">
                  <h3 class="report-header mb-5 fw-700">
                    LEARN MORE ABOUT BONE HEALTH
                  </h3>

                  <mat-accordion>
                    <div class="accordion-item">
                      <mat-expansion-panel
                        (opened)="setQAndABonelessExpansionPanelOpened(1, true)"
                        (closed)="
                          setQAndABonelessExpansionPanelOpened(1, false)
                        "
                        hideToggle="true"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <p
                              class="flex items-center ibmplex-semi-font ft-large fw-500"
                            >
                              <i class="fa fa-info-circle mr-2"></i>
                              <span> What is bone loss?</span>
                            </p>
                          </mat-panel-title>
                          <div class="icons">
                            <mat-icon *ngIf="!bonelossAccordionPanels[1]"
                              ><i class="fa fas fa-plus" aria-hidden="true"></i>
                            </mat-icon>
                            <mat-icon *ngIf="bonelossAccordionPanels[1]"
                              ><i
                                class="fa fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </mat-icon>
                          </div>
                        </mat-expansion-panel-header>
                        <div class="faq-ans mt-8">
                          <p class="ft-medium fw-500">
                            Separate from your teeth and gum, your bone health
                            is very important for your overall oral health. Good
                            bone health provides support and stability to your
                            teeth. As we age, we lose bone throughout our bodies
                            and this also occurs in our mouths. We can also have
                            accelerated amount of bone loss due to different
                            factors such as genetics, poor hygiene practices,
                            large restorations, and even pregnancies. The
                            dentist monitors the bone level whenever you take
                            x-rays to make sure the bone is at a healthy level.
                          </p>
                        </div>
                      </mat-expansion-panel>
                    </div>

                    <div class="accordion-item">
                      <mat-expansion-panel
                        (opened)="setQAndABonelessExpansionPanelOpened(2, true)"
                        (closed)="
                          setQAndABonelessExpansionPanelOpened(2, false)
                        "
                        hideToggle="true"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <p
                              class="flex items-center ibmplex-semi-font ft-large fw-500"
                            >
                              <i class="fa fa-info-circle mr-2"></i>
                              <span>How is bone loss treated?</span>
                            </p>
                          </mat-panel-title>
                          <div class="icons">
                            <mat-icon *ngIf="!bonelossAccordionPanels[2]"
                              ><i class="fa fas fa-plus" aria-hidden="true"></i>
                            </mat-icon>
                            <mat-icon *ngIf="bonelossAccordionPanels[2]"
                              ><i
                                class="fa fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </mat-icon>
                          </div>
                        </mat-expansion-panel-header>
                        <div class="faq-ans mt-8">
                          <p class="ft-medium fw-500">
                            There are two parts to treating bone loss. If the
                            loss is mild, the dentist will suggest a deep
                            cleaning to remove any tartar that will cause
                            further bone loss. The dentist will often suggest
                            more frequent cleaning in order to keep your gums
                            free of tartar. In more advanced cases, a specialist
                            may be called on to help regenerate the bone.
                          </p>
                        </div>
                      </mat-expansion-panel>
                    </div>

                    <div class="accordion-item">
                      <mat-expansion-panel
                        (opened)="setQAndABonelessExpansionPanelOpened(3, true)"
                        (closed)="
                          setQAndABonelessExpansionPanelOpened(3, false)
                        "
                        hideToggle="true"
                      >
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            <p
                              class="flex items-center ibmplex-semi-font ft-large fw-500"
                            >
                              <i class="fa fa-info-circle mr-2"></i>
                              <span>How can bone loss be prevented?</span>
                            </p>
                          </mat-panel-title>
                          <div class="icons">
                            <mat-icon *ngIf="!bonelossAccordionPanels[3]"
                              ><i class="fa fas fa-plus" aria-hidden="true"></i>
                            </mat-icon>
                            <mat-icon *ngIf="bonelossAccordionPanels[3]"
                              ><i
                                class="fa fas fa-minus"
                                aria-hidden="true"
                              ></i>
                            </mat-icon>
                          </div>
                        </mat-expansion-panel-header>
                        <div class="faq-ans mt-8">
                          <p class="ft-medium fw-500">
                            Bone loss is inevitable over time but the degree of
                            bone loss can be mitigated by keeping the teeth and
                            gums clean. This means keeping up with the daily
                            habit of cleaning and flossing. Oftentimes the bone
                            is very sensitive to tartar build-up and will result
                            in bone loss. Patients who are more susceptible to
                            loss, the doctor will often recommend more frequent
                            cleanings.
                          </p>
                        </div>
                      </mat-expansion-panel>
                    </div>
                  </mat-accordion>
                </div>
              </div>
            </section>

            <!-- Smile checklist -->
            <section
              id="{{ SMILE_CHECKLIST_SECTION_ID }}"
              class="smile-checklist report-generate-block"
              #smilechecklist
            >
              <div class="kells-report-container">
                <h1 class="report-header mb-5 fw-700 bottom-hb">
                  YOUR SMILE CHECKLIST
                </h1>
                <div class="smile-checklist-details section-content">
                  <p class="py-5 ft-medium fw-500">
                    Some of the best practices to maintain your oral health are:
                  </p>
                  <div
                    class="checklist flex xs:flex-wrap align-center justify-between"
                  >
                    <div
                      class="col-span-2 px-3 py-3 flex flex-col align-items-center justify-start items-center xs:w-1/2 xs:p-0 xs:my-3"
                    >
                      <div
                        class="flex flex-col align-center content-end items-center w-auto"
                      >
                        <img
                          src="/assets/punch/images/btad.svg"
                          class="w-16 h-16 mb-3 m-auto"
                          alt="Tooth brush"
                        />
                        <p class="fw-500 ft-extra-small text-center">
                          Brush twice a day
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-span-2 px-3 py-3 flex flex-col align-items-center justify-start items-center xs:w-1/2 xs:p-0 xs:my-3"
                    >
                      <div
                        class="flex flex-col align-center content-end items-center w-auto"
                      >
                        <img
                          src="/assets/punch/images/SUGRY.svg"
                          class="w-16 h-16 mb-3 m-auto"
                          alt="Sugary foods"
                        />
                        <p class="fw-500 ft-extra-small text-center">
                          Limit sugary foods &amp; starches
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-span-2 px-3 py-3 flex flex-col align-center items-center justify-start xs:w-1/2 xs:p-0 xs:my-3"
                    >
                      <div
                        class="flex flex-col align-center items-center content-end w-auto"
                      >
                        <img
                          src="/assets/punch/images/umr.svg"
                          class="w-16 h-16 mb-3 m-auto"
                          alt="Mouthwash"
                        />
                        <p class="fw-500 ft-extra-small text-center">
                          Use mouthwash regularly
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-span-2 px-3 py-3 flex flex-col align-center items-center justify-start xs:w-1/2 xs:p-0 xs:my-3"
                    >
                      <div
                        class="flex flex-col align-center content-end items-center w-auto"
                      >
                        <img
                          src="/assets/punch/images/FLOSS.svg"
                          class="w-16 h-16 mb-3 m-auto"
                          alt="Dental Floss"
                        />
                        <p class="fw-500 ft-extra-small text-center">
                          Floss once a day
                        </p>
                      </div>
                    </div>
                    <div
                      class="col-span-2 px-3 py-3 flex flex-col align-center justify-start items-center xs:w-1/2 xs:p-0 xs:my-3"
                    >
                      <div
                        class="flex flex-col align-center content-end items-center w-auto"
                      >
                        <img
                          src="/assets/punch/images/NO-SNOKING.svg"
                          class="w-16 h-16 mb-3 m-auto"
                          alt="Don't smoke"
                        />
                        <p class="fw-500 ft-extra-small text-center">
                          Avoid smoking
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <!-- Product recommendation section -->
            <section
              id="{{ PRODUCT_RECOMMENDATION_SECTION_ID }}"
              #productRecommendationSection
            >
              <kpr-product-recommendation-page
                class="product-recommendation-page"
                [latestReviewDate$]="selectedSessionDate$"
                [latestReviewId$]="selectedSessionId$"
                [isInreportRecommendation]="true"
                *ngIf="
                  !(isProductRecommendationsHidden$ | async) &&
                  (selectedSessionDate$ | async) &&
                  (selectedSessionId$ | async)
                "
              ></kpr-product-recommendation-page>
            </section>

            <!-- Find dentist section -->
            <section
              id="{{ FIND_A_DENTIST_SECTION_ID }}"
              #findDentistSection
              *ngIf="!(isZocDocHidden$ | async)"
              class="find-dentist-section omit-report-generation"
            >
              <div class="kells-report-container">
                <h1 class="report-header mb-5 fw-700 bottom-hb">
                  FIND A DENTIST
                </h1>
              </div>

              <div #searchWidgetContainer></div>
            </section>

            <!-- Footer section -->
            <section id="footer" class="footer report-generate-block" #footer>
              <div class="footer-content">
                <kpr-footer [loading]="true" [portal]="portal"></kpr-footer>
              </div>
            </section>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="kells-loading" *ngIf="isLoading$ | async">
  <div class="loading-conatiner flex flex-col justify-center items-center">
    <img
      src="/assets/punch/images/kells-loading-black-logo.gif"
      class="kells-gif"
      alt="kells-logo"
    />
    <p class="my-3 ft-large fw-500">LOADING</p>
  </div>
</div>

<ng-template
  #canvasTemplate
  let-imageUrl="imageUrl"
  let-imageId="imageId"
  let-findings$="findings$"
  let-isInsightEnabled$="isInsightEnabled$"
  let-isImageConfirmed$="isImageConfirmed$"
>
  <kells-canvas
    [attr.data-cy]="'readonly-template-image-preview'"
    [attr.data-id]="imageId"
    [url]="imageUrl"
    [findings]="(activeTooth$ | async)?.advanced"
    [showFindings]="true"
    [sizingPriority]="'sameSize'"
    defaultCursor="pointer"
    (click)="forwardImageClickEvent(imageId)"
    isThumbnail="true"
  >
    <div
      topLeftBadge
      class="confirmed-badge"
      *ngIf="(isInsightEnabled$ | async) && (isImageConfirmed$ | async)"
    >
      <i class="fa fas fa-lock"></i>
      Confirmed
    </div>
  </kells-canvas>
</ng-template>

<ng-container *ngLet="data.images$ | async as images">
  <svg style="position: fixed; left: -9999rem; top: -999rem">
    <defs>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-1"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M74,64.92v.58l.41.4a54.4,54.4,0,0,1,15.87,34.25c1,11.5-1.34,21.57-4.4,26.43-2.92,4.63-7.15,5.75-11.54,5.34s-9-2.41-12-3.89c-6.64-3.25-12.73-2-22.49,1.91-6.25,2.5-11.16,2-15.23.32s-7.5-4.85-10.55-7.83c-5.44-5.33-7.36-18.08,2.11-31.37,3.39-4.76,3.68-9.45,2.77-14.62a82.3,82.3,0,0,0-2-8.15l-.15-.53C16,65,15.15,62,14.42,58.77c-4.32-19.29,0-32.39,7-50.6a17.41,17.41,0,0,1,2-3.93A3,3,0,0,1,24.77,3a.91.91,0,0,1,.82.17A5.37,5.37,0,0,1,27,4.65a25.8,25.8,0,0,1,2.66,5.85,53.75,53.75,0,0,1,1.6,5.95c.2,1,.38,2.18.57,3.49q.13.86.27,1.77a48,48,0,0,0,1.24,6,10.65,10.65,0,0,0,2.48,4.74,4.19,4.19,0,0,0,2.35,1.15,5,5,0,0,0,2.8-.56,7.74,7.74,0,0,0,3.82-4.51A21.19,21.19,0,0,0,45.82,22c.08-2.34,0-4.84-.16-7.29,0-.33,0-.66,0-1-.11-2.12-.22-4.16-.22-6.06,0-4.24,1.45-6.61,2.74-7.37A1.92,1.92,0,0,1,49.92.15a4.51,4.51,0,0,1,2,2.2c1.43,2.81,3.54,9.88,5.31,17.5s3.1,15.43,3.1,19.73a7.55,7.55,0,0,0,.12,1.52,2.79,2.79,0,0,0,.23.69,1.69,1.69,0,0,0,.85.79,1.59,1.59,0,0,0,1.27-.06,2.1,2.1,0,0,0,.61-.45,5.24,5.24,0,0,0,.72-1c.54-.9,1.24-2.35,2-3.94.42-.87.85-1.77,1.3-2.66a31.54,31.54,0,0,1,4.18-6.75,3.81,3.81,0,0,1,1.68-1.23c.27-.07.59-.06,1.06.41,1.6,1.56,2.52,4.24,2.88,7.76a55.27,55.27,0,0,1-.36,11.59c-.49,4-1.21,8-1.82,11.3,0,.13,0,.26-.07.39-.27,1.49-.52,2.83-.7,4A20.53,20.53,0,0,0,74,64.92Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-2"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M7.52,112.81v-.1h0v-.06a1.94,1.94,0,0,1,0-.27c0-.24,0-.6,0-1.07A25.58,25.58,0,0,1,8,107.44a26.61,26.61,0,0,1,5-11.33h0a42.63,42.63,0,0,0,6.75-14.6c1.37-5.32,1.77-10.85.64-15.3-.32-1.82-.92-4.19-1.62-6.92l-.39-1.5c-.87-3.42-1.84-7.4-2.64-11.77-1.6-8.75-2.47-19-.36-29.44h0c.87-4.34,3.11-7,5-7.67a2.33,2.33,0,0,1,2.33.3,6.59,6.59,0,0,1,2,3.84c.53,2.63.91,4.82,1.23,6.7l.12.7c.27,1.58.5,2.94.75,4.14a16,16,0,0,0,2.86,6.92,16.94,16.94,0,0,0,1.58,2,5.31,5.31,0,0,0,1.56,1.22,2.34,2.34,0,0,0,2,0,2.85,2.85,0,0,0,1.28-1.48,14.65,14.65,0,0,0,.76-4.81c.08-2.1,0-4.78-.17-8h0A59.49,59.49,0,0,0,35.08,9.85c-.21-.91-.39-1.72-.52-2.45A8.12,8.12,0,0,1,34.48,4,6.9,6.9,0,0,1,36.38.78c.78-.82,2-1.07,3.62-.37a13.9,13.9,0,0,1,5.48,5C49.11,10.6,52,19.33,50.79,30.63c0,0,0,.08,0,.13A71.7,71.7,0,0,0,53,47.5a21.56,21.56,0,0,0,2.45,6.14,4.42,4.42,0,0,0,1.91,1.7A2.65,2.65,0,0,0,60,54.89a1.27,1.27,0,0,0,.5-1.08c-.6-10.23-.2-17,.7-21.31A14.43,14.43,0,0,1,62.73,28a2.63,2.63,0,0,1,1.45-1.34,1.76,1.76,0,0,1,1.36.3A6.4,6.4,0,0,1,67.3,28.7a12.13,12.13,0,0,1,2.13,5.93c1.45,24.53,8,39.93,15.93,53,3.92,6.79,6.66,13.23,7.08,18.63a13.18,13.18,0,0,1-1.17,7.07,12.19,12.19,0,0,1-5.41,5.2h0l0,0a18.68,18.68,0,0,0-7,6.67l-.06.1a15.34,15.34,0,0,1-1.83,2.42,10.24,10.24,0,0,1-3.11,2.15,12.57,12.57,0,0,1-9.19.05,35.22,35.22,0,0,1-4.33-1.85l-1.4-.7c-.9-.45-1.79-.9-2.62-1.28-4.85-2.56-11.78-1.58-18.4,2.32a1.16,1.16,0,0,0-.21.16,16,16,0,0,1-6.57,2.75,26.05,26.05,0,0,1-10.33.26,15.34,15.34,0,0,1-9.16-5.13C9.17,123.59,7.52,119.24,7.52,112.81Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-3"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M13,112.77v-.09h0v-.07c0-.06,0-.15,0-.27s0-.61,0-1.08a29.17,29.17,0,0,1,.4-3.88A28.21,28.21,0,0,1,18,96h0a44.44,44.44,0,0,0,6.1-14.55c1.24-5.3,1.6-10.79.58-15.21-.29-1.86-.84-4.24-1.49-7C20.82,48.92,17.13,33,20.15,16.58h0a14.74,14.74,0,0,1,1.94-5.25,6,6,0,0,1,2.57-2.45,1.8,1.8,0,0,1,2,.29,6.87,6.87,0,0,1,1.86,3.88c.48,2.63.83,4.83,1.12,6.71,0,.23.07.47.11.7.24,1.57.45,2.93.67,4.12A16.64,16.64,0,0,0,33,31.45a16.29,16.29,0,0,0,1.42,1.95,5.14,5.14,0,0,0,1.44,1.23,2.06,2.06,0,0,0,2,0A2.66,2.66,0,0,0,39,33.2a15.46,15.46,0,0,0,.68-4.74q.1-3.13-.15-8h0A64.46,64.46,0,0,0,38,10c-.19-.92-.36-1.74-.48-2.47A9.16,9.16,0,0,1,37.47,4,7.23,7.23,0,0,1,39.21.76,2,2,0,0,1,40.5,0a3.4,3.4,0,0,1,1.85.37,13.05,13.05,0,0,1,4.95,5c3.31,5.23,5.9,14,4.84,25.32v.11a77.79,77.79,0,0,0,2,16.68,22.37,22.37,0,0,0,2.2,6.1,4.13,4.13,0,0,0,1.76,1.7,2.25,2.25,0,0,0,1.34.15,2.54,2.54,0,0,0,1.2-.61,1.17,1.17,0,0,0,.42-1c-.53-10.23.06-16.65,1.19-20.55s2.68-5,3.85-5.16a6.09,6.09,0,0,1,4.29,1.47A14.53,14.53,0,0,1,72,31l.46.46.08.08c5.77,9.73,5.81,21.51,4.32,31a82,82,0,0,1-2.8,11.81c-.46,1.47-.88,2.64-1.18,3.44-.15.4-.27.7-.36.91l-.09.23,0,.06h0a1.2,1.2,0,0,0,0,1c3.58,6.84,8.89,17.17,12,26.54A41.57,41.57,0,0,1,87,119.08c0,3.51-1,5.87-3.06,7.07h0l0,0a19.88,19.88,0,0,1-3.67,2,4.35,4.35,0,0,1-1.68.31h-.27a8.18,8.18,0,0,0-1.8.12A13,13,0,0,0,73,129.9a10.2,10.2,0,0,1-8.21,0,29.57,29.57,0,0,1-3.91-1.84l-1.27-.7c-.81-.45-1.62-.9-2.37-1.28-4.47-2.59-10.83-1.58-16.84,2.33a1,1,0,0,0-.2.16,13.7,13.7,0,0,1-5.91,2.72,21.43,21.43,0,0,1-9.29.27,13.58,13.58,0,0,1-8.26-5.11C14.52,123.6,13,119.23,13,112.77Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-4"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M31.57,87.27l0,0h0l.05-.1c0-.08.08-.21.15-.36.13-.32.31-.77.53-1.35.44-1.15,1-2.79,1.57-4.74A44.61,44.61,0,0,0,35.8,66.53,37.63,37.63,0,0,1,37.19,52.8c.48-1.95,1.05-4.27,1.67-7.44a81.52,81.52,0,0,0,.93-18.17,135.31,135.31,0,0,0-1.43-14.9h0v0a32.58,32.58,0,0,1-.47-5.36,16.41,16.41,0,0,1,.67-5.38,2.87,2.87,0,0,1,.8-1.28A1.25,1.25,0,0,1,40.43,0c1.15.23,3,1.5,5.84,5l0,0c2.15,2.42,4.14,6.52,5.92,11.68A137.1,137.1,0,0,1,56.74,34.1,226.78,226.78,0,0,1,60.9,66.65v0a47.76,47.76,0,0,0,1.72,11.85,31.76,31.76,0,0,0,1.91,4.69c.21.44.41.86.59,1.27a19.59,19.59,0,0,0,.94,1.73c.08.14.17.27.25.42.32.54.67,1.13,1,1.79a36.41,36.41,0,0,1,2.86,6.35c1.72,5.15,2.73,12.35.48,22.16-2.18,9.46-8.92,14.22-16.53,15A24.41,24.41,0,0,1,33,122.68c-5.09-6.54-5.51-15.29-4.57-22.61a59.09,59.09,0,0,1,2-9.18c.34-1.16.65-2.08.88-2.72l.26-.72C31.53,87.37,31.56,87.31,31.57,87.27Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-5"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M36.61,14.5h0c-.27-5.23.25-8.8,1.15-11.09S39.87.26,41,.05a5.42,5.42,0,0,1,4.19,1.47,14.44,14.44,0,0,1,4,5.32C53.5,16.39,60.53,34,60.53,53.65c0,9.93.27,14.73,1.76,19.55a88.65,88.65,0,0,0,5.05,11.61c1.07,2.2,2.29,4.73,3.7,7.73C80,111.77,67.27,132,49.67,132a21.68,21.68,0,0,1-18.92-10.35,30.87,30.87,0,0,1-4.54-19.86c.8-6.34,2.51-10.32,4.49-14.85l.06-.12A78.91,78.91,0,0,0,36.59,69c1.67-8.38,1.66-22,1.25-33.52-.2-5.75-.51-11-.77-14.79-.13-1.9-.24-3.45-.33-4.51l-.09-1.23,0-.32Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-6"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M54.9,50.34c1.16,6.89,2.57,15.19,4.34,24.59v0l0,.13c0,.12,0,.28.07.5.07.42.17,1,.3,1.79.27,1.51.7,3.58,1.3,5.84C62.1,87.61,64,92.93,67,96.06a36.73,36.73,0,0,0,2.4,4.18l.48.75a45.34,45.34,0,0,1,3.48,6.38c2,4.6,2.7,9.86-.83,15.53A19.43,19.43,0,0,1,65.59,130a17.38,17.38,0,0,1-8.15,2,57.4,57.4,0,0,1-10-.88l-2.71-.42c-2.87-.45-5.95-.94-9.2-1.29-4.5-.88-7.19-3-8.73-5.67A16.92,16.92,0,0,1,25.07,114a38,38,0,0,1,2.28-10.1,25.81,25.81,0,0,1,3.1-6.26c1.88-2.38,3.05-6.26,3.9-10.56.78-3.89,1.32-8.28,1.83-12.48l.18-1.44c.59-4.72.58-13.1.44-20.24-.07-3.58-.18-6.87-.27-9.27-.05-1.19-.09-2.17-.12-2.84q0-.51,0-.78V39.8c0-1.12-.12-3.1-.28-5.57-.05-.95-.12-2-.18-3-.23-3.92-.45-8.58-.44-13.12a65.52,65.52,0,0,1,.94-12.28,14.79,14.79,0,0,1,1.34-4A3.17,3.17,0,0,1,39.5.08a2,2,0,0,1,1.82.38,7.94,7.94,0,0,1,2.07,2.47,42.46,42.46,0,0,1,3.74,9.65c1.08,3.81,2,7.92,2.74,11.54l.71,3.51c.41,2.08.77,3.84,1.05,5,.86,3.51,1.8,9,3,16.2C54.73,49.34,54.82,49.84,54.9,50.34Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-7"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M40.28,74.26a1.07,1.07,0,0,0,.43-.85c0-6.46-.46-16-.94-25.8-.17-3.54-.34-7.11-.49-10.62-.3-6.64-.53-13-.55-18.28a63.16,63.16,0,0,1,.63-11.57,18.71,18.71,0,0,1,2.14-6,2.71,2.71,0,0,1,1-1A1.25,1.25,0,0,1,43.39,0c.81.18,1.94,1,3.47,2.62a60.58,60.58,0,0,1,5.2,6.91C60,21.31,70.56,47.29,65.26,80.41a1,1,0,0,0,.34,1,40.28,40.28,0,0,1,8.71,11.49c2.47,5,3.69,10.64,1.69,16a1.08,1.08,0,0,0,0,.59c.87,3.88,1.24,9.63-.37,14.35a12.11,12.11,0,0,1-4,5.84,12.59,12.59,0,0,1-8,2.38c-9.31,0-11.86-.6-13.72-1.15l-.47-.14a19.11,19.11,0,0,0-2.93-.71,44.88,44.88,0,0,0-6.93-.39c-6.34,0-11.35-2.82-14.14-6.7a12,12,0,0,1-.94-13.19,25.69,25.69,0,0,0,2.62-8.28c.2-1.13.37-2.26.54-3.39.27-1.84.55-3.7,1-5.63A28.33,28.33,0,0,1,40.28,74.26Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-8"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M46.75,15.19c.52-3.84,1.66-8.68,3.42-11.89A6.62,6.62,0,0,1,53,.21c.89-.38,2-.32,3.48.82l0,0C58.31,2.31,59.8,5,61,8.9a81.79,81.79,0,0,1,2.71,13.75c1,7.63,1.41,15.92,1.8,22.89.13,2.43.26,4.69.39,6.72.52,7.81,1,14.3,1.31,18.83.17,2.27.3,4,.39,5.26,0,.61.08,1.08.11,1.39l0,.36v.11h0v-.06h0l1.08-.09-1.08.09a1.1,1.1,0,0,0,.47.8h0l.07,0,.3.25a10.84,10.84,0,0,1,1.14,1.14,21.52,21.52,0,0,1,3.38,5.31c2.36,5.07,4.42,13.53,2.94,26.86-.92,8.35-4.66,13.15-9.1,15.76a22,22,0,0,1-14,2.49h-.07l-.22,0c-4.37-.32-11.23-.82-17.23-3.57A19.32,19.32,0,0,1,23.54,110c-.78-9.54,3.29-17.29,7.64-22.71a45.9,45.9,0,0,1,6.07-6.22c.83-.71,1.54-1.25,2-1.61l.57-.41L40,79l0,0h0a1.06,1.06,0,0,0,.45-.61c2.66-9.87,4-25.47,4.63-38.52.33-6.55.49-12.48.57-16.78,0-2.15.07-3.89.08-5.09V16.56l1-1.33Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-9"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M48.58,130.5h0l.06,0,.21.07.85.25c.73.2,1.78.46,3,.69a26.9,26.9,0,0,0,9.19.13,14.26,14.26,0,0,0,8.91-5.07c2.42-3,4.12-7.74,4.12-15v0h0C75.24,98.37,72.49,90,69.75,85A24.47,24.47,0,0,0,66,79.79a13.32,13.32,0,0,0-1.21-1.11c-.14-.12-.25-.2-.32-.25l0,0h0a1.13,1.13,0,0,1-.52-.86c-.48-7.92-2.22-16.6-4.05-25.77-.12-.61-.25-1.23-.37-1.84-2-9.82-3.93-20.15-4.46-30.48S53.24,4.29,51.94,2A3.58,3.58,0,0,0,50.18.24a5.2,5.2,0,0,0-2-.24h0a1.87,1.87,0,0,0-.68.42,9.37,9.37,0,0,0-1.59,1.76A35.85,35.85,0,0,0,42,9.23a71.91,71.91,0,0,0-5.57,24.39c-1.06,19.85-1.73,33.54,0,43.64a1.13,1.13,0,0,1-.18.8l-1-.62,1,.62h0v0l0,.07-.19.29-.71,1c-.59.89-1.39,2.12-2.25,3.47a56,56,0,0,0-4.32,7.81c-1.55,4-5.68,18.11-2.59,30.75,0,0,0,0,0,.08.64,3.81,1.77,6.14,3.11,7.58a7.54,7.54,0,0,0,4.92,2.26,28.92,28.92,0,0,0,6.63-.32l.91-.12a50.15,50.15,0,0,1,6.49-.57Zm.48,0Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-10"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M27.21,104.41a1.12,1.12,0,0,0,0-.32,15.81,15.81,0,0,1,.8-4.09c.51-1.71,1.23-3.67,2.06-5.71,1.65-4.07,3.7-8.39,5.28-11.59a1.16,1.16,0,0,0,.12-.44l-1.1-.06,1.1.06h0v-.13l0-.4c0-.35,0-.85.06-1.47,0-1.24.11-3,.15-4.89a113,113,0,0,0-.24-11.8,112.55,112.55,0,0,1-.16-18.73c.63-8.42,2.36-18,6.4-26.2s7.81-12.92,11-15.51S58.48,0,60.46,0a1.19,1.19,0,0,1,.72.18,1.08,1.08,0,0,1,.38.56,8.59,8.59,0,0,1,.11,3.54,94.71,94.71,0,0,1-2.08,10.05l-.84,3.54c-2.19,9.41-2.47,21.22-1.42,32.55s3.44,22.31,6.66,30a.75.75,0,0,0,.1.2h0l0,0,0,.08.19.3c.17.26.42.65.72,1.15.61,1,1.44,2.39,2.33,4.05a49.71,49.71,0,0,1,4.69,11.67c1,4.19,3,14.18,1.11,22.29-1,4-2.88,7.45-6.32,9.55s-8.64,3-16.37,1.45a1.37,1.37,0,0,0-.35,0c-8.27,1-13.72-.23-17.27-2.5a14.49,14.49,0,0,1-6.24-9.21,29.63,29.63,0,0,1-.37-10.27c.18-1.44.41-2.65.6-3.5.1-.42.18-.76.24-1s.05-.2.07-.25v-.08Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-11"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M44.71,51.63c-1.15,7.06-2.52,15.57-4.26,25.21v0l0,.14c0,.12,0,.29-.07.5-.06.44-.16,1.07-.29,1.84-.27,1.55-.68,3.67-1.27,6-1.16,4.51-3,10-5.95,13.17a35.7,35.7,0,0,1-2.36,4.28c-.15.26-.31.52-.47.78a48.29,48.29,0,0,0-3.41,6.54c-1.92,4.72-2.65,10.14.82,16,3.36,5.65,9.27,6.34,16.19,5.78,1.72-.14,3.47-.35,5.24-.58l.75-.09c1.51-.2,3-.39,4.53-.54a31,31,0,0,1,9.76.19c5,0,7.83-1.66,9.37-4s2-5.75,1.58-9.41A42.37,42.37,0,0,0,72,106.82a33.57,33.57,0,0,0-3.4-6.7c-1.84-2.43-3-6.4-3.82-10.81-.76-4-1.29-8.47-1.79-12.78l-.18-1.48c-.57-4.83-.57-13.42-.43-20.74.07-3.67.18-7.05.27-9.5,0-1.23.08-2.23.11-2.92,0-.35,0-.62,0-.8v-.26c0-1.14.13-3.17.27-5.71.06-1,.12-2,.18-3.11.23-4,.44-8.8.44-13.46A69.93,69.93,0,0,0,62.79,6,15.6,15.6,0,0,0,61.47,1.8,3.17,3.17,0,0,0,59.77.08,1.88,1.88,0,0,0,58,.46,8.09,8.09,0,0,0,56,3a45.36,45.36,0,0,0-3.66,9.9c-1.07,3.92-2,8.13-2.69,11.84-.25,1.27-.48,2.48-.7,3.6-.41,2.14-.75,3.94-1,5.14-.84,3.6-1.77,9.25-3,16.62Q44.83,50.85,44.71,51.63Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-12"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M64,12.83V13l0,.43c0,.37-.07.92-.13,1.62-.11,1.41-.27,3.43-.44,5.92-.35,5-.76,11.79-1,19.15s-.42,15.27-.21,22.45.76,13.65,1.9,18.13a46.52,46.52,0,0,0,6.08,14.09c.51.84,1,1.64,1.43,2.44a18,18,0,0,1,2.19,6.33,38,38,0,0,1-.32,10.75c-1,6.77-2.58,11-4.48,13.7a8.82,8.82,0,0,1-6.28,3.94,18.42,18.42,0,0,1-7.33-.94c-.86-.25-1.69-.51-2.51-.78l-1.11-.35a27.48,27.48,0,0,0-3.35-.89,22.93,22.93,0,0,0-3.4-.28l-1.25,0c-.79,0-1.59,0-2.41-.06A16.77,16.77,0,0,1,34.14,127c-4.26-2.27-8.13-8-8.14-23.59a21.56,21.56,0,0,1,3-9.72,37.74,37.74,0,0,1,4.33-5.44l1-1.15A30.34,30.34,0,0,0,39.9,79,35.77,35.77,0,0,0,42.59,65.1,199.84,199.84,0,0,1,47,28.81c2.48-11.46,5.76-21,9.19-25.62C57.94.81,59.23.09,60,0s1.36.29,2.09,1.52a14.91,14.91,0,0,1,1.61,5.06A28.49,28.49,0,0,1,64,12.83Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-13"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M57.59,4.07h0a.71.71,0,0,0,.1-.14c1.62-2.78,3-3.74,3.9-3.9.76-.14,1.66.22,2.6,1.65a16.08,16.08,0,0,1,2.07,6.23,30.57,30.57,0,0,1,0,8.76,104.79,104.79,0,0,0-.79,21.47c.09,2,.18,3.88.18,5.72V44c.12,1.36.23,2.65.34,3.9.47,5.35.86,9.8.86,13.78v.14c0,6.3,0,13,3.79,19,2.58,4.4,4.2,10.93,4.35,17.81s-1.19,13.93-4.36,19.37a1.56,1.56,0,0,0-.15.41c-.88,5.34-2.56,8.72-4.65,10.77A10.54,10.54,0,0,1,58.18,132a29,29,0,0,1-10-2.14A97.21,97.21,0,0,1,37.57,125c-7-3.63-11-6.41-12.19-11.44-.61-2.57-.51-5.84.52-10.27a108.94,108.94,0,0,1,5.94-17,12.79,12.79,0,0,1,1.53-2.38l.52-.71A28.94,28.94,0,0,0,37,77.77C39.16,72.94,41,65.29,41,51.69c0-13.28,4.14-25.18,8.32-33.8A92,92,0,0,1,55,7.74C55.8,6.54,56.45,5.62,56.9,5c.22-.31.4-.55.52-.7l.13-.18,0,0Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-14"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M87,112.77v-.09h0v-.07c0-.06,0-.15,0-.27s0-.61,0-1.08a29.17,29.17,0,0,0-.4-3.88A28.21,28.21,0,0,0,82,96h0a44.44,44.44,0,0,1-6.1-14.55c-1.24-5.3-1.6-10.79-.58-15.21.29-1.86.84-4.24,1.49-7,2.38-10.27,6.07-26.15,3-42.61h0a14.74,14.74,0,0,0-1.94-5.25,6,6,0,0,0-2.57-2.45,1.8,1.8,0,0,0-2,.29,6.87,6.87,0,0,0-1.86,3.88c-.48,2.63-.83,4.83-1.12,6.71,0,.23-.07.47-.11.7-.24,1.57-.45,2.93-.67,4.12A16.64,16.64,0,0,1,67,31.45a16.29,16.29,0,0,1-1.42,1.95,5.14,5.14,0,0,1-1.44,1.23,2.06,2.06,0,0,1-2,0A2.66,2.66,0,0,1,61,33.2a15.46,15.46,0,0,1-.68-4.74q-.1-3.13.15-8h0A64.46,64.46,0,0,1,62,10c.19-.92.36-1.74.48-2.47A9.36,9.36,0,0,0,62.53,4,7.16,7.16,0,0,0,60.78.76,1.93,1.93,0,0,0,59.5,0a3.4,3.4,0,0,0-1.85.37,13.05,13.05,0,0,0-4.95,5c-3.31,5.23-5.9,14-4.84,25.32v.11a77.86,77.86,0,0,1-2,16.68,22.37,22.37,0,0,1-2.2,6.1,4.13,4.13,0,0,1-1.76,1.7,2.25,2.25,0,0,1-1.34.15,2.54,2.54,0,0,1-1.2-.61,1.27,1.27,0,0,1-.42-1c.53-10.19-.07-16.59-1.19-20.48s-2.68-5-3.85-5.16a6.09,6.09,0,0,0-4.29,1.47A14.53,14.53,0,0,0,28,31c-.2.19-.35.35-.46.46l-.08.08c-5.77,9.73-5.81,21.51-4.32,31A82,82,0,0,0,26,74.32c.46,1.47.88,2.64,1.18,3.44.15.4.27.7.36.91l.09.23,0,.06h0a1.2,1.2,0,0,1,0,1c-3.58,6.84-8.89,17.17-12,26.54A41.57,41.57,0,0,0,13,119.08c0,3.51,1,5.87,3.06,7.07h0l0,0a19.88,19.88,0,0,0,3.67,2,4.35,4.35,0,0,0,1.68.31h.27a8.18,8.18,0,0,1,1.8.12A13,13,0,0,1,27,129.9a10.2,10.2,0,0,0,8.21,0,29.57,29.57,0,0,0,3.91-1.84l1.27-.7c.81-.45,1.62-.9,2.37-1.28,4.47-2.59,10.83-1.58,16.84,2.33a1,1,0,0,1,.2.16,13.7,13.7,0,0,0,5.91,2.72,21.43,21.43,0,0,0,9.29.27,13.58,13.58,0,0,0,8.26-5.11C85.48,123.6,87,119.23,87,112.77Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-15"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M94,113.32v-.16h0v-.31c0-.23,0-.58,0-1a23.62,23.62,0,0,0-.45-3.78A25.58,25.58,0,0,0,88.39,96.9v0a42.8,42.8,0,0,1-7.23-15c-1.45-5.43-1.9-11.15-.68-15.82.34-1.87,1-4.29,1.71-7,.13-.49.27-1,.4-1.51C83.5,54,84.52,50,85.35,45.65c1.67-8.79,2.57-19.06.39-29.46h0C84.85,12,82.61,9.54,81,9a1.86,1.86,0,0,0-1.86.22,5.85,5.85,0,0,0-1.85,3.45c-.56,2.64-1,4.85-1.29,6.74,0,.24-.09.48-.13.71-.28,1.59-.53,3-.79,4.21a16.56,16.56,0,0,1-3.14,7.29,18.15,18.15,0,0,1-1.72,2.05A6.45,6.45,0,0,1,68.33,35a3.12,3.12,0,0,1-2.68,0A3.63,3.63,0,0,1,64,33.19a14.78,14.78,0,0,1-.86-5.14q-.12-3.24.17-8.18h0A59.4,59.4,0,0,1,65,9c.21-.91.4-1.7.53-2.39a7.37,7.37,0,0,0,.1-3.17A6.28,6.28,0,0,0,63.85.59c-.56-.57-1.51-.87-3.1-.23a13.79,13.79,0,0,0-5.46,4.81c-3.71,5.11-6.65,13.74-5.45,25v.21A70.5,70.5,0,0,1,47.54,47.5a21.68,21.68,0,0,1-2.68,6.43,5.12,5.12,0,0,1-2.33,2A3.55,3.55,0,0,1,39,55.23a1.9,1.9,0,0,1-.64-1.59c.62-10.32.2-17.1-.72-21.34A13.54,13.54,0,0,0,36.06,28c-.53-.83-1-1-1.12-1.06a1.28,1.28,0,0,0-.95.23,6,6,0,0,0-1.65,1.61,11.28,11.28,0,0,0-2.1,5.59C28.72,59.27,21.82,75,13.39,88.25c-4.1,6.85-6.9,13.23-7.33,18.51a12.19,12.19,0,0,0,1.15,6.74,11.86,11.86,0,0,0,5.38,5h0l.07,0a19.91,19.91,0,0,1,7.64,7l.06.08a15.71,15.71,0,0,0,1.84,2.37,10.45,10.45,0,0,0,3.07,2,13,13,0,0,0,9.22,0A34.27,34.27,0,0,0,39,128.11l1.45-.7c1-.46,1.9-.92,2.78-1.31,5.37-2.7,12.88-1.62,19.93,2.38a1.94,1.94,0,0,1,.33.23,16.4,16.4,0,0,0,6.61,2.63,27.87,27.87,0,0,0,10.61.27,15.62,15.62,0,0,0,9.25-5C92.29,123.88,94,119.68,94,113.32Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-16"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M26.33,64.92v.58l-.41.4a54.62,54.62,0,0,0-15.67,34.25c-1,11.5,1.32,21.57,4.34,26.43,2.89,4.63,7.06,5.75,11.4,5.34s8.86-2.41,11.85-3.89c6.56-3.25,12.57-2,22.22,1.91,6.17,2.5,11,2,15,.32s7.41-4.85,10.41-7.83c5.38-5.33,7.28-18.08-2.07-31.37-3.36-4.76-3.65-9.45-2.75-14.62.44-2.55,1.18-5.25,2-8.15l.15-.53c.76-2.76,1.59-5.72,2.31-9,4.26-19.29,0-32.39-6.91-50.6a17.62,17.62,0,0,0-2-3.93A3,3,0,0,0,74.92,3a.88.88,0,0,0-.81.17,5.07,5.07,0,0,0-1.34,1.45,25.39,25.39,0,0,0-2.63,5.85,53.1,53.1,0,0,0-1.58,5.95c-.2,1-.37,2.18-.57,3.49q-.12.86-.27,1.77a48,48,0,0,1-1.21,6,10.72,10.72,0,0,1-2.46,4.74,4.1,4.1,0,0,1-2.32,1.15A4.85,4.85,0,0,1,59,33a7.75,7.75,0,0,1-3.78-4.51A21.5,21.5,0,0,1,54.13,22c-.08-2.34,0-4.84.15-7.29l.06-1c.11-2.12.22-4.16.22-6.06,0-4.24-1.44-6.61-2.72-7.37A1.86,1.86,0,0,0,50.08.15a4.44,4.44,0,0,0-2,2.2c-1.42,2.81-3.5,9.88-5.25,17.5s-3.07,15.43-3.07,19.73a8.44,8.44,0,0,1-.11,1.52,2.79,2.79,0,0,1-.23.69,1.63,1.63,0,0,1-.84.79,1.54,1.54,0,0,1-1.25-.06,2,2,0,0,1-.6-.45,5.24,5.24,0,0,1-.72-1c-.53-.9-1.22-2.35-2-3.94-.41-.87-.84-1.77-1.28-2.66a32,32,0,0,0-4.13-6.75A3.84,3.84,0,0,0,27,26.5c-.27-.07-.58-.06-1,.41-1.58,1.56-2.49,4.24-2.84,7.76a55.27,55.27,0,0,0,.36,11.59c.47,4,1.18,8,1.78,11.3l.08.39c.27,1.49.51,2.83.69,4A20.53,20.53,0,0,1,26.33,64.92Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-17"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M92.14,13.93h0v0C89.38,4.74,81.6,1.59,73.73,1.07a42.45,42.45,0,0,0-10.84.75,33,33,0,0,0-3.86,1,8.15,8.15,0,0,0-1.83.78,17.17,17.17,0,0,1-6.9,3.5,10.78,10.78,0,0,1-8.56-2l-.06,0C39,3.19,35.05.48,30.23.06S19.59,1.41,12.9,9.33A23.66,23.66,0,0,0,7.3,20.41,22.1,22.1,0,0,0,8,30.46,64.73,64.73,0,0,0,11.8,40l.19.4A57.07,57.07,0,0,1,15.48,49a102.74,102.74,0,0,1,2.9,15.2c0,.25.07.5.1.76.8,5.82,1.9,13.93,4.47,28.15,2.67,14.79,6.31,24.18,10.32,30s8.3,8.09,12.49,8.77h0c1.85.32,2.84,0,3.41-.57a4.64,4.64,0,0,0,1.13-3.21c.19-3.24-1-7.86-2.92-12.26l0-.06,0-.06c-3.14-9.89-3.21-15.18-3.21-26.37a10.9,10.9,0,0,1,1.72-6.55,4.81,4.81,0,0,1,2.25-1.67,4.72,4.72,0,0,1,2.81,0,10.73,10.73,0,0,1,5.3,4.26,35.3,35.3,0,0,1,4.63,9.52c1.25,3.76,2,6.36,2.69,8.63C65,108.58,66,112,70.78,122.71A5.91,5.91,0,0,0,77,126.38c2.69-.39,6.16-2.81,9-9.38a88.43,88.43,0,0,1,.78-10.3l.27-2.23c.33-2.66.66-5.23.83-7.79.45-6.5,0-12.61-3.67-18.58C80.21,71.39,79.67,63.26,81,55.51a60.35,60.35,0,0,1,8-21h0l0,0a24.7,24.7,0,0,0,4-14,28.6,28.6,0,0,0-.45-4.78c-.11-.57-.22-1-.29-1.33,0-.15-.08-.26-.1-.33l0-.08Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-18"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M91.74,17.28h0v-.07c-.89-6.92-3.39-11.14-6.53-13.66A17.86,17.86,0,0,0,74,0,38.18,38.18,0,0,0,62.44,1.88a41.9,41.9,0,0,0-4.15,1.55,13.68,13.68,0,0,0-2,1.06,16.51,16.51,0,0,1-6.63,3.38A10.38,10.38,0,0,1,41.39,6l-.06,0c-2.61-1.82-6.37-4.43-11-4.84S20.1,2.37,13.67,10A22.81,22.81,0,0,0,8.29,20.69,21.41,21.41,0,0,0,9,30.39a62.58,62.58,0,0,0,3.66,9.15l.19.4a55.47,55.47,0,0,1,3.35,8.3c1.29,4.71.59,8.14-.24,12.21-.19.92-.38,1.87-.56,2.87-1,5.51-1.73,13.26.83,27.45C21.25,119,40,130.58,48.27,131.91h0a3.22,3.22,0,0,0,1.75-.07,1.47,1.47,0,0,0,.78-.72,8.1,8.1,0,0,0,.33-4.48c-.53-4.18-2.49-9.66-4.33-13.94l0-.06,0-.06c-3-9.54-3.09-14.63-3.09-25.43,0-5.47,2.72-9.71,6.66-11.52s8.86-1.07,12.9,2.86a23.53,23.53,0,0,1,4.87,7.66,85.31,85.31,0,0,1,3.43,10.18c.41,1.43.81,2.88,1.21,4.35a135.39,135.39,0,0,0,6.13,18.64v0c1.44,3.66,3,5.65,4.37,6.53a3,3,0,0,0,3.54,0,9.93,9.93,0,0,0,3.37-4.86,25.41,25.41,0,0,0,1.48-8.89c0-2.6.08-5.29.16-8,.14-4.62.28-9.41,0-14.19-.35-7.57-1.61-14.77-5.13-20.58C83,63.11,81.2,57.13,81.3,51.27s2-11.48,5.46-17h0l0,0a29.79,29.79,0,0,0,4.86-12.18,20.08,20.08,0,0,0,.21-3.57c0-.41,0-.73-.07-.93s0-.17,0-.22v0Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-19"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M73.51,48.47h0c-2.31,3.72-3.43,8.82-4,12.88-.27,2-.41,3.87-.48,5.18,0,.66-.05,1.19-.06,1.56,0,.18,0,.32,0,.42v.12h0c-.62,11.22,0,26.66,1.88,35.2a68.13,68.13,0,0,1,.45,15A26.42,26.42,0,0,1,70,125.32c-.65,1.79-1.43,2.73-2.19,3.05l0,0a4.05,4.05,0,0,1-2.31.52,4,4,0,0,1-2.1-1.11c-1.69-1.51-3.62-4.47-6.44-9.4-2.66-4.64-3.89-12.08-4.75-19.46-.19-1.68-.37-3.39-.55-5-.19-1.88-.38-3.69-.58-5.32a39.87,39.87,0,0,0-1.49-7.72,13.18,13.18,0,0,0-2.42-4.41,4.55,4.55,0,0,0-3.6-1.72,5.4,5.4,0,0,0-3.74,2.1,19.25,19.25,0,0,0-3.09,5.37A28.07,28.07,0,0,0,35.06,91a85.43,85.43,0,0,0,.18,10.33c.21,3.08.51,6.1.77,8.79,0,.36.07.7.11,1.05.29,3,.51,5.44.51,7.12,0,1.33,0,2.67.09,3.94,0,.42,0,.83,0,1.24a39.59,39.59,0,0,1,0,4.41,7.19,7.19,0,0,1-.71,2.88,1.89,1.89,0,0,1-1.46,1l-.24.07a1.82,1.82,0,0,1-1,.12,4.05,4.05,0,0,1-1.86-1.05c-1.76-1.5-4.32-4.82-8-11.79-7.23-13.54-5.48-30.61-.53-48,4-14.09-.28-22.24-4.35-30-1.13-2.15-2.24-4.26-3.15-6.46-2.13-5.11-2.82-9.23-2-13.19s3.12-8,7.41-12.8,8.35-6.23,11.84-6.23a11,11,0,0,1,8.1,3.43l.09.1.22.19c1.55,1.4,4.27,3.85,7.43,4.94a8.85,8.85,0,0,0,5.5.24A10.65,10.65,0,0,0,59.35,7.2h0v0C61.78,3.78,63.82,2,65.74,1.1A13.9,13.9,0,0,1,72.05,0a14.65,14.65,0,0,1,9,3.05c3.15,2.52,6,7.21,6,16s-1.82,13.72-4.31,17.51a52.93,52.93,0,0,1-4,5.08l-.35.42A56.1,56.1,0,0,0,73.51,48.47Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-20"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M27.73,26.81v-.06l0-.06C25,19,25.88,14,27.82,10.87a13.45,13.45,0,0,1,7.73-5.61c2.6-.78,7.32-2.1,12.24-3.22A69.06,69.06,0,0,1,61.1,0c2.72,0,5.44,2.48,7.79,7.29a59.77,59.77,0,0,1,4.84,18.19c.84,6.85-.41,14.19-2.12,21-.77,3-1.62,5.93-2.4,8.63-.1.33-.19.65-.29,1-.86,3-1.63,5.66-2.07,7.93-.89,4.55-1.32,14.4-1.53,24.32s-.22,20.15-.22,25.45h0c0,2,.12,4.18.23,6.35,0,.81.09,1.6.12,2.38a39.79,39.79,0,0,1-.12,7.43,4.43,4.43,0,0,1-.59,1.81.46.46,0,0,1-.51.23,4.53,4.53,0,0,1-2-1.11,29.71,29.71,0,0,1-3.68-3.74c-5.85-6.85-9.68-17.24-12-26.92A108,108,0,0,1,43.3,78.4a89.75,89.75,0,0,0-1.53-15.26c-1.09-5.78-2.85-11.76-5.61-15.54a47.15,47.15,0,0,1-5.28-10.67A99.84,99.84,0,0,1,27.73,26.81Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-21"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M69.74,37.93l0,.06A113.6,113.6,0,0,0,61,64c-1.47,7.28-.52,13.42.83,22,.28,1.83.59,3.77.89,5.86a76.7,76.7,0,0,1,.64,15.22c-.07,1.38-.15,2.77-.24,4.21-.2,3.4-.42,7-.42,11.3a21.29,21.29,0,0,1-.8,6.52,4.53,4.53,0,0,1-1.67,2.56c-1,.62-2.55.28-4.18-1.34a6.34,6.34,0,0,1-1.08-1.65,28.82,28.82,0,0,1-1.24-3c-.85-2.34-1.71-5.26-2.58-8.36-.34-1.23-.68-2.48-1-3.74a142.87,142.87,0,0,0-4.21-13.77c-1.65-4.09-2.52-11.2-2.95-18.51s-.43-14.52-.43-18.81c0-3.72-1.55-7.78-3.39-11.27A49.63,49.63,0,0,0,33.59,43c-8.47-12.61-8.76-22.49-6.12-29.11s8.37-10.2,12.11-10.2a40.68,40.68,0,0,0,10-1.1c.68-.15,1.36-.32,2.08-.5C54,1.5,56.63.85,60.56.2a9,9,0,0,1,5.36.41,6.7,6.7,0,0,1,2.94,3.07,29.93,29.93,0,0,1,2.07,5.59c.17.58.34,1.19.52,1.81.49,1.73,1,3.59,1.66,5.5a22.73,22.73,0,0,1,.14,12.11A35.36,35.36,0,0,1,69.74,37.93Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-22"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M37.94,41.82v0h0A72.22,72.22,0,0,1,30.44,30c-2.5-5-4.44-10.41-4.44-15.14C26,9,28.25,4.79,31.51,2.41A13.86,13.86,0,0,1,43.69.66C53.18,3.43,57.43,3.48,61.74,2a8.26,8.26,0,0,1,7.43.91C71.68,4.74,74,8.83,74,17.63s-2,14.11-4.07,17.55a25.52,25.52,0,0,1-3.08,4c-.14.16-.28.31-.41.47-.31.33-.6.65-.85.94A6.85,6.85,0,0,0,64.67,42l-.06.12,0,.13C63.68,47.29,62.14,60,63.23,70c1.34,12.32,1.34,13.68,1.34,23.23v0c0,1.7,0,3.88.09,6.35.09,4.6.19,10.19.08,15.46A88.6,88.6,0,0,1,64,125.79a19.69,19.69,0,0,1-.8,3.59,3.38,3.38,0,0,1-.92,1.59,5.06,5.06,0,0,1-2.38,1,2.41,2.41,0,0,1-1.89-.77,12.22,12.22,0,0,1-2.36-3.56,61.16,61.16,0,0,1-2.77-7.21,58.4,58.4,0,0,1-2.48-16.58c0-.41,0-.82,0-1.22-.06-1.91-.12-3.68-.28-5.2a10.62,10.62,0,0,0-1.35-4.72,19.87,19.87,0,0,1-1.85-5c-.62-2.33-1.21-5.13-1.71-8a105.74,105.74,0,0,1-1.67-15.3A57.29,57.29,0,0,0,41.8,52,51.62,51.62,0,0,0,40,46.07,18.05,18.05,0,0,0,37.94,41.82Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-23"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M34.39,45.82a.16.16,0,0,0,0-.07,65.75,65.75,0,0,1-5.81-12.94c-1.81-5.54-3-11.77-2.37-17.44C27,8.29,29.75,4.53,33,2.49S40.15,0,43.42,0a19.37,19.37,0,0,1,6.26,1.1l.94.29a14,14,0,0,0,8.06.22,13.06,13.06,0,0,1,10,2.09A11.68,11.68,0,0,1,74,13.61c0,9.74-1.66,24.23-8.18,35.43h0a77.3,77.3,0,0,0-5.29,12,52.12,52.12,0,0,0-3.19,16.37c0,2.4-.06,5.11-.12,8-.1,4.9-.22,10.35-.09,15.74.21,8.62,1,17.37,3.62,24.11,1.22,3.2,1,4.91.58,5.72s-1.38,1.13-3,1a12.52,12.52,0,0,1-5.13-2A11,11,0,0,1,49.4,126a39.85,39.85,0,0,0-2.31-3.75c-.47-.71-1-1.46-1.47-2.25a53.25,53.25,0,0,1-4.72-9.24c-3.06-7.8-5.54-19.5-4.72-38.18a129.75,129.75,0,0,0,.38-13.72A65.94,65.94,0,0,0,36,51.52,19.19,19.19,0,0,0,34.39,45.82Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-24"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M48.64,1.5h0l.05,0,.21-.07.81-.25c.7-.2,1.71-.46,2.93-.69A24.79,24.79,0,0,1,61.47.34,13.6,13.6,0,0,1,70,5.4c2.31,3,4,7.75,4,15.06v0h0C74.23,33.63,71.59,42,69,47a24.25,24.25,0,0,1-3.63,5.24,12.91,12.91,0,0,1-1.16,1.11l-.31.25,0,0h0a1.1,1.1,0,0,0-.49.86c-.47,7.92-2.13,16.6-3.89,25.77L59,82.1c-1.88,9.82-3.77,20.15-4.28,30.48s-1.65,15.13-2.9,17.44a3.5,3.5,0,0,1-1.68,1.74,4.83,4.83,0,0,1-1.91.24h0a1.75,1.75,0,0,1-.65-.42,9.45,9.45,0,0,1-1.53-1.76,36.55,36.55,0,0,1-3.75-7A74.53,74.53,0,0,1,37,98.38c-1-19.85-1.66-33.54,0-43.64a1.12,1.12,0,0,0-.17-.8l-.91.62.91-.62h0v0l-.05-.07-.18-.29c-.16-.24-.4-.6-.68-1-.57-.89-1.34-2.12-2.16-3.47a58,58,0,0,1-4.15-7.81c-1.49-4-5.45-18.11-2.49-30.75,0,0,0-.05,0-.08.61-3.81,1.69-6.14,3-7.58A7.14,7.14,0,0,1,34.79.56a26.7,26.7,0,0,1,6.37.32L42,1a46.38,46.38,0,0,0,6.24.57Zm.48,0Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-25"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M46.75,116.81c.52,3.84,1.66,8.68,3.42,11.89A6.62,6.62,0,0,0,53,131.79c.89.38,2,.32,3.48-.82l0,0c1.77-1.25,3.26-4,4.47-7.84a81.79,81.79,0,0,0,2.71-13.75c1-7.63,1.41-15.92,1.8-22.89.13-2.43.26-4.69.39-6.72.52-7.81,1-14.3,1.31-18.83.17-2.27.3-4,.39-5.26,0-.61.08-1.08.11-1.39l0-.36v-.11h0v.06h0l1.08.09-1.08-.09a1.1,1.1,0,0,1,.47-.8h0l.07,0,.3-.25a10.84,10.84,0,0,0,1.14-1.14,21.52,21.52,0,0,0,3.38-5.31c2.36-5.07,4.42-13.53,2.94-26.86C75.14,11.09,71.4,6.29,67,3.68A22,22,0,0,0,53,1.19h-.07l-.22,0C48.3,1.54,41.44,2,35.44,4.79a20.43,20.43,0,0,0-7.91,6.07,20.45,20.45,0,0,0-4,11.11c-.78,9.54,3.29,17.29,7.64,22.71a45.9,45.9,0,0,0,6.07,6.22c.83.71,1.54,1.25,2,1.61l.57.41L40,53l0,0h0a1.06,1.06,0,0,1,.45.61c2.66,9.87,4,25.47,4.63,38.52.33,6.55.49,12.48.57,16.78,0,2.15.07,3.89.08,5.09v1.39l1,1.33Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-27"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M40.57,57.65a1.11,1.11,0,0,1,.07.57c-1.41,9.41-.87,27,0,42.37.45,7.65,1,14.71,1.42,19.86.21,2.57.4,4.66.53,6.11.07.73.12,1.29.16,1.68l0,.38.12.22c.13.24.32.57.57.94a6.05,6.05,0,0,0,2,1.92,2.11,2.11,0,0,0,2.33-.1c1-.63,2.37-2.14,3.86-5.29A183.19,183.19,0,0,0,63.47,93c2.44-9.73,3.46-17.14,3.46-19.86V73c.32-2.72-.06-8.33-.55-13.39-.24-2.5-.5-4.82-.7-6.52-.1-.85-.19-1.54-.25-2,0-.24-.05-.43-.07-.56l0-.14v0h0l1.13-.14-1.13.14a1.11,1.11,0,0,1,.41-1h0a.3.3,0,0,1,.08-.07l.34-.31c.3-.28.73-.7,1.25-1.27a27.56,27.56,0,0,0,3.66-5.15,32.45,32.45,0,0,0,3.52-20.73v-.29c.81-6.89.26-11.55-1.08-14.7A9.86,9.86,0,0,0,67.64,1a23.58,23.58,0,0,0-8.73-1c-1.39.05-2.78.14-4.14.23l-.51,0c-1.51.1-3,.19-4.37.19-1.2,0-2.41,0-3.63-.06-2-.06-4-.11-6,0a20.83,20.83,0,0,0-8.78,1.88A10.94,10.94,0,0,0,26,9.16c-1.12,3.6-1.35,8.74-.13,16.05,1.82,10.84,4.38,16.18,7,20.11.76,1.15,1.55,2.22,2.35,3.29A38.72,38.72,0,0,1,40.57,57.65Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-28"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M36.6,117.5h0c-.27,5.23.25,8.8,1.15,11.09S39.86,131.74,41,132a5.43,5.43,0,0,0,4.19-1.47,14.55,14.55,0,0,0,4-5.32c4.36-9.55,11.4-27.19,11.4-46.81,0-9.93.27-14.73,1.76-19.55a87.58,87.58,0,0,1,5.06-11.61c1.06-2.2,2.29-4.73,3.69-7.73C80.06,20.23,67.29,0,49.67,0A21.72,21.72,0,0,0,30.73,10.35a30.87,30.87,0,0,0-4.54,19.86c.8,6.34,2.52,10.32,4.5,14.85l0,.12A78.34,78.34,0,0,1,36.58,63c1.67,8.38,1.66,22.05,1.25,33.52-.2,5.75-.51,11-.77,14.79-.13,1.9-.24,3.45-.33,4.51,0,.54-.07,1-.1,1.23l0,.32Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-29"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M29.36,44.73l0,0h0l.05.1.17.36q.22.48.6,1.35c.49,1.15,1.13,2.79,1.75,4.74A40.07,40.07,0,0,1,34.1,65.47,33.78,33.78,0,0,0,35.65,79.2c.53,2,1.17,4.26,1.88,7.44,1.18,5.35,1.32,12.07,1,18.17a121.58,121.58,0,0,1-1.6,14.9h0v0a29.83,29.83,0,0,0-.53,5.36,14.79,14.79,0,0,0,.75,5.38,3,3,0,0,0,.9,1.28,1.55,1.55,0,0,0,1.22.2c1.28-.23,3.38-1.5,6.53-5l0,0c2.41-2.42,4.64-6.52,6.63-11.68A127.33,127.33,0,0,0,57.55,97.9,202.25,202.25,0,0,0,62.2,65.35v0a43,43,0,0,1,1.93-11.86,31.48,31.48,0,0,1,2.13-4.68c.24-.44.46-.86.67-1.27a18.25,18.25,0,0,1,1-1.73l.28-.42c.36-.54.75-1.13,1.17-1.79a33.93,33.93,0,0,0,3.21-6.35c1.93-5.15,3.06-12.35.53-22.16C70.75,5.61,63.2.85,54.67.1s-18,2.6-23.75,9.22-6.17,15.29-5.11,22.61A54.34,54.34,0,0,0,28,41.11c.38,1.16.73,2.08,1,2.72.12.31.22.56.29.72Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-30"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M26.49,48.47h0c2.31,3.72,3.43,8.82,4,12.88.27,2,.41,3.87.48,5.18,0,.66.05,1.19.06,1.56,0,.18,0,.32-.52.42l1,.12h0c.15,11.22-.46,26.66-2.35,35.2a68.13,68.13,0,0,0-.45,15A26.42,26.42,0,0,0,30,125.32c.65,1.79,1.43,2.73,2.19,3.05l.05,0a4.05,4.05,0,0,0,2.31.52,4,4,0,0,0,2.1-1.11c1.69-1.51,3.62-4.47,6.44-9.4,2.66-4.64,3.89-12.08,4.75-19.46.19-1.68.37-3.39.55-5,.19-1.88.38-3.69.58-5.32a39.87,39.87,0,0,1,1.49-7.72,13.18,13.18,0,0,1,2.42-4.41,4.56,4.56,0,0,1,3.61-1.72,5.4,5.4,0,0,1,3.73,2.1,19.25,19.25,0,0,1,3.09,5.37A28.07,28.07,0,0,1,64.94,91a85.43,85.43,0,0,1-.18,10.33c-.21,3.08-.51,6.1-.77,8.79,0,.36-.07.7-.11,1.05-.29,3-.51,5.44-.51,7.12,0,1.33,0,2.67-.09,3.94,0,.42,0,.83,0,1.24a39.59,39.59,0,0,0,0,4.41,7.19,7.19,0,0,0,.71,2.88,1.89,1.89,0,0,0,1.46,1l.24.07a1.82,1.82,0,0,0,1,.12,4.05,4.05,0,0,0,1.86-1.05c1.76-1.5,4.32-4.82,8-11.79,7.23-13.54,5.48-30.61.53-48-4-14.09.28-22.24,4.35-30,1.13-2.15,2.24-4.26,3.15-6.46,2.13-5.11,2.82-9.23,2-13.19s-3.12-8-7.41-12.8S70.92,2.45,67.43,2.45a11,11,0,0,0-8.1,3.43l-.09.1L59,6.17c-1.55,1.4-4.27,3.85-7.43,4.94a8.85,8.85,0,0,1-5.5.24A10.65,10.65,0,0,1,40.65,7.2h0v0C38.22,3.78,36.18,2,34.26,1.1A13.9,13.9,0,0,0,28,0,14.65,14.65,0,0,0,19,3.05c-3.15,2.52-6,7.21-6,16s1.82,13.72,4.31,17.51a52.93,52.93,0,0,0,4,5.08l.35.42A56.1,56.1,0,0,1,26.49,48.47Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-31"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M8.15,17.28h0v-.07c.89-6.92,3.4-11.14,6.54-13.66A18,18,0,0,1,26,0,38.26,38.26,0,0,1,37.52,1.88a42.49,42.49,0,0,1,4.17,1.55,13.15,13.15,0,0,1,2.05,1.06,16.53,16.53,0,0,0,6.65,3.38A10.42,10.42,0,0,0,58.63,6l.06,0c2.62-1.82,6.39-4.43,11-4.84S80,2.37,86.43,10a22.75,22.75,0,0,1,5.39,10.68,21.28,21.28,0,0,1-.67,9.7,61.48,61.48,0,0,1-3.66,9.15c-.07.13-.13.26-.19.4a54.51,54.51,0,0,0-3.36,8.3c-1.29,4.71-.59,8.14.24,12.21.19.92.38,1.87.56,2.87,1,5.51,1.73,13.26-.83,27.45C78.83,119,60,130.58,51.73,131.91h0a3.22,3.22,0,0,1-1.75-.07,1.47,1.47,0,0,1-.78-.72,7.92,7.92,0,0,1-.33-4.48c.53-4.18,2.5-9.66,4.34-13.94l0-.06,0-.06c3-9.54,3.09-14.63,3.09-25.43,0-5.47-2.72-9.71-6.67-11.52s-8.88-1.07-12.93,2.86a23.4,23.4,0,0,0-4.89,7.66,85.27,85.27,0,0,0-3.44,10.18c-.41,1.43-.81,2.88-1.22,4.35A134.1,134.1,0,0,1,21,119.33v0c-1.44,3.66-3,5.65-4.38,6.53a3,3,0,0,1-3.55,0,10,10,0,0,1-3.38-4.86,25.41,25.41,0,0,1-1.48-8.89c0-2.6-.08-5.29-.16-8C7.92,99.53,7.78,94.74,8,90c.35-7.57,1.62-14.77,5.14-20.58,3.8-6.27,5.56-12.25,5.47-18.11s-2.05-11.48-5.47-17h0l0,0A29.69,29.69,0,0,1,8.25,22.05,20.08,20.08,0,0,1,8,18.48c0-.41,0-.73.07-.93s0-.17,0-.22v0Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-32"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M8.84,13.93h0v0c2.69-9.17,10.29-12.32,18-12.84a40.5,40.5,0,0,1,10.58.75,31.5,31.5,0,0,1,3.77,1A7.7,7.7,0,0,1,43,3.6,16.57,16.57,0,0,0,49.7,7.1a10.32,10.32,0,0,0,8.36-2l.06,0C60.78,3.19,64.6.48,69.31.06S79.7,1.41,86.24,9.33A23.74,23.74,0,0,1,91.7,20.41,22.52,22.52,0,0,1,91,30.46,64.29,64.29,0,0,1,87.31,40l-.18.4A57.47,57.47,0,0,0,83.72,49a103.93,103.93,0,0,0-2.83,15.2c0,.25-.07.5-.1.76-.78,5.82-1.86,13.93-4.37,28.15-2.61,14.79-6.17,24.18-10.08,30s-8.11,8.09-12.2,8.77h0c-1.81.32-2.77,0-3.32-.57a4.74,4.74,0,0,1-1.12-3.21c-.18-3.24,1-7.86,2.86-12.26l0-.06,0-.06c3.06-9.89,3.13-15.18,3.13-26.37A11,11,0,0,0,54,82.8a4.68,4.68,0,0,0-2.2-1.67,4.46,4.46,0,0,0-2.73,0c-1.8.52-3.57,2-5.18,4.26a35.14,35.14,0,0,0-4.52,9.52c-1.23,3.76-2,6.36-2.63,8.63-1.46,5.06-2.45,8.46-7.08,19.19a5.75,5.75,0,0,1-6.11,3.67c-2.62-.39-6-2.81-8.83-9.38A93.08,93.08,0,0,0,14,106.7l-.27-2.23c-.32-2.66-.64-5.23-.81-7.79-.44-6.5.05-12.61,3.59-18.58,4-6.71,4.51-14.84,3.25-22.59a61,61,0,0,0-7.81-21h0l0,0A25.17,25.17,0,0,1,8,20.47a28.76,28.76,0,0,1,.44-4.78c.11-.57.21-1,.29-1.33L8.82,14l0-.08Z"
          />
        </g>
      </svg>
      <svg
        class="omit-report-generation-style"
        id="BoneLoss-Tooth-26"
        viewBox="-8 -8 108 140"
        preserveAspectRatio="xMidYMid meet"
        width="100"
        height="132"
      >
        <g
          x="0"
          y="0"
          width="100"
          height="132"
          class="omit-report-generation-style"
        >
          <path
            class="inner cls-1 tooth-icon-path omit-report-generation-style"
            stroke-width="2"
            shape-rendering="auto"
            vector-effect="non-scaling-stroke"
            d="M40.57,57.65a1.11,1.11,0,0,1,.07.57c-1.41,9.41-.87,27,0,42.37.45,7.65,1,14.71,1.42,19.86.21,2.57.4,4.66.53,6.11.07.73.12,1.29.16,1.68l0,.38.12.22c.13.24.32.57.57.94a6.05,6.05,0,0,0,2,1.92,2.11,2.11,0,0,0,2.33-.1c1-.63,2.37-2.14,3.86-5.29A183.19,183.19,0,0,0,63.47,93c2.44-9.73,3.46-17.14,3.46-19.86V73c.32-2.72-.06-8.33-.55-13.39-.24-2.5-.5-4.82-.7-6.52-.1-.85-.19-1.54-.25-2,0-.24-.05-.43-.07-.56l0-.14v0h0l1.13-.14-1.13.14a1.11,1.11,0,0,1,.41-1h0a.3.3,0,0,1,.08-.07l.34-.31c.3-.28.73-.7,1.25-1.27a27.56,27.56,0,0,0,3.66-5.15,32.45,32.45,0,0,0,3.52-20.73v-.29c.81-6.89.26-11.55-1.08-14.7A9.86,9.86,0,0,0,67.64,1a23.58,23.58,0,0,0-8.73-1c-1.39.05-2.78.14-4.14.23l-.51,0c-1.51.1-3,.19-4.37.19-1.2,0-2.41,0-3.63-.06-2-.06-4-.11-6,0a20.83,20.83,0,0,0-8.78,1.88A10.94,10.94,0,0,0,26,9.16c-1.12,3.6-1.35,8.74-.13,16.05,1.82,10.84,4.38,16.18,7,20.11.76,1.15,1.55,2.22,2.35,3.29A38.72,38.72,0,0,1,40.57,57.65Z"
          />
        </g>
      </svg>

      <ng-container
        *ngFor="let imgData of images; trackBy: trackImageIdentityByUrl"
      >
        <symbol
          [attr.id]="imgData.id"
          preserveAspectRatio="xMinYMin meet"
          width="100%"
          heigh="auto"
        >
          <image
            crossorigin="anonymous"
            [attr.href]="imgData.url"
            height="100%"
            width="100%"
          ></image>
        </symbol>
      </ng-container>

      <filter id="blur" width="110%" height="100%">
        <feGaussianBlur stdDeviation="2" result="blur" />
      </filter>

      <filter id="naturalShadow" x="0" y="0" width="2" height="2">
        <feOffset in="SourceGraphic" dx="3" dy="3" />
        <feGaussianBlur stdDeviation="5" result="blur" />
        <feMerge>
          <feMergeNode in="blur" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  </svg>
</ng-container>
