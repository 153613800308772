import { StoryboardImageFilters } from "@kells/clinic-one/apis";
import { FindingFilterSelectionTypes } from "@kells/interfaces/finding";
import { createAction, props } from "@ngrx/store";

export enum ActionTypes {

  IMAGE_DETAIL_PAGE_SHOW_LABELS = "[Image Detail Page] Show Finding Labels",
  IMAGE_DETAIL_PAGE_HIDE_LABELS = "[Image Detail Page] Hide Finding Labels",


  PATIENT_PAGE_SHOW_FINDINGS = "[Patient Page] Show Findings",
  PATIENT_PAGE_HIDE_FINDINGS = "[Patient Page] Hide Findings",

  IMAGE_DETAIL_PAGE_TURN_ON_INSIGHT = "[Image Detail Page] Turn On Insight Status",
  IMAGE_DETAIL_PAGE_TURN_OFF_INSIGHT = "[Image Detail Page] Turn Off Insight Status",

  IMAGE_DETAIL_PAGE_SHOW_FINDINGS = "[Image Detail Page] Show Findings",
  IMAGE_DETAIL_PAGE_HIDE_FINDINGS = "[Image Detail Page] Hide Findings",

  IMAGE_DETAIL_PAGE_TURN_ON_INSIGHT_AUTO = "[Image Detail Page / Auto] Turn On Insight Status",
  IMAGE_DETAIL_PAGE_TURN_OFF_INSIGHT_AUTO = "[Image Detail Page / Auto] Turn Off Insight Status",

  PATIENT_PAGE_TURN_ON_INSIGHT = "[Patient Page] Turn On Insight Status",
  PATIENT_PAGE_TURN_OFF_INSIGHT = "[Patient Page] Turn Off Insight Status",

  PATIENT_PAGE_TURN_ON_INSIGHT_AUTO = "[Patient Page / Auto] Turn On Insight Status",
  PATIENT_PAGE_TURN_OFF_INSIGHT_AUTO = "[Patient Page / Auto] Turn Off Insight Status",

  PRODUCT_TOUR_TURN_ON_INSIGHT = "[Product Tour] Turn On Insight Status",
  PRODUCT_TOUR_TURN_OFF_INSIGHT = "[Product Tour] Turn Off Insight Status",

  TOGGLE_IMAGE_ENHANCEMENT = "[Image Detail Page] Toggle Image Enhancement",

  TOGGLE_SIDEBAR = "[Layout] Toggle Sidebar",
  CLOSE_SIDEBAR = "[Layout] Close Sidebar",
  OPEN_SIDEBAR = "[Layout] Open Sidebar",

  TOGGLE_PATIENT_SEARCH = "[Layout] Toggle Patient Search",
  TOGGLE_SESSIONS_LIST = "[Layout] Toggle Sessions List",

  TOGGLE_PATIENT_REPORT = "[Patient Page] Toggle Patient Report",
  CLOSE_PATIENT_REPORT = "[Patient Page] Close Patient Report",

  OPEN_PATIENT_TEMPLATE = "[Patient Page] Open Template",

  OPEN_PATIENT_ORAL_ANALYTICS = "[Patient Page] Open Oral Analytics",

  DISPLAY_ALL_IMAGES = "[Patient Page] Display All Images",
  DISPLAY_BITEWING_IMAGES = "[Patient Page] Display Bitewing Images",

  SELECT_STORYBOARD_IMAGE_FILTERS = "[Patient Storyboard] Select Image Filters",

  BEGIN_TOURING = "[Product Tour] Begin",
  END_TOURING = "[Product Tour] End",

  NAVIGATE_TO_SETTINGS_PANEL = "[Settings Page] Enter",

  HOME_LOGO_CLICKED = "[Header] Home Logo Clicked",

  SET_FINDING_FILTER_TYPES = "[Image] Set Image Filter Types"
}


export const imageDetailShowLabels = createAction(
  ActionTypes.IMAGE_DETAIL_PAGE_SHOW_LABELS

);
export const imageDetailHideLabels = createAction(
  ActionTypes.IMAGE_DETAIL_PAGE_HIDE_LABELS
);
export const imageDetailPageTurnOnInsight = createAction(
  ActionTypes.IMAGE_DETAIL_PAGE_TURN_ON_INSIGHT
);
export const imageDetailPageTurnOffInsight = createAction(
  ActionTypes.IMAGE_DETAIL_PAGE_TURN_OFF_INSIGHT
);
export const imageDetailPageAutoTurnOnInsight = createAction(
  ActionTypes.IMAGE_DETAIL_PAGE_TURN_ON_INSIGHT_AUTO
);
export const imageDetailPageAutoTurnOffInsight = createAction(
  ActionTypes.IMAGE_DETAIL_PAGE_TURN_OFF_INSIGHT_AUTO
);
export const patientPageTurnOnInsight = createAction(
  ActionTypes.PATIENT_PAGE_TURN_ON_INSIGHT
);
export const patientPageTurnOffInsight = createAction(
  ActionTypes.PATIENT_PAGE_TURN_OFF_INSIGHT
);
export const patientPageAutoTurnOnInsight = createAction(
  ActionTypes.PATIENT_PAGE_TURN_ON_INSIGHT_AUTO
);
export const patientPageAutoTurnOffInsight = createAction(
  ActionTypes.PATIENT_PAGE_TURN_OFF_INSIGHT_AUTO
);
export const productTourTurnOnInsight = createAction(
  ActionTypes.PRODUCT_TOUR_TURN_ON_INSIGHT
);
export const productTourTurnOffInsight = createAction(
  ActionTypes.PRODUCT_TOUR_TURN_OFF_INSIGHT
);

export const userInitiatedImageToggleOnActions = [
  imageDetailPageTurnOnInsight,
  patientPageTurnOnInsight,
];

export const userInitiatedImageToggleOffActions = [
  imageDetailPageTurnOffInsight,
  patientPageTurnOffInsight,
];

export const toggleImageEnhancement = createAction(
  ActionTypes.TOGGLE_IMAGE_ENHANCEMENT
);


export const toggleSidebar = createAction(ActionTypes.TOGGLE_SIDEBAR);
export const openSidebar = createAction(ActionTypes.OPEN_SIDEBAR);
export const closeSidebar = createAction(ActionTypes.CLOSE_SIDEBAR);

export const togglePatientSearch = createAction(ActionTypes.TOGGLE_PATIENT_SEARCH,
  props<{ isVisible: boolean }>());

export const toggleSessionsList = createAction(ActionTypes.TOGGLE_SESSIONS_LIST,
  props<{ isVisible: boolean }>());

export const togglePatientReport = createAction(
  ActionTypes.TOGGLE_PATIENT_REPORT,
  props<{ sessionDate: string; sessionId: string }>()
);

export const openPatientTemplate = createAction(
  ActionTypes.OPEN_PATIENT_TEMPLATE,
  props<{ sessionDate: string; sessionId: string }>()
);

export const openOralAnalyticsPage = createAction(
  ActionTypes.OPEN_PATIENT_ORAL_ANALYTICS
);

export const closePatientReport = createAction(
  ActionTypes.CLOSE_PATIENT_REPORT
);

export const displayAllImages = createAction(ActionTypes.DISPLAY_ALL_IMAGES);
export const displayBitewingImages = createAction(
  ActionTypes.DISPLAY_BITEWING_IMAGES
);

export const selectStoryboardFilters = createAction(
  ActionTypes.SELECT_STORYBOARD_IMAGE_FILTERS,
  props<{ filters: StoryboardImageFilters[] }>()
);

export const setImageFindingFilters = createAction(
  ActionTypes.SET_FINDING_FILTER_TYPES,
  props<{ filters:FindingFilterSelectionTypes[]}>()
)

export const beginTouring = createAction(ActionTypes.BEGIN_TOURING);
export const endTouring = createAction(ActionTypes.END_TOURING);

export const enterSettingsPanel = createAction(
  ActionTypes.NAVIGATE_TO_SETTINGS_PANEL,
  props<{ settingsExitUrl: string }>()
);

export const homeLogoClicked = createAction(ActionTypes.HOME_LOGO_CLICKED);
