import {
  TreatmentCostEstimate,
  TreatmentTypes,
} from "../models/treatment.model";

/** Maps treatment types to corresponding cost estimate descriptions */
const TREATMENT_COST_ESTIMATES: Record<
  TreatmentTypes,
  TreatmentCostEstimate | undefined
> = {
  [TreatmentTypes.Filling]: {
    type: TreatmentTypes.Filling,
    costMin: 175,
    costMax: 345,
    costContext: "Based on size and position in mouth and case complexity",
  },
  [TreatmentTypes.Crown]: {
    type: TreatmentTypes.Crown,
    costMin: 1100,
    costMax: 1250,
    costContext: "Based on material, ceramic versus metal",
  },
  [TreatmentTypes.RootCanal]: {
    type: TreatmentTypes.RootCanal,
    costMin: 800,
    costMax: 1100,
    costContext: "Based on tooth position and case complexity",
  },
  [TreatmentTypes.Extraction]: {
    type: TreatmentTypes.Extraction,
    costMin: 190,
    costMax: 290,
    costContext: "Based on case complexity",
  },
  [TreatmentTypes.Implant]: {
    type: TreatmentTypes.Implant,
    costMin: 1700,
    costMax: 2500,
  },
  [TreatmentTypes.Dentures]: {
    type: TreatmentTypes.Dentures,
    costMin: 1400,
    costMax: 2500,
  },
  [TreatmentTypes.PartialDenture]: undefined,
  [TreatmentTypes.DeepCleaning]: {
    type: TreatmentTypes.DeepCleaning,
  },
  [TreatmentTypes.Cleaning]: {
    type: TreatmentTypes.Cleaning,
  },
  [TreatmentTypes.GumGraft]: {
    type: TreatmentTypes.GumGraft,
  },
  [TreatmentTypes.Monitor]: undefined,
  [TreatmentTypes.Other]: undefined,
};

interface TreatmentDef {
  type: TreatmentTypes;
  description: string;
}

interface TreatmentConfigurations {
  hasLocationMesial?: boolean;
}

/** treatment options for initial stage */
const createInitialStageTreatments = () => {
  // return Object.freeze([
  //   {
  //     type: TreatmentTypes.Monitor,
  //     description: "Carefully monitor at next recall",
  //   },
  //   {
  //     type: TreatmentTypes.Monitor,
  //     description: "Reinforce utilization of topical fluoride",
  //   },
  //   {
  //     type: TreatmentTypes.Monitor,
  //     description: "May consider additional BW in 6 months",
  //   },
  //   {
  //     type: TreatmentTypes.Monitor,
  //     description: "May consider additional BW in one year",
  //   },
  //   {
  //     type: TreatmentTypes.Other,
  //     description: "Other",
  //   },
  // ]);
  return Object.freeze([
    {
      type: TreatmentTypes.Filling,
      description: TreatmentTypes.Filling,
    },
    {
      type: TreatmentTypes.RootCanal,
      description: TreatmentTypes.RootCanal,
    },
    {
      type: TreatmentTypes.Crown,
      description: TreatmentTypes.Crown,
    },
    {
      type: TreatmentTypes.Extraction,
      description: TreatmentTypes.Extraction,
    },
    {
      type: TreatmentTypes.Implant,
      description: TreatmentTypes.Implant,
    },
    {
      type: TreatmentTypes.Monitor,
      description: TreatmentTypes.Monitor,
    },
    {
      type: TreatmentTypes.GumGraft,
      description: TreatmentTypes.GumGraft,
    },
    {
      type: TreatmentTypes.Cleaning,
      description: TreatmentTypes.Cleaning,
    },
    {
      type: TreatmentTypes.Other,
      description: "Other",
    },
  ]);
};

/** treatment options for moderate stage */
const createModerateStageTreatments = (
  { hasLocationMesial }: TreatmentConfigurations = {
    hasLocationMesial: false,
  }
) => {
  // const otherTreatments: TreatmentDef[] = [
  //   {
  //     type: TreatmentTypes.Crown,
  //     description: "Full Coverage All Ceramic restoration",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "Full Coverage PFM restoration",
  //   },
  //   {
  //     type: TreatmentTypes.Other,
  //     description: "Other",
  //   },
  // ];

  // const standardTreatments: TreatmentDef[] = [
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DO Composite",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DOB Composite",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DOL Composite",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DOBL Composite",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DO Ceramic Inlay",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DOB Ceramic Inlay",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DOL Ceramic Inlay",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DOBL Ceramic Inlay",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "DO Ceramic Onlay",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "MO Ceramic Inlay",
  //   },
  // ];

  // const mesialTreatments = hasLocationMesial
  //   ? standardTreatments.map((t) => ({
  //       ...t,
  //       description: `M${t.description.substring(1)}`,
  //     }))
  //   : [];

  // return Object.freeze([
  //   ...standardTreatments,
  //   ...mesialTreatments,
  //   ...otherTreatments,
  // ]);
  return Object.freeze([
    {
      type: TreatmentTypes.Filling,
      description: TreatmentTypes.Filling,
    },
    {
      type: TreatmentTypes.RootCanal,
      description: TreatmentTypes.RootCanal,
    },
    {
      type: TreatmentTypes.Crown,
      description: TreatmentTypes.Crown,
    },
    {
      type: TreatmentTypes.Extraction,
      description: TreatmentTypes.Extraction,
    },
    {
      type: TreatmentTypes.Implant,
      description: TreatmentTypes.Implant,
    },
    {
      type: TreatmentTypes.Monitor,
      description: TreatmentTypes.Monitor,
    },
    {
      type: TreatmentTypes.GumGraft,
      description: TreatmentTypes.GumGraft,
    },
    {
      type: TreatmentTypes.Cleaning,
      description: TreatmentTypes.Cleaning,
    },
    {
      type: TreatmentTypes.Other,
      description: "Other",
    },
  ]);
};

/** treatment options for advanced stage */
const createAdvancedStageTreatments = () =>
  // Object.freeze([
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "MOD Composite",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "MODB Composite",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "MODBL Composite",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "MOD Ceramic Onlay",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "MODB Ceramic Onlay",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "MODBL Ceramic Onlay",
  //   },
  //   {
  //     type: TreatmentTypes.Crown,
  //     description: "Full Coverage All Ceramic restoration",
  //   },
  //   {
  //     type: TreatmentTypes.Filling,
  //     description: "Full Coverage PFM restoration",
  //   },

  //   {
  //     type: TreatmentTypes.RootCanal,
  //     description: "Root canal required. Post/core, full coverage crown",
  //   },

  //   {
  //     type: TreatmentTypes.Extraction,
  //     description:
  //       "Recommend Extraction, graft, implant placement, implant-supported restoration",
  //   },
  //   {
  //     type: TreatmentTypes.Other,
  //     description: "Other",
  //   },
  // ]);
  Object.freeze([
    {
      type: TreatmentTypes.Filling,
      description: TreatmentTypes.Filling,
    },
    {
      type: TreatmentTypes.RootCanal,
      description: TreatmentTypes.RootCanal,
    },
    {
      type: TreatmentTypes.Crown,
      description: TreatmentTypes.Crown,
    },
    {
      type: TreatmentTypes.Extraction,
      description: TreatmentTypes.Extraction,
    },
    {
      type: TreatmentTypes.Implant,
      description: TreatmentTypes.Implant,
    },
    {
      type: TreatmentTypes.Monitor,
      description: TreatmentTypes.Monitor,
    },
    {
      type: TreatmentTypes.GumGraft,
      description: TreatmentTypes.GumGraft,
    },
    {
      type: TreatmentTypes.Cleaning,
      description: TreatmentTypes.Cleaning,
    },
    {
      type: TreatmentTypes.Other,
      description: "Other",
    },
  ]);

/** treatment options for whole mouth */
const createWholeMouthTreatments = () =>
  Object.freeze([
    {
      type: TreatmentTypes.Dentures,
      description: TreatmentTypes.Dentures,
    },
    {
      type: TreatmentTypes.PartialDenture,
      description: TreatmentTypes.PartialDenture,
    },
    {
      type: TreatmentTypes.DeepCleaning,
      description: TreatmentTypes.DeepCleaning,
    },
    {
      type: TreatmentTypes.Cleaning,
      description: TreatmentTypes.Cleaning,
    },
  ]);

/** all treatment options for findings */
const createAllFindingsTreatments = () => {
  return Object.freeze([
    {
      type: TreatmentTypes.Filling,
      description: TreatmentTypes.Filling,
    },
    {
      type: TreatmentTypes.RootCanal,
      description: TreatmentTypes.RootCanal,
    },
    {
      type: TreatmentTypes.Crown,
      description: TreatmentTypes.Crown,
    },
    {
      type: TreatmentTypes.Extraction,
      description: TreatmentTypes.Extraction,
    },
    {
      type: TreatmentTypes.Implant,
      description: TreatmentTypes.Implant,
    },
    {
      type: TreatmentTypes.Monitor,
      description: TreatmentTypes.Monitor,
    },
    {
      type: TreatmentTypes.GumGraft,
      description: TreatmentTypes.GumGraft,
    },
    {
      type: TreatmentTypes.Cleaning,
      description: TreatmentTypes.Cleaning,
    },
    {
      type: TreatmentTypes.Other,
      description: "Other",
    },
  ]);
};
/**
 * Provide an interface for hard-coded treatment-related data.
 */
export class TreatmentDB {
  /** Treatments for initial caries. */
  static getInitialStageTreatments(): readonly TreatmentDef[] {
    return createInitialStageTreatments();
  }

  /** Treatments for moderate caries. */
  static getModerageStageTreatments(
    opts?: TreatmentConfigurations
  ): readonly TreatmentDef[] {
    return createModerateStageTreatments(opts);
  }

  /** Treatments for advanced caries. */
  static getAdvancedStageTreatments(): readonly TreatmentDef[] {
    return createAdvancedStageTreatments();
  }

  /** Treatments for Whole Mouth. */
  static getWholeMouthTreatments(): readonly TreatmentDef[] {
    return createWholeMouthTreatments();
  }

  /** All Treatments for findings */
  static getAllFindingsTreatments(): readonly TreatmentDef[] {
    return createAllFindingsTreatments();
  }

  /** Look up a the description of a specific type of treatment's costs. */
  static getCostEstimate(
    type: TreatmentTypes
  ): TreatmentCostEstimate | undefined {
    return TREATMENT_COST_ESTIMATES[type];
  }
}
