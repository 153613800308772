import { createAction, props } from "@ngrx/store";
import { KellsOffice } from "../models/office.model";

export const getLoggedInUserOffice = createAction(
  "[Office] Get Logged In User's Office"
);

export const getAllOffices = createAction("[Office/API] Get All Offices");

export const getAllOfficesSuccess = createAction(
  "[Office/API] Get All Offices Success",
  props<{ offices: KellsOffice[] }>()
);

export const getAllOfficesFailure = createAction(
  "[Office/API] Get All Offices Failure",
  props<{ error: any }>()
);

export const getOfficeInfo = createAction(
  "[Office/API] Get Office Info",
  props<{ officeId: string }>()
);

export const getOfficeInfoSuccess = createAction(
  "[Office/API] Get Office Info Success",
  props<{ office: KellsOffice }>()
);

export const getOfficeInfoFailure = createAction(
  "[Office/API] Get Office Info Failure",
  props<{ error: any }>()
);

export const postIntegrationGetOfficeInfo = createAction(
  "[Integration Completion / Office API] Get Office Info",
  props<{ officeId: string }>()
);

export const errorActions = [getOfficeInfoFailure];
