import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  SimpleChanges,
} from "@angular/core";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { isNumber } from "lodash";

gsap.registerPlugin(MotionPathPlugin);

export type RiskLevel = "low" | "medium" | "high";

const COLORS_BY_RISK_STATUS: Record<string, string> = {
  LOW: "#84D444",
  MEDIUM: "#FDD046",
  HIGH: "#F45446",
};

@Component({
  selector: "koa-risk-chart",
  templateUrl: "./risk-chart.component.html",
  styleUrls: ["./risk-chart.component.scss"],
})
export class RiskChartComponent implements OnChanges {
  // riskScore is a range between 0 and 100
  // risk score diagram has next grades: 0-27 low, 27-72 medium, 72+ high
  @Input() riskScore: number;
  // label in the center
  @Input() level: RiskLevel;

  riskColor = "";
  label = "";

  // for correct animation
  _isIndicatorMoved = false;
  _currentIndicator = {
    startPath: 0,
    endPath: 0.99,
    progress: 0,
  };

  @ViewChild("riskIndicatorPath") riskIndicatorPath: ElementRef;
  @ViewChild("riskIndicator") riskIndicator: ElementRef;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.level && this.level) {
      this.label = this.getLabelByLevel();
      this.riskColor = this.getRiskColorByLevel();
    }

    if (changes.riskScore && isNumber(this.riskScore)) {
      this.moveIndicatorToPoint();
    }
  }

  private getLabelByLevel() {
    switch (this.level) {
      case "low":
        return "LOW";
      case "medium":
        return "MEDIUM";
      case "high":
        return "HIGH";

      default:
        return "";
    }
  }

  private getRiskColorByLevel() {
    switch (this.level) {
      case "low":
        return COLORS_BY_RISK_STATUS.LOW;
      case "medium":
        return COLORS_BY_RISK_STATUS.MEDIUM;
      case "high":
        return COLORS_BY_RISK_STATUS.HIGH;

      default:
        return "";
    }
  }

  private moveIndicatorToPoint() {
    if (
      !this.riskIndicator?.nativeElement ||
      !this.riskIndicatorPath?.nativeElement
    ) {
      return;
    }

    // align risk indicator
    if (!this._isIndicatorMoved) {
      gsap.set(this.riskIndicator.nativeElement, {
        xPercent: -50,
        yPercent: -50,
        transformOrigin: "50% 50%",
        opacity: 0,
      });
    }

    const endPathRate = 0.99;
    const duration = 1;
    const delay = this._isIndicatorMoved ? 0 : 3;

    const destinationPath = (this.riskScore * endPathRate) / 100;
    const startPath = this.calculateCurrentPath(
      this._currentIndicator.startPath,
      this._currentIndicator.endPath,
      this._currentIndicator.progress
    );

    this._currentIndicator = {
      startPath,
      endPath: destinationPath,
      progress: 0,
    };

    /* eslint-disable @typescript-eslint/no-this-alias */
    const AngularInstance = this;

    gsap.to(this.riskIndicator.nativeElement, {
      overwrite: true,
      ease: "none",
      duration,
      delay,
      onStart: () => {
        // will be called after delay
        if (!this._isIndicatorMoved) {
          gsap.set(this.riskIndicator.nativeElement, { opacity: 1 });
        }

        this._isIndicatorMoved = true;
      },
      onUpdate: function () {
        // this here points to the gsap context object
        AngularInstance._currentIndicator.progress = this.progress();
      },

      motionPath: {
        path: this.riskIndicatorPath.nativeElement,
        align: this.riskIndicatorPath.nativeElement,
        start: startPath,
        end: destinationPath,
      },
    });
  }

  private calculateCurrentPath(start: number, end: number, progress: number) {
    const takenPath = (end - start) * progress;

    return start + takenPath;
  }
}
