import { createAction, props } from "@ngrx/store";
import { Finding, FindingCreationPayload } from "@kells/clinic-one/apis";
import { FindingUpdatePayload } from "@kells/shared-ui/canvas";

enum ActionTypes {
  FETCH_FINDINGS = "[Findings API] Fetch Findings",
  FETCH_FINDINGS_SUCCESS = "[Findings API] Fetch Findings Success",
  FETCH_FINDINGS_FAILURE = "[Findings API] Fetch Findings Failure",

  ADD_NEW_FINDING = "[Findings API] Add New Finding",
  ADD_NEW_FINDING_SUCCESS = "[Findings API] Add New Finding Success",
  ADD_NEW_FINDING_FAILURE = "[Findings API] Add New Finding Failure",

  EDIT_FINDING = "[Findings API] Edit Finding",
  EDIT_FINDING_SUCCESS = "[Findings API] Edit Finding Success",
  EDIT_FINDING_FAILURE = "[Findings API] Edit Finding Failure",

  DELETE_FINDING = "[Findings API] Delete Finding",
  DELETE_FINDING_SUCCESS = "[Findings API] Delete Finding Success",
  DELETE_FINDING_FAILURE = "[Findings API] Delete Finding Failure",


  DELETE_IMAGE_FINDINGS = "[Findings API] Delete Image's Findings",
  DELETE_IMAGE_FINDINGS_SUCCESS = "[Findings API] Delete Images's Findings Success",
  DELETE_IMAGE_FINDINGS_FAILURE = "[Findings API] Delete Images's Findings Failure",

  DELETE_SESSION_FINDINGS = "[Findings API (Admin)] Delete Session Findings",
  DELETE_SESSION_IMAGE_FINDINGS_START = "[Findings API (Admin)] Delete Session Findings By Image START",
  DELETE_SESSION_FINDINGS_SUCCESS = "[Findings API (Admin)] Delete Session Findings Success",
  DELETE_SESSION_IMAGE_FINDINGS_SUCCESS = "[Findings API (Admin)] Delete Session Findings By Image SUCCESS",
  DELETE_SESSION_FINDINGS_FAILURE = "[Findings API (Admin)] Delete Session Findings Failure",
  DELETE_SESSION_IMAGE_FINDINGS_FAILURE = "[Findings API (Admin)] Delete Session Findings By Image FAILURE",
}

export const fetchFindings = createAction(
  ActionTypes.FETCH_FINDINGS,
  props<{ imageId: string }>()
);
export const fetchFindingsSuccess = createAction(
  ActionTypes.FETCH_FINDINGS_SUCCESS,
  props<{ imageId: string; findingEntities: Finding[] }>()
);
export const fetchFindingsFailure = createAction(
  ActionTypes.FETCH_FINDINGS_FAILURE,
  props<{ error: any }>()
);

export const addNewFinding = createAction(
  ActionTypes.ADD_NEW_FINDING,
  props<{ imageId: string; newFinding: FindingCreationPayload }>()
);
export const addNewFindingSuccess = createAction(
  ActionTypes.ADD_NEW_FINDING_SUCCESS,
  props<{ imageId: string; newFinding: FindingCreationPayload }>()
);
export const addNewFindingFailure = createAction(
  ActionTypes.ADD_NEW_FINDING_FAILURE,
  props<{ imageId: string; error: any }>()
);

export const editFinding = createAction(
  ActionTypes.EDIT_FINDING,
  props<{
    imageId: string;
    findingId: string;
    findingUpdate: FindingUpdatePayload;
  }>()
);
export const editFindingSuccess = createAction(
  ActionTypes.EDIT_FINDING_SUCCESS
);
export const editFindingFailure = createAction(
  ActionTypes.EDIT_FINDING_FAILURE,
  props<{ error: any }>()
);

export const deleteFinding = createAction(
  ActionTypes.DELETE_FINDING,
  props<{ imageId: string; findingId: string }>()
);
export const deleteFindingSuccess = createAction(
  ActionTypes.DELETE_FINDING_SUCCESS
);
export const deleteFindingFailure = createAction(
  ActionTypes.DELETE_FINDING_FAILURE,
  props<{ error: any; imageId: string }>()
);


export const deleteImageFindings = createAction(
  ActionTypes.DELETE_IMAGE_FINDINGS,
  props<{ imageId: string;}>()
);
export const deleteImageFindingsSuccess = createAction(
  ActionTypes.DELETE_IMAGE_FINDINGS_SUCCESS
);
export const deleteImageFindingsFailure = createAction(
  ActionTypes.DELETE_IMAGE_FINDINGS_FAILURE,
  props<{ error: any; imageId: string }>()
);

export const deleteSessionFindings = createAction(
  ActionTypes.DELETE_SESSION_FINDINGS,
  props<{ patientId: string; sessionDate: string; sessionId: string }>()
);


export const deleteSessionImageFindingsStart = createAction(
  ActionTypes.DELETE_SESSION_IMAGE_FINDINGS_START,
  props<{ imageId: string; findings:Finding[] }>()
);
export const deleteSessionFindingsSuccess = createAction(
  ActionTypes.DELETE_SESSION_FINDINGS_SUCCESS
);

export const deleteSessionImageFindingsSuccess = createAction(
  ActionTypes.DELETE_SESSION_IMAGE_FINDINGS_SUCCESS,
  props<{ imageId: string; }>()
);



export const deleteSessionImageFindingsFailure = createAction(
  ActionTypes.DELETE_SESSION_IMAGE_FINDINGS_FAILURE,
  props<{ error: any, imageId: string }>()
);
export const deleteSessionFindingsFailure = createAction(
  ActionTypes.DELETE_SESSION_FINDINGS_FAILURE,
  props<{ error: any }>()
);

export const errorActions = [
  fetchFindingsFailure,
  addNewFindingFailure,
  editFindingFailure,
  deleteFindingFailure,
];
