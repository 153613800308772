import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SwiperService {
  init(swiperEl: any, params: any) {
    Object.assign(swiperEl, params);

    swiperEl.initialize();
  }
}
