
import { RenderState } from '@kells/shared-ui/canvas';
import { isNullable } from '@kells/utils/js';
import  {theme } from '../../../exported-theme';
import { IFabricFindingStyles, Palette, PaletteKeyType } from '../fabricstyle.interface';



export const mountPalette = (colors: ReadonlyArray<string>): { main: Palette, alt: Palette } => {
  return {
    main: {
      900: colors[0],
      800: colors[1],
      700: colors[2],
      600: colors[3],
      500: colors[4],
      400: colors[5],
      300: colors[6],
      200: colors[7],
      100: colors[8],
      50: colors[9],
    },
    alt: {
      900: colors[9],
      800: colors[8],
      700: colors[7],
      600: colors[6],
      500: colors[5],
      400: colors[4],
      300: colors[3],
      200: colors[2],
      100: colors[1],
      50: colors[0],
    }
  }
};


const MAIN_PALETTE =  theme.colors.cariesInitial; //.altBright).alt;
const HOVER_PALETTE = ['#C6C0C8', '#B5AEB5', '#A49DA4', '#938D92', '#827C81', '#716C71', '#615D61', '#524D51', '#433F42'];
const FOCUS_PALETTE = theme.colors.cyan as Palette;
const ACTIVE_PALETTE = theme.colors.cyan as Palette;

class CariesStateStyles implements IFabricFindingStyles {

  HOVER_PALETTE = HOVER_PALETTE;
  ACTIVE_PALETTE  = ACTIVE_PALETTE;
  ACTIVE_HOVER_PALETTE=ACTIVE_PALETTE;
  FOCUS_PALETTE  =  FOCUS_PALETTE;
  FOCUS_HOVER_PALETTE  = FOCUS_PALETTE;
  MAIN_PALETTE  = MAIN_PALETTE;

  strokeWidth_active_hover = 3;
  strokeWidth_active = 3;
  strokeWidth_focus_hover = 2;
  strokeWidth_focus = 2;

  strokeWidth_hover = 8; 
  strokeWidth = 8; 

  strokeColor:string  = MAIN_PALETTE[300] + 'ff';
  strokeColor_hover: string = HOVER_PALETTE[200];

  fillColor = 'transparent';
  fillColor_hover: string  = MAIN_PALETTE[300] + '20';

  strokeColor_focus: string  = FOCUS_PALETTE['100'] + 'cc';
  strokeColor_focus_hover: string = FOCUS_PALETTE[300] as string;

  fillColor_focus: string = FOCUS_PALETTE['200'] + '27';
  fillColor_focus_hover: string = (FOCUS_PALETTE[200] as string) + '15';

  strokeColor_active: string = ACTIVE_PALETTE[300] + '27';
  strokeColor_active_hover: string = ACTIVE_PALETTE[200] as string;

  fillColor_active: string = ACTIVE_PALETTE[300] + '27';
  fillColor_active_hover: string;

 

 unconfirmedDashArray = [10, 10, 30, 30, 30, 20];

  confirmed = false;

  rectState: RenderState;

  // eslint-disable-next-line class-methods-use-this
  // loadAltModerate():void {
  //   const keys = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50];

  //   // keys.map((key, i) => {
  //   //   altBrightTheme[key] = theme.colors.cariesModerateFocus.altBright[keys[i]] as string;
  //   // });
  //   const focus = new Map();
  //   keys.forEach((key, i) => {
  //     focus.set(key, theme.colors.cariesModerateFocus.altBright[key]);
  //   });
  //   const active = new Map();
  //   keys.map((key, i) => {
  //     active.set(key, focus.set(key, theme.colors.cariesModerateActive.altBright[key]));
  //   });
  //   const focusHover = new Map();
  //   keys.map((key, i) => {
  //     if(i > 50) {
  //       focus.set(key, focus.set(key, theme.colors.cariesModerateActive.altBright[ i < 900 ? key + 100: key]));
  //     }
  //   });

  // }

  // registerPalette(key:string, palette:Palette ) {
  //   switch(key) {
  //     case 'MAIN':
  //     this.MAIN_PALETTE = palette;
  //     break;
  //     case 'FOCUS':
  //     this.FOCUS_PALETTE = palette;
  //     break;
  //     case 'HOVER':
  //     this.HOVER_PALETTE = palette;
  //     break;
  //     case 'FOCUS_HOVER':
  //     this.FOCUS_HOVER_PALETTE = palette;
  //     break;
  //     case 'ACTIVE':
  //       this.ACTIVE_PALETTE = palette;
  //     break;
  //     case 'ACTIVE_HOVER':
  //       this.ACTIVE_HOVER_PALETTE = palette;
  //     break;
  //     default:
  //     console.warn('Uknown key value ' + key, palette);
  //   }
  // }

  commitColor() {
    //this.initColors(this.MAIN_PALETTE, this.HOVER_PALETTE, this.ACTIVE_PALETTE, this.ACTIVE_HOVER_PALETTE, this.FOCUS_PALETTE, this.FOCUS_HOVER_PALETTE);
  }
/*
  initColors(main:Palette, hover:Palette, active:Palette, activeHover:Palette, focus:Palette, focusHover:Palette) {
    if (!isNullable(main.defaultKey) && !isNullable(main[main.defaultKey as PaletteKeyType])  ) {
      this.strokeColor = (main[main.defaultKey as PaletteKeyType]) + 'cc';
    }

    this.strokeColor_hover = hover[main.hoverKey] as string | undefined | number;

    this.fillColor = main['300'] + '00';
    this.fillColor_hover = main[50] + '00';

    this.strokeColor_focus = focus['100'] + 'cc';
    this.strokeColor_focus_hover = focusHover['300'] as string;

    this.fillColor_focus = focus['200'] + '27';
    this.fillColor_focus_hover = focusHover['200'] + '15';

    this.strokeColor_active = active['300'] + '27';
    this.strokeColor_active_hover = active['200'];

    this.fillColor_active = active['300'] + '27';
    this.fillColor_active_hover = activeHover['200'];
  }*/

  setInitial() {
    const up = ['#C6C0C8', '#B5AEB5', '#A49DA4', '#938D92', '#827C81', '#716C71', '#615D61', '#524D51', '#433F42'];
    const {
      cariesInitialFocus_altDark:hover,
      pinkGray_altVivid: active } = theme.colors;
    const focus = ['#5C454E', '#B9969B',
    '#CCA8AB',

    '#DFBBBA','#F2CEC9'];

    // this.strokeWidth_focus_hover = focus[50];
    // this.strokeWidth_focus = focus[100];


    this.strokeColor = hover[2];
    this.strokeColor_hover = hover[1];

    this.fillColor = active[4] + '00';
    this.fillColor_hover = active[3] + '10';

    this.strokeColor_focus = '#ffffff' + 'cc';
    this.strokeColor_focus_hover = '#ffffff';

    this.fillColor_focus=focus[2] + '00';
    this.fillColor_focus_hover = focus[1] + '11';

    this.strokeColor_active = active[4];
    this.strokeColor_active_hover = active[1];

    this.fillColor_active = active[1] + '00';
    this.fillColor_active_hover = active[1] + '11';
  }

  setModerate() {
    const {
      cariesModerateFocus_altDark: up,
      cariesModerateActive_altDark: hover,
      cariesModerateFocus_altDark: focus }= theme.colors;
    const { pinkGray_altVivid: active } = theme.colors;

    // this.strokeWidth_focus_hover = focus[50];
    // this.strokeWidth_focus = focus[100];


    this.strokeColor = up[2];
    this.strokeColor_hover = hover[1];

    this.fillColor = active[4] + '00';
    this.fillColor_hover = hover[3] + '10';

    this.strokeColor_focus = focus[1];
    this.strokeColor_focus_hover = focus[0];

    this.fillColor_focus = focus[2] + '00';
    this.fillColor_focus_hover = focus[1] + '11';

    this.strokeColor_active = active[4];
    this.strokeColor_active_hover = active[1];

    this.fillColor_active = active[1] + '00';
    this.fillColor_active_hover = active[1] + '11';
  }

  setAdvanced() {
    const {
      cariesAdvanced_altBright: up,
      cariesAdvanced_altBright: hover,
      cariesAdvancedFocus_altBright: focus } = theme.colors;
    const { pinkGray_altDark: active } = theme.colors;

    // this.strokeWidth_focus_hover = focus[50];
    // this.strokeWidth_focus = focus[100];


    this.strokeColor = up[3];
    this.strokeColor_hover = hover[4];

    this.fillColor = up[6] + '00';
    this.fillColor_hover = hover[7] + '10';

    this.strokeColor_focus = focus[5];
    this.strokeColor_focus_hover = focus[7];

    this.fillColor_focus = focus[6] + '00';
    this.fillColor_focus_hover = focus[7] + '11';

    this.strokeColor_active = active[3];
    this.strokeColor_active_hover = active[4];

    this.fillColor_active = active[4] + '00';
    this.fillColor_active_hover = active[5] + '11';
  }

  setUp() {
    this.rectState = {
      strokeWidth: this.strokeWidth,
      strokeColor: this.strokeColor,
      fillColor: this.fillColor,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray
    };
  }

  setActiveHover() {
    this.rectState = {
      strokeWidth: this.strokeWidth_active_hover,
      strokeColor: this.strokeColor_active_hover,
      fillColor: this.fillColor_active_hover,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray
    };
  }

  setActive() {
    this.rectState = {
      strokeWidth: this.strokeWidth_active,
      strokeColor: this.strokeColor_active,
      fillColor: this.fillColor_active,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray
    };
  }

  setFocusHover() {
    this.rectState = {
      strokeWidth: this.strokeWidth_focus_hover,
      strokeColor: this.strokeColor_focus_hover,
      fillColor: this.fillColor_focus_hover,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray
    };
  }


  setFocus() {
    this.rectState = {
      strokeWidth: this.strokeWidth_focus,
      strokeColor: this.strokeColor_focus,
      fillColor: this.fillColor_focus,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray
    };
  }
  setHover() {
    this.rectState = {
      strokeWidth: this.strokeWidth_hover,
      strokeColor: this.strokeColor_hover,
      fillColor: this.fillColor_hover,
      strokeDashArray: this.confirmed ? undefined : this.unconfirmedDashArray
    };
  }

  public constructor(confirmed: boolean) {
    this.confirmed = confirmed;

    this.setUp();
  }


}
export default CariesStateStyles;
