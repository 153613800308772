import { isNullable } from "@kells/utils/js";
import { Observable } from "rxjs";
import ResizeObserver from "resize-observer-polyfill";

/**
 * Creates an observable that emits when the specified DOM element resizes.
 *
 * ### Why it exists
 *
 * This observable creator makes it easier to react to a DOM element's size
 * changes.
 *
 * The `fromEvent` creator from `rxjs` can observe on page resizes like so:
 *
 * ```typescript
 * const windowResized$ = fromEvent(window, 'resize');
 * ```
 *
 * Yet there is no way to do `fromEvent(someDomElement, 'resize')`, because
 * `'resize'` isn't an event that's emitted by a DOM element.
 *
 * ### Behavior
 *
 * The observable:
 *
 * - emits when the DOM element has been resized
 * - errs when the provided DOM element is `null` or `undefined`
 * - does not complete on its own; user needs to unsubscribe explicitly.
 *
 * ### Example
 *
 * `fromElementResize` works well with Angular element refs. In an Angular
 * component, you may observe an element's resize using the `ngAfterViewInit`
 * lifecycle hook:
 *
 * ```typescript
 * import { fromElementResize } from '@kells/observable-utils/observable-creators';
 *
 * class BlogComponent {
 *   // in the HTML template, this element would have to be marked with
 *   // '#blog_container'
 *   @ViewChild('blog_container') blogContainerRef: ElementRef;
 *
 *   blogContainerResized$: Observable<{ width: number; height: number }>;
 *
 *   ngAfterViewInit() {
 *     if (!this.blogContainerRef.nativeElement) {
 *       throw new Error("Blog body isn't initialized.");
 *     }
 *
 *     this.blogContainerResized$ = fromElementResize(
 *       this.blogContainerRef.nativeElement
 *     )
 *   }
 * }
 * ```
 *
 * More generally, `fromElementResize` works with any DOM element that can be
 * acquired by native APIs, such as `document.getElementById(...)`.
 * To get started, you may:
 *
 * ```typescript
 * // wouldn't compile under TS 'strict' mode as the element may be `null`
 * const appResized$ = fromElementResize(document.getElementById('#app'));
 *
 * const tabResized$ = fromElementResize(document.getElementsByClassName('.tab')[0]);
 * ```
 *
 * Note that the observable errs when the element provided is `null`. This is
 * prevented at build time if TypeScript strict mode is enabled. More generally,
 * it is prudent to perform a null check before using `getElementResize`:
 *
 * ```typescript
 * const appContainer = document.getElementById('#app');
 *
 * if (appContainer) {
 *   fromElementResize(appContainer).subscribe(...)
 * }
 * ```
 *
 * @param element the DOM element to observe.
 * @returns an observable that emits the element's height and size when the
 *    element has been resized.
 */
export const fromElementResize = (
  element: Element
): Observable<{ width: number; height: number }> => {
  return new Observable<{ width: number; height: number }>((subscriber) => {
    const resizeObserver = new ResizeObserver(
      (entries: Readonly<ResizeObserverEntry[]>) => {
        // We use the first element that's matched by the resize observer.
        // Only one element's height and width will be emitted by an observable.
        const firstEntry = entries[0];

        subscriber.next({
          width: firstEntry.contentRect.width,
          height: firstEntry.contentRect.height,
        });
      }
    );

    if (isNullable(element)) {
      subscriber.error("The provided element cannot be `undefined` or `null`.");
    }

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  });
};
