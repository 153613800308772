import { Injectable } from "@angular/core";
import axios from "axios";
import JSZip from "jszip";
import mime from "mime";
import { saveAs } from "file-saver";
import bowser from "bowser";
import { uniqueId } from "lodash";

import {
  DownloadFileModel,
  FileBlobData,
} from "../../../../../oral-analytics/report/src/lib/models/file.model";

const browser = bowser.parse(window.navigator.userAgent);

@Injectable({
  providedIn: "root",
})
export class FileService {
  public getBlobMimeType(blob: Blob): string | null {
    return mime.getExtension(blob.type);
  }

  public async getFileBlobDataByUrl({
    fileName,
    downloadUrl,
    includeFileExtension,
  }: DownloadFileModel): Promise<FileBlobData> {
    try {
      // https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
      // force Chrome to make a new request
      const uniqueQueryParam =
        browser?.browser?.name === "Chrome"
          ? `&x-request=${uniqueId() + new Date().getTime()}`
          : "";
      const { data: fileBlob } = await axios.get<Blob>(
        `${downloadUrl}${uniqueQueryParam}`,
        {
          responseType: "blob",
        }
      );

      if (includeFileExtension) {
        fileName = `${fileName}.${this.getBlobMimeType(fileBlob)}`;
      }

      return { fileBlob, fileName };
    } catch (e) {
      throw new Error(e);
    }
  }

  public async getFilesBlobsDataByUrl(
    files: DownloadFileModel[]
  ): Promise<FileBlobData[]> {
    const filesBlobsDataPromises = await Promise.allSettled(
      files.map((file) => this.getFileBlobDataByUrl(file))
    );
    const filesBlobsData = filesBlobsDataPromises
      .filter((response) => response.status === "fulfilled")
      .map(
        (response) => (response as PromiseFulfilledResult<FileBlobData>).value
      );

    return filesBlobsData;
  }

  async downloadZip(
    filesBlobsData: FileBlobData[],
    folderName: string
  ): Promise<void> {
    const zip = new JSZip();
    const folder = zip.folder(folderName);

    if (!folder) return;

    filesBlobsData.forEach((file) => {
      const fileData = new File([file.fileBlob], file.fileName);

      folder.file(file.fileName, fileData, { base64: true });
    });

    const content = await folder.generateAsync({ type: "blob" });

    saveAs(content, folderName);
  }

  public async urlToFile(imageUrl: string, fileName: string): Promise<File> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });

    return file;
  }

  public fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }
}
