<ng-container *ngIf="dataReady$ | async">
  <div class="snapshot-row-layout grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2">
    <div class="left-side">
      <h1 class="snapshot-title pb-5 fw-700">BONE HEALTH STATUS</h1>
      <h3 class="snapshot-subtitle py-2 fw-700">SUMMARY</h3>
      <p class="snapshot-description py-2 fw-500 ibmplex-semi-font ft-medium">
        {{ snapshotBonelossBody$ | async }}
      </p>
    </div>
    <div class="right-side relative">
      <koa-snapshot-boneloss-chart></koa-snapshot-boneloss-chart>
    </div>
  </div>
</ng-container>