/** The range of feasible tooth number values. */
export const TOOTH_NUMBER_RANGE = {
  MIN: 1,
  MAX: 32,
};

/**
 * Tooth numbers.
 *
 * Contains the numbers that are considered to be valid tooth numbers.
 * Use this type to assert that a static number that is immutable is one of the
 * tooth numbers.
 */
export type ToothNumber =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32;

export enum ToothLocation {
  UpperRight = "Upper Right",
  LowerRight = "Lower Right",
  UpperLeft = "Upper Left",
  LowerLeft = "Lower Left",
}
