import {
  CariesStageTypes,
  FindingFilterSelectionTypes,
  FindingStatus,
  FindingType,
} from "@kells/interfaces/finding";
import { CanvasLibFinding, KellsFabric } from "@kells/shared-ui/canvas";
import { theme } from "../../../exported-theme";
import BaseFindingObject from "../base-finding-object";
import { default as CariesStateStyles } from "./styles";
import { fabric } from "fabric";
import { isNullable } from "@kells/utils/js";
import { CanvasContext } from "../../canvas/canvas-context.interface";
import { tap } from "rxjs/operators";

class CariesFindingFabric extends BaseFindingObject {
  static BASE_FABRIC_PROPS = {
    strokeUniform: true,
    hasRotatingPoint: false, // hide the rotating control above a fabric.Rect
    hasBorders: false, // disable the blue border shown when a box is selected
    selectable: true,
    hasControls: true,
    lockScalingX: false,
    lockScalingY: false,
    lockRotation: true,
    noScaleCache: false,
    strokeLineCap: "square",
    hoverCursor: "pointer",
    cornerColor: theme.controlFillColor,
    cornerStrokeColor: theme.controlStrokeColor,
    transparentCorners: false,
    cornerSize: theme.controlCornerSize,
  };

  createLabel() {
    if (!this.labelElement && !isNullable(this.object)) {
      const { id, object, styles: style, canvas } = this;

      this.labelElement = fabric.util.makeElement("div", {
        id: `${id}_label`,
      }) as HTMLDivElement;

      this.labelElement.classList.add(
        "kco-finding-label",
        "finding-label-carries",
        "finding-carries-" + this.finding.stage
      );
      this.labelElement.classList.add("invisible");
      // this.labelElement.innerHTML = `
      // <div class="label-inner">
      //   <div>
      //   <span class="type">${this.finding.stage}</span>
      //   <span class="stage" >${this.finding.type}</span>
      //   </div>
      //   <span class="tooth"  data-testid="tooth-num">
      //     <span class="strong">Tooth</span>
      //     <span>${this.finding?.tooth ?? 'Unknown'}</span>
      //     <span class="location">${this.finding.location}</span>
      //   </span>
      // </div>`;
      this.labelElement.innerHTML = `
        <div class="label-inner">
          <div>
          <span class="type">Caries:${
            this.finding?.tooth ?? "Unknown"
          }${this.finding.location != 'others' ? this.finding.location.slice(0, 1).toUpperCase() : '-'}</span>
          </div>
          <span class="tooth"  data-testid="tooth-num">
          <span class="type">${this.finding.stage}</span>
          </span>
        </div>`;
      this.canvasElementRef = document.getElementById(
        "overlay-positioning-container"
      ) as HTMLDivElement;
      document.body.appendChild(this.labelElement);
      super.createLabel();
    }
  }

  constructor(
    finding: CanvasLibFinding,
    protected _ctx: CanvasContext,
    ordinality: number
  ) {
    super(finding, _ctx);
    this.ordinality = ordinality;
    this.styles = new CariesStateStyles(
      finding.status === FindingStatus.Confirmed
    );

    if (finding.stage === CariesStageTypes.Initial && this.styles.setInitial) {
      this.styles.setInitial();
    }
    if (
      finding.stage === CariesStageTypes.Moderate &&
      this.styles.setModerate
    ) {
      this.styles.setModerate();
    }
    if (
      finding.stage === CariesStageTypes.Advanced &&
      this.styles.setAdvanced
    ) {
      this.styles.setAdvanced();
    }
    this.typeKey = finding.stage as FindingFilterSelectionTypes;
    this.styles.setUp();
    this.initFabricObject();
    this.initStyles();
    this._subs.sink = _ctx.findingFilterKeys$
      .pipe(
        tap((f: string[]) => {
          if (f.includes(this.finding.stage)) {
            this.makeVisible();
          } else {
            this.makeHidden();
          }
        })
      )
      .subscribe();
    setTimeout(() => {
    }, 0);
  }

  initFabricObject() {
    super.initFabricObject(CariesFindingFabric.BASE_FABRIC_PROPS);
  }

  updateFinding(finding: CanvasLibFinding, render = false) {
    this.styles = new CariesStateStyles(
      finding.status === FindingStatus.Confirmed
    );

    if (finding.stage === CariesStageTypes.Initial && this.styles.setInitial) {
      this.styles.setInitial();
    }
    if (
      finding.stage === CariesStageTypes.Moderate &&
      this.styles.setModerate
    ) {
      this.styles.setModerate();
    }
    if (
      finding.stage === CariesStageTypes.Advanced &&
      this.styles.setAdvanced
    ) {
      this.styles.setAdvanced();
    }
    this.styles.setUp();
    this.typeKey = finding.stage as FindingFilterSelectionTypes;
    super.updateFinding(finding, render);
  }
}

export default CariesFindingFabric;
