export enum ImageFilters {
  All = "All",
  Bitewings = "Bitewings",
}

export enum StoryboardImageFilters {
  None = "None",
  All = "All",
  Caries = "Caries",
  /**
   * The default fallback value for when no filter is selected in the image
   * history view.
   */
  Uninitialized = "",
}

const bitewingTemplate = [
  "Bitewingrightmolar",
  "Bitewingrightpremolar",
  "Bitewingleftpremolar",
  "Bitewingleftmolar",
];

const imageTemplates = {
  [ImageFilters.Bitewings]: bitewingTemplate,
};

/** Returns an image's template, given the filter name. */
export const getImageTemplate = (filter: ImageFilters) => {
  switch (filter) {
    case ImageFilters.All:
      return null;
    default:
      return imageTemplates[filter];
  }
};

// prettier-ignore
export const FMX_TEMPLATE = [
  7, 8, 15, 5, 16, 9, 10,
  1, 2, 3, 4,
  11, 12, 17, 6, 18, 13, 14
];
