export interface CariesDetectionThresholds {
  low: number;
  medium: number;
  high: number;
}

/**
 * Caries detection threshold values to be used when none is set for the
 * logged in user.
 */
export const CariesDetectionThresholdDefaultValues: CariesDetectionThresholds = {
  low: 0.7,
  medium: 0.5,
  high: 0.3,
};

export type CariesDetectionThresholdTypes = keyof CariesDetectionThresholds;

export interface PredictionThresholdSettings extends CariesDetectionThresholds {
  selected: CariesDetectionThresholdTypes;
}

export enum UserRole {
  Dentist = "dentist",
  Hygenist = "hygenist",
  Assistant = "assistant",
  PracticeManager = "practice manager",
  Admin = "admin",
  Test = "test",
}

export enum UserPermission {
  // Patient permissions
  ReadPatient = "read:patient",
  CreatePatient = "create:patient",
  UpdatePatient = "update:patient",
  DeletePatient = "delete:patient",
  // User permissions
  ReadUser = "read:user",
  CreateUser = "create:user",
  UpdateUser = "update:user",
  DeleteUser = "delete:user",
  // Office permissions
  ReadOffice = "read:office",
  CreateOffice = "create:office",
  UpdateOffice = "update:office",
  DeleteOffice = "delete:office",
  // Organization permissions
  ReadOrganization = "read:organization",
  CreateOrganization = "create:organization",
  UpdateOrganization = "update:organization",
  DeleteOrganization = "delete:organization",
  // Office_user permissions
  ReadOfficeUser = "read:office_user",
  CreateOfficeUser = "create:office_user",
  UpdateOfficeUser = "update:office_user",
  DeleteOfficeUser = "delete:office_user",
  // Office_patient permissions
  ReadOfficePatient = "read:office_patient",
  CreateOfficePatient = "create:office_patient",
  UpdateOfficePatient = "update:office_patient",
  DeleteOfficePatient = "delete:office_patient",
}

export const USER_PERMISSION_LABELS: Record<UserPermission, string> = {
  // Patient permissions
  [UserPermission.ReadPatient]: "Read Patient",
  [UserPermission.CreatePatient]: "Create Patient",
  [UserPermission.UpdatePatient]: "Update Patient",
  [UserPermission.DeletePatient]: "Delete Patient",
  // User permissions
  [UserPermission.ReadUser]: "Read User",
  [UserPermission.CreateUser]: "Create User",
  [UserPermission.UpdateUser]: "Update User",
  [UserPermission.DeleteUser]: "Delete User",
  // Office permissions
  [UserPermission.ReadOffice]: "Read Office",
  [UserPermission.CreateOffice]: "Create Office",
  [UserPermission.UpdateOffice]: "Update Office",
  [UserPermission.DeleteOffice]: "Delete Office",
  // Organization permissions
  [UserPermission.ReadOrganization]: "Read Organization",
  [UserPermission.CreateOrganization]: "Create Organization",
  [UserPermission.UpdateOrganization]: "Update Organization",
  [UserPermission.DeleteOrganization]: "Delete Organization",
  // Office_user permissions
  [UserPermission.ReadOfficeUser]: "Read Office User",
  [UserPermission.CreateOfficeUser]: "Create Office User",
  [UserPermission.UpdateOfficeUser]: "Update Office User",
  [UserPermission.DeleteOfficeUser]: "Delete Office User",
  // Office_patient permissions
  [UserPermission.ReadOfficePatient]: "Read Office Patient",
  [UserPermission.CreateOfficePatient]: "Create Office Patient",
  [UserPermission.UpdateOfficePatient]: "Update Office Patient",
  [UserPermission.DeleteOfficePatient]: "Delete Office Patient",
};

/**
 * Describes the essential and optional properties of a Kells user.
 */
export interface KellsUser {
  /** The email associated with this user account. */
  email: string;
  /**
   * The ID provided during login.
   *
   * Per the default sign-up flow, users should have their emails as their
   * usernames. This field is added for future flexibility, should we introduce
   * another system for user names / user IDs.
   */
  id: string;
  /** ID of the office this Kells User belongs to */
  officeId?: string;
  /**
   * A name associated with the user that is more readable than their email
   * and usernames.
   *
   * The sign-up flow defaults this field to the name of the email address.
   * For instance, the nickname for `alex@getkells.com` is `'alex'`.
   */
  nickname?: string;
  /** The user's first name */
  firstName?: string;
  /** The user's last name */
  lastName?: string;
  /** The user's gender */
  gender?: string;
  /** Indicates whether the user is an admin */
  role?: UserRole;
  /** Contains user-specific settings */
  settings: {
    autoEnhance: boolean;
    cariesDetection: {
      thresholds: {
        low: number;
        medium: number;
        high: number;
      };
      selected: CariesDetectionThresholdTypes;
    };
  };
}

/**
 * Response signature of requesting a Kells User's information.
 */
export interface KellsUserResponse {
  /** A one-element array containing the info of the requested user. */
  data: {
    _id: string;
    email: string;
    first_name: string;
    gender: string;
    last_name: string;
    office: string;
    role: UserRole;
    settings?: {
      auto_enhance_images?: boolean;
      prediction_thresholds: PredictionThresholdSettings;
    };
  };
}

export type KellsUserAuthResponse = Pick<
  KellsUser,
  "email" | "id" | "nickname"
>;

export interface UserResponse {
  readonly _id: string;
  readonly email: string;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly office: string;
  readonly photo_url?: string;
  readonly specialty?: string;
  readonly permissions: UserPermission[];
  readonly role: string;
  readonly auth_id?: string;
  readonly gender?: "male" | "female";
  readonly organizations: string[];
}

export interface User {
  readonly id: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly office: string;
  readonly photoUrl: string;
  readonly specialty: string;
  readonly permissions: UserPermission[];
  readonly role: string;
  readonly authId: string;
  readonly gender?: "male" | "female";
  readonly organizations: string[];
}

export interface GetUserResponse {
  readonly data: UserResponse;
  readonly message: string;
}

export interface GetUsersResponse {
  readonly data: UserResponse[];
  readonly message: string;
}

export interface AddUserRequestParams {
  readonly first_name: string;
  readonly last_name: string;
  readonly email: string;
  readonly specialty?: string;
  readonly gender?: "male" | "female";
  readonly photo_url?: string;
  readonly image_data_b64?: string | null;
  readonly office?: string;
  readonly role?: UserRole;
  readonly permissions?: UserPermission[];
  readonly organizations?: string[];
}

export interface UpdateUserRequestParams extends AddUserRequestParams {
  readonly id: string;
}

export interface AddUserResponse {
  readonly user_id: string;
}

export interface UpdateUserResponse {
  readonly user_id: string;
}

export interface DentistResponse {
  provider?: UserResponse | null;
  session?: string;
}

export interface DentistsResponse {
  readonly data: {
    providers?: DentistResponse[];
  };
  readonly message: string;
}

export interface UserProvider {
  provider: User | null;
  session?: string;
}
