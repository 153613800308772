import { Injectable } from "@angular/core";
import { FindingStatus, FindingType } from "@kells/interfaces/finding";
import { assertNever, isNullable } from "@kells/utils/js";
import { FindingService, Image } from "@kells/clinic-one/apis";

/**
 * Type describing the predicate functions accepted in [[`ImageFilterService.filter`]].
 */
export type ImagePredicate<G> = (image: G) => boolean;

/**
 * Service responsible for filtering images given certain predicates.
 *
 * See the class's `filter` function for more detail.
 *
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class ImageFilterService {
  constructor() {}

  /**
   * Given some images, return a subset of them given some filtering criteria.
   *
   * This function does not mutate the images in any way. The returned subset
   * contains new references to the original images (i.e. they're shallow copied).
   *
   * @param images Provide one or more images to filter from. This parameter
   *    accepts a single image as-is, or an image array of an arbitrary size.
   *
   * @param predicates Provide one or more functions that specify the filtering
   *    rules. For an image to be accepted, all predicates must return `true`
   *    on this image.
   *    These functions should at least accept an [[`Image`]] as input,
   *    and must return a `boolean`.
   *      - See [[`ImagePredicate`]] for the exact type specifications.
   *      - See [[`By`]] in this file for the pre-built, commonly used filter functions.
   *
   * @returns an array of images that fit the filtering criteria. The returned
   *    array could be:
   *       - empty: meaning no image met the provided criteria
   *       - a filtered array: meaning not all images met all of the predicates
   *       - equal to the input array: meaning all images met the criteria
   * @throws
   *   This method itself does not throw, but the predicate functions provided
   *   as parameters may. Check the specified predicates for more detail.
   *
   * @usage
   * ```ts
   * // Filtering images do not require injecting the `ImageFilterService`,
   * // because this method is static.
   *
   * export class AppComponent extends OnInit {
   *
   *   ngOnInit(private someDataService) {
   *     this.someDataService.getImages().pipe(
   *       map(images => {
   *         // Get all images that contain at least some finding to render,
   *         // and includes a caries on tooth number 10.
   *         return ImageFilterService.filter(
   *           images,
   *           By.ContainingRenderableFinding,
   *           By.ContainingCaries,
   *           By.ContainingTooth(10)
   *         )
   *       })
   *     )
   *   }
   * }
   * ```
   */
  static filter<G extends Image>(
    images: G | G[],
    predicate: ImagePredicate<G>,
    ...predicates: ImagePredicate<G>[]
  ): G[] {
    const _images = Array.isArray(images) ? images : [images];
    const _predicates = [predicate, ...predicates];

    return _predicates.reduce(
      (imagesToFilter, nextPredicate) =>
        imagesToFilter.filter((g) => nextPredicate(g)),
      _images
    );
  }
}

/**
 * A collection of predicate functions used for image filtering.
 */
export const By = Object.freeze({
  /**
   * Checks if an image contains some finding that is of the provided type.
   *
   * @param targetType Set a type of finding to filter for. See [[`FinidngType`]]
   *    for all available finding types.
   */
  ContainingFindingType: <G extends Image>(targetType: FindingType) => (
    image: G
  ): boolean => {
    return image.findings.some((f) => f.type === targetType);
  },

  /**
   * Checks if an image contains some finding that can be rendered on-screen.
   */
  ContainingRenderableFinding: <G extends Image>(image: G): boolean => {
    return image.findings.some((f) => FindingService.isFindingRenderable(f));
  },

  /**
   * Checks if an image contains as least one caries.
   */
  ContainingCaries: <G extends Image>(image: G): boolean => {
    return By.ContainingFindingType(FindingType.Caries)(image);
  },

  /**
   * Checks if an image contains at least one caries on a specific tooth.
   *
   * @param targetTooth the tooth number where caries will be checked.
   */
  ContainingCariesOnTooth: <G extends Image>(targetTooth: number) => (
    image: G
  ): boolean => {
    return (
      image.findings.length > 0 &&
      image.findings.some((f) => {
        if (isNullable(f.tooth)) return false;
        const toothNumber = Number.parseInt(f.tooth);
        return f.type === FindingType.Caries && toothNumber === targetTooth;
      })
    );
  },

  /**
   * Checks if an image contains at least one prediction.
   */
  ContainingPredictions: <G extends Image>(image: G): boolean => {
    return image.findings.some((f) => f.status === FindingStatus.Predicted);
  },

  /**
   * Checks if an image contains at least one finding corresponding to
   * the specified tooth.
   *
   * @param targetTooth a tooth, in the format of one of the following:
   *    - a number,
   *    - a string parseable as a number
   *    - other strings (Filter will only be performed if it is one of the
   *      letters 'A-T'. These are the only valid tooth letters).
   */
  ContainingTooth: <G extends Image>(targetTooth: string | number) => (
    image: G
  ): boolean => {
    return image.findings.some((f) => {
      const { tooth: findingTooth } = f;

      if (isNullable(findingTooth)) return false;

      if (typeof findingTooth === "string" && typeof targetTooth === "string") {
        return findingTooth === targetTooth;
      } else if (
        typeof findingTooth === "number" &&
        typeof targetTooth === "number"
      ) {
        return findingTooth === targetTooth;
      } else if (
        typeof findingTooth === "string" &&
        typeof targetTooth === "number"
      ) {
        const intFindingTooth = Number.parseInt(findingTooth, 10);
        if (Number.isNaN(intFindingTooth)) return false;
        return intFindingTooth === targetTooth;
      } else if (
        typeof findingTooth === "number" &&
        typeof targetTooth === "string"
      ) {
        const intTargetTooth = Number.parseInt(targetTooth, 10);
        if (Number.isNaN(intTargetTooth)) return false;
        return findingTooth === intTargetTooth;
      }

      return assertNever(
        `Unrecognized finding type in one of the following types: ${typeof findingTooth}, ${typeof targetTooth}`
      );
    });
  },
});
