import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { SubSink } from "subsink";
import { RecommendedProductFilter } from "../../shared/enums/recommended-product-filter.enum";
import { RecommendedProductTabs } from "../../shared/enums/recommended-product-tabs.enum";
import {
  RecommendedProduct,
  RecommendedProductsByCategory,
} from "../../shared/interfaces/recommended-product.interfaces";
import { recommendedProductsByCategory } from "../../shared/constants/recommended-products-by-category.constants";
import { Params } from "@angular/router";
import { LinkParams } from "../../shared/interfaces/link-params.interface";

@Component({
  selector: "kpr-product-recommendation-main",
  templateUrl: "./product-recommendation-main.component.html",
  styleUrls: ["./product-recommendation-main.component.scss"],
})
export class ProductRecommendationMainComponent implements OnInit, OnDestroy {
  @Output() public openReportClicked = new EventEmitter<LinkParams>();
  @Input() public isInreportRecommendation: boolean;
  filter: RecommendedProductFilter = RecommendedProductFilter.OTC;
  tab: RecommendedProductTabs = RecommendedProductTabs.Caries;

  recommendedProductsByCategory: RecommendedProductsByCategory = recommendedProductsByCategory;
  displayedRecommendedItems: RecommendedProduct[];

  @Input() tabs: RecommendedProductTabs[];
  @Input() patientId: string;
  @Input() currentSessionId: string;

  private _subs = new SubSink();

  ngOnInit(): void {
    this.displayedRecommendedItems = this.recommendedProductsByCategory?.[
      this.tab
    ]?.[this.filter];
  }

  tabChange(tab: RecommendedProductTabs) {
    this.tab = tab;
    this.displayedRecommendedItems = this.recommendedProductsByCategory?.[
      this.tab
    ]?.[this.filter];
  }

  filterChange(filter: RecommendedProductFilter) {
    this.filter = filter;
    this.displayedRecommendedItems = this.recommendedProductsByCategory?.[
      this.tab
    ]?.[this.filter];
  }

  openReportEmmit(route: string[], routeQuery: Params) {
    this.openReportClicked.emit({ route, routeQuery });
  }

  public ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
