// tslint:disable: no-input-rename
// tslint:disable: no-output-rename
import {
  Component,
  /* tslint:disable use-lifecycle-interface */
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  /* tslint:disable use-lifecycle-interface */
  AfterViewInit,
  HostBinding,
} from "@angular/core";
import { combineLatest, BehaviorSubject, Observable } from "rxjs";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import * as fromRootStore from "@app/store";
import * as fromStore from "@app/core/store";

import { Router, NavigationStart, ActivatedRoute } from "@angular/router";
import { fabric } from "fabric";
import {
  shareReplay,
  map,
  take,
  tap,
  distinctUntilChanged,
  withLatestFrom,
  filter,
} from "rxjs/operators";
import {
  KellsFabric,
  isBackgroundImage,
  FabricEvents,
} from "../../models/fabric.model";
import { FindingUpdatePayload } from "../../models/finding.model";
import {
  CariesLocationTypes,
  CariesStageTypes,
  FindingFilterSelectionTypes,
  FindingProcessingService,
  FindingStatus,
  FindingType,
  MaterialTypes,
} from "@kells/interfaces/finding";
import { SubSink } from "subsink";
import { observeProperty } from "@kells/utils/angular";
import {
  CanvasModes,
  ZoomEvent,
  CanvasElementCreator,
  FindingUpdateOutputs,
  FindingUpdateTypes,
  SizingPriority,
  FindingBrush,
} from "../../models/canvas.model";
import { CanvasLibFinding } from "../../models/finding.model";
import { isDefined, isNullable } from "@kells/utils/js";
import { ImageRenderSuccess } from "../../services/canvas-render.service";
import {
  CanvasRenderService,
  CanvasAdjustService,
  CanvasEditToolsService,
  CanvasResizeService,
  CanvasService,
  CanvasFindingService,
  CanvasElementService,
} from "../../services";
import BoneLossObject from "../finding-fabrics/bone-loss-object";
import ToothFindingFabric from "../finding-fabrics/tooth";
import CalculusFindingFabric from "../finding-fabrics/calculus";
import FractureFindingFabric from "../finding-fabrics/fracture";
import InfectionFindingFabric from "../finding-fabrics/infection";
import PlaqueFindingFabric from "../finding-fabrics/plaque";
import GumRecessionFindingFabric from "../finding-fabrics/gum-recession";
import GumInflammationFindingFabric from "../finding-fabrics/gum-inflammation";
import MissingToothFindingFabric from "../finding-fabrics/missing-tooth";
import DefectiveRestorationFindingFabric from "../finding-fabrics/defective-restoration";
import MaterialFindingFabric from "../finding-fabrics/material";
import CariesFindingFabric from "../finding-fabrics/caries";
import { DomPortal } from "@angular/cdk/portal";
import { CanvasContext } from "./canvas-context.interface";
import { select, Store } from "@ngrx/store";
import * as LayoutActions from "@app/core/store/layout/layout.actions";

@Component({
  selector: "kells-canvas",
  templateUrl: "./canvas.component.html",
  providers: [
    CanvasAdjustService,
    CanvasRenderService,
    CanvasResizeService,
    CanvasEditToolsService,
  ],
})
export class CanvasComponent implements OnInit, AfterViewInit, OnDestroy {
  static PIXELS_PER_MM: number = 24.4 / 95;

  @HostBinding("class.adjust-mode") isAdjustMode: boolean = false;

  @ViewChild("findingLabelPortalContent")
  findingLabelPortalContent: ElementRef<HTMLElement>;
  domPortal: DomPortal;

  @Input("imageId") imageId: string;
  private imageId$: Observable<string> = observeProperty(this, "imageId").pipe(
    distinctUntilChanged()
  );

  @Input("url") imageUrl: string;
  private imageUrl$: Observable<string> = observeProperty(
    this,
    "imageUrl"
  ).pipe(distinctUntilChanged());

  @Input("findingFilterKeys") findingFilterKeys:
    | FindingFilterSelectionTypes[]
    | null;
  private findingFilterKeys$: Observable<
    FindingFilterSelectionTypes[]
  > = this.store.pipe(select(fromStore.getFindingFilterTypes));

  // Observable<FindingFilterSelectionTypes[]> = observeProperty(
  //   this,
  //   "findingFilterKeys"
  // ).pipe(pipeLog('findingFilterKeys$'), keepDefined());

  @Input("findings") findings: CanvasLibFinding[] | null;
  private findings$: Observable<CanvasLibFinding[]> = observeProperty(
    this,
    "findings"
  ).pipe(keepDefined());

  /**
   * Sets the canvas's current modality.
   *
   * See documentation on `CanvasModes` for further detail.
   */
  @Input("mode") canvasMode: CanvasModes;
  public canvasMode$: Observable<CanvasModes> = observeProperty(
    this,
    "canvasMode"
  ).pipe(distinctUntilChanged());

  @Input("findingBrush") findingBrush: FindingBrush;

  public findingBrush$: Observable<FindingBrush> = observeProperty(
    this,
    "findingBrush"
  ).pipe(distinctUntilChanged());

  /**
   * Optionally specify whether the findings provided in input is rendered.
   *
   * @default false
   */
  @Input("showFindings") showFindings = true;
  private showFindings$: Observable<boolean> = observeProperty(
    this,
    "showFindings"
  ).pipe(distinctUntilChanged());

  @Input("isImageDetail") isImageDetail = false;

  @Input("showConfirmedFindings") showConfirmedFindings = false;
  private isConfirmedFindingsEnabled$: Observable<boolean> = observeProperty(
    this,
    "showConfirmedFindings"
  ).pipe(distinctUntilChanged());

  @Input("showPredictionFindings") showPredictionFindings = false;
  private isPredictionFindingsEnabled$: Observable<boolean> = observeProperty(
    this,
    "showPredictionFindings"
  ).pipe(distinctUntilChanged());

  @Input("isPreview") isPreview: boolean;
  isPredictionBoneLossEnabled$: Observable<boolean> = combineLatest([
    this.isPredictionFindingsEnabled$.pipe(keepDefined()),
    this.showFindings$.pipe(keepDefined()),
    this.findingFilterKeys$.pipe(keepDefined()),
  ]).pipe(
    map(([isPredictionEnabled, isAllEnabled, findingFilters]) => {
      console.info("isPredictionBoneLossEnabled", findingFilters);
      return (
        isPredictionEnabled &&
        isAllEnabled &&
        findingFilters.indexOf("bone_loss") !== -1
      );
    })
  );

  @HostBinding("class.imagedetail")
  get imgDetail() {
    return this.isImageDetail;
  }

  /**
   * Optionally specify whether the texts associated with each finding box
   * should be drawn on canvas.
   *
   * If `showFindings` is set to `false`, finding labels will not be rendered
   * regardless of the value specified in this input.
   *
   * @default false
   */
  private showFindingLabels = new BehaviorSubject<boolean>(false);
  showFindingLabels$: Observable<boolean> = this.store
    .pipe(
      select(fromStore.getLabelsEnabledStatus),

      shareReplay(1)
    )
    .pipe(
      distinctUntilChanged(),
      tap((val: boolean) => this.showFindingLabels.next(val))
    );

  @Input("confirmed") isImageConfirmed = false;
  private isImageConfirmed$: Observable<boolean> = observeProperty(
    this,
    "isImageConfirmed"
  ).pipe(distinctUntilChanged());

  @Input() contrast = 1;

  @Input() brightness = 1;

  /**
   * When the component's aspect ratio is not equal to that of the image,
   * this input configures whether the component should maximize the image
   * horizontally or vertically.
   *
   * See [[`SizingPriority`]] to see all available options.
   */
  @Input() sizingPriority: SizingPriority = SizingPriority.Height;

  /**
   * Optionally specify a width, in pixels, that the canvas cannot exceed.
   * @default infinity
   */
  @Input() maxWidth = Infinity;
  private maxWidth$: Observable<number> = observeProperty(this, "maxWidth");

  /**
   * Optionally specify a height, in pixels, that the canvas cannot exceed.
   * @default infinity
   */
  @Input() maxHeight = Infinity;
  private maxHeight$: Observable<number> = observeProperty(this, "maxHeight");

  /**
   * Optionally specify a cursor style for the canvas.
   *
   * This input accepts all valid css cursor values. For an exhaustive list,
   * see https://developer.mozilla.org/en-US/docs/Web/CSS/cursor.
   *
   * @default 'default'
   */
  @Input() defaultCursor = "default";
  private defaultCursor$: Observable<string> = observeProperty(
    this,
    "defaultCursor"
  );

  /**
   * Specifies whether this canvas instance is being used as a thumbnail.
   *
   * By default, the CanvasComponet assumes it is an enlarged image, i.e. the
   * value for this option is `false`.
   * If this canvas instance is intended to be a thumbnail, set this to `true`.
   *
   * @remark
   * By default, the CanvasComponent renders as if it is a large image on th
   * page. This allows the component to make certain assumptions, such as how
   * thick the box borders should be relative to the image size. These
   * assumptions, however, sometimes do not make sense when the canvas is a
   * thumbnail. In this case, canvas elements should be much larger relative
   * to the image size.
   *
   * Therefore, this input is used to determine the size of some of the
   * canvas's elements, to ensure they're legible both as a thumbnail and
   * as an enlarged image.
   *
   * @default `false`
   */
  @Input() isThumbnail = false;
  private isThumbnail$: Observable<boolean> = observeProperty(
    this,
    "isThumbnail"
  );

  @Output("modeChange") canvasModeChange = new EventEmitter<CanvasModes>();

  @Output("brushChange") brushChange = new EventEmitter<FindingBrush>();

  @Output() contrastChange = new EventEmitter<number>();

  @Output() brightnessChange = new EventEmitter<number>();

  @Output() findingUpdate = new EventEmitter<FindingUpdateOutputs>();

  /**
   * The canvas's immediate wrapper.
   *
   * This element defines the actual width and height of the canvas;
   * the actual canvas will be dynamically resized to match the dimensions
   * of this DOM element.
   */
  @ViewChild("canvas_bounding_container")
  canvasBoundingContainerRef: ElementRef<HTMLDivElement>;

  @ViewChild("editTools") editTools: ElementRef<HTMLDivElement>;

  @ViewChild("canvas") canvasRef: ElementRef<HTMLCanvasElement>;

  @ViewChild("label_box_tooltip")
  labelBoxTooltipRef: ElementRef<HTMLDivElement>;

  private _activeObject$ = new BehaviorSubject<KellsFabric.Object | undefined>(
    undefined
  );

  private drawingBox: KellsFabric.Rect | null = null;

  private canvasObject: KellsFabric.Canvas;

  private enableDraw$ = this.canvasMode$.pipe(
    map((mode) => mode === CanvasModes.Draw)
  );

  private enableAdjustCursor$ = this.canvasMode$
    .pipe(map((mode) => mode === CanvasModes.Adjust))
    .pipe(
      tap((val) => {
        this.isAdjustMode = val;
      })
    );

  readonly MAX_ZOOM_IN_LEVEL = 7;

  private isMouseDown = false;

  private hasInitModeChangeEffects = false;

  shouldShowActiveFindingTooltip$ = this.editToolsService.isEditTooltipShown$;

  isLabelTextTooltipShown$ = new BehaviorSubject(false);

  private initialZoomLevel$ = this.canvasResizeService.initialZoomLevel$;

  /** Is the user currently dragging the canvas */
  private isDragging = false;

  private draggedMouseLatestPosition: {
    x: number | undefined;
    y: number | undefined;
  } = {
    x: undefined,
    y: undefined,
  };

  /** Zoom events initiated by the user scrolling the mousewheel on canvas. */
  private scrollZoomEvent$ = new BehaviorSubject<ZoomEvent | undefined>(
    undefined
  );

  /**
   * A collective stream of non-null zoom events initiated by the user.
   */
  private zoomEvent$: Observable<ZoomEvent> = this.scrollZoomEvent$.pipe(
    keepDefined(),
    distinctUntilChanged(CanvasComponent._areZoomEventsEqual),
    shareReplay(1)
  );

  private isImageLoaded$ = new BehaviorSubject<boolean>(false);

  /**
   * The finding that's being currently selected or hovered over.
   * `undefined` is returned if there is no finding that's currently active.
   */
  activeFinding$ = this._activeObject$.pipe(
    map((activeObject) => {
      if (!activeObject || !this.findings) {
        return;
      }
      return activeObject.finding;
    })
  );

  activeFindingType$ = this.activeFinding$.pipe(
    keepDefined(),
    map((obj) => obj.type)
  );

  private readonly shouldEditForm$ = combineLatest([
    this.editToolsService.isEditFormShown$,
    this.activeFindingType$,
  ]).pipe(keepDefined());

  shouldShowCariesEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.Caries;
    })
  );

  shouldShowToothEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.Tooth;
    })
  );

  shouldShowMaterialEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.Material;
    })
  );

  shouldShowBonelossEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.BoneLoss;
    })
  );

  shouldShowFractureEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.Fracture;
    })
  );

  shouldShowCalculusEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.Calculus;
    })
  );

  shouldShowInfectionEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.Infection;
    })
  );

  shouldShowDefectiveRestorationEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.DefectiveRestoration;
    })
  );

  shouldShowPlaqueEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.Plaque;
    })
  );

  shouldShowGumRecessionEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.GumRecession;
    })
  );

  shouldShowGumInflammationEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.GumInflammation;
    })
  );

  shouldShowMissingToothEditForm$ = this.shouldEditForm$.pipe(
    map(([isShown, type]) => {
      return isShown && type === FindingType.MissingTooth;
    })
  );

  /**
   * Resolution, in px, of the source image.
   *
   * This is not the canvas's dimension. For the canvas's dimensions, use the
   * `getHeight()` and `getWidth()` methods on `this.canvas` instead.
   *
   * Canvas's aspect ratio will be the same as that of the source image.
   * But its actual dimension rendered on screen, in px, is determined by
   * window sizes and screen resolutions. Therefore, it is almost always
   * different from the resolution of the underlying image.
   */
  private sourceImageResolution$ = new BehaviorSubject<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  private toggleDrawMode$ = combineLatest([
    this.enableDraw$.pipe(distinctUntilChanged()),
    this.findingBrush$.pipe(distinctUntilChanged()),
  ]);

  private updateCanvasDefaultCursor$ = this.defaultCursor$.pipe(
    distinctUntilChanged(),
    tap((defaultCursorSetting) => {
      if (!this.canvasObject) return;
      this.canvasObject.defaultCursor = defaultCursorSetting;
    })
  );

  isInAdjustMode$ = this.canvasMode$.pipe(
    map((mode) => mode === CanvasModes.Adjust)
  );

  get context(): CanvasContext {
    return {
      showFindings$: this.showFindings$,
      isConfirmedFindingsEnabled$: this.isConfirmedFindingsEnabled$,
      isPredictionFindingsEnabled$: this.isPredictionFindingsEnabled$,
      isPredictionBoneLossEnabled$: this.isPredictionBoneLossEnabled$,
      showFindingLabels$: this.showFindingLabels$,
      findingFilterKeys$: this.findingFilterKeys$,
      brightnessChange: this.brightnessChange,
      contrastChange: this.contrastChange,
      findingUpdate: this.findingUpdate,
      editToolsService: this.editToolsService,
      isImageConfirmed$: this.isImageConfirmed$,
      canvasMode$: this.canvasMode$,
      canvas: this.canvasObject,
      zoomEvent$: this.zoomEvent$,

      materialFabricControllers: this._fabricFindingsMaterial,
      cariesFabricControllers: this._fabricFindingsCaries,
      toothFabricControllers: this._fabricFindingsTooth,
      boneLossFabricControllers: this._fabricFindingsBoneLoss,
      calculusFabricControllers: this._fabricFindingsCalculus,
      fractureFabricControllers: this._fabricFindingsFracture,
      infectionFabricControllers: this._fabricFindingsInfection,
      defectiveRestorationFabricControllers: this
        ._fabricFindingsDefectiveRestoration,
      plaqueFabricControllers: this._fabricFindingsPlaque,
      gumRecessionFabricControllers: this._fabricFindingsGumRecession,
      gumInflammationFabricControllers: this._fabricFindingsGumInflammation,
      missingToothFabricControllers: this._fabricFindingsMissingTooth,

      filmScaleFactorWidth$: this.filmScaleFactorWidth$,
      filmScaleFactorHeight$: this.filmScaleFactorHeight$,
      navStart$: this.navStart$,
      activatedRoute$: this.activatedRoute$,
      sourceImageResolution$: this.sourceImageResolution$,

      canvasOffsetLeft: CanvasService.getCanvasLeftOffset(this.canvasObject),
      canvasOffsetTop: CanvasService.getCanvasTopOffset(this.canvasObject),
    } as CanvasContext;
  }

  navStart$ = this.router.events.pipe(
    tap((event) => {
      console.info("router event", event);
    }),
    filter((evt) => evt instanceof NavigationStart)
  ) as Observable<NavigationStart>;

  activatedRoute$: BehaviorSubject<ActivatedRoute>;

  private _subs = new SubSink();

  constructor(
    private canvasAdjustService: CanvasAdjustService,
    private canvasResizeService: CanvasResizeService,
    private editToolsService: CanvasEditToolsService,
    private appStore: Store<fromStore.AppState>,
    private store: Store<fromRootStore.RootState>,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    // this.drawMouseMoveHandler = this.drawMouseMoveHandler.bind(this);
    this.drawMouseUpHandler = this.drawMouseUpHandler.bind(this);
    this.drawMouseDownHandler = this.drawMouseDownHandler.bind(this);
    this.canvasBoxModified = this.canvasBoxModified.bind(this);

    this.readonlyMousewheelHandler = this.readonlyMousewheelHandler.bind(this);
    this.readonlyMouseUpHandler = this.readonlyMouseUpHandler.bind(this);
    this.readonlyMouseDownHandler = this.readonlyMouseDownHandler.bind(this);
    this.readonlyMouseMoveHandler = this.readonlyMouseMoveHandler.bind(this);

    this.onFindingSelected = this.onFindingSelected.bind(this);
    this.onFindingSelectedUpdated = this.onFindingSelectedUpdated.bind(this);
    this.onFindingDeselected = this.onFindingDeselected.bind(this);
    this.onFindingMoved = this.onFindingMoved.bind(this);
    this.onFindingCreated = this.onFindingCreated.bind(this);

    this.canvasResizeService.initialZoomLevel$.subscribe(
      (__initialZoomLevel) => {
        this._initialZoomLevel = __initialZoomLevel;
      }
    );

    this.activatedRoute$ = new BehaviorSubject<ActivatedRoute>(activeRoute);

    this._subs.sink = this.navStart$.subscribe((navStart) => {
      this.canvasObject.off();
    });

    this._subs.sink = this.navStart$.subscribe((navStart) => {
      this._fabricFindingsMaterial.clear();
      this._fabricFindingsBoneLoss.clear();
      this._fabricFindingsCaries.clear();
      this._fabricFindingsTooth.clear();
    });

    this._subs.sink = this.showFindingLabels$.subscribe();
    // this.readonlyMouseOverHandler = this.readonlyMouseOverHandler.bind(this);
    // this.readonlyMouseOutHandler = this.readonlyMouseOutHandler.bind(this);
  }

  ngOnInit(): void {
    this._validateComponentInputs();

    this.initFindingRenderingEffects();
    if (!this.isPreview) {
      this.initCanvasZoomEffects();
    }
    this.initCanvasAdjustEffects();

    this.watchImageLoadingStatus();

    this._subs.sink = this.toggleDrawMode$
      .pipe(take(1))
      .subscribe(([enableDraw, brushType]) => {
        if (enableDraw) {
          this.canvasModeChange.next(CanvasModes.Draw);
          this.brushChange.next(brushType);
        } else {
          this._exitDrawingMode();
          this.brushChange.next(FindingBrush.None);
        }
      });
    this._subs.sink = this.findingFilterKeys$.subscribe();
    // this._subs.sink = this.toggleLabelTexts$.subscribe();
    // this._subs.sink = this.toggleInteractivityOnLabelBoxes$.subscribe();
    this._subs.sink = this.updateCanvasDefaultCursor$.subscribe();
  }

  private initCanvasAdjustEffects() {
    this._subs.sink = this.canvasAdjustService.brightness$.subscribe(
      (brightness) => {
        this.brightnessChange.emit(brightness);
      }
    );

    this._subs.sink = this.canvasAdjustService.contrast$.subscribe(
      (contrast) => {
        this.contrastChange.emit(contrast);
      }
    );
  }

  ngAfterViewInit() {
    this.initCanvasRenderEffects();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    for (let eventName in FabricEvents) {
      this.canvasObject.off(eventName);
    }
  }

  /**
   * Custom lifecycle method invoked whenever canvas finishes rendering an image.
   */
  onCanvasReady() {
    const { canvasObject: canvas } = this;
    if (!this.hasInitModeChangeEffects) {
      this.initCanvasModeChangeEffectsOnce();
    }
    // enables interaction with canvas objects
    this.canvasModeChange.next(CanvasModes.ReadOnly);

    this.initCanvasResizeOnCanvasReady();
    if (this.isImageDetail) {
      this.initEditToolsOnCanvasReady();

      if (!isNullable(canvas.backgroundImage)) {
        if (
          !isNullable(canvas.backgroundImage) &&
          isBackgroundImage(canvas.backgroundImage)
        ) {
          this._background = this.canvasObject.backgroundImage as fabric.Image;
          const { _background: background } = this;
          background.set({
            selectable: true,
            hasControls: false,
            evented: true,
          });
        }
      }
      this.enableReadOnlyInteractions();
    }
  }

  enableReadOnlyInteractions() {
    const { canvasObject: canvas } = this;
    canvas.on("mouse:wheel", this.readonlyMousewheelHandler);
    canvas.on("mouse:down", this.readonlyMouseDownHandler);
    canvas.on("object:moved", this.onFindingMoved);
    canvas.on("selection:created", this.onFindingSelected);
    canvas.on("selection:updated", this.onFindingSelectedUpdated);
    canvas.on("selection:cleared", this.onFindingDeselected);
  }

  disableReadOnlyInteractions() {
    const { canvasObject: canvas } = this;
    canvas.off("mouse:wheel", this.readonlyMousewheelHandler);
    canvas.off("mouse:down", this.readonlyMouseDownHandler);
    canvas.off("selection:created", this.onFindingSelected);
    canvas.off("selection:updated", this.onFindingSelectedUpdated);
    canvas.off("selection:cleared", this.onFindingDeselected);
    canvas.off("object:moved", this.onFindingMoved);
  }

  /**
   * Fired in response to canvas selection:created event
   * @param event
   */
  onFindingSelected(event: any) {
    const target = event.selected[0];
    this.activeObject = target;
    if (target.type !== "Image" && target.ctrl.type !== FindingType.BoneLoss) {
      this.editToolsService.showEditFormFor(target);
    }
  }

  onFindingMoved(event: any) {
    const target = event.target;
    this.editToolsService.showEditFormFor(target);
  }

  onFindingSelectedUpdated(event: any) {
    const target = event?.selected[0];
    const untarget = event?.deselected[0];

    if (!isNullable(untarget)) {
      this.editToolsService.hideEditTools();
    }
    if (!isNullable(target) && this.canvasMode !== CanvasModes.Draw) {
      this.activeObject = target;
      this.editToolsService.showEditFormFor(target);
    }
  }

  onFindingDeselected(event: any) {
    this.editToolsService.hideEditTools();
  }

  private initCanvasRenderEffects() {
    this._subs.sink = this.imageUrl$
      .pipe(withLatestFrom(this.initialZoomLevel$))
      .subscribe(([imageUrl, initialZoomLevel]) => {
        this.rerenderImageBackground(imageUrl, { initialZoomLevel });
      });
  }

  private rerenderImageBackground(
    imageUrl: string,
    args: {
      initialZoomLevel: number;
    }
  ) {
    const { initialZoomLevel } = args;

    if (!this.canvasObject) {
      this.canvasObject = CanvasRenderService.instantiateCanvas({
        canvasRef: this.canvasRef,
      });
      this.canvasObject.hoverCursor = "pointer";
      this.initDebugObserver();
    }

    // ensure all editing tools are hidden before (re)rendering the images.
    this.editToolsService.hideEditTools();

    // Remove the previous background image, if it exists, before altering
    // the image dimensions and rendering the next image.
    this.canvasObject.backgroundImage = undefined;

    this.resetCanvasZoomLevel(initialZoomLevel);

    CanvasRenderService.renderImageAsBackground(this.canvasObject, imageUrl)
      .then(this.onImageRendered.bind(this))
      .catch((err) => console.error(err));
  }
  observe = (eventName: string) => {
    this.canvasObject.on(eventName, (opt) => {
      console.info("CANVAS " + eventName, opt);
    });
  };

  observeObj = (eventName: string) => {
    this.canvasObject.getObjects().forEach(function (o) {
      o.on(eventName, (opt: any) => {
        console.info("OBJECT " + eventName, opt);
      });
    });
  };

  initDebugObserver() {
    const { observe, observeObj } = this;
    observe("object:modified");

    observe("object:moving");
    observe("object:scaling");
    observe("object:rotating");
    observe("object:skewing");
    observe("object:moved");
    observe("object:scaled");
    observe("object:rotated");
    observe("object:skewed");

    observe("before:transform");
    observe("before:selection:cleared");
    observe("selection:cleared");
    observe("selection:created");
    observe("selection:updated");

    observe("mouse:up");
    observe("mouse:down");
    observe("mouse:move");
    observe("mouse:up:before");
    observe("mouse:down:before");
    //observe('mouse:move:before');
    observe("mouse:dblclick");
    observe("mouse:wheel");
    observe("mouse:over");
    observe("mouse:out");

    observe("drop");
    observe("dragover");
    observe("dragenter");
    observe("dragleave");

    // observe('after:render');

    // observeObj('moving');
    // observeObj('scaling');
    // observeObj('rotating');
    // observeObj('skewing');
    // observeObj('moved');
    // observeObj('scaled');
    // observeObj('rotated');
    // observeObj('skewed');

    // observeObj('mouseup');
    // observeObj('mousedown');
    // observeObj('mousemove');
    // observeObj('mouseup:before');
    // observeObj('mousedown:before');
    // observeObj('mousemove:before');
    // observeObj('mousedblclick');
    // observeObj('mousewheel');
    // observeObj('mouseover');
    // observeObj('mouseout');

    // observeObj('drop');
    // observeObj('dragover');
    // observeObj('dragenter');
    // observeObj('dragleave');
  }

  private onImageRendered(res: ImageRenderSuccess) {
    const { sourceImageResolution } = res;

    this.sourceImageResolution$.next(sourceImageResolution);

    // notify all parties in this component that the image is loaded.
    this.isImageLoaded$.next(true);
    // trigger side-effects that are only safe to run when the image is rendered.
    this.onCanvasReady();
  }

  private resetCanvasZoomLevel(initialZoomLevel: number) {
    if (!this.canvasObject) return;

    if (isDefined(initialZoomLevel) && initialZoomLevel !== 0) {
      this.canvasObject.setZoom(initialZoomLevel);
      this.canvasResizeService.limitImageToCanvasBoundaries(
        this.canvasObject,
        initialZoomLevel
      );
    }
  }

  private initEditToolsOnCanvasReady() {
    if (!this.canvasObject) {
      throw new Error(
        "cannot init canvas resize effects before canvas is ready."
      );
    }

    this.editToolsService.init({
      canvasRef: this.canvasObject,
      editToolContainerRef: this.editTools,
    });
  }

  private initCanvasResizeOnCanvasReady() {
    if (!this.canvasObject) {
      throw new Error(
        "cannot init canvas resize effects before canvas is ready."
      );
    }

    this.canvasResizeService.init({
      canvasRef: this.canvasObject,
      sizingPriority: this.sizingPriority as any,
      imageResolution: this.sourceImageResolution$.value,
      boundingContainerRef: this.canvasBoundingContainerRef,
    });

    this._subs.sink = this.maxWidth$.subscribe((maxWidth) => {
      this.canvasResizeService.setMaxWidth(maxWidth);
    });

    this._subs.sink = this.maxHeight$.subscribe((maxHeight) => {
      this.canvasResizeService.setMaxHeight(maxHeight);
    });

    this.canvasResizeService.triggerResize();
  }

  savedFindings: CanvasLibFinding[] | null = null; // To get the details for the saved Findings
  private initFindingRenderingEffects() {
    this._subs.sink = combineLatest([
      this.showFindings$,
      this.isImageLoaded$,
      this.findings$,
    ]).subscribe(
      ([showFindings, isImageLoaded, findings]) => {
        if (!this.canvasObject) {
          return;
        }

        this.activeObject = undefined;
        if (isImageLoaded && showFindings) {
          if (this.showFindingLabels.value === true) {
            this.store.dispatch(LayoutActions.imageDetailHideLabels());
            setTimeout(() => {
              this.store.dispatch(LayoutActions.imageDetailShowLabels());
            }, 500);
          }

          if (!this.savedFindings) {
            this.savedFindings = JSON.parse(JSON.stringify(this.findings));
          }
          if (!this._findingUpdate) {
            setTimeout(() => {
              this.renderElementsOnCanvas(this.findings);
            });
          } else {
            //   let finding;
            //   if (this._findingUpdate.id === 'NEW') {

            //   switch(this._findingUpdate.type) {
            //     case FindingType.Tooth:
            //       finding = findings.find((f) => !this._fabricFindingsTooth.has(f.id as string));
            //       break;
            //     case FindingType.BoneLoss:
            //       finding = findings.find((f) => !this._fabricFindingsBoneLoss.has(f.id as string));
            //       break;
            //     case FindingType.Material:
            //       finding = findings.find((f) => !this._fabricFindingsMaterial.has(f.id as string));
            //       break;
            //     case FindingType.Caries:
            //       finding = findings.find((f) => !this._fabricFindingsCaries.has(f.id as string));
            //       break;
            //   }
            //   if(isDefined(this._ghostFinding)) {
            //     this._ghostFinding.ctrl.dispose();
            //     this._ghostFinding = null;
            //   }

            // } else {
            let finding = findings.find((f) => f.id == this._findingUpdate!.id);

            if (finding) {
              let matOrdinality = 0;
              let cariesOrdinality = 0;

              const { context, canvasResizeService } = this;
              switch (finding.type) {
                case FindingType.Tooth:
                  if (!this._fabricFindingsTooth.has(finding.id as string)) {
                    const toothFabric = new ToothFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsTooth.set(
                      finding.id as string,
                      toothFabric
                    );
                  } else {
                    this._fabricFindingsTooth
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.BoneLoss:
                  if (!this._fabricFindingsBoneLoss.has(finding.id as string)) {
                    const boneLossFabric = new BoneLossObject(
                      finding,
                      context,
                      canvasResizeService
                    );
                    this._fabricFindingsBoneLoss.set(
                      finding.id as string,
                      boneLossFabric
                    );
                  } else {
                    this._fabricFindingsBoneLoss
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;
                case FindingType.Material:
                  if (!this._fabricFindingsMaterial.has(finding.id as string)) {
                    const materialFabric = new MaterialFindingFabric(
                      finding,
                      context,
                      ++matOrdinality
                    );
                    materialFabric.ordinality = ++matOrdinality;

                    this._fabricFindingsMaterial.set(
                      finding.id as string,
                      materialFabric
                    );
                  } else {
                    this._fabricFindingsMaterial
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.Caries:
                  if (!this._fabricFindingsCaries.has(finding.id as string)) {
                    context.isPreview = this.isPreview;
                    const cariesFabric = new CariesFindingFabric(
                      finding,
                      context,
                      ++cariesOrdinality
                    );

                    this._fabricFindingsCaries.set(
                      finding.id as string,
                      cariesFabric
                    );
                  } else {
                    this._fabricFindingsCaries
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.Calculus:
                  if (!this._fabricFindingsCalculus.has(finding.id as string)) {
                    const calculusFabric = new CalculusFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsCalculus.set(
                      finding.id as string,
                      calculusFabric
                    );
                  } else {
                    this._fabricFindingsCalculus
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.Fracture:
                  if (!this._fabricFindingsFracture.has(finding.id as string)) {
                    const fractureFabric = new FractureFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsFracture.set(
                      finding.id as string,
                      fractureFabric
                    );
                  } else {
                    this._fabricFindingsFracture
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.Infection:
                  if (
                    !this._fabricFindingsInfection.has(finding.id as string)
                  ) {
                    const infectionFabric = new InfectionFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsInfection.set(
                      finding.id as string,
                      infectionFabric
                    );
                  } else {
                    this._fabricFindingsInfection
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.DefectiveRestoration:
                  if (
                    !this._fabricFindingsDefectiveRestoration.has(
                      finding.id as string
                    )
                  ) {
                    const defectiveRestorationFabric = new DefectiveRestorationFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsDefectiveRestoration.set(
                      finding.id as string,
                      defectiveRestorationFabric
                    );
                  } else {
                    this._fabricFindingsDefectiveRestoration
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.Plaque:
                  if (!this._fabricFindingsPlaque.has(finding.id as string)) {
                    const plaqueFabric = new PlaqueFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsPlaque.set(
                      finding.id as string,
                      plaqueFabric
                    );
                  } else {
                    this._fabricFindingsPlaque
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.GumRecession:
                  if (
                    !this._fabricFindingsGumRecession.has(finding.id as string)
                  ) {
                    const gumRecessionFabric = new GumRecessionFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsGumRecession.set(
                      finding.id as string,
                      gumRecessionFabric
                    );
                  } else {
                    this._fabricFindingsGumRecession
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.GumInflammation:
                  if (
                    !this._fabricFindingsGumInflammation.has(
                      finding.id as string
                    )
                  ) {
                    const gumInflammationFabric = new GumInflammationFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsGumInflammation.set(
                      finding.id as string,
                      gumInflammationFabric
                    );
                  } else {
                    this._fabricFindingsGumInflammation
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;

                case FindingType.MissingTooth:
                  if (
                    !this._fabricFindingsMissingTooth.has(finding.id as string)
                  ) {
                    const missingToothFabric = new MissingToothFindingFabric(
                      finding,
                      context
                    );

                    this._fabricFindingsMissingTooth.set(
                      finding.id as string,
                      missingToothFabric
                    );
                  } else {
                    this._fabricFindingsMissingTooth
                      .get(finding.id as string)
                      ?.updateFinding(finding, true);
                  }
                  break;
              }
              this.canvasObject.requestRenderAll();
            }
            delete this._findingUpdate;
          }
        }

        // this.rerenderFindings();
      }
      //  else {
      //   this._removeCanvasElements();
      // }
    );
  }

  /**
   * On image URL updates, invalidate image load success status to prevent
   * any further modifications on the canvas.
   */
  private watchImageLoadingStatus() {
    this._subs.sink = this.imageUrl$.pipe(keepDefined()).subscribe(() => {
      this.isImageLoaded$.next(false);
    });
  }

  private initCanvasModeChangeEffectsOnce() {
    this._subs.sink = this.canvasMode$.subscribe((mode) => {
      if (!this.canvasObject) {
        return;
      }

      switch (mode) {
        case CanvasModes.ReadOnly:
          this.canvasAdjustService.disable();
          this.removeAllCanvasListeners();
          this.disableDrawInteractions();
          this.enableReadOnlyInteractions();
          this._allFindingsSelectable = true;
          this.canvasObject.hoverCursor = "pointer";
          this.canvasObject.defaultCursor = this.defaultCursor;
          this.editToolsService.hideEditTools();
          this.cancelEdit();
          return;
        case CanvasModes.Draw:
          this.canvasAdjustService.disable();
          this.removeAllCanvasListeners();
          //this.disableReadOnlyInteractions();
          this.enableDrawInteractions();
          this.resetActiveObject();
          this._allFindingsSelectable = false;
          this.canvasObject.defaultCursor = "crosshair";
          return;
        case CanvasModes.Adjust:
          this.removeAllCanvasListeners();
          this.canvasAdjustService.enable(this.canvasObject, {
            contrastBaseline: this.contrast,
            brightnessBaseline: this.brightness,
          });
          return;
        case CanvasModes.Uninitialized:
        default:
          this.canvasAdjustService.disable();
          this.removeAllCanvasListeners();
          this.disableDrawInteractions();
          this.enableReadOnlyInteractions();
          this._allFindingsSelectable = true;
          this.canvasObject.hoverCursor = "pointer";
          this.canvasObject.defaultCursor = this.defaultCursor;
      }
    });

    this.hasInitModeChangeEffects = true;
  }

  /** Removes all canvas interaction (mouse move, click, etc.) handlers. */
  private removeAllCanvasListeners() {
    this.disableReadOnlyInteractions();
    if (!this.canvasObject) return;

    this.canvasObject.off("mouse:down");
    this.canvasObject.off("mouse:move");
    this.canvasObject.off("mouse:up");
    this.canvasObject.off("object:moving");
    this.canvasObject.off("object:modified");
    this.canvasObject.off("mouse:wheel");
    this.canvasObject.off("mouse:over");
    this.canvasObject.off("mouse:out");
  }
  private _background: fabric.Image;
  /** Sets up event handlers for canvas mouse interactions in readonly mode. */
  // private enableReadonlyInteractions() {
  //   if (!this.canvasObject) return;

  //   //this.canvasObject.on("mouse:up", this.readonlyMouseUpHandler);
  //   // this.canvasObject.on("mouse:down", this.readonlyMouseDownHandler);
  //   //this.canvasObject.on("mouse:move", this.readonlyMouseMoveHandler);
  //   //this.canvasObject.on("mouse:over", this.readonlyMouseOverHandler);
  //   //this.canvasObject.on("mouse:out", this.readonlyMouseOutHandler);
  // }

  // zoom with mousewheel when canvas is not expanded
  // pan with mousewheel when canvas is expanded
  private readonlyMousewheelHandler(option: any) {
    //this.initialZoomLevel$.pipe(takeLatest()).subscribe((initialZoomLevel) => {
    const { _initialZoomLevel: initialZoomLevel } = this;

    const delta = option.e.deltaY;
    let zoom = this.canvasObject.getZoom() * 0.999 ** delta;
    const maxZoomLevel = this.MAX_ZOOM_IN_LEVEL + initialZoomLevel;
    const minZoomLevel = initialZoomLevel * 0.5;

    if (zoom > maxZoomLevel) zoom = maxZoomLevel;
    if (zoom < minZoomLevel) zoom = minZoomLevel;

    const centerPoint = {
      x: this.canvasObject.getWidth() / 2,
      y: this.canvasObject.getHeight() / 2,
    };
    const mouseFocalPoint = { x: option.e.offsetX, y: option.e.offsetY };

    const nextFocalPoint =
      zoom < initialZoomLevel * 1.1 ? centerPoint : mouseFocalPoint;

    this.scrollZoomEvent$.next({
      focalPoint: nextFocalPoint,
      zoom: Math.round((zoom + Number.EPSILON) * 100) / 100,
    });

    // if (this.activeObject) {
    //   this.editToolsService.showEditTooltipFor(this.activeObject);
    // }

    option.e.preventDefault();
    option.e.stopPropagation();
    //});
  }
  _initialZoomLevel: number = 0;
  maxZoomLevel = this.MAX_ZOOM_IN_LEVEL + this._initialZoomLevel;

  // zoomIn(point:fabric.Point) {
  //   const { _initialZoomLevel: initialZoomLevel, canvasObject, MAX_ZOOM_IN_LEVEL } = this;
  //   let zoom = this.canvasObject.getZoom() * 0.999 ** delta;
  //   const maxZoomLevel = this.MAX_ZOOM_IN_LEVEL + initialZoomLevel;
  //   const minZoomLevel = initialZoomLevel * 0.5;

  //   if (zoom > maxZoomLevel) zoom = maxZoomLevel;
  //   if (zoom < minZoomLevel) zoom = minZoomLevel;
  //   const { canvasObject: canvas } = this;
  //   if (zoomLevel < zoomLevelMax) {
  //     zoomLevel++;
  //     canvas.zoomToPoint(point, Math.pow(2, zoomLevel));
  //     keepPositionInBounds(canvas);
  //   }
  // }

  // zoomOut(point: fabric.Point) {
  //   const { canvasObject: canvas } = this;
  //   if (zoomLevel > zoomLevelMin) {
  //     zoomLevel--;
  //     canvas.zoomToPoint(point, Math.pow(2, zoomLevel));
  //     keepPositionInBounds(canvas);
  //   }
  // }

  keepPositionInBounds() {
    const { canvasObject: canvas, clamp } = this;
    var zoom = canvas.getZoom();
    var xMin = ((2 - zoom) * canvas.getWidth()) / 2;
    var xMax = (zoom * canvas.getWidth()) / 2;
    var yMin = ((2 - zoom) * canvas.getHeight()) / 2;
    var yMax = (zoom * canvas.getHeight()) / 2;

    var point = new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2);
    var center = fabric.util.transformPoint(
      point,
      canvas.viewportTransform as number[]
    );

    var clampedCenterX = clamp(center.x, xMin, xMax);
    var clampedCenterY = clamp(center.y, yMin, yMax);

    var diffX = clampedCenterX - center.x;
    var diffY = clampedCenterY - center.y;

    if (diffX != 0 || diffY != 0) {
      canvas.relativePan(new fabric.Point(diffX, diffY));
    }
  }

  clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(value, max));
  }

  private _activeFindingCtrl: any;
  private readonlyMouseUpHandler(option: any) {
    const { canvasObject: canvas } = this;
    this.isDragging = false;
    canvas.off(FabricEvents.MOUSE_MOVE_BEFORE, this.readonlyMouseMoveHandler);
    canvas.off(FabricEvents.MOUSE_OUT, this.readonlyMouseUpHandler);
    canvas.off(FabricEvents.MOUSE_UP_BEFORE, this.readonlyMouseUpHandler);
    // if (this.isImageConfirmed) return;
    // const { target: objectClicked } = option;
    // if (!isNullable(objectClicked) && !isNullable(objectClicked.ctrl)) {
    //   let fabFinding = objectClicked.ctrl;//this._getFabricObjectController(objectClicked);
    //   //this.activeObject = fabFinding.activeObject;
    //   this._activeFindingCtrl = fabFinding;
    //   this.editToolsService.showEditFormFor(this.activeObject);
    //   // this.canvasObject.renderAll();
    //   // if (!this.editToolsService.isEditFormShown ) {
    //   //   this.editToolsService.showEditFormFor(objectClicked);
    //   // }

    // } else {
    //   this.canvasObject.discardActiveObject();
    // }

    // if (objectClicked && objectClicked.enableInteraction) {
    //   this._onActiveObjectChange(objectClicked);
    // }

    // if (
    //   !this.isImageConfirmed &&
    //   objectClicked &&
    //   !this.editToolsService.isEditFormShown &&
    //   objectClicked.enableInteraction
    // ) {
    //   // ensure the selected box has controls
    //   this.canvasObject.setActiveObject(objectClicked);
    //   objectClicked.selectable = true;
    //   objectClicked.hasControls = true;

    //   objectClicked.setControlsVisibility({
    //     mb: false,
    //     ml: false,
    //     mr: false,
    //     mt: false,
    //   });

    //   const strokeWidth = 2 / this.canvasObject.getZoom();
    //   for (let object of this.canvasObject.getObjects()) {
    //     object.set("strokeWidth", strokeWidth);
    //   }
    //   this.canvasObject.renderAll();

    //   this.editToolsService.showEditFormFor(objectClicked);

    //this.editToolsService.showEditFormFor(objectClicked);
    // }
  }

  /**
   * Only serves to enable panning
   * @param option
   */
  private readonlyMouseDownHandler(option: any) {
    const { canvasObject: canvas, _background } = this;
    const { target, e: event, pointer, absolutePointer } = option;
    console.info("CANVAS:BACKGROUND mousedown target", option);
    // const { target: objectHovered } = option;
    // console.info('mousedown target', objectHovered);
    // if (isNullable(objectHovered)) {
    //   return;
    // }
    // this.initialZoomLevel$.pipe(takeLatest()).subscribe((initialZoomLevel) => {
    const { _initialZoomLevel: initialZoomLevel } = this;
    // if (this.canvasObject.getZoom() > initialZoomLevel) return;
    if (
      !isNullable(_background) &&
      isBackgroundImage(_background) &&
      !isNullable(_background.lineCoords) &&
      !isNullable(_background.width) &&
      !isNullable(_background.height)
    ) {
      if (
        Math.abs(_background.lineCoords.bl.x - _background.lineCoords.br.x) <=
          canvas.width! &&
        Math.abs(_background.lineCoords.bl.y - _background.lineCoords.tr.y) <=
          canvas.height!
      ) {
        return;
      }

      // _background.on("moving", this.readonlyMouseMoveHandler);
      const { e: event } = option.e;

      this.isDragging = true;
      this.draggedMouseLatestPosition = {
        ...pointer,
      };
      canvas.on(FabricEvents.MOUSE_MOVE_BEFORE, this.readonlyMouseMoveHandler);
      canvas.on(FabricEvents.MOUSE_UP, this.readonlyMouseUpHandler);
      canvas.on(FabricEvents.MOUSE_OUT, this.readonlyMouseUpHandler);
    }

    // });

    // let fabFinding = objectHovered.ctrl;//this._getFabricObjectController(objectHovered);
    // if (fabFinding === null || !this.isImageLoaded$.value) return;
    // this._activeFindingCtrl = fabFinding;
    // console.info('canvas mousedown ', fabFinding);
    //this._activeFindingCtrl.mousedown(objectHovered);
    // this.initialZoomLevel$.pipe(takeLatest()).subscribe((initialZoomLevel) => {
    //   if(this.canvasObject.getZoom() > initialZoomLevel) return;

    //   const { e:event } = option.e;

    //   if (
    //     objectHovered === null &&
    //     this.isImageLoaded$.value &&
    //     this.isImageDetail //this.canvasObject.getZoom() > initialZoomLevel
    //   ) {
    //     this.isDragging = true;
    //     this.draggedMouseLatestPosition = {
    //       x: event.clientX,
    //       y: event.clientY,
    //     };

    //   }
    // });
  }

  private readonlyMouseMoveHandler(option: any) {
    /*
const takeMouseSwipe = pipe(
  // take mouse moves
  switchMap(_ => fromEvent(document, 'mousemove')),
  // once mouse is up, we end swipe
  takeUntil(fromEvent(document, 'mouseup')),
  throttleTime(50)
);
*/

    // const setLatestLabelBoxTooltipLocation = () => {
    //   const pixelify = (num: any) => `${num}px`;

    //   // shift left slightly to account for the width of the cursor.
    //   const LEFT_OFFSET = -5;
    //   // shift down to add padding between the mouse and the tooltip.
    //   const TOP_OFFSET = 20;

    //   this.labelBoxTooltipRef = CanvasService.updateNativeElementStyle(
    //     this.labelBoxTooltipRef,
    //     {
    //       left: pixelify(option.e.x + LEFT_OFFSET),
    //       top: pixelify(option.e.y + TOP_OFFSET),
    //     }
    //   );
    // };

    // const shouldNotShowMouseTooltip =
    //   !objectHovered ||
    //   !objectHovered.ctrl ||
    //   this.isImageConfirmed ||
    //   this.editToolsService.isEditFormShown;

    // if (shouldNotShowMouseTooltip) {
    //   this.isLabelTextTooltipShown$.next(false);
    // } else if (objectHovered.enableInteraction) {
    //   this.isLabelTextTooltipShown$.next(true);
    //   setLatestLabelBoxTooltipLocation();
    // }
    if (this.isDragging) {
      const newX = option.e.clientX;
      const newY = option.e.clientY;
      const deltaX = newX - (this.draggedMouseLatestPosition.x || 0);
      const deltaY = newY - (this.draggedMouseLatestPosition.y || 0);

      this._dragCanvas(deltaX, deltaY);
      this.draggedMouseLatestPosition = { x: newX, y: newY };

      // updates canvas obj coordinates and edit label toolbar coords if active
      const objects = this.canvasObject.getObjects();
      objects.forEach((obj: fabric.Object) => {
        obj.setCoords();
        // if (this.activeObject) {
        //   this.editToolsService.showEditTooltipFor(this.activeObject);
        // }
      });
      /*
      if(this.editToolsService.isEditFormShown) {
        this.editToolsService._placeEditFormNextTo(this.canvasObject, this.activeObject);
      }*/
    }
    //  else if (!this.editToolsService.isEditFormShown) {
    //   const { target: objectHovered } = option;
    //   if (
    //     objectHovered &&
    //     objectHovered.ctrl
    //   ) {
    //     this.activeObject = objectHovered;
    //     this.editToolsService.showEditTooltipFor(objectHovered);
    //   } else {
    //     this.editToolsService.hideEditTools();
    //   }
    // }
  }

  //   private _prevHoverObject:fabric.Object | null = null;

  //   private readonlyMouseOverHandler(option: any) {
  //     const { target: objectHovered } = option;
  //     if (!objectHovered) return;
  //     // console.info('mouseover', objectHovered);

  //     const { type, id } = objectHovered?.finding;
  //     this._prevHoverObject = objectHovered;
  //     // const i = objectHovered?.group?.finding?.type;
  //     if(type && id) {
  //       // let fabFinding = this._getFabricObjectController(objectHovered);
  //       // fabFinding?.mouseover(objectHovered);
  //       // switch (type) {
  //       //   case FindingType.Material:
  //       //     this._fabricFindingsMaterial.get(id)?.mouseover(objectHovered);
  //       //     break;
  //       //   case FindingType.BoneLoss:
  //       //     this._fabricFindingsBoneLoss.get(id)?.mouseover(objectHovered);
  //       //     break;

  //       // }
  //       // this.canvasObject.renderAll();
  //     }
  // //    const targetFinding = this.retrieveFindingFromLabelBox(objectHovered);
  //     // if (!targetFinding) {
  //     //   console.warn(
  //     //     `expected finding with id '${objectHovered.id}' but none could be found.`
  //     //   );
  //     //   return;
  //     // }

  //     // if (targetFinding?.renderConfig?.useTintOnHover) {
  //     //   this.applyOpaqueTintToLabelBox(
  //     //     objectHovered,
  //     //     this.defaultLabelBoxHoverTintTransparency
  //     //   );
  //     // }
  //   }

  // private readonlyMouseOutHandler(option: any) {

  //     const { target: objectHovered } = option;
  //     if (!objectHovered) return;
  //     // console.info('mouseOut', objectHovered);
  //     if (this._activeFindingCtrl && this._activeFindingCtrl.id === objectHovered.id ) {
  //       return this._activeFindingCtrl;
  //     } else if (!isNullable(objectHovered.data)){
  //       const { type, id } = objectHovered.data;
  //       // const i = objectHovered?.group?.finding?.type;
  //       if (type && id) {
  //         let fabFinding = this._getFabricObjectController(objectHovered);
  //         fabFinding?.mouseout(objectHovered);
  //         // switch (type) {
  //         //   case FindingType.Material:
  //         //     this._fabricFindingsMaterial.get(id)?.mouseover(objectHovered);
  //         //     break;
  //         //   case FindingType.BoneLoss:
  //         //     this._fabricFindingsBoneLoss.get(id)?.mouseover(objectHovered);
  //         //     break;

  //         // }
  //         this.canvasObject.renderAll();
  //       }
  //     }

  //     const { type, id } = objectHovered?.finding;
  //     // const i = objectHovered?.group?.finding?.type;
  //     if (type && id) {
  //       let fabFinding = this._getFabricObjectController(objectHovered);
  //       fabFinding?.mouseout(objectHovered);
  //       // switch (type) {
  //       //   case FindingType.Material:
  //       //     this._fabricFindingsMaterial.get(id)?.mouseover(objectHovered);
  //       //     break;
  //       //   case FindingType.BoneLoss:
  //       //     this._fabricFindingsBoneLoss.get(id)?.mouseover(objectHovered);
  //       //     break;

  //       // }
  //       this.canvasObject.renderAll();
  //     }
  //     if (!this.canvasObject) return;

  //     const { target: objectHovered } = option;
  //     if (!objectHovered) return;

  //     // reset a label box's hovered tint if
  //     // 1) it is indeed a label box, not any other type of element
  //     // 2) it does not contain config that enforces its tint to be displayed
  //     if (
  //       CanvasComponent.isLabelBox(objectHovered) &&
  //       !(objectHovered as any).useTint
  //     ) {
  //       objectHovered.set("fill", "transparent");
  //     }

  //     this.canvasObject.requestRenderAll();
  //   }

  /*
var xMin = (2 - zoom) * canvas.getWidth() / 2;
  var xMax = zoom * canvas.getWidth() / 2;
  var yMin = (2 - zoom) * canvas.getHeight() / 2;
  var yMax = zoom * canvas.getHeight() / 2;
  */
  private _dragCanvas(deltaX: number, deltaY: number) {
    if (this.canvasObject) {
      const viewport = this.canvasObject.viewportTransform;

      if (!viewport) {
        console.warn("Aborting drag canvas: canvas viewport is undefined.");
        return;
      }

      const { canvasObject: canvas, clamp, zoom } = this;

      viewport[4] += deltaX;
      viewport[5] += deltaY;

      this.canvasResizeService.limitImageToCanvasBoundaries(
        this.canvasObject,
        this.canvasObject.getZoom()
      );

      this.canvasObject.renderAll();
    }
  }

  _prevLabelState: boolean;
  /**
   * Handles active object and relevant state changes when canvas is clicked.
   */
  private _onActiveObjectChange(objectClicked: any) {
    if (!this.canvasObject) return;

    if (objectClicked) {
      const clickedOnAnotherLabelBox =
        this.activeObject &&
        this.activeObject.id !== objectClicked.id &&
        this.canvasObject.getObjects().length > 1;

      // if (!this.editToolsService.isEditFormShown && clickedOnAnotherLabelBox) {
      //   this.editToolsService.hideEditTools();
      //   if (this._prevLabelState) {
      //     // this.showFindingLabels = false;
      //     this._prevLabelState = false;
      //   }
      // }

      // if (
      //   !this.editToolsService.isEditFormShown &&
      //   objectClicked.ctrl
      // ) {
      //   const targetFinding = this.retrieveFindingFromLabelBox(objectClicked);
      //   this.activeObject = objectClicked;

      //   if (targetFinding !== undefined && this.activeObject) {
      //     this.activeObject.findingType = targetFinding.type;
      //   }
      // }

      if (this.activeObject) {
        if (this.editToolsService.isEditFormShown) {
          //TODO: Absrtact for finding type
          //this.showFindingLabels$.pipe(takeLatest((val:boolean) =>{
          // this._prevLabelState = this.showFindingLabels;
          // if (this._prevLabelState) {

          //   this.showFindingLabels = false;
          // }

          //}));

          this.editToolsService.showEditFormFor(this.activeObject);
        }

        // this.canvasObject.bringToFront(this.activeObject);
      }
    } else {
      this.resetActiveObject();
    }
    // const strokeWidth = 2 / this.canvasObject.getZoom();
    // for (let object of this.canvasObject.getObjects()) {
    //   object.set("strokeWidth", strokeWidth);
    // }
    this.canvasObject.renderAll();
  }

  xMin: number = 0;
  xMax: number = 0;
  yMin: number = 0;
  yMax: number = 0;
  center: fabric.Point = new fabric.Point(0, 0);
  zoom: number = 0;
  /** Initiates subscriptions for changing the canvas's zoom levels. */
  private initCanvasZoomEffects() {
    this._subs.sink = this.zoomEvent$.subscribe(({ zoom, focalPoint }) => {
      this._setZoom(zoom, focalPoint);
    });
  }

  /** Perform zoom transformation on canvas. */
  private _setZoom(newZoomLevel: number, focalPoint: { x: number; y: number }) {
    this.zoom = newZoomLevel;
    if (this.canvasObject) {
      const canvasHeight = this.canvasObject.getHeight();
      const canvasWidth = this.canvasObject.getWidth();
      const imageOriginalWidth = this.sourceImageResolution$.value.width;
      const imageOriginalHeight = this.sourceImageResolution$.value.height;
      if (
        focalPoint.x >=
        ((canvasWidth - imageOriginalWidth) / 2) * this.zoom
      ) {
      }

      this.canvasObject.zoomToPoint(
        new fabric.Point(focalPoint.x, focalPoint.y),
        newZoomLevel
      );

      // apply image boundary check to 'snap' the image back to its canvas border.
      this.canvasResizeService.limitImageToCanvasBoundaries(
        this.canvasObject,
        newZoomLevel
      );
      const strokeWidth = 2 / this.canvasObject.getZoom();
      // for (let object of this.canvasObject.getObjects()) {
      //   object.set("strokeWidth", strokeWidth);
      // }
      if (this.activeObject && this.activeObject.type !== "image") {
        this.editToolsService.showEditTooltipFor(
          this.activeObject as KellsFabric.Object
        );
      }
      if (this.isCanvasInDrawingMode()) {
        //TODO: Abstract based on finding type
        this.editToolsService.showEditFormFor(this.drawingBox);
      }
    }
  }
  private disableDrawInteractions() {
    this.canvasObject.off("mouse:down", this.drawMouseDownHandler);
    // this.canvasObject.off("mouse:move", this.drawMouseMoveHandler);
    this.canvasObject.off("mouse:up", this.drawMouseUpHandler);
    this.canvasObject.off("object:moving", this.canvasBoxModified);
    this.canvasObject.off("object:modified", this.canvasBoxModified);
  }

  private enableDrawInteractions() {
    this.canvasObject.on("mouse:down", this.drawMouseDownHandler);
    // this.canvasObject.on("mouse:move", this.drawMouseMoveHandler);
    this.canvasObject.on("mouse:up", this.drawMouseUpHandler);
    this.canvasObject.on("object:moving", this.canvasBoxModified);
    this.canvasObject.on("object:modified", this.canvasBoxModified);
  }

  private set _allFindingsSelectable(selectable: boolean) {
    this.allFindingsFabricControllerValues.forEach((finding) => {
      if (finding.object) {
        finding.object.selectable = selectable;
      }
    });
  }

  private _newFindingFabric: any;

  private drawMouseDownHandler(option: any) {
    this.canvasObject.off("mouse:down", this.drawMouseDownHandler);
    if (!this.canvasObject) return;

    const pointer = this.canvasObject.getPointer(option.e);

    this.isMouseDown = true;
    this.editToolsService.hideEditTools();
    let newFabric: any, newFinding: CanvasLibFinding;
    let matchTooth: ToothFindingFabric | null = null;
    this._fabricFindingsTooth.forEach((tooth: ToothFindingFabric) => {
      const rect = tooth.object.getBoundingRect();
      if (rect.top <= pointer.y && rect.top + rect.height > pointer.y) {
        if (rect.left <= pointer.x && rect.left + rect.width > pointer.x) {
          matchTooth = tooth;
        }
      }
    });

    switch (this.findingBrush) {
      case FindingBrush.Caries:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as ToothFindingFabric).finding.tooth
            : "0",
          type: FindingType.Caries,
          stage: CariesStageTypes.Initial,
          location: CariesLocationTypes.Other,
        };
        this.context.isPreview = this.isPreview;
        this._newFindingFabric = new CariesFindingFabric(
          newFinding,
          this.context,
          -1
        );

        // this.canvasObject.fire('mouse:down', option);
        //cariesFabric.object.controls.bl('mouse:down', option)

        break;
      case FindingBrush.BoneLoss:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, pointer.x + 10, pointer.y + 10],
          bone_loss_attributes: {
            cej_x: pointer.x,
            cej_y: pointer.y,
            ac_x: pointer.x + 10,
            ac_y: pointer.y + 10,
            severity: "0",
          },
          tooth: isDefined(matchTooth)
            ? (matchTooth as ToothFindingFabric).finding.tooth
            : "0",
          type: FindingType.BoneLoss,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
        };

        this._newFindingFabric = new BoneLossObject(newFinding, this.context);

        break;
      case FindingBrush.Material:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as ToothFindingFabric).finding.tooth
            : "0",
          type: FindingType.Material,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };

        this._newFindingFabric = new MaterialFindingFabric(
          newFinding,
          this.context,
          -1
        );

        break;

      case FindingBrush.Tooth:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as ToothFindingFabric).finding.tooth
            : "0",
          type: FindingType.Tooth,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new ToothFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.Calculus:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as CalculusFindingFabric).finding.tooth
            : "0",
          type: FindingType.Calculus,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new CalculusFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.Fracture:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as FractureFindingFabric).finding.tooth
            : "0",
          type: FindingType.Fracture,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new FractureFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.Infection:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as ToothFindingFabric).finding.tooth
            : "0",
          type: FindingType.Infection,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new InfectionFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.DefectiveRestoration:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as DefectiveRestorationFindingFabric).finding.tooth
            : "0",
          type: FindingType.DefectiveRestoration,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new DefectiveRestorationFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.Plaque:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as PlaqueFindingFabric).finding.tooth
            : "0",
          type: FindingType.Plaque,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new PlaqueFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.GumRecession:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as GumRecessionFindingFabric).finding.tooth
            : "0",
          type: FindingType.GumRecession,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new GumRecessionFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.GumInflammation:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as GumInflammationFindingFabric).finding.tooth
            : "0",
          type: FindingType.GumInflammation,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new GumInflammationFindingFabric(
          newFinding,
          this.context
        );

        break;

      case FindingBrush.MissingTooth:
        newFinding = {
          id: "NEW",
          box: [pointer.x, pointer.y, 1, 1],
          tooth: isDefined(matchTooth)
            ? (matchTooth as MissingToothFindingFabric).finding.tooth
            : "0",
          type: FindingType.MissingTooth,
          stage: CariesStageTypes.Undefined,
          location: CariesLocationTypes.Other,
          material: MaterialTypes.Undefined,
        };
        this._newFindingFabric = new MissingToothFindingFabric(
          newFinding,
          this.context
        );

        break;
    }

    this.canvasObject.on("mouse:up:before", this.onFindingCreated);
    this.canvasObject.defaultCursor = "crosshair";

    // this.drawingBox = new fabric.Rect({
    //   left: pointer.x,
    //   top: pointer.y,
    //   originX: "left",
    //   originY: "top",
    //   fill: "#007bff4d",
    //   cornerColor: "white",
    //   strokeUniform: true,
    //   noScaleCache: false,
    //   angle: 0,
    //   cornerSize: 15,
    //   transparentCorners: false,
    //   cornerStyle: "rect",
    //   hoverCursor: "default"
    // }) as KellsFabric.Rect;

    // this.drawingBox.setControlsVisibility({ mtr: false });
    // this.canvasObject.add(this.drawingBox);
  }

  onFindingCreated = (evt: any) => {
    if (evt.target) {
      this.canvasObject.off("mouse:up:before", this.onFindingCreated);
    }

    const newFindingFabric = this.canvasObject.getActiveObject() as KellsFabric.Object;
    this._ghostFinding = newFindingFabric;
    this._activeObject$.next(newFindingFabric);
    if (!this.editToolsService.isEditFormShown) {
      this.editToolsService.showEditFormFor(newFindingFabric);
    }
    return true;
  };

  private drawMouseUpHandler() {
    // if (!this.canvasObject) return;
    // if (!this.drawingBox) {
    //   throw new Error(
    //     "Draw on canvas mouse up handler invoked before a box was drawn."
    //   );
    // }
    // this.canvasObject.setActiveObject(this.drawingBox);
    // this.canvasObject.defaultCursor = "default";
    // this.drawingBox.hasControls = true;
    // this.drawingBox.set("fill", "rgba(0,0,0,0)");
    // // render drawing box display property updates
    // this.canvasObject.renderAll();
    // this.isMouseDown = false;
    // this.drawingBox.setCoords();
    // this.editToolsService.showEditFormFor(this.drawingBox);
    // this._activeObject$.next(this.drawingBox);
    // if (!this.activeObject) {
    //   throw new Error(
    //     "Could not display edit form because no active object has been set."
    //   );
    // }
    // this.editToolsService.showEditFormFor(this.activeObject);
  }

  // canvas event handler for object change.
  private canvasBoxModified(e: any) {
    const obj = e.target;
    // if object is too big ignore
    if (
      obj.currentHeight > obj.canvas.height ||
      obj.currentWidth > obj.canvas.width
    ) {
      return;
    }
    obj.setCoords();

    // top-left corner
    if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
      obj.top = Math.max(obj.top, 0);
      obj.left = Math.max(obj.left, 0);
    }

    // bot-right corner
    const objHeight = obj.aCoords.br.y - obj.aCoords.tr.y;
    const objWidth = obj.aCoords.br.x - obj.aCoords.bl.x;
    if (
      obj.getBoundingRect().top + obj.getBoundingRect().height >
        obj.canvas.height ||
      obj.getBoundingRect().left + obj.getBoundingRect().width >
        obj.canvas.width
    ) {
      obj.top = Math.min(
        obj.top,
        obj.canvas.backgroundImage.height - objHeight
      );
      obj.left = Math.min(
        obj.left,
        obj.canvas.backgroundImage.width - objWidth
      );
    }
    this.drawingBox = obj;
  }

  // re-renders canvas to reflect updates
  private rerenderFindings() {
    //   //this.removeAllCanvasElements();
    //  this.renderVisibleElements();
  }

  /**
   * Renders all the elements that should be visible on the image.
   *
   * Elements include label boxes for various types of findings, as well as
   * label texts, if they should be displayed.
   */
  private renderVisibleElements() {
    this.renderElementsOnCanvas(this.findings);
    // this.renderVisibleLabelTexts();

    // this.bringInteractiveElementsToFront();
    // this.bringLabelTextsToFront();
  }

  /**
   * Bring elements marked as interactive to the front.
   *
   * Ensure interactive objects are on top of non-interactive ones
   * so mouse hovering over interactive objects work properly.
   */
  // private bringInteractiveElementsToFront() {
  //   if (!this.canvasObject || !this.findings) return;

  //   const objects = this.canvasObject.getObjects();
  //   const boxes = objects.filter(CanvasComponent.isLabelBox);

  //   const interactiveObjects = boxes.filter(
  //     (b) => (b as any).enableInteraction
  //   );

  //   interactiveObjects.forEach((obj) => obj.bringToFront());
  // }

  /**
   * Ensure text labels are legible and not blocked by other
   */
  private bringLabelTextsToFront() {
    if (!this.canvasObject || !this.findings) return;

    // const objects = this.canvasObject.getObjects();
    // const texts = objects.filter(CanvasComponent.isLabelText);
    // texts.forEach((t) => t.bringToFront());
  }

  /**
   * Render the label texts asscociated with each label box.
   *
   * The label texts displayed depend on component settings and each finding's
   * display settings.
   *
   * For more context, please see:
   *
   *   - `showFindingLabels`: component input
   *   - `showLabelText`: stored under each finding's render settings
   */
  // private renderVisibleLabelTexts() {
  //   if (!this.canvasObject || !this.findings) {
  //     console.warn("either canvas or findings are not defined");
  //     return;
  //   }

  //   if (this.showFindingLabels.value) {
  //     // show finding labels for all findings
  //     return this.renderElementsOnCanvas(this.findings);
  //   }
  // }

  /**
   * Render every label box on canvas.
   */
  // private renderAllLabelBoxes():void {

  //    this.renderElementsOnCanvas(this.findings);
  // }

  SENSOR_DIMENSIONS_WIDTH = 35.5;
  SENSOR_DIMENSIONS_HEIGHT = 25.6;
  filmScaleFactorWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(
    1
  );
  filmScaleFactorHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(
    1
  );

  /**
   * Renders new elements on canvasObj, given one or a series of render funcs.
   *
   * @param renderFuncs render function(s) to be applied to canvasObj.
   */
  private renderElementsOnCanvas(findings: CanvasLibFinding[] | null) {
    if (!this.canvasObject) {
      console.warn(
        "Attempted to trigger render routine while canvas is not initiated."
      );
      return;
    }
    if (isNullable(findings) || findings.length === 0) return;

    if (isDefined(this._ghostFinding)) {
      this._ghostFinding?.ctrl?.dispose();
      this._ghostFinding = null;
    }

    let matOrdinality = 0;
    let cariesOrdinality = 0;

    const { context, canvasResizeService } = this;
    this.filmScaleFactorWidth$.next(
      this.SENSOR_DIMENSIONS_WIDTH / this.sourceImageResolution$.value.width
    );
    this.filmScaleFactorHeight$.next(
      this.SENSOR_DIMENSIONS_HEIGHT / this.sourceImageResolution$.value.height
    );

    this.canvasObject.discardActiveObject();
    findings.forEach((finding) => {
      switch (finding.type) {
        case FindingType.Tooth:
          if (!this._fabricFindingsTooth.has(finding.id as string)) {
            const toothFabric = new ToothFindingFabric(finding, context);

            this._fabricFindingsTooth.set(finding.id as string, toothFabric);
          } else {
            this._fabricFindingsTooth
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.BoneLoss:
          if (!this._fabricFindingsBoneLoss.has(finding.id as string)) {
            const boneLossFabric = new BoneLossObject(
              finding,
              context,
              canvasResizeService
            );
            this._fabricFindingsBoneLoss.set(
              finding.id as string,
              boneLossFabric
            );
          } else {
            this._fabricFindingsBoneLoss
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;
        case FindingType.Material:
          if (!this._fabricFindingsMaterial.has(finding.id as string)) {
            const materialFabric = new MaterialFindingFabric(
              finding,
              context,
              ++matOrdinality
            );
            materialFabric.ordinality = ++matOrdinality;

            this._fabricFindingsMaterial.set(
              finding.id as string,
              materialFabric
            );
          } else {
            this._fabricFindingsMaterial
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.Caries:
          if (!this._fabricFindingsCaries.has(finding.id as string)) {
            const cariesFabric = new CariesFindingFabric(
              finding,
              context,
              ++cariesOrdinality
            );
            context.isPreview = this.isPreview;

            this._fabricFindingsCaries.set(finding.id as string, cariesFabric);
          } else {
            this._fabricFindingsCaries
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.Calculus:
          if (!this._fabricFindingsCalculus.has(finding.id as string)) {
            const calculusFabric = new CalculusFindingFabric(finding, context);

            this._fabricFindingsCalculus.set(
              finding.id as string,
              calculusFabric
            );
          } else {
            this._fabricFindingsCalculus
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.Fracture:
          if (!this._fabricFindingsFracture.has(finding.id as string)) {
            const fractureFabric = new FractureFindingFabric(finding, context);

            this._fabricFindingsFracture.set(
              finding.id as string,
              fractureFabric
            );
          } else {
            this._fabricFindingsFracture
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.Infection:
          if (!this._fabricFindingsInfection.has(finding.id as string)) {
            const infectionFabric = new InfectionFindingFabric(
              finding,
              context
            );

            this._fabricFindingsInfection.set(
              finding.id as string,
              infectionFabric
            );
          } else {
            this._fabricFindingsInfection
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.DefectiveRestoration:
          if (
            !this._fabricFindingsDefectiveRestoration.has(finding.id as string)
          ) {
            const defectiveRestorationFabric = new DefectiveRestorationFindingFabric(
              finding,
              context
            );

            this._fabricFindingsDefectiveRestoration.set(
              finding.id as string,
              defectiveRestorationFabric
            );
          } else {
            this._fabricFindingsDefectiveRestoration
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.Plaque:
          if (!this._fabricFindingsPlaque.has(finding.id as string)) {
            const plaqueFabric = new PlaqueFindingFabric(finding, context);

            this._fabricFindingsPlaque.set(finding.id as string, plaqueFabric);
          } else {
            this._fabricFindingsPlaque
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.GumRecession:
          if (!this._fabricFindingsGumRecession.has(finding.id as string)) {
            const gumRecessionFabric = new GumRecessionFindingFabric(
              finding,
              context
            );

            this._fabricFindingsGumRecession.set(
              finding.id as string,
              gumRecessionFabric
            );
          } else {
            this._fabricFindingsGumRecession
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.GumInflammation:
          if (!this._fabricFindingsGumInflammation.has(finding.id as string)) {
            const gumInflammationFabric = new GumInflammationFindingFabric(
              finding,
              context
            );

            this._fabricFindingsGumInflammation.set(
              finding.id as string,
              gumInflammationFabric
            );
          } else {
            this._fabricFindingsGumInflammation
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;

        case FindingType.MissingTooth:
          if (!this._fabricFindingsMissingTooth.has(finding.id as string)) {
            const missingToothFabric = new MissingToothFindingFabric(
              finding,
              context
            );

            this._fabricFindingsMissingTooth.set(
              finding.id as string,
              missingToothFabric
            );
          } else {
            this._fabricFindingsMissingTooth
              .get(finding.id as string)
              ?.updateFinding(finding, true);
          }
          break;
      }
    });
    const sourceImageWidth = this.sourceImageResolution$.value.width;
    const sourceImageHeight = this.sourceImageResolution$.value.height;

    let bonelossOrdinality = 0;
    let topBoneLoss: BoneLossObject[] = [];
    let bottomBoneLoss: BoneLossObject[] = [];

    function initQuadrant(finding: BoneLossObject, centerPoint: fabric.Point) {
      let isLeft = centerPoint.x <= sourceImageWidth / 2 ? true : false;
      let isUp = centerPoint.y <= sourceImageHeight / 2 ? true : false;
      if (isUp) {
        finding.isUpper = true;
        topBoneLoss.push(finding);
        if (isLeft) {
          finding.quadrant = 1;
        } else {
          finding.quadrant = 2;
        }
      } else {
        finding.isUpper = false;
        bottomBoneLoss.push(finding);
        if (isLeft) {
          finding.quadrant = 3;
        } else {
          finding.quadrant = 4;
        }
      }
    }

    let matSortedMap: Map<string, BoneLossObject> = new Map(
      [...this._fabricFindingsBoneLoss.entries()].sort((obj1, obj2) => {
        const [id_A, finding1] = obj1;
        const [id_B, finding2] = obj2;
        const [x1A, y1A, x2A, y2A] = finding1.lineCoordsFromFinding;
        const {
          labelWidth: labelWidthA,
          labelHeight: labelHeightA,
          labelAnchor: labelAnchorA,
        } = finding1;
        const boundingBoxA = finding1.getEditFormPoint(0);
        const bBA = boundingBoxA.getCenterPoint();

        const [x1B, y1B, x2B, y2B] = finding2.lineCoordsFromFinding;
        const {
          labelWidth: labelWidthB,
          labelHeight: labelHeightB,
          labelAnchor: labelAnchorB,
        } = finding2;
        const x1 = Math.min(x1A, x2A);
        const x2 = Math.min(x1B, x2B);
        const boundingBoxB = finding2.getEditFormPoint(0);
        const bBB = boundingBoxB.getCenterPoint();

        return x1 - x2;
      })
    );
    matSortedMap = new Map(
      [...matSortedMap.entries()].map(([id, finding]) => {
        const boundingBoxA = finding.getEditFormPoint(0);
        const bBA = boundingBoxA.getCenterPoint();
        if (finding.quadrant === 0) {
          initQuadrant(finding, bBA);
        }
        return [id, finding];
      })
    );

    topBoneLoss.forEach(
      (obj: BoneLossObject, index: number, array: BoneLossObject[]) => {
        obj.ordinality = ++bonelossOrdinality;
      }
    );
    bottomBoneLoss.forEach(
      (obj: BoneLossObject, index: number, array: BoneLossObject[]) => {
        obj.ordinality = ++bonelossOrdinality;
      }
    );
  }

  get boneLossFabricControllers(): Map<string, BoneLossObject> {
    return this._fabricFindingsBoneLoss;
  }
  private _fabricFindingsBoneLoss: Map<string, BoneLossObject> = new Map();

  get toothFabricControllers(): Map<string, ToothFindingFabric> {
    return this._fabricFindingsTooth;
  }
  private _fabricFindingsTooth: Map<string, ToothFindingFabric> = new Map();

  get cariesFabricControllers(): Map<string, CariesFindingFabric> {
    return this._fabricFindingsCaries;
  }
  private _fabricFindingsCaries: Map<string, CariesFindingFabric> = new Map();

  get materialFabricControllers(): Map<string, MaterialFindingFabric> {
    return this._fabricFindingsMaterial;
  }
  private _fabricFindingsMaterial: Map<
    string,
    MaterialFindingFabric
  > = new Map();

  get calculusFabricControllers(): Map<string, CalculusFindingFabric> {
    return this._fabricFindingsCalculus;
  }
  private _fabricFindingsCalculus: Map<
    string,
    CalculusFindingFabric
  > = new Map();

  get fractureFabricControllers(): Map<string, FractureFindingFabric> {
    return this._fabricFindingsFracture;
  }
  private _fabricFindingsFracture: Map<
    string,
    FractureFindingFabric
  > = new Map();

  get infectionFabricControllers(): Map<string, InfectionFindingFabric> {
    return this._fabricFindingsInfection;
  }
  private _fabricFindingsInfection: Map<
    string,
    InfectionFindingFabric
  > = new Map();

  get defectiveRestorationFabricControllers(): Map<
    string,
    DefectiveRestorationFindingFabric
  > {
    return this._fabricFindingsDefectiveRestoration;
  }
  private _fabricFindingsDefectiveRestoration: Map<
    string,
    DefectiveRestorationFindingFabric
  > = new Map();

  get plaqueFabricControllers(): Map<string, PlaqueFindingFabric> {
    return this._fabricFindingsPlaque;
  }
  private _fabricFindingsPlaque: Map<string, PlaqueFindingFabric> = new Map();

  get gumRecessionFabricControllers(): Map<string, GumRecessionFindingFabric> {
    return this._fabricFindingsGumRecession;
  }
  private _fabricFindingsGumRecession: Map<
    string,
    GumRecessionFindingFabric
  > = new Map();

  get gumInflammationFabricControllers(): Map<
    string,
    GumInflammationFindingFabric
  > {
    return this._fabricFindingsGumInflammation;
  }
  private _fabricFindingsGumInflammation: Map<
    string,
    GumInflammationFindingFabric
  > = new Map();
  get missingToothFabricControllers(): Map<string, MissingToothFindingFabric> {
    return this._fabricFindingsMissingTooth;
  }
  private _fabricFindingsMissingTooth: Map<
    string,
    MissingToothFindingFabric
  > = new Map();

  get allFindingsFabricControllerValues() {
    return [
      ...this.boneLossFabricControllers.values(),
      ...this.toothFabricControllers.values(),
      ...this.cariesFabricControllers.values(),
      ...this.materialFabricControllers.values(),
      ...this.calculusFabricControllers.values(),
      ...this.fractureFabricControllers.values(),
      ...this.infectionFabricControllers.values(),
      ...this.defectiveRestorationFabricControllers.values(),
      ...this.plaqueFabricControllers.values(),
      ...this.gumRecessionFabricControllers.values(),
      ...this.gumInflammationFabricControllers.values(),
      ...this.missingToothFabricControllers.values(),
    ];
  }

  private _getFabricObjectController(obj: KellsFabric.Object) {
    const { type, id } = obj?.finding;
    let ctrl;
    // const i = objectHovered?.group?.finding?.type;
    if (type && id) {
      switch (type) {
        case FindingType.Tooth:
          ctrl = this._fabricFindingsTooth.get(id);
        case FindingType.Material:
          ctrl = this._fabricFindingsMaterial.get(id);
          break;
        case FindingType.BoneLoss:
          ctrl = this._fabricFindingsBoneLoss.get(id);
          break;
        case FindingType.Caries:
          ctrl = this._fabricFindingsCaries.get(id);
          break;
        case FindingType.Calculus:
          ctrl = this._fabricFindingsCalculus.get(id);
          break;
        case FindingType.Fracture:
          ctrl = this._fabricFindingsFracture.get(id);
          break;
        case FindingType.Infection:
          ctrl = this._fabricFindingsInfection.get(id);
          break;
        case FindingType.DefectiveRestoration:
          ctrl = this._fabricFindingsDefectiveRestoration.get(id);
          break;
        case FindingType.Plaque:
          ctrl = this._fabricFindingsPlaque.get(id);
          break;
        case FindingType.GumRecession:
          ctrl = this._fabricFindingsGumRecession.get(id);
          break;
        case FindingType.GumInflammation:
          ctrl = this._fabricFindingsGumInflammation.get(id);
          break;
        case FindingType.MissingTooth:
          ctrl = this._fabricFindingsMissingTooth.get(id);
          break;
      }

      return ctrl;
    }
  }

  private removeFinding(obj: KellsFabric.Object) {
    const { type, id } = obj?.finding;
    this.resetActiveObject();
    // const i = objectHovered?.group?.finding?.type;
    if (type && id) {
      switch (type) {
        case FindingType.Tooth:
          this._fabricFindingsTooth.delete(id);
        case FindingType.Material:
          this._fabricFindingsMaterial.delete(id);
          break;
        case FindingType.BoneLoss:
          this._fabricFindingsBoneLoss.delete(id);
          break;
        case FindingType.Caries:
          this._fabricFindingsCaries.delete(id);
          break;
        case FindingType.Calculus:
          this._fabricFindingsCalculus.delete(id);
          break;
        case FindingType.Fracture:
          this._fabricFindingsFracture.delete(id);
          break;
        case FindingType.Infection:
          this._fabricFindingsInfection.delete(id);
          break;
        case FindingType.DefectiveRestoration:
          this._fabricFindingsDefectiveRestoration.delete(id);
          break;
        case FindingType.Plaque:
          this._fabricFindingsPlaque.delete(id);
          break;
        case FindingType.GumRecession:
          this._fabricFindingsGumRecession.delete(id);
        case FindingType.GumInflammation:
          this._fabricFindingsGumInflammation.delete(id);
          break;
        case FindingType.MissingTooth:
          this._fabricFindingsMissingTooth.delete(id);
          break;
      }
      // obj?.ctrl?.dispose();
    }
  }

  private resetFinding(obj?: KellsFabric.Object) {
    if (!obj) return;

    const { type, id } = obj?.finding;
    if (type && id) {
      switch (type) {
        case FindingType.BoneLoss:
          const targetFinding = this.savedFindings?.find((f) => f.id === id);
          if (targetFinding) {
            const { context, canvasResizeService } = this;
            if (!this._fabricFindingsBoneLoss.has(id as string)) {
              const boneLossFabric = new BoneLossObject(
                targetFinding,
                context,
                canvasResizeService
              );
              this._fabricFindingsBoneLoss.set(id as string, boneLossFabric);
            } else {
              this._fabricFindingsBoneLoss
                .get(id as string)
                ?.updateFinding(targetFinding, true);
            }
          }

          break;
      }
      // obj?.ctrl?.dispose();
    }
  }
  /**
   * Describes the procedure for rendering label boxes, given finding boxes.
   *
   * @param canvasObj a canvasObj to add new label boxes to.
   * @param findings describes the configurations of label boxes to be added.
   * @returns a canvasObj with new label boxes added to it.
   */
  // private addLabelBoxesToCanvas(
  //   canvasObj: KellsFabric.Canvas,
  //   findings: CanvasLibFinding[]
  // ) {
  //   const strokeWidth = 2; /* CanvasComponent.computeLabelBoxStrokeWidth({
  //     sourceImageWidth: this.sourceImageResolution$.value.width,
  //     sourceImageHeight: this.sourceImageResolution$.value.height,
  //     isThumbnail: this.isImageDetail,
  //   }); */

  //   const standardRectConfig = {
  //     strokeWidth: this.canvasObject
  //       ? strokeWidth / this.canvasObject.getZoom()
  //       : 1,
  //     cornerColor: "white",
  //     strokeUniform: true,
  //     cornerSize: 15,
  //     hasRotatingPoint: false, // hide the rotating control above a fabric.Rect
  //     hasBorders: false, // disable the blue border shown when a box is selected
  //     selectable: false,
  //   };

  //   const labelBoxCreator = (finding: CanvasLibFinding) => {
  //     if (!this.canvasObject) {
  //       console.warn(
  //         "Attempted to trigger render routine while canvas is not initiated."
  //       );
  //       return;
  //     }
  //     if (finding.type === FindingType.BoneLoss) {

  //       if(this._fabricFindingsBoneLoss.has(finding.id as string)) {
  //         const boneLossFabric = new BoneLossObject(finding, this.canvasObject);
  //         boneLossFabric.initFabricObject();
  //         this._fabricFindingsBoneLoss.set(finding.id as string, boneLossFabric);
  //       }
  //       //return BoneLossObject.createFabricObject(canvasObj as KellsFabric.Canvas, finding);
  //     }
  //     const [left, top, width, height] = finding.box;
  //     const strokeColor = CanvasFindingService.resolveLabelBoxColor(finding);

  //     const tintColor = CanvasComponent.addTransparencyToHexColor(
  //       strokeColor,
  //       this.defaultLabelBoxHoverTintTransparency
  //     );

  //     const enableInteraction = true;

  //     const rect = new fabric.Rect({
  //       left,
  //       top,
  //       width,
  //       height,
  //       strokeDashArray: finding.renderConfig?.useDashedLine
  //         ? [strokeWidth, strokeWidth]
  //         : undefined,
  //       stroke: strokeColor,
  //       noScaleCache: false,
  //       //strokeWidthUnscaled: null,
  //       hoverCursor:
  //         this.isImageConfirmed || !enableInteraction ? "default" : "pointer",
  //       ...standardRectConfig,
  //       fill: finding.renderConfig?.useTint ? tintColor : "transparent",
  //     }) as KellsFabric.Rect;

  //     rect.controls = {
  //       ...fabric.Rect.prototype.controls,
  //       mtr: new fabric.Control({ visible: false }),
  //     };

  //     //rect.setControlsVisibility({ mtr: false });

  //     rect.id = finding.id;
  //     rect.enableInteraction = enableInteraction;

  //     return rect;
  //   };

  //     BoneLossObject.createBoneLossFabricGroup(canvasObj as KellsFabric.Canvas, finding);
  //   } else {
  //     canvasObj.add(labelBoxCreator(finding));
  //   }

  // })
  // return canvasObj;
  //return CanvasComponent._addToCanvas(canvasObj, findings, labelBoxCreator);
  // }

  /**
   * Describes the procedure for rendering text labels, given finding boxes.
   *
   * @param canvasObj a canvasObj to add new text labels to.
   * @param findings describes the configurations of text labels to be added.
   * @returns a canvasObj with new text labels added to it.
   */
  // private addLabelTextsToCanvas(
  //   canvasObj: KellsFabric.Canvas,
  //   findings: CanvasLibFinding[]
  // ) {
  //   const fontSize = CanvasComponent.computeLabelTextFontSize({
  //     sourceImageWidth: this.sourceImageResolution$.value.width,
  //     sourceImageHeight: this.sourceImageResolution$.value.height,
  //     isThumbnail: this.isThumbnail,
  //   });

  //   const standardLabelTextConfig = {
  //     fontSize,
  //     fontFamily: "arial",
  //     selectable: false,
  //     cursor: "default",
  //     hoverCursor: "default",
  //     padding: 10,
  //   };

  //   const labelTextCreator = (finding: CanvasLibFinding) => {
  //     const [x, y, , boxHeight] = finding.box;
  //     const { id } = finding;

  //     const label =
  //       finding.renderConfig?.labelText ??
  //       CanvasComponent.resolveFindingLabelText(finding);

  //     const effectiveStyleConfig = (() => {
  //       if (finding.type === FindingType.Material)
  //         return LabelTextStyleConfigs.Material;

  //       if (finding.type === FindingType.Tooth)
  //         return LabelTextStyleConfigs.Tooth;

  //       return LabelTextStyleConfigs.Standard;
  //     })();

  //     const effectiveTopCoor =
  //       finding.type === FindingType.Tooth ||
  //       finding.type === FindingType.Material
  //         ? y
  //         : y + boxHeight;

  //     const text = new fabric.Text(label, {
  //       left: x,
  //       top: effectiveTopCoor,
  //       ...effectiveStyleConfig,
  //       ...standardLabelTextConfig,
  //     }) as KellsFabric.Text;
  //     text.id = id;

  //     return text;
  //   };
  //   return CanvasComponent._addToCanvas(canvasObj, findings.filter(f => f.type !== FindingType.BoneLoss), labelTextCreator);
  // }

  /**
   * Adds a list of elements to a canvasObj.
   *
   * @param canvasObj a canvasObj to add new elements to.
   * @param inputList a array of listing configs of new fabric elements.
   * @param creatorFunc transforms a config object to a fabric element.
   * @returns a canvasObj with new elements added to it.
   */
  private static _addToCanvas(
    canvasObj: fabric.Canvas,
    inputList: CanvasLibFinding[],
    creatorFunc: CanvasElementCreator
  ) {
    const newElems = inputList.map(creatorFunc);
    canvasObj.add(...newElems);
    return canvasObj;
  }

  /**
   * Remove all LabelTexts on the canvasObj.
   */
  private removeLabelTexts() {
    // const isElemLabelText = (elem: any) =>
    //   Object.prototype.hasOwnProperty.call(elem, "text");
    // this._removeCanvasElements(isElemLabelText);
  }

  /**
   * Remove all elements on the canvasObj.
   */
  // private removeAllCanvasElements() {
  //   this._removeCanvasElements();
  // }

  /**
   * Remove canvas elements matching the filters specified.
   *
   * The ordering of filters matters. Filter functions will be applied in the
   * sequence that they're passed in.
   *
   * @param filterFuncs to be applied, in the sequence that is passed in, to
   *     canvasObjElements.
   */
  private _removeCanvasElements(...filterFuncs: ((elem: any) => boolean)[]) {
    if (isNullable(this.canvasObject)) return;

    for (let tooth of this._fabricFindingsTooth.values()) {
      tooth.remove();
    }
    for (let boneLoss of this._fabricFindingsBoneLoss.values()) {
      boneLoss.remove();
    }
    for (let material of this._fabricFindingsMaterial.values()) {
      material.remove();
    }
    // this._fabricFindingsBoneLoss.forEach(fObj => fObj.remove());

    // this._fabricFindingsMaterial.forEach(fObj => fObj.remove());
    // const allElements = this.canvasObject.getObjects();
    // const toBeRemoved = filterFuncs.reduce(
    //   (elements, nextFilter) => elements.filter(nextFilter),
    //   allElements
    // );

    // this.canvasObject.remove(...toBeRemoved);
  }

  isCanvasInDrawingMode() {
    return this.canvasMode === CanvasModes.Draw;
  }

  /** finding box clicked by a user */
  private get activeObject() {
    return this._activeObject$.getValue();
  }

  private set activeObject(obj) {
    this._activeObject$.next(obj);
  }

  /**
   * The distance between the left edge of the screen to the left edge of the
   * canvas, in pixels.
   *
   * @returns `undefined` if this property is accessed before the canvas has
   *     been initialized, otherwise the canvas left offset in pixels.
   */
  private get canvasLeftOffset(): number | undefined {
    return CanvasService.getCanvasLeftOffset(this.canvasObject);
  }

  /**
   * The distance between the top edge of the screen and the top edge of the
   * canvas, in pixels.
   *
   * @returns `undefined` if this property is accessed before the canvas has
   *     been initialized, otherwise the canvas left offset in pixels.
   */
  private get canvasTopOffset(): number | undefined {
    return CanvasService.getCanvasTopOffset(this.canvasObject);
  }

  private get canvasLabelBoxes(): KellsFabric.Rect[] {
    if (!this.canvasObject) return [];
    return this.canvasObject
      .getObjects()
      .filter((o): o is KellsFabric.Rect => o instanceof fabric.Rect);
  }

  private _exitDrawingMode() {
    if (!this.canvasObject || !this.isImageLoaded$.value) return;

    this.canvasObject.remove(this.canvasObject.getActiveObject());
    this.resetActiveObject();
    this.canvasModeChange.next(CanvasModes.ReadOnly);
  }

  deleteActiveFinding() {
    if (!this.activeObject) {
      throw new Error(
        "Attempted to delete finding but active object is undefined."
      );
    }

    this.findingUpdate.next({
      type: FindingUpdateTypes.Delete,
      payload: {
        id: this.activeObject.ctrl.id,
      },
    });
    this.removeFinding(this.activeObject);
    this.activeObject?.ctrl?.dispose();
    if (this.showFindingLabels.value === true) {
      this.store.dispatch(LayoutActions.imageDetailHideLabels());
      setTimeout(() => {
        this.store.dispatch(LayoutActions.imageDetailShowLabels());
      }, 100);
    }
  }

  cancelEdit() {
    if (this.isCanvasInDrawingMode()) {
      //this.canvasObject.remove(this.canvasObject.getActiveObject());
      this.resetActiveObject();
      this.enableDrawInteractions();
      if (this.activeObject) {
        this.removeFinding(this.activeObject);
        this.activeObject?.ctrl?.dispose();
      }
      this.canvasObject.defaultCursor = "crosshair";
      this.canvasObject.renderAll();
      this.canvasModeChange.next(CanvasModes.ReadOnly);
    } else {
      CanvasFindingService.setIsCancelMode();
      if (this.activeObject) {
        this.removeFinding(this.activeObject);
        this.resetFinding(this.activeObject);
        this.activeObject?.ctrl?.dispose();
      }
      this.initFindingRenderingEffects();
    }
    if (this.showFindingLabels.value === true) {
      this.store.dispatch(LayoutActions.imageDetailHideLabels());
      setTimeout(() => {
        this.store.dispatch(LayoutActions.imageDetailShowLabels());
      }, 100);
    }
  }

  /**
   * A visual fabric finding object the user has drawn and is being saved. Will be removed
   * when findings return from backend
   */
  _ghostFinding: any | undefined;

  _findingUpdate: any | undefined;
  /** Save new edit values */
  saveFinding(findingUpdate: FindingUpdatePayload) {
    this._subs.sink = this.isImageConfirmed$
      .pipe(take(1))
      .subscribe((reportConfirmed) => {
        const nextFinding = {
          ...findingUpdate,
          // finding update is automatically confirmed if image has been confirmed
          status: reportConfirmed ? "confirmed" : "",
        };

        if (this.isCanvasInDrawingMode()) {
          this.saveNewFinding(nextFinding);
          this.canvasModeChange.next(CanvasModes.ReadOnly);
        } else {
          this._findingUpdate = { ...findingUpdate };
          this._findingUpdate.id = (this.canvasObject.getActiveObject() as KellsFabric.Object).ctrl.id;
          this.saveEditToFinding(nextFinding);
        }
      });
  }

  private saveEditToFinding(findingUpdate: FindingUpdatePayload) {
    const target = this.canvasObject.getActiveObject() as KellsFabric.Object;
    let payload: any = null;
    if (!target) {
      console.error(`
        Cannot save edit to finding when no object is selected on canvas. Aborting...
      `);
      return;
    }
    const findingId = target.ctrl.id;
    switch (target.finding.type) {
      case FindingType.BoneLoss:
        const finding = this._fabricFindingsBoneLoss.get(findingId);

        payload = {
          id: findingId,
          update: {
            // ...findingUpdate,
            tooth: findingUpdate.tooth,
            status: FindingStatus.Confirmed,
            stage: findingUpdate.severity,
            bone_loss_attributes: {
              ...target.finding.bone_loss_attributes,
              severity: findingUpdate.severity,
              tooth: findingUpdate.tooth,
            },
          },
        };

        if (finding) {
          payload = {
            ...payload,
            update: {
              ...payload.update,
              bone_loss_attributes: {
                ...payload.update.bone_loss_attributes,
                cej_x: finding?.cej_x,
                cej_y: finding?.cej_y,
                ac_x: finding?.ac_x,
                ac_y: finding?.ac_y,
              },
            },
          };
        }

        // if(payload.update.box) delete payload.upload.box;
        this._findingUpdate = payload;
        this.findingUpdate.next({
          type: FindingUpdateTypes.Edit,
          payload,
        });
        target.finding.tooth = findingUpdate.tooth;
        this._activeObject$.next(target);

        break;
      default:
        const latestBoxDimension = CanvasElementService.computeBoxCoordinates(
          this.activeObject as any
        );

        this.findingUpdate.next({
          type: FindingUpdateTypes.Edit,
          payload: {
            id: findingId,
            update: {
              ...findingUpdate,
              box: latestBoxDimension,
            },
          },
        });
        target.finding = Object.assign(target.finding, findingUpdate);
        this._activeObject$.next(target);
    }
    this.savedFindings = null;
    this.resetActiveObject();
    if (this.showFindingLabels.value === true) {
      this.store.dispatch(LayoutActions.imageDetailHideLabels());
      setTimeout(() => {
        this.store.dispatch(LayoutActions.imageDetailShowLabels());
      });
    }
  }

  private saveNewFinding(finding: FindingUpdatePayload) {
    const drawingBox = this.canvasObject.getActiveObject();
    if (
      !drawingBox ||
      !drawingBox.aCoords ||
      !drawingBox.left ||
      !drawingBox.top
    ) {
      throw new Error(
        `Attempted to save new findings but drawing box is invalid: ${JSON.stringify(
          this.drawingBox
        )}`
      );
    }

    const boxDimensions = CanvasElementService.computeBoxCoordinates(
      drawingBox as KellsFabric.Rect
    );

    const newFinding = {
      ...finding,
      tooth: isNaN(parseInt(finding.tooth, 10))
        ? finding.tooth.toUpperCase()
        : finding.tooth,
      box: boxDimensions,
      status: FindingStatus.Confirmed,
      treatments: [],
      type: this._newFindingFabric.finding.type,
    };
    if (newFinding.type === FindingType.BoneLoss) {
      newFinding.bone_loss_attributes = this._newFindingFabric.finding.bone_loss_attributes;
    }
    this.findingUpdate.next({
      type: FindingUpdateTypes.Create,
      payload: newFinding,
    });
    this.savedFindings = null;
    this.enableDrawInteractions();
    this.editToolsService.hideEditTools();
    if (this.showFindingLabels.value === true) {
      this.store.dispatch(LayoutActions.imageDetailHideLabels());
      setTimeout(() => {
        this.store.dispatch(LayoutActions.imageDetailShowLabels());
      }, 100);
    }
  }

  private emitFindingCoordinateUpdate(targetBox: KellsFabric.Rect) {
    if (!this.findings) {
      throw new Error(
        "Expected 'findings' to be defined for 'CanvasComponent'."
      );
    }

    const box = CanvasElementService.computeBoxCoordinates(targetBox);

    const targetFinding = this.findings.find((f) => f.id === targetBox.id);

    if (!targetFinding) {
      throw new Error(`
        Invalid finding index number '${targetBox.id}'.
        These findings are available: ${this.findings}
      `);
    }

    this.findingUpdate.next({
      type: FindingUpdateTypes.Edit,
      payload: {
        id: targetBox.id as string,
        update: {
          box,
          stage: targetFinding.stage,
          tooth: targetFinding.tooth,
          location: targetFinding.location as CariesLocationTypes,
        },
      },
    });
  }

  /** Discard all tracked data for active obj that's being modified */
  private resetActiveObject() {
    this.editToolsService.hideEditTools();
    this.canvasObject.discardActiveObject();
  }

  private static _areZoomEventsEqual(
    one: ZoomEvent,
    other: ZoomEvent
  ): boolean {
    return (
      one.zoom === other.zoom &&
      one.focalPoint.x === other.focalPoint.x &&
      one.focalPoint.y === other.focalPoint.y
    );
  }

  /**
   * Given a finding, calculate the text that should be displayed beneath the
   * box (or in another location, if there are no spaces beneath the box).
   */
  private static resolveFindingLabelText(finding: CanvasLibFinding) {
    if (finding.type === FindingType.Tooth) return finding.tooth;

    try {
      return FindingProcessingService.toFindingText(finding);
    } catch (err) {
      console.error(err);
      return "Unknown";
    }
  }

  /**
   * Performs runtime validation on critical component inputs.
   */
  private _validateComponentInputs() {
    // function that performs actual validation
    const validate = (
      propertyName: keyof CanvasComponent,
      args: {
        required: boolean;
        acceptedType: string;
      }
    ) => {
      const { required, acceptedType } = args;
      if (required && !isDefined(this[propertyName])) {
        console.warn(
          `Input '${propertyName}' is required but is not supplied.`
        );
        return;
      }

      if (
        isDefined(this[propertyName]) &&
        typeof this[propertyName] !== acceptedType
      ) {
        console.warn(
          `Input '${propertyName}' must be a ${acceptedType}, received ${typeof this[
            propertyName
          ]}.`
        );
      }
    };

    validate("maxHeight", {
      required: false,
      acceptedType: "number",
    });

    validate("maxWidth", {
      required: false,
      acceptedType: "number",
    });
  }

  /**
   * Calculate the stroke width to be used for a label box.
   *
   * @note
   * the source image's height and width is different from the canva's. The
   * arguments for this function uses the resolution of the original image.
   * The dimension of the canvas can be much larger or smaller, depending on
   * the user's screen and where the canvas is rendered.
   *
   * @param args
   *   - sourceImageHeight: the height of the source image, in pixels.
   *   - sourceImageWidth: the width of the source image, in pixels.
   *   - isThumbnail: a boolean indicating whether the canvas is treated
   *       as a thumbnail or an expanded canvas.
   *
   * @returns a numerical stroke width value to be used on a `fabric.Rect`,
   *   the fabric.js entity responsible for rendering label boxes.
   */
  // private static computeLabelBoxStrokeWidth(args: {
  //   sourceImageWidth: number;
  //   sourceImageHeight: number;
  //   isThumbnail: boolean;
  // }) {}
  //   const {
  //     sourceImageWidth: width,
  //     sourceImageHeight: height,
  //     isThumbnail,
  //   } = args;

  //   // summation is used here to factor in both the image's width and height.
  //   // note that with summation, image orientation will not affect the computed
  //   // stroke width for images with the same orientation.
  //   const widthHeightSum = width + height;

  //   // Magic numbers to compute the effective stroke width relative to
  //   // an image's resolution.
  //   const STROKE_FACTOR = 0.0015;
  //   const THUMBNAIL_STROKE_FACTOR = 0.005;

  //   const thumbnailStrokeWidth = Math.max(
  //     Math.floor(widthHeightSum * THUMBNAIL_STROKE_FACTOR),
  //     1
  //   );

  //   const strokeWidth = Math.max(Math.floor(widthHeightSum * STROKE_FACTOR), 1);

  //   return isThumbnail ? thumbnailStrokeWidth : strokeWidth;
  // }

  // /**
  //  * Calculates the font size of a label's text.
  //  */
  // private static computeLabelTextFontSize(args: {
  //   sourceImageWidth: number;
  //   sourceImageHeight: number;
  //   isThumbnail: boolean;
  // }) {
  //   const {
  //     sourceImageWidth: width,
  //     sourceImageHeight: height,
  //     isThumbnail,
  //   } = args;

  //   const widthHeightSum = width + height;

  //   // Magic numbers to compute the effective font size relative to
  //   // an image's resolution.
  //   const FONT_SIZE_FACTOR = 0.012;
  //   const THUMBNAIL_FONT_SIZE_FACTOR = 0.03;

  //   const thumbnailFontSize = Math.floor(
  //     widthHeightSum * THUMBNAIL_FONT_SIZE_FACTOR
  //   );

  //   const fontSize = Math.floor(widthHeightSum * FONT_SIZE_FACTOR);

  //   return isThumbnail ? thumbnailFontSize : fontSize;
  // }
}
