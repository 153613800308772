import { Injectable } from "@angular/core";
import { Meta, MetaDefinition, Title } from "@angular/platform-browser";

export interface PageMetadata {
  title?: string;
  image?: string;
  description?: string;
  author?: string;
  keywords?: string[];
}

@Injectable({
  providedIn: "root",
})
export class MetadataService {
  constructor(
    private readonly metaTagService: Meta,
    private readonly titleService: Title
  ) {}

  public setMetadata(metadata: Partial<PageMetadata>): void {
    if (metadata.title) {
      this.titleService.setTitle(metadata.title);
    }

    const metatags: MetaDefinition[] = this.generateMetaDefinitions(metadata);
    this.metaTagService.addTags(metatags);
  }

  private generateMetaDefinitions(
    metadata: Partial<PageMetadata>
  ): MetaDefinition[] {
    return [
      { name: "title", content: metadata.title },
      { property: "og:title", content: metadata.title },

      { name: "description", content: metadata.description },
      { property: "og:description", content: metadata.description },

      { property: "og:image", content: metadata.image },

      { name: "author", content: metadata.author },
      { property: "og:author", content: metadata.author },

      { name: "keywords", content: metadata.keywords?.join(", ") },
    ].filter((meta) => !!meta.content) as MetaDefinition[];
  }
}
