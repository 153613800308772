import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { apiEnvironment } from "./environments/environment";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "./injection-tokens";

/**
 * Module that provides common environment variables for Clinic One and its
 * libraries.
 *
 * @example
 * ```ts
 * // import the environment module at the appropriate scope (usually at root)
 * @Injectable({
 *   imports: [ClinicOneEnvironmentsModule]
 * })
 * export class AppModule {}
 *
 * // then, in a library service file:
 * @Injectable()
 * export class ImageService {
 *   // access API base URL
 *   constructor(@Inject(CLINIC_ONE_APP_BASE_URL) private baseUrl: string) {}
 * }
 * ```
 */
@NgModule({
  imports: [CommonModule],
  providers: [
    // inject API endpoint-related info for libs
    { provide: CLINIC_ONE_API_BASE_URL, useValue: apiEnvironment.apiBaseUrl },
    { provide: CLINIC_ONE_API_VER, useValue: apiEnvironment.apiVersion },
  ],
})
export class ClinicOneEnvironmentsModule {}
