/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { DatePipe } from "@angular/common";
import {
  PatientDataResponse,
  RawPatient,
  Patient,
  SESSION_DATE_FORMAT,
  SessionIndexed,
} from "../models/patient.model";
import { ImageResponse } from "../models/image.model";
import { flatten } from "lodash-es";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";
import { KellsPatient } from "@kells/interfaces/patient";

interface PatientListResponse {
  message: string;
  data: RawPatient[];
}

interface MostRecentPatientsListResponse {
  data: RawPatient[];
  count: number;
}

interface PatientImagesResponseData {
  [sessionDate: string]: {
    [sessionId: string]: ImageResponse[];
  };
}

interface PatientImagesResponse {
  message: string;
  data: PatientImagesResponseData;
}

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class PatientService {
  officeServiceBaseUrl = `${this.baseUrl}/${this.apiVersion}/offices`;

  patientServiceBaseUrl = (pid: number | string) =>
    `${this.baseUrl}/${this.apiVersion}/patients/${pid}`;

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient,
    private datePipe: DatePipe
  ) {}

  getPatientsList(officeId: string): Observable<Patient[]> {
    const patientSortFunc = (a: RawPatient, b: RawPatient) => {
      if (a.last_name > b.last_name) return 1;
      if (b.last_name > a.last_name) return -1;
      return 0;
    };

    return this.http
      .get<PatientListResponse>(
        `${this.officeServiceBaseUrl}/${officeId}/patients`
      )
      .pipe(
        map((res) => res.data),
        map((patientsList) => patientsList.sort(patientSortFunc)),
        map((patientsList) =>
          patientsList.map(PatientService.parsePatientResponse)
        )
      );
  }

  getMostRecentPatients(
    officeId: string,
    maxPatientsFetchedCount = 10
  ): Observable<Patient[]> {
    return this.http
      .get<MostRecentPatientsListResponse>(
        `${this.officeServiceBaseUrl}/${officeId}/patients/recent`,
        {
          params: {
            num: maxPatientsFetchedCount.toString(),
          },
        }
      )
      .pipe(
        map((res) => res.data),
        map((patients) => patients.map(PatientService.parsePatientResponse))
      );
  }

  static parsePatientResponse = (p: PatientDataResponse): Patient => {
    const extractImageIds = (images: SessionIndexed<ImageResponse>) =>
      Object.entries(images).reduce(
        (a, [date, gs]) => ({ ...a, [date]: gs.map((g) => g._id) }),
        {}
      );

    const resp: Patient = {
      id: p._id,
      birthday: p.dob,
      firstName: p.first_name,
      lastName: p.last_name,
      officeId: p.office_id,
      predicting: false,
      email: p.email,
      gender: p.gender,
      insurance_provider: p.insurance_provider,
      insurance_status: p.insurance_status,
      has_insurance: p.has_insurance,
      phone: p.phone,
      is_onboarded: p.is_onboarded,
      organizations: p.organizations,
      is_photo_scan_onboarded: p.is_photo_scan_onboarded,
      street1: p.address?.street1,
      city: p.address?.city,
      state: p.address?.state,
      zipcode: p.address?.zipcode,
      subscription_plan: p.subscription_plan,
    };
    if (p.images) resp.images = extractImageIds(p.images);
    return resp;
  };

  updatePatient(data: Partial<KellsPatient>) {
    const { id, ...rest } = data;

    return this.http.patch(`${this.patientServiceBaseUrl(id as string)}`, rest);
  }

  getPatientById(id: number | string): Observable<PatientDataResponse> {
    return this.http
      .get<{ message: string; data: PatientDataResponse }>(
        `${this.patientServiceBaseUrl(id)}`
      )
      .pipe(map(({ data }) => data));
  }

  getPatientByEmail(email: string): Observable<Patient> {
    return this.http
      .get<{ message: string; data: PatientDataResponse }>(
        `${this.baseUrl}/${this.apiVersion}/patients/email/${email}`
      )
      .pipe(
        map(({ data }) => data),
        map((resp) => PatientService.parsePatientResponse(resp))
      );
  }

  /**
   * Return session-date-indexed images of a partigular patient.
   *
   * The returned observable would return an object of the following schema:
   * ```
   * {
   *   sessionDate: [
   *     an array of images
   *   ]
   * }
   * ```
   * @param patientID the ID of the patient to request images for
   */
  getSessionDateIndexedImages(
    patientID: string | number
  ): Observable<SessionIndexed<ImageResponse>> {
    return this.http
      .get<PatientImagesResponse>(
        `${this.patientServiceBaseUrl(patientID)}/images`
      )
      .pipe(map(({ data }) => this.parseSessionDateIndexedImages(data)));
  }

  parseSessionDateIndexedImages(
    images: PatientImagesResponseData
  ): SessionIndexed<ImageResponse> {
    return Object.entries(images).reduce(
      (acc, [sessionDate, sessionImages]) => {
        const test = Object.entries(sessionImages).map(
          ([sessionId, images]) => {
            return Object.values(images).map((image) => {
              return {
                ...image,
                sessionId,
              };
            });
          }
        );

        acc[sessionDate] = flatten(Object.values(test));
        return acc;
      },
      {} as SessionIndexed<ImageResponse>
    );
  }

  /**
   * Given any date string, returns one that's formatted using the standard
   * session date format.
   */
  formatSesssionDate(anyDate: string) {
    return this.datePipe.transform(anyDate, SESSION_DATE_FORMAT);
  }
}
