export interface RiskFactorDto {
  _id: string;
  display_name: string;
  icon_url: string;
  selected_icon_url: string;
}

export interface RiskFactor {
  id: string;
  iconUrl: string;
  selectedIconUrl: string;
  displayedName: string;
}

export interface PatientRecommendationDto {
  _id: string;
  created_datetime: string;
  note: string;
  patient: string;
  risk_level: number;
  risk_factors: string[];
  session: string;
}

export interface PatientRecommendation {
  id: string;
  createTs: Date;
  updated?: Date;
  isPending?: boolean;
  note: string;
  patientId: string;
  riskLevel: PatientRecommendationRiskLevels;
  riskFactors: string[];
  sessionId: string;
}

export enum PatientRecommendationRiskLevels {
  Undefined = -1,
  Low = 1,
  Medium = 2,
  High = 3,
}

/**
 * Interface for the payload included in requests to update a patient's recommendation.
 *
 * Properties are a subset of [[`PatientRecommendation`]].
 */
export type PatientRecommendationUpdatePayload = Partial<PatientRecommendation>;
//"riskFactors" | "note" | "riskLevel"
