<ng-container *ngLet="isLoading$ | async as isLoading">
  <div
    *ngIf="tabs?.length || isLoading || !isInreportRecommendation"
    [ngClass]="{ 'report-product-recommendation': isInreportRecommendation }"
    class="product-recommendation"
  >
    <div class="product-recommendation__header">PRODUCT RECOMMENDATION</div>
    <div class="product-recommendation__data">
      <div class="product-recommendation-loading" *ngIf="isLoading">
        Loading...
      </div>

      <kpr-product-recommendation-main
        (openReportClicked)="linkClickedHandler($event)"
        *ngIf="tabs?.length && !isLoading"
        [tabs]="tabs"
        [currentSessionId]="latestReviewId$ | async"
        [patientId]="patientId$ | async"
        class="product-recommendation__data__main"
        [isInreportRecommendation]="isInreportRecommendation"
      ></kpr-product-recommendation-main>
      <kpr-product-recommendation-no-recommendations
        (openPhotoScanClicked)="linkClickedHandler($event)"
        *ngIf="!tabs?.length && !isLoading"
      ></kpr-product-recommendation-no-recommendations>
    </div>
  </div>
</ng-container>
