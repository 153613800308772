import { Injectable } from "@angular/core";
import { RiskEvaluationApiService, RiskFactor } from "@kells/clinic-one/apis";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

type RiskFactorMap = Record<string, RiskFactor>;

/**
 * Get a risk factor's definition (icon URL, description, etc.), given its ID.
 *
 * @category Service
 */
@Injectable({
  providedIn: "any",
})
export class RiskFactorService {
  private riskFactors$ = this.riskEvalApi.getRiskFactors().pipe(shareReplay(1));

  private riskFactorsMap$: Observable<RiskFactorMap> = this.riskFactors$.pipe(
    map((riskFactors) =>
      riskFactors.reduce((acc, r) => ({ ...acc, [r.id]: r }), {})
    )
  );

  constructor(private riskEvalApi: RiskEvaluationApiService) {}

  /**
   * Get an observable of the risk factor that's looked up.
   *
   * @param riskFactorId ID of the risk factor to look for.
   */
  get(riskFactorId: string): Observable<RiskFactor | null> {
    return this.riskFactorsMap$.pipe(
      map((riskFactors) => riskFactors[riskFactorId] ?? null)
    );
  }
}
