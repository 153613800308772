import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { isNullable } from "@kells/utils/js";
import { map } from "rxjs/operators";
import {
  AddUserRequestParams,
  AddUserResponse,
  CariesDetectionThresholdDefaultValues,
  CariesDetectionThresholdTypes,
  DentistsResponse,
  GetUserResponse,
  GetUsersResponse,
  KellsUser,
  KellsUserResponse,
  UpdateUserRequestParams,
  UpdateUserResponse,
  User,
  UserProvider,
  UserResponse,
} from "../models/user.model";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class UserService {
  private userServiceBaseUrl = `${this.baseUrl}/${this.apiVersion}/users`;

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient
  ) {}

  /**
   * Gets a Kells User's information.
   *
   * @param userId the ID of a Kells User
   * @returns an observable of `KellsUser`, if response from the server was
   *    parsed successfully, or of `undefined`, if error(s) occurred while
   *    parsing the response.
   */
  getUserInfo(args: {
    userId: string;
    officeId: string;
  }): Observable<KellsUser | undefined> {
    return this.http
      .get<KellsUserResponse>(
        `${this.baseUrl}/${this.apiVersion}/offices/${args.officeId}/users/${args.userId}`
      )
      .pipe(map(UserService.parseUserResponse));
  }

  patchUserSettings(args: {
    officeId: string;
    userId: string;
    updatedSettings: KellsUser["settings"];
  }) {
    return this.http.patch<unknown>(
      `${this.baseUrl}/${this.apiVersion}/offices/${args.officeId}/users/${args.userId}`,
      {
        settings: UserService.serializeUserSettings(args.updatedSettings),
      }
    );
  }

  /**
   * Parses a Kells User response payload from the server into a `KellsUser`.
   *
   * @param userResponse the response object to be parsed.
   * @returns `undefined` if the argument is `null`, `undefined`, or some
   *    error(s) occurred while parsing the argument.
   *    A `KellsUser` instance otherwise.
   */
  static parseUserResponse(
    userResponse: KellsUserResponse | null | undefined
  ): KellsUser | undefined {
    if (isNullable(userResponse)) {
      return undefined;
    }

    const resp = userResponse.data;

    return {
      id: resp._id,
      officeId: resp.office,
      email: resp.email,
      firstName: resp.first_name,
      lastName: resp.last_name,
      gender: resp.gender,
      role: resp.role,
      settings: UserService.parseUserSettings(resp.settings),
    };
  }

  static getUserFullname(user: KellsUser | undefined): string | undefined {
    if (!user) return;

    return [user.firstName, user.lastName].filter(Boolean).join(" ");
  }

  /**
   * Extracts capitalized initials of a user.
   *
   * @param user the user whose initials will be returned, if available.
   * @returns a string of up to 2 characters or `undefined`. If a user's first
   *    name or last name does not contain letters, a string of 1 character is
   *    returned. If neither first name or last name are defined or neither
   *    doesn't have letters, return `undefined`.
   */
  static getInitials(user: KellsUser | undefined): string | undefined {
    const keepAlphabeticLetters = (s: string): string => {
      const isAlpha = RegExp("[a-zA-Z]");
      return s
        .trim()
        .split("")
        .filter((char) => isAlpha.test(char))
        .join("");
    };

    if (!user) {
      return undefined;
    }

    const initialsSource = [user.firstName, user.lastName]
      .filter((v): v is string => !isNullable(v))
      .map(keepAlphabeticLetters)
      .filter((str) => str.length > 0);

    if (initialsSource.length === 0) {
      return undefined;
    }

    return initialsSource
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
  }

  private static parseUserSettings(
    settingsResponse: KellsUserResponse["data"]["settings"]
  ): KellsUser["settings"] {
    const autoEnhanceResp = settingsResponse?.auto_enhance_images;
    const autoEnhance = !isNullable(autoEnhanceResp) ? autoEnhanceResp : false;

    const defaultSelectedThreshold: CariesDetectionThresholdTypes = "medium";

    // ensure the caries detection thresholds key order are as follows
    const cariesDetectionThresholds = {
      low:
        settingsResponse?.prediction_thresholds.low ??
        CariesDetectionThresholdDefaultValues.low,
      medium:
        settingsResponse?.prediction_thresholds.medium ??
        CariesDetectionThresholdDefaultValues.medium,
      high:
        settingsResponse?.prediction_thresholds.high ??
        CariesDetectionThresholdDefaultValues.high,
    };

    return {
      autoEnhance,
      cariesDetection: {
        thresholds: cariesDetectionThresholds,
        selected:
          settingsResponse?.prediction_thresholds.selected ??
          defaultSelectedThreshold,
      },
    };
  }

  private static serializeUserSettings(
    settings: KellsUser["settings"]
  ): KellsUserResponse["data"]["settings"] {
    return {
      auto_enhance_images: settings.autoEnhance,
      prediction_thresholds: {
        low: settings.cariesDetection.thresholds.low,
        medium: settings.cariesDetection.thresholds.medium,
        high: settings.cariesDetection.thresholds.high,
        selected: settings.cariesDetection.selected,
      },
    };
  }

  public addUser(data: AddUserRequestParams): Observable<AddUserResponse> {
    return this.http.post<AddUserResponse>(this.userServiceBaseUrl, data);
  }

  public updateUser(
    data: UpdateUserRequestParams
  ): Observable<UpdateUserResponse> {
    const { id, ...rest } = data;

    return this.http.patch<UpdateUserResponse>(
      `${this.userServiceBaseUrl}/${id}`,
      rest
    );
  }

  public getAllUsers(): Observable<User[]> {
    return this.http
      .get<GetUsersResponse>(this.userServiceBaseUrl)
      .pipe(map(({ data }) => this.parseUsersResponse(data)));
  }

  public getUserById(userId: string | number): Observable<User> {
    return this.http
      .get<GetUserResponse>(`${this.userServiceBaseUrl}/${userId}`)
      .pipe(map(({ data }) => this.parseUserResponse(data)));
  }

  public getUserByEmail(email: string): Observable<User> {
    return this.http
      .get<GetUserResponse>(`${this.userServiceBaseUrl}/email/${email}`)
      .pipe(map(({ data }) => this.parseUserResponse(data)));
  }

  public getUserByPatientId(
    patientId: string | number
  ): Observable<UserProvider[]> {
    return this.http
      .get<DentistsResponse>(
        `${this.userServiceBaseUrl}/by_patient_id/${patientId}`
      )
      .pipe(
        map(({ data }) => {
          return (data.providers || []).map((data) => ({
            ...data,
            provider: data.provider
              ? this.parseUserResponse(data.provider)
              : null,
          }));
        })
      );
  }

  public parseUserResponse(user: UserResponse): User {
    return {
      id: user._id,
      email: user.email || "",
      firstName: user.first_name || "",
      lastName: user.last_name || "",
      authId: user.auth_id || "",
      photoUrl: user.photo_url || "",
      specialty: user.specialty || "",
      organizations: user.organizations || [],
      permissions: user.permissions || [],
      role: user.role || "",
      office: user.office || "",
      gender: user.gender || undefined,
    };
  }

  public parseUsersResponse(users: UserResponse[]): User[] {
    return users.map((user) => this.parseUserResponse(user));
  }
}
