<!-- <kpr-recommended-product-filter
  (filterChange)="filterChange($event)"
  class="product-filter"
></kpr-recommended-product-filter> TODO Return when perscripted products are added-->

<kpr-recommended-product-navigation
  (tabChange)="tabChange($event)"
  [tabs]="tabs"
  class="product-navigation"
></kpr-recommended-product-navigation>

<div *ngIf="!isInreportRecommendation" class="report-link">
  <div class="report-link__description">
    <img
      src="assets/double-arrow.svg"
      alt="double-arrow"
      class="report-link__image"
    />
    <span>this condition is identified from your past scan</span>
  </div>
  <div
    class="report-link__button"
    (click)="
      openReportEmmit(['/app', 'report', 'member', patientId], {
        selectedSession: currentSessionId
      })
    "
  >
    CHECK REPORT
  </div>
</div>

<div class="products-list">
  <ng-container *ngFor="let item of displayedRecommendedItems">
    <kpr-recommended-product-item
      [recommendedProduct]="item"
    ></kpr-recommended-product-item
  ></ng-container>
</div>
