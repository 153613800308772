import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class URLService {
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  removeQueryParam(param: string) {
    const urlWithoutQueryParams = this.router.url.split("?")[0];

    const params = { ...this.route.snapshot.queryParams };
    delete params[param];

    const queryParamsString = new URLSearchParams(params).toString();

    this.location.replaceState(
      queryParamsString
        ? `${urlWithoutQueryParams}?${queryParamsString}`
        : urlWithoutQueryParams
    );
  }
}
