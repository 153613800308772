import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromAuth from "./auth.reducer";
import { isNullable } from "@kells/utils/js";

export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  fromAuth.featureKey
);

export const isAuthenticated = createSelector(
  selectAuthState,
  (state) => !isNullable(state.loggedInProfile?.userId)
);

export const getAccessToken = createSelector(
  selectAuthState,
  (state) => state.authToken?.accessToken
);

export const selectRefreshToken = createSelector(
  selectAuthState,
  (state) => state.authToken?.refreshToken
);

export const getLoggedInUserId = createSelector(
  selectAuthState,
  (state) => state.loggedInProfile?.userId ?? undefined
);

/**
 * Returns the ID of the office that is associated with the logged in user,
 * or `undefined` if such a value is not available (e.g. accessing this when
 * no user is logged in).
 */
export const getLoggedInUserOfficeId = createSelector(
  selectAuthState,
  (state) => state.loggedInProfile?.userOfficeId ?? undefined
);

/**
 * Authentication error getter.
 */
export const authError = createSelector(
  selectAuthState,
  (state) => state.authError
);

/** Selects whether the application is being logged in. */
export const isAuthenticating = createSelector(
  selectAuthState,
  (state) => state.isAuthenticating
);
