import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  toothInputValidator,
  cariesLocations,
  cariesStages,
  RenderableFindingBase,
  toothLocationValidator,
  CariesLocationTypes,
} from "@kells/interfaces/finding";
import * as fromRootStore from "@app/store";
import { observeProperty } from "@kells/utils/angular";
import { SubSink } from "subsink";
import {
  keepDefined,
  pipeLog,
} from "@kells/utils/observable/observable-operators";
import { Observable } from "rxjs";
import { FindingUpdatePayload } from "@kells/shared-ui/canvas";
import { Store } from "@ngrx/store";

export function parseLocation(location: string): CariesLocationTypes | null {
  switch (location.toUpperCase()) {
    case "MESIAL":
      return CariesLocationTypes.Mesial;
    case "DISTAL":
      return CariesLocationTypes.Distal;
    case "LINGUAL":
      return CariesLocationTypes.Lingual;
    case "OCCLUSAL":
      return CariesLocationTypes.Occlusal;
    case "BUCCAL":
      return CariesLocationTypes.Buccal;
    default:
      return null;
  }
}
@Component({
  selector: "canvas-finding-edit-form",
  templateUrl: "./finding-edit-form.component.html",
  styleUrls: ["./finding-edit-form.component.scss"],
})
export class FindingEditFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() initialData:
    | (RenderableFindingBase & { id: number })
    | undefined = undefined;
  private initialData$: Observable<
    (RenderableFindingBase & { id: number }) | undefined
  > = observeProperty(this, "initialData").pipe(pipeLog("initialData$"));

  @Input("imageConfirmed") isImageConfirmed = false;
  isImageConfirmed$: Observable<boolean> = observeProperty(
    this,
    "isImageConfirmed"
  ).pipe(pipeLog("isImageConfirmed$"));

  @Input() showDeletButton: boolean;

  @Input() theme = "darkMode";

  @Output() onSave = new EventEmitter<FindingUpdatePayload>();

  @Output() onCancel = new EventEmitter<unknown>();

  @Output() onDelete = new EventEmitter<number>();

  @ViewChild("tooth_field") toothFieldRef: ElementRef<HTMLInputElement>;

  readonly cariesLocations = cariesLocations;

  readonly cariesStages = cariesStages;

  private _subs = new SubSink();

  constructor(private store: Store<fromRootStore.RootState>) {}

  ngOnInit() {
    this.initialData$
      .pipe(keepDefined())
      .subscribe(this.populateInitialData.bind(this));
  }

  ngAfterViewInit() {
    this.afterViewInitFocusOnToothField();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  editForm = new FormGroup({
    tooth: new FormControl("", [Validators.required, toothInputValidator]),
    location: new FormControl("", [toothLocationValidator]),
    stage: new FormControl("", [Validators.required]),
  });

  get editFormTooth() {
    return this.editForm.get("tooth");
  }

  onSaveClicked() {
    this.onSave.emit({
      tooth: this.editForm.value.tooth,
      location: this.editForm.value.location,
      stage: this.editForm.value.stage,
    });
  }

  onCancelClicked() {
    this.editForm.reset();
    this.onCancel.next();
  }

  onDeleteClicked() {
    if (this.initialData) {
      this.onDelete.emit(this.initialData.id);
      this.editForm.reset();
    }
  }

  private populateInitialData(data: RenderableFindingBase) {
    this.editForm.setValue({
      tooth: data.tooth,
      location: parseLocation(data.location),
      stage: data.stage,
    });
  }

  private afterViewInitFocusOnToothField() {
    this.toothFieldRef.nativeElement.focus();
  }
}
