<div class="navigation">
  <div class="navigation__list">
    <ng-container *ngFor="let tab of tabs">
      <div
        class="navigation__list__item"
        [ngClass]="{ selected: tab === selectedTab }"
        (click)="selectTab(tab)"
      >
        {{ tab }}
      </div>
    </ng-container>
  </div>
  <div class="navigation__line"></div>
</div>
