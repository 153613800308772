import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  toothInputValidator,
  cariesLocations,
  cariesStages,
  RenderableFindingBase,
  PerioData,
  CariesStageTypes,
  CariesLocationTypes,
} from "@kells/interfaces/finding";
import { observeProperty } from "@kells/utils/angular";
import { SubSink } from "subsink";
import {
  keepDefined,
  pipeLog,
} from "@kells/utils/observable/observable-operators";
import { Observable } from "rxjs";
import { FindingUpdatePayload } from "@kells/shared-ui/canvas";

@Component({
  selector: "canvas-boneloss-edit-form",
  templateUrl: "./boneloss-edit-form.component.html",
  styleUrls: ["./boneloss-edit-form.component.scss"],
})
export class BonelossEditFormComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() initialData:
    | (RenderableFindingBase & { id: number })
    | undefined = undefined;
  private initialData$: Observable<
    (RenderableFindingBase & { id: number }) | undefined
  > = observeProperty(this, "initialData");

  @Input() showDeletButton: boolean;

  @Input("imageConfirmed") isImageConfirmed = false;
  isImageConfirmed$: Observable<boolean> = observeProperty(
    this,
    "isImageConfirmed"
  ).pipe(pipeLog("isImageConfirmed$"), keepDefined());
  // @Input() theme: "lightMode" | "darkMode" = "lightMode";

  @Output() onSave = new EventEmitter<FindingUpdatePayload>();

  @Output() onCancel = new EventEmitter<unknown>();

  @Output() onDelete = new EventEmitter<number>();

  @ViewChild("tooth_field") toothFieldRef: ElementRef<HTMLInputElement>;

  @ViewChild("severity_field") severityFieldRef: ElementRef<HTMLInputElement>;

  // @ViewChild("grade_field") gradeFieldRef: ElementRef<HTMLInputElement>;

  // readonly cariesLocations = cariesLocations;

  // readonly cariesStages = cariesStages;

  private _subs = new SubSink();

  ngOnInit() {
    this.initialData$
      .pipe(keepDefined())
      .subscribe(this.populateInitialData.bind(this));
  }

  ngAfterViewInit() {
    this.afterViewInitFocusOnToothField();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  severityOptions: Array<string> = ["1", "2", "3", "4"];

  editForm = new FormGroup({
    tooth: new FormControl("", [Validators.required, toothInputValidator]),
    // grade: new FormControl("", [Validators.required]),
    // severity: new FormControl("", [Validators.required])
  });

  get editFormTooth() {
    return this.editForm.get("tooth");
  }

  // get editFormGrade() {
  //   return this.editForm.get("grade");
  // }

  get editFormSeverity() {
    return this.editForm.get("severity");
  }

  onSaveClicked() {
    this.onSave.emit({
      tooth: this.editForm.value.tooth,
      // severity: this.editForm.value.severity,
      location: CariesLocationTypes.Other,
      stage: this.editForm.value.severity,
      // location: this.editForm.value.location,
      // stage: this.editForm.value.stage,
    });
  }

  onCancelClicked() {
    this.editForm.reset();
    this.onCancel.next();
  }

  onDeleteClicked() {
    if (this.initialData) {
      this.onDelete.emit(this.initialData.id);
      this.editForm.reset();
    }
  }

  private populateInitialData(data: RenderableFindingBase) {
    // const bone_loss_attributes = data.bone_loss_attributes as PerioData;
    this.editForm.setValue({
      tooth: data.tooth,
      // severity: data?.bone_loss_attributes?.severity,
      // grade: bone_loss_attributes.grade,
    });
  }

  private afterViewInitFocusOnToothField() {
    this.toothFieldRef.nativeElement.focus();
  }
}
