import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from '@angular/material/menu';
import { CanvasModule } from "@kells/shared-ui/canvas";
import { NumericBubbleComponent } from "./components/numeric-bubble/numeric-bubble.component";
import { PillToggleComponent } from "./components/pill-toggle/pill-toggle.component";
import { ImageCardPreviewBadgeComponent } from "./components/image-preview-card-badge/image-preview-card-badge.component";
import { ImageCardPreviewComponent } from "./components/image-preview-card/image-preview-card.component";
import { ImagePreviewComponent } from "./components/image-preview/image-preview.component";
import { ImagePreviewCardActionBtn } from "./components/image-preview-card-action-btn/image-preview-card-action-btn.component";
import { KellsButtonPrimaryDirective } from "./directives/kells-button-primary.directive";
import { KellsButtonStrokedDirective } from "./directives/kells-button-stroked.directive";
import { KellsButtonLinkDirective } from "./directives/kells-button-link.directive";
import { TeethChartComponent } from "./components/teeth-chart/teeth-chart.component";
import { MatIconCustomSvgColorDirective } from "./directives/mat-icon-custom-svg-color.directive";
import { InlineSvgDirective } from "./directives/inline-svg.directive";
import { CariesSeverityLegendComponent } from "./components/caries-severity-legend/caries-severity-legend.component";
import { LoadingContainerComponent } from "./components/loading-container/loading-container.component";
import { ImageSelectComponent } from "./components/image-select/image-select.component";


@NgModule({
  imports: [ CommonModule, MatTooltipModule, MatMenuModule, MatIconModule, CanvasModule],

  declarations: [
    NumericBubbleComponent,
    PillToggleComponent,
    ImagePreviewComponent,
    ImageCardPreviewComponent,
    ImagePreviewCardActionBtn,
    ImageCardPreviewBadgeComponent,
    // MouthComponent,
    KellsButtonPrimaryDirective,
    KellsButtonStrokedDirective,
    KellsButtonLinkDirective,
    // ToothDirective,
    TeethChartComponent,
    MatIconCustomSvgColorDirective,

    InlineSvgDirective,
    CariesSeverityLegendComponent,
    LoadingContainerComponent,
    ImageSelectComponent,
  ],
  exports: [
    NumericBubbleComponent,
    PillToggleComponent,
    ImagePreviewComponent,
    ImageCardPreviewComponent,
    ImagePreviewCardActionBtn,
    ImageCardPreviewBadgeComponent,
    KellsButtonPrimaryDirective,
    KellsButtonStrokedDirective,
    KellsButtonLinkDirective,
    TeethChartComponent,
    MatIconCustomSvgColorDirective,
    InlineSvgDirective,
    CariesSeverityLegendComponent,
    LoadingContainerComponent,
    ImageSelectComponent,
    // MouthComponent,
    // ToothDirective
  ],
})
export class SharedUiAngularModule {}
