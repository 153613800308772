import { Organization } from "@app/core/models/organization.model";
import { createAction, props } from "@ngrx/store";

enum ActionTypes {
  GetOrganizations = "[Organization] Get Organizations",
  GetOrganizationsSuccess = "[Organization] Get Organizations Success",
  GetOrganizationsFailure = "[Organization] Get Organizations Failure",
}

export const getOrganizations = createAction(ActionTypes.GetOrganizations);

export const getOrganizationsSuccess = createAction(
  ActionTypes.GetOrganizationsSuccess,
  props<{ organizations: Organization[] }>()
);

export const getOrganizationsFailure = createAction(
  ActionTypes.GetOrganizationsFailure,
  props<{ error: any }>()
);
