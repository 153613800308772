import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  SelectPatientFromPatientHome = "[Patient Page] Select Patient",
  SelectPatientFromProductTour = "[Product Tour] Select Patient",
  SELECT_SESSION_DATES = "[Patient Page] Select Session Dates",
  SELECT_ALL_SESSION_DATES = "[Patient Page] Select All Session Dates",
  UNDO_SELECT_ALL_SESSION_DATES = "[Patient Page] Undo Select All Session Dates",
  SELECT_ACTIVE_TAB = "[Patient Page] Select Active Tab",
  SET_START_PREDICTING_FROM = "[Patient Page] Set Start Predicting From",
}

export const selectPatientFromPatientHome = createAction(
  ActionTypes.SelectPatientFromPatientHome,
  props<{ patientId: string }>()
);

export const selectPatientFromProductTour = createAction(
  ActionTypes.SelectPatientFromProductTour,
  props<{ patientId: string }>()
);

export const selectSessionDates = createAction(
  ActionTypes.SELECT_SESSION_DATES,
  props<{ sessionDates: string[] }>()
);

export const selectAllSessionDates = createAction(
  ActionTypes.SELECT_ALL_SESSION_DATES
);

export const undoSelectAllSessonDates = createAction(
  ActionTypes.UNDO_SELECT_ALL_SESSION_DATES
);

export const selectActiveTab = createAction(
  ActionTypes.SELECT_ACTIVE_TAB,
  props<{ tab: string }>()
);
export const setStartPredictingFrom = createAction(
  ActionTypes.SET_START_PREDICTING_FROM,
  props<{ startPredictingFrom: string }>()
);
