import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as OrganizationActions from "./organization.actions";
import { Organization } from "@app/core/models/organization.model";

export const featureKey = "organization";

export type OrganizationState = EntityState<Organization> & {
  isLoaded: boolean;
  isLoading: boolean;
  error: any;
};

const adapter: EntityAdapter<Organization> = createEntityAdapter<Organization>();

export const initialState: OrganizationState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(OrganizationActions.getOrganizations, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(OrganizationActions.getOrganizationsSuccess, (state, { organizations }) =>
    adapter.addMany(organizations, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null,
    })
  ),
  on(OrganizationActions.getOrganizationsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  }))
);

export const { selectAll } = adapter.getSelectors();
