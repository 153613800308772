import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";

import { CanvasComponent } from "./components/canvas/canvas.component";
import { FindingLabelComponent } from "./components/finding-label/finding-label.component";
import { FindingEditFormComponent } from "./components/finding-edit-form/finding-edit-form.component";
import { ToothEditFormComponent } from "./components/tooth-edit-form/tooth-edit-form.component";
import { MaterialEditFormComponent } from "./components/material-edit-form/material-edit-form.component";
import { BonelossEditFormComponent } from "./components/boneloss-edit-form/boneloss-edit-form.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,

  ],
  declarations: [
    CanvasComponent,
    FindingLabelComponent,
    FindingEditFormComponent,
    ToothEditFormComponent,
    MaterialEditFormComponent,
    BonelossEditFormComponent
  ],
  exports: [CanvasComponent],
})
export class CanvasModule {}
