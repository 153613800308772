 <ng-container *ngIf="dataReady$ | async">
   <ng-container *ngLet="{
     data: (toothIcon$ | async),
     y: (dataY$ | async)
   } as props">
 <svg preserveAspectRatio="xMinYMid meet" viewBox="-8 -8 108 140">
      <defs>
          <clipPath [attr.id]="'clip-0-' + props.data.toothNumber + '-boneloss'">
            <rect width="100" height="0"  fill="#000000" x="0" [attr.y]="props.data.y"></rect>
          </clipPath>
          <clipPath [attr.id]="'clip-1-' + props.data.toothNumber + '-boneloss'">
            <rect width="100" height="44"  fill="#000000" x="0" [attr.y]="props.data.y"></rect>
          </clipPath>
          <clipPath [attr.id]="'clip-2-' + props.data.toothNumber + '-boneloss'">
            <rect width="100" height="88"  fill="#000000" x="0" [attr.y]="props.data.y"></rect>
          </clipPath>
          <clipPath [attr.id]="'clip-3-' + props.data.toothNumber + '-boneloss'">
            <rect width="100" height="134"  fill="#000000" x="0" [attr.y]="props.data.y"></rect>
          </clipPath>
      </defs>


      <use #iconSvg [attr.xlink:href]="props.data.xlink" x="0" y="-4"
                    [attr.stroke]="props.data.toothStroke"
                    [attr.fill]="props.data.toothFill"

                    ></use>

        <!--
[attr.class]="props.data.toothClass"
          -->


      <g>
        <use  [attr.clip-path]="props.data.xlinkClip"
              [attr.stroke]="props.data.dataStroke"
              [attr.fill]="props.data.dataFill"
              [attr.xlink:href]="props.data.xlink" x="0" y="-4"
          ></use>
          <!--
            [attr.class]="props.data.dataClass"
           -->
      </g>
</svg>
</ng-container>
</ng-container>
