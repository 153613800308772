import { createAnalyticsEvent } from "@kells/apis/analytics";
import { PatientAttributable } from "./base-attributes";

enum EventTypes {
  OpenSessionsPage = "Open Sessions Page",
}

export const openSessionsPage = createAnalyticsEvent<PatientAttributable>(
  EventTypes.OpenSessionsPage
);
