import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

const NotInitializedError = Error(
  "Cannot call this method before initializing IconRegistryService through method 'init'."
);

@Injectable({
  providedIn: "root",
})
export class IconRegistryService {
  private iconRootDirSubject = new BehaviorSubject<string | undefined>(
    undefined
  );

  private isWindowsSubject = new BehaviorSubject<boolean>(false);

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  /**
   * Specify where icon is stored.
   */
  init(iconRootDir: string, config = { isWindows: false }) {
    this.iconRootDirSubject.next(iconRootDir);
    this.isWindowsSubject.next(config.isWindows);
  }

  /**
   * Register icons.
   *
   * @param iconNames file name(s) (suffix excluded) of svgs stored in the asset folder.
   */
  register(iconNames: string | string[]) {
    if (!this.isInitialized) {
      return NotInitializedError;
    }

    if (!this.iconRootDirSubject.value) return;

    const names = typeof iconNames === "string" ? [iconNames] : iconNames;

    const svgFile = (s: string) => {
      if (!this.iconRootDirSubject.value) {
        throw new Error(
          "expected icon rootdir to be defined but none could be found."
        );
      }

      return [this.iconRootDirSubject.value, `${s}.svg`].join(
        this.isWindowsSubject.value ? "\\" : "/"
      );
    };

    names.forEach((iconName) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(svgFile(iconName))
      );
    });
  }

  private isInitialized() {
    // eslint-disable-next-line eqeqeq
    return this.iconRootDirSubject.value != null;
  }
}
