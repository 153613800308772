import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { SVG_ICON_NAMES } from "@kells/shared-assets";
import {
  apiEnvironment,
  ClinicOneEnvironmentsModule,
} from "@kells/clinic-one/environments";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { AuthConfig } from "./auth.config";
import { IconRegistryService } from "@kells/utils/angular";
import { DataAccessApisModule } from "@kells/apis/data-access";
import { NgLetModule } from "@ngrx-utils/store";
import { StoreModule } from "@ngrx/store";
import * as Sentry from "@sentry/angular-ivy";

import { register } from "swiper/element/bundle";
import { SharedModule } from "./shared/shared.module";
import { Router } from "@angular/router";
// register Swiper custom elements
register();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgLetModule,
    AppRoutingModule,
    HttpClientModule,
    DataAccessApisModule,
    ClinicOneEnvironmentsModule,
    SharedModule,
    AuthModule.forRoot({
      domain: AuthConfig.domain,
      clientId: AuthConfig.clientId,
      scope: AuthConfig.scope,
      // the audience field needs to be configured correctly to obtain tokens
      // that our backend recognizes.
      audience: AuthConfig.audience,
      httpInterceptor: {
        allowedList: [
          `${apiEnvironment.apiBaseUrl}/*`,
          `https://sandbox.optiopublishing.com/*`,
        ],
      },
    }),
    StoreModule.forRoot([]),
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private iconRegistry: IconRegistryService) {
    this.registerIcons();
  }

  private registerIcons() {
    this.iconRegistry.init("../assets/svgs");
    this.iconRegistry.register(SVG_ICON_NAMES);
    this.iconRegistry.init("../assets/");
    this.iconRegistry.register([
      "chevron-right",
      "chevron-left",
      "social-instagram",
      "social-linkedin",
      "social-email",
      "social-map",
    ]);
  }
}
