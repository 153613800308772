import { createAction, props } from "@ngrx/store";
import {
  CariesDetectionThresholdTypes,
  CariesDetectionThresholds,
  KellsUser,
} from "../models/user.model";

export enum ActionTypes {
  GetLoggedInUser = "[User] Get Logged In User",

  GetUser = "[User/API] Get User",
  GetUserSuccess = "[User/API] Get User Success",
  GetUserFailure = "[User/API] Get User Failure",

  TurnOnAutoEnhance = "[User/API] Turn On Auto Enhance",
  TurnOnAutoEnhanceSuccess = "[User/API] Turn On Auto Enhance Success",
  TurnOnAutoEnhanceFailure = "[User/API] Turn On Auto Enhance Failure",

  TurnOffAutoEnhance = "[User/API] Turn Off Auto Enhance",
  TurnOffAutoEnhanceSuccess = "[User/API] Turn Off Auto Enhance Success",
  TurnOffAutoEnhanceFailure = "[User/API] Turn Off Auto Enhance Failure",

  UpdateCariesDetectionThresholdPreference = "[User/API] Update Caries Detection Threshold Preference",
  UpdateCariesDetectionThresholdPreferenceSuccess = "[User/API] Update Caries Detection Threshold Preference Success",
  UpdateCariesDetectionThresholdPreferenceFailure = "[User/API] Update Caries Detection Threshold Preference Failure",

  UpdateCariesDetectionThresholdValues = "[User/API] Update Caries Detection Threshold Values",
  UpdateCariesDetectionThresholdValuesSuccess = "[User/API] Update Caries Detection Threshold Success Values",
  UpdateCariesDetectionThresholdValuesFailure = "[User/API] Update Caries Detection Threshold Failure Values",
}

export const getLoggedInUser = createAction(ActionTypes.GetLoggedInUser);

export const getUser = createAction(
  ActionTypes.GetUser,
  props<{ officeId: string; userId: string }>()
);

export const getUserSuccess = createAction(
  ActionTypes.GetUserSuccess,
  props<{ user: KellsUser }>()
);

export const getUserFailure = createAction(
  ActionTypes.GetUserFailure,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const turnOnAutoEnhance = createAction(ActionTypes.TurnOnAutoEnhance);

export const turnOnAutoEnhanceSuccess = createAction(
  ActionTypes.TurnOnAutoEnhanceSuccess,
  props<{ loggedInUserId: string }>()
);

export const turnOnAutoEnhanceFailure = createAction(
  ActionTypes.TurnOnAutoEnhanceFailure,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const turnOffAutoEnhance = createAction(ActionTypes.TurnOffAutoEnhance);

export const turnOffAutoEnhanceSuccess = createAction(
  ActionTypes.TurnOffAutoEnhanceSuccess,
  props<{ loggedInUserId: string }>()
);

export const turnOffAutoEnhanceFailure = createAction(
  ActionTypes.TurnOffAutoEnhanceFailure,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const updateCariesDetectionThresholdPreference = createAction(
  ActionTypes.UpdateCariesDetectionThresholdPreference,
  props<{ thresholdKey: CariesDetectionThresholdTypes }>()
);

export const updateCariesDetectionThresholdPreferenceSuccess = createAction(
  ActionTypes.UpdateCariesDetectionThresholdPreferenceSuccess,
  props<{
    thresholdKey: CariesDetectionThresholdTypes;
    loggedInUserId: string;
  }>()
);

export const updateCariesDetectionThresholdPreferenceFailure = createAction(
  ActionTypes.UpdateCariesDetectionThresholdPreferenceFailure,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const updateCariesDetectionThresholdValues = createAction(
  ActionTypes.UpdateCariesDetectionThresholdValues,
  props<{ thresholds: CariesDetectionThresholds }>()
);

export const updateCariesDetectionThresholdValuesSuccess = createAction(
  ActionTypes.UpdateCariesDetectionThresholdValuesSuccess,
  props<{ thresholds: CariesDetectionThresholds; loggedInUserId: string }>()
);

export const updateCariesDetectionThresholdValuesFailure = createAction(
  ActionTypes.UpdateCariesDetectionThresholdValuesFailure,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ error: any }>()
);

export const errorActions = [
  turnOnAutoEnhanceFailure,
  turnOffAutoEnhanceFailure,
  updateCariesDetectionThresholdPreferenceFailure,
  updateCariesDetectionThresholdValuesFailure,
];
