export const SVG_ICON_NAMES = [
  "add-finding",
  "ai-off",
  "ai",
  "circled-question-mark",
  "chevron-right",
  "chevron-left",
  "image-adjust",
  "locked",
  "eye-hidden",
  "eye-visible",
  "green-checkmark",
  "more-info",
  "page-back",
  "rotate-left",
  "rotate-right",
  "trash",
  "triangle",
  "unlocked",
  "tooth-1",
  "tooth-2",
  "tooth-3",
  "tooth-4",
  "tooth-5",
  "tooth-6",
  "tooth-7",
  "tooth-8",
  "tooth-9",
  "tooth-10",
  "tooth-11",
  "tooth-12",
  "tooth-13",
  "tooth-14",
  "tooth-15",
  "tooth-16",
  "tooth-17",
  "tooth-18",
  "tooth-19",
  "tooth-20",
  "tooth-21",
  "tooth-22",
  "tooth-23",
  "tooth-24",
  "tooth-25",
  "tooth-26",
  "tooth-27",
  "tooth-28",
  "tooth-29",
  "tooth-30",
  "tooth-31",
  "tooth-32",
];
