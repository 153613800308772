import { isNullable } from "@kells/utils/js";

export enum BitewingClassifications {
  RightMolar = "Bitewingrightmolar",
  RightPremolar = "Bitewingrightpremolar",
  LeftMolar = "Bitewingleftmolar",
  LeftPremolar = "Bitewingleftpremolar",
}

/**
 * `ClassificationBaseKind` describes an image's possible classification states,
 * other than an actual classification.
 */
export enum ClassificationBaseKind {
  /** Placeholder value for images that have _not_ gone through classification. */
  NotInitialized = "Classification_NotInitialized",
  /** When an image has received an error in the classification process. */
  Err = "Classification_Err",
  /**
   * When an image has gone through the classification process, but did not
   * receive an actual classification.
   */
  Generic = "Others",
}

/** Denotes all the types that describe a valid classification result. */
export type ValidClassificationKinds = BitewingClassifications;

/** Denotes all classification types an Image could have. */
export type ImageClassification =
  | ClassificationBaseKind
  | ValidClassificationKinds;

export interface ClassifierSuccessResponse {
  data: string;
  model_ver: string;
}

export interface ClassifierErrorResponse {
  errorMessage: string;
}

/** Information required to classify an image. */
export interface ClassificationRequestPayload {
  imageId: string;
  imageUrl: string;
}

/** Information required to store classification success results locally. */
export interface ClassificationSuccessResult {
  imageId: string;
  xrayType: ImageClassification;
  modelVersion: string;
}

export const isClassificationSuccessResult = (
  arg: any
): arg is ClassificationSuccessResult =>
  !isNullable(arg.imageId) &&
  !isNullable(arg.xrayType) &&
  !isNullable(arg.modelVersion);

/** Describes the nature of a specific classification error. */
export interface ClassificationErrorResult {
  imageId: string;
  error: any;
}

export const isClassificationErrorResult = (
  arg: any
): arg is ClassificationErrorResult =>
  !isNullable(arg.imageId) && !isNullable(arg.error);

/**
 * An enumeration of the possible interfaces for classification results,
 * used for the purpose of persisting these results in the app locally.
 */
export type ClassificationResult =
  | ClassificationSuccessResult
  | ClassificationErrorResult;

export const isClassifierSuccessResponse = (
  arg: any
): arg is ClassifierSuccessResponse =>
  arg.data !== undefined && arg.model_ver !== undefined;

export type ClassifierResponse =
  | ClassifierSuccessResponse
  | ClassifierErrorResponse;
