import { ReviewJob, ReviewJobWithPatient } from "../models/review-job.model";
import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  GetAll = "[ReviewJobs/API] Get All",
  GetAllSuccess = "[ReviewJobs/API] Get All Success",
  GetAllFailure = "[ReviewJobs/API] Get All Failure",

  OpenReviewJob = "[ReviewJobs] Open Review Job",

  CompleteReviewJob = "[ReviewJobs/API] Complete Review Job",
  CompleteReviewJobSuccess = "[ReviewJobs/API] Complete Review Job Success",
  CompleteReviewJobFailure = "[ReviewJobs/API] Complete Review Job Failure",
}

export const getAllReviewJobs = createAction(ActionTypes.GetAll);

export const getAllReviewJobsSuccess = createAction(
  ActionTypes.GetAllSuccess,
  props<{ reviewJobs: ReviewJobWithPatient[] }>()
);

export const getAllReviewJobsFailure = createAction(
  ActionTypes.GetAllFailure,
  props<{ error: any }>()
);

export const openReviewJob = createAction(
  ActionTypes.OpenReviewJob,
  props<{ reviewJob: ReviewJob }>()
);

export const completeReviewJob = createAction(
  ActionTypes.CompleteReviewJob,
  props<{ patientId: string; sessionId: string }>()
);

export const completeReviewJobSuccess = createAction(
  ActionTypes.CompleteReviewJobSuccess,
  props<{ reviewJobId: string }>()
);

export const completeReviewJobFailure = createAction(
  ActionTypes.CompleteReviewJobFailure,
  props<{ error: any }>()
);

export const reviewJobErrorActions = [getAllReviewJobsFailure];
