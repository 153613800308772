import { Component, Input } from "@angular/core";
import { RecommendedProduct } from "../../shared/interfaces/recommended-product.interfaces";
import { AnalyticsService } from "@kells/apis/analytics";
import { OpenProductLinkEvent } from "apps/patient-reporting/src/app/shared/models/analytics/home-events";

@Component({
  selector: "kpr-recommended-product-item",
  templateUrl: "./recommended-product-item.component.html",
  styleUrls: ["./recommended-product-item.component.scss"],
})
export class RecommendedProductItemComponent {
  @Input() recommendedProduct: RecommendedProduct;

  constructor(private analyticsService: AnalyticsService) {}

  public onShopNowClick() {
    this.analyticsService.record(
      OpenProductLinkEvent({
        productName: this.recommendedProduct.name,
        productLink: this.recommendedProduct.link,
      })
    );
  }
}
