import { FindingStatus } from "@kells/interfaces/finding";
import { FindingDTO } from "./finding-api.model";
import { Finding } from "./finding.model";
import { ImageClassification } from "./image-classifications.model";

/** Type alias for an Image ID. */
export type ImageId = string;

/** Minimum properties required to represent an Image entity. */
export interface ImageDefinition {
  id: string;
  url: string;
}

export enum ImageType {
  Xray = "xray",
  Photo = "photo",
}

export const RawImageOrderModes = {
  PREDICTED: "predict",
  USER: "user",
};

export enum ImageOrderMode {
  NotInitialized = "",
  Predicted = "predict",
  User = "user",
}

/** Canonical representation of an Image within the application. */
export interface Image extends ImageDefinition {
  /**
   * A number's order index among other images in its session.
   * A lower order index indicates the image is positioned earlier.
   */
  orderId?: number;
  orderMode: ImageOrderMode;
  xrayType: ImageClassification;
  classifierModelVersion?: string;
  captureTime: Date | null;
  xrayCaptureTime: Date | null;
  loaded?: boolean;
  /** Optional. `true` if the image is part of a pending prediction session. */
  isBeingPredicted?: boolean;
  isPredicted?: boolean;
  isPredictionFailed?: boolean;
  isBeingDeleted?: boolean;
  isDeleted?: boolean;
  isDeletedFailed?: boolean;
  isConfirming?: boolean;
  findings: Finding[];
  templateId?: number;
  dragged?: boolean;
  template_type?: string;
  sessionName?: string;
  treatments: string[];
  isRotating?: boolean;
  imageType?: ImageType;
  status: FindingStatus;
  sessionId: string;
}

/** Image response from server. */
export interface ImageResponse {
  _id: string;
  url: string;
  order_id?: number;
  captureTime: Date | null;
  xrayCaptureTime: Date | null;
  order_mode?: string;
  created_datetime: Date | null;
  image_datetime: string;
  xray_image_type: string;
  classifier_model_ver?: string;
  findings: FindingDTO[];
  template_id?: number;
  template_type?: string;
  treatments: string[];
  image_type: ImageType;
  status: FindingStatus;
  is_photo_scan: boolean;
  sessionId?: string;
  session?: string;
}

/** A union of the types that describe an Image entity. */
export type ImageLike = ImageId | Image | ImageResponse | ImageDefinition;
