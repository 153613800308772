import {
  PatientRecommendation,
  PatientRecommendationUpdatePayload,
} from "@kells/clinic-one/apis";
import { createAction, props } from "@ngrx/store";

export enum ActionTypes {
  FetchPatientRecommendations = "[Risk Evaluation API] Fetch Patient Recommendations",
  FetchPatientRecommendationsSuccess = "[Risk Evaluation API] Fetch Patient Recommendations Success",
  FetchPatientRecommendationsFailure = "[Risk Evaluation API] Fetch Patient Recommendations Failure",

  CreatePatientRecommendation = "[Risk Evaluation API] Create Patient Recommendation",
  CreatePatientRecommendationSuccess = "[Risk Evaluation API] Create Patient Recommendation Success",
  CreatePatientRecommendationFailure = "[Risk Evaluation API] Create Patient Recommendation Failure",


  UpdatePatientRecommendation = "[Risk Evaluation API] Update Patient Recommendation",
  UpdatePatientRecommendationSuccess = "[Risk Evaluation API] Update Patient Recommendation Success",
  UpdatePatientRecommendationFailure = "[Risk Evaluation API] Update Patient Recommendation Failure",
}

export const fetchPatientRecommendations = createAction(
  ActionTypes.FetchPatientRecommendations,
  props<{ patientId: string }>()
);

export const fetchPatientRecommendationsSuccess = createAction(
  ActionTypes.FetchPatientRecommendationsSuccess,
  props<{ recommendations: PatientRecommendation[] }>()
);

export const fetchPatientRecommendatonsFailure = createAction(
  ActionTypes.FetchPatientRecommendationsFailure,
  props<{ error: unknown }>()
);


export const createPatientRecommendation = createAction(
  ActionTypes.CreatePatientRecommendation,
  props<{
    patientId: string;
    recommendation: Partial<PatientRecommendationUpdatePayload>;
  }>()
);


export const createPatientRecommendationSuccess = createAction(
  ActionTypes.CreatePatientRecommendationSuccess,
  props<{
    patientId: string;
    recommendation: PatientRecommendation;
  }>()
);


export const createPatientRecommendationFailure = createAction(
  ActionTypes.CreatePatientRecommendationFailure,
  props<{ error: unknown }>()
);


export const updatePatientRecommendation = createAction(
  ActionTypes.UpdatePatientRecommendation,
  props<{
    patientId: string;
    update: PatientRecommendationUpdatePayload;
  }>()
);

export const updatePatientRecommendationSuccess = createAction(
  ActionTypes.UpdatePatientRecommendationSuccess
);

export const updatePatientRecommendationFailure = createAction(
  ActionTypes.UpdatePatientRecommendationFailure,
  props<{ error: unknown }>()
);
