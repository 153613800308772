import { BoxCoordinates, PerioData } from "./box-coordinates.model";
import { CariesStageTypes } from "./caries.model";
import { ImageType, Treatment } from "@kells/clinic-one/apis";
import { KellsImageBase } from "@kells/interfaces/image";
import { ToothNumber } from "@kells/interfaces/tooth";
export enum FindingType {
  Caries = "caries",
  Tooth = "tooth",
  Material = "material",
  Dummy = "dummy",
  BoneLoss = "bone_loss",
  Fracture = "fracture",
  Calculus = "calculus",
  Infection = "infection",
  DefectiveRestoration = "defective_restoration",
  Plaque = "plaque",
  GumRecession = "gum_recession",
  GumInflammation = "gum_inflammation",
  MissingTooth = "missing_tooth",
}

export const FindingTypeLabels: Record<FindingType, string> = {
  [FindingType.Caries]: "Cavity",
  [FindingType.Tooth]: "Tooth",
  [FindingType.Material]: "Material",
  [FindingType.Dummy]: "Dummy",
  [FindingType.BoneLoss]: "Bone Loss",
  [FindingType.Fracture]: "Fracture",
  [FindingType.Calculus]: "Calculus",
  [FindingType.Infection]: "Infection",
  [FindingType.DefectiveRestoration]: "Defective Restoration",
  [FindingType.Plaque]: "Plaque",
  [FindingType.GumRecession]: "Gum Recession",
  [FindingType.GumInflammation]: "Gum Inflammation",
  [FindingType.MissingTooth]: "Missing Toooth",
};

export type NonDummyFindingType = Exclude<FindingType, FindingType.Dummy>;

/**
 * Lists all possible values for the 'status' field in a finding.
 *
 * Finding status denotes different stages a finding could be in.
 *
 * - A finding generated by machine learning prediction will first have a
 *     'predicted' status, and will carry a 'confirmed' status once it is
 *     reviewed by a dentist.
 *
 * - A finding drawn by a dentist automatically carries the 'confirmed' status.
 */
export enum FindingStatus {
  Confirmed = "confirmed",
  Predicted = "predicted",
  Drawing = "drawing",
  Default = "",
}

export enum MaterialTypes {
  Filling = "Filling",
  Crown = "Crown",
  ImplantCrown = "Implant Crown",
  Post = "Post",
  RootCanal = "Root Canal Treatment",
  Implant = "Implant",
  Pontic = "Pontic",
  OnlayInlay = "Onlay/Inlay",
  Veneer = "Veneer",
  /**
   * Fallback value for Findings without an explicitly defined meterial.
   */
  Undefined = "",
}

export const materialOptions: MaterialTypes[] = [
  MaterialTypes.Filling,
  MaterialTypes.Crown,
  MaterialTypes.ImplantCrown,
  MaterialTypes.Post,
  MaterialTypes.RootCanal,
  MaterialTypes.Implant,
  MaterialTypes.Pontic,
  MaterialTypes.OnlayInlay,
  MaterialTypes.Veneer,
];

/**
 * Essential properties for a finding to be renderable.
 */
export interface RenderableFindingBase {
  box: BoxCoordinates;
  location: string;
  /**
   * Tooth identifier, valid ones including 1 - 32 | A - T.
   */
  tooth: string;
  type: FindingType;
  stage: CariesStageTypes;
  /**
   * A string describing a finding's material.
   * This field only exists for material findings.
   */
  material?: string;
  status?: FindingStatus;
  bone_loss_attributes?: PerioData;
}

export interface DummyFindingBase {
  type: FindingType.Dummy;
  status: FindingStatus;
  tooth?: any;
}

export type KellsFinding = DummyFindingBase | RenderableFindingBase;

/** Key for selecting the material findings in the finding filter. */
export const MaterialsFilterSelectionType = "material";

/** Key for selecting the tooth findings in the finding filter. */
export const ToothFilterSelectionType = "tooth";

/** Key for selecting the uncategorized findings in the finding filter. */
export const UncategorizedFindingsFilterSelectionType = "uncategorized";

/** Key for selecting all the caries in the finding filter. */
export const AllCariesFilterSelectionType = "caries";

/** Key for selecting all the boneloss perio in the finding filter. */
export const BonelossFilterSelectionType = "bone_loss";

/** Key for selecting fracture in the finding filter. */
export const FractureFilterSelectionType = "fracture";

/** Key for selecting calculus in the finding filter. */
export const CalculusFilterSelectionType = "calculus";

/** Key for selecting infection in the finding filter. */
export const InfectionFilterSelectionType = "infection";

/** Key for selecting defecting restoration in the finding filter. */
export const DefectiveRestorationFilterSelectionType = "defective_restoration";

/** Key for selecting plaque in the finding filter. */
export const PlaqueFilterSelectionType = "plaque";

/** Key for selecting gum recession in the finding filter. */
export const GumRecessionFilterSelectionType = "gum_recession";

/** Key for selecting gum inflammation in the finding filter. */
export const GumInflammationFilterSelectionType = "gum_inflammation";

/** Key for selecting missing tooth in the finding filter. */
export const MissingToothFilterSelectionType = "missing_tooth";
/**
 * A union of available selection options for finding filtering.
 */
export type FindingFilterSelectionTypes =
  // CariesStageTypes.Undefined is not a valid finding type and is
  // therefore excluded from finding selection.
  | Exclude<CariesStageTypes, CariesStageTypes.Undefined>
  | typeof UncategorizedFindingsFilterSelectionType
  | typeof MaterialsFilterSelectionType
  | typeof AllCariesFilterSelectionType
  | typeof ToothFilterSelectionType
  | typeof BonelossFilterSelectionType
  | typeof FractureFilterSelectionType
  | typeof CalculusFilterSelectionType
  | typeof InfectionFilterSelectionType
  | typeof DefectiveRestorationFilterSelectionType
  | typeof PlaqueFilterSelectionType
  | typeof GumRecessionFilterSelectionType
  | typeof GumInflammationFilterSelectionType
  | typeof MissingToothFilterSelectionType;

/**
 * Definition of the displayed texts for each finding filter option.
 */
export enum FilterEntryDisplayedValues {
  CariesAll = "Caries",
  CariesInitial = "Initial",
  CariesModerate = "Moderate",
  CariesAdvanced = "Advanced",
  CariesUncategorized = "Not Categorized",
  ToothAll = "Tooth",
  MaterialsAll = "Material",
  BoneLoss = "Bone Loss",
  Fracture = "Fracture",
  Calculus = "Calculus",
  Infection = "Infection",
  DefectiveRestoration = "Defective Restoration",
  Plaque = "Plaque",
  GumRecession = "Gum Recession",
  GumInflammation = "Gum Inflammation",
  MissingTooth = "Missing Tooth",
}

export const FINDING_TYPE_LABELS: Record<FindingType, string> = {
  [FindingType.Caries]: "Caries",
  [FindingType.Tooth]: "Tooth",
  [FindingType.Material]: "Material",
  [FindingType.Dummy]: "Dummy",
  [FindingType.BoneLoss]: "Bone Loss",
  [FindingType.Fracture]: "Fracture",
  [FindingType.Calculus]: "Calculus",
  [FindingType.Infection]: "Infection",
  [FindingType.DefectiveRestoration]: "Defective Restoration",
  [FindingType.Plaque]: "Plaque",
  [FindingType.GumRecession]: "Gum Recession",
  [FindingType.GumInflammation]: "Gum Inflammation",
  [FindingType.MissingTooth]: "Missing Tooth",
};

export const TOOTH_NAMES = [
  "Unknown",
  "3rd Molar",
  "2nd Molar",
  "1st Molar",
  "2nd Bicuspid",
  "1st Bicuspid",
  "Cuspid or canine",
  "Lateral incisor",
  "Central incisor",
  "Central incisor",
  "Lateral incisor",
  "Cuspid",
  "1st Premolar",
  "2nd Premolar",
  "1st Molar",
  "2nd Molar",
  "3rd Molar",
  "3rd Molar",
  "2nd Molar",
  "1st Molar",
  "2nd Premolar",
  "1st Premolar",
  "Cuspid or canine",
  "Lateral incisor",
  "Central incisor",
  "Central incisor",
  "Lateral incisor",
  "Cuspid or canine",
  "1st Premolar",
  "2nd Premolar",
  "1st Molar",
  "2nd Molar",
  "3rd Molar",
];

export interface FindingsAggregator {
  images: KellsImageBase[];
  allFindings: RenderableFindingBase[];
  allCaries: RenderableFindingBase[];
  treatments: Treatment[];
  tooth: RenderableFindingBase[];
  initial: RenderableFindingBase[];
  advanced: RenderableFindingBase[];
  moderate: RenderableFindingBase[];
  material: RenderableFindingBase[];
  boneLoss: RenderableFindingBase[];
  calculus: RenderableFindingBase[];
  fracture: RenderableFindingBase[];
  infection: RenderableFindingBase[];
  defectiveRestoration: RenderableFindingBase[];
  plaque: RenderableFindingBase[];
  gumRecession: RenderableFindingBase[];
  gumInflammation: RenderableFindingBase[];
  missingTooth: RenderableFindingBase[];
}

export interface FindingsByTooth extends FindingsAggregator {
  session: string;
  sessionId: string;
  tooth_id: string;
  name: string;
  toothAppearances: number;
  totalCaries: number;
  qtyAdvanced: number;
  qtyInitial: number;
  qtyModerate: number;
  qtyMaterial: number;
  qtyBoneLoss: number;
  xrays: string[];
}

export interface FindingsByImage extends FindingsAggregator {
  session: string;
  sessionId: string;
  image_id: string;
  image_url: string;
  image: KellsImageBase;
  name: string;
  imageBase64?: Blob;
  thumbBase64?: Blob;
  naturalWidth?: number;
  naturalHeight?: number;
  imageAppearances: number;
  totalCaries: number;
  qtyAdvanced: number;
  qtyInitial: number;
  qtyModerate: number;
  qtyMaterial: number;
  qtyBoneLoss: number;
  xrays: string[];
  imageType?: ImageType;
}

export type IndexedFindingsMap = Map<string, FindingsByTooth>;

export interface ToothIconSlot {
  toothNumber: ToothNumber;
  xlink: string;
  iconIdentifier: string;
  xlinkClip?: string;
  bonelossScore?: number;
  toothColor?: string;
  dataColor?: string;
  dataHeight?: number;
  strokeColor?: string;
}
