import { theme } from "../../exported-theme";
export type ColorKey = '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';//50 |100 |200 |300 |400 |500 |600 |700 |800 | 900;
export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
}
export type Palette = PartialRecord<50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | 'defaultKey' | 'hoverKey' | 'focusKey' | 'activeKey' | 'focusHoverKey' | 'activeHoverKey', string | number | undefined>
  //[K in '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900']:string;
 // [K in 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | '50' | '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | 'defaultKey' | 'hoverKey' | 'focusKey' | 'activeKey' | 'focusHoverKey' | 'activeHoverKey']: string | undefined;

  // 50: string;
  // 100: string;
  // 200: string; 300: string;
  // 400: string; 500: string;
  // 600: string; 700: string;
  // 800: string; 900: string;
// };
type ColorFlag<Type> = {
  [Property in keyof Type]: string;
};

export type ColorStateKeymap = {
  defaultKey?: string;
  hoverKey?: string;
  focusKey?: string;
  activeKey?: string;
  focusHoverKey?: string;
  activeHoverKey?: string;

}
// export type Palette = Partial<ColorSet>;

export type PaletteKeyType = ColorStateKeymap | ColorFlag<ColorStateKeymap>;

export const FabricPropStyles  = [
  'strokeWidth_active_hover',
  'strokeWidth_active',
  'strokeWidth_focus_hover',
  'strokeWidth_focus',

  'strokeWidth_hover',
  'strokeWidth',

  'strokeColor',
  'strokeColor_hover',

  'fillColor',
  'fillColor_hover',

  'strokeColor_focus',
  'strokeColor_focus_hover',

  'fillColor_focus',
  'fillColor_focus_hover',

  'strokeColor_active',
  'strokeColor_active_hover',

  'fillColor_active',
  'fillColor_active_hover',
];
export interface IFabricFindingStyles {
  strokeWidth_active_hover:number;
  strokeWidth_active: number;
  strokeWidth_focus_hover: number;
  strokeWidth_focus: number;

  strokeWidth_hover: number;
  strokeWidth: number;

  strokeColor:string;
  strokeColor_hover:string;

  fillColor:string;
  fillColor_hover:string

  strokeColor_focus:string;
  strokeColor_focus_hover:string;

  fillColor_focus:string;
  fillColor_focus_hover: string;

  strokeColor_active: string;
  strokeColor_active_hover: string;

  fillColor_active: string;
  fillColor_active_hover: string;
  strokeDashArray?: Array<number>;

//  configBasePalette?: {(p:Palette):void};

  setUp(subtarget?:string):void;
  setHover(subtarget?: string): void;
  setFocus(subtarget?: string): void;
  setFocusHover(subtarget?: string): void;

  setActive(subtarget?:string):void;
  setActiveHover(subtarget?: string): void;

  setInitial?: { (): void };
  setModerate?: { (): void };
  setAdvanced?: { (): void };


  rectState: Partial<IFabricFindingStyles>;

  confirmed?:boolean;

  commitColor?:{():void};
  registerPalette?:{(key:string, palette:Palette):void};
}


export const BASE_FABRIC_PROPS = {
  strokeUniform: true,
  hasRotatingPoint: false, // hide the rotating control above a fabric.Rect
  hasBorders: false, // disable the blue border shown when a box is selected
  selectable: true,
  hasControls: true,
  lockScalingX: false,
  lockScalingY: false,
  lockRotation: true,
  noScaleCache: false,
  originX: 'center',
  originY: 'center',
  strokeLineCap: 'square',
  hoverCursor: 'pointer',
  cornerColor: theme.controlFillColor,
  cornerStrokeColor: theme.controlStrokeColor,
  transparentCorners: false,
  cornerSize: theme.controlCornerSize
};
